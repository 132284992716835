<template>
  <div class="position-relative">
    <div v-if="canView('STORAGE_FILE') && showHeader" class="dashboard-banner">
      <AvatarBanner v-model="avatarBanner" :baseAvatarIcon="['fad', 'user-tie']" :readOnly="true"/>
    </div>
    <div class="dashboard-buttons">
      <div v-if="!disableLocker" class="dashboard-locker">
        <b class="mr-1">Lock Dashboard:</b>
        <b-form-checkbox switch data-vv-name="lockWidgets" data-vv-delay="500" v-model="lockWidgets"/>
      </div>
      <div id="DASHBOARD_SHOW_SETTINGS" class="dashboard-settings" @click="showDashboardSettings()">
        <font-awesome-icon :icon="['far', 'gear']"/>
      </div>
      <b-popover target="DASHBOARD_SHOW_SETTINGS" triggers="hover" placement="top">
        {{ $t('dashboard.title') }}
      </b-popover>
      <div class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
        <b-dropdown :id="`DASHBOARD_BTN_VIEW`" ref="view" class="action-bar-dropdown view-btn" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far','desktop']"/>
          </template>
          <b-dropdown-item @click="savePreset" href="#">
            <span class="action-item-label">{{ $t(contextKey === 'home' ? 'dashboard.button.save' : 'dashboard.button.save_project') }}</span>
          </b-dropdown-item>
          <b-dropdown-divider/>
          <template v-for="(item, index) in dashboardViews">
            <b-dropdown-item class="action-item" @click="loadViewSettings(item)" href="#" :key="index">
              <span class="action-item-label-with-icon">{{ item.name }}</span>
              <span>
                <span class="action-item-icon" 
                    v-if="!editPermission(item)"
                    :id="`BTN_COPY_${index}`"
                    @click.stop.prevent="copyColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="['far','copy']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_COPY_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(contextKey === 'home' ? 'dashboard.button.copy' : 'dashboard.button.copy_project')">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="!editPermission(item)"
                    @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                    :id="`BTN_INFO_${index}`">
                  <font-awesome-icon class="" :icon="['far','circle-info']"/>
                </span>
                <b-popover
                  v-if="!editPermission(item)"
                  :target="`BTN_INFO_${index}`"
                  :ref="`BTN_INFO_${index}`"
                  :show.sync="showInfo[index]"
                  placement="top"
                  boundary="viewport"
                  triggers="manual"
                  :content="$t('owner', [dashboardViews[index].owner])">
                </b-popover>
                <span class="action-item-icon position-third" 
                    v-if="editPermission(item)"
                    :id="`BTN_SHARE_${index}`"
                    @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_SHARE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(contextKey === 'home' ? 'dashboard.button.share' : 'dashboard.button.share_project')">
                </b-popover>
                <span class="action-item-icon position-second" 
                    v-if="editPermission(item)"
                    :id="`BTN_UPDATE_${index}`"
                    @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                  <font-awesome-icon class="" :icon="['far','save']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_UPDATE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(contextKey === 'home' ? 'dashboard.button.update' : 'dashboard.button.update_project')">
                </b-popover>
                <span class="action-item-icon"
                    v-if="editPermission(item)"
                    :id="`BTN_DELETE_${index}`"
                    @click.stop.prevent="removeColumnSettings(index)">
                  <font-awesome-icon class="" :icon="['far','trash-alt']"/>
                </span>
                <b-popover
                  v-if="editPermission(item)"
                  :target="`BTN_DELETE_${index}`"
                  placement="top"
                  boundary="viewport"
                  triggers="hover"
                  :content="$t(contextKey === 'home' ? 'dashboard.button.delete' : 'dashboard.button.delete_project')">
                </b-popover>
              </span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>
    
    <div id="grid-stack" class="grid-stack" data-gs-animate="yes">
      <template v-for="item in layout">
        <div class="grid-stack-item"
            :ref="item.uuId"
            :gs-x="item.x"
            :gs-y="item.y"
            :gs-w="item.w"
            :gs-h="item.h"
            :gs-min-w="item.minw"
            :gs-min-h="item.minh"
            :gs-max-h="item.maxh"
            :gs-max-w="item.maxw"
            :data-id="item.uuId"
            v-bind:style="{'cursor': !lockWidgets ? ' move ' : 'initial'}"
            :i="item.i" :key="item.uuId">
            <div class="grid-stack-item-content">
              <div class="debug-coords" v-if="debug"> x{{item.x}} y{{item.y}} w{{item.w}} h{{item.h}}</div>
              <template v-if="!lockWidgets"><div class="movable"></div></template>

              <!-- Launchpad appears on both dashboard types -->
              <LaunchpadWidget
                v-if="item.type === 'launchpad'"
                :widget="item"
                :project="project"
                @launchpadUpdated="launchpadUpdated"
              />

              <!-- Dataview appears on both dashboard types -->
              <DataviewWidget
                v-if="item.type === 'dataview'"
                :widget="item"
                :project="project"
                @dataviewUpdated="dataviewUpdated"
              />

              <!-- Home widgets -->
              <ProjectProgressWidget
                v-if="item.type === 'gauge'"
                :projects="projects"
                :uuId="item.i"
                :isHome="true"
                />
              <HomeMyTasksWidget
                v-if="item.type === 'mytasks'"
                :userEmail="userEmail" @editTask='editTask'/>
              <HomeStaffUtilizationWidget
                v-if="item.type === 'staff'"/> 
              <HomeMyScheduleWidget
                v-if="item.type === 'myschedule'"
                :userEmail="userEmail"/>
              <HomeDepartmentStaffWidget
                v-if="item.type === 'departmentstaff'"
                @saveWidget="saveWidget"
                :profile="item"/>
              <HomeActiveTasksWidget
                v-if="item.type === 'activetasks'"
                @editTask='editTask'/>
              <HomeActiveProjectsWidget
                v-if="item.type === 'activeprojects'"
                :refresh="refreshProjects" @refreshed="refreshedProjects"
                @editProject="editProjectId"/>
              <HomeStaffSkillsWidget
                v-if="item.type === 'hskills'"
                @saveWidget="saveWidget"
                :profile="item"/>
              <HomeTimelineWidget
                 v-if="item.type === 'timeline'"
                 class="no-clipping"
                 :refresh="refreshProjects" @refreshed="refreshedProjects"
                 @editProject="editProjectId"
                 @saveWidget="saveWidget"
                :profile="item"/>
                              
              <!-- Project widgets -->
              <ProjectInfoWidget
                v-if="item.type === 'info'"
                :project="project"
                @editProject="editProject" />
              <ProjectProgressWidget
                v-if="item.type === 'progress'"
                :project="project" />
              <ProjectStaffWidget
                v-if="item.type === 'pstaff'"
                @saveWidget="saveWidget"
                @openStaff="onOpenStaff"
                :refresh="refreshStaff" @refreshed="refreshedStaff"
                :project="project" :profile="item"/>
              <ProjectBudgetActualWidget
                v-if="item.type === 'pbudget'"
                @saveWidget="saveWidget"
                :project="project" :profile="item" />
              <ProjectTasksWidget
                v-if="item.type === 'tasks'"
                :project="project" :taskCount="taskCount"/>
              <ProjectKanbanBoardWidget
                v-if="item.type === 'kanban'"
                :project="project" :tasks="tasks" :refresh="refresh" @refreshed="refreshed" :taskCount="taskCount" />
              <ProjectTaskAlertsWidget
                v-if="item.type === 'talerts'"
                @saveWidget="saveWidget"
                :project="project" :profile="item" :refresh="refresh" @refreshed="refreshed" @editTask='editTask' />
              <ProjectSkillsWidget
                v-if="item.type === 'skills'"
                @saveWidget="saveWidget"
                :project="project" :profile="item" />
              <ProjectDepartmentsWidget
                v-if="item.type === 'departments'"
                @saveWidget="saveWidget"
                :project="project" :profile="item" />
              <ProjectMilestonesWidget
                v-if="item.type === 'milestones'"
                :project="project" @editTask='editTask'/>
              <ProjectActiveTasksWidget
                v-if="item.type === 'pactivetasks'"
                :project="project" :refresh="refresh" @refreshed="refreshed" @editTask='editTask'/>
            </div>
        </div>
      </template>
    </div>

    <DashboardWidgetSelectorModal
      :standardWidgets="allowedStandardWidgets"
      :progressWidgets="allowedProgressWidgets"
      :launchpadWidgets="allowedLaunchpadWidgets"
      :dataviewWidgets="allowedDataviewWidgets"
      :show.sync="dashboardSettingsShow"
      :title="settingsTitle"
      :origin="originName"
      @launchpadAdded="launchpadAdded"
      @launchpadUpdated="launchpadUpdated"
      @launchpadRemoved="launchpadRemoved"
      @launchpadDuplicated="launchpadDuplicated"
      @dataviewAdded="dataviewAdded"
      @dataviewUpdated="dataviewUpdated"
      @dataviewRemoved="dataviewRemoved"
      @toggleWidget="toggleWidget"
      @restoreDefaults="restoreDefaults"
      :mode="contextKey"
      @cancel="onSettingsClosed"
      :projects="projects"
    />

    <TaskModal
      :show.sync="taskEditShow"
      :id="taskEdit.uuId"
      :projectId="taskEdit.projectId"
      @success="taskEditSuccess"
    />
    <StaffModal
      :show.sync="staffEditShow"
      :id="staffEdit.uuId"
      @success="staffEditSuccess"
    />
    <ProjectModal
      :show.sync="projectEditShow"
      :id="projectEdit.uuId"
      :title="projectTitle"
      @success="projectEditSuccess"
    />
    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/> 
   
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('dashboard.error.sharing')"
        v-model="memberAlertShow"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('dashboard.error.sharing_message', [memberAlertName]) }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>



<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import dSettings from "@/_dashboardSettings";
import { companyService, dashboardProfileService, projectService, taskService, viewProfileService, profileService } from "@/services";

import 'gridstack/dist/gridstack.min.css';
import $ from 'gridstack/dist/jq/jquery';
import { GridStack } from 'gridstack';
import 'gridstack/dist/jq/gridstack-dd-jqueryui';

/**
 * The dashboard uses gridstack.js as the widget layout library. The library relies
 * on its own copy of jQuery (and UI) and requires a few workarounds to play nicely
 * with Vue's reactive behaviour.
 * Loading the widget with webpack: https://stackoverflow.com/a/60847490
 * 
 * Normally, you init a grid and then add/remove widgets using its API. We can't do this
 * with vue because it handles creating/deleting/updating DOM elements on its own. This
 * means any time a widget is added or removed, we have to re-init the grid (on the
 * next tick, to ensure the DOM has finished updating), which is easy to detect when
 * the grid's node count differs from our layout's widget count.
 *
 * When using the 'restore defaults' or 'cancel' button in the settings modal, we
 * replace all the widgets in the layout at once. This creates an inconsistency with
 * Gridstack's internal representation of our widgets and our actual layout. It would
 * ideally be enough to re-init the grid on these events, but Vue's selective
 * re-rerendering doesn't update the data attributes for each widget (and hence their
 * size and coordinates), causing them to be out of order (and on top of each other).
 * 
 * In the future it may be a better idea to get Vue to re-render each individual widget
 * with the new coordinates. The current solution is to give the grid a key, 'gridKey',
 * which is bumped on these events, causing the entire grid's DOM (and each widget) to
 * be rerendered. This is the cause of the temporary refresh and scroll effect when
 * using 'restore defaults' or 'cancel'. It will do for now.
 * 
 * There is touch support provided by the 'jQuery UI Touch Punch' library. The official version
 * doesn't work, but a modified version does: https://github.com/gridstack/gridstack.js/issues/444
 * The script being imported is copied from the working demo into our project. It takes effect
 * automatically without anything invoked by us.
 *
 * Aspect Ratio:
 * Gridstack uses a jQueryUI library called 'resizable' internally. One feature it provides
 * is aspect-ratio resizing, and gridstack provides an option to turn this on for all widgets.
 * We prefer to enable it on a per-widget basis, so we use a hack to allow it work that way.
 * In the widget, set the data-attribute "keep-aspect-ratio" to 'true' or a ratio to enable it.
 * More comments in code below (see mounted()).
 * 
 * Debugging:
 * There is a useful 'debug' flag that prints output for crucial events and puts coordinates
 * on the widgets. The coordinates can be helpful for building a new default layout.
 */


export default {
  name: "DashBoard",
  props: {
    projectId: { type: String, default: null }
  },
  components: {
    TaskModal: () => import('@/components/modal/TaskModal'),
    StaffModal: () => import('@/components/modal/StaffModal'),
    ProjectModal: () => import('@/components/modal/ProjectModal'),
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    DashboardWidgetSelectorModal: () => import('@/components/modal/DashboardWidgetSelectorModal'),


    // Shared
    LaunchpadWidget: () => import('@/components/Dashboard/Widget/LaunchpadWidget'),
    ProjectProgressWidget: () => import('@/components/Dashboard/Widget/ProjectProgressWidget'),
    DataviewWidget: () => import('@/components/Dashboard/Widget/DataviewWidget'),
    
    HomeMyTasksWidget: () => import('@/components/Dashboard/Widget/HomeMyTasksWidget'),
    HomeStaffUtilizationWidget: () => import('@/components/Dashboard/Widget/HomeStaffUtilizationWidget'),
    HomeMyScheduleWidget: () => import('@/components/Dashboard/Widget/HomeMyScheduleWidget'),
    HomeDepartmentStaffWidget: () => import('@/components/Dashboard/Widget/HomeDepartmentStaffWidget'),
    HomeActiveTasksWidget: () => import('@/components/Dashboard/Widget/HomeActiveTasksWidget'),
    HomeActiveProjectsWidget: () => import('@/components/Dashboard/Widget/HomeActiveProjectsWidget'),
    HomeStaffSkillsWidget: () => import('@/components/Dashboard/Widget/HomeStaffSkillsWidget'),
    HomeTimelineWidget: () => import('@/components/Dashboard/Widget/HomeTimelineWidget'),

    ProjectKanbanBoardWidget: () => import('@/components/Dashboard/Widget/ProjectKanbanBoardWidget'),
    ProjectBudgetActualWidget: () => import('@/components/Dashboard/Widget/ProjectBudgetActualWidget'),
    ProjectTaskAlertsWidget: () => import('@/components/Dashboard/Widget/ProjectTaskAlertsWidget'),
    ProjectInfoWidget: () => import('@/components/Dashboard/Widget/ProjectInfoWidget'),
    ProjectStaffWidget: () => import('@/components/Dashboard/Widget/ProjectStaffWidget'),
    ProjectTasksWidget: () => import('@/components/Dashboard/Widget/ProjectTasksWidget'),
    ProjectSkillsWidget: () => import('@/components/Dashboard/Widget/ProjectSkillsWidget'),
    ProjectDepartmentsWidget: () => import('@/components/Dashboard/Widget/ProjectDepartmentsWidget'),
    ProjectMilestonesWidget: () => import('@/components/Dashboard/Widget/ProjectMilestonesWidget'),
    ProjectActiveTasksWidget: () => import('@/components/Dashboard/Widget/ProjectActiveTasksWidget'),
    SaveViewModal: () => import('@/components/modal/SaveViewModal.vue')
  },
  data: function() {
    return {
      debug: false,  // more event logging, x,y on cards
      user: null,
      project: null,
      tasks: null,  // Pass into Project widgets that use tasks
      taskCount: 0, // a count of the tasks in a project
      projects: {}, // Pre-fetch project details into this for Home widgets (index by uuId)
      contextKey: null, // dashboard context - either 'home' or project uuId
      syncing: false, // Avoid re-mapping widgets on move when programatically moving to match layout
      layout: [],
      widgets: [],
      userId: null,
      showHeader: false,
      grid: null,
      lockWidgets: null,
      disableLocker: true,
      dashboardSettingsShow: false,
      taskEditShow: false,
      taskEdit: {
        uuId: null,
        projectId: null,
      },
      staffEdit: {
        uuId: null
      },
      staffEditShow: false,
      projectEditShow: false,
      projectEdit: {
        uuId: null,
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      dashboardViews: [],
      memberAlertShow: false,
      memberAlertName: null,
      refresh: false,
      refreshStaff: false,
      refreshProjects: false,
      showInfo: []
    }
  },
  watch: {
    lockWidgets() {
      if (this.lockWidgets !== null) { // do nothing on first load
        if (this.grid) {
          this.updateGridInteraction();
        }
      }
    },
    '$store.state.authentication.user.avatarRef': function() {
      this.loadHeaderImages();
    }
    ,'$store.state.authentication.user.bannerRef': function() {
      this.loadHeaderImages();
    },
    '$store.state.company.uuId': function(newValue, oldValue) {
      if (oldValue !== null) {
        this.layout = [];
        const self = this;
        setTimeout(() => {
          self.layout = self.widgets.filter(s => s.enable);
        }, 500);
      }
    }
  },
  created() {
    this.contextKey = this.projectId || "home"
    this.created();
  },
  computed: {
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    userPermissions() {
      if (this.$store.state.authentication.user.permissionList) {
        return this.$store.state.authentication.user.permissionList.map(p => p.name);
      }
      return [];
    },
    allowedStandardWidgets() {
      return this.widgets.filter(w => this.hasWidgetPermission(w) && (w.type != 'gauge' && w.type != 'launchpad' && w.type != 'dataview'));
    },
    allowedProgressWidgets() {
      return this.widgets.filter(w => this.hasWidgetPermission(w) && w.type == 'gauge');
    },
    allowedLaunchpadWidgets() {
      // We don't have permissions for these since they are user-owned
      return this.widgets.filter(w => w.type == 'launchpad');
    },
    allowedDataviewWidgets() {
      // We don't have permissions for these since they are user-owned
      return this.widgets.filter(w => w.type == 'dataview');
    },
    projectTitle() {
      return this.projectEdit.uuId && this.projectEdit.uuId.indexOf('PROJECT_NEW') == -1? this.$t('project.title_detail'): this.$t('project.title_new');
    },
    originName() {
      if (this.projectId) {
        return 'project';
      }
      return 'home';
    },
    settingsTitle() {
      if (this.projectId) {
        return this.$t('dashboard.selector.title_project');
      }
      return this.$t('dashboard.selector.title_home');
    }
  },
  mounted() {
    /**
     * We extend the prototype of the resizable library to allow us to run our
     * own code on resize events to ensure aspect ratio is maintained. The
     * original library has a feature to maintain aspect ratio if the Shift
     * key is held, so here we mark the Shift key as pressed if we find that
     * the element being resized has the 'keep-aspect-ratio' attribute set.
     * 
     * NOTE: the original _mouseDrag also has an _aspectRatio field to specify
     * the exact ratio preferred. It may be useful in the future to use this
     * instead.
     */
    var orig_mouseDrag = $.ui.resizable.prototype._mouseDrag;
    $.widget("ui.resizable", $.extend({}, $.ui.resizable.prototype, {
        _mouseDrag: function(event /** , item */) {
          let ui = this.ui();
          if (ui.originalElement.data('keepAspectRatio')) {
            event.shiftKey = true;
          }
          orig_mouseDrag.apply(this, arguments);
        }
      }
    ));
  },
  methods: {
    debugLog(s) {
      if (this.debug) {
        console.debug(s); // eslint-disable-line no-console
      }
    },
    created() {
      this.user = this.$store.state.authentication.user;
      this.userId = this.user.uuId;
      this.userEmail = this.user.email;

      let self = this;
      if (this.projectId) {
        // We need to load the project before we start rendering for
        // project-view mode
        projectService.get([{ uuId: this.projectId }], ['STAGE_LIST', 'CUSTOMER', 'LOCATION', 'COMPANY', 'REBATE', 'STAGE'], false).then(response => {
          const listName = response.data.jobCase;
          self.project = response.data[listName][0];
          self.loadHeaderImages();
          self.loadDashboardSettings();
          this.$store.dispatch('breadcrumb/update', self.project.name, { root: true });
        })
        .catch(e => {
          console.log(e); // eslint-disable-line no-console
        });
        projectService.taskCount(this.projectId).then(response => {
          self.taskCount = response.data[0].count;
        }).catch(e => {
          //eslint-disable-next-line
          console.error(e);
        });
      } else {
        this.loadHeaderImages();
        this.loadDashboardSettings();
      }
      this.loadUserProfile(); // User profile holds Staff views  
      this.loadPublicProfile(); // Public profile holds public Staff views
    },
    showDashboardSettings() {
      this.dashboardSettingsShow = true;
    },
    dashboardSettingsCancel(original) {
      this.widgets = original;
      this.layout = this.widgets.filter(s => s.enable);
      this.syncGridWithLayout()
    },
    async restoreDefaults() {
      // Restore the widget and layout without saving
      this.debugLog("Restoring defaults");
      let self = this;
      // We need to populate the profile uuId of each widget before we can use
      // this new list. Assumption: the known set of widgets contains all the
      // widgets and their uuIds already, because we populate them on load
      // (including any that were missing). We copy the uuIds here.
      let newWidgets = await this.getDefaultWidgetSet();
      newWidgets.forEach(w => {
        const existing = self.widgets.find(ow => ow.i == w.i);
        if (existing) {
          w.uuId = existing.uuId;
        }
        else {
          self.widgets.push(w);
        }
      });
      // Grab the launchpads and dataviews from the old list and add them to the new list.
      let userWidgets = this.widgets.filter(w => w.type == "launchpad" || w.type == "dataview");
      // But they are disabled in the default layout
      userWidgets.forEach(l => {
        l.enable = false;
      })

      // remove duplicates from the userWidgets
      for (let ii = userWidgets.length - 1; ii >= 0; ii--) {
        if (newWidgets.find(nw => nw.uuId === userWidgets[ii].uuId)) {
          userWidgets.splice(ii, 1);
        }
      }
      
      this.widgets = [...newWidgets, ...userWidgets];
      this.layout = this.widgets.filter(s => s.enable);
      this.syncGridWithLayout();
    },
    hasWidgetPermission(widget) {
      let t = widget.type;
      let can = (s) => this.userPermissions.includes(s);
      return t === 'launchpad' && true
        || t === 'dataview'
        || t === 'gauge' && can('HOME_DASHBOARD__WIDGET__PROGRESS')
        || t === 'mytasks' && can('HOME_DASHBOARD__WIDGET__MY_TASKS')
        || t === 'staff' && can('HOME_DASHBOARD__WIDGET__STAFF_UTILIZATION')
        || t === 'myschedule' && can('HOME_DASHBOARD__WIDGET__MY_SCHEDULE')
        || t === 'departmentstaff' && can('HOME_DASHBOARD__WIDGET__DEPARTMENT_STAFF')
        || t === 'activetasks' && can('HOME_DASHBOARD__WIDGET__ACTIVE_TASKS')
        || t === 'activeprojects' && can('HOME_DASHBOARD__WIDGET__ACTIVE_PROJECTS')
        || t === 'hskills' && can('HOME_DASHBOARD__WIDGET__STAFF_SKILLS')
        || t === 'timeline' && can('HOME_DASHBOARD__WIDGET__TIMELINE')
        
        || t === 'progress' && can('PROJECT_SUMMARY__WIDGET__PROGRESS')
        || t === 'info' && can('PROJECT_SUMMARY__WIDGET__INFO')
        || t === 'pstaff' && can('PROJECT_SUMMARY__WIDGET__STAFF')
        || t === 'pbudget' && can('PROJECT_SUMMARY__WIDGET__BUDGET_VS_ACTUAL')
        || t === 'tasks' && can('PROJECT_SUMMARY__WIDGET__TASKS')
        || t === 'kanban' && can('PROJECT_SUMMARY__WIDGET__KANBAN_BOARD')
        || t === 'talerts' && can('PROJECT_SUMMARY__WIDGET__TASK_ALERTS')
        || t === 'skills' && can('PROJECT_SUMMARY__WIDGET__SKILLS')
        || t === 'departments' && can('PROJECT_SUMMARY__WIDGET__SKILLS')
        || t === 'milestones' && can('PROJECT_SUMMARY__WIDGET__MILESTONES')
        || t === 'pactivetasks' && can('PROJECT_SUMMARY__WIDGET__ACTIVE_TASKS');
    },
    deepClone(jsonObj) {
      //Suitable for json object only. (Example: this doesn't suit object with function etc)
      return JSON.parse(JSON.stringify(jsonObj));
    },
    buildGrid(firstLoad = false) {
      this.debugLog("Building grid!");
      this.debugLog("Layout has:" + this.layout.length);

      this.grid = GridStack.init({
        alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
        resizable: {
          handles: 'e, se, s, sw, w, nw, n, ne',
          aspectRatio: false
        },
        minRow: 10,
        cellHeight: '104px',
        // margin: 10,
        disableDrag: this.lockWidgets,
        disableResize: this.lockWidgets
      });

      //Defensive code: Stop proceed further when grid is null. It happens when user navigates away from the page while the page is still loading/rendering.
      if (this.grid == null) {
        return;
      }

      this.disableLocker = false;
      if (this.grid.engine.column == 1) {
        this.disableLocker = true;
      }

      // Attach events only on the first load - they remain after re-init
      if (firstLoad) {
        let self = this;
        this.grid.on('change', function(e /** , items*/) {
          if (self.dashboardSettingsShow) {
            // We are going to ignore events that happen during widget on/off toggle via
            // settings because it is now trigering a change event (it didn't used to).
            // We don't want to save the widgets on toggle, only when modal is accepted.
            self.debugLog("Ignoring change event while settings open.");
            return;
          }
          if (self.syncing) {
            self.debugLog("Ignoring change event while syncing.");
            return;
          }
          if (e.bubbles) {
            // This is a workaround for events being triggered when elements inside widgets
            // generate events. We only want to save grid events. Widgets save their own state.
            // It just so happens that grid events don't have the 'bubbles' property, so we'll use it.
            self.debugLog("Ignore bubblable events.");
            return;
          }
          self.debugLog("Grid items changed");
          self.disableLocker = false;
          self.saveWidgets();
        });
      }
    },
    syncGridWithLayout() {
      // Make the internal grid state matche the layout definition.
      //
      // We are replacing the whole layout in a single step (restore defaults, cancel), and
      // Vue is responsible for maintaining the DOM. This means we end up with a mismatched
      // representation of the DOM and we need to sync them up.
      // We cannot use the grid's save/load functions because it doesn't work with Vue's
      // model.
      //
      // One difficulty with this approach is that we need elements to exist in the DOM
      // before we add or remove them from the grid. Thus, the remove happens before the
      // layout updates (same tick) and the add happens after the layout updates (next tick)

      const self = this;
      this.syncing = true

      // Remove
      self.widgets.forEach(function(widget) {
        let node = self.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
        if (!widget.enable && node) {
          self.debugLog("Removing widget from grid: " + widget.label)
          self.grid.removeWidget(node.el, false, false)
        }
      })

      Vue.nextTick(function() {
        // Add
        self.widgets.forEach(function(widget) {
          let node = self.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
          if (widget.enable && !node) {
            self.debugLog("Adding widget to grid: " + widget.label)
            if (self.$refs[widget.uuId]) {
              self.grid.makeWidget(self.$refs[widget.uuId][0])
            }
          }
        })


        // Update
        // The grid is dumb and will shuffle other widgets around as we reposition each widget,
        // including the ones we just updated.
        // The batchUpdate function provided has no effect. The solution here is to update
        // all the widgets again for each widget, unsuring each one has attained its desired
        // position per round.
        for (var i = 0; i < self.widgets.length; i++) {
          self.widgets.forEach(function(widget) {
            if (!widget.enable) {
              return;
            }
            let node = self.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
            if (node) {
              self.grid.update(node.el, {x: widget.x, y: widget.y, w: widget.w, h: widget.h})
            }
          })
        }
        self.syncing = false
      });
    },
    updateGridInteraction() {
      if (this.lockWidgets) {
        this.grid.disable()
      } else {
        this.grid.enable()
      }
    },
    async saveWidgets(viewName) {
      if (!this.grid) {
        this.debugLog("Can't save when no grid yet");
        return;
      }
      let self = this;
      this.updateGridInteraction();
      if (this.grid.engine.column == 1) {
        // We are NOT going to save the layout if it has switched to the 1-column
        // (responsive) layout, since it will override the layout values for the
        // 12 column layout.
        this.debugLog("1-column layout - not saving");
        this.lockWidgets = true;
        this.disableLocker = true;
        this.dashboardSettingsShow = false;
        this.updateGridInteraction();
        return;
      }

      this.grid.engine.nodes.forEach(function(node) {
        let id = node.el.dataset.id;
        self.widgets.forEach(function(widget) {
          if (widget.uuId == id) {
            widget.w = node.w;
            widget.h = node.h;
            widget.x = node.x;
            widget.y = node.y;
          }
        });
      });

      if (!viewName) {
        this.$store.dispatch("breadcrumb/clearView");
        const index = this.widgets.findIndex(w => w.type === 'viewName' && w.contextKey === this.contextKey);
        if (index !== -1) {
          await dashboardProfileService.remove([{ uuId: this.widgets[index].uuId }], this.user.uuId)
          this.widgets.splice(index, 1);
        }
      }
      else {
        
        // Set the view name and save it as a widget for recall
        this.$store.dispatch("breadcrumb/updateView", viewName, { root: true });
        // update the viewName in the widget list
        const viewNameIndex = this.widgets.findIndex(w => w.type === 'viewName' && w.contextKey === this.contextKey);
        if (viewNameIndex !== -1) {
          this.widgets[viewNameIndex].label = viewName;
        }
        else {
          const nw = { type: 'viewName', label: viewName, contextKey: this.contextKey };
          this.widgets.push(nw);
          await dashboardProfileService.create([nw], this.user.uuId)
        }
        
      }
      
      if (this.widgets.length > 0) {
        await dashboardProfileService.update(this.widgets, this.userId)
        .then(() => {
          self.debugLog("Saved widgets to profile!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      }
    },
    saveWidget() {
      let self = this;
      // Use this function to save an individual widget when its internal state changes
      dashboardProfileService.update(this.widgets, this.userId)
        .then(() => {
          self.debugLog("Saved widget state!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
    },
    toggleWidget({id, state}) {
      this.debugLog("Toggling: " + id);
      const widget = this.widgets.filter(w => w.i === id)[0];
      widget.enable = state;
      if (widget.enable) {
        let [x, y] = this.getNextOpenCoords(widget.w, widget.h);
        if (x !== -1 &&
            y !== -1) {
          widget.x = x;
          widget.y = y;
          this.debugLog("Open slot at x:" +x +"y" +y);
        }
        else {
          this.debugLog("No Open slot found");
        }
      }
      this.layout = this.widgets.filter(s => s.enable);

      if (widget.enable) {
        // Grid now needs to know about the DOM element we added
        const self = this;
        Vue.nextTick(function() {
          if (typeof self.$refs[widget.uuId] !== 'undefined') {
            self.grid.makeWidget(self.$refs[widget.uuId][0])
          }
        });
      } else {
        // Remove from grid (element will remove from DOM on text tick)
        const node = this.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
        if (typeof node !== 'undefined') {
          this.grid.removeWidget(node.el)
        }
      }
    },
    setWidgetList(widgets) {
      // Selector modal has given us a list of enabled widgets.
      // Compare with the existing list, and toggle on/off if changed.

      // Sort the disabled first so we can clear out the gaps
      // to fill in, in case we remove+add at the same time.
      // Otherwise the sequence of remove+add+remove may not fill
      // in all available space as expected.
      widgets.sort(function(a, b) {
        return a.enable && !b.enable;
      });

      this.dashboardSettingsShow = true;
      var self = this;
      widgets.forEach(function(nw, idx, widgets) {  
        let ow = self.widgets.find(ow => ow.uuId == nw.uuId);
        if ((ow.enable && !nw.enable) || (!ow.enable && nw.enable)) {

          setTimeout(() => {
            self.toggleWidget({id: nw.i, state: !nw.enable});
          }, 1);
        }
        if (idx == widgets.length - 1) {
          setTimeout(() => {
            self.saveWidgets();
            self.dashboardSettingsShow = false;
          }, 10);
        }
      });
    },
    getNextOpenCoords(w, h) {
      // Try every position in the grid until we find an open slot. 100 rows
      // is a sane maximum. The plugin creates a new row if we go over the
      // current max, so it's fine to overshoot by any amount.

      // The two functions here are copied from the plugin with a bug fix
      var self = this;
      function isIntercepted(a, b) {
        return !(a.x + a.w <= b.x || b.x + b.w <= a.x || a.y + a.h <= b.y || b.y + b.h <= a.y);
      }
      function isAreaEmpty(x, y, w, h) {
        var nn = {x: x || 0, y: y || 0, w: w || 1, h: h || 1};
        var collisionNode = self.grid.engine.nodes.find(function(n) {
          // TODO: the bugfix was for version 1 of the plugin; we are now on version 4. Is this still needed?
          return isIntercepted(n, nn) || x + w > 12; // Bug fix here: also check it doesn't spill over grid X
        });
        return !collisionNode;
      }
      
      for(var y = 0; y < 50; y++) {
        for(var x = 0; x < this.grid.engine.column; x++) {
          if (isAreaEmpty(x, y, w, h)) {
            return [x, y];
          }
        }
      }
      return [-1, -1]; // no coordinates found
    },
    loadHeaderImages() {
      var avatarId;
      var bannerId;
      if (this.project) {
        // Project dashboards
        avatarId = this.project.avatarRef;
        bannerId = this.project.bannerRef;
      } else {
        // Personal dashboards
        avatarId = this.$store.state.authentication.user.avatarRef;
        bannerId = this.$store.state.authentication.user.bannerRef;
      }
      this.avatarBanner.avatarId = avatarId == '00000000-0000-0000-0000-000000000000' ? null : avatarId;
      this.avatarBanner.bannerId = bannerId == '00000000-0000-0000-0000-000000000000' ? null : bannerId;

      if (this.avatarBanner.avatarId || this.avatarBanner.bannerId) {
        this.showHeader = true;
      } else {
        this.showHeader = false;
      }
    },
    async loadDashboardSettings() {
        let self = this;

      // NOTE: The dashboard profile stores one widget per setting. Each widget
      // gets its own UUID and collection of personal settings.

      // Here, we build a single list of all possible widgets from all sources
      // and check our stored settings for them - if we have a setting for that
      // widget, use it. If not (e.g., a new project was added), we create a
      // profile for it immediately.
      // That way, we can:
      //  - Offer all widgets in the selector without discovering 'missing' widgets
      //  - Not worry about creating profile entries when toggling on/off (i.e., it's
      //    guaranteed to be there - we just flip the enable flag)

      // Get our settings
      let list = await dashboardProfileService.list(this.user.uuId, this.contextKey)
      .then(response => {
        return response.data[response.data.jobCase];
      })
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
        return [];
      });
      
      // Save all project widgets for cleanup
      const projectWidgets = list.filter(l => l.contextKey !== 'home');
      // Filter out by 'mode' -- only keep home or project widgets
      list = list.filter(l => l.contextKey == this.contextKey);

      // // Purge existing widgets for testing
      // list = await dashboardProfileService.list(this.user.uuId, this.contextKey)
      // .then(response => {
      //   return response.data[response.data.jobCase];
      // });
      // const toDeleteFirst = list;
      // if (toDeleteFirst.length !== 0) {
      //   this.widgets = this.widgets.filter(w => !toDeleteFirst.some(d => d.i === w.i));
      //   const toDeleteIds = toDeleteFirst.map(w => { return { uuId: w.uuId } });
      //   if (toDeleteIds !== null) {
      //     dashboardProfileService.remove(toDeleteIds, this.user.uuId)
      //     .then(response => {
      //       console.log("Deleted:");
      //       console.log(toDeleteIds);
      //     })
      //     .catch(e => {
      //       console.error(e);
      //       return null;
      //     });
      //   }
      // }
      // console.log("Ended early");
      // return

      if(list.length !== 0) {
        // The user has settings stored for dashboard widgets.
        // Here, we populate the settings for any new widgets. Off by default.
        let newWidgets = await this.getDefaultWidgetSet();
        for (var i = 0; i < newWidgets.length; i++) {
          let nw = newWidgets[i];
          let widget = list.find(w => w.i == nw.i);
          if (widget == null) {
            // It's missing, so let's create it.
            this.debugLog("User has not seen this widget yet. Creating profile: " + nw.type);
            nw.enable = false;
            let addedWidget = await dashboardProfileService.create([nw], this.user.uuId)
            .then(response => {
              return response.data[response.data.jobCase][0];
            })
            .catch(e => {
              console.error(e); // eslint-disable-line no-console
              return null;
            });
            if (addedWidget) {
              nw.uuId = addedWidget.uuId;
              list.push(nw);
            }
          }
        }
        // And delete any widgets that we've removed from the system
        list = list.filter(w => w.type == 'launchpad' || w.type === 'dataview' || w.type === 'viewName' || newWidgets.map(nw => nw.type).includes(w.type));
        this.widgets = this.deepClone(list);
        
        this.applyViewName(this.widgets, this.user.uuId, true);
        
        // Ensure we only have one of each of the standard widgets
        const standardWidgets = ['activeprojects', 'activetasks', 'departmentstaff', 'mytasks', 'myschedule', 'timeline', 'hskills', 'staff', 'gauge',
                                 'info', 'progress', 'tasks', 'pstaff', 'kanban', 'pbudget', 'skills', 'milestones', 'talerts', 'pactivetasks'];
        for (const stand of standardWidgets) {
          const standlist = list.filter(w => w.type === stand);
          if (standlist.length > 1) {
            if (stand === 'gauge') {
              // ensure that there is only one widget for each project
              for (let j = standlist.length - 1; j > 0; j--) {
                const projectWList = standlist.filter(prj => prj.i === standlist[j].i);
                const toDeleteIds = [];
                for (let i = 1; i < projectWList.length; i++) {
                  const prjW = projectWList[i];
                  const index = list.findIndex(l => l.uuId === prjW.uuId);
                  if (index !== -1) {
                    list.splice(index, 1);
                    toDeleteIds.push({ uuId: prjW.uuId });
                  }
                }
                if (toDeleteIds.length > 0) {
                  dashboardProfileService.remove(toDeleteIds, this.user.uuId)
                }
              }
            }
            else {
              // remove the duplicates
              const toDeleteIds = [];
              for (let i = 1; i < standlist.length; i++) {
                const index = self.widgets.findIndex(l => l.uuId === standlist[i].uuId);
                if (index !== -1) {
                  self.widgets.splice(index, 1);
                  toDeleteIds.push({ uuId: standlist[i].uuId });
                }
              }
              if (toDeleteIds.length > 0) {
                dashboardProfileService.remove(toDeleteIds, this.user.uuId)
              }
            }
          }
        }
        
        // For home dashboards, when a project is deleted we need to remove the progress widget
        if (!this.projectId && this.canView('PROJECT')) {
          var projectList = await projectService.list({ start: 0, limit: -1 }, false, false)
          .then(response => {
            return response.data;
          }).catch(e => {
            console.error(e); // eslint-disable-line no-console
            return [];
          });
          if (projectList.length > 0) {
            const toDelete = this.widgets.filter(w => (w.type === 'gauge' && !projectList.some(p => p.uuId === w.i)) ||
                                                      (w.contextKey !== 'home' && !projectList.some(p => p.uuId === w.contextKey)));
            const toDelete2 = projectWidgets.filter(w => !projectList.some(p => p.uuId === w.contextKey));
            toDelete.push(...toDelete2);
            if (toDelete.length !== 0) {
              // Remove the widgets from the list
              this.widgets = this.widgets.filter(w => !toDelete.some(d => d.i === w.i));
              // And remove them from the profile
              const toDeleteIds = toDelete.map(w => { return { uuId: w.uuId } });
              if (toDeleteIds !== null) {
                dashboardProfileService.remove(toDeleteIds, this.user.uuId).then(() => {})
                .catch(e => {
                  console.error(e); // eslint-disable-line no-console
                  return null;
                });
              }
            }
          }
        }
      } else {
        // No widgets in profile. Start from scratch.
        // Build the initial set of widgets and settings
        this.widgets = await this.getDefaultWidgetSet();

        let profileList = await dashboardProfileService.create(this.widgets, this.user.uuId)
        .then(response => {
            return response.data[response.data.jobCase];
        })
        .catch(e => {
            console.error(e); // eslint-disable-line no-console
            return null;
        });
        // Put the uuIds we get from the profile into our widgets so we can save them later
        for (let i = 0; i < this.widgets.length; i++) {
          this.widgets[i].uuId = profileList[i].uuId;
        }
      }


      // Override any stored settings with system settings that should take precedence
      this.widgets.forEach(widget => {
        var settings;
        if (widget.type == "gauge") {
          settings = dSettings.progress;
        } else if (widget.type == "launchpad") {
          settings = dSettings.launchpad;
          if (!widget.owner) {
            widget.owner = this.userId;
          }
        } else if (widget.type == "dataview") {
          settings = dSettings.dataview;
        } else if (self.projectId) {
          settings = dSettings.project.find(s => s.type == widget.type);
        } else {
          settings = dSettings.home.find(s => s.type == widget.type);
        }
        
        if (settings) {
          widget.minh = settings.minh;
          widget.maxh = settings.maxh;
          widget.minw = settings.minw;
          widget.maxw = settings.maxw;
        }
      });

      // Dashboards start out locked 
      this.lockWidgets = true

      // Update the layout to show the widgets
      this.layout = this.widgets.filter(s => s.enable && this.hasWidgetPermission(s));
      Vue.nextTick(function() {
        self.buildGrid(true);
      });

      if (this.projectId) {
        // In project mode, the widgets look at the project's task data. It's most
        // efficient to fetch them all once at the start and reuse this local copy.
        //taskService.listProjectDashboard(this.projectId).then(resp => {
        //  self.tasks = resp.data;
        //});
      } else {
        // For home mode, fetch the project progress details. Make sure the
        // name of the widget matches the project name, in case it changes.
        self.projects = {};
        if (self.canView('PROJECT')) {
          projectService.listHomeDashboard().then(resp => {
            resp.data.forEach(p => {
              var widget = self.widgets.find(w => w.i == p.uuId);
              if (widget) {
                widget.name = p.name;
                widget.label = p.name;
              }
              Vue.set(self.projects, p.uuId, p);
            })
          });
        }
      }
    },
    async getDefaultWidgetSet() {
      // Get a list of all possible widgets and their default layout values
      // This includes 'dynamic' sources like project progress
      let data = this.projectId ? dSettings.project : dSettings.home;
      await this.loadUserProfile(); // User profile holds Staff views  
      await this.loadPublicProfile(); // Public profile holds public Staff views
      const defaultView = this.dashboardViews.find(v => v.defaultView);
      if (defaultView) {
        data = defaultView.widgets.filter(w => w.enable);
        for (const w of data) {
          delete w.uuId;
          w.contextKey = this.contextKey;
        }
      }
      
      const defaultWidgets = this.deepClone(data).filter(w => w.i);

      defaultWidgets.forEach(w => {
        w.contextKey = this.contextKey;
      })

      // Home dashboard has project widgets
      if (!this.projectId && this.canView('PROJECT')) {
        // Home dashboard - populate progress widgets
        let projects = await projectService.list({ start: 0, limit: -1 }, false, false)
        .then(response => {
          return response.data;
        }).catch(e => {
          console.error(e); // eslint-disable-line no-console
          return [];
        });
        for (const project of projects) {
          // only add the project if it is not in the default view
          if (!defaultWidgets.find(f => f.i === project.uuId)) {
            var settings = this.deepClone(dSettings.progress);
            settings.contextKey = this.contextKey;
            settings.i = project.uuId;
            settings.name = project.name;
            settings.label = project.name;
            defaultWidgets.push(settings);
          }
        }
      }
      return defaultWidgets.filter(w => this.hasWidgetPermission(w));
    },
    editTask({taskId, projectId, callback}) {
      this.taskEdit.uuId = taskId;
      this.taskEdit.projectId = projectId;
      this.taskEdit.callback = callback;
      this.taskEditShow = true;
    },
    onOpenStaff(id) {
      this.staffEdit.uuId = id;
      this.staffEditShow = true;
    },
    refreshed() {
      this.refresh = false;
    },
    refreshedStaff() {
      this.refreshStaff = false;
    },
    refreshedProjects() {
      this.refreshProjects = false;
    },
    taskEditSuccess(/** data */) {
      if (this.projectId) {
        // Reload grid data to refresh the grids
        this.refresh = true;
      }
      if (this.taskEdit.callback) {
        this.taskEdit.callback();
      }
    },
    staffEditSuccess() {
      this.staffEditShow = false;
      this.refreshStaff = true;
    },
    editProjectId(projectId) {
      this.projectEdit.uuId = projectId;
      this.projectEditShow = true;
    },
    editProject() {
      this.projectEdit.uuId = this.projectId;
      this.projectEditShow = true;
    },
    projectEditSuccess(/** data */) {
      // Since it's possible to change everything (including banner images),
      // it's best to reload the whole page
      this.created();
      this.refreshProjects = true;
    },
    async launchpadAdded(widget) {
      widget.contextKey = this.contextKey;
      widget.owner = this.userId;
      let addedWidget = await dashboardProfileService.create([widget], this.user.uuId)
      .then(response => {
        return response.data[response.data.jobCase][0];
      });
      this.debugLog("Created new launchpad widget!");
      widget.uuId = addedWidget.uuId;
      widget.i = addedWidget.uuId;
      this.widgets.push(widget);
      this.toggleWidget({id: widget.i, state: !widget.enable});
    },
    async dataviewAdded(widget) {
      widget.contextKey = this.contextKey;
      widget.owner = this.userId;
      let addedWidget = await dashboardProfileService.create([widget], this.user.uuId)
      .then(response => {
        return response.data[response.data.jobCase][0];
      });
      this.debugLog("Created new dataview widget!");
      widget.uuId = addedWidget.uuId;
      widget.i = addedWidget.uuId;
      this.widgets.push(widget);
      this.toggleWidget({id: widget.i, state: !widget.enable});
    },
    async launchpadCopy(widget, user) {
      widget.contextKey = this.contextKey;
      widget.owner = user;
      let addedWidget = await dashboardProfileService.create([widget], user)
      .then(response => {
        return response.data[response.data.jobCase][0];
      });
      this.debugLog("Created new launchpad widget!");
      widget.uuId = addedWidget.uuId;
      widget.i = addedWidget.uuId;
      return widget;
    },
    async dataviewCopy(widget, user) {
      widget.contextKey = this.contextKey;
      widget.owner = user;
      let addedWidget = await dashboardProfileService.create([widget], user)
      .then(response => {
        return response.data[response.data.jobCase][0];
      });
      this.debugLog("Created new dataview widget!");
      widget.uuId = addedWidget.uuId;
      widget.i = addedWidget.uuId;
    },
    launchpadUpdated(widget) {
      dashboardProfileService.update([widget], this.userId)
        .then(() => {
          this.debugLog("Saved launchpad widget state!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      // Replace the widget we updated with the new version and force it to render
      var idx = this.widgets.findIndex(w => w.uuId == widget.uuId);
      Vue.set(this.widgets, idx, widget);
      this.layout = this.widgets.filter(s => s.enable);
    },
    dataviewUpdated(widget) {
      dashboardProfileService.update([widget], this.userId)
        .then(() => {
          this.debugLog("Saved dataview widget state!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      // Replace the widget we updated with the new version and force it to render
      var idx = this.widgets.findIndex(w => w.uuId == widget.uuId);
      Vue.set(this.widgets, idx, widget);
      this.layout = this.widgets.filter(s => s.enable);
    },
    launchpadRemoved(widgets) {
      dashboardProfileService.remove(widgets, this.userId)
        .then(() => {
          this.debugLog("Deleted launchpad widget!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      widgets.forEach(widget => {
        var idx = this.widgets.findIndex(w => w.uuId == widget.uuId);
        Vue.delete(this.widgets, idx, widget);
      });
      this.layout = this.widgets.filter(s => s.enable);
    },
    dataviewRemoved(widgets) {
      dashboardProfileService.remove(widgets, this.userId)
        .then(() => {
          this.debugLog("Deleted launchpad widget!");
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
        });
      widgets.forEach(widget => {
        var idx = this.widgets.findIndex(w => w.uuId == widget.uuId);
        Vue.delete(this.widgets, idx, widget);
        let node = this.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
        if (node) {
          this.debugLog("loadViewSettings: Removing widget from grid: " + widget.label)
          this.grid.removeWidget(node.el, false, false)
        }
      });
      this.layout = this.widgets.filter(s => s.enable);
    },
    async launchpadDuplicated(widget) {
      delete widget.originalUuid;
      let addedWidget = await dashboardProfileService.create([widget], this.user.uuId)
      .then(response => {
        return response.data[response.data.jobCase][0];
      });
      this.debugLog("Duplicated launchpad widget!");
      widget.uuId = addedWidget.uuId;
      widget.i = addedWidget.uuId;
      this.widgets.push(widget);
      if (widget.enable) {
        // Toggle sets it to enabled again
       widget.enable = false;
       this.toggleWidget({id: widget.i, state: !widget.enable}) ;
      }
    },
    onSettingsClosed() {
      this.saveWidgets();
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (arg.toElement &&
          typeof arg.toElement.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    },
    async saveWidgetContext(widgets) {
  
      let profileList = await dashboardProfileService.create(widgets, this.user.uuId)
      .then(response => {
          return response.data[response.data.jobCase];
      })
      .catch(e => {
          console.error(e); // eslint-disable-line no-console
          return null;
      });
      // Put the uuIds we get from the profile into our widgets so we can save them later
      for (let i = 0; i < widgets.length; i++) {
        widgets[i].uuId = profileList[i].uuId;
      }
    },
    async loadViewSettings(view) {
      const self = this;
      
      const gaugeWidgets = this.widgets.filter(w => w.type === 'gauge' && view.widgets.filter(vw => vw.uuId === w.uuId).length === 0);
      // turn off gaugeWidgets that are not in this view
      gaugeWidgets.forEach(l => l.enable = false);
      
      const viewNameEntries = this.widgets.filter(w => w.type === 'viewName' && w.contextKey === this.contextKey);
      if (viewNameEntries.length !== 0 &&
          view.widgets.filter(w => w.type === 'viewName' && w.contextKey === this.contextKey).length === 0) {
        view.widgets.push(viewNameEntries[0]);
      }
      
      const userWidgets = this.widgets.filter(w => (w.type === 'launchpad' || w.type === 'dataview') && view.widgets.filter(vw => vw.uuId === w.uuId && vw.enable).length === 0);
      // get a list of all the user widgets
      const userWidgetsAll = this.widgets.filter(w => (w.type === 'launchpad' || w.type === 'dataview'));
      // turn off userWidgets that are not in this view
      userWidgets.forEach(l => l.enable = false);
    
      // ensure that widgets not in this view are off on refresh
      await this.saveWidgets(view.name);
      
      // clear the layout
      // Remove
      this.widgets.forEach(function(widget) {
        let node = self.grid.engine.nodes.find(n => n.el.dataset.id == widget.uuId);
        if (node) {
          self.debugLog("loadViewSettings: Removing widget from grid: " + widget.label)
          self.grid.removeWidget(node.el, false, false)
        }
      })
      
      const newWidgets = JSON.parse(JSON.stringify(view.widgets.filter(v => v.type !== 'viewName')));
      const newContextWidgets = [];
      for (let i = 0; i < newWidgets.length; i++) {
        const w = newWidgets[i];
        
        // if the widget is from another project then we need to create a new widget for this project
        if (w.contextKey !== this.contextKey &&
            w.enable) {
          const haveWidget = self.widgets.find(ww => ww.i === w.i && ww.contextKey === this.contextKey);
          if (haveWidget) {
            w.contextKey = this.contextKey;
            w.uuId = haveWidget.uuId; // we will update the existing widget
          }
          else {
            w.contextKey = this.contextKey;
            delete w.uuId;
            newContextWidgets.push(w);
          }
        }
        else {
          const widget = self.widgets.filter(ow => ow.i == w.i && ow.type === w.type);
          if (widget.length !== 0) {
            w.uuId = widget[0].uuId;
          }
        }
      }
      
      if (newContextWidgets.length !== 0) {
        await this.saveWidgetContext(newContextWidgets);
      }
      
      const toAdd = [];
      newWidgets.forEach((w, index, object) => {
             
        if ((w.type === 'launchpad' || w.type === 'dataview') && w.owner !== self.userId) {
          const ourlaunchpad = userWidgetsAll.filter(l => (l.originalUuid === w.uuId || (l.uuId === w.uuId) && l.owner === self.userId));
          if (ourlaunchpad.length === 0) {
            // the launchpad is not one of ours and we also do not have a copy of it
            w.originalUuid = w.uuId;
            delete w.uuId;
            toAdd.push(w);
          }
          else {
            ourlaunchpad[0].enable = w.enable;
            ourlaunchpad[0].x = w.x;
            ourlaunchpad[0].y = w.y;
            ourlaunchpad[0].w = w.w;
            ourlaunchpad[0].h = w.h;
            object.splice(index, 1);
          }
        }
        else if ((w.type === 'launchpad' || w.type === 'dataview') && w.owner === self.userId) {
          // is it one of our widgets that we have deleted?
          const myWidget = this.widgets.filter(wid => wid.i === w.i);
          if (myWidget.length === 0) {
            // remove the widget
            newWidgets.splice(index, 1);
          }
        }
      });
      
      for (const w of toAdd) {
        if (w.type === 'launchpad') {
          await this.launchpadCopy(w, this.userId);
        }
        else {
          await this.dataviewCopy(w, this.userId);
        }
      }
      
      // force reload
      this.widgets = [];
      this.layout = [];
      if (this.grid) {
        this.syncGridWithLayout();
      }
      
      // remove duplicates from the userWidgets
      for (let ii = userWidgets.length - 1; ii >= 0; ii--) {
        if (newWidgets.find(nw => nw.uuId === userWidgets[ii].uuId)) {
          userWidgets.splice(ii, 1);
        }
      }
      
      this.$nextTick(() => {
        this.widgets = [...newWidgets, ...userWidgets];
        this.layout = this.widgets.filter(s => s.enable);
        if (this.grid) {
          this.syncGridWithLayout()
        }
      
        // Save the new layout after applying it
        this.saveWidgets(view.name);
      });
    },
    savePreset() {
      const type = this.contextKey === 'home' ? 'dashboard' : 'project_dashboard';
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        type: type,
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId), 
        widgets: this.widgets
      };
      this.promptSaveShow = true;
    },
    applyViewName(widgets, userId, updateBreadcrumb) {
  
      const viewNameEntries = widgets.filter(w => w.type === 'viewName' && w.contextKey === this.contextKey);
      if (viewNameEntries.length !== 0) {
        if (updateBreadcrumb) {
          const viewName = viewNameEntries[0].label;
          this.$store.dispatch("breadcrumb/updateView", viewName, { root: true });
        }
            
        const toDeleteIds = [];
       
        // if we have multiple viewName entries we need to clean them up
        for (let count = 1; count < viewNameEntries.length; count++) {
          toDeleteIds.push( { uuId: viewNameEntries[count].uuId } );
        }
        if (toDeleteIds.length > 0) {
         dashboardProfileService.remove(toDeleteIds, userId)
        }
      }
      
    },
    async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          let nameFound = false;
          const list = await service.list(user, this.contextKey)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          for (const w of list) {
            // disable all widgets
            w.enable = false;
           
            if (w.type === 'viewName' && w.contextKey === this.contextKey) {
              w.label = profile.name;
              nameFound = true;
            }
          }
         
          this.applyViewName(list, user, false);
          
          const toAdd = [];
          
          // enable the widgets of this view
          const viewWidgets = profile.widgets.filter(w => w.enable);
          for (const w of viewWidgets) {
            const existingWidget = list.find(l => l.i === w.i && l.type === w.type);
            if (existingWidget) {
              existingWidget.enable = true;
              existingWidget.x = w.x;
              existingWidget.y = w.y;
              existingWidget.w = w.w;
              existingWidget.h = w.h;
            }
            else {
              // is this a user widget
              if ((w.type === 'launchpad' || w.type === 'dataview') &&
                 w.owner !== user) {
                const ourlaunchpad = list.filter(l => l.originalUuid === w.uuId || (l.uuId === w.uuId));
                if (ourlaunchpad.length === 0) {
                  // the launchpad is not one of ours and we also do not have a copy of it
                  w.originalUuid = w.uuId;
                  delete w.uuId;
                  toAdd.push(w);
                }
                else {
                  ourlaunchpad[0].enable = true;
                  ourlaunchpad[0].x = w.x;
                  ourlaunchpad[0].y = w.y;
                  ourlaunchpad[0].w = w.w;
                  ourlaunchpad[0].h = w.h;
                  list.push(ourlaunchpad[0]);
                }
              }
              else {
                list.push(w);
              }
            }
          }
          
          
          for (const w of toAdd) {
            if (w.type === 'launchpad') {
              list.push(await this.launchpadCopy(w, user));
            }
            else {
              list.push(await this.dataviewCopy(w, user));
            }
          }
          
          if (!nameFound) {
            await service.create([{ type: 'viewName', label: profile.name, contextKey: this.contextKey }], user);
          }
         
          await service.update(list, user)
          .then(response => {
            return response.data[response.data.jobCase];
          });
        }
      }
    },
    async confirmSaveOk({ profile, newDefault, updateUsers }) {
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.dashboardViews.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, dashboardProfileService);
      
      if (this.saveIndex !== -1) {
        this.dashboardViews.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (profile.sharingMembers.length > 1 ||
          profile.sharedVisibility === 'public') {
        // check for private data views
        const dataviews = profile.widgets.filter(w => w.type === 'dataview');
        if (dataviews.length > 0) {
          const userId = this.$store.state.authentication.user.uuId;
          const profiles = await this.$store.dispatch('data/dataViewProfileList', userId).then((response) => {
            const profileData = response;
            const privateProfiles = profileData.filter(f => f.sharedVisibility !== 'public').sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            return privateProfiles;
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          
          for (const dataview of dataviews) {
            const dvprofile = profiles.filter(p => p.uuId === dataview.dataview.uuId);
            if (dvprofile.length > 0) {
              // it is not a public data view so check the sharing members
              const members = dvprofile[0].sharingMembers.split(",");
              const viewMembers = profile.sharingMembers.split(",");
              for (const member of viewMembers) {
                // If this is not this user
                if (member !== userId && !members.includes(member)) {
                  // The data view is not shared with this member
                  this.memberAlertName = dvprofile[0].name;
                  this.memberAlertShow = true;
                  return;
                }
              }
            }
          }
        }
      }
    
    
      // Save the new layout after applying it
      this.saveWidgets(profile.name);
    },
    addViews(views) {
      const type = this.contextKey === 'home' ? 'dashboard' : 'project_dashboard';
      for (const view of views) {
        // if not in the list, add it
        if (view.type === type &&
            this.dashboardViews.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.dashboardViews.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
        }
      }
      
      this.dashboardViews.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    updateColumnSettings(index, name, profile) {
      const type = this.contextKey === 'home' ? 'dashboard' : 'project_dashboard';
      this.saveName = name;
      this.saveProfile = { 
        name: profile.name,
        uuId: profile.uuId,
        defaultView: profile.defaultView,
        type: type,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        widgets: this.widgets
      };
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings( name, profile) {
      const type = this.contextKey === 'home' ? 'dashboard' : 'project_dashboard';
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: type,
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        widgets: profile.widgets
      };
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      const toRemove = this.dashboardViews.splice(this.deleteViewIndex, 1);
      
      viewProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {  
                        //
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    },
    async loadUserProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    
      const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    printDiv() {
      var appHeader = document.querySelector('.app-header');
      var appHeaderDisp = appHeader.style.display;
      appHeader.style.display = 'none';

      var sidebar = document.querySelector('.sidebar');
      var sidebarDisp = sidebar.style.display;
      sidebar.style.display = 'none';
      
      var breadcrumb = document.querySelector('.breadcrumb');
      var breadcrumbDisp = breadcrumb.style.display;
      breadcrumb.style.display = 'none';
      
      var dashboardbuttons = document.querySelector('.dashboard-buttons');
      var dashboardbuttonsDisp = dashboardbuttons.style.display;
      dashboardbuttons.style.display = 'none';
      
      var gridStack = document.querySelector('.grid-stack');
      var gridStackHeight = gridStack.offsetHeight;
      
      var items = document.querySelectorAll('.grid-stack-item');
      const itemTop = [];
      var totalOffset = 600;
      for (const item of items) {
        itemTop.push(item.offsetTop);
        if ((item.offsetTop + item.offsetHeight >= 1955 &&
            item.offsetTop <= 1900) ||
            item.offsetTop > 1955) {
          item.style.top = `${item.offsetTop + 600}px`;
        }
        
        if ((item.offsetTop + item.offsetHeight >= 3855 &&
            item.offsetTop <= 3855) ||
            item.offsetTop > 3855) {
          item.style.top = `${item.offsetTop + 1200}px`;
          totalOffset = 1200;
        }
      }
      
      gridStack.style.height = `${gridStackHeight + totalOffset}px`;
      var body = document.querySelector('body');
      body.style.width = '1500px';
      
      setTimeout(() => {
        window.print();
        appHeader.style.display = appHeaderDisp;
        sidebar.style.display = sidebarDisp;
        breadcrumb.style.display = breadcrumbDisp;
        dashboardbuttons.style.display = dashboardbuttonsDisp;
        
        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          item.style.top = `${itemTop[j]}px`;
        }
        gridStack.style.height = `${gridStackHeight}px`;
        body.style.width = '';
      }, 500);
      
    },
    onInfoOver(index) {
      profileService.nodeList(this.dashboardViews[index].uuId).then((response) => {
        this.dashboardViews[index].owner = response.data.resultList.filter(v => this.dashboardViews[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    }
  },
}
</script>