import Vue from 'vue';
import { 
  managementService, 
  colorProfileService,
  viewProfileService, 
  templateProjectService, 
  projectService, 
  dataviewProfileService ,
  taskViewService,
  templateTaskService,
  folderService,
  accessPolicyService,
  enumService,
  webhookService
} from "@/services";

export const data = {
  namespaced: true,
  state: { 
    version: null,
    versionExpirey: null
  },
  actions: {
    info({ dispatch, commit }, params ) {
      const self = this;
      const now = new Date().getTime();
      if (this.state.data.version === null ||
          this.state.data.versionExpirey < now) {

        const versionPromise = managementService
          .status()
          .then(response => {
            const value = response.data.version;
            if (typeof self.state.data.version === 'string' &&
                value !== self.state.data.version) {
              Vue.set(self.state, data, {});
            }
            Object.freeze(value);
            commit('update', {name: 'version', value: value});
            commit('update', {name: 'versionExpirey', value: now + 86400000});
            return value;
          })
          .catch(e => {
            if (e.response.status !== 401) {
              dispatch("alert/error", e, { root: true });
            }
          });
        Object.freeze(versionPromise);
        commit('update', {name: 'version', value: versionPromise});
        commit('update', {name: 'versionExpirey', value: now + 50000});
      }
      
      // use the object name of TASK, COMPANY, etc if specified otherwise use
      // type predicate, macro, etc
      const name = params.object ? params.object : params.type;
      if (this.state.data[name]) {
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name]);
        });
      }
      
      const promise = managementService
        .info(params)
        .then(response => {
          const value = response.data[response.data.jobCase];
          commit('update', {name: name, value: value})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
          }
        });
      commit('update', {name: name, value: promise})
      return promise;
    },
    status({ dispatch, commit }) {
      const name = 'status'
      if (this.state.data[name]) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name]);
        });
      }
      
      const promise = managementService
        .status()
        .then(response => {
          const value = response.data;
          commit('update', {name: name, value: value})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
          }
        });
      commit('update', {name: name, value: promise})
      return promise;
    },
    viewProfileList({ dispatch, commit }, userId) {
      const name = `viewProfile${userId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = viewProfileService.list(userId)
        .then(response => {
          const value = response.data[response.data.jobCase];
          
          // clean up view profiles
          if (value.length > 1) {
            const toDelete = [];
            // log the event in the json
            value[0].cleanupDate = Date();
            value[0].cleanupCount = value[0].cleanupCount ? value[0].cleanupCount + 1 : 1;
            
            for (let i = 1; i < value.length; i++) {
              toDelete.push({ uuId: value[i].uuId });
              for (const key of Object.keys(value[i])) {
                if (!(key in value[0])) {
                  value[0][key] = value[i][key];
                }
              }
            }
            viewProfileService.update([value[0]], userId);
            viewProfileService.remove(toDelete, userId);
          }
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return viewProfileService.list(userId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    colorProfileList({ dispatch, commit }, companyId) {
      const name = `colorProfile${companyId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = colorProfileService.list(companyId)
        .then(response => {
          const value = response.data[response.data.jobCase];
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return colorProfileService.list(companyId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    presetviewProfileList({ dispatch, commit }, userId) {
      const name = `presetviewProfile${userId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = viewProfileService.listPreset(userId)
        .then(response => {
          const value = response.data[response.data.jobCase];
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return viewProfileService.listPreset(userId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    viewProfileListPublic({ dispatch, commit }, companyId) {
      const name = `viewProfile${companyId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = viewProfileService.listPublic(companyId)
        .then(response => {
          const value = response.data[response.data.jobCase];
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return viewProfileService.listPublic(companyId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    clearDataViewProfileCache({dispatch, commit }, userId, companyId) {
      commit('clearTtl', {name: `dataViewProfileList${userId}`});
      commit('clearTtl', {name: `dataViewProfileListPublic${companyId}`});
    },
    dataViewProfileList({ dispatch, commit }, userId) {
      const name = `dataViewProfileList${userId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = dataviewProfileService.list(userId)
        .then((response) => {
          const value = response.data[response.data.jobCase];
          const d = {name: name, value: value, ttl: 5000}
          Object.freeze(d);
          commit('updateTtl', d);
          // commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return dataviewProfileService.list(userId);
                }, timeout);
              });
            }
          }
        });
      Object.freeze(promise);
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    dataViewProfileListPublic({ dispatch, commit }, uuId) {
      const name = `dataViewProfileListPublic${uuId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = dataviewProfileService.listPublic(uuId)
        .then((response) => {
          const value = response.data[response.data.jobCase];
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return dataviewProfileService.listPublic(uuId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    projectGet({ dispatch, commit }, payload) {
      const projectIds = payload.projectIds || [];
      const links = payload.links || null;
      const macros = payload.macros || null;

      const name = `projectGet${projectIds.map(p => { return p.uuId }).join()}${links ? links.join() : ''}${macros ? macros.join() : ''}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = projectService.get(projectIds, links, macros)
        .then(response => {
          commit('updateTtl', {name: name, value: response.data, ttl: 5000})
          return response.data;
        })
        .catch(e => {
          if (e != null && e.response != null && e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return projectService.get(projectIds, links, macros);
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    templateProjectGet({ dispatch, commit }, payload) {
      const projectIds = payload.projectIds || [];
      const links = payload.links || null;
      const macros = payload.macros || null;
      const name = `templateProjectGet${projectIds.map(p => { return p.uuId }).join()}${links ? links.join() : ''}${macros ? macros.join() : ''}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = templateProjectService.get(projectIds, links, macros)
        .then(response => {
          commit('updateTtl', {name: name, value: response.data, ttl: 5000})
          return response.data;
        })
        .catch(e => {
          if (e != null && e.response != null && e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return templateProjectService.get(projectIds, links, macros);
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    taskListTree({ dispatch, commit }, payload) {
      const params = payload.params != null? payload.params : { start: 0, limit: -1 };
      const projectId = payload.projectId != null? payload.projectId : null;

      let name = `taskListTree${projectId}`;
      if (params.start != null) {
        name = name.concat(params.start);
      }
      if (params.limit != null) {
        name = name.concat(params.limit); 
      }
      
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = taskViewService.listTree(params, projectId, [])
        .then(response => {
          const value = response;
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return taskViewService.listTree(params, projectId, []);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    taskListSummaryTree({ dispatch, commit }, payload) {
      const params = payload.params != null? payload.params : { start: 0, limit: -1 };
      const projectId = payload.projectId != null? payload.projectId : null;

      let name = `taskListSummaryTree${projectId}`;
      if (params.start != null) {
        name = name.concat(params.start);
      }
      if (params.limit != null) {
        name = name.concat(params.limit); 
      }
      
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = taskViewService.listSummaryTree(params, projectId, [])
        .then(response => {
          const value = response;
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return taskViewService.listSummaryTree(params, projectId, []);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    templateTaskListTree({ dispatch, commit }, payload) {
      const params = payload.params != null? payload.params : { start: 0, limit: -1 };
      const projectId = payload.projectId != null? payload.projectId : null;
      const showFullDetails = payload.showFullDetails != null? payload.showFullDetails : false;

      let name = `templateTaskListTree${projectId}${showFullDetails}`;
      if (params.start != null) {
        name = name.concat(params.start);
      }
      if (params.limit != null) {
        name = name.concat(params.limit); 
      }
      
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = templateTaskService.listTree(params, projectId, showFullDetails)
        .then(response => {
          const value = response;
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return templateTaskService.listTree(params, projectId, showFullDetails);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    folderList({ dispatch, commit }, folderId) {
      const name = `folderList${folderId}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = folderService.get(folderId)
        .then(response => {
          const value = response.data;
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return folderService.get(folderId);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    enumPermission({ dispatch, commit }) {
      const name = 'enumPermission';
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = enumService.getPermission()
        .then(response => {
          const value = response.data;
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return enumService.getPermission();
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    enumList({ dispatch, commit }) {
      const name = 'enumList';
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = enumService.list()
        .then(response => {
          const value = response.data;
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return enumService.list();
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    schemaAPI({ dispatch, commit }, params) {
      
      const name = `schemaAPI`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = managementService
        .info(params)
        .then(response => {
          const value = response.data[response.data.jobCase];
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return managementService.info(params);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    whitelist({ dispatch, commit }, params) {
      
      const name = `whitelist${JSON.stringify(params)}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = managementService
        .info(params)
        .then(response => {
          const value = response.data[response.data.jobCase];
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return managementService.info(params);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    schemaPassword({ dispatch, commit }, params) {
      
      const name = `schemaPassword`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = managementService
        .info(params)
        .then(response => {
          const value = response.data[response.data.jobCase];
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return managementService.info(params);
                }, timeout);
              });
            }
          }
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    accessPolicyGet({ dispatch, commit }, payload) {
      const accessPolicyIds = payload.accessPolicyIds || [];
      const links = payload.links || null;
      const macros = payload.macros || null;
      const name = `accessPolicyGet${accessPolicyIds.map(p => { return p.uuId }).join()}${links ? links.join() : ''}${macros ? macros.join() : ''}`;
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }

      const promise = accessPolicyService.get(accessPolicyIds, links, macros)
        .then(response => {
          commit('updateTtl', {name: name, value: response.data, ttl: 5000})
          return response.data;
        })
        .catch(e => {
          if (e != null && e.response != null && e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return accessPolicyService.get(accessPolicyIds, links, macros);
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
    webhookEntityOptions({ dispatch, commit }, payload) {
      const name = 'webhookEntityOptions';
      const now = new Date().getTime();
      if (this.state.data[name] &&
          this.state.data[name].expiry > now) {
        const self = this;
        return new Promise((resolve, reject) => {
          resolve(self.state.data[name].value);
        });
      }
      
      const promise = webhookService.getEntityOptions()
        .then(response => {
          const value = response.data;
          
          commit('updateTtl', {name: name, value: value, ttl: 5000})
          return value;
        })
        .catch(e => {
          if (e.response.status !== 401) {
            dispatch("alert/error", e, { root: true });
            if (e.response.status === 429) {
              const timeout = e.response.headers['X-Rate-Limit-Retry-After-Seconds'] * 1000;
              return new Promise(() => {
                setTimeout(() => {
             
                  return webhookService.getEntityOptions();
                }, timeout);
              });
            }
          }
          throw e;
        });
      commit('updateTtl', {name: name, value: promise, ttl: 5000})
      return promise;
    },
  },
  mutations: {
    update(state, params) {
      Vue.set(this.state.data, params.name, params.value);
    },
    updateTtl(state, params) {
      const now = new Date();

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: params.value,
        expiry: now.getTime() + params.ttl,
      }
      Vue.set(this.state.data, params.name, item);
    },
    clearTtl(state, params) {
      Vue.set(this.state.data, params.name, null);
    }
  }
};
