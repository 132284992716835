<template>
  <div class="edit-cell">
    <ColorModal :show="colorShow"
      :color="value"
      @cancel="closePicker"
      @changed="changed"
      :update="update"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';
// import { tagService } from '@/services';

// const KEY_BACKSPACE = 8;
// const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default Vue.extend({
  name: 'ColorEditor',
  components: {
    ColorModal: () => import('@/components/modal/ColorModal.vue')
  },
  data() {
    return {
      value: '',
      cancelBeforeStart: false,
      // highlightAllOnFocus: true,
      compactMode: false,
      colorShow: true,
      update: null
    };
  },
  methods: {
    isPopup() {
      return true;
    },
    getValue() {
       if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;

        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length) > 0 || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (const t of orgValue) {
          if (!curValue.includes(t)) {
            hasChanged = true;
            break;
          }
        }
        
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      this.value = this.compactMode? objectClone(params.value.single) : params.value;
      // this.highlightAllOnFocus = true;
    },
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    changed(val) {
      this.value = val;
      this.update = val;
      this.closePicker();
    }
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.setInitialState(this.params);
  }
})
</script>

<style lang="scss">

</style>
