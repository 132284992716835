<template>
  <div class="edit-cell">
    <span>{{ label }}</span>
    <TaskTemplateSelectorModal :show.sync="selectorShow" :multiple="true" @ok="selectorOk" :selectedIds="selectedIds"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'TaskTemplateEditor',
  components: {
    TaskTemplateSelectorModal: () => import('@/components/modal/TaskTemplateSelectorModal')
  },
  data() {
    return {
      selectorShow: true,
      value: null,
      selectedIds: []
    };
  },
  watch: {
    selectorShow(newValue) {
      if(!newValue) {
        this.closePicker();
      }
    }
  },
  methods: {
    /** Reserved methods START */
    getValue() {
      //Determine if there is a change. If no, return original value to avoid trigger onCellValueChanged event.
      if (typeof this.params.value === 'object') {
        const oldUuIds = this.params.value.uuIds != null? this.params.value.uuIds : [];
        if (oldUuIds.length != this.value.uuIds.length) {
          return this.value;
        }
        const uuIds = this.value.uuIds;
        for (const uuId of oldUuIds) {
          if (!uuIds.includes(uuId)) {
            return this.value;
          }
        }
        return this.params.value; //return original value;
      }
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    /** Reserved methods END */

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    selectorOk({ details }) {
      if(details.length > 0 && this.value.uuId != details[0].uuId) {
        const newIds = details.map(i => i.uuId);
        const newNames = details.map(i => i.name)
        
        this.value.uuIds = newIds;
        
        if(this.value.names != null) {
          this.value.names.splice(0, this.value.names.length, ...newNames);
        } else {
          this.value.names = newNames;
        }
      } else { 
        //Empty selection is made (User choose to deselect the value), clean up the values (uuIds and names).
        if (this.value.uuIds != null) {
          this.value.uuIds.splice(0, this.value.uuIds.length);
        }
        if (this.value.names != null) {
          this.value.names.splice(0, this.value.names.length);
        }
      }
      this.closePicker();
    },
    selectorCancel() {
      this.closePicker();
    },
    prepareValue() {
      if (typeof this.params.value === 'object') {
        this.value = objectClone(this.params.value);
      } else if (typeof this.params.value === 'string') {
        this.value = { uuIds: this.params.value };
      } else {
        this.value = { uuIds: null, names: null }
      }
      this.selectedIds = this.value != null && this.value.uuIds != null && this.value.uuIds.length > 0? this.value.uuIds : [];
    }
  },
  created() {
    this.prepareValue();
  },
  mounted() {

  },
  computed: {
    label() {
      return this.value && this.value.names? this.value.names.join(', '): '';
    }
  }
})
</script>