
import { httpAjax, urlParams } from '@/helpers';
import axios from 'axios'

export const fileService = {
  upload,
  extract,
  download,
  multidownload,
  downloadUrl,
  get,
  list,
  update,
  remove,
  getCancelTokenSource,
  query,
  properties
};

function getCancelTokenSource() {
  return axios.CancelToken.source();
}

/**
 * Create a new file 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Mandatory field.
 *  2. file: Blob/File Object; Mandatory field.
 *  3. notes: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. accessLevel: String. Possible value: ['Private','Public']. Default to 'Private' if not supplied.
 * @param progress a function to process upload progress event
 */
function upload(formData, progress) {
  const url = '/api/file/upload';
  const config = {
    headers: getHeaders(),
    onUploadProgress: progress
  };
  return httpAjax.post(url, formData, config);
}

/**
 * extracts a table from a file 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. file: Blob/File Object; Mandatory field.
 * @param progress a function to process upload progress event
 */
function extract(formData, progress) {
  const url = '/api/document/extract';
  const config = {
    headers: getHeaders(),
    onUploadProgress: progress
  };
  return httpAjax.post(url, formData, config);
}

/**
 * downloads a file from a url 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. file: Blob/File Object; Mandatory field.
 * @param progress a function to process upload progress event
 */
function downloadUrl(source) {
  const url = `/api/document/download?source=${source}`;
  const config = {
    headers: getHeaders(),
    responseType:"arraybuffer"
  };
  return httpAjax.get(url, config);
}

/**
 * Retrieving a list of file details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of file
 * @param progress a function to process upload progress event
 * @param cancelToken Axios Cancel Token's token, to support user cancel action
 */
function download(data, progress, cancelToken) {
  const url = '/api/file/download';
  const config = {
    responseType: 'blob',
    headers: getHeaders(),
    onDownloadProgress: progress,
    cancelToken: cancelToken
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of file details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of file
 * @param progress a function to process upload progress event
 * @param cancelToken Axios Cancel Token's token, to support user cancel action
 */
function multidownload(data, progress, cancelToken) {
  const url = '/api/file/multidownload';
  const config = {
    responseType: 'blob',
    headers: getHeaders(),
    onDownloadProgress: progress,
    cancelToken: cancelToken
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a list of file details by 
 * passing in an array list of uuIds as arguments
 * @param uuId uuId of file
 */
function get(uuId) {
  const url = '/api/file';
  const config = {
    responseType: 'blob',
    
  };
  return httpAjax.get(`${url}/${uuId}`, config);
}

/**
 * Retrieving a list of file info (file content excluded)
 * @param parentId parent UuId
 */
function list(parentId, links = null) {
  const url = urlParams('/api/file/get', links);
  const config = {
    params: {
      uuId: parentId
    },
    headers: getHeaders()
  }
  return httpAjax.get(url, config);
}


/**
 * update an  existing file 
 * by passing necessary information
 * @param FormData formData 
 * Note: Possible properties for formData: 
 *  1. name: String; Optional field.
 *  2. file: Blob/File Object; Optional field.
 *  3. notes: String; Optional field.
 *  4. checksum: md5 hash. Optional field. If supply, backend validation will kick in.
 *  5. accessLevel: String. Possible value: ['Private','Public']
 *  6. uuId: target uuId; Mandatory field.
 * @param progress a function to process upload progress event
 */
function update(data, progress) {
  const url = '/api/file/update';
  const config = {
    headers: getHeaders(),
    onUploadProgress: progress
  }
  return httpAjax.put(url, data, config);
}

/**
 * update an  existing file properties
 * by passing necessary information
 * @param json
 * Note: Possible properties for formData: 
 *  1. uuId: target uuId; Mandatory field.
 */
function properties(data) {
  const url = '/api/file/properties';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a file
 * by passing in uuId
 * @param String uuId 
 * e.g 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
 */
function remove(uuId) {
  const url = `/api/file/delete/${uuId}`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.delete(url, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}


function query(fileIds) {
  const fields = {
    fileId: ["STORAGE_FILE.uuId"]
    , name: ["STORAGE_FILE.name"]
  }

  let data = {
    'name': 'File Details List'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'select': Object.keys(fields).map(i => fields[i])
    , 'holder': fileIds
  }

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    return mappedData;
  });
}