/* 
  import of DHTMLX Gantt is required.
*/
export const ganttScaleUtil = {
  create
}

/**
 * Configure the gantt's date scale with provided scale level
 * @param {*} g     Gantt instance
 * @param {*} level Scale level. Possible value: hour|day|week|month
 */
function create(g, level) {
  g.config.subscales = [];
  g.config.min_column_width = 50;
  g.config.date_scale = '%Y';
  g.config.scale_unit = 'year';
  g.config.skip_off_time = false;

  switch (level) {
    case 'hour':
      g.config.skip_off_time = true;
      g.config.scale_unit = 'day';
      g.config.date_scale = '%d %M %Y';
      g.config.subscales.push({ unit: 'hour', step: 1, date: '%H:%i' });
      break;
    case 'day':
      g.config.scale_unit = 'month';
      g.config.date_scale = '%M %Y';
      g.config.subscales.push({ unit: 'day', step: 1, date: '%d' });
      break;
    case 'week': {
      const dateToStr = g.date.date_to_str('%d %M');
      const weekScaleTemplate = function (date) {
        const endDate = g.date.add(g.date.add(date, 1, 'week'), -1, 'day');
        return `${dateToStr(date)} - ${dateToStr(endDate)}`;
      };
      g.config.subscales.push({
        unit: 'week',
        step: 1,
        template: weekScaleTemplate
      });
      break;
    }
    case 'month':
      g.config.subscales.push({ unit: 'month', step: 1, date: '%F, %Y' });
      break;
  }
}