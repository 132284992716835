<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { convertDurationToDisplay } from '@/helpers/task-duration-process';

export default Vue.extend({
  name: 'MinuteDurationCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    this.value = convertDurationToDisplay(this.params.value / 60000, 'D');
  },
  computed: {
    label() {
      return this.value? this.value : '';
    }
  }
})
</script>
