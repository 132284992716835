var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "company" }, [
        _vm.avatarUrl !== null &&
        _vm.canView("STORAGE_FILE") &&
        _vm.canView("COMPANY", ["STORAGE_FILE"])
          ? _c("img", {
              staticClass: "img-avatar",
              attrs: { src: _vm.avatarUrl },
            })
          : _vm._e(),
        _c("span", { staticClass: "d-md-inline" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "edit",
            class: _vm.avatarUrl !== null ? "has-image" : "",
          },
          [
            _c(
              "div",
              {
                staticClass: "text",
                attrs: { id: "changecompanybtn" },
                on: { click: _vm.selector },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: ["far", "pen"] } }),
                _c("b-popover", {
                  attrs: {
                    target: "changecompanybtn",
                    placement: "top",
                    triggers: "hover",
                    content: _vm.$t("button.change_company"),
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("CompanySelectorModal", {
        attrs: {
          show: _vm.selectorShow,
          preselected: _vm.settings.company ? _vm.settings.company.uuId : null,
          title: _vm.selectorTitle,
        },
        on: {
          "update:show": function ($event) {
            _vm.selectorShow = $event
          },
          ok: _vm.selectorSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }