
import { httpAjax } from "@/helpers";

export const queryService = {
  run,
  query
};

/**
 * Retrieving a list of data dictacted by installed query.
 * @param {String} Query uuId
 * @param {Array}  data
 * e.g. {staff: "XXXXXXXX-XXXX-XXXXXXX" }
 * @param {Array}  params
 * e.g. {params: {start: 0, limit: 10 }}
 * TODO: try to get more details from chris regarding available request content
 */
function run(uuId, data = {}, params = {}) {
  const url = `/api/query/run/${uuId}`;
  const config = {
    params: params,
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving results from a query.
 * @param {Array}  params
 * @param {Array}  fields
 * @param {Array}  filter
 * @param {Array}  nominate
 * e.g. {params: {start: 0, limit: 10 }}
 */
function query(params, fields, filter, nominate, dedup, leftjoin) {
  const url = '/api/query/match';
  const config = {
    params: params,
    headers: getHeaders()
  };

  let data = {
    'name'  : params.name ? params.name : 'Query'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'timeout': 300
    ,'sep_array' : ' / '
    ,'select': Object.keys(fields).map(i => typeof fields[i] === 'string' ? [fields[i]] : fields[i])
  }

  if (filter !== null) {
      data['filter'] = filter;
  }
  
  data[nominate.type] = nominate.field;

  if (dedup) {
    data['dedup'] = dedup;
  }
  
  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort) && Array.isArray(params.order)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        data['sort'].push([params.ksort[i], params.order[i], '', 'lowerCase']);
      }
    } 
    else if (Array.isArray(params.ksort) && ((typeof params.ksort[0] === 'string' && !params.ksort[0].startsWith('=')) ||
             typeof params.ksort[0] === 'object')) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        if (typeof params.ksort[i] === 'object') {
          if (typeof params.ksortType !== 'undefined' && params.ksortType !== null && params.ksortType[i] === 'String') {
            data['sort'].push([params.ksort[i].field, params.ksort[i].sortdirection, '', 'lowerCase']);
          }
          else {
            data['sort'].push([params.ksort[i].field, params.ksort[i].sortdirection]);
          }
        }
        else {
          if (params.ksortType && params.ksortType[i] === 'String') {
            data['sort'].push([params.ksort[i], 'incr', '', 'lowerCase']);
          }
          else {
            data['sort'].push([params.ksort[i], 'incr']);
          }
        }
      }
    }
    else if (params.ksortType === 'String') {
      data['sort'].push([params.ksort, params.order, '', 'lowerCase']);
    }
    else if (params.ksortAgFunc !== null) {
      if (params.ksortAgFunc === 'year' ||
        params.ksortAgFunc === 'month' ||
        params.ksortAgFunc === 'day' ||
        params.ksortAgFunc === 'cos' ||
        params.ksortAgFunc === 'sin' ||
        params.ksortAgFunc === 'tan') {
        data['sort'].push([params.ksort, params.order, params.ksortAgFunc]);
      }
      else {
        if (params.ksortAgFunc === 'count') {
            const parts = params.ksort.split('.');
            parts.splice(parts.length - 1, 1);
            params.ksort = `${parts.join('.')}.uuId`;
          data['sort'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
          data['select'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
        }
        else {
          data['sort'].push([params.ksort, params.order, 0, params.ksortAgFunc]);
        }
      }
    }
    else if (params.ksort[0] === "=fullPath(A)") {
      data['sort'].push([params.ksort, params.order, '', 'rcombine']);
    }
    else {
      data['sort'].push([params.ksort, params.order]);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.ksortType;
  delete params.ksortAgFunc;
  delete params.order;
  delete params.filter;
  delete params.name;
  
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}
