<template>
  <div ref="paged-aggrid-gantt-container" class="position-relative">
    <GanttActionBar
      :readOnly="isAccessDenied" 
      v-if="!isWidget"
      :allowManage="allowManage"
      :actionProcessing="actionProcessing"
      :disableEdit="disableEdit"
      :disableDelete="disableDelete"
      
      @task-new-open="taskNewHandler"
      @task-edit-open="taskEditHandler"
      @row-delete="rowDeleteHandler"
      @file-export="fileExportHandler"

      :hasAutoAssignTasks="hasAutoAssignTasks"
      @auto-assign-staff="autoAssignStaffHandler"

      :dates="control.dates" 
      :startDate="control.startDate"
      :endDate="control.endDate"
      :timescale.sync="control.timescale"
      :criticalPath.sync="control.criticalPath"
      :freeFloat.sync="control.freeFloat" 
      hideOptProjectStartToEnd
      hideOptProjectSchedule
      :hideOptTaskSchedule="false"
      :isTemplate="false"
      @datesChanged="handleControlDates"

      hideMasterCheckBox

      hideTaskOutdent
      hideTaskIndent
      hideTaskCopy
      hideTaskPaste
      hideTemplateApply
      hideTemplateSave
      hideFileImport
      hideScheduleMenu
      hideCriticalPath
      hideFreeFloat
      hideTaskCollapse
      hideTaskExpand
      hideSearch
      hideScheduling
      :hideTaskAdd="!canList('PROJECT')"
      :views="ganttViews"
      @load-view-settings="loadViewSettings"
      @copy-columns="copyColumnSettings"
      @share-columns="shareColumnSettings"
      @update-columns="updateColumnSettings"
      @remove-columns="removeColumnSettings"
      @save-columns="savePreset"
      @all-columns="showAllColumns"
      @no-columns="showNoColumns"
      @color-change="onColorChange"
      :coloring="coloring"

      @startDateChanged="actionBarDateChanged($event, { isStartDate: true })"
      @endDateChanged="actionBarDateChanged($event, { isStartDate: false })"
    />

    <div class="splitter-container" ref="splitter-container" :style="splitterStyle">
      <div class="lhs-grid" ref="lhs-grid">
        <ag-grid-vue style="" class="ag-theme-balham task-grid-height" id="paged-grid" :style="lhsGridStyle"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          animateRows
          :autoGroupColumnDef="autoGroupColumnDef"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :getMainMenuItems="getMainMenuItems"

          noRowsOverlayComponent="noRowsOverlay"
          :noRowsOverlayComponentParams="noRowsOverlayComponentParams"

          :overlayLoadingTemplate="overlayLoadingTemplate"
          :getRowId="params => params.data.uuId"
          :headerHeight="34"
          rowMultiSelectWithClick="false"
          rowSelection="multiple"
          :sideBar="false"
          suppressContextMenu
          suppressDragLeaveHidesColumns
          :suppressCellFocus="false"
          :singleClickEdit="false"
          :enableRangeSelection="true"
          :enableFillHandle="true"
          :fillOperation="fillOperation"
          fillHandleDirection="xy"
          :processCellForClipboard="processCellForClipboard"
          :processCellFromClipboard="processCellFromClipboard"
          :navigateToNextCell="navigateToNextCell"
          :tabToNextCell="tabToNextCell"
          suppressMultiSort
          suppressScrollOnNewData
          suppressRowClickSelection
          suppressRowDrag
          suppressClipboardApi
          rowModelType="serverSide"
          pagination
          :paginationPageSize="paginationPageSize"
          :cacheBlockSize="cacheBlockSize"
          :maxBlocksInCache="maxBlocksInCache"
          :serverSideInfiniteScroll="true"
          
          @cell-key-down="onCellKeyDown"
          @paste-start="onPasteStart"
          @paste-end="onPasteEnd"
          @cell-focused="cellFocused"
          enableCellEditingOnBackspace

          @pagination-changed="onPaginationChanged"
        />
        
      </div>
      <div class="resizer" ref="resizer" id="grid-gantt-resizer" @mousedown="mouseDownHandler">
        <div class="resizer-overlay" ref="resizer-overlay" id="resizer-overlay">
          <font-awesome-icon class="resizer-icon" :icon="['far', 'arrows-left-right']"/>
        </div>
      </div>

      <!-- Gantt Chart -->
      <div class="rhs-chart" ref="rhs-chart">
        <DhtmlxGantt
          :scrollY="scrollState.top"
          :scrollX.sync="scrollState.left"
          :height="ganttHeight"
          :router="routerObj"
          :ganttData="ganttData"
          :startDate="control.startDate"
          :endDate="control.endDate"
          :timescale="control.timescale"
          :criticalPath="control.criticalPath"
          :freeFloat="control.freeFloat"
          :defaultColoring="coloring.none"

          :pendingRerender.sync="pendingRerender"
          :enableProjectColumn="true"
          :enableTaskPathColumn="true"
          @taskNew="taskNewHandler"
          @taskEdit="taskEditHandler"
          @taskLinkEdit="linkEditHandler"
          
          @ganttScroll="ganttScrollHandler"
          @taskUpdated="taskUpdatedHandler"
          @taskLinkUpdated="taskLinkUpdatedHandler"
          @taskLinkCreated="taskLinkUpdatedHandler"
          @taskLinkUpdateError="refreshData"
          @taskLinkCreateError="refreshData"
          @taskUpdateError="refreshData"

          @taskSelectionChanged="taskSelectionChangedHandler"
          @taskClicked="taskClickedHandler"
          @cellFocused="taskCellFocusedHandler"

          :collapseId="collapseId"
          :expandId="expandId"

          :selectedTasks="taskSelectionIds"
          :readOnly="!canEdit('TASK') || ganttReadOnly || ganttInsufficientEditPermission"

          :calendar="calendar"

          :locationCalendarMap="locationCalendarMap"

          :toDeleteTaskIds="ganttDeleteTaskIds"
        />
      </div>
    </div>

    <TaskModal mode="BOTH"
      :id.sync="taskEdit.uuId"
      :isTemplate="false"
      :parentId="taskEdit.parentId"
      :projectId="taskEdit.projectId"
      :show.sync="state.taskShow"
      @success="taskEditSuccess" 
    />

    <!-- In Progress Modal -->
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>

    <TaskLinkModal 
      :show.sync="state.taskLinkEditShow" 
      :taskId="taskLinkEdit.taskId"
      :predecessorId="taskLinkEdit.predecessorId"
      :isTemplate="false"
      @success="taskLinkEditSuccess"
    />

    <ProjectSelectorModal 
      :show.sync="state.projectSelectorShow" 
      :selectedId="projectSelectorEdit.uuId"
      :multiple="false"
      @ok="projectSelectorOk"
    />

    <b-modal :title="$t('task.auto_assign.summary')"
        v-model="autoAssignSummaryShow"
        @ok="autoAssignSummaryOk"
        ok-only
        content-class="shadow"
        no-close-on-backdrop
        >
      <ul class="task-summary-list" v-if="autoAssignSummary.length > 0">
        <li class="d-block task-summary-list-item" v-for="(item,index) in autoAssignSummary" :key="index">
          <div>
            <div class="task-summary-title">{{ getPathNames(taskNames[item.taskUUID]) }}</div>
            <template v-if="item.staffAssignmentList.length > 0">
              <div  class="d-block" v-for="(staff, sIndex) in item.staffAssignmentList" :key="sIndex">
                {{ $t('task.auto_assign.assigned', [`${staff.firstName} ${staff.lastName}`]) }}
              </div>
            </template>
            <div v-if="item.staffAssignmentList.length === 0" class="d-block">
              {{ $t('task.auto_assign.none_assigned') }}
            </div>
          </div>
        </li>
      </ul>
      <div v-if="autoAssignSummary.length === 0" class="d-block">
        {{ $t('task.auto_assign.none_assigned') }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>

    <AutoAssignStaffModal 
      :projectId="project ? project.uuId : null" 
      :tasks="autoAssignTasks()"
      :show.sync="autoAssignStaffShow"
      @success="autoAssignStaffSuccess" />

    <b-modal :title="$t('task.confirmation.title_change_on_complete')"
        v-model="state.confirmChangeOnCompleteShow"
        @close="changeOnCompleteCancel"
        content-class="change-on-complete-modal shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.change_on_complete') }}
      </div>
      <template v-slot:modal-footer="{}">
        <b-form-checkbox v-if="processTaskMoveChangedList.length > 0" class="apply-to-all" v-model="applyAllChangeOnComplete">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="changeOnCompleteOk()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="changeOnCompleteCancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :taskName="durationCalculation.taskName"
      :defaultActionForNonWorkPrompt="durationCalculation.defaultActionForNonWorkPrompt"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :enableManualScheduleSuggestion="durationCalculation.enableManualScheduleSuggestion"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projectScheduleFromStart"
      :taskAutoScheduleMode="durationCalculation.taskAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :projectStartDateStr="durationCalculation.projectStartDateStr"
      :projectCloseDateStr="durationCalculation.projectCloseDateStr"
      :resizeMode="durationCalculation.resizeMode"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :clearPreviousChoice.sync="dcClearPreviousChoice"
      :showApplyAllCheckbox="dcShowApplyAllCheckbox"
      @success="durationCalculationOk"
      @skip="durationCalculationOk({ skip: true })"
      @cancel="durationCalculationCancel"
      @calendarChange="durationCalculationCalendarChange"
    />

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/>

    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="tcConfirmDeleteShow"
        @hidden="tcConfirmDeleteShow=false"
        @ok="tcConfirmDeleteOk"
        @cancel="tcConfirmDeleteCancel"
        content-class="task-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ tcConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="tcShowApplyAllCheckbox" class="apply-to-all" v-model="taskCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

import alertStateEnum from '@/enums/alert-state';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { cloneDeep,debounce } from 'lodash';
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, columnDefSortFunc } from '@/views/management/script/common'
import { 
  strRandom
  , msToTime
  , objectClone
  , invertColor
  , getFirstColor
  , toComplimentary
  , processCalendar
  , transformCalendar
} from '@/helpers';
import DhtmlxGantt from '@/components/Gantt/DhtmlxGanttWithNoGrid';
import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';
import { TaskViewRequestGenerator } from '@/components/Task/script/task.view.request.generator';
import { TaskViewPropertyUtil } from '@/components/Task/script/task.view.property.util';
import { 
  taskService
  , taskLinkSuccessorService
  , aggridGanttService
  , companyService
  , staffService
  , locationService
  , calendarService
  , layoutProfileService
  , viewProfileService
  , tagService
  , compositeService
} from '@/services';
import { getCustomFieldInfo, prepareCustomFieldColumnDef, handleCustomFieldError } from '@/helpers/custom-fields';
import { prepareMovedTasks } from './script/common';

import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/RowSelector';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import TaskConstraintCellRenderer from '@/components/Aggrid/CellRenderer/TaskConstraint';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import TaskSkillCellRenderer from '@/components/Aggrid/CellRenderer/TaskSkill';
import TaskStaffCellRenderer from '@/components/Aggrid/CellRenderer/TaskStaff';
import TaskTypeCellRenderer from '@/components/Aggrid/CellRenderer/TaskType';
import TaskAutoSchedulingCellRenderer from '@/components/Aggrid/CellRenderer/TaskAutoScheduling';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import NoteCellRenderer from '@/components/Aggrid/CellRenderer/Note';
import TaskTemplateCellRenderer from '@/components/Aggrid/CellRenderer/TaskTemplate';
import ConstraintCellRenderer from '@/components/Aggrid/CellRenderer/Constraint';
import StageCellRenderer from '@/components/Aggrid/CellRenderer/Stage';
import SelectionHeaderComponent from '@/components/Aggrid/CellHeader/PagedRangeSelection';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly';
import BooleanCellRenderer from '@/components/Aggrid/CellRenderer/Boolean';
import currencies from '@/views/management/script/currencies';

import TagEditor from '@/components/Aggrid/CellEditor/Tag';
import DurationEditor from '@/components/Aggrid/CellEditor/Duration';
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime';
import CostEditor from '@/components/Aggrid/CellEditor/Cost';
import PercentageEditor from '@/components/Aggrid/CellEditor/Percentage';
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric';
import ListEditor from '@/components/Aggrid/CellEditor/List';
import StaffEditor from '@/components/Aggrid/CellEditor/Staff';
import StageEditor from '@/components/Aggrid/CellEditor/Stage';
import ResourceEditor from '@/components/Aggrid/CellEditor/Resource';
import SkillEditor from '@/components/Aggrid/CellEditor/Skill';
import NameEditor from '@/components/Aggrid/CellEditor/Name';
import WorkEffortEditor from '@/components/Aggrid/CellEditor/WorkEffort_v1';
import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline';
import RebateEditor from '@/components/Aggrid/CellEditor/Rebate';
import TaskTemplateEditor from '@/components/Aggrid/CellEditor/TaskTemplate_v1';
import ConstraintEditor from '@/components/Aggrid/CellEditor/Constraint';
import CommentEditor from '@/components/Aggrid/CellEditor/Note3';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import ColorEditor from '@/components/Aggrid/CellEditor/Color';
import FloatNumericEditor from '@/components/Aggrid/CellEditor/FloatNumeric';
import IntegerNumericEditor from '@/components/Aggrid/CellEditor/IntegerNumeric';

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

import { DEFAULT_CALENDAR, TRIGGERS, convertDisplayToDuration, convertDurationToDisplay
, analyzeDurationAUM } from '@/helpers/task-duration-process';
import {
  durationComparator,
  numberComparator,
  booleanComparator,
  skillComparator,
  staffComparator,
  noteComparator,
  stageComparator,
  resourceComparator,
  rebateComparator,
  constraintComparator
} from '@/helpers/task-column-comparator';

function ServerSideDatasource(self) {
      return {
        // called by the grid when more rows are required
        getRows: function(params) {
          self.$set(self, 'overlayLoadingMessage', null);// reset to default message.
          params.api.hideOverlay();
          params.api.showLoadingOverlay();

          //Clear all selection
          params.api.deselectAll();
          params.api.clearRangeSelection();

          if (self.originalRowDataList.length > 0) {
            self.originalRowDataList.splice(0, self.originalRowDataList.length);
          }
          
          // self.ganttReadOnly = true; //Commented out this statement to disable the logic of setting gantt read-only while reloading data. Decision made by Paul.
          self.actionProcessing = false;

          //Keep the last focused cell info and reapply it after data refresh
          const resetFocus = (api, cell) => {
            api.clearRangeSelection();
            if (cell != null && cell.rowIndex != null && cell.colId != null) {
              api.setFocusedCell(cell.rowIndex, cell.colId, null);
              api.addCellRange({
                rowStartIndex: cell.rowIndex
                , rowEndIndex: cell.rowIndex
                , columnStart: cell.colId
                , columnEnd: cell.colId
              });
            }
          }
          
          if (self.taskIds.length == 0) {
            params.successCallback([], 0);
            if (!self.loading) {
              self.inProgressShow = false;
              self.actionProcessing = false;
              self.ganttReadOnly = false;
              params.api.hideOverlay();
            }
            return;
          }

          const request = params.request;
          const sortModel = request.sortModel;
          const offset = request.startRow || 0;
          var limit = (request.endRow - request.startRow) + 1;

          if(self.gridColumnApi) {
            self.columnCache = params.columnApi.getAllDisplayedColumns().filter(i => i.colId !== 'ag-Grid-AutoColumn').map(i => i.colId);
          } else {
            self.columnCache = [];
          }

          const taskIds = self.taskIds.slice(offset, offset + limit);
          
          // get the time window
          self.prepareTaskEarliestAndLatestDate(taskIds);
           
          //First query to get core data
          aggridGanttService.listPagedTaskDataDynamic({ taskIds: taskIds, sortModel, start: 0, limit, requestedFields: self.columnCache
                                                      , customFields: self.customFields, skillCustomFields: self.skillCustomFields
                                                      , resourceCustomFields: self.resourceCustomFields, noteCustomFields: self.noteCustomFields })
          .then(response => {
            if (response.lackOfMandatoryFields === true) {
              self.ganttData = response.gantt;
              self.taskNames = response.grid.taskNames;
              params.successCallback(response.grid.records, response.total);
              self.isAccessDenied = true;
              self.showNoRowsOverlay(self.$t('entity_selector.error.insufficient_permission_to_show_data'));
              return;
            }

            if (response.fields != null) {
              self.columnCache = response.fields;
            }
            
            self.ganttData = response.gantt;
            self.taskNames = response.grid.taskNames;
            params.successCallback(response.grid.records, self.taskIds.length);

            const left = self.scrollXBeforeReload;
            const top = self.scrollYBeforeReload;
            if(self.selectedBeforeReload.length > 0) {
              self.updateTimeline();
              self.$nextTick(() => {
                self.selectedBeforeReload.forEach(i => {
                  const rowNode = params.api.getRowNode(i);
                  if(rowNode) {
                    rowNode.setSelected(true);
                  }
                });
                self.resetPreviousScrollPosition(left, top);
              });
            }

            setTimeout(() => {
              //Skip reseting focus cell when it is triggered by page navigation.
              if (!params.api.destroyCalled && !self.isNextPage) {
                resetFocus(params.api, self.lastFocusedCell);
              }
              self.isNextPage = false;
            }, 0);
            
            
            
            //Force refreshing header to update checkbox state
            if (!params.api.destroyCalled) {
              params.api.refreshHeader();
            }
          })
          .catch(err => {
            params.failCallback();
            // self.httpAjaxError(err);
            self.isAccessDenied = true;
            self.ganttData = { data: [], collections: { links: [] } };
            self.taskNames = [];
            if (err != null && err.response != null && err.response.status == 403) {
              self.showNoRowsOverlay(self.$t('entity_selector.error.insufficient_permission_to_show_data'));
            } else {
              self.showNoRowsOverlay(self.$t('task.grid.error.failed_to_load'));
            }
          })
          .finally(() => {
            self.inProgressShow = false;
            self.actionProcessing = false;
            self.ganttReadOnly = false;
            if (!self.isAccessDenied) {
              params.api.hideOverlay();
            }
          });
        }
      };
    }

export default {
  name: 'Gantt'
  , components: {
    ProjectSelectorModal: () => import('@/components/modal/ProjectSelectorModal')
    , TaskModal: () => import('@/components/modal/TaskModal')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , TaskLinkModal: () => import('@/components/modal/TaskLinkModal')
    , GanttActionBar: () => import('@/components/Gantt/components/AgGridGanttActionBar')
    , DhtmlxGantt
    , 'ag-grid-vue': AgGridVue
    , AutoAssignStaffModal: () => import('@/components/modal/AutoAssignStaffModal')
    , TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation')
    , SaveViewModal: () => import('@/components/modal/SaveViewModal.vue')

    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    , 'rowSelectorCellRenderer': RowSelectorCellRenderer
    , 'costCellRenderer': CostCellRenderer
    , 'detailLinkCellRenderer': DetailLinkCellRenderer
    , 'dateTimeCellRenderer': DateTimeCellRenderer
    , 'durationCellRenderer': DurationCellRenderer
    , 'percentageCellRenderer': PercentageCellRenderer
    , 'taskConstraintCellRenderer': TaskConstraintCellRenderer
    , 'taskResourceCellRenderer': TaskResourceCellRenderer
    , 'taskSkillCellRenderer': TaskSkillCellRenderer
    , 'taskStaffCellRenderer': TaskStaffCellRenderer
    , 'taskTypeCellRenderer': TaskTypeCellRenderer
    , 'taskAutoSchedulingCellRenderer': TaskAutoSchedulingCellRenderer
    , 'rebateCellRenderer': RebateCellRenderer
    , 'noteCellRenderer': NoteCellRenderer
    , 'taskTemplateCellRenderer': TaskTemplateCellRenderer
    , 'constraintCellRenderer': ConstraintCellRenderer
    , 'stageCellRenderer': StageCellRenderer
    , 'dateOnlyCellRenderer': DateOnlyCellRenderer
    , 'booleanCellRenderer': BooleanCellRenderer
    , 'tagEditor': TagEditor
    , 'durationEditor': DurationEditor
    , 'dateTimeEditor': DateTimeEditor
    , 'percentageEditor': PercentageEditor
    , 'costEditor': CostEditor
    , 'listEditor': ListEditor
    , 'staffEditor': StaffEditor
    , 'resourceEditor': ResourceEditor
    , 'skillEditor': SkillEditor
    , 'numericEditor': NumericEditor
    , 'nameEditor': NameEditor
    , 'workEffortEditor': WorkEffortEditor
    , 'multilineEditor': MultilineEditor
    , 'rebateEditor': RebateEditor
    , 'taskTemplateEditor': TaskTemplateEditor
    , 'constraintEditor': ConstraintEditor
    , 'stageEditor': StageEditor
    , 'commentEditor': CommentEditor
    , 'stringEditor': StringEditor
    , 'colorEditor': ColorEditor
    , 'floatNumericEditor': FloatNumericEditor
    , 'integerNumericEditor': IntegerNumericEditor
    , 'selectionHeaderComponent': SelectionHeaderComponent
    , 'colorCellRenderer': ColorCellRenderer
    , 'genericCellRenderer': GenericCellRenderer
    , 'enumCellRenderer': EnumCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  }
  , props: {
    mode: {
      type: String
      , default: 'BOTH'
    }
    , heightOffset: {
      type: Number
      , default: -1
    }
    , taskIds: {
      type: Array
      , default: null
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    widgetOwner: {
      type: String,
      default: null
    },
    dataviewId: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
  , data() {
    return {
      ganttHeight: -1
      , ganttData: {
        data: []
      }
      , permissionName: 'TASK'
      , pendingRerender: false

      , control: {
        dates: null
        , startDate: null
        , endDate: null
        , timescale: 'week'
        , criticalPath: false
        , freeFloat: false
      }
      , datesChangeFlag: false
      
      , state: {
        taskShow: false //Toggle task dialog true to display, or false to hide.
        , taskLinkEditShow: false
        , projectSelectorShow: false
        , taskTemplateSelectorForNewTaskShow: false
        , confirmChangeOnCompleteShow: false
      }

      , opts: {
        constraint: []
        , priority: []
        , type: []
        , hr: []
        , link_type: []
        , task: []
      }

      //Task Dialog
      , taskEdit: {
        uuId: null
        , parentId: null
        , projectId: null
      }

      //Task Link Dialog
      , taskLinkEdit: {
        taskId: null
        , predecessorId: null
      }

      , projectSelectorEdit: {
        uuId: null
      }

      , rowSelection: {
        uuId: null
        , isSelected: false
        , signalUpdate: null
      }

      , project: {
        uuId: null
        , name: '' //Value can't be null or undefined as complained by GanttControl's props validation 'required: true'
        , startDate: null
        , closeDate: null
        , earliestDate: null
        , latestDate: null
        , durationUnit: null
        , durationAUM: null
      }

      , splitterEventState: {
        isMouseDown: false
        , x: 0
        , y: 0
        , leftWidth: 0
      }

      // Variables to signal gantt to collapse/expand a branch
      , collapseId: null
      , expandId: null

      //Start Scroll state
      , scrollState: {
        left: -1
        , top: -1
        , triggeredByLHSGrid: false
        , triggeredByRHSChart: false
      }
      //End Scroll state

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false
      , inProgressState: {
        cancel: false
      }

      , gridOptions: null
      , gridApi: null
      , autoGroupColumnDef: null
      , columnApi: null
      , context: null
      , defaultColDef: null
      
      , selected: []
      , parentRootId: null

      , confirmDeleteShow: false
      , actionProcessing: false

      , taskCopyIds: []
      , taskNames: {}
      
      , autoAssignStaffShow: false
      , autoAssignSettings: null
      , autoAssignSummaryShow: false
      , autoAssignSummary: []

      , splitterStyle: {}
      , lhsGridStyle: {}

      , ganttReadOnly: false

      , taskEarliestDate: null
      , taskLatestDate: null

      , selectedBeforeReload: []
      , scrollXBeforeReload: -1
      , scrollYBeforeReload: -1

      , calendar: null

      , overlayLoadingMessage: null

      , optionPriority: []
      , optionConstraint: []
      , optionComplexity: []
      , optionCurrency: []

      , durationCalculationShow: false
      , durationCalculationTasks: []
      , durationCalculationPendingUpdateTasks: []
      , durationCalculation: {
        taskName: null
        , trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projectScheduleFromStart: true
        , taskAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , defaultActionForNonWorkPrompt: null
        , skipOutOfProjectDateCheck: false
        , projectStartDateStr: null
        , projectCloseDateStr: null
        , oldConstraintType: null
        , oldConstraintDateStr: null
      }
      , dcClearPreviousChoice: false
      , dcShowApplyAllCheckbox: false
      , applyAllChangeOnComplete: false
      , forceReloadAfterDurationCalculation: false

      , locationCalendarMap: new Map()
      , projectStagesMap: new Map()
      , columnCache: []

      , applyTemplateEdit: {
        parentIds: []
      }

      , layoutProfile: {}
      , ganttViews: []
      , userProfile: {}
      , promptSaveShow: false
      , promptShareShow: false
      , saveName: null
      , saveProfile: null
      , saveIndex: -1
      , confirmDeleteViewShow: false
      , deleteViewIndex: -1

      , tcConfirmDeleteShow: false
      , tcConfirmDeleteTasks: []
      , taskCol: {
        taskName: null
        , parentName: null
        , taskId: null
        , parentId: null
        , colId: null
        , applyAll: false
      }

      , pendingListByFillOperation: []
      , triggeredByFillOperation: false
      , processValueChangedList: []
      , processDateValueChangedList: []
      , processTaskMoveChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []
      , modelInfo: null
      , enumList: {}
      
      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , taskSelection: []
      
      , coloring: {
        none: true,
        task: false,
        stage: false, 
        skill: false,
        staff: false,    
        resource: false,   
        rebate: false, 
        file: false
      }

      , ganttDeleteTaskIds: []
      , hasAutoAssignTasks: false
      , taskSelectionIds: []

      , noRowsMessage: null
      , noRowsOverlayComponentParams: null
      , isAccessDenied: false
      , ganttInsufficientEditPermission: false

      , paginationPageSize: null
      , cacheBlockSize: null
      , maxBlocksInCache: null
      , isNextPage: false
      , lastOpenColumnMenuParams: null

      , calendarType: {
        holderId: null
        , type: null
      }
      , projectCalendar: null
      , systemCalendar: null

      , customFields: []
      , skillCustomFields: []
      , resourceCustomFields: []
      , noteCustomFields: []
    }
  }
  , mounted() {
    this.chartResizeHandler();
    window.addEventListener('resize', this.chartResizeHandler);

    if(this.isTouchDevice()) {
      this.$refs['resizer-overlay'].addEventListener('touchstart', this.touchStartHandler);
    } else {
      this.$refs['resizer-overlay'].style.display = 'none';
    }

    document.addEventListener('keyup', this.loseCellFocusOnEscapeKey);
  }
  , created() {
    this.isGridReady = false;
    this.isCustomFieldsReady = false;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
    this.maxBlocksInCache = 1;
    this.entityId = this.isWidget ? this.dataviewId : this.$route.params.id;
    this.userId = this.$store.state.authentication.user.uuId;

    //Declare properties that do not need value changing observer.
    this.resizerWidth = 5;
    this.touchEvent = {
      isTouchStart: false
      , x: 0
      , leftWidth: 0
    }

    this.debouncedTouchMoveHandler = debounce(this.touchMoveHandler, 10);

    document.addEventListener('keydown', this.keyDownHandler);

    // this.actionProcessing = true; //Set true to disable all action buttons.

    /** START - AgGrid related */
    this.originalRowDataList = [];
    this.lastFocusedCell = null;

    this.getModelInfo();
    this.isDateCalcInProgress = false;
    this.isPasteInProgress = false;
    this.cellCopyPrefix = 'PRJTL_COPIED_OBJ=';
    this.taskGroupPrefix = 'taskgroup_';
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn';
    this.COLUMN_NAME = 'name';

    //Show data loading message 
    if(this.gridApi) {
      this.overlayLoadingMessage = null;// reset to default message.
      this.gridApi.showLoadingOverlay();
      this.gridApi.deselectAll();
    }
    /** END - AgGrid related */

    this.prepareProjectRelatedDetails(this.taskIds);
    this.loadUserProfile(); // User profile holds Gantt views
    this.loadPublicProfile();

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
  }
  , beforeMount() {
    this.$store.dispatch('data/enumList')
    .then(response => {
      if (response != null) {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          const keys = Object.keys(propertyList);
          for (const k of keys) {
            const obj = propertyList[k]
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.$set(this.enumList, k, list)
          }
        }

        //Fill up currency name property
        if (Array.isArray(this.enumList.CurrencyEnum)) {
          const currencyList = this.enumList.CurrencyEnum
          for (const item of currencyList)   {
            const found = currencies.find(i => i.code === item.value)
            if (found != null && found.name != null) {
              item.text = `${found.code} (${found.name})`
            }
          }
        }

        this.optionComplexity.splice(0, this.optionComplexity.length, ...this.enumList.GanttComplexityEnum)
        this.optionPriority.splice(0, this.optionPriority.length, ...this.enumList.GanttPriorityEnum)
        this.optionCurrency.splice(0, this.optionCurrency.length, ...this.enumList.CurrencyEnum)
      }
    })
    .catch(e => {
      this.httpAjaxError(e)
    })

    const self = this;
    const canEdit = this.canEdit();

    this.userId = this.$store.state.authentication.user.uuId;
    function saveColumnState() {
      const columns = self.gridOptions.columnApi.getAllDisplayedColumns();
      self.layoutProfile.ganttColumns = columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort }});
      self.updateLayoutProfile();
    }
    this.gridOptions = { 
      onColumnResized: debounce(function() {
        self.maintainGridHorinzontalScrollbar();
      }, 100)
      , onGridSizeChanged: debounce(function() {
        self.maintainGridHorinzontalScrollbar();
        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (self.gridColumnApi != null) {
          const columnState = self.gridColumnApi.getColumnState();
          const index = columnState.findIndex(i => i.colId == 'name');
          if (index > -1) {
            columnState[index].pinned = width < 800? '' : 'left';
          }
          self.gridColumnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
          });
        }
      }, 100)
      // onSelectionChanged: function(event) {
      //   self.selected = event.api.getSelectedNodes().map(i => i.data.uuId);
      // }
      , onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        params.api.resetRowHeights();
        saveColumnState();

        const shownColumns = params.columns.filter(i => i.visible);
        if (shownColumns.some(i => !self.columnCache.includes(i.colId))) {
          self.refreshData();
        } 
        
        //Fix a bug: columnResized event is not triggered when toggling the master checkbox in column menu.
        //Solution: Manually call maintainGridHorizontalScrollbar()
        setTimeout(() => {
          self.maintainGridHorinzontalScrollbar();
        }, 100);
      }
      , postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
      , onSortChanged: function(/** event */) {
        saveColumnState();
      }
      , onDragStopped: function(/** event */) {
        saveColumnState();
      }
      , onBodyScroll: function(event) {
        self.scrollState.triggeredByLHSGrid = false;
        self.scrollState.triggeredByRHSChart = false;
        if(self.scrollState.top != event.top) {
          self.scrollState.triggeredByLHSGrid = true;
          self.scrollState.top = event.top;
        }
      }
      , onCellValueChanged: function(event) {
        let colId = event.column.colId;
        const rowIndex = event.rowIndex;
        let newValue = event.newValue;
        let oldValue = event.oldValue;
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
        // if (event.column.colId == self.COLUMN_AGGRID_AUTOCOLUMN) {
        //   colId = 'name';
        //   newValue = rowNode.data.name;
        //   oldValue = rowNode.data.oldName; //oldName is added in autoGroupColumnDef.valueSetter.
        // }
        
        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , taskId: rowNode.data.uuId
          , parentId: rowNode.data.pUuId
          , taskName: rowNode.data.name
          , taskType: rowNode.data.taskType
          , color: event.colDef.color //Default task Color. Used when creating new task.
          , projId: rowNode.data.projId
        }
        let found = self.originalRowDataList.find(i => i.uuId == rowNode.data.uuId);
        if (found == null) {
          found = JSON.parse(JSON.stringify(rowNode.data));
          found[colId] = oldValue != null? JSON.parse(JSON.stringify(oldValue)) : null;
          self.originalRowDataList.push(found)
        } else {
          found[colId] = oldValue != null? JSON.parse(JSON.stringify(oldValue)) : null;
        }

        if (payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload);
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload);
        } else {
          self.processValueChangedList.push(payload);
          self.processValueChanged(event.api);
        }
      }
      , onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length);
            self.processValueChangedList.push(...pendingList);
            self.consolidateChangedCellValues(self.processValueChangedList);
            self.triggeredByFillOperation = false;
            self.processValueChanged(event.api);
          }
          const cellRanges = event.api.getCellRanges();
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          });
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1];
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })();
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0]; 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api, 
                columnApi: event.columnApi 
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns;
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex;
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex;
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex;

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1);
              const newCellRanges = [];
              let hasChanges = false;

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn;
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false;
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true;
                  break;
                }
              }
              if (isStartCellClashed) {
                return;
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex);
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i));                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range);
                  continue;
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex;
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex;
                const firstClashedCol = clashedColumns[0];
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0;
                const lastClashedCol = clashedColumns[clashedColumns.length-1];
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1;

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1;
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  });
                  hasChanges = true;
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  });
                  hasChanges = true;
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol);
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  });
                  hasChanges = true;
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false;
              if (newCellRanges.length > 0) {
                const allColumns = event.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0]);
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1]);
                const cloned = objectClone(newCellRanges);
                const newRanges = [];
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0]);
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1]);
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex;
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex;
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex;
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex;
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    });
                    hasRangeMerged = true;
                    continue;
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true;
                    continue;
                  }
                  newRanges.push(cRange);
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges);
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  });
                }
                event.api.clearRangeSelection();
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange);
                }
              } else {
                //When thing settles down, update taskSelection variable.
                let selectedTasks = [];
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex;
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex;
                  const columns = oRange.columns;
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i);
                    
                    if (rowNode == null || rowNode.data == null) {
                      continue;
                    }
                    
                    //Handle non task Cols.
                    if (columns.length > 0) {
                      //Treat any non task cell selection is ag-Grid-AutoColumn cell selection.
                      selectedTasks.push({ 
                         uuId: rowNode.data.uuId
                        , name: rowNode.data.name
                        , parent: rowNode.data.pUuId
                        , parentName: rowNode.data.pName
                        , colId: self.COLUMN_NAME
                        , rowIndex: rowNode.rowIndex
                      });
                    }
                  }
                }
                
                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedTasks.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0;
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1;
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1;
                  }
                  return 0;
                });
                self.taskSelection.splice(0, self.taskSelection.length, ...selectedTasks);

                //Update node selected state. Used in PagedRangeSelection.
                event.api.forEachNode(function(node) {
                  if (node != null && node.data != null) {
                    node.setSelected(selectedTasks.findIndex(i => i.uuId == node.data.uuId) != -1);
                  }
                });
              }
            }
          } else {
            //Clean up taskSelection when range selection is empty.
            self.taskSelection.splice(0, self.taskSelection.length);

            //Update node selected state. Used in PagedRangeSelection.
            event.api.forEachNode(function(node) {
              if (node.data != null) {
                node.setSelected(false);
              }
            });
          }
          
          event.api.refreshHeader();
        }
      }
    };

    const defaultCellClass = (params) => { return params.data.taskType === 'Project' ? ['grid-cell-summary']: [] };
    
    this.columnDefs = [];
    const colDefs = [];
    colDefs.push({
      headerName: 'Tasks'
      , minWidth: 150
      , width: 200
      , field: 'name'
      , pinned: 'left'
      , lockVisible: true
      , editable: canEdit
      , cellEditor: 'nameEditor'
      , cellRenderer: 'detailLinkCellRenderer'
      , cellRendererParams: {
        isReadOnly: !canEdit
        , enableReadonlyStyle: true
      }
      , menuTabs: ['generalMenuTab']
      , resizable: true
      , headerComponent: 'selectionHeaderComponent'
      , headerComponentParams: {
          targetColId: this.COLUMN_NAME
          , displayName: 'Tasks'
      }
      , cellStyle: params => {
        const defaultStyle = {
          'height': '100%'
          , 'display': 'flex '
          , 'align-items': 'center'
          , 'overflow': 'hidden'
          , 'text-overflow': 'ellipsis'
          , 'white-space': 'nowrap'
        };
        
        if (params.data &&
          params.data.taskColor &&
          this.coloring.task) {
          return { ...defaultStyle, background: params.node.data.taskColor, color: invertColor(params.node.data.taskColor, true) };
        }
        else if (params.data &&
          params.data.stageColor &&
          this.coloring.stage) {
          const color = getFirstColor(params.data.stageColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.rebateColor &&
          this.coloring.rebate) {
          const color = getFirstColor(params.data.rebateColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.fileColor &&
          this.coloring.file) {
          const color = getFirstColor(params.data.fileColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.staffColor &&
          this.coloring.staff) {
          const color = getFirstColor(params.data.staffColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.skillColor &&
          this.coloring.skill) {
          const color = getFirstColor(params.data.skillColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        else if (params.data &&
          params.data.resourceColor &&
          this.coloring.resource) {
          const color = getFirstColor(params.data.resourceColor);
          if (color) return { ...defaultStyle, background: color, color: invertColor(color, true) };
        }
        return defaultStyle;
      }
    });
    colDefs.push({
      headerName: this.$t('task.field.taskType')
      , field: 'taskType'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'taskTypeCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , getQuickFilterText: function(params) {
        return params.value === 'Project' ? 'Summary Task' : params.value;
      }
    });
    colDefs.push({
      headerName: this.$t('task.field.project')
      , field: 'projName'
      , hide: false
      , minWidth: 300
      , getQuickFilterText: function(params) {
        return params.value;
      }
    });
    colDefs.push({
      headerName: this.$t('task.field.estimatedDuration')
      , field: 'estimatedDuration'
      , hide: false
      , minWidth: 150
      , cellEditor: 'durationEditor'
      , cellStyle: {
        'height': '100%',
        'display': 'flex ',
        'align-items': 'center '
      }
      , editable: canEdit? (params) => { return params.data.taskType == 'Task' } : false
      , comparator: durationComparator
      , sortable: false
    });
    colDefs.push({
      headerName: this.$t('task.field.startTime')
      , field: 'startTime'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'dateTimeCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'dateTimeEditor'
      , cellEditorParams: { 
        labelDate: this.$t('task.field.startTime'),
        optional: true
      }
      , editable: canEdit? (params) => { return params.data.taskType !== 'Project' } : false
      , getQuickFilterText: function(params) {
        return self.toDateTime(params.value);
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.closeTime')
      , field: 'closeTime'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'dateTimeCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'dateTimeEditor'
      , cellEditorParams: { 
        labelDate: this.$t('task.field.closeTime')
      }
      , editable: canEdit? (params) => { return params.data.taskType == 'Task' } : false
      , getQuickFilterText: function(params) {
        return self.toDateTime(params.value);
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.progress')
      , field: 'progress'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'percentageCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'percentageEditor'
      , editable: canEdit? (params) => { return params.data.taskType !== 'Project' } : false
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.skills')
      , field: 'skills'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'taskSkillCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'skillEditor'
      , cellEditorParams: {
        edgeName: 'TASK-SKILL'
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: skillComparator
      , cellClass: (params) => {
        const classes = defaultCellClass(params);
        // if (params.data == null || params.data.uuId == 'ROOT') {
        //   classes.push('cell-disabled');
        // }
        return classes;
      }
    });
    colDefs.push({
      headerName: this.$t('task.field.staffs')
      , field: 'staffs'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'taskStaffCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'staffEditor'
      , cellEditorParams: { 
        companyList: () => this.project ? this.project.companyList : null
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: staffComparator
      , cellClass: (params) => {
        const classes = defaultCellClass(params);
        // if (params.data == null || params.data.uuId == 'ROOT') {
        //   classes.push('cell-disabled');
        // }
        return classes;
      }
    });
    colDefs.push({
      headerName: this.$t('task.field.actualDuration')
      , field: 'totalActualDuration'
      , hide: false
      , minWidth: 150
      , cellEditor: 'workEffortEditor'
      , editable: canEdit? (params) => { return params.data != null && params.data.taskType !== 'Project' } : false
      , comparator: durationComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.fixedDuration')
      , field: 'fixedDuration'
      , hide: false
      , minWidth: 150
      , cellEditor: 'durationEditor'
      , editable: canEdit
      , valueGetter: (params) => {
          if (typeof params.data.fixedDuration !== 'undefined' &&
              params.data.fixedDuration !== '' &&
            // eslint-disable-next-line
            !/\d+[\.,m,h,D,W,M,Y]/.test(params.data.fixedDuration)) {
            return params.data.fixedDuration + 'D';
          }
          return params.data.fixedDuration;
        }
      , comparator: durationComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.currencyCode')
      , field: 'currencyCode'
      , cellEditor: 'listEditor'
      , cellRenderer: 'enumCellRenderer'
      , cellRendererParams: { options: this.optionCurrency, enableReadonlyStyle: true }
      , cellEditorParams: { options: this.optionCurrency, isEnumType: true }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , hide: true
    });
    colDefs.push({
      headerName: this.$t('task.field.estimatedCost')
      , field: 'estimatedCost'
      , hide: false
      , minWidth: 150
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.actualCost')
      , field: 'actualCost'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.notes')
      , field: 'notes'
      , minWidth: 150
      , hide: false
      , cellRenderer: 'noteCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'commentEditor'
      , cellEditorParams: { 
        entityName: 'TASK'
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: noteComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.estimatedTimeToComplete')
      , field: 'estimatedTimeToComplete'
      , hide: true
      , minWidth: 150
      , cellEditor: 'durationEditor'
      , cellStyle: {
        'border-width': '1px',
        'height': '100%',
        'display': 'flex ',
        'align-items': 'center '
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.taskType == 'Task' } : false
      , comparator: durationComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.fixedCostNet')
      , field: 'fixedCostNet'
      , hide: true
      , minWidth: 150
      , cellStyle: {
        'border-width': '1px'
        , 'height': '100%'
        , 'display': 'flex '
        , 'align-items': 'center '
      }
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.fixedCostTotal')
      , field: 'totalFixedCost'
      , hide: true
      , minWidth: 150
      , cellStyle: {
        'border-width': '1px'
        , 'height': '100%'
        , 'display': 'flex '
        , 'align-items': 'center '
      }
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.fixedCostTotalNet')
      , field: 'totalFixedCostNet'
      , hide: true
      , minWidth: 150
      , cellStyle: {
        'border-width': '1px'
        , 'height': '100%'
        , 'display': 'flex '
        , 'align-items': 'center '
      }
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.priority')
      , field: 'priority'
      , minWidth: 150
      , hide: true
      , cellEditor: 'listEditor'
      , cellEditorParams: { options: this.optionPriority, isEnumType: true }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
    });
    colDefs.push({
      headerName: this.$t('task.field.stage')
      , field: 'stage'
      , minWidth: 150
      , hide: true
      , cellRenderer: 'stageCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'stageEditor'
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: stageComparator
      , cellClass: (params) => {
          const classes = defaultCellClass(params);
          // if (params.data == null || params.data.uuId == 'ROOT') {
          //   classes.push('cell-disabled');
          // }
          return classes;
        }
    });
    colDefs.push({
      headerName: this.$t('task.field.complexity')
      , field: 'complexity'
      , minWidth: 150
      , hide: true
      , cellEditor: 'listEditor'
      , cellEditorParams: { options: this.optionComplexity, isEnumType: true }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , cellClass: (params) => {
          const classes = defaultCellClass(params);
          // if (params.data == null || params.data.uuId == 'ROOT') {
          //   classes.push('cell-disabled');
          // }
          return classes;
        }
    });
    colDefs.push({
      headerName: this.$t('task.field.resources')
      , field: 'resources'
      , minWidth: 150
      , hide: true
      , cellRenderer: 'taskResourceCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'resourceEditor'
      , cellEditorParams: {
        edgeName: 'TASK-RESOURCE'
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: resourceComparator
      , cellClass: (params) => {
          const classes = defaultCellClass(params);
          // if (params.data == null || params.data.uuId == 'ROOT') {
          //   classes.push('cell-disabled');
          // }
          return classes;
        }
    });
    colDefs.push({
      headerName: this.$t('task.field.fixedCost')
      , field: 'fixedCost'
      , hide: true
      , minWidth: 150
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , cellEditor: 'costEditor'
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.estimatedCostNet')
      , field: 'estimatedCostNet'
      , hide: true
      , minWidth: 150
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.actualCostNet')
      , field: 'actualCostNet'
      , minWidth: 150
      , hide: true
      , cellRenderer: 'costCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
        , customCurrencyProp: 'currencyCode'
      }
      , comparator: numberComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.rebates')
      , field: 'rebates'
      , minWidth: 150
      , hide: true
      , cellRenderer: 'rebateCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'rebateEditor'
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , comparator: rebateComparator
    });

    colDefs.push({
      headerName: this.$t('task.field.constraint')
      , colId: 'constraint'
      , field: 'constraint'
      , minWidth: 205
      , hide: true
      , cellEditor: 'constraintEditor'
      , cellEditorParams: { field: 'type', options: this.optionConstraint }
      , cellRenderer: 'constraintCellRenderer'
      , cellRendererParams: { field: 'type', enableReadonlyStyle: true }
      , editable: canEdit? (params) => { return params.data != null && params.data.taskType !== 'Project' } : false
      , cellClass: (params) => {
          const classes = defaultCellClass(params);
          // if (params.data == null || params.data.taskType === 'Project' || params.data.uuId == 'ROOT') {
          //   classes.push('cell-disabled');
          // }
          return classes;
        }
      , comparator: constraintComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.autoScheduling')
      , field: 'autoScheduling'
      , minWidth: 100
      , hide: true
      , cellEditor: 'listEditor'
      , cellEditorParams: { 
        options: [
          { value: true, text: this.$t('task.autoschedule.auto') },
          { value: false, text: this.$t('task.autoschedule.manual') }
        ]
      }
      , cellRenderer: 'taskAutoSchedulingCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , editable: canEdit? (params) => { return params.data != null && params.data.taskType !== 'Project' } : false
      , cellClass: (params) => {
          const classes = defaultCellClass(params);
          // if (params.data == null || params.data.taskType === 'Project') {
          //   classes.push('cell-disabled');
          // }
          return classes;
        }
      , comparator: booleanComparator
    });
    colDefs.push({
      headerName: this.$t('task.field.description')
      , field: 'description'
      , minWidth: 150
      , hide: true
      , cellEditor: 'multilineEditor'
      , cellEditorParams: { title: this.$t('task.edit_description') }
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false,
    });
    colDefs.push({
      headerName: this.$t('task.field.template')
      , field: 'template'
      , hide: true
      , cellRenderer: 'taskTemplateCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , cellEditor: 'taskTemplateEditor'
      , minWidth: 120
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' && params.data.taskType === 'Project' } : false
      , cellClass: (params) => {
        const classes = defaultCellClass(params);
        // if (params.data == null || params.data.uuId == 'ROOT' || params.data.taskType !== 'Project') {
        //   classes.push('cell-disabled');
        // }
        return classes;
      }
    });
    colDefs.push({
      headerName: this.$t('field.tag')
      , field: 'tag'
      , minWidth: 150
      , hide: true
      , cellEditor: 'tagEditor'
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
    });
    colDefs.push({
      headerName: this.$t('field.color')
      , field: 'taskColor'
      , cellRenderer: 'colorCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , hide: true
      , cellEditor: 'colorEditor'
      , editable: canEdit? (params) => { return params.data.uuId !== 'ROOT' } : false
    });
    colDefs.push({
      headerName: this.$t('field.identifier_full')
      , field: 'identifier'
      , minWidth: 150
      , hide: true
      , editable: canEdit? (params) => { return params.data != null && params.data.uuId !== 'ROOT' } : false
      , cellEditor: 'stringEditor'
    });
    colDefs.push({
      headerName: this.$t('task.field.taskpath')
      , field: 'taskPath'
      , minWidth: 150
      , hide: true
      , valueGetter: (params) => {
        if (!self.exporting) {
          return params.data.taskPath.substr(params.data.taskPath.indexOf('\n') + 1).replace(/\n/g, ' / ');
        }
        return params.data.taskPath;
      }
    });

    const K_TASK = 'TASK';
    const linkedEntities = [
        { selector: `${K_TASK}.TAG`, field: 'tag', properties: ['name'] }
      , { selector: `${K_TASK}.STAGE`, field: 'stage', properties: ['name'] }
      , { selector: `${K_TASK}.TASK-SKILL`, field: 'skills', properties: ['level'] }
      , { selector: `${K_TASK}.TASK-STAFF`, field: 'staffs', properties: ['duration', 'durationAUM', 'utilization'] }
      , { selector: `${K_TASK}.TASK-RESOURCE`, field: 'resources', properties: ['utilization', 'quantity'] }
      , { selector: `${K_TASK}.NOTE`, field: 'notes', properties: ['text', 'identifier', 'modified'] }
      , { selector: `${K_TASK}.NOTE.USER`, field: 'notes', properties: ['firstName', 'lastName'] }
      , { selector: `${K_TASK}.PROJECT_TEMPLATE`, field: 'template', properties: ['name'] }
      , { selector: `${K_TASK}.SKILL`, field: 'skills', properties: ['name'] }
      , { selector: `${K_TASK}.RESOURCE`, field: 'resources', properties: ['name'] }
      , { selector: `${K_TASK}.REBATE`, field: 'rebates', properties: ['name'] }
      , { selector: `${K_TASK}.STAFF`, field: 'staffs', properties: ['firstName', 'lastName', 'genericStaff'] }
      , { selector: `${K_TASK}.STAGE`, field: 'stageColor', properties: ['color'] }
      , { selector: `${K_TASK}.SKILL`, field: 'skillColor', properties: ['color'] }
      , { selector: `${K_TASK}.RESOURCE`, field: 'resourceColor', properties: ['color'] }
      , { selector: `${K_TASK}.REBATE`, field: 'rebateColor', properties: ['color'] }
      , { selector: `${K_TASK}.STAFF`, field: 'staffColor', properties: ['color'] }
      , { selector: `${K_TASK}.STORAGE_FILE`, field: 'fileColor', properties: ['color'] }
      , { selector: K_TASK, field: 'taskColor', properties: ['color'] }
    ]

    const viewLinkedEntities = JSON.parse(JSON.stringify(linkedEntities));
    viewLinkedEntities.push(...[
      { selector: K_TASK, field: 'fixedCost', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'fixedCostNet', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'actualCost', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'actualCostNet', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'estimatedCost', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'estimatedCostNet', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'totalFixedCost', properties: ['currencyCode'] }
      , { selector: K_TASK, field: 'totalFixedCostNet', properties: ['currencyCode'] }
    ])

    const requests = [
      this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
      , getCustomFieldInfo(this, 'TASK', 'TASK').catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'SKILL_LINK', null, { customFieldsPropName: 'skillCustomFields' }).catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'RESOURCE_LINK', null, { customFieldsPropName: 'resourceCustomFields' }).catch(e => this.httpAjaxError(e))
      , getCustomFieldInfo(this, 'NOTE', null, { customFieldsPropName: 'noteCustomFields' }).catch(e => this.httpAjaxError(e))
    ]
    Promise.allSettled(requests)
    .finally(() => {
      prepareCustomFieldColumnDef(colDefs, this.customFields, { self: this })

      //VIEW permission: Remove column from display list
      filterOutViewDenyProperties(colDefs, K_TASK, viewLinkedEntities);

      //Update linkedEntities for EDIT permission checking
      //Some fields need to work as a group. If one of them being read-only will lead to failure of updating value as they are updated as a group.
      const durationGroup = ['startTime', 'closeTime', 'duration', 'durationAUM', 'lockDuration', 'autoScheduling', 'constraintType', 'constraintTime'];
      const editList = [
          { selector: K_TASK, field: 'startTime', properties: durationGroup }
        , { selector: K_TASK, field: 'closeTime', properties: durationGroup }
        , { selector: K_TASK, field: 'estimatedDuration', properties: durationGroup }
        , { selector: K_TASK, field: 'constraint', properties: durationGroup }
        , { selector: K_TASK, field: 'autoScheduling', properties: durationGroup }
        , { selector: K_TASK, field: 'estimatedTimeToComplete', properties: durationGroup }
        , { selector: K_TASK, field: 'totalActualDuration', properties: ['STAFF'] } //No edit when STAFF is read-only
      ]
     
      for (const item of editList) {
        const index = linkedEntities.findIndex(i => i.field == item.field && i.selector == item.selector)
        linkedEntities.splice(index < 0? 0:index, index < 0?0:1, item)
      }

      //EDIT permission: set column to be read only.
      setEditDenyPropertiesReadOnly(colDefs, K_TASK, linkedEntities);

      //Determine whether to make RHS gantt read only when any gantt's mandatory field is read only
      const dummyColDefs = [{ field: 'durationGroup', editable: true }]
      const dummyLinkedEntities = [{ selector: K_TASK, field: 'durationGroup', properties: durationGroup }]
      setEditDenyPropertiesReadOnly(dummyColDefs, K_TASK, dummyLinkedEntities)
      if (!dummyColDefs[0].editable) {
        this.ganttInsufficientEditPermission = true
      }
      
      colDefs.sort(columnDefSortFunc);
      colDefs.unshift(this.getRowSelectorColumn());
      this.columnDefs = colDefs;
      
      if (this.isGridReady == true) {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        this.loadLayoutProfile()
        .finally(() => {
          //Initialize the columnCache because the user profile feature is absent.
          if(this.gridColumnApi) {
            this.columnCache = this.gridColumnApi.getAllDisplayedColumns().filter(i => i.colId !== 'ag-Grid-AutoColumn').map(i => i.colId);
          } else {
            this.columnCache = [];
          }

          const self = this;
          this.gridApi.setServerSideDatasource(new ServerSideDatasource(self));
          if(this.loading) {
            this.overlayLoadingMessage = null;// reset to default message.
            this.gridApi.showLoadingOverlay();
          } else {
            this.gridApi.showNoRowsOverlay();
          }
          this.isGridReady = false;
        });
      }
      this.isCustomFieldsReady = true;
    });
    
    this.defaultColDef = {
      sortable: false
      , resizable: true
      , minWidth: 100
      , hide: false
      , editable: false
      , cellRenderer: 'genericCellRenderer'
      , cellRendererParams: {
        enableReadonlyStyle: true
      }
      , menuTabs: ['columnsMenuTab']
      , columnsMenuParams: {
        contractColumnSelection: true
      }
      , lockPinned: true
      , cellStyle: params => {
        if (params.column.colId !== this.COLUMN_NAME) {
          return {
            'height': '100%',
            'display': 'flex ',
            'align-items': 'center ',
          }
        }
        
      }
      , cellClass: defaultCellClass
      , suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true;
        } else if (params.event.keyCode == 13) {
          if (params.event.ctrlKey) {
            return true;
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true;
          }
        } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
          return true;
        }
        return false;
      }
    };

    this.context = {
      componentParent: self
    };
    this.populateTaskConstraint();
  }
  , beforeDestroy() {
    if(this.gridHorizontalScrollbarElem != null) {
      this.gridHorizontalScrollbarElem.parentNode.removeChild(this.gridHorizontalScrollbarElem);
      this.gridHorizontalScrollbarElem = null;
    }
    if(this.splitterEventState.isMouseDown) {
      this.mouseUpHandler();
    }
    window.removeEventListener('resize', this.chartResizeHandler);
    this.$refs['resizer-overlay'].removeEventListener('touchstart', this.touchStartHandler);
    this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
    this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
    this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);

    document.removeEventListener('keyup', this.loseCellFocusOnEscapeKey);

    document.removeEventListener('keydown', this.keyDownHandler);
    
    delete this.keyDownListenerId;
    delete this.resizerWidth;
    delete this.touchEvent;
    delete this.debouncedTouchMoveHandler;

    this.originalRowDataList = null;
    this.lastFocusedCell = null;
    this.isGridReady = false;
  }
  , computed: {
    routerObj() {
      const linkService = taskLinkSuccessorService;
      // eslint-disable-next-line
      return function({ g, self } = {}) {
        return {
          task: {
            update: debounce(function(data, id) {
              let otherTasks = null; //used when multiple tasks are selected and moved in chart.

              let startTime = moment.utc(data.start_date);
              let closeTime = moment.utc(data.end_date);
              const oldStartDateTime = moment.utc(data.orgStartDate);
              const oldCloseDateTime = moment.utc(data.orgEndDate);

              //Stop proceed further when startTime and closeTime have no change.
              if (oldStartDateTime.format('YYYY-MM-DD HH:mm') == startTime.format('YYYY-MM-DD HH:mm') 
                  && oldCloseDateTime.format('YYYY-MM-DD HH:mm') == closeTime.format('YYYY-MM-DD HH:mm')) {
                return Promise.resolve({ 'action': 'updated' });
              }

              let constraintDate = null;
              if (data.constraint_date != null) {
                constraintDate = moment.utc(data.constraint_date);
              }

              let durationDisplay = convertDurationToDisplay(data.orgDuration, data.durationAUM);
              let dragMode = 'move';
              if (data.dragMode == 'move') {
                const selectedTaskIds = g.getSelectedTasks();
                const isPartOfSelection = selectedTaskIds != null && selectedTaskIds.length > 0 && selectedTaskIds.includes(id);
                const tasks = [];
                if (isPartOfSelection) {
                  for (const selectedId of selectedTaskIds) {
                    const t = g.getTask(selectedId);
                    if (t != null) {
                      tasks.push(t);
                    }
                  }
                } else {
                  tasks.push(data);
                }

                const preparedTasks = prepareMovedTasks(tasks, self, false);
                const currentIdx = preparedTasks.findIndex(i => i.taskUuId == id);
                const currentTasks = preparedTasks.splice(currentIdx, 1);
                startTime = currentTasks[0].startTime;
                closeTime = currentTasks[0].closeTime;
                if (preparedTasks.length > 0) {
                  otherTasks = preparedTasks;
                }
                
              } else if (data.dragMode == 'resize') {
                dragMode = `resize_${data.resizeRight? 'right' : 'left'}`;
                if (self.timescale != 'day') {
                  if (data.resizeRight) {
                    const oct = oldCloseDateTime;
                    closeTime.hour(oct.hour()).minute(oct.minute());
                  } else {
                    const ost = oldStartDateTime;
                    startTime.hour(ost.hour()).minute(ost.minute());
                  }
                }
              } else if (data.dragMode == 'progress') {
                dragMode = 'progress';
              }

              if(self) {
                self.$emit('taskUpdated', {
                  dragMode: dragMode
                  , taskUuId: id
                  , taskName: data.temp_text != null? data.temp_text : data.text
                  , progressComplete: data.originalProgress == 1
                  , startDateStr: startTime.format('YYYY-MM-DD')
                  , startTimeStr: startTime.format('HH:mm')
                  , closeDateStr: closeTime.format('YYYY-MM-DD')
                  , closeTimeStr: closeTime.format('HH:mm')
                  , durationDisplay: durationDisplay
                  , lockDuration: data.lockDuration != null? data.lockDuration : false
                  , taskAutoScheduleMode: data.actual_auto_scheduling != null? data.actual_auto_scheduling : data.auto_scheduling != null ? data.auto_scheduling : true
                  , constraintType: data.constraintType
                  , constraintDateStr: constraintDate != null? constraintDate.format('YYYY-MM-DD'): null
                  , oldStartDateStr: oldStartDateTime != null? oldStartDateTime.format('YYYY-MM-DD') : null
                  , oldStartTimeStr: oldStartDateTime != null? oldStartDateTime.format('HH:mm') : null
                  , oldCloseDateStr: oldCloseDateTime != null? oldCloseDateTime.format('YYYY-MM-DD') : null
                  , oldCloseTimeStr: oldCloseDateTime != null? oldCloseDateTime.format('HH:mm') : null
                  , projId: data.projId
                  , projScheduleFromStart: data.projScheduleMode != null? data.projScheduleMode == 'ASAP' : true
                  , projStartDateStr: data.projScheduleStart != null? moment.utc(data.projScheduleStart).format('YYYY-MM-DD') : null
                  , projCloseDateStr: data.projScheduleFinish != null? moment.utc(data.projScheduleFinish).format('YYYY-MM-DD') : null
                  , projLocationId: data.projLocationId
                  , progress: (parseInt(data.progress * 100) / 100).toFixed(2)
                  , staffs: data.staffList != null ? data.staffList : []
                  , otherTasks: otherTasks //used only by 'task move action'
                });
              }
              return Promise.resolve({ 'action': 'updated' });
            }, 50)
            , create: function(/** data */) {
              // This create() should not be called theoretically as its 'create' event is interceptted by task modal.
              return Promise.resolve({ 'action': 'error' });
            }
            , delete: function(/** id */) {
              // This delete() should not be called theoretically as no UI to trigger it. Task modal has its own delete logic.
              return Promise.resolve({ 'action': 'error' });
            }
          }
          , link: {
            update: function(/** data, id */) {
              // This update() should not be called theoretically as no UI to trigger it. Task Link modal has its own update logic.
              return Promise.resolve({ 'action': 'error' });
            }
            , create: function(data) {
              const successor = [{
                uuId: data.target
                , type: data.type
                //, lag: data.lag //May not be supplied in data object
              }]
              return linkService.create(data.source, successor)
              .then(resp => {
                const rData = resp.data[resp.data.jobCase][0];
                if(self) {
                  self.$emit('taskLinkCreated', rData.uuId);
                }
                return { 
                  'action': 'inserted'
                  , 'tid': rData.uuId
                }
              })
              .catch(() => {
                if(self) {
                  self.$emit('taskLinkCreateError');
                }
                return { 'action': 'error' }
              });
            }
            , delete: function(/** id */) {
              // This delete() should not be called theoretically as no UI to trigger it. Task Link modal has its own delete logic.
              return Promise.resolve({ 'action': 'error' });
            }
          }
        }
      }
    }
    , overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task.grid.no_data') }</span>`;
    }
    , overlayLoadingTemplate() {
      if(this.overlayLoadingMessage != null) {
        return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${this.overlayLoadingMessage}</span>`;
      } else {
        return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('task.grid.loading') }</span>`;
      }
    }
    , allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    }
    , allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    }
    , disableEdit() {
      return this.taskSelection.length != 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    }
    , disableDelete() {
      return this.taskSelection.length < 1 || this.taskSelection.find(i => i.uuId == 'ROOT') != null;
    }
    , tcShowApplyAllCheckbox() {
      return this.tcConfirmDeleteTasks.length > 0;
    }
    , tcConfirmDeleteStatement() {
      const key = this.taskCol.parentName == null? 'task.confirmation.taskcol_delete_from_project' : 'task.confirmation.taskcol_delete';
      return this.$t(key, [this.taskCol.taskName, this.taskCol.parentName]);
    }
  }
  , watch: {
    'control.startDate': function() {
      this.handleDateRange();
    }
    , 'control.endDate': function() {
      this.handleDateRange();
    }
    , taskIds(newValue) {
      this.actionProcessing = true;
      this.prepareProjectRelatedDetails(newValue);
      this.$nextTick(() => {
        if(this.gridApi) {
          const { top } = this.gridApi.getVerticalPixelRange();
          this.scrollYBeforeReload = top;
          this.scrollXBeforeReload = this.scrollState.left;
          this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
          this.gridApi.refreshServerSide({ purge: true }); //Force reloading data from server.
        }
      });
    }
    , 'scrollState.top': function() {
      this.scrollState.triggeredByLHSGrid = false;
      this.scrollState.triggeredByRHSChart = false;
    }
    , 'control.timescale': function(newValue) {
      if(this.layoutProfile.ganttControlTimescale != newValue) {
        this.layoutProfile.ganttControlTimescale = newValue;
        this.updateLayoutProfile();
      }
    }
    , taskSelection: function(newValue) {
      this.hasAutoAssignTasks = this.autoAssignTasks().length > 0;
      this.taskSelectionIds.splice(0, this.taskSelectionIds.length, ...newValue.map(i => i.uuId));
    }
    , height: function(/** newValue */) {
      this.chartResizeHandler();
    }
  }
  , methods: {
    nameWithRole({text, role}) {
      return `${text} (${role})`
    }
    , projectSelectorOk({ details }) {
      if(details && details.length > 0) {
        this.taskEdit.parentId = details[0].uuId;
        this.taskEdit.parentId = null;
        this.state.taskShow = true;
      }

    }
    , taskNewHandler({ parentId, triggeredByActionBar = false } = {}) {
      this.taskEdit.uuId = `TASK_NEW_${strRandom(5)}`;
      if(triggeredByActionBar) {
        let selectedTaskObj = null;
        if(this.selected.length > 0) {
          selectedTaskObj = this.gridOptions.api.getRowNode(this.selected[0]);
        }
        

        this.taskEdit.parentId = selectedTaskObj? selectedTaskObj.uuId : null;
        this.state.taskEditShow = true;
        this.resetAlert();

        const projectId = selectedTaskObj? selectedTaskObj.projId : null;
        this.projectSelectorEdit.uuId = projectId;
        this.state.projectSelectorShow = true;
        return;
      } else { // triggered By Gantt
        const data = this.ganttData.data;
        if(parentId != null && data && data.length > 0) {
          const selectedTask = data.find(i => i.id === parentId);
          this.projectSelectorEdit.uuId = selectedTask.projId;
        } else {
          this.projectSelectorEdit.uuId = null;
        }
        this.state.projectSelectorShow = true;
      }
    }
    , taskEditHandler({ uuId, triggeredByActionBar = false /*, parentId */} = {}) {
      this.resetAlert();
      if(triggeredByActionBar) {
        const selectedId = this.taskSelection[0].uuId;
        this.taskEdit.uuId = selectedId;
        this.taskEdit.parentId = null;
        const taskObj = this.gridApi.getRowNode(selectedId).data;
        this.taskEdit.projectId = taskObj.projId;
      } else { //triggered By Gantt
        const taskObj = this.ganttData.data.find(i => i.id === uuId);
        this.taskEdit.uuId = uuId;
        this.taskEdit.parentId = null;
        this.taskEdit.projectId = taskObj.projId;
      }
      this.state.taskShow = true;
    }
    , linkEditHandler({ taskId, predecessorId }) {
      this.taskLinkEdit.taskId = taskId;
      this.taskLinkEdit.predecessorId = predecessorId;
      this.state.taskLinkEditShow = true;
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.$emit('ganttMsg', { msg, alertState });
    }
    , taskEditSuccess(/** { id } */) {
      const { top } = this.gridApi.getVerticalPixelRange();
      this.scrollYBeforeReload = top;
      this.scrollXBeforeReload = this.scrollState.left;
      this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      this.gridApi.refreshServerSide({ purge: true });
    }
    , resetPreviousScrollPosition(left, top) {
      // Reset value
      this.scrollState.triggeredByLHSGrid = false;
      this.scrollState.triggeredByRHSChart = false;
      this.scrollState.top = 0; // Reset to 0. So that gantt scroll can be triggered later to return to previous scroll after data reload.
      this.scrollState.left = 0;
      this.$nextTick(() => {
        this.scrollState.triggeredByLHSGrid = true;
        this.scrollState.top = top;
        this.scrollState.left = left;
        const api = this.gridOptions;
        if (api && api.gridBodyCtrl && api.gridBodyCtrl.bodyScrollFeature) {
          if (top > -1) {
            api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);
          } 
        }
      });
    }
    , taskLinkEditSuccess() {
      this.actionProcessing = true;
      const { top } = this.gridApi.getVerticalPixelRange();
      this.scrollYBeforeReload = top;
      this.scrollXBeforeReload = this.scrollState.left;
      this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      this.gridApi.refreshServerSide({ purge: true });
    }
    , handleControlDates(value, updateProfileFlags = null) {
      const today = moment();
      if(value != null) {
        this.datesChangeFlag = true;
      }

      if(this.layoutProfile.ganttControlDates !== value) {
        this.layoutProfile.ganttControlDates = value;
      }

      this.$set(this.control, 'dates', value);
      if ('task-schedule' === value) {
        let srcStartDate = this.taskEarliestDate != null? this.taskEarliestDate.clone() : null;
        let srcCloseDate = this.taskLatestDate != null? this.taskLatestDate.clone() : null;
        
        if (srcStartDate == null || srcCloseDate == null) {
          this.layoutProfile.ganttControlDates = null;
          this.$nextTick(() => {
            this.$set(this.control, 'dates', null);
          })
          let _startDate = null;
          let _closeDate = null;
          if(srcStartDate == null && srcCloseDate == null) {
            _startDate = today.clone();
            _closeDate = _startDate.clone().add(1, 'months');
          } else if (srcStartDate == null) {
            _closeDate = srcCloseDate;
            _startDate = _closeDate.clone().subtract(1, 'months');
          } else {
            _startDate = srcStartDate;
            _closeDate = _startDate.clone().add(1, 'months');
          }
          this.control.startDate = _startDate.format('YYYY-MM-DD');
          this.control.endDate = _closeDate.format('YYYY-MM-DD');
        }
        else {
          this.control.startDate = srcStartDate.format('YYYY-MM-DD');
          this.control.endDate = srcCloseDate.format('YYYY-MM-DD');
        }
      } 
      else if ('this-week' === value) {
        // Get this week's Monday
        this.control.startDate = today.clone().isoWeekday(1).format('YYYY-MM-DD');
        // Get this week's Friday
        this.control.endDate = today.clone().isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('this-week-to-date' === value) {
        this.control.endDate = today.format('YYYY-MM-DD');
        this.control.startDate = today.clone().isoWeekday(1).format('YYYY-MM-DD');
      } 
      else if ('this-month' === value) {
        this.control.endDate = today.clone().endOf('month').format('YYYY-MM-DD');
        this.control.startDate = today.clone().startOf('month').format('YYYY-MM-DD');
      } 
      else if ('this-month-to-date' === value) {
        this.control.endDate = today.format('YYYY-MM-DD');      
        this.control.startDate = today.startOf('month').format('YYYY-MM-DD');
      } 
      else if ('this-quarter' === value) {
        this.control.startDate = today.clone().startOf('quarter').format('YYYY-MM-DD');
        this.control.endDate = today.clone().endOf('quarter').format('YYYY-MM-DD');
      } 
      else if ('this-quarter-to-date' === value) {
        this.control.startDate = today.clone().startOf('quarter').format('YYYY-MM-DD');
        this.control.endDate = today.format('YYYY-MM-DD');
      } 
      else if ('this-year' === value) {
        this.control.startDate = today.startOf('year').format('YYYY-MM-DD');
        this.control.endDate = today.endOf('year').format('YYYY-MM-DD');
      } 
      else if ('this-year-to-date' === value) {
        this.control.startDate = today.startOf('year').format('YYYY-MM-DD');
        this.control.endDate = today.format('YYYY-MM-DD');
      } 
      else if ('last-week' === value) {
        const lastWeek = today.clone().isoWeek(today.isoWeek() - 1);
        this.control.startDate = lastWeek.isoWeekday(1).format('YYYY-MM-DD');
        this.control.endDate = lastWeek.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('last-week-to-date' === value) {
        const lastWeek = today.clone().isoWeek(today.isoWeek() - 1);   
        this.control.startDate = lastWeek.isoWeekday(1).format('YYYY-MM-DD');
        this.control.endDate = today.format('YYYY-MM-DD');
      } 
      else if ('last-month' === value) {
        const lastMonth = today.clone().subtract(1, 'months');
        this.control.startDate = lastMonth.startOf('month').format('YYYY-MM-DD');
        this.control.endDate = lastMonth.endOf('month').format('YYYY-MM-DD');
      } 
      else if ('last-month-to-date' === value) {
        this.control.startDate = today.clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        this.control.endDate = today.format('YYYY-MM-DD');
      } 
      else if ('last-quarter' === value) {
        const lastQuarter = today.clone().subtract(1, 'quarters');
        this.control.startDate = lastQuarter.clone().startOf('quarter').format('YYYY-MM-DD');
        this.control.endDate = lastQuarter.clone().endOf('quarter').format('YYYY-MM-DD');
      } 
      else if ('last-quarter-to-date' === value) {
        this.control.startDate = today.clone().subtract(1, 'quarters').startOf('quarter').format('YYYY-MM-DD');
        this.control.endDate = today.format('YYYY-MM-DD');
      } 
      else if ('last-year' === value) {
        const lastYear = today.clone().subtract(1, 'years');
        this.control.startDate = lastYear.clone().startOf('year').format('YYYY-MM-DD');
        this.control.endDate = lastYear.clone().endOf('year').format('YYYY-MM-DD');
      } 
      else if ('next-week' === value) {
        const nextWeek = today.clone().isoWeek(today.isoWeek() + 1);
        this.control.startDate = today.clone().format('YYYY-MM-DD');
        this.control.endDate = nextWeek.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('next-4-weeks' === value) {
        today.weekday(1);
        const isTodayFirstDayOfWeek = today.isoWeekday() == 1;
        const next4Weeks = today.clone().isoWeek(today.isoWeek() + (isTodayFirstDayOfWeek? 3 : 4));
        this.control.startDate = today.clone().format('YYYY-MM-DD');
        this.control.endDate = next4Weeks.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('next-8-weeks' === value) {
        today.weekday(1);
        const isTodayFirstDayOfWeek = today.isoWeekday() == 1;
        const next8Weeks = today.clone().isoWeek(today.isoWeek() + (isTodayFirstDayOfWeek? 7 : 8));
        this.control.startDate = today.clone().format('YYYY-MM-DD');
        this.control.endDate = next8Weeks.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('next-12-weeks' === value) {
        today.weekday(1);
        const isTodayFirstDayOfWeek = today.isoWeekday() == 1;
        const next12Weeks = today.clone().isoWeek(today.isoWeek() + (isTodayFirstDayOfWeek? 11 : 12));
        this.control.startDate = today.clone().format('YYYY-MM-DD');
        this.control.endDate = next12Weeks.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('next-24-weeks' === value) {
        today.weekday(1);
        const isTodayFirstDayOfWeek = today.isoWeekday() == 1;
        const next24Weeks = today.clone().isoWeek(today.isoWeek() + (isTodayFirstDayOfWeek? 23 : 24));
        this.control.startDate = today.clone().format('YYYY-MM-DD');
        this.control.endDate = next24Weeks.isoWeekday(7).format('YYYY-MM-DD');
      } 
      else if ('next-month' === value) {
        const nextMonth = today.clone().add(1, 'months');
        this.control.startDate = nextMonth.clone().startOf('month');
        this.control.endDate = nextMonth.clone().endOf('month');
      } 
      else if ('next-quarter' === value) {
        const nextQuarter = today.clone().add(1, 'quarters');
        this.control.startDate = nextQuarter.clone().startOf('quarter').format('YYYY-MM-DD');
        this.control.endDate = nextQuarter.clone().endOf('quarter').format('YYYY-MM-DD');
      } 
      else if ('next-year' === value) {
        const nextYear = today.clone().add(1, 'years');
        this.control.startDate = nextYear.clone().startOf('year').format('YYYY-MM-DD');
        this.control.endDate = nextYear.clone().endOf('year').format('YYYY-MM-DD');
      }
      if (this.datesChangeFlag) {
        //controlDates has been set before.
        this.layoutProfile.ganttControlStartDate = this.control.startDate;
        this.layoutProfile.ganttControlEndDate = this.control.endDate;
        this.updateLayoutProfile(updateProfileFlags);
      }
    }
    , httpAjaxError(e) {
      this.$emit('gridGanttError', e);
    }
    , handleDateRange: debounce(function() {
      this.resetAlert();      
      if(!this.datesChangeFlag) { //If it is not triggered by DatesOpt changed, set control.dates to null.
        this.control.dates = null;
        this.layoutProfile.ganttControlDates = this.control.dates;
        this.layoutProfile.ganttControlStartDate = this.control.startDate;
        this.layoutProfile.ganttControlEndDate = this.control.endDate;
        delete this.layoutProfile.startDate;
        delete this.layoutProfile.closeDate;
        this.updateLayoutProfile();
      }
      this.datesChangeFlag = false; //Reset the flag.
      const startDate = this.control.startDate;
      const endDate = this.control.endDate;
      if (!startDate || !endDate || endDate < startDate) {
        this.$emit('ganttMsg', { msg: this.$t('error.invalid_date_range'), isDanger: true });
        return;
      }
      if (this.control.startDate) {
        this.startDate = this.control.startDate;
      }
      if (this.control.endDate) {
        this.endDate = this.control.endDate;
      }
      //Set true to signal dhtmlxGantt vue component to rerender gantt.
      this.pendingRerender = true;
    }, 100)
    , async taskTemplateSelectorForNewTaskOk({ details }) {
      if(details && details.length > 0) {
        this.taskEdit.projectId = details[0].uuId;
        this.taskEdit.parentId = null;
        this.state.taskShow = true;
      }
    }
    , touchStartHandler(e) {
      if('resizer-overlay' == e.target.id) {        
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.add('pressed');
        this.$refs['resizer-overlay'].addEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].addEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].addEventListener('touchend', this.touchEndHandler);
        
        this.touchEvent.isTouchStart = true;
        this.touchEvent.x = e.touches[e.touches.length - 1].clientX;  
        this.touchEvent.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;
      }
    }
    , touchMoveHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        const dx = e.touches[e.touches.length - 1].clientX - this.touchEvent.x;
        const resizer = this.$refs['resizer'];
        const leftSide = this.$refs['lhs-grid'];
        const rightSide = this.$refs['rhs-chart'];

        const newLeftWidth = (this.touchEvent.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;
        rightSide.style.width = `${100 - newLeftWidth}%`;

        this.toggleSplitterResizeStyle(false);
      }
    }
    , toggleUserSelect(element, disableSelect) {
      if(disableSelect) {
        element.style.userSelect = 'none';
        element.style.pointerEvents = 'none';
      } else {
        element.style.removeProperty('user-select');
        element.style.removeProperty('pointer-events');
      }
    }
    , toggleSplitterResizeStyle(state) {
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];
      if(state) {
        this.toggleUserSelect(leftSide, true);
        this.toggleUserSelect(rightSide, true);
      } else {
        this.toggleUserSelect(leftSide, false);
        this.toggleUserSelect(rightSide, false);
      }
      // save the updated splitter position in the settings
      this.layoutProfile.ganttLHSGrid = { width: this.$refs['lhs-grid'].style.width };
      this.layoutProfile.ganttRHSChart = { width: this.$refs['rhs-chart'].style.width };
      if (this.updateLayoutProfileTimeout) {
        clearTimeout(this.updateLayoutProfileTimeout);
      }
      this.updateLayoutProfileTimeout = setTimeout(this.updateLayoutProfile, 1000);
    }
    , touchEndHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    }
    , touchCancelHandler(/*e*/) {
      if(this.touchEvent.isTouchStart) {
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    }
    , mouseDownHandler(e) {
      e.preventDefault();
      this.splitterEventState.isMouseDown = true;
      // Get the current mouse position
      this.splitterEventState.x = e.clientX;
      this.splitterEventState.y = e.clientY;
      this.splitterEventState.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
    }
    , mouseMoveHandler(e) {
      e.preventDefault();
      // How far the mouse has been moved
      const dx = e.clientX - this.splitterEventState.x;
      const resizer = this.$refs['resizer'];
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];

      const newLeftWidth = (this.splitterEventState.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `${100 - newLeftWidth}%`;
      this.toggleSplitterResizeStyle(true);
    }
    , mouseUpHandler(e) {
      e.preventDefault();
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
      this.splitterEventState.isMouseDown = false;
      this.toggleSplitterResizeStyle(false);
    }
    , async onGridReady(params) {
      //Set up a flag when grid is ready before customFields data. 
      //The flag will be used by beforeMount() to continue load layout profile.
      if (!this.isCustomFieldsReady) {
        this.isGridReady = true;
      } else {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        await this.loadLayoutProfile();

        //Initialize the columnCache because the user profile feature is absent.
        if(this.gridColumnApi) {
          this.columnCache = this.gridColumnApi.getAllDisplayedColumns().filter(i => i.colId !== 'ag-Grid-AutoColumn').map(i => i.colId);
        } else {
          this.columnCache = [];
        }

        const self = this;
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
        if(this.loading) {
          this.overlayLoadingMessage = null;// reset to default message.
          this.gridApi.showLoadingOverlay();
        } else {
          this.gridApi.showNoRowsOverlay();
        }
      }
    }
    , chartResizeHandler: debounce(function(/** e */) {
      const windowHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;
      const pagedAGContainerElem = this.$refs['paged-aggrid-gantt-container'];
      const offsetTop = pagedAGContainerElem != null? pagedAGContainerElem.offsetTop : 0;
      const _heightOffset = (this.heightOffset != null && this.heightOffset > -1)? this.heightOffset : 0;
      let availablePAGContainerHeight = this.isWidget ? this.height + 100: windowHeight - offsetTop - 32 - _heightOffset;
      if (availablePAGContainerHeight < 400) {
        availablePAGContainerHeight = 400;
      }
      if (pagedAGContainerElem != null) {
        pagedAGContainerElem.style.height = `${availablePAGContainerHeight}px`;
      }
      this.$set(this.splitterStyle, 'height', `${availablePAGContainerHeight - 90}px`);
      this.$set(this.lhsGridStyle, 'height', `${availablePAGContainerHeight - 90}px`);
      this.$nextTick(() => {
        if(this.$refs['splitter-container']) {
          this.ganttHeight = this.$refs['splitter-container'].clientHeight - 32;// 32 is the height of LHS pagination bar.
        }
      });
    }, 100)
    , ganttScrollHandler: debounce(function({ left, top }) {
      this.scrollState.left = left; //Used in keeping previous x position after gantt data refresh
      if( this.scrollState.top != top && !this.scrollState.triggeredByLHSGrid) {
        this.scrollState.triggeredByLHSGrid = false;
        this.scrollState.triggeredByRHSChart = true;
        this.scrollState.top = top;
        if (this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);
        }
      } else {
        this.scrollState.triggeredByLHSGrid = false;
        this.scrollState.triggeredByRHSChart = false;
      }
    }, 5)
    , async refreshData() {
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }
      this.actionProcessing = true;
      
      const { top } = this.gridApi.getVerticalPixelRange();
      this.scrollYBeforeReload = top;
      this.scrollXBeforeReload = this.scrollState.left;
      this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      this.gridApi.refreshServerSide({ purge: true });
    }
    , taskUpdatedHandler: debounce(async function({
        dragMode
        , taskUuId, taskName
        , progressComplete
        , startDateStr, startTimeStr
        , closeDateStr, closeTimeStr
        , durationDisplay
        , taskAutoScheduleMode
        , lockDuration
        , constraintType, constraintDateStr
        , oldStartDateStr, oldStartTimeStr
        , oldCloseDateStr, oldCloseTimeStr
        , projId
        , projStartDateStr, projCloseDateStr
        , projScheduleFromStart
        , projLocationId
        , progress
        , staffs
        , otherTasks
        }) {

      if (dragMode === 'progress') {
        //Update only progress when it is a progress dragging action
        await this.updateTask('update', [{ uuId: taskUuId, progress }], projId);
        this.refreshData();
        return;
      }

      // let newDateStr = null;
      // let newTimeStr = null;
      let oldDateStr = null;
      let oldTimeStr = null;
      let _startDateStr = startDateStr;
      let _startTimeStr = startTimeStr;
      let _closeDateStr = closeDateStr;
      let _closeTimeStr = closeTimeStr;
      let _durationDisplay = durationDisplay;
      let _trigger = projScheduleFromStart? TRIGGERS.START_DATE : TRIGGERS.CLOSE_DATE;
      let _resizeMode = false;

      //Handle mutiple tasks being moved in chart scenario.
      if (otherTasks != null && Array.isArray(otherTasks) && otherTasks.length > 0) {
        const dcPayloads = [];
        for (const t of otherTasks) {
          let currentTrigger = t.projScheduleFromStart? TRIGGERS.START_DATE : TRIGGERS.CLOSE_DATE;
          let _oldDateStr = null;
          let _oldTimeStr = null;
          if (currentTrigger == TRIGGERS.START_DATE) {
            _oldDateStr = t.oldStartDateStr;
            _oldTimeStr = t.oldStartTimeStr;
          } else if (currentTrigger == TRIGGERS.CLOSE_DATE) {
            _oldDateStr = t.oldCloseDateStr;
            _oldTimeStr = t.oldCloseTimeStr;
          }
          const dcPayload = {
            taskId: t.taskUuId
            , trigger: currentTrigger
            , taskName: t.taskName
            , startDateStr: t.startDateStr
            , startTimeStr: t.startTimeStr
            , closeDateStr: t.closeDateStr
            , closeTimeStr: t.closeTimeStr
            , oldDateStr: _oldDateStr
            , oldTimeStr: _oldTimeStr
            , durationDisplay: t.durationDisplay
            , lockDuration: t.lockDuration
            , constraintType: t.constraintType
            , constraintDateStr: t.constraintDateStr
            , skipOutOfProjectDateCheck: false
            , defaultActionForNonWorkPrompt: null
            , taskAutoScheduleMode: typeof t.taskAutoScheduleMode != 'boolean'? (/true/).test(t.taskAutoScheduleMode) : t.taskAutoScheduleMode //Convert string boolean to boolean type if necessary.
            , projectScheduleFromStart: t.projectScheduleFromStart
            , projectStartDateStr: t.scheduleStartDate
            , projectCloseDateStr: t.scheduleCloseDate
            , resizeMode: false
            , progressComplete: t.progressComplete
            , projLocationId: t.projLocationId
          }

          if (t.staffs.length > 0) {
            dcPayload.staffId = t.staffs[0].uuId;
          } else {
            dcPayload.staffId = null;
          }

          dcPayloads.push(dcPayload);
          this.processTaskMoveChangedList.splice(0, this.processTaskMoveChangedList.length, ...dcPayloads);
        }
      }

      const dragModeTokens = dragMode.split('_');
      if (dragModeTokens[0] == 'resize') {
        _resizeMode = true;
        const isResizeRight = dragModeTokens[1] == 'right';
        _trigger = isResizeRight? TRIGGERS.CLOSE_DATE : TRIGGERS.START_DATE;
      }

       if (_trigger == TRIGGERS.START_DATE) {
        oldDateStr = oldStartDateStr;
        oldTimeStr = oldStartTimeStr;
      } else if (_trigger == TRIGGERS.CLOSE_DATE) {
        oldDateStr = oldCloseDateStr;
        oldTimeStr = oldCloseTimeStr;
      }

      const dc = this.durationCalculation;
      dc.taskId = taskUuId;
      dc.trigger = _trigger;
      dc.taskName = taskName;
      dc.startDateStr = _startDateStr;
      dc.startTimeStr = _startTimeStr;
      dc.closeDateStr = _closeDateStr;
      dc.closeTimeStr = _closeTimeStr;
      dc.oldDateStr = oldDateStr;
      dc.oldTimeStr = oldTimeStr;
      dc.durationDisplay = _durationDisplay;
      dc.lockDuration = lockDuration;
      dc.constraintType = constraintType
      dc.constraintDateStr = constraintDateStr != null && constraintDateStr !== 'Invalid Date'? constraintDateStr : null;
      dc.skipOutOfProjectDateCheck = false;
      dc.defaultActionForNonWorkPrompt = this.isTemplate? 'move' : null;
      dc.taskAutoScheduleMode = taskAutoScheduleMode;
      dc.projectScheduleFromStart = projScheduleFromStart;
      dc.projectStartDateStr = projStartDateStr;
      dc.projectCloseDateStr = projCloseDateStr;
      dc.projectId = projId
      dc.projectLocationId = projLocationId;
      dc.resizeMode = _resizeMode;

      if (staffs.length > 0) {
        this.calendarType.holderId = staffs[0].uuId;
        this.calendarType.type = 'staff';
        dc.enableManualScheduleSuggestion = false;
        dc.defaultActionForNonWorkPrompt = null;
        this.calendar = await staffService.calendar(staffs[0].uuId)
        .then((response) => {
          // combine the calendar lists into single lists for each day
          const data = response.data[response.data.jobCase];
          return transformCalendar(processCalendar(data));
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });

      } else if (projLocationId != null && this.locationCalendarMap.has(projLocationId)) {
        this.calendarType.holderId = this.project.locationId;
        this.calendarType.type = 'project-location';
        this.calendar = this.locationCalendarMap.get(projLocationId);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      } else if (this.systemCalendar != null) {
        this.calendarType.holderId = null;
        this.calendarType.type = 'system';
        this.calendar = this.systemCalendar;
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }

      //defensive code: fallback to default calendar
      if (this.calendar == null) {
        this.calendarType.holderId == null;
        this.calendarType.type = 'system';
        this.calendar = cloneDeep(DEFAULT_CALENDAR);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }
      dc.calendar = this.calendar;

      this.forceReloadAfterDurationCalculation = true;
      
      if (progressComplete) {
        this.state.confirmChangeOnCompleteShow = true;
      } else {
        //Start calculation
        //$nextTick is required to wait for the on-going ui rendering to be done.
        this.$nextTick(() => {
          this.durationCalculationShow = true;
        });
      }
    }, 300)
    , async taskLinkUpdatedHandler(/** id */) {
      const { top } = this.gridApi.getVerticalPixelRange();
      this.scrollYBeforeReload = top;
      this.scrollXBeforeReload = this.scrollState.left;
      this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      this.gridApi.refreshServerSide({ purge: true });
    }
    , changeOnCompleteOk() {
      this.state.confirmChangeOnCompleteShow = false;
      this.$nextTick(() => {
        this.durationCalculationShow = true;
      });
    }
    , changeOnCompleteCancel() {
      //reset state
      this.applyAllChangeOnComplete = false; 
      this.forceReloadAfterDurationCalculation = false;
      this.state.confirmChangeOnCompleteShow = false;
      this.processTaskMoveChangedList.splice(0, this.processTaskMoveChangedList.length);

      if (this.pendingProcessRequestList.length > 0) {
        //When there is at least one task which has been processed before.
        this.processValueChanged(this.gridOptions.api);
        return;
      }

      this.inProgressShow = true;
      this.refreshData(() => {
        this.inProgressShow = false;
      });
      
    }
    , openDetail(id) {
      this.taskEdit.uuId = id;
      this.taskEdit.parentId = null;
      const taskObj = this.gridApi.getRowNode(id).data;
      this.taskEdit.projectId = taskObj.projId;
      this.state.taskShow = true;
      this.resetAlert();
    }
    , detailLinkLabel(params) {
      return params.data.name;
    }
    , detailLinkId(params) {
      return params.data.uuId;
    }
    , rowDeleteHandler(/*{ triggeredByActionBar = false } = {} */) {
      // At the moment, only action bar will trigger.
      this.resetAlert();
      if (this.taskSelection.length == 0) {
        return;
      }
      const taskColTasks = this.getUpperTaskNodesFromSelection();//objectClone(this.taskSelection);
      //Prepare data for taskcol delete confirmation dialog
      this.tcConfirmDeleteTasks = taskColTasks;
      this.prepareTaskColConfirmDeleteDialog();
    }
    , async confirmDeleteOk() { 
      // const selectedNodes = this.gridOptions.api.getSelectedNodes();
      // const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`task.delete${toDeleteIds.length > 1? '_plural':''}`);
      // let alertMsgDetailTitle = null;
      // let alertMsgDetailList = [];

      if (toDeleteIds.length > 0) {
        this.showInProgress(this.$t('task.progress.deleting'));
        this.actionProcessing = true;
      }

      await taskService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('task.delete_partial');
          // alertMsgDetailTitle = this.$t(`task.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          // const feedbackList = response.data[response.data.jobCase];
          // for (let i = 0, len = feedbackList.length; i < len; i++) {
          //   const feedback = feedbackList[i];
          //   if (feedback.clue == 'OK') {
          //     continue;
          //   }
          //   const targetId = toDeleteIds[i].uuId;
          //   const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
          //   alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          // }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`task.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          // if (response.status == 422) {
          //   alertMsgDetailTitle = this.$t(`task.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          //   const feedbackList = response.data[response.data.jobCase];
          //   for (let i = 0, len = feedbackList.length; i < len; i++) {
          //     const feedback = feedbackList[i];
          //     if (feedback.clue == 'OK') {
          //       continue;
          //     }
          //     const targetId = toDeleteIds[i].uuId;
          //     const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
          //     alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          //   }
          // } else 
          if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        const { top } = this.gridApi.getVerticalPixelRange();
        this.scrollYBeforeReload = top;
        this.scrollXBeforeReload = this.scrollState.left;
        this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
        this.gridApi.refreshServerSide({ purge: true });
        this.$emit('ganttMsg', { msg: this.$t(toDeleteIds.length > 1? 'task.delete_plural' : 'task.delete'), isDanger: false });
      } else {
        this.$emit('ganttMsg', { msg: alertMsg , isDanger: true });
      }

      this.inProgressShow = false;
      this.actionProcessing = false;
    }
    , showInProgress(label=null, isStoppable=false) {
      this.inProgressState.cancel = false;
      this.inProgressShow = true;
      this.inProgressLabel = label;
      this.inProgressStoppable = isStoppable;
    }
    , progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
      this.inProgressLabel = this.$t('task.progress.stopping');
    }
    , fileExportHandler() {
      this.resetAlert();
      const keys = this.gridOptions.columnApi
          .getAllDisplayedColumns()
          .map(column => column.getColId());
      if (!keys.includes('taskPath')) {
        keys.push('taskPath');
      }
      if (!keys.includes('taskType')) {
        keys.push('taskType');
      }
      const self = this;
      this.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Tasks'
        , sheetName: 'Tasks'
        , rowHeight: 20
        , processCellCallback: TaskTemplateDataUtil.processCellCallback(self)
      });
    }
    , isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }

      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    }
    , autoAssignStaffHandler() {
      this.resetAlert();
      this.autoAssignStaffShow = true;
    }
    , autoAssignStaffSuccess(result) {
      this.autoAssignSettings = cloneDeep(result);
      this.showInProgress(this.$t('task.progress.assigning_staff'), true);
      this.allocateStaff(this.autoAssignSettings.staffList);     
    }
    , async allocateStaff(staffList) {
      var skillMatchList = [];
      const settings = this.autoAssignSettings.settings;
            
      if (settings.match_staff_based_on_skills) {
        skillMatchList.push({ 'level': 'Yes' });
      }
      else {
        skillMatchList.push({ 'level': 'No' });
      }
      if (settings.include_staff_exact_skill) {
        skillMatchList.push({ 'level': 'Exact' });
      }
      if (settings.include_staff_lower_skill) {
        skillMatchList.push({ 'level': 'Low', 'changeDuration': settings.adjust_task_duration_lower });
      }
      if (settings.include_staff_higher_skill) {
        skillMatchList.push({ 'level': 'High', 'changeDuration': settings.adjust_task_duration_higher });
      }
      const taskList = this.autoAssignTasks();
      for (let idx = 0; idx < taskList.length; idx+=10) {
        if (this.inProgressState.cancel) {
          break;
        }
        const list = taskList.slice(idx, idx + 10).map(t => { return { uuId: t.uuId } });
        this.inProgressLabel = this.$t('task.progress.assigning_staff_to_plural', [`${Math.trunc(idx / taskList.length * 100)}%`]);
        let data = await staffService.allocation({}, {
                    type: "Assign",
                    includeAssignedTask: !settings.skip_already_assigned,
                    includeStartedTask: !settings.skip_already_started,
                    overAllocateStaff: settings.allow_over_alloc,
                    skillMatchList: skillMatchList,
                    staffList: staffList, 
                    taskList: list
                  })
        .then(response => {
          return response.data[response.data.jobCase].length !== 0 &&
                    response.data[response.data.jobCase][0].length !== 0 ? response.data[response.data.jobCase][0] : [];
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          return [];
        });

        
        for (let j = 0; j < data.length; j++) {
          this.autoAssignSummary.push(data[j]);
        }
      } 
      this.autoAssignSummaryShow = true;
      const { top } = this.gridApi.getVerticalPixelRange();
      this.scrollYBeforeReload = top;
      this.scrollXBeforeReload = this.scrollState.left;
      this.selectedBeforeReload = this.gridApi.getSelectedNodes().map(i => { return i.data.uuId });
      this.gridApi.refreshServerSide({ purge: true });
      
    }
    , getPathNames(value) {
      //There are two possible data type:
      // 1. String data type when taskIds are provided as prop. 
      // 2. Object with sample { name, path } when projectId is provided as prop.
      // Note: When both taskIds and projectId are provided, projectId has high priority.
      
      if('string' === typeof value) {
        return value;
      }
      const path = value.path;
      if(!path) {
        return value.name;
      }

      var names = '';
      for (var idx = 0; idx < path.length; idx++) {
        names += ' / ';
        names += this.taskNames[path[idx]].name;
      }
      return names;
    }
    , autoAssignSummaryOk() {
      this.autoAssignSummary = [];
    }    
    , async prepareTaskEarliestAndLatestDate(taskIds) {
      if (!taskIds) {
        return;
      }
      
      let min = null;
      let max = null;
      
      for (let i = 0; i < taskIds.length; i+= 200) {
        const ids = taskIds.slice(i, i + 200);
        if (ids.length !== 0) {
          // const result = 
          await aggridGanttService.span({ taskIds: ids})
          .then(response => {
            if (min === null ||
                (response.min !== 0 && response.min !== 32400000 && response.min < min)) {
              min = response.min;
            }
            if (max === null ||
                (response.max !== 0 && response.max > max)) {
              max = response.max;  
            }
          })
          .catch(e => {
            this.httpAjaxError(e);
          });
        }
      }      
      this.savedTaskEarliestDate = this.taskEarliestDate = min != 0? moment(min) : null;
      this.savedTaskLatestDate = this.taskLatestDate = max != 0? moment(max) : null;
      this.handleControlDates('task-schedule', { clearViewName: false }); // show the "task-schedule" by default
    }
    , async updateTimeline() {
      const option = this.control.dates;
      if ('task-schedule' === option) {
        // we get the task dates from the paged tasks
        this.taskEarliestDate = this.savedTaskEarliestDate;
        this.taskLatestDate = this.savedTaskLatestDate;
      }
    }
    , async locationCalendar(locationId, callback=null) {
      let data = await locationService.calendar(locationId)
      .then(response => {
        return (response && response.data && response.data.jobCase? response.data[response.data.jobCase] : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      let calendar = DEFAULT_CALENDAR;
      if (data.length > 0) {
        calendar = this.digestCalendarResponse(data);
      }
      if (callback == null || typeof callback !== 'function') {
        this.$set(this, 'calendar', calendar);
      } else {
        callback(locationId, calendar);
      }
    }
    , async systemLocationCalendar(callback) {
      const uuId = '00000000-0000-0000-0000-000000000000';
      let data = await calendarService.get([{ uuId }])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      let calendar = DEFAULT_CALENDAR;
      if (data.length > 0) {
        calendar = this.digestCalendarResponse(data, ['base_calendar']);
        this.systemCalendar = JSON.parse(JSON.stringify(calendar));
      }
      if (callback == null || typeof callback !== 'function') {
        this.calendarType.holderId = null;
        this.calendarType.type = 'system';
        this.$set(this, 'calendar', calendar);
      } else {
        callback(null, calendar);//Return null as id.
      }
    }
    , digestCalendarResponse(data, calendarOrder=['location','base_calendar']) {
      const calendar = {};
      // const calendarOrder = ['location','base_calendar'];
      const existingTypes = [];
      for(const order of calendarOrder) {
        const calObj = data.find(i => i.name === order); 
        if(!calObj) {
          continue;
        }
        const calendarList = calObj.calendarList;
        
        for(const type of calendarList) {
          if (!type || type.length < 1 || (calendar[type.type] && calendar[type.type][0].calendar !== order)) {
            continue;
          }
          if(!calendar[type.type]) {
            existingTypes.push(type.type);
            calendar[type.type] = [];
          }
          const cloned = cloneDeep(type);
          cloned.calendar = order;
          if(cloned.startHour != null) {
            cloned.startHour = msToTime(cloned.startHour);
          }
          if(cloned.endHour != null) {
            cloned.endHour = msToTime(cloned.endHour);
          }
          calendar[type.type].push(cloned);
        }
      }

      return calendar;
    }
    , async prepareProjectRelatedDetails(/**entity, filter */) {
      if (this.taskIds.length == 0) {
        return;
      }

      this.projectStagesMap.clear();
      this.locationCalendarMap.clear();

      const result = [];
      let hasFirstValidLocationId = false;
    
      const _result = await aggridGanttService.listTaskProjectDetails();
      
      if (_result != null && _result.length > 0) {
        if (!hasFirstValidLocationId) {
          const found =_result.find(v => v.projLocationId != null);
          const firstLocationId = found != null? found.projLocationId : null;
          if (firstLocationId != null) {
            hasFirstValidLocationId = true;
            this.calendarType.holderId = this.project.locationId;
            this.calendarType.type = 'project-location';
            this.locationCalendar(firstLocationId, (id, calendar) => {
              this.projectCalendar = JSON.parse(JSON.stringify(calendar));
              this.locationCalendarMap.set(id, calendar);
              this.$set(this, 'calendar', calendar);
            });
          }
        }
        result.push(..._result);
      }

      if (!hasFirstValidLocationId) {
        this.systemLocationCalendar((id, calendar) => {
          this.locationCalendarMap.set(id, calendar);
          this.$set(this, 'calendar', calendar);
        });
      }

      
      if(result != null && result.length > 0) {
        for(let i = 0, len = result.length; i < len; i++) {
          //Stages
          const projId = result[i].projId;
          if (!this.projectStagesMap.has(projId)) {
            this.projectStagesMap.set(projId, result[i].stages.map(i => { return { text: i.name, value: i.uuId }}));
          }
          
          //Calendars
          const projLocationId = result[i].projLocationId;
          if (!this.locationCalendarMap.has(projLocationId)) {
            this.locationCalendarMap.set(projLocationId, null);
            if(projLocationId != null) {
              this.locationCalendar(projLocationId, (id, calendar) => {
                this.locationCalendarMap.set(id, calendar);
              });
            }
          }
        }
      }

      if (this.calendar == null) {
        this.calendar = DEFAULT_CALENDAR;
        this.locationCalendarMap.set(null, this.calendar);
      }
    }
    , async dateTimeDurationValueChanged(taskId, property, newValue, rowData, { skipOutOfProjectDateCheck=false, defaultActionForNonWorkPrompt=null, oldValue=null }={}) {
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }
      //Change lock state to true to prevent other thread calling dateTimeDurationValueChanged.
      //Reason: The TaskDateTimeDurationCalculation modal can only be called one at a time.
      this.isDateCalcInProgress = true;

      let trigger = TRIGGERS.DURATION;
      if (property == 'startTime') {
        trigger = TRIGGERS.START_DATE;
      } else if (property == 'closeTime') {
        trigger = TRIGGERS.CLOSE_DATE;
      } else if (property == 'constraint') {
        trigger = TRIGGERS.CONSTRAINT_TYPE;
      } else if (property == 'autoScheduling') {
        trigger = TRIGGERS.TASK_SCHEDULE_MODE;
      }

      const projectScheduleFromStart = rowData.projScheduleMode != null? rowData.projScheduleMode == 'ASAP' : true;
      const projectStartDateStr = rowData.projScheduleStart != null? moment.utc(rowData.projScheduleStart).format('YYYY-MM-DD') : null;
      const projectCloseDateStr = rowData.projScheduleFinish != null? moment.utc(rowData.projScheduleFinish).format('YYYY-MM-DD') : null;
      
      const nodeData = rowData;
      let newDateStr = null;
      let newTimeStr = null;
      let newConstraintType = null;
      let newConstraintDateStr = null;
      let newAutoScheduling = null;
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.CLOSE_DATE == trigger) {
        const newDateTime = newValue != null? moment.utc(newValue) : null;
        if (newDateTime != null) {
          newDateStr = newDateTime.format('YYYY-MM-DD');
          newTimeStr = newDateTime.format('HH:mm');
        }
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        if (newValue != null) {
          newConstraintType = newValue.type;
          newConstraintDateStr = newValue.time != null? moment.utc(newValue.time).format('YYYY-MM-DD') : null;
        }
      } else if (TRIGGERS.TASK_SCHEDULE_MODE == trigger) {
        if (newValue != null) {
          newAutoScheduling = newValue;
        }
      }
      let startDateTime = nodeData.startTime != null? moment.utc(nodeData.startTime) : null;
      if (TRIGGERS.START_DATE == trigger && oldValue != null) {
        startDateTime = moment.utc(oldValue);
      }
      let closeDateTime = nodeData.closeTime != null? moment.utc(nodeData.closeTime) : null;
      if (TRIGGERS.CLOSE_DATE == trigger && oldValue != null) {
        closeDateTime = moment.utc(oldValue);
      }
      
      const constraintDateTime = nodeData.constraint.time != null? moment.utc(nodeData.constraint.time) : null;
      
      const startDateStr = startDateTime != null? startDateTime.format('YYYY-MM-DD') : null;
      const startTimeStr = startDateTime != null? startDateTime.format('HH:mm') : null;
      const closeDateStr = closeDateTime != null? closeDateTime.format('YYYY-MM-DD') : null;
      const closeTimeStr = closeDateTime != null? closeDateTime.format('HH:mm') : null;
      const constraintType = nodeData.constraint.type != null? nodeData.constraint.type : null;
      const constraintDateStr = constraintDateTime != null? constraintDateTime.format('YYYY-MM-DD') : null;

      const dc = this.durationCalculation;
      dc.taskId = taskId;
      dc.trigger = trigger;
      dc.taskName = nodeData.name;
      dc.resizeMode = false; //default (false): task move action.
      if (TRIGGERS.START_DATE == trigger && !projectScheduleFromStart) {
        dc.resizeMode = true;
      } else if (TRIGGERS.CLOSE_DATE == trigger && projectScheduleFromStart) {
        dc.resizeMode = true;
      }
       
      this.calendar = null;
      if (nodeData.staffs.length > 0) {
        this.calendarType.holderId = nodeData.staffs[0].uuId;
        this.calendarType.type = 'staff';
        dc.enableManualScheduleSuggestion = false;
        dc.defaultActionForNonWorkPrompt = null;
        this.calendar = await staffService.calendar(nodeData.staffs[0].uuId)
        .then((response) => {
          // combine the calendar lists into single lists for each day
          const data = response.data[response.data.jobCase];
          return transformCalendar(processCalendar(data));
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });

      } else if (this.locationCalendarMap.has(nodeData.projLocationId)) {
        this.calendarType.holderId = nodeData.projLocationId;
        this.calendarType.type = 'project-location';
        this.calendar = this.locationCalendarMap.get(nodeData.projLocationId);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      } else if (this.systemCalendar != null) {
        this.calendarType.holderId = null;
        this.calendarType.type = 'system';
        this.calendar = this.systemCalendar;
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }

      //defensive code: fallback to default calendar
      if (dc.calendar == null) {
        this.calendarType.holderId == null;
        this.calendarType.type = 'system';
        this.calendar = cloneDeep(DEFAULT_CALENDAR);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }
      dc.calendar = this.calendar;
      
      dc.oldDateStr = null;
      dc.oldTimeStr = null;
      if (TRIGGERS.START_DATE == trigger) { // || TRIGGERS.START_TIME == trigger) {
        dc.startDateStr = newDateStr;
        dc.startTimeStr = newTimeStr;
        dc.oldDateStr = startDateStr;
        dc.oldTimeStr = startTimeStr;
      } else {
        dc.startDateStr = startDateStr;
        dc.startTimeStr = startTimeStr;
      }
      if (TRIGGERS.CLOSE_DATE == trigger) { // || TRIGGERS.CLOSE_TIME == trigger) {
        dc.closeDateStr = newDateStr;
        dc.closeTimeStr = newTimeStr;
        dc.oldDateStr = closeDateStr;
        dc.oldTimeStr = closeTimeStr;
      } else {
        dc.closeDateStr = closeDateStr;
        dc.closeTimeStr = closeTimeStr;
      }

      //Check if trigger is time related
      if (TRIGGERS.START_DATE == trigger && dc.startDateStr == dc.oldDateStr) {
        dc.trigger = trigger = TRIGGERS.START_TIME;
      } else if (TRIGGERS.CLOSE_DATE == trigger && dc.closeDateStr == dc.oldDateStr) {
        dc.trigger = trigger = TRIGGERS.CLOSE_TIME;
      }

      //Make sure duration value is in string format
      let rawDuration = nodeData.duration;
      if (rawDuration != null && typeof rawDuration != 'string') {
        rawDuration = convertDurationToDisplay(rawDuration, nodeData.durationAUM != null? nodeData.durationAUM: 'D');
      }
      dc.durationDisplay = TRIGGERS.DURATION == trigger? newValue : rawDuration;
      dc.lockDuration = nodeData.lockDuration != null? nodeData.lockDuration : false;
      
      if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        dc.constraintType = newConstraintType;
        dc.constraintDateStr = newConstraintDateStr;
        dc.oldConstraintType = constraintType;
        dc.oldConstraintDateStr = constraintDateStr;
      } else {
        dc.constraintType = constraintType;
        dc.constraintDateStr = constraintDateStr;
      }
      
      dc.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck == true;

      //Override defaultActionForNonWorkPrompt when provided parameter is not null
      if (defaultActionForNonWorkPrompt != null) {
        dc.defaultActionForNonWorkPrompt = defaultActionForNonWorkPrompt;
      }
      
      dc.taskAutoScheduleMode = newAutoScheduling != null? newAutoScheduling : (nodeData.autoScheduling != null? nodeData.autoScheduling : true);
      dc.projectScheduleFromStart = projectScheduleFromStart;
      dc.projectStartDateStr = projectStartDateStr;
      dc.projectCloseDateStr = projectCloseDateStr;
      dc.projectLocationId = nodeData.projLocationId;
      
      //Start calculation
      //$nextTick is required to wait for the on-going ui rendering to be done.
      this.$nextTick(() => {
        this.durationCalculationShow = true;
      });
    }
    , async durationCalculationOk({ skip=false, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr, taskAutoScheduleMode=null }) {
      this.forceReloadAfterDurationCalculation = false; //reset the state
      this.isDateCalcInProgress = false;
      if (skip) {
        //call taskMoveChanged() instead of calling processValueChanged() when multiple task involved in task move (RHS chart) event.
        if (this.processTaskMoveChangedList.length > 0) {
          this.taskMoveValueChanged();
          return;
        }

        //Call processValueChanged() for next iteration.
        this.processValueChanged(this.gridOptions.api);
        return;
      }

      const task = { uuId: this.durationCalculation.taskId }
      if (startDateStr != null) {
        const startDateTime = moment.utc(startDateStr, 'YYYY-MM-DD');
        if (startTimeStr != null) {
          const token = startTimeStr.split(':');
          startDateTime.hour(token[0]).minute(token[1]);
        }
        task.startTime = startDateTime.valueOf();
      } else {
        task.startTime = null;
      }

      if (closeDateStr != null) {
        const closeDateTime = moment.utc(closeDateStr, 'YYYY-MM-DD');
        if (closeTimeStr != null) {
          const token = closeTimeStr.split(':');
          closeDateTime.hour(token[0]).minute(token[1]);
        }
        task.closeTime = closeDateTime.valueOf();
      } else {
        task.closeTime = null;
      }

      if (taskAutoScheduleMode != null) {
        task.autoScheduling = taskAutoScheduleMode;
      } else if (TRIGGERS.TASK_SCHEDULE_MODE === this.durationCalculation.trigger) {
        task.autoScheduling = this.durationCalculation.taskAutoScheduleMode;
      }

      if (durationDisplay != null) {
        const { value } = convertDisplayToDuration(durationDisplay);
        const { unit } = analyzeDurationAUM(durationDisplay);
        task.durationAUM = unit;
        task.duration = value;
      } else if (task.autoScheduling == true) {
        task.durationAUM = 'D';
        task.duration = 480; // 1 day = 480 minutes
      } else {
        task.duration = null; 
      }

      task.constraintType = constraintType;
      if (constraintDateStr != null) {
        task.constraintTime = moment.utc(constraintDateStr, 'YYYY-MM-DD').valueOf();
      } else {
        task.constraintTime = null;
      }

      this.pendingProcessRequestList.push({
        method: 'PUT',
        invoke: `/api/task/update`,
        body: [task],
        vars: [],
        note: `taskUpdate__${task.uuId}`
      });

      //call taskMoveChanged() instead of calling processValueChanged() when multiple task involved in task move (RHS chart) event.
      if (this.processTaskMoveChangedList.length > 0) {
        this.taskMoveValueChanged();
        return;
      }

      //Last, call processValueChanged() for next iteration
      this.processValueChanged(this.gridOptions.api);
    }
    , durationCalculationCancel() {
      this.isDateCalcInProgress = false;
      //2024-04-18 logic update: when user chose to abort the operation, commit the requests being processed/generated previously while abort the rest.

      this.processDateValueChangedList.splice(0, this.processDateValueChangedList.length);
      this.processValueChangedList.splice(0, this.processValueChangedList.length);
      this.applyAllChangeOnComplete = false;

      if (this.pendingProcessRequestList.length > 0) {
        this.forceReloadAfterDurationCalculation = false; //reset the state
        this.processValueChanged(this.gridOptions.api);
        return;
      }

      if (this.forceReloadAfterDurationCalculation) {
        this.refreshData(() => {
          this.forceReloadAfterDurationCalculation = false; //reset the state
          this.inProgressShow = false;
        });
        return;
      }

      //Revert the affected row data to original value
      if (this.originalRowDataList.length > 0) {
        const api = this.gridOptions.api;
        const oldData = JSON.parse(JSON.stringify(this.originalRowDataList));
        
        for (const d of oldData) {
          const found = api.getRowNode(d.uuId);
          if (found == null) {
            continue;
          }
          found.updateData(d);
        }
        api.refreshCells({ force: true, rowNodes: oldData });
        this.originalRowDataList.splice(0, this.originalRowDataList.length);
      }
      this.forceReloadAfterDurationCalculation = false; //reset the state
      this.inProgressShow = false;
      return;
    }
    , async durationCalculationCalendarChange({ toAddExceptions, toUpdateExceptions /**, skipOutOfProjectDateCheck */}) {
      //Note: This event will not be called when projectLocationId is null. Otherwise, something goes wrong in dateTimeDurationValueChanged()
      //1. Call calendar service (API) to add or update the exceptions
      //2. Update the calendar object
      //3. Call calcDateTimeDuration() to restart calculation with updated calendar object.
      //4. Reload latest calendar from backend for future usage.

      let hasError = false;
      const errorMsg = this.$t(`calendar.error.failed_to_update_calendar`);
      const calendar = this.durationCalculation.calendar;
      const locationId = this.calendarType?.holderId ?? null;

      // Defensive code: Theorectically durationCalculationCalendarChange() will only be call when calendarType.type is 'staff'. 
      // When it is not staff, abort operation.
      if (this.calendarType.type != 'staff') {
        return;
      }
      
      if (toUpdateExceptions != null && toUpdateExceptions.length > 0) {
        const _toUpdateExceptions = cloneDeep(toUpdateExceptions);
        _toUpdateExceptions.forEach(i => {
          delete i.calendar;
        });
        await calendarService.update(_toUpdateExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
            this.$emit('ganttMsg', { msg: errorMsg, isDanger: true });
            return;
          }

          //Update the calendar object with the change
          if (calendar.Leave == null) {
            calendar.Leave = [];
          }
          for (let i = 0, len = _toUpdateExceptions.length; i < len; i++) {
            const curException = _toUpdateExceptions[i];
            //When exception type is Working, add it to calendar.Working
            if (curException.type == 'Working') {
              if (calendar.Working == null) {
                calendar.Working = [];
              }
              calendar.Working.push(cloneDeep(curException));
            }

            //Remove old leave exception if exception type is 'Working'. Otherwise, update the old leave exception.
            const idx = calendar.Leave.findIndex(j => j.uuId === curException.uuId);
            if (idx != -1) {
              if (curException.type == 'Working') {
                calendar.Leave.splice(idx, 1);
              } else {
                calendar.Leave[idx] = cloneDeep(curException);
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
          this.$emit('ganttMsg', { msg: errorMsg, isDanger: true });
        });
      }

      //Stop proceed further when failed to update calendar exception.
      if (hasError) {
        this.durationCalculationCancel();
        return;
      }

      if (toAddExceptions != null && toAddExceptions.length > 0) {
        await calendarService.create(toAddExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
            this.$emit('ganttMsg', { msg: errorMsg, isDanger: true });
          } else { //
            //Fill the uuId to exception and add the newly created exception to calendar object
            const list = response.data[response.data.jobCase];
            for (let i = 0, len = list.length; i < len; i++) {
              const curItem = list[i];
              if (curItem && curItem.uuId != null) {
                const curException =  toAddExceptions[i];
                curException.uuId = curItem.uuId;
                if (calendar[curException.type] == null) {
                  calendar[curException.type] = [];
                }
                calendar[curException.type].push(cloneDeep(curException));
              } else {
                hasError = true;
                this.alertError = true;
                this.alertMsg = errorMsg;
                this.$emit('ganttMsg', { msg: errorMsg, isDanger: true });
                break;
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
          this.$emit('ganttMsg', { msg: errorMsg, isDanger: true });
        });
      }

      if (hasError) {
        this.durationCalculationCancel();
      } else {
        const dc = this.durationCalculation;
        dc.calendar = this.calendar;
        dc.skipOutOfProjectDateCheck = true; //Set true. Reason: Usually user choose to proceed in the projectDateCheck logic before reaching this calendarChange logic.
        
        this.$nextTick(() => {
          this.durationCalculationShow = true;
        });
      }
    }
    , async updateTask(method, data, projectId) {
      this.showInProgress(this.$t('task.progress.updating_task'));
      const result = {
        hasError: false,
        msg: this.$t(`task.${method}`)
      }
      const service = taskService;
      let taskId = await service[method](data, projectId)
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`task.error.failed_to_${method}_task`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          let hasFieldError = false;
          const clues = ['missing_argument', 'invalid_value', 'column_limit_exceeded']
          for(let i = 0, len = list.length; i < len; i++) {
            if(clues.includes(list[i].clue)) {
              let spot = list[i].spot || [];
              spot = spot.length > 0? spot[0] : '';
              spot = spot.length == 1? spot.toLowerCase() : spot.length > 1? spot.charAt(0).toLowerCase() + spot.substring(1) : spot;
              this.errors.add({
                field: `task.${spot}`,
                msg: this.$t(`error.${list[i].clue}`, [this.$t(`task.field.${spot}`)])
              });
              hasFieldError = true;
            }
          }
          if(hasFieldError) {
            result.msg = this.$t(`error.attention_required`);
          }
        }
      });
      result.taskId = taskId;
      return result;
    }
    , async populateTaskConstraint() {
      if (this.optionConstraint.length !== 0) {
        return;
      }
      
      const service = taskService;
      let list = await service.optionConstraint()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      
      this.optionConstraint.splice(0, this.optionConstraint.length, ...list.map(i => { 
        return { value: i.label, text: this.$t(`constraint_type.${i.label}`)} 
      }));
    }
    , async updateTags(taskId, oldTagList, newTagList) {
      const oldTagNames = objectClone(oldTagList);
      const oldTagFilters = [];
      for(const t of oldTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        oldTagFilters.push({ value: t.trim(), regex: false });
      }      
      let oldTags;
      if (oldTagFilters.length > 0) {
        oldTags = await tagService.list_with_filters({filters: oldTagFilters}).then((response) => {
          return response.data.filter(i => i.uuId != null);
        });
      } else {
        oldTags = [];
      }

      const newTagNames = objectClone(newTagList);
      const newTagFilters = [];
      for(const t of newTagNames) {
        if (t == null || t.trim().length == 0) {
          continue;
        }
        newTagFilters.push({ value: t.trim(), regex: false });
      }      
      let newTags;
      if (newTagFilters.length > 0) {
        newTags = await tagService.list_with_filters({filters: newTagFilters}).then((response) => {
          const data = response.data;
          const list = [];
          for (const f of newTagFilters) {
            const found = data.find(i => i.name.localeCompare(f.value, undefined, { sensitivity: 'base' }) === 0);
            if (found != null) {
              list.push(found);
            }else {
              list.push({ uuId: null, name: f.value });
            }
          }
          return list;
        });
      } else {
        newTags = [];
      }
      return TaskViewRequestGenerator.updateTag(taskId, oldTags, newTags);
    }
    , maintainGridHorinzontalScrollbar() {
      const gridBodyHScrollElem = document.querySelector('#paged-grid .ag-body-horizontal-scroll');
      
      let hScrollHeight = gridBodyHScrollElem ? gridBodyHScrollElem.style.height : 0;
      hScrollHeight = parseInt(hScrollHeight);
      if (isNaN(hScrollHeight)) {
        hScrollHeight = 0;
      }
      const toggleVisibility = function(elem, show) {
        const value = show? '15px': '0px';
        elem.style.height = value;
        elem.style.minHeight = value;
        elem.style.maxHeight = value;
      }
      
      if(hScrollHeight > 0 && this.gridHorizontalScrollbarElem != null) {
        this.gridHorizontalScrollbarElem.parentNode.removeChild(this.gridHorizontalScrollbarElem);
        this.gridHorizontalScrollbarElem = null;
      }
      if (hScrollHeight == 0 && this.gridHorizontalScrollbarElem == null) {
        this.gridHorizontalScrollbarElem = gridBodyHScrollElem.cloneNode(true);
        this.gridHorizontalScrollbarElem.classList.add('ag-body-horizontal-scroll-absent');
        this.gridHorizontalScrollbarElem.classList.remove('ag-body-horizontal-scroll');

        const clonedViewportElem = this.gridHorizontalScrollbarElem.querySelector('.ag-body-horizontal-scroll-viewport');
        toggleVisibility(this.gridHorizontalScrollbarElem, true);
        toggleVisibility(clonedViewportElem, true);

        const parentNode = gridBodyHScrollElem.parentNode;
        parentNode.insertBefore(this.gridHorizontalScrollbarElem, gridBodyHScrollElem);
      }
    }
    , async taskTemplateSelectorOk({ ids }, override=false) {
      if (ids && ids.length > 0) {
        this.showInProgress(this.$t('task.progress.insert_template'));
        this.actionProcessing = true;
        const templateProjectId = ids[0];

        const parentIds = this.applyTemplateEdit.parentIds != null? cloneDeep(this.applyTemplateEdit.parentIds) : [];
        if (parentIds.length == 0) {
          parentIds.push(this.projectId);
        }
        this.applyTemplateEdit.parentIds = null; //Reset value after the values are passed to local variable.

        const requests = [];
        for (let i = 0, len = parentIds.length; i < len; i++) {
          requests.push(taskService.applyTaskTemplate(parentIds[i], templateProjectId, override));
        }

        if(requests.length > 0) {
          Promise.all(requests)
          .then(() => {
            this.inProgressShow = false;
            this.refreshData();
          })
          .catch(() => {
            this.alertError = true;
            const msg = this.$t('task.error.failed_to_apply_template');
            this.alertMsg = msg;
            this.$emit('ganttMsg', { msg, isDanger: true });
            this.inProgressShow = false;
          });  
        } else {
          //Do nothing as no request
          this.inProgressShow = false;
        }
      }
    }
    , initializeLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'ganttColumns')) {
        this.layoutProfile.ganttColumns = [];
        this.useDefault = true;
        const defaultView = this.ganttViews.find(v => v.defaultView);
        if (defaultView) {
          this.loadViewSettings({view: defaultView});
        }
      }
    }
    , createLayoutProfile() {
      this.initializeLayoutProfile();
      const self = this;
      layoutProfileService.create([this.layoutProfile], this.entityId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        self.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , updateLayoutProfile({ clearViewName=true } = {}) {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
      
      // clear the view name from the breadcrumb
      if (clearViewName) {
        this.layoutProfile.ganttViewName = null;
        this.$store.dispatch("breadcrumb/clearView");
      }
      
      layoutProfileService.update([this.layoutProfile], this.entityId, this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      })
    }
    , async loadLayoutProfile() {
      const self = this;
      const userId = this.isWidget && this.widgetOwner ? this.widgetOwner : this.userId;
      const profileData = await layoutProfileService.list(this.entityId, userId).then((response) => {
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return [];
      });
      
      if (profileData.length === 0) {
        await self.createLayoutProfile();
      } else {
        self.layoutProfile = profileData[0];
        self.initializeLayoutProfile();
        
        // Remove sort property in ganttColumns settings, sorting is not permitted
        // at the moment. Sorting breaks the synchonization of record order between
        // lhs grid and rhs gantt.
        const columns = self.layoutProfile.ganttColumns;
        for(let i = 0, len = columns.length; i < len; i++) {
          delete columns[i].sort;
          // Ensure task is always visible
          if (columns[i].field == "name") {
            columns[i].hide = false;
          }
        }

        self.loadColumnSettings(columns);

        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlTimescale')) {
          self.$set(self.control, 'timescale', self.layoutProfile['ganttControlTimescale']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlCriticalPath')) {
          self.$set(self.control, 'criticalPath', self.layoutProfile['ganttControlCriticalPath']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlFreeFloat')) {
          self.$set(self.control, 'freeFloat', self.layoutProfile['ganttControlFreeFloat']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlDates')) {
          self.$set(self.control, 'dates', self.layoutProfile['ganttControlDates']);
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlStartDate')) {
          self.$set(self.control, 'startDate', self.layoutProfile['ganttControlStartDate']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlEndDate')) {
          self.$set(self.control, 'endDate', self.layoutProfile['ganttControlEndDate']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlStartDate') || Object.prototype.hasOwnProperty.call(self.layoutProfile, 'ganttControlEndDate')) {
          self.datesChangeFlag = true;
          self.handleDateRange();
        }

        if (self.layoutProfile['ganttLHSGrid']) {
          self.$refs['lhs-grid'].style.width = self.layoutProfile['ganttLHSGrid'].width;
        }
        if (self.layoutProfile['ganttRHSChart']) {
          self.$refs['rhs-chart'].style.width = self.layoutProfile['ganttRHSChart'].width;
        }
        
        if (self.layoutProfile.ganttViewName) {
          this.$store.dispatch("breadcrumb/updateView", self.layoutProfile.ganttViewName, { root: true });
        }
      }
      self.refreshData();
    }
    , createUserProfile() {
      viewProfileService.create(this.userProfile, this.userId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.userProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , updateUserProfile() {
      viewProfileService.update([this.userProfile], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , async loadUserProfile() {
      const self = this;
      const userProfile = await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      // move the views from the old array in a single profile to their own profiles
      if (Object.prototype.hasOwnProperty.call(userProfile, 'ganttViews')) {
        const list = [];
        for (const profile of userProfile.ganttViews) {
          profile.type = 'gantt';
          profile.editingPermissions = self.userId;
          profile.sharingMembers = cloneDeep(self.userId);
          profile.sharedVisibility = 'private';
          await viewProfileService.createPreset([profile], this.userId).then((response) => {
            const data = response.data[response.data.jobCase];
            profile.uuId = data[0].uuId;
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
          list.push(profile);
        }
        delete userProfile.ganttViews;
        await viewProfileService.update([userProfile], self.userId).then(() => {
          //
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        self.ganttViews = list;
      }
      else {
        const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
          return value;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        
        this.addViews(views);
      }
    }
    , async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    }
    , addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === 'gantt' &&
            this.ganttViews.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.ganttViews.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
        }
      }
      
      this.ganttViews.sort(function( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    }
    , loadColumnSettings(data) {
      const self = this;
      const columns = Array.isArray(data) ? data : data.columns;

      if (columns.length == 0) {
        // The user doesn't have custom columns yet, so use defaults
        return;
      }
      
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = self.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          self.columnDefs.splice(idx++, 0, self.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of this.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field || c.colId === column.colId);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
        }
      }
      
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.resetRowHeights();
      return false;
    }
    , copyColumnSettings({/** index ,*/ name /**, profile */ }) {
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      const view = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: 'gantt',
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        expandLevel: this.expandLevel, 
        lhsGrid: { width: this.$refs['lhs-grid'].style.width },
        rhsChart: { width: this.$refs['rhs-chart'].style.width },
        ganttControlTimescale: this.control.timescale,
        ganttControlCriticalPath: this.control.criticalPath,
        ganttControlFreeFloat: this.control.freeFloat,
        ganttControlDates: this.control.dates,
        ganttControlStartDate: this.control.startDate,
        ganttControlEndDate: this.control.endDate,
        columns: columns.map(c => { return { colId: c.colId, width: c.actualWidth, sort: c.sort }})
      };
      this.saveProfile = view;
      this.saveIndex = -1;
      this.promptSaveShow = true;
    }
    , shareColumnSettings({ index, name, profile }) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    }
    /* Save and Load of user profile Gantt views*/
    , loadViewSettings(data) {
      // Only keep the columns that are valid for this context.
      var keys = this.columnDefs.map(c => c.field);
      var columns = data.view.columns.filter(c => keys.includes(c.colId) || c.colId == "ag-Grid-AutoColumn");

      var taskColumn = columns.filter(c => c.colId == "ag-Grid-AutoColumn");
      if (taskColumn.length > 0) {
        taskColumn[0].colId = "name";
      }

      this.layoutProfile.ganttColumns = columns;
      this.loadColumnSettings(columns);
      
      const coloringChanged = JSON.stringify(data.view.coloring) !== JSON.stringify(this.coloring) 
      this.coloring.none = data.view.coloring ? data.view.coloring.none : true;
      this.coloring.staff = data.view.coloring ? data.view.coloring.staff : false;
      this.coloring.task = data.view.coloring ? data.view.coloring.task : false;
      this.coloring.stage = data.view.coloring ? data.view.coloring.stage : false;
      this.coloring.rebate = data.view.coloring ? data.view.coloring.rebate : false;
      this.coloring.skill = data.view.coloring ? data.view.coloring.skill : false;
      this.coloring.resource = data.view.coloring ? data.view.coloring.resource : false;
      this.coloring.file = data.view.coloring ? data.view.coloring.file : false;
            
      // save the view name in the profile
      this.layoutProfile.ganttViewName = data.view.name;
      
      // Save the new layout after applying it
      this.updateLayoutProfile({ clearViewName: false });

      if (data.view.lhsGrid) {
        this.$refs['lhs-grid'].style.width = data.view.lhsGrid.width;
      }
      if (data.view.rhsChart) {
        this.$refs['rhs-chart'].style.width = data.view.rhsChart.width;
      }

      const shownColumns =
          columns
          ? columns.filter(i => i.colId !== 'ag-Grid-AutoColumn').map(i => i.colId)
          : [];

      if (shownColumns.some(i => !this.columnCache.includes(i))) {
        this.refreshData();
      }
      
      if (coloringChanged) {
        this.updateColoring();
      }
      
      this.$store.dispatch("breadcrumb/updateView", data.view.name, { root: true });
    }
    , showAllColumns() {
      for (const column of this.columnDefs) {
        column.hide = false;
      }
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    , showNoColumns() {
      for (const column of this.columnDefs) {
        if (column.field == "name") {
          continue;
        }
        column.hide = true;
      }
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    , updateColumnSettings(data) {
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();
    
      const view = { 
        name: this.saveName
        , type: 'gantt'
        , uuId: data.profile.uuId
        , defaultView: data.profile.defaultView
        , sharedVisibility: cloneDeep(data.profile.sharedVisibility)
        , sharingMembers: cloneDeep(data.profile.sharingMembers)
        , editingPermissions: cloneDeep(data.profile.editingPermissions)
        , columns: columns.map(c => { return { colId: c.colId, width: c.actualWidth }})
        , lhsGrid: { width: this.$refs['lhs-grid'].style.width }
        , rhsChart: { width: this.$refs['rhs-chart'].style.width } 
        , ganttControlTimescale: this.control.timescale
        , ganttControlCriticalPath: this.control.criticalPath
        , ganttControlFreeFloat: this.control.freeFloat
        , ganttControlDates: this.control.dates
        , ganttControlStartDate: this.control.startDate
        , ganttControlEndDate: this.control.endDate
        , expandLevel: this.expandLevel
        , coloring: this.coloring
      };
      this.saveProfile = view;
      this.saveName = this.ganttViews[data.index].name;
      this.saveIndex = data.index;
      this.promptSaveShow = true;
    }
    , savePreset() {
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();
      const view = { 
        name: this.saveName
        , type: 'gantt'
        , sharingMembers: cloneDeep(this.userId)
        , editingPermissions: cloneDeep(this.userId)
        , columns: columns.map(c => { return { colId: c.colId, width: c.actualWidth }})
        , lhsGrid: { width: this.$refs['lhs-grid'].style.width }
        , rhsChart: { width: this.$refs['rhs-chart'].style.width } 
        , controlTimescale: this.control.timescale
        , controlCriticalPath: this.control.criticalPath
        , controlFreeFloat: this.control.freeFloat
        , controlDates: this.control.dates
        , controlStartDate: this.control.startDate
        , controlEndDate: this.control.endDate
        , expandLevel: this.expandLevel
        , coloring: this.coloring
      };
      this.saveProfile = view;
      this.saveName = null;
      this.saveIndex = -1;
      this.promptSaveShow = true;
    }
    , removeColumnSettings(data) {
      const index = data.index;
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    }
    , confirmDeleteViewOk() {
      const toRemove = this.ganttViews.splice(this.deleteViewIndex, 1);
      viewProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {  
                        //
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    , async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(this.entityId, user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0]['ganttControlTimescale'] = profile.ganttControlTimescale;
            profileData[0]['ganttControlCriticalPath'] = profile.ganttControlCriticalPath;
            profileData[0]['ganttControlFreeFloat'] = profile.ganttControlFreeFloat;
            profileData[0]['ganttControlDates'] = profile.ganttControlDates;
            profileData[0]['ganttControlStartDate'] = profile.ganttControlStartDate;
            profileData[0]['ganttControlEndDate'] = profile.ganttControlEndDate;
            profileData[0]['ganttColumns'] = profile.columns;
            profileData[0]['ganttBadgeFilter'] = profile.ganttBadgeFilter;
            profileData[0]['ganttLHSGrid'] = profile.lhsGrid;
            profileData[0]['ganttRHSChart'] = profile.rhsChart;
            profileData[0]['ganttMarker_showStartText'] = profile.ganttMarker_showStartText;
            profileData[0]['ganttMarker_showEndText'] = profile.ganttMarker_showEndText;
            profileData[0]['ganttMarker_showTodayText'] = profile.ganttMarker_showTodayText;
            
            profileData[0]['gantt_view_coloring'] = profile.coloring;
            profileData[0]['ganttFilterText'] = profile.filterText;
            profileData[0]['ganttViewName'] = profile.name;
            profileData[0]['ganttExpandLevel'] = profile.expandLevel;
            profileData[0]['gantt_flatList'] = profile.gantt_flatList;
            
            
            await service.update(profileData, this.entityId, user)
          }
        }
      }
    }
    , confirmSaveOk({ /**  name, */ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.ganttViews.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, layoutProfileService);
      
      if (this.saveIndex !== -1) {
        this.ganttViews.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      }
      
      if (!sharing) {
        // save the view name in the profile
        this.layoutProfile.ganttViewName = profile.name;
        this.$store.dispatch("breadcrumb/updateView", profile.name, { root: true });
        this.updateLayoutProfile({ clearViewName: false });
      }
    }
    , actionBarDateChanged(dateInStr, { isStartDate=true } = {}) {
      //When user sets startDate and it is later than closeDate, reset closeDate to 1 month later than startDate.
      //When user sets closeDate and it is earlier than startDate, reset startDate to 1 month earlier than closeDate.
      //When user sets startDate 16 year earlier than closeDate, reset startDate to 15 year earlier than closeDate.
      //When user sets closeDate 16 year later than startDate, reset closeDate to 15 year later than startDate.
      let _startDate = null;
      let _closeDate = null;
      if (isStartDate) {
        _startDate = moment.utc(dateInStr, 'YYYY-MM-DD');
        _closeDate = moment.utc(this.control.endDate, 'YYYY-MM-DD');
      } else {
        _startDate = moment.utc(this.control.startDate, 'YYYY-MM-DD');
        _closeDate = moment.utc(dateInStr, 'YYYY-MM-DD');
      }

      //When the date overlaps another date
      if (_closeDate.diff(_startDate) <= 0) {
        if (isStartDate) {
          _closeDate = _startDate.clone().add(1, 'months');
        } else {
          _startDate = _closeDate.clone().subtract(1, 'months');
        }
        
        this.control.startDate = _startDate.format('YYYY-MM-DD');
        this.control.endDate = _closeDate.format('YYYY-MM-DD');
        return;
      }

      //When the range is greater than 15 years
      if (_closeDate.diff(_startDate, 'years') > 15) {
        if (isStartDate) {
          _startDate = _closeDate.clone().subtract(15, 'years');
          this.control.startDate = _startDate.format('YYYY-MM-DD');
          return;
        } else {
          _closeDate = _startDate.clone().add(15, 'years');
          this.control.endDate = _closeDate.format('YYYY-MM-DD');
          return;
        }
      }

      //Default
      if (isStartDate) {
        this.control.startDate = _startDate.format('YYYY-MM-DD');
      } else {
        this.control.endDate = _closeDate.format('YYYY-MM-DD');
      }
    }
    , getMainMenuItems(params) {
      const colId = params.column.colId;
      if (colId != null && colId === this.COLUMN_NAME) {
        const menuItems = [];
        menuItems.push({
          name: this.$t('button.selectall'),
          action: () => {
            const api = params.api;
            const rows = this.getCurrentPageDisplayRowNode(api);
            if (rows.length > 0) {
              const range = {
                rowStartIndex: rows[0].rowIndex,
                rowEndIndex: rows[rows.length-1].rowIndex,
                columns: [this.COLUMN_NAME]
              }
              api.clearRangeSelection();
              api.addCellRange(range);

              //Make sure there is a focused cell in the grid.
              //Without focused cell, cell navigation, delete key interaction will not work.
              if(api.getFocusedCell() == null) {
                api.setFocusedCell(range.rowStartIndex, range.columns[0]);
              }
            }
          },
        });
        menuItems.push({
          name: this.$t('button.selectnone'),
          action: () => {
            params.api.deselectAllFiltered();
            params.api.clearRangeSelection();
            params.api.clearFocusedCell();
          },
        });

        const selectCellRangeByType = (api, columnApi, type) => {
          const newCellRanges = [];
          const rows = this.getCurrentPageDisplayRowNode(api);
          let startIndex = -1;

          for (const row of rows) {
            if (row.data.taskType == type) {
              if (startIndex == -1) {
                startIndex = row.rowIndex;
              }
              continue;
            } else if (startIndex != -1) {
              newCellRanges.push({
                rowStartIndex: startIndex,
                rowEndIndex: row.rowIndex - 1,
                columns: [this.COLUMN_NAME]
              })
              startIndex = -1;
              continue;
            }
          }
          

          if (startIndex != -1 && rows.length > 0) {
            const lastRowIndex = rows[rows.length -1].rowIndex;
            newCellRanges.push({
              rowStartIndex: startIndex,
              rowEndIndex: lastRowIndex,
              columns: [this.COLUMN_NAME]
            })
          }

          api.clearRangeSelection();
          for (const cellRange of newCellRanges) {
            api.addCellRange(cellRange);
          }

          //Make sure there is a focused cell in the grid.
          //Without focused cell, cell navigation, delete key interaction will not work.
          if(newCellRanges.length > 0 && api.getFocusedCell() == null) {
            api.setFocusedCell(newCellRanges[0].rowStartIndex, newCellRanges[0].columns[0]);
          }
        }
        
        menuItems.push({
          name: this.$t('task.select_summary'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Project');
          },
        });
        
        menuItems.push({
          name: this.$t('task.select_task'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Task');
          },
        });
        
        menuItems.push({
          name: this.$t('task.select_milestone'),
          action: () => {
            selectCellRangeByType(params.api, params.columnApi, 'Milestone');
          },
        });

        return menuItems;
      }
      return params.defaultItems;
    }
    , onColorChange({val, color_key}) {
      const coloring = this.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.layoutProfile[color_key] = coloring;
      this.updateLayoutProfile();
      this.updateColoring();
    }
    , updateColoring() {
      this.gridOptions.api.redrawRows();
      const ganttData = cloneDeep(this.ganttData);
       // set the colors
      for (var i = 0; i < ganttData.data.length; i++) {
        ganttData.data[i] = this.setGanttColors(ganttData.data[i]);
      }
      this.ganttData = ganttData;
    }
    , getRowColor(data) {
      if (data &&
        data.taskColor &&
        this.coloring.task) {
        return data.taskColor;
      }
      else if (data &&
        data.stageColor &&
        this.coloring.stage) {
        return getFirstColor(data.stageColor);
      }
      else if (data &&
        data.rebateColor &&
        this.coloring.rebate) {
        return getFirstColor(data.rebateColor);
      }
      else if (data &&
        data.fileColor &&
        this.coloring.file) {
        return getFirstColor(data.fileColor);
      }
      else if (data &&
        data.staffColor &&
        this.coloring.staff) {
        return getFirstColor(data.staffColor);
      }
      else if (data &&
        data.skillColor &&
        this.coloring.skill) {
        return getFirstColor(data.skillColor);
      }
      else if (data &&
        data.resourceColor &&
        this.coloring.resource) {
        return getFirstColor(data.resourceColor);
      }
    }
    , setGanttColors(obj) {
      // clear the existing color
      delete obj['color'];
      delete obj['textColor'];
      delete obj['progressColor'];
      
      const color = this.getRowColor(obj);
      // set the color fields for use in gantt chart
      if (color) {
        obj.color = color;
        obj.textColor = invertColor(color, true);
        obj.progressColor = toComplimentary(color);
      }
      return obj;
    }
    , loseCellFocusOnEscapeKey(event) {
      if (event.keyCode !== 27) {
        return;
      }
      
      let api = this.gridOptions != null && this.gridOptions.api != null? this.gridOptions.api : null;
      if (api == null || this.taskEditShow) { //skip when cell editing is in progress
        return;
      }
      api.deselectAllFiltered();
      api.clearRangeSelection();
      api.clearFocusedCell();
    }
    , fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue;
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue;
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true;

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode;
      const targetColId = params.column.colId;
      let srcRowNode = null; 
      let sourceColId = null;
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode; //Default: Same row as target.
        const columns = params.columnApi.getColumnState().filter(i => i.hide != true).map(i => i.colId);
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1;
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1;
        }
        sourceColId = columns[sourceColIdx];
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1;
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1;
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex);
        sourceColId = targetColId; //Default: Same column as source.
      }

      let isCompatible = false;
      let srcProperty = sourceColId;
      let tgtProperty = targetColId;
      //Check if the source and target's property value type are compatible
      if (sourceColId == targetColId) {
        isCompatible = true;
      } else {
        isCompatible = TaskViewPropertyUtil.isCompatible(srcProperty, tgtProperty);
      }

      if (!isCompatible) {
        return params.currentCellValue;
      }

      let srcValue = srcRowNode.data[sourceColId];

      if ('staffs' == srcProperty && 'staffs' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtStaffs = tgtValue;
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == srcProperty && 'notes' == tgtProperty) {
        const tgtValue = objectClone(tgtRowNode.data[targetColId]);
        let tgtNotes = tgtValue;
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return params.currentCellValue; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === tgtProperty || 'description' === tgtProperty || 'identifier' === tgtProperty) {
        srcValue = this.truncatePropValue(tgtProperty, srcValue);
      }
      
      return srcValue;
    }
    , getRowSelectorColumn() {
      return {
        headerName: '',
        colId: 'rowSelector',
        field: 'taskType',
        width: 67,
        minWidth: 67,
        maxWidth: 67,
        hide: false,
        cellRenderer: 'rowSelectorCellRenderer',
        cellRendererParams: {
          enableReadonlyStyle: true
        },
        pinned: 'left',
        lockPosition: 'left',
        lockVisible: true,
        suppressColumnsToolPanel: true,

        menuTabs: ['generalMenuTab'],
        resizable: false,
        headerComponent: 'selectionHeaderComponent',
        headerComponentParams: {
          targetColId: this.COLUMN_NAME,
          displayName: 'Tasks'
        },
        suppressFillHandle: true ,
        rowDrag: false,
        rowDragText: function(params) {
          return params.rowNode && params.rowNode.data? params.rowNode.data.name : params.defaultTextValue;
        },
      }
    }
    // //Referenced in RowSelector.vue
    // , rowSelectorMouseDown(rowIndex=null) {
    //   if (rowIndex == null) {
    //     return;
    //   }
      
    //   //Consolidate all ranges's row and column details into rowColumnMap 
    //   const rowColumnMap = new Map();
    //   const cellRanges = this.gridOptions.api.getCellRanges();
    //   for (const cRange of cellRanges) {
    //     const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
    //     const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
    //     const columns = cRange.columns.map(i => i.colId);
    //     if (rowStartIndex == rowEndIndex) {
    //       if (!rowColumnMap.has(rowStartIndex)) {
    //         rowColumnMap.set(rowStartIndex, new Set());
    //       }
    //       const rCol = rowColumnMap.get(rowStartIndex);
    //       for (const col of columns) {
    //         if (col == 'rowSelector') {
    //           continue;
    //         }
    //         rCol.add(col);
    //       }
    //       continue;
    //     }

    //     for (let i = rowStartIndex; i <= rowEndIndex; i++) {
    //       if (!rowColumnMap.has(i)) {
    //         rowColumnMap.set(i, new Set());
    //       }
    //       const rCol = rowColumnMap.get(i);
    //       for (const col of columns) {
    //         rCol.add(col);
    //       }
    //     }
    //   }

    //   const maxColumnsLength = this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length;
    //   //Reset list
    //   this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length);
    //   //Check which row has full set of columns in range selection.
    //   //When the row has full set, add it to tobeUnselected list
    //   for (let [key, value] of rowColumnMap) {
    //     if (value.size == maxColumnsLength) {
    //       this.rowSelectorClicked_allColsSelectedRowIndexList.push(key);
    //     }
    //   }
    // }
    , async processValueChanged(api) {
      this.inProgressLabel = this.$t('task.progress.updating_tasks');
      this.inProgressShow = true;
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250));
        } while(this.pendingProcessRequestList.length > 250);
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {

        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length);
          this.pendingRequestBatchList.push(requests);
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length);
          let hasError = false;
          let errorObj = null;
          let alertMsg = null;
          for (const b of bList) {
            //Turn off project autoScheduling, webhook and macro during batch CRUD operation
            // to improve performance.
            b.unshift({
              'note': 'Disable macros',
              'invoke': 'PUT /api/system/features?entity=macros&action=DISABLE'
            });
            b.unshift({
              'note': 'Disable project scheduling',
              'invoke': 'PUT /api/system/features?entity=scheduling&action=DISABLE'
            });
            await compositeService.exec(b)
            .then(response => {
              if (response.status == 207) {
                const feedbackList = response.data[response.data.jobCase];
                const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                if (msg != null) {
                  hasError = true;
                  alertMsg = msg;
                }
              }
            })
            .catch(e => {
              hasError = true;
              errorObj = e;
            });

            if (hasError) {
              break;
            }
          }

          if (hasError) {
            if (errorObj != null && alertMsg == null) {
              alertMsg = this.$t('error_failed_to_complete_operation');
              const response = errorObj.response;
              if (response) {
                if (403 === response.status) {
                  alertMsg = this.$t('error.authorize_action')
                } else if (422 === response.status) {
                  const feedbackList = response.data[response.data.jobCase];
                  const msg = handleCustomFieldError(feedbackList, this.columnDefs, this);
                  if (msg != null) {
                    alertMsg = msg;
                  }
                }
              }
            }
            this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          } else {
            this.resetAlert();
          }

          this.refreshData();
        } else {
          this.inProgressShow = false;
        }
        
        //Reset session properties
        this.dcClearPreviousChoice = true;
        this.applyAllChangeOnComplete = false;
        return;
      }

      let isPendingOtherProcess = false;
      do {
        let currentItem = null;
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift();
        } else {
          currentItem = this.processValueChangedList.shift();
        }

        const colId = currentItem.colId;
        const property = currentItem.property;
        const oldValue = currentItem.oldValue;
        const newValue = currentItem.newValue;
        const rowData = currentItem.data;
        let taskId = currentItem.taskId;
        const taskType = currentItem.taskType;
        
        const skippedWhenTypeSummaryTask = [
          'startTime', 'closeTime', 'estimatedDuration'
          , 'progress', 'autoScheduling'
          , 'constraint', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Project' && skippedWhenTypeSummaryTask.includes(property)) {
          continue;
        }

        const skippedWhenTypeMilestone = [
          'closeTime', 'estimatedDuration', 'estimatedTimeToComplete'
        ];
        if (taskType == 'Milestone' && skippedWhenTypeMilestone.includes(property)) {
          continue;
        }

        if ('tag' === property) {
          const request = await this.updateTags(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('template' === property) {
          let request = null;
          if (oldValue.uuIds.length > 0 && newValue.uuIds.length == 0) {
            request = TaskViewRequestGenerator.removeTaskTemplate(taskId, oldValue.uuIds);
          } else {
            request = TaskViewRequestGenerator.applyTaskTemplate(taskId, newValue.uuIds, { override: true, group: newValue.uuIds.length > 1 });
          }
          
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('totalActualDuration' === property) {
          //This flow only for action of click and edit 'totalActualDuration' cell directly. No fill operation. No copy and paste.
          const oldStaff = rowData.oldStaffs; //This property is added in WorkEffortEditor.
          const newStaff = rowData.staffs;
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldStaff, newStaff);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('notes' === property) {
          const request = TaskViewRequestGenerator.updateNote(taskId, oldValue != null? oldValue : [], newValue, { customFields: this.noteCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('estimatedTimeToComplete' === property) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const unit = analyzeDurationAUM(newValue).unit;
            const durationInMinutes = convertDisplayToDuration(newValue).value;
            const task = { uuId: taskId, duration: durationInMinutes, durationAUM: unit }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/task/update',
              body: [task],
              vars: [],
              note: `taskUpdate__${colId}`
            })
            
          } else {
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }
            let totalWorkEffort = 0;
            if (rowData.staffs != null) {
              for (const staff of rowData.staffs) {
                totalWorkEffort += staff.duration;
              }
            }
            const etcInMinutes = convertDisplayToDuration(newValue).value;
            let estimatedDuration = totalWorkEffort + etcInMinutes;
            estimatedDuration = convertDurationToDisplay(estimatedDuration, 'D');
            this.dateTimeDurationValueChanged(taskId, 'estimatedDuration', estimatedDuration, rowData);
            isPendingOtherProcess = true;
            break;
          }
        } else if (['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling'].includes(property)) {
          if (rowData == null) { //When target is non-existing (/empty) task
            const task = { uuId: taskId }
            if ('estimatedDuration' == property) {
              const unit = analyzeDurationAUM(newValue).unit;
              const durationInMinutes = convertDisplayToDuration(newValue).value;
              task.duration = durationInMinutes;
              task.durationAUM = unit;
            } else if ('constraint' == property) {
              task.constraintType = newValue.type;
              task.constraintTime = newValue.time;
            } else {
              task[property] = newValue;
            }
            
            this.pendingProcessRequestList.push({
              method: 'PUT',
              invoke: '/api/task/update',
              body: [task],
              vars: [],
              note: `taskUpdate__${colId}`
            })
          } else {
            if (this.isDateCalcInProgress) {
              this.processDateValueChangedList.push(currentItem);
              break;
            }
            const opt = {};
            if (Object.hasOwn(currentItem, 'defaultActionForNonWorkPrompt')) {
              opt.defaultActionForNonWorkPrompt = currentItem.defaultActionForNonWorkPrompt;
            }
            if (Object.hasOwn(currentItem, 'skipOutOfProjectDateCheck')) {
              opt.skipOutOfProjectDateCheck = currentItem.skipOutOfProjectDateCheck;
            }
            opt.oldValue = currentItem.oldValue;

            this.dateTimeDurationValueChanged(taskId, property, newValue, rowData, opt);
            isPendingOtherProcess = true;
            break;
          }
        } else if ('staffs' === property) {
          const request = TaskViewRequestGenerator.updateStaff(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('resources' === property) {
          const request = TaskViewRequestGenerator.updateResource(taskId, oldValue, newValue, { customFields: this.resourceCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('rebates' === property) {
          const request = TaskViewRequestGenerator.updateRebate(taskId, oldValue, newValue);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('skills' === property) {
          const request = TaskViewRequestGenerator.updateSkill(taskId, oldValue , newValue, { customFields: this.skillCustomFields });
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else if ('stage' === property) {
          const oldStage = oldValue != null && oldValue.uuId != null? oldValue : null;
          const newStage = newValue;
          const request = TaskViewRequestGenerator.updateStage(taskId, oldStage, newStage);
          if (request.length > 0) {
            this.pendingProcessRequestList.push(...request);
          }
        } else { // update task
          const task = { uuId: taskId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority'];
          if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            task[property] = null;
          } else if (property == 'taskColor') {
            task.color = newValue;
          } else {
            task[property] = newValue;
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: `/api/${this.isTemplate? '/template' : '' }task/update`,
            body: [task],
            vars: [],
            note: `${this.isTemplate? 'templateTask' : 'task' }Update__${property}`
          })
        }

      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }  
    }
    , getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "TASK"}).then(value => {
        self.modelInfo = value.TASK.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
    , truncatePropValue(property, value) {
      if ('name' === property && value != null && value.length > this.maxNameLength) {
        return value.substring(0, this.maxNameLength);
      }
      if ('description' === property && value != null && value.length > this.maxDescriptionLength) {
        return value.substring(0, this.maxDescriptionLength);
      }
      if ('identifier' === property && value != null && value.length > this.maxIdentifierLength) {
        return value.substring(0, this.maxIdentifierLength);
      }
      return value;
    }
    , simpleHashCode(str) {
      var hash = 0, i, chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      
      const srcRowId = rowData.uuId;
      const srcRowData = params.api.getRowNode(srcRowId).data;
      
      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      return this.cellCopyPrefix+JSON.stringify(source);
    }
    , processCellFromClipboard(params) {
      let rowData = params.node.data;
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , columnApi: params.columnApi
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[colId];
      }

      let colId = params.column.colId;
      
      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration'];
      if (blackListed.includes(colId)) {
        return rowData[colId];
      }

      let source = params.value;
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId];
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length);
      source = JSON.parse(source);
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
      }

      let isCompatible = false;
      if (source.property == target.property) {
        isCompatible = true;
      } else {
        isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
      }

      if (!isCompatible) {
        return rowData[colId];
      }

      let srcValue = source.value;
      if ('staffs' == source.property && 'staffs' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtStaffs = tgtValue;
        if (srcValue.length == 0 && tgtStaffs.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove all staff from tgtStaffs
        if (srcValue.length == 0) {
          tgtStaffs.splice(0, tgtStaffs.length);
          return tgtValue;
        }

        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        tgtStaffs.splice(0, tgtStaffs.length, ...updatedStaffs);
        return tgtValue;
      }

      if ('notes' == source.property && 'notes' == target.property) {
        const tgtValue = objectClone(rowData[colId]);
        let tgtNotes = tgtValue;
        if (srcValue.length == 0 && tgtNotes.length == 0) {
          return rowData[colId]; //No change when both lists are empty.
        }

        //Remove the top item from the tgtNotes when src is empty
        if (srcValue.length == 0) {
          tgtNotes.shift();
          return tgtValue;
        }

        const newNote = objectClone(srcValue[0]);
        if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
          delete newNote.identifier;
        }
        delete newNote.uuId;
        tgtNotes.unshift(newNote);
        return tgtValue;
      }

      if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
        srcValue = this.truncatePropValue(target.property, srcValue);
      }

      return srcValue;
    }
    , prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , taskId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      const isTgtTaskCol = colId.startsWith(this.taskColPrefix);

      if (isTgtTaskCol) {
        const valueObj = target.oldValue;
        target.data = valueObj.data;
        target.oldValue = valueObj.single; 
        target.property = valueObj.property;
        target.taskId = valueObj.uuId != null? valueObj.uuId : null;
        target.parentId = rowData.uuId;
        target.taskType = 'Task'; //colId starts with taskColPrefix must be 'Task'

        let taskName = '<undefined>';
        const regex = new RegExp(`^${this.taskColPrefix}([A-Za-z0-9-]+)_([0-9][0-9])_(.*)$`);
        const r = colId.match(regex);
        if (r != null) {
          taskName = r[3];
        }
        target.taskName = taskName;
      }

      return target;
    }
    , ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtNotes = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtNotes = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift();
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0]);
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier;
      }
      delete newNote.uuId;
      tgtNotes.unshift(newNote);
      return { action: 'add', newValue: tgtNotes }
    }
    , ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix);
      let tgtStaffs = tgtValue;
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = [];
        }
        tgtStaffs = tgtValue.single;
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = [];
      if (srcValue.length > 0) {
        const updatedStaffs = [];
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId);
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization;
            updatedStaffs.push(found);
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration;
            delete srcStaff.durationAUM;
            updatedStaffs.push(srcStaff);
          }
        }
        newValue = updatedStaffs;
      }
      return { action: 'add', newValue }
    }
    , ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex);
      if (srcRowNode == null) {
        return;
      }

      //Prepare source (property) value
      const srcRowData = srcRowNode.data;
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix);

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value;
        source.value = valueObj.single; 
        source.property = valueObj.property;
        source.taskId = valueObj.uuId != null? valueObj.uuId : null;
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return;
      }

      const pendingFilled = [];
      const cellRanges = api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }});
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i);
          if (curRowNode == null) {
            continue;
          }

          const tgtRowData = curRowNode.data;
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue; //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color });
            let isCompatible = false;
            if (source.property == target.property) {
              isCompatible = true;
            } else {
              isCompatible = TaskViewPropertyUtil.isCompatible(source.property, target.property);
            }
            if (!isCompatible) {
              continue;
            }

            let srcValue = source.value;
            if ('staffs' == source.property && 'staffs' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_staff(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('notes' == source.property && 'notes' == target.property) {
              const result = this.ctrlEnterFillCellPropCheck_note(srcValue, target);
              if (result.action == 'continue') {
                continue;
              }
              target.newValue = result.newValue;
              pendingFilled.push(target);
              continue;
            }

            if ('name' === target.property || 'description' === target.property || 'identifier' === target.property) {
              srcValue = this.truncatePropValue(target.property, srcValue);
            }

            const isTgtTaskCol = target.colId.startsWith(this.taskColPrefix);
            if (isTgtTaskCol) {
              //Skip if lack of required permission
              if (target.taskId == null && !this.canAdd(this.permissionName)) {
                continue;
              }
              if (target.taskId != null && !this.canEdit(this.permissionName)) {
                continue;
              }
              const tgtValue = objectClone(target.oldValue); //clone and return new object to trigger onCellValueChanged() event
              tgtValue.single = srcValue;
              target.newValue = tgtValue;
              pendingFilled.push(target);
              continue;
            }

            target.newValue = srcValue;
            pendingFilled.push(target);
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled);
        this.inProgressLabel = this.$t('task.progress.updating_tasks');
        this.processValueChanged(api);
      }
    }
    , onCellKeyDown(params) {
      if (params.event.key == 'Delete' && this.canDelete(this.permissionName)) {
        const cellRanges = params.api.getCellRanges();
        //Prepare cell information
        const taskColTasks = [];
        const processedCells = []; //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex; 
          
          const columns = cRange.columns;
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx);
            
            for (const column of columns) {
              const colId = column.colId;
              const rowId = rowNode.data.uuId;
              const found = processedCells.find(i => rowId == i.rowId && (this.COLUMN_NAME == i.colId || colId == i.colId));
              if (found != null) {
                continue; //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId });

              //Handle 'ag-Grid-AutoColumn' cell
              //Brief: Delete ag-Grid-AutoColumn cell means remove the summary task (whole row) and thus delete all it's children.
              //Remove previously handled cells of the same row before current `ag-Grid-AutoColumn`.
              if (this.COLUMN_NAME == colId) {
                //Remove redundant cells in taskColTasks
                let redundantTaskColCells = [];
                taskColTasks.forEach((i, idx) => {
                  if (i.parent == rowId) {
                    redundantTaskColCells.push(idx);
                  }
                });
                if (redundantTaskColCells.length > 0) {
                  redundantTaskColCells = redundantTaskColCells.reverse();
                  for (const idx of redundantTaskColCells) {
                    taskColTasks.splice(idx, 1);
                  }
                }
                
                //Remove redundant cells in (non taskCol) pendingDeleteCells
                let redundantPendingDeleteCells = [];
                this.pendingDeleteCells.forEach((i, idx) => {
                  if (i.taskId == rowId) {
                    redundantPendingDeleteCells.push(idx);
                  }
                });
                if (redundantPendingDeleteCells.length > 0) {
                  redundantPendingDeleteCells = redundantPendingDeleteCells.reverse();
                  for (const idx of redundantPendingDeleteCells) {
                    this.pendingDeleteCells.splice(idx, 1);
                  }
                }

                taskColTasks.push({
                   uuId: rowNode.data.uuId
                  , name: rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                });
                continue;
              }

              if (rowNode.data[colId] == null) {
                continue; //Skip when the property value is null.
              }

              //Handle non taskCol cell
              //Skip when the cell is not editable
              let isEditable;
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable;
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId);
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }});
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable;
              }
              if (!isEditable) {
                continue;
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , taskId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                , taskType: rowNode.data.taskType
                , isTaskCol: false
                , value: rowNode.data[colId]
              });
            }
          }
        }

        if (taskColTasks.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.tcConfirmDeleteTasks = taskColTasks;
          this.prepareTaskColConfirmDeleteDialog();
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell();
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api;
        if (api == null) {
          return;
        }

        const focusedCell = api.getFocusedCell();
        if (focusedCell == null) {
          return;
        }

        const cellRanges = api.getCellRanges();
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return;
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex;
          }
          return false;
        })
        if (fRange == null) {
          return;
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return;
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex;

        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId);
        return;

      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false;
        const api = params.api;
        if (api == null) {
          return;
        }
        const fRowIndex = params.rowIndex;
        const column = params.column;
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable;
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data;
          editable = column.colDef.editable({ data });
        }
        if (!editable) {
          let rowIndex;
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1;
            if (rowIndex < 0) {
              rowIndex = 0;
            }
          } else {
            rowIndex = fRowIndex + 1;
            const lastRowIndex = api.getDisplayedRowCount() - 1;
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex;
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null);
          }
        }
      }
    }
    , async deleteCell() {
      //deleteCell() expects only non-taskcol cell.
      const pendingItems = [];
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length);
      for (const cell of cells) {
        cell.oldValue = cell.value;
        //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
        cell.newValue = TaskViewPropertyUtil.getEmptyDataModel(cell.property, cell.data).value; 
        delete cell.value;
        pendingItems.push(cell);
      }
      
      if (pendingItems.length > 0) {
        const data = [];
        for (const item of pendingItems) {
          const rowData = item.data; //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue;
          data.push(rowData);
        }
        this.gridOptions.api.applyTransaction({ update: data });
        this.processValueChangedList.push(...pendingItems);
      }
      
      this.inProgressLabel = this.$t('task.progress.deleting');
      this.processValueChanged(this.gridOptions.api);
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true;
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false;

      this.consolidateChangedCellValues(this.processValueChangedList);
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks');
        this.processValueChanged(params.api);  
      }
    }
    , consolidateChangedCellValues(valueChangedlist) {
      //Handle edge case: Copy & paste multiple duration related cells may trigger multiple requests which send incorrect data to backend.
      //Solution: Consolidate duration related cell value changes into one if they belong to the same task.
      const durationGroup = ['startTime', 'closeTime', 'estimatedDuration', 'constraint', 'autoScheduling']
      const ids = valueChangedlist.filter(i => durationGroup.includes(i.property)).map(i => i.taskId);
      for (const id of ids) {
        const len = valueChangedlist.filter(i => i.taskId == id && durationGroup.includes((i.property))).length;
        if (len < 1) {
          continue;
        }
        const list = [];
        for (let i = 0; i < len; i++) {
          const idx = valueChangedlist.findIndex(j => j.taskId == id && durationGroup.includes(j.property));
          list.push(valueChangedlist.splice(idx, 1)[0]);
        }

        //Sort out the trigger value
        const triggerOrder = ['estimatedDuration','startTime','closeTime','autoScheduling','constraint'];
        const property = list.reduce((r, c) => {
          if (r == null) {
            return c.property;
          }
          if (triggerOrder.findIndex(i => i == c.property) < triggerOrder.findIndex(i => i == r)) {
            return c.property;
          }
          return r;
        }, null);

        //Find out the cell value change with sorted (trigger) property
        const foundIdx = list.findIndex(i => i.property === property);
        const consolidatedItem = list.splice(foundIdx, 1)[0];
        //Update the consolidated item with all other values
        for (const l of list) {
          if (l.property == 'estimatedDuration') {
            consolidatedItem.data.duration = l.newValue;
          } else {
            consolidatedItem.data[l.property] = l.newValue;
          }
        }

        valueChangedlist.push(consolidatedItem);
      }

      return valueChangedlist;
    }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition;
      const nextCellPosition = params.nextCellPosition;
      if (nextCellPosition == null) {
        return previousCellPosition;
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection();
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition;
      }
      return nextCellPosition;
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab while cell editing.
      const curColId = params.previousCellPosition.column.colId;
      const columns = params.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector');
      let rowIndex = params.previousCellPosition.rowIndex;
      let index = columns.findIndex(i => i.colId == curColId);
      let nextColIdx;
      if (index == 0 && params.backwards) {
        rowIndex -= 1;
        nextColIdx = columns.length - 1;
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1;
        nextColIdx = 0;
      } else if (params.backwards) {
        nextColIdx = index - 1;
      } else {
        nextColIdx = index + 1;
      }
      const column = columns[nextColIdx];

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex;
        this.tabColumn = column;
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null;
          this.tabColumn = null;
          this.tabTimeoutId = null;
        }, 0);
        setTimeout(() => {
          params.api.clearRangeSelection();
          params.api.setFocusedCell(rowIndex, column.colId);
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null);
          params.api.ensureColumnVisible(column.colId, 'auto');
        }, 0);
        return params.previousCellPosition;
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null;
    }
    , tcConfirmDeleteOk() {
      const tasks = [{ 
        taskId: this.taskCol.taskId
        , parentId: this.taskCol.parentId 
        , colId: this.taskCol.colId
      }];
      if (this.taskCol.applyAll == true) {
        tasks.push(...this.tcConfirmDeleteTasks.map(i => {
          return {
            taskId: i.uuId
            , parentId: i.parent
            , colId: i.colId
          }
        }));
        this.tcConfirmDeleteTasks.splice(0, this.tcConfirmDeleteTasks.length);
      }
      
      const deleteTaskReqTemplate = (taskId) => {
        return {
          method: 'POST',
          invoke: `/api${this.isTemplate? '/template' : ''}/task/delete`,
          body: [{
            uuId: taskId,
          }],
          vars: [],
          note: `${this.isTemplate? 'templateTask' : 'task'}Delete__${taskId}`
        }
      }
      const toBeUpdated = [];
      const toBeRemoved = [];
      const leafChildrenToBeRemoved = []; //Used to remove child rows of deleted summary task in grid for better visual.
      const toBeRemovedRowIndexes = [];
      for(const task of tasks) {
        this.pendingProcessRequestList.push(deleteTaskReqTemplate(task.taskId));
        
        if (task.colId == this.COLUMN_NAME) {
          const rowNode = this.gridOptions.api.getRowNode(task.taskId);
          const rowData = objectClone(rowNode.data);
          toBeRemoved.push(rowData);
          toBeRemovedRowIndexes.push(rowNode.rowIndex);
          //Collect child row data and will be used in grid applytransaction to remove rows for better visual.
          if (rowData.taskType == 'Project') {
            if (rowNode.allLeafChildren == null || rowNode.allLeafChildren.length < 2) {
              continue;
            }
            const rowNodeChildren = rowNode.allLeafChildren;
            for (let i = 1, len = rowNodeChildren.length; i < len; i++) {
              leafChildrenToBeRemoved.push(objectClone(rowNodeChildren[i].data));
            }
          }
          
        } else {
          const rowData = objectClone(this.gridOptions.api.getRowNode(task.parentId).data);
          rowData[task.colId].single = TaskViewPropertyUtil.getEmptyDataModel(rowData[task.colId].property, rowData[task.colId].data).value;
          delete rowData[task.colId].uuId;
          toBeUpdated.push(rowData);
        }
      }

      // //Adjust focused cell position to cell above (if possible) when the row which focused cell belongs to is removed.
      let suggestedRowIndex = -1;
      if (toBeRemovedRowIndexes.length > 0) {
        const currentFocusedCell = this.gridOptions.api.getFocusedCell();
        if (currentFocusedCell != null) {
          const rowIndex = currentFocusedCell.rowIndex;
          const colId = currentFocusedCell.column.colId;
          const rowPinned = currentFocusedCell.rowPinned;
          const isFocusedCellAffected = toBeRemovedRowIndexes.includes(rowIndex);
          if (isFocusedCellAffected) {
            suggestedRowIndex = rowIndex - 1;
            while (toBeRemovedRowIndexes.includes(suggestedRowIndex)) {
              suggestedRowIndex = suggestedRowIndex - 1;
            }
          }
          if (suggestedRowIndex > -1) {
            this.gridOptions.api.clearRangeSelection();
            this.gridOptions.api.setFocusedCell(suggestedRowIndex, colId, rowPinned);
          }
        }
      }

      if (toBeUpdated.length > 0 || toBeRemoved.length > 0) {
        const ganttToBeRemoved = toBeRemoved.map(i => i.uuId); //Keep a copy before adding leafChildren. Gantt row deletion doesn't need the leafChildren.
        if (leafChildrenToBeRemoved.length > 0) {
          toBeRemoved.push(...leafChildrenToBeRemoved);
        }
        this.gridOptions.api.applyTransaction({ update: toBeUpdated.length > 0? toBeUpdated : null, remove: toBeRemoved.length > 0? toBeRemoved : null });
        if (toBeRemoved.length > 0) {
          this.ganttDeleteTaskIds.splice(0, this.ganttDeleteTaskIds.length, ...ganttToBeRemoved);
        }
      }
      
      setTimeout(() => {
        this.prepareTaskColConfirmDeleteDialog();
      }, 300);
    }
    , prepareTaskColConfirmDeleteDialog() {
      if (this.tcConfirmDeleteTasks == null || this.tcConfirmDeleteTasks.length == 0) {
        this.deleteCell();
        return;
      }
      
      const task = this.tcConfirmDeleteTasks.shift();
      this.taskCol.taskId = task.uuId;
      this.taskCol.taskName = task.name;
      this.taskCol.parentName = task.parent == null || task.parent == 'ROOT'? null : task.parentName;
      this.taskCol.applyAll = false;
      this.taskCol.parentId = task.parent;
      this.taskCol.colId = task.colId;
      this.inProgressShow = false;
      this.tcConfirmDeleteShow = true;
    }
    , tcConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deleteCell();
      }
    }
    , getUpperTaskNodesFromSelection() {
      const api = this.gridOptions.api;
      const processedTasks = [];
      const processingTasks = [];
      //Brief: Remove the selected child tasks if their parent or grand parent is also a part of the selection.
      //1) Collect the task's path. It is needed to identify the relationship of parent and child.
      for (const t of this.taskSelection) {
        if (t.colId == this.COLUMN_NAME) {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.uuId);
          if (rowNode == null || rowNode.data == null || rowNode.data.path == null) {
            continue;
          }
          cloned.path = rowNode.data.path;
          cloned.pathString = rowNode.data.path.join(',');
          processingTasks.push(cloned);
        } else {
          const cloned = objectClone(t);
          const rowNode = api.getRowNode(t.parent);
          if (rowNode == null || rowNode.data == null || rowNode.data.path == null) {
            continue;
          }
          const path = objectClone(rowNode.data.path);
          path.push(t.uuId);
          cloned.path = path;
          cloned.pathString = path.join(',');
          processingTasks.push(cloned);
        }
      }

      processingTasks.sort((a, b) => {
        if(a.path.length > b.path.length) return 1;
        if(a.path.length < b.path.length) return -1;
        return 0;
      })

      //2) Remove unneeded tasks
      while (processingTasks.length > 0) {
        const task = processingTasks.splice(0, 1)[0];
        const matchPattern = [];
        for (let i = 0, len = task.path.length; i < len; i++) {
          matchPattern.push(task.path.slice(0, i+1).join(','));
        }
        
        let found = false;
        for (const mPattern of matchPattern) {
          if (processedTasks.find( i => i.pathString == mPattern) != null) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          processedTasks.push(task);
        }
      }
      return processedTasks;
    }
    , autoAssignTasks() {
      const api = this.gridOptions != null? this.gridOptions.api: null;
      if (api == null) {
        return [];
      }
      
      const tasks = this.getUpperTaskNodesFromSelection().filter(i => i.uuId != 'ROOT');
      const taskIdSet = new Set();
      for (const t of tasks) {
        if (t.colId == this.COLUMN_NAME) {
          const rowNode = api.getRowNode(t.uuId);
          const rowData = rowNode.data;

          //When it is not a summary task, add the uuId to taskIdSet and move on to next iteration.
          if (rowData.taskType != 'Project') {
            taskIdSet.add(t.uuId);
            continue;
          }
          
          //Add all valid task id from the child (or grand child) nodes of the summary task to taskIdSet.
          if (rowNode.allLeafChildren != null) {
            for (const childNode of rowNode.allLeafChildren) {
              const nodeData = childNode.data;

              if (nodeData.taskType != 'Project') {
                taskIdSet.add(nodeData.uuId);
                continue;
              }
            }
          }
        } else { //Add task id of task (from task columns) to taskIdSet
          taskIdSet.add(t.uuId);
        }
      }
      if (taskIdSet.size > 0) {
        tasks.push(...(Array.from(taskIdSet).map(i => { return { uuId: i } })));
      }
      return Array.from(taskIdSet).map(i => { return { uuId: i } });
    }
    , toggleCurrentColumnVisibility(toHide) {
      const columnDefs = this.gridOptions.api.getColumnDefs();
      for (const cDef of columnDefs) {
        if (cDef.colId == 'rowSelector') {
          cDef.hide = false; //rowSelector is always visible.
          continue;
        }
        if (cDef.groupId != null && cDef.groupId.startsWith(this.taskGroupPrefix)) {
          const children = cDef.children;
          if (children == null || children.length == 0) {
            continue;
          }
          for (const child of children) {
            child.hide = toHide;
          }
        } else {
          cDef.hide = toHide;
        }
      }
      this.gridOptions.api.setColumnDefs(columnDefs);
    }
    , taskSelectionChangedHandler(payload) {
      this.processTaskSelectionChangedList(payload)
    }
    , processTaskSelectionChangedList(pList) {
     
      const api = this.gridOptions.api;
      if (api == null) {
        return;
      }

      //Remove the task id which is already included in taskSelectionIds and it's state is true.
      //Reason: User clicks a non-autoGridColumn cell in LHS grid and this event is triggered indirectly. It is not intended. So the logic helps break the flow.
      let pendingList = pList.filter(i => !(this.taskSelectionIds.includes(i.id) && i.state == true));
      if (pendingList.length < 1) {
        return;
      }

      let hasRowNodeNull = false;
      //Get the rowIndex based on the provided (task) id.
      for (const p of pendingList) {
        const rNode = api.getRowNode(p.id);
        if (rNode == null) {
          hasRowNodeNull = true;
          break;
        }
        p.rowIndex = rNode.rowIndex;
      }

      //Defensive code: Stop proceed further when the rowNode is null
      if (hasRowNodeNull) {
        return;
      }

      //Sort in ascending order
      pendingList.sort(( a, b ) => {
        if (a.rowIndex == null && b.rowIndex == null) {
          return 0;
        }
        if (b.rowIndex == null || a.rowIndex < b.rowIndex){
          return -1;
        }
        if (a.rowIndex == null || a.rowIndex > b.rowIndex){
          return 1;
        }
        return 0;
      });

      //Get the existing cell ranges if there is any
      let cellRanges = api.getCellRanges();
      if (cellRanges == null) {
        cellRanges = [];
      }
      //Preprocess the cell range data to extract the needed information.
      cellRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex < i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex
          , rowEndIndex: i.startRow.rowIndex < i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex
          , columns: i.columns.map(j => j.colId)
        }
      });
      
      const excludeFromCellRanges = (pendingList, cellRanges) => {
        let _cRanges = objectClone(cellRanges);
        let processedRanges = [];
        for (const row of pendingList) {
          for (const cRange of _cRanges) {
            if (cRange.rowStartIndex == row.rowIndex && cRange.rowEndIndex == row.rowIndex) {
              continue; //Continue to next iteration when current range contains only single row and it matches the current row.
            }
            if (row.rowIndex < cRange.rowStartIndex || row.rowIndex > cRange.rowEndIndex) {
              processedRanges.push(cRange);
              continue; //Register current range and continue to next iteration when the current row is not in the range.
            }
            //Split range to exclude the current row
            //Rows before the current row.
            if (cRange.rowStartIndex < row.rowIndex) {
              processedRanges.push({
                rowStartIndex: cRange.rowStartIndex
                , rowEndIndex: row.rowIndex - 1
                , columns: cRange.columns
              });
            }
            //Rows after the current row.
            if (cRange.rowEndIndex > row.rowIndex) {
              processedRanges.push({
                rowStartIndex: row.rowIndex + 1
                , rowEndIndex: cRange.rowEndIndex
                , columns: cRange.columns
              });
            }
          }
          _cRanges = processedRanges; //Sync latest change and ready for next iteration.
          processedRanges = []; //Reset value and ready for next iteration.
        }
        return _cRanges;
      }

      const addToCellRanges = (toSelectList, cellRanges) => {
        let _cRanges = objectClone(cellRanges);
        let processedRanges = [];
        let beforeRange, afterRange;
        for (const selected of toSelectList) {
          beforeRange = null;
          afterRange = null;
          let found;
          for (const cRange of _cRanges) {
            found = false;
            //Idea: loop thu all the ranges to find the ranges with only autoGroupColumn and are right before or after the current row.
            if (cRange.columns.length == 1 && cRange.columns[0] == this.COLUMN_NAME) {
              if (cRange.rowStartIndex - 1 == selected.rowIndex) {
                found = true;
                afterRange = cRange;
              } else if (cRange.rowEndIndex + 1 == selected.rowIndex) {
                found = true;
                beforeRange = cRange;
              }
            }
            if (!found) {
              processedRanges.push(cRange);
            }
          }

          //Combine those ranges into one with help of current row.
          let newRowStartIndex = selected.rowIndex;
          let newRowEndIndex = selected.rowIndex;
          if (beforeRange != null) {
            newRowStartIndex = beforeRange.rowStartIndex;
          } 
          if (afterRange != null) {
            newRowEndIndex = afterRange.rowEndIndex;
          }
          
          processedRanges.push({
            rowStartIndex: newRowStartIndex
            , rowEndIndex: newRowEndIndex
            , columns: [this.COLUMN_NAME]
          });
          _cRanges = processedRanges; //Sync latest change and ready for next iteration.
          processedRanges = []; //Reset value and ready for next iteration.
        }
        return _cRanges;
      }

      //Remove all the affected rows (either selected or unselected) from cellRanges
      cellRanges = excludeFromCellRanges(pendingList, cellRanges);
      //Add the newly selected row to the cellRanges
      cellRanges = addToCellRanges(pendingList.filter(i => i.state == true), cellRanges);

      api.clearRangeSelection();
      for (const ncRange of cellRanges) {
        api.addCellRange(ncRange);
      }
    }
    , toDateTime(value) {
      let rawValue = value ? value : null;
      if (rawValue == 0 || rawValue == '' || rawValue == 9223372036854776000) {
        rawValue = null;
      }
      return rawValue != null? moment.utc(rawValue).format('YYYY-MM-DD hh:mm A') : null;
    }
    , getCurrentPageDisplayRowNode(api) {
      const lastGridIdx = api.getDisplayedRowCount() - 1;
      const currentPage = api.paginationGetCurrentPage();
      const pageSize = api.paginationGetPageSize();
      const startPageIdx = currentPage * pageSize;
      let endPageIdx = ((currentPage + 1) * pageSize) - 1;
      if (endPageIdx > lastGridIdx) {
        endPageIdx = lastGridIdx;
      }

      const rows = [];
      for (let i = startPageIdx; i <= endPageIdx; i++) {
        const rowNode = api.getDisplayedRowAtIndex(i);
        if (rowNode.key == 'ROOT') {
          continue;
        }
        rows.push(rowNode);
      }
      return rows;
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , keyDownHandler(e) {
      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return;
      }
      if (e.key == 'Delete' && e.target.closest('.lhs-grid') == null 
          && this.gridOptions.api.getCellRanges() != null && this.gridOptions.api.getCellRanges().length > 0) {
        //Construct the necessary payload value and call the cellKeyDown method 
        this.onCellKeyDown({ api: this.gridOptions.api, event: { keyCode: e.keyCode, key: e.key } });
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridOptions.api;
        if (api == null) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (e.shiftKey) {
          return;
        }
        const rowCount = api.getDisplayedRowCount();
        if (rowCount == 0) {
          return;
        }

        const firstRowIndex = 0;
        const lastRowIndex = rowCount - 1;
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
        const startColumn = columns[0];
        const lastColumn = columns[columns.length - 1];

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex;
        const colId = e.keyCode == 36? startColumn : lastColumn;
        const vPosition = e.keyCode == 36? 'top' : 'bottom';

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition);
        } else {
          const focusedCell = api.getFocusedCell();
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null);
          }
        }
        return;
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const focusedCell = api.getFocusedCell();
        if (focusedCell != null) {
          const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null);
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation();
        e.preventDefault();
        const api = this.gridOptions.api;
        const totalCount = api.getDisplayedRowCount();
        if (totalCount == 0) {
          return;
        }
        const columns = this.gridOptions.columnApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId);
        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        });
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell();
        if (focusCell != null) {
          return;
        }
        api.setFocusedCell(0, columns[0], null);
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation();
        e.preventDefault();
      }
    }
    , navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex;
        const colId = pColId;
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
        api.ensureIndexVisible(rowIndex, vPosition);
        api.ensureColumnVisible(colId, 'auto');
      })
    }
    , updateFocusedCell(id) {
      let api = this.gridOptions.api;
      if (api == null || id == null) {
        return;
      }
      const rNode = api.getRowNode(id);
      if (rNode == null || rNode.rowIndex == null) {
        return;
      }
      api.clearFocusedCell();
      api.setFocusedCell(rNode.rowIndex, this.COLUMN_NAME, null);
     
    }
    , taskClickedHandler(id) {
      this.updateFocusedCell(id);
    }
    , taskCellFocusedHandler(id) {
      this.updateFocusedCell(id);
    }
    , checkPermissions(defs) {
      const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === 'TASK__VIEW');
      const perms = permList.length > 0 ? 
                    permList[0] : 
                    [];
      const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                        perms.permissionLink.denyRules : [];
                        
      for (var i = defs.length - 1; i >= 0; i--) {
        const entry = defs[i].field;
        if (denyRules.includes(entry)) {
          defs.splice(i, 1);
        }
      }
      return defs;
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('task.grid.no_data');
    }

    , showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridOptions != null && this.gridOptions.api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          this.gridOptions.api.showNoRowsOverlay()
        })
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return;
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map();
      const cellRanges = this.gridOptions.api.getCellRanges();
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex;
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex;
        const columns = cRange.columns.map(i => i.colId);
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set());
          }
          const rCol = rowColumnMap.get(rowStartIndex);
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue;
            }
            rCol.add(col);
          }
          continue;
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set());
          }
          const rCol = rowColumnMap.get(i);
          for (const col of columns) {
            rCol.add(col);
          }
        }
      }

      const maxColumnsLength = this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length;
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length);
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key);
        }
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridOptions.api.getCellRanges();
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      });

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridOptions.api.getFocusedCell();
        const focusedCellRowIndex = focusedCell.rowIndex;
        const focusedCellColId = focusedCell.column.colId;

        let cellRanges = this.gridOptions.api.getCellRanges();
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = [];
        
        let isDirectionUp = rowIndex < focusedCellRowIndex;
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex;
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex;
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1);
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex);
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue;
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        });

        this.gridOptions.api.clearRangeSelection();
        for (const nRange of newRanges) {
          this.gridOptions.api.addCellRange(nRange);
        }
        this.gridOptions.api.setFocusedCell(focusedCellRowIndex, focusedCellColId);
        return;
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridOptions.api.getFocusedCell();
        if (curFocusedCell == null) {
          this.gridOptions.api.clearFocusedCell();
          return;
        }
        const rowIndex = this.gridOptions.api.getFocusedCell().rowIndex;
        this.gridOptions.api.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridOptions.columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridOptions.api.setFocusedCell(rowIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
        return;
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1];
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex;
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })();

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridOptions.api, 
        columnApi: this.gridOptions.columnApi 
      });
    }
    , rowSelectorChanged({ cellRanges, originalRanges, lastRange, api, columnApi }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn;
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex;
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0;
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false;
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex;
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex;
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex;
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex;
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0;
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true;
            break;
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex);

        //Prepare new cell ranges for previous ranges
        const newCellRanges = [];
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex);

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue;
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: columnApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null);
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection();
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange);
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay;
          const selection = new Set();
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex;
            let endIdx = range.rowEndIndex;
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex;
              endIdx = range.rowStartIndex;
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId);
            }
          }
        }
        
      }
    }
    , onPaginationChanged(event) {
      if (event.newPage) {
        this.isNextPage = true;
      }
    }
    , async taskMoveValueChanged() {
      if (this.processTaskMoveChangedList.length == 0) {
        this.processValueChanged(this.gridOptions.api);
        return;
      }
      
      const currentItem = this.processTaskMoveChangedList.shift();
      const dc = this.durationCalculation;
      dc.taskId = currentItem.taskId;
      dc.trigger = currentItem.trigger;
      dc.taskName = currentItem.taskName;
      dc.startDateStr = currentItem.startDateStr;
      dc.startTimeStr = currentItem.startTimeStr;
      dc.closeDateStr = currentItem.closeDateStr;
      dc.closeTimeStr = currentItem.closeTimeStr;
      dc.oldDateStr = currentItem.oldDateStr;
      dc.oldTimeStr = currentItem.oldTimeStr;
      dc.durationDisplay = currentItem.durationDisplay;
      dc.lockDuration = currentItem.lockDuration;
      dc.constraintType = currentItem.constraintType
      dc.constraintDateStr = currentItem.constraintDateStr
      dc.skipOutOfProjectDateCheck = currentItem.skipOutOfProjectDateCheck;
      dc.defaultActionForNonWorkPrompt = currentItem.defaultActionForNonWorkPrompt;
      dc.taskAutoScheduleMode = currentItem.taskAutoScheduleMode
      dc.projectScheduleFromStart = currentItem.projectScheduleFromStart;
      dc.projectStartDateStr = currentItem.projectStartDateStr;
      dc.projectCloseDateStr = currentItem.projectCloseDateStr;
      dc.resizeMode = currentItem.resizeMode;

      if (currentItem.staffId != null) {
        this.calendarType.holderId = currentItem.staffId;
        this.calendarType.type = 'staff';
        dc.enableManualScheduleSuggestion = false;
        dc.defaultActionForNonWorkPrompt = null;
        this.calendar = await staffService.calendar(currentItem.staffId)
        .then((response) => {
          // combine the calendar lists into single lists for each day
          const data = response.data[response.data.jobCase];
          return transformCalendar(processCalendar(data));
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });

      } else if (currentItem.projLocationId != null && this.locationCalendarMap.has(currentItem.projLocationId)) {
        this.calendarType.holderId = this.project.locationId;
        this.calendarType.type = 'project-location';
        this.calendar = this.locationCalendarMap.get(currentItem.projLocationId);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      } else if (this.systemCalendar != null) {
        this.calendarType.holderId = null;
        this.calendarType.type = 'system';
        this.calendar = this.systemCalendar;
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }

      //defensive code: fallback to default calendar
      if (this.calendar == null) {
        this.calendarType.holderId == null;
        this.calendarType.type = 'system';
        this.calendar = cloneDeep(DEFAULT_CALENDAR);
        dc.enableManualScheduleSuggestion = true;
        dc.defaultActionForNonWorkPrompt = 'move';
      }
      dc.calendar = this.calendar;

      if (currentItem.progressComplete == 1 && this.applyAllChangeOnComplete != true) {
        this.$nextTick(() => {
          this.state.confirmChangeOnCompleteShow = true;
        });
        return;
      } else {
        //Start calculation
        //$nextTick is required to wait for the on-going ui rendering to be done.
        this.$nextTick(() => {
          this.durationCalculationShow = true;
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resizer {
  position: relative;
  background-color: var(--border-dark);
  cursor: ew-resize;
  height: 100%;
  width: 5px;
}

.splitter-container {
  width: 100%;
  display: flex;
  height: calc(100vh - 270px);
}

.lhs-grid {
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.rhs-chart {
  justify-content: center;
  align-items: center;
  width: calc(75% - 5px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid var(--border-medium);
  border-right: 1px solid var(--border-medium);
}

</style>

<style lang="scss">
#paged-grid .ag-row-selected::before {
  background-color: transparent;
}

#paged-grid .ag-row-hover.ag-row-selected::before {
  background-color: var(--ag-row-hover-color);
  background-image: unset;
}

#paged-grid .ag-row-selected div[col-id=rowSelector] {
  background-color:var(--ag-row-hover-color);
}

#paged-grid .ag-cell-range-selected.ag-cell-focus, #paged-grid .ag-body-viewport.ag-has-focus .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: var(--ag-range-selection-background-color);
  border: 2px solid;
  border-color: var(--ag-range-selection-border-color);
  border-style: var(--ag-range-selection-border-style);
}

#paged-grid .ag-row.drag-to-bottom::after, #paged-grid .ag-row.drag-to-top::after{
  content: '';
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--grid-cell-disabled-3);
}

#paged-grid .ag-row.drag-to-bottom::after {
  bottom: 0;
}

#paged-grid .ag-row.drag-to-top::after{
  top: 0;
}

.change-on-complete-modal .apply-to-all,
.task-delete-modal .apply-to-all {
  position: absolute;
  left: 15px;
}
</style>