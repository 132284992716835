import { resourceService, resourceLinkTagService } from '@/services'
import { addTags, objectClone, costFormat, costFormatAdv } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

function payFrequencyOptions(self) {
  let options = null
  if (self.modelInfo != null) {
    const rawOptions = self.modelInfo.find(f => f.field === 'payFrequency').options
    if (rawOptions != null) {
      options = rawOptions.map(i => {
        return { value: i, text: self.$t(`payFrequency.${i}`) }
      })
    }
  }
  if (options != null) {
    return options
  }
  return []
}

function getMinMaxQuota(self) {
  if (self.modelInfo != null) {
    const properties = self.modelInfo.find(f => f.field === 'resourceQuota')
    return { 
      min: Object.hasOwn(properties, 'min')? properties.min : 1 
      , max: Object.hasOwn(properties, 'max')? properties.max : 1000000 
    }
  }

  return { min: 1, max: 1000000 }
}

export const resourceUtil = {
  list: (bParams, { self }={}) => {
    return resourceService.list(bParams, self?.customFields)
  }
  , listNames: resourceService.listNames
  , remove: resourceService.remove
  , clone: resourceService.clone
  , importDataFunc: (self) => {
    return async (item, errorFunc) => {
      let method = 'create';
      
      const data = {
        name: item.name
        , description: item.desc
        , payAmount: item.cost
        , payCurrency: item.currency
        , payFrequency: item.frequency
        , identifier: item.identifier
        , color: item.color
      }

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      
      const result = await resourceService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })

      if (result && item.tag) {
        await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), resourceLinkTagService).catch(() => {
          // fail silently
        });
      }
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }
  , getColumnDefs: (self) => {
    const payFrequencies = payFrequencyOptions(self)
    const quotaProps = getMinMaxQuota(self)
    const currencies = self.enumList.CurrencyEnum
    const colDefs = [
      {
        headerName: self.$t('resource.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: self.canEdit('RESOURCE', ['name'])
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
          return false
        }
      }
      , {
        headerName: self.$t('resource.field.description')
        , field: 'description'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'multilineEditor'
        , cellEditorParams: { title: self.$t('task.edit_description') }
        , hide: false
        , editable: true
      }
      , {
        headerName: self.$t('resource.field.cost')
        , field: 'payAmount'
        , cellRenderer: 'costCellRenderer'
        , cellRendererParams: {
          customCurrencyProp: 'payCurrency'
        }
        , cellEditor: 'costEditor'
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('resource.field.currency')
        , field: 'payCurrency'
        , cellEditor: 'listEditor'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { options: currencies, enableReadonlyStyle: true }
        , cellEditorParams: { options: currencies, isEnumType: true }
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('resource.field.frequency')
        , field: 'payFrequency'
        , cellEditor: 'listEditor'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { options: payFrequencies, enableReadonlyStyle: true }
        , cellEditorParams: { options: payFrequencies }
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      }
      , {
        headerName: self.$t('resource.field.resourceQuota')
        , field: 'resourceQuota'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'numericEditor'
        , cellEditorParams: {
          minValue: quotaProps.min
          , maxValue: quotaProps.max
        }
        , minWidth: 100
        , hide: true
        , editable: true
      }
    ]
    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = [{ selector: 'RESOURCE.TAG', field: 'tag', properties: ['name'] }]
    const viewLinkedEntities = JSON.parse(JSON.stringify(linkedEntities))
    viewLinkedEntities.push({ selector: 'RESOURCE', field: 'payAmount', properties: ['payCurrency'] })
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'RESOURCE', viewLinkedEntities)
    //EDIT permission: set column to be read only.
    setEditDenyPropertiesReadOnly(colDefs, 'RESOURCE', linkedEntities)
    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , resource: false
  })
  , getImportDataProperties: (self) => [
    { value: 'color', text: self.$t('field.color') }
    , { value: 'cost', text: self.$t('resource.field.cost') }
    , { value: 'currency', text: self.$t('resource.field.currency') }
    , { value: 'desc', text: self.$t('resource.field.description') }
    , { value: 'frequency', text: self.$t('resource.field.frequency') }
    , { value: 'identifier', text: self.$t('field.identifier') }
    , { value: 'name', text: self.$t('resource.field.name') }
    , { value: 'tag', text: self.$t('field.tag') }
  ]
  , entityUpdateApiUrl: '/api/resource/update'
  , entityDeleteApiUrl: '/api/resource/delete'
  , getValueChangedHandler: (/** self */) => ({})
  , getPropertyCompatibleFunc: (self) => {
    const _dataGroup = {
      stringGroup: ['name', 'description', 'identifier']
    }
    return (src, tgt) => {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(_dataGroup)
      for(const key of keys) {
        if (_dataGroup[key].includes(src) && _dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
  }
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
      , payCurrency: () => ({ value: null, status: 'ABORT' })
      , payFrequency: () => ({ value: null, status: 'ABORT' })
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    let maxDescriptionLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'description')
      if (val.length > 0) {
        maxDescriptionLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , description: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxDescriptionLength) {
          value = srcValue.substring(0, maxDescriptionLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , getExportDataPropertyHandler: (/** self */) => {
    const formatCost = (value, currencyCode=null) => {
      let rawValue = parseInt(value);
      if (rawValue < 0) {
        return '';
      }
      else {
        return currencyCode == null? `$${costFormat(rawValue, {notation:'standard'})}` : costFormatAdv(rawValue, currencyCode, {notation:'standard'});
      }
    }

    return {
      payAmount: (params) => {
        const currencyCode = params.node != null 
                              && params.node.data != null
                              && params.node.data.payCurrency != null 
                              && params.node.data.payCurrency.trim().length > 0? params.node.data.payCurrency : null
        return formatCost(params.value, currencyCode)
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'RESOURCE', action: 'VIEW' }])
  }
  , getMandatoryFields() {
    return [
      'name', 'payCurrency', 'payFrequency'
    ]
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('resource.field.name') }
      , { value: 'color', text: self.$t('resource.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'payFrequency', text: self.$t('resource.field.payFrequency') }
      , { value: 'payCurrency', text: self.$t('resource.field.currency') }
      , { value: 'identifier', text: self.$t('field.identifier') }
      // , { value: 'resourceQuota', text: self.$t('resource.field.resourceQuota') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      }
      return resourceService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            if (field == 'payFrequency') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                const text = !d ? '(Empty)' : self.$t(`payFrequency.${d}`)
                const value = d ? d : null
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            } else if (field == 'payCurrency') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                let text = d
                if (!d) {
                  text = '(Empty)'
                } else {
                  const found = self.enumList.CurrencyEnum.find(j => j.value === d);
                  text = found != null? found.text : '(Empty)'
                }
                const value = d ? d : null
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            }
            
            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        if (field == 'payFrequency') {
          //Additional property 'value' is added to keep the original value.
          const list = data.map(i => ({ text: !i ? '(Empty)' : self.$t(`payFrequency.${i}`), value: i ? i : null, checked: false }))
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        } else if (field == 'payCurrency') {
          //Additional property 'value' is added to keep the original value.
          let list = []
          for (const d of data) {
            let text = d
            if (!d) {
              text = '(Empty)'
            } else {
              const found = self.enumList.CurrencyEnum.find(j => j.value === d);
              text = found != null? found.text : '(Empty)'
            }
            const value = d ? d : null
            list.push({
              text
              , value
              , checked: false
            })
          }
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
}
