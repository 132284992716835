import Vue from 'vue';
import { costFormat } from '@/helpers/cost-format.js';
import { convertMsToDays } from '@/helpers/time-format.js'
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
  import { countryCodes } from '@/selectOptions';
import { validateEmail } from '@/helpers';

export function getMacroParams(macro, entity) {
  if (macro.includes('(A)')) {
    if (macro === '=fullPath(A)' ||
        macro === '=timeStamp(A)') {
      return [macro, [`${entity}.name`, '--']]; // =track requires a field and not just the entity
    }
  return [macro, [entity]];
  }
  else if (macro.includes('(A,B)')) {
  return [macro, [entity], '<AUTO>'];
  }
  else if (macro.includes('fixedCost(A,B,C)')) {
    return [macro, [entity], '<AUTO>', false];
  }
  else if (macro.includes('Cost(A,B,C)')) {
    return [macro, [entity], '<AUTO>', false];
  }
  else if (macro.includes('fixedCostNet(A,B,C)')) {
    return [macro.replace('CostNet', 'Cost'), [entity], '<AUTO>', true];
  }
  else if (macro.includes('CostNet(A,B,C)')) {
    return [macro.replace('CostNet', 'Cost'), [entity], '<AUTO>', true];
  }
}

export function transformField(field, type, retType='object') {
  if (type === 'Macro') {
    const fieldparts = field.split('.');
    const macro = fieldparts[fieldparts.length - 1];
    const entity = fieldparts.slice(0, -1).join('.');
    if (retType === 'object') {
      if (macro.includes('Duration') || macro.includes('TimeToComplete')) {
        return [getMacroParams(macro, entity), 0, "Days"];
      }
      else if (macro === '=fullPath(A)') {
        if (entity === 'PROJECT.TASK') {
          return [getMacroParams(macro, entity), "", "", "rcombine"];
        }
        else {
          return [getMacroParams(macro, entity), "", "", "combine"];
        }
      }
      else {
        return [getMacroParams(macro, entity)];
      }
    }
    else {
      return getMacroParams(macro, entity);
    }
  }
  return field;
}

export function getDefaultValue(func) {
  if (func === 'year' ||
      func === 'month' ||
      func === 'day') {
    return "<OLD>";   
  }
  return "<AUTO>";
}

export function isBlacklisted(entity, field) {
  // Column feature blacklist
  const blacklist = {
      "ACTIVITY": ['durationAUM'],
      "ACTIVITY-RESOURCE": ['duration','durationAUM','durationStep'],
      "BOOKING": ['durationAUM'],
      "CONTACT" : ['emails','messengers'],
      "STORAGE_FILE" : ['accessLevel','countAccess','status','label','isEmpty','fileNotes','deleted','checkSum','created','createdDate','modified','modifiedDate'],
      "LOCATION" : ['avatarUrl','bannerUrl','pictureUrl','avatarRef','bannerRef','description','emails','messengers','socials','phones','websites','timezone', 'CONTACT'],
      "PROJECT": ['avatarUrl','bannerUrl','complexity','duration','durationAUM','durationStep','durationUnit','lockDuration','pictureUrl','editable','readonly','sgId','templateRef','taskType','phase'],
      "PROJECT_TEMPLATE": ['actualCost','actualCostNet','actualDuration','autoScheduling','avatarUrl','bannerUrl','closeTime','currencyCode','complexity','duration','durationAUM','durationStep','durationUnit','lockDuration','estimatedTimeToComplete','fixedCost','fixedCostNet','pictureUrl','priority','scheduleMode','startTime','editable','readonly','sgId','templateRef','totalFixedCost','totalFixedCostNet','phase','scheduleStart','scheduleFinish', 'TASK'],
      "RESOURCE-ACTIVITY": ['duration','durationAUM','durationStep'],
      "RESOURCE-STAFF": ['duration','durationAUM','durationStep'],
      "RESOURCE-TASK_TEMPLATE": ['duration','durationAUM','durationStep'],
      "STAFF": ['avatarUrl','bannerUrl','created','createdDate','modified','modifiedDate','description','emails','messengers','mobile','enabled','registered','superUser','nickName'],
      "STAFF-RESOURCE": ['duration','durationAUM','durationStep'],
      "TASK": ['avatarUrl','bannerUrl','duration','durationAUM','durationStep','durationUnit','editable','lockDuration','pictureUrl','readonly','templateRef','phase','scheduleStart','scheduleFinish','scheduleMode'],
      "TASK_TEMPLATE": ['avatarUrl','actualCost','actualCostNet','actualDuration','bannerUrl','duration','durationAUM','durationStep','durationUnit','editable','estimatedTimeToComplete','fixedCost','fixedCostNet','totalFixedCost','totalFixedCostNet','lockDuration','pictureUrl','readonly','templateRef','phase','scheduleStart','scheduleFinish','scheduleMode','progress'],
      "TASK_TEMPLATE-RESOURCE": ['duration','durationAUM','durationStep'],
      "TASK-PREDECESSOR_ALL_TASK": ['editable','durationAUM','lag','readonly','predecessor','successor'],
      "TAG": ['color', 'description', 'identifier'],
      "USER": ['avatarRef','avatarUrl','bannerRef','bannerUrl','nonExpired','registered','created','createdDate','modified','modifiedDate']
  }
  
  if (entity in blacklist) {
    const list = blacklist[entity];
    for (const term of list) {
      if (term === field) {
        return true;
      }
    }
  }
  return false;
}

export function isBlacklistedPermission(entity, field, isEdit) {
  if (entity === 'TASK' &&
      field === 'duration' &&
      !isEdit) {
    field = 'estimatedDuration'; // blacklist duration for non edit
  }
  else if (entity === 'TASK' &&
      field === 'estimatedDuration' &&
      !isEdit) {
    field = 'duration'; // do not blacklist estimatedDuration for non edit
  }
  
  // do not allow denying name, firstName or lastName in view
  if ((field === 'name' ||
      field === 'lastName' ||
      field === 'firstName') &&
      !isEdit) {
    return true; 
  }
      
  // Column feature blacklist
  const blacklist = {
      "ACCESS_POLICY": ['ACCESS_POLICY-PERMISSION', 'PERMISSION', 'USER'],
      "ACTIVITY": ['durationAUM', 'ACTIVITY-RESOURCE', 'ACTIVITY-STAFF'],
      "ACTIVITY-RESOURCE": ['duration','durationAUM','durationStep'],
      "BOOKING": ['durationAUM'],
      "CONTACT" : ['emails','messengers', 'COMPANY', 'CUSTOMER'],
      "CUSTOMER": ['PROJECT', 'PROJECT_TEMPLATE'],
      "DEPARTMENT": ['COMPANY'],
      "STORAGE_FILE" : ['accessLevel','countAccess','status','label','isEmpty','fileNotes','deleted','checkSum','created','createdDate','modified','modifiedDate','ACTIVITY', 'BOOKING', 'COMPANY', 'CONTACT', 'CUSTOMER', 'DEPARTMENT', 'PROJECT', 'PROJECT_TEMPLATE', 'RESOURCE', 'STAFF', 'STORAGE_FOLDER', 'TASK', 'TASK_TEMPLATE'],
      "LOCATION" : ['avatarUrl','bannerUrl','pictureUrl','avatarRef','bannerRef','description','emails','messengers','socials','phones','websites','timezone', 'ACTIVITY', 'COMPANY', 'CONTACT', 'CUSTOMER', 'PROJECT', 'PROJECT_TEMPLATE', 'STAFF'],
      "NOTE": ['ACTIVITY', 'BOOKING', 'COMPANY', 'CONTACT', 'CUSTOMER', 'DEPARTMENT', 'LOCATION', 'PROJECT', 'PROJECT_TEMPLATE', 'REBATE', 'SKILL', 'RESOURCE', 'STAFF', 'STORAGE_FILE', 'STORAGE_FOLDER', 'TASK', 'TASK_TEMPLATE'],
      "PROJECT": ['avatarUrl','bannerUrl','complexity','duration','durationAUM','durationStep','durationUnit','lockDuration','pictureUrl','editable','readonly','sgId','templateRef','taskType','phase', 'COMPANY', 'PROJECT_TEMPLATE', 'STAGE_LIST'],
      "PROJECT_TEMPLATE": ['actualCost','actualCostNet','actualDuration','autoScheduling','avatarUrl','bannerUrl','closeTime','currencyCode','complexity','duration','durationAUM','durationStep','durationUnit','lockDuration','estimatedTimeToComplete','fixedCost','fixedCostNet','pictureUrl','priority','scheduleMode','startTime','editable','readonly','sgId','templateRef','totalFixedCost','totalFixedCostNet','phase','scheduleStart','scheduleFinish'],
      "REBATE": ['ACTIVITY', 'PROJECT', 'PROJECT_TEMPLATE', 'TASK_TEMPLATE', 'TASK'],
      "RESOURCE": ['ACTIVITY', 'RESOURCE-ACTIVITY', 'RESOURCE-STAFF', 'RESOURCE-TASK', 'RESOURCE-TASK_TEMPLATE', 'STAFF', 'TASK', 'TASK_TEMPLATE'],
      "RESOURCE-ACTIVITY": ['duration','durationAUM','durationStep'],
      "RESOURCE-STAFF": ['duration','durationAUM','durationStep'],
      "RESOURCE-TASK_TEMPLATE": ['duration','durationAUM','durationStep'],
      "SKILL": ['SKILL-STAFF', 'SKILL-TASK_TEMPLATE', 'SKILL-TASK', 'STAFF', 'TASK', 'TASK_TEMPLATE'],
      "STAFF": ['avatarUrl','bannerUrl','created','createdDate','genericStaff','modified','modifiedDate','description','emails','messengers','mobile','enabled','registered','superUser','nickName', 'ACTIVITY', 'COMPANY', 'DEPARTMENT', 'STAFF-RESOURCE', 'STAFF-SKILL', 'STAFF-TASK_TEMPLATE', 'STAFF-TASK', 'STAFF-ACTIVITY', 'TASK_TEMPLATE', 'TASK'],
      "STAFF-RESOURCE": ['duration','durationAUM','durationStep'],
      "STAGE": ['ACTIVITY', 'BOOKING', 'PROJECT', 'TASK'],
      "TASK": ['avatarUrl','bannerUrl','estimatedDuration','durationAUM','durationStep','durationUnit','editable', 'fullPath', 'lockDuration','pictureUrl','readonly','templateRef','phase','scheduleStart','scheduleFinish','scheduleMode', 'CHILD_TASK', 'CHILD_ALL_TASK', 'PROJECT', 'PARENT_TASK', 'PARENT_ALL_TASK', 'PREDECESSOR_TASK', 'PREDECESSOR_ALL_TASK', 'PROJECT_TEMPLATE', 'TASK-PREDECESSOR_ALL_TASK', 'TASK-RESOURCE', 'TASK-PREDECESSOR_TASK', 'TASK-SKILL', 'TASK-STAFF'],
      "TASK_TEMPLATE": ['avatarUrl','actualCost','actualCostNet','actualDuration','bannerUrl','duration','durationAUM','durationStep','durationUnit','editable','estimatedTimeToComplete','fixedCost','fixedCostNet','totalFixedCost','totalFixedCostNet','lockDuration','pictureUrl','readonly','templateRef','phase','scheduleStart','scheduleFinish','scheduleMode','progress'],
      "TASK_TEMPLATE-RESOURCE": ['duration','durationAUM','durationStep'],
      "TASK-PREDECESSOR_ALL_TASK": ['editable','durationAUM','lag','readonly','predecessor','successor'],
      "TAG": ['color', 'description', 'identifier'],
      "USER": ['avatarRef','avatarUrl','bannerRef','bannerUrl','nonExpired','registered','created','createdDate','modified','modifiedDate', 'PERMISSION', 'STAFF', 'USER-PERMISSION']
  }
  
  if (entity in blacklist) {
    const list = blacklist[entity];
    for (const term of list) {
      if (term === field) {
        return true;
      }
    }
  }
  return false;
}

export function getFieldType(field, schema) {
  var type = '';
  if (schema !== null && typeof field === 'string') {
    const fieldparts = field.split('.');
    if (fieldparts.length > 1) {
      const entity = fieldparts[fieldparts.length - 2];
      // if the schema[entity] is a string then use this to find the 'real' object
      // example entity is CHILD_TASK then schema[entity] will be TASK
      const properties = typeof schema[entity] !== 'undefined' ? (typeof schema[entity] === 'string' ? schema[schema[entity]].properties : schema[entity].properties) : null;
      if (properties !== null && typeof properties !== 'undefined') {
        const prop = properties.filter(p => p.field === fieldparts[fieldparts.length - 1]);
        if (prop.length !== 0) {
          type = prop[0].type;
          if (type === 'Integer' && fieldparts[fieldparts.length - 1] === 'duration') {
            if (entity === 'BOOKING' ||
                entity === 'ACTIVITY') {
              type = 'Duration';
            }
            else {
              type = 'MinuteDuration';
            }
          }
          // estimatedTimeToComplete
          else if (type === 'Integer' && (fieldparts[fieldparts.length - 1] === 'estimatedTimeToComplete' ||
              fieldparts[fieldparts.length - 1].includes('Duration'))) {
            type = 'Duration';
          }
          else if (type === 'Float' && fieldparts[fieldparts.length - 1] === 'progress') {
            type = 'Progress';
          }
          else if (type === 'Integer' && fieldparts[fieldparts.length - 1] === 'priority') {
            type = 'Priority';
          }
          else if (type === 'Float' && fieldparts[fieldparts.length - 1].includes('Cost')) {
            type = 'Cost';
          }
          else if (type === 'Float' && fieldparts[fieldparts.length - 1] === 'payAmount') {
            type = 'Cost';
          }
          else if (type === 'Enum' && fieldparts[fieldparts.length - 1] === 'payFrequency') {
            type = 'PayFrequency';
          }
          else if (type === 'Enum' && fieldparts[fieldparts.length - 1] === 'taskType') {
            type = 'TaskType';
          }
          else if (type === 'Enum' && fieldparts[fieldparts.length - 1] === 'staffType') {
            type = 'StaffType';
          }
          else if (fieldparts[fieldparts.length - 1] === 'skillLevels') {
            type = 'List<KindData>';
          }
          else if (type === 'List<KindData>') {
            type = 'List<KindData>';
          }
          else if (type === 'List<String>') {
            type = 'List<String>';
          }
        }
        else if (fieldparts[fieldparts.length - 1].startsWith('=')) {
          type = 'Macro';
        }
      }
    }
  }
  else if (Array.isArray(field)) {
    type = [];
    for (var i = 0; i < field.length; i++) {
      if (typeof field[i] === 'object') {
        const fieldType = getFieldType(field[i].field, schema);
        type.push(fieldType);
      }
    }
  }
  else {
    type = 'Macro';
  }
  return type;
}

export function getMacroType(macro, macros) {
  var type = '';
  if (macros !== null && typeof macro === 'string') {
    const macroparts = macro.split('.');
    if (macroparts.length > 1) {
      const entity = macroparts[macroparts.length - 1].replace('CostNet', 'Cost');
      const entry = macros[entity];
      if (typeof entity !== 'undefined' && typeof entry !== 'undefined') {
        type = entry.type;
      }
    }
  }
  return type;
}

export function formatFieldValue(key, value, schema, { durationFunc=null, percentageFunc=null } = {}) {
  if (key == null || schema == null) {
    return value;
  }
  let type = getFieldType(key, schema);
  if (type === 'Macro') {
    if (key.indexOf('Cost') != -1) {
      type = 'Cost';
    }
  }
  const keyParts = key.split('.');
  const lastPart = keyParts[keyParts.length - 1];
  if (type == 'Float' && (lastPart.endsWith('rebate') || lastPart.endsWith('utilization') || lastPart.endsWith('Progress'))) {
    type = 'Percentage';
  } else if (type == 'HashMap' && lastPart.endsWith('=timeStamp(A)')) {
    type = 'DateHashMap';
  } else if (type == 'Macro') {
    if (key.indexOf('Cost') != -1) {
      type = 'Cost';
    }
  } else if (type == 'Float' && lastPart === 'payAmount') {
    type = 'Cost';
  } 

  let fValue = value;
  switch(type) {
    case 'Cost':
      fValue = `$${costFormat(value)}`;
      break;
    case 'Integer':
    case 'Long':
      fValue = parseFloat(value).toFixed(0).toString();
      break;
    case 'Percentage':
    case 'Progress':
      if (percentageFunc != null && typeof percentageFunc === 'function') {
        fValue = percentageFunc(value);
      } else {
        fValue = `${(parseFloat(value) * 100).toFixed(0).toString()}%`;  
      }
      break;
    case 'Date':
      fValue = moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
      break;
    case 'DateHashMap':
      fValue = moment.utc(value.at).format('YYYY-MM-DD HH:mm:ss');
      break;
    case 'Duration':
      if (durationFunc != null && typeof durationFunc === 'function') {
        fValue = durationFunc(value);
      } else {
        fValue = `${convertMsToDays(value)}D`;
      }
      break;
    default:
  }
  return fValue;
}

export function buildFilter(entity, query) {
  // create
  /*[
    "_and_",
    [
      [
        "STAFF.endDate",
        "gte",
        1593054882921
      ],
      "_or_",
      [
        [
          "STAFF.firstName",
          "regex",
          "(?i).*3.*"
        ],
        [
          "STAFF.lastName",
          "regex",
          "(?i).*3.*"
        ]
      ]
    ]*/      
  
  const filter = [];
  return processQuery(entity, query, filter);
}

function processQuery(entity, query, filter) {
  if (query && query.type === 'group') {
    var grouprules = [];
    for (var child of query.children) {
      grouprules = processQuery(entity, child, grouprules);
    }
    if (grouprules.length !== 0) {
      if (filter.length > 0) {
        const elem = filter.pop();
        filter.push(elem, query.operator, grouprules);
      }
      else {
        filter = [query.operator, grouprules];
      }
    }
  }
  else if (query && query.type === 'rule') {
    filter = processQueryRule(entity, query, filter);
  }
  return filter;
}

function getFieldStart(entity) {
  if (entity.endsWith('PROJECT')) {
    return `${entity}.scheduleStart`;
  }
  else if (entity.endsWith('TASK') ||
           entity.endsWith('ACTIVITY')) {
    return `${entity}.startTime`;
  }
  else if (entity.endsWith('STAFF')) {
    return `${entity}.startDate`;
  }
  else if (entity.endsWith('BOOKING')) {
    return `${entity}.beginDate`;
  }
  return ''; // unknown
}

function getFieldEnd(entity) {
  if (entity.endsWith('PROJECT')) {
    return `${entity}.scheduleFinish`;
  }
  else if (entity.endsWith('TASK') ||
      entity.endsWith('ACTIVITY')) {
    return `${entity}.closeTime`;
  }
  else if (entity.endsWith('STAFF')) {
    return `${entity}.endDate`;
  }
  else if (entity.endsWith('BOOKING')) {
    return `${entity}.untilDate`;
  }
  return ''; // unknown
}

function processCustomMacro(entity, query, filter) {
  const fieldEntity = query.field.substr(0, query.field.indexOf('.='));
  if (query.field.includes(".=active()")) {
    if ((query.operator === 'eq' && query.value) ||
        (query.operator === 'neq' && !query.value)) {
      filter.push('_and_');
      filter.push([[getFieldStart(fieldEntity), 'lt', '<NOW>'], [getFieldEnd(fieldEntity), 'gte', '<NOW>']]);
    }
    else {
      filter.push('_or_');
      filter.push([[getFieldStart(fieldEntity), 'gte', '<NOW>'], [getFieldEnd(fieldEntity), 'lte', '<NOW>']]);
    }
  }
  else if (query.field.includes(".=started()")) {
    if ((query.operator === 'eq' && query.value) ||
        (query.operator === 'neq' && !query.value)) {
      filter.push('_and_');
      filter.push([[getFieldStart(fieldEntity), 'lt', '<NOW>'], [getFieldStart(fieldEntity), 'gte', '<NOW>']]);
    }
    else {
      filter.push('_or_');
      filter.push([[getFieldStart(fieldEntity), 'gte', '<NOW>'], [getFieldStart(fieldEntity), 'lte', '<NOW>']]);
    }
  }
  else if (query.field.includes(".=ended()")) {
    if ((query.operator === 'eq' && query.value) ||
        (query.operator === 'neq' && !query.value)) {
      filter.push('_and_');
      filter.push([[getFieldEnd(fieldEntity), 'lt', '<NOW>'], [getFieldEnd(fieldEntity), 'gte', '<NOW>']]);
    }
    else {
      filter.push('_or_');
      filter.push([[getFieldEnd(fieldEntity), 'gte', '<NOW>'], [getFieldEnd(fieldEntity), 'lte', '<NOW>']]);
    }
  }
  return filter;
}

function processQueryRule(entity, query, filter) {
  // check the type of value this rule expects
  var field = "";
  var usingAg = false;
  if (query.field.includes('.=')) {
    if (query.field.includes(".=active()") ||
        query.field.includes(".=started()") ||
        query.field.includes(".=ended()")) {
      return processCustomMacro(entity, query, filter);
    }
    field = transformField(query.field, 'Macro', 'string');
  }
  else if (typeof query.agFunc !== 'undefined' && query.agFunc.applyFunction) {
    //["=aggregate(A,B)",["PROJECT.TASK"],["PROJECT.TASK.name",0,"count"]],
    //  "gt",
    //  20
    //]
    usingAg = true;
    if ((query.agFunc.agFunction === 'year' ||
        query.agFunc.agFunction === 'month' ||
        query.agFunc.agFunction === 'day' ||
        query.agFunc.agFunction === 'hour' ||
        query.agFunc.agFunction === 'minute') && query.field.split('.').length === 2) {
      // [ "PROJECT.closeTime", "gte", "2021-01-01 00:00"]
      field = ["=calc(A)", [ query.field, "<OLD>", query.agFunc.agFunction ]];
    }
    else {
      const agField = query.field.substr(entity.length + 1); // remove entity from field name
      field = ["=aggregate(A,B,C)", [entity], [agField, 0, query.fieldtype.toLowerCase()], query.agFunc.agFunction];
    }
  }
  else {
    field = query.field;
  }
  const operator = query.operator;
  var value = typeof query.value !== 'undefined' ? query.value : '';
  if (query.fieldtype === "Date" && !(typeof query.agFunc !== 'undefined' && query.agFunc.applyFunction)) {
    if (value !== '<NOW>') {
      value = Date.parse(value);
    }
  }
  else if (query.fieldtype === "Integer" ||
            query.fieldtype === "Long" ||
            usingAg) {
    value = parseInt(value);
  }
  else if (query.fieldtype === "Float") {
    value = value !== '' ? parseFloat(value) : 0.0;
  }
  else if (operator === 'regex' || operator === 'containsRegex') {
    value = `(?)${value}`;
  }
  
  if (operator === 'between' || operator === 'outside' || operator === 'inside') {
    var value2 = typeof query.value2 !== 'undefined' ? query.value2 : '';
    if (query.fieldtype === "Date") {
      value2 = Date.parse(value2);
    }
    else if (query.fieldtype === "Integer" ||
              query.fieldtype === "Long") {
      value2 = parseInt(value2);
    }
    else if (query.fieldtype === "Float") {
      value2 = parseFloat(value2);
    }
    value = `${value}|${value2}`;
  }
  
  filter.push([field, operator, value]);
  return filter;
}

export function checkRequiredField(field, item, mode, { currencies=[], priorities=[]}={}) {
  if ((field !== 'starttime' && field !== 'closetime' && field !== 'duration' && !(field === 'type' && mode === 'TASKS')) &&
      (item[field] === '' ||
      !item[field] ||
      item.emailErrorMessage)) {
    return true; 
  }
  else if (field === 'duration') {
    item.durationErrorMessage = null;
    if (item.type === 'Task' &&
        (!item.duration ||
            /^0[D,m,h,W,M,Y]*$/.test(item.duration))) {
      item.durationErrorMessage = 'task.error.duration';
      return true;
    }
  }
  else if (field === 'email') {
    const valid = validateEmail(item.email);
    if (!valid) {
      item.emailErrorMessage = 'staff.error.email';
      return true;
    }
  }
  else if (field === 'cost') {
    item.costErrorMessage = null;
    // eslint-disable-next-line
    if (!item[field].match(/^[\d,\.]+$/)) {
      item.costErrorMessage = 'resource.error.cost_number';
      return true;
    }        
  }
  else if (field === 'currencycode' ||
           field === 'currency') {
    item.currencyCodeErrorMessage = null;
    let found = false;
    for (const currency of currencies) {
      if (item[field] === currency.value) {
        found = true;
        break;
      }
    }
    if (!found) {
      item.currencyCodeErrorMessage = 'project.error.currency_code';
      return true;
    }        
  }
  else if (field === 'priority') {
    item.priorityCodeErrorMessage = null;
    let found = false;
    for (const priority of priorities) {
      if (item[field] === priority.value) {
        found = true;
        break;
      }
    }
    if (!found) {
      item.priorityCodeErrorMessage = 'project.error.priority';
      return true;
    }        
  }
  else if (field === 'country') {
    item.countryErrorMessage = null;
    let found = false;
    for (const country of countryCodes) {
      if (item[field] === country.value) {
        found = true;
        break;
      }
    }
    if (!found) {
      item.countryErrorMessage = 'location.error.country';
      return true;
    }        
  }
  else if (field === 'rebate') {
    item.rebateErrorMessage = null;
    const rebate = parseInt(item[field]);
    if (rebate <= 0 || rebate > 100 || Number.isNaN(rebate)) {
      item.rebateErrorMessage = 'rebate.error.value';
      return true;
    }        
  }
  else if (field === 'starttime' &&
           typeof item[field] !== 'undefined') {
    item.starttimeErrorMessage = null;
    const dt = new Date(item[field]).getTime();
    if (Number.isNaN(dt) && item[field] !== null) {
      item.starttimeErrorMessage = 'project.error.starttime';
      return true;
    }        
  }
  else if (field === 'closetime' &&
           typeof item[field] !== 'undefined') {
    item.starttimeErrorMessage = null;
    const dt = new Date(item[field]).getTime();
    if (Number.isNaN(dt) && item[field] !== null) {
      item.starttimeErrorMessage = 'project.error.closetime';
      return true;
    }        
  }
  else if (field === 'payamount') {
    item.payamountErrorMessage = null;
    //eslint-disable-next-line
    if (!item[field].match(/^[\d,\.]+$/)) {
      item.payamountErrorMessage = 'staff.error.pay_amount';
      return true;
    }  
  }
  return false;
}

export function calcSpan(params) {
  // starting at this column count the contiguous cells
  const colId = params.column.colId.substr(2);
  let count = 0;
  let start = false;
  for (const key of Object.keys(params.data.w)) {
    if (key === colId) {
      start = true;
    }
    
    const obj = params.data.w[key];
    const exists = obj.t ? obj.t.filter(t => t.tu === params.data.tu).length !== 0 : false;
    if (start) {
      if (exists) {
        count++;
      }
      else {
        break;
      }
    }
  }
  return count !== 0 ? count : 1;
}

export function ellipsisText(val, position = 'start', length = 40) {
  if (val.length > length) {
    if (position === 'start') {
      return `...${val.substr(val.length - length, length)}`;
    }
    else {
      return `${val.substr(0, length)}...`;
    }
  }
  return val;
}

export function formatField(item) {
  if (typeof item.agFunc === 'undefined' ||
      !(item.agFunc.applyFunction)) {
    return item.field.replace(/\(A\)|\(A,B\)/, "()");
  }
  return `${item.agFunc.agFunction.toUpperCase()}(${item.field})`;
}

export function prepareQuery(query, edit) {
  if (query === null) {
    return query;
  }
  
  if (query) {
    if (query.type === 'group') {
      for (var i = 0; i < query.children.length; i++) {
        const child = query.children[i];
        query.children[i] = prepareQuery(child, edit);
      }
    }
    else {
      // clear the errors before checking
      delete query.operatorError;
      delete query.fieldError;
      delete query.valueError;
      delete query.regexError;
      // Using value in v-modal causes problems getting the values
      // back from the edtiors so changing this to value1 works better
      if (edit) {
        Vue.set(query, 'value1', query.value);
      }
      else {
        Vue.set(query, 'value', query.value1);
        delete query.value1;
      }
    }
  }
  return query;
}

export function replacePlaceholders(query, placeholders) {
  if (query === null) {
    return query;
  }
  
  if (query !== null) {
    if (query.type === 'group') {
      for (var i = 0; i < query.children.length; i++) {
        const child = query.children[i];
        query.children[i] = replacePlaceholders(child, placeholders);
      }
    }
    else if (query.value in placeholders){
      Vue.set(query, 'value', placeholders[query.value]);
    }
  }
  return query;
}


export function getSum(data, self) {
  const ret = {};
  for (const d of data) {
    for (const key of Object.keys(d)) {
      const fieldType = getFieldType(key.replace(/ /g, '.'), self.schema);
      if (typeof d[key] === 'number' &&
          fieldType !== 'Progress' &&
          fieldType !== 'Date' &&
          !(d[key] === -1 && fieldType === 'Cost') &&
          !key.includes('plannedProgress')) { // -1 means the cost is not set
        if (!ret[key]) {
          ret[key] = d[key];
        }
        else {
          ret[key] += d[key];
        }
      }
    }
  }
  return ret;
}

export function getMin(data, self) {
  const ret = {};
  for (const d of data) {
    for (const key of Object.keys(d)) {
      const fieldType = getFieldType(key.replace(/ /g, '.'), self.schema);
      if (typeof d[key] === 'number' &&
          fieldType !== 'Progress' &&
          fieldType !== 'Date' &&
          !key.includes('plannedProgress')) {
        const val = !(d[key] === -1 && fieldType === 'Cost') ? d[key] : 0; // -1 means the cost is not set
        if (!ret[key] && ret[key] !== 0) {
          ret[key] = val;
        }
        else if (val < ret[key]) {
          ret[key] = val;
        }
      }
    }
  }
  return ret;
}

export function getMax(data, self) {
  const ret = {};
  for (const d of data) {
    for (const key of Object.keys(d)) {
      const fieldType = getFieldType(key.replace(/ /g, '.'), self.schema);
      if (typeof d[key] === 'number' &&
          fieldType !== 'Progress' &&
          fieldType !== 'Date' &&
          !(d[key] === -1 && fieldType === 'Cost') &&
          !key.includes('plannedProgress')) { // -1 means the cost is not set
        if (!ret[key]) {
          ret[key] = d[key];
        }
        else if (d[key] > ret[key]) {
          ret[key] = d[key];
        }
      }
    }
  }
  return ret;
}

export function getAvg(data, self) {
  const total = data.length;
  const sum = getSum(data, self);
  for (const key of Object.keys(sum)) {
    if (typeof sum[key] === 'number') {
      sum[key] = parseFloat(parseFloat(sum[key] / total).toFixed(1).replace(/\.?0+$/, ''));
    }
  }
  return sum;
}
