export function msToTime(duration) {
  if (typeof duration === 'undefined' ||
      duration === null) {
    return null;
  }
  if (typeof duration === 'string') {
    return duration;
  }

  let hour = Math.trunc(duration / 1000 / 60 / 60);
  if (hour < 10) {
    hour = '0' + hour;
  }
  let minute = duration / 1000 / 60 % 60;
  if (minute < 10) {
    minute = '0' + minute;
  }
  return `${hour}:${minute}`;
}

export function timeToMs(value) {
  const matches = /^(\d+):(\d+).*?$/g.exec(value);
  var val = 0;
  if (matches !== null) {
    val = ((parseInt(matches[1]) * 60 * 60) + (parseInt(matches[2]) * 60)) * 1000;
  }
  return val;
}

export function convertMsToDuration(ms, units) {
  let seconds = (ms / 1000) | 0;
  ms -= seconds * 1000;

  if (seconds === 0) {
    return `${seconds}D`;
  }

  let minutes = (seconds / 60) | 0;
  seconds -= minutes * 60;

  if (minutes === 0) {
    return `${seconds}s`;
  }

  let hours = (minutes / 60) | 0;
  minutes -= hours * 60;

  if (hours === 0) {
    return `${minutes}m`;
  }

  let days = (hours / 8) | 0;
  hours -= days * 8; // 8 working hours in a day

  if (days === 0) {
    return `${hours}h`;
  }

  if (units === 'days') {
    return `${days}D`;
  }

  let weeks = (days / 7) | 0;
  days -= weeks * 7;

  if (weeks === 0) {
    return `${days}D`;
  }

  let months = (weeks / 4) | 0;
  weeks -= months * 4;

  if (months === 0) {
    return `${weeks}W`;
  }

  return `${months}W`;
}

export function convertMsToDays(ms) {
  let seconds = (ms / 1000) | 0;
  ms -= seconds * 1000;

  if (seconds === 0) {
    return 0;
  }

  let minutes = (seconds / 60) | 0;
  seconds -= minutes * 60;

  if (minutes === 0) {
    return 0;
  }

  let hours = (minutes / 60) | 0;
  minutes -= hours * 60;

  if (hours === 0) {
    return 0;
  }

  let days = (hours / 8) | 0;
  hours -= days * 8; // 8 working hours in a day

  return days;
}

export function convertMinutesToDuration(minutes, units) {
  // "D" = 28800000 (8h) W=144000000 (5days) M=604800000 (21days) Y=7488000000 (260 days)

  if (units === 'D') {
    return `${minutes / 60 / 8}D`;
  }
  else if (units === 'W') {
    return `${minutes / 60 / 8 / 5}W`;
  }
  else if (units === 'M') {
    return `${minutes / 60 / 8 / 21}M`;
  }
  else if (units === 'Y') {
    return `${minutes / 60 / 8 / 260}Y`;
  }

}

export function convertMinutesToDays(minutes) {
  return minutes / 60 / 8;
}

export function convertDurationToMinutes(dur) {
  // "D" = 28800000 (8h) W=144000000 (5days) M=604800000 (21days) Y=7488000000 (260 days)
  if (dur === 0) {
    return dur;
  }

  var durUnit = dur[dur.length - 1];
  if (dur.indexOf('.') === 1) {
    dur = parseFloat(dur.substring(0, dur.length - 1));
  }
  else {
    dur = parseInt(dur.substring(0, dur.length - 1));
  }

  if (durUnit === 'Y') {
    dur = dur * 260 * 8 * 60;
  }
  else if (durUnit === 'M') {
    dur = dur * 21 * 8 * 60;
  }
  else if (durUnit === 'W') {
    dur = dur * 5 * 8 * 60;
  }
  else if (durUnit === 'D') {
    dur = dur * 8 * 60;
  }
  else if (durUnit === 'h') {
    dur = dur * 60;
  }

  return dur;
}

export function convertDurationToMs(dur) {
  if (dur === 0) {
    return dur;
  }

  var durUnit = dur[dur.length - 1];
  if (dur.indexOf('.') === 1) {
    dur = parseFloat(dur.substring(0, dur.length - 1));
  }
  else {
    dur = parseInt(dur.substring(0, dur.length - 1));
  }

  if (durUnit === 'Y') {
    dur = dur * 365 * 24 * 60 * 60 * 1000;
  }
  else if (durUnit === 'M') {
    dur = dur * 4 * 7 * 24 * 60 * 60 * 1000;
  }
  else if (durUnit === 'W') {
    dur = dur * 7 * 24 * 60 * 60 * 1000;
  }
  else if (durUnit === 'D') {
    dur = dur * 24 * 60 * 60 * 1000;
  }
  else if (durUnit === 'h') {
    dur = dur * 60 * 60 * 1000;
  }
  else if (durUnit === 'm') {
    dur = dur * 60 * 1000;
  }
  else if (durUnit === 's') {
    dur = dur * 1000;
  }

  return dur;
}

export function incrementDuration(dur, delta) {
  if (dur === 0) {
    return dur;
  }

  var durUnit = dur[dur.length - 1];
  if (durUnit.match(/[a-z]/i)) {
    dur = parseInt(dur.substring(0, dur.length - 1));
  }
  else {
    dur = parseInt(dur);
    durUnit = 'D';
  }
  dur += delta;

  if (dur < 0) {
    dur = 0;
  }
  
  return dur + durUnit;
}

export function calculateDurationDiff(est, act, units) {
  let diff = new Date(est - act);
  return convertMsToDuration(diff, units);
}