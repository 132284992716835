import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { TRIGGERS, convertDurationToDisplay
  , calcDateTimeDurationv2 } from '@/helpers/task-duration-process';

export function getCalendar(projLocationId, parentComponent) {
  let calendar = null;
  let _projLocationId = null;
  if (projLocationId != null) {
    _projLocationId = projLocationId;
  }
  if (parentComponent.locationCalendarMap != null && parentComponent.locationCalendarMap instanceof Map) {
    const locationCalendarMap = parentComponent.locationCalendarMap;
    if (locationCalendarMap.has(_projLocationId)) {
      calendar = locationCalendarMap.get(_projLocationId);
    }
  }

  if (calendar == null) {
    calendar = parentComponent.calendar;
  }
  return calendar;
}
  

export function taskMove_calcDurationDisplay(task, parentComponent) {
  const timescale = parentComponent.timescale;

  const convertDateToMoment = function(_date) {
    return moment.utc([
      _date.getFullYear()
      , _date.getMonth()
      , _date.getDate()
      , _date.getHours()
      , _date.getMinutes()
      , _date.getSeconds()
    ]);
  }
  const startTime = convertDateToMoment(task.start_date);
  const closeTime = convertDateToMoment(task.end_date);
  const calendar = getCalendar(task.projLocationId, parentComponent);

  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  if (task.projScheduleFrom != null && task.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  } else if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
    trigger = TRIGGERS.CLOSE_DATE;
    projectScheduleFromStart = false;
  }

  let taskAutoScheduleMode = task.auto_scheduling;
  if (task.actual_auto_scheduling != null) { //Used by PagedAgGridGantt
    taskAutoScheduleMode = task.actual_auto_scheduling; 
  }

  if (timescale != 'day') {
    const oct = task.org_end_date;
    closeTime.hour(oct.getHours()).minute(oct.getMinutes());
  }

  const payload = {
    trigger: trigger
    , startDateStr: startTime.format('YYYY-MM-DD')
    , startTimeStr: startTime.format('HH:mm')
    , closeDateStr: closeTime.format('YYYY-MM-DD')
    , closeTimeStr: closeTime.format('HH:mm')
    , durationDisplay: null
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: taskAutoScheduleMode
    , lockDuration: false
    , autoMoveForNonWorkingDay: true
    , skipOutOfProjectDateCheck: true
  }
  const result = calcDateTimeDurationv2(payload);
  return result.durationDisplay;
}

export function prepareMovedTasks(tasks, parentComponent, isAgGridGantt=true) {
  const returnList = [];
  const timescale = parentComponent.timescale;
  for (const data of tasks) {
    const oldStartDateTime = moment.utc(data.orgStartDate);
    const oldCloseDateTime = moment.utc(data.orgEndDate);
    let startTime = moment.utc(data.start_date);
    let closeTime = moment.utc(data.end_date);
    
    //Stop proceed further when startTime and closeTime have no change.
    if (oldStartDateTime.format('YYYY-MM-DD HH:mm') == startTime.format('YYYY-MM-DD HH:mm') 
        && oldCloseDateTime.format('YYYY-MM-DD HH:mm') == closeTime.format('YYYY-MM-DD HH:mm')) {
      continue;
    }
    
    let constraintDate = data.constraint_date != null? moment.utc(data.constraint_date) : null;
    
    let durationDisplay = convertDurationToDisplay(data.orgDuration, data.durationAUM);
    if (timescale == 'day') {
      const minute = parseInt(startTime.minutes() / 15) * 15;
      if (minute >= 60) {
        startTime.add(1, 'hour');
        startTime.minutes(0);
      } else {
        startTime.minutes(minute);
      }
    } else {
      //when timescale is 'week', 'month', or 'year'. Not 'day'.
      //Reuse the original time (hour and minute). 
      startTime = moment.utc(`${startTime.format('YYYY-MM-DD')} ${oldStartDateTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
      closeTime = moment.utc(`${closeTime.format('YYYY-MM-DD')} ${oldCloseDateTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');

      let calendar = null;
      if (parentComponent.locationCalendarMap != null && parentComponent.locationCalendarMap instanceof Map) {
        const locationCalendarMap = parentComponent.locationCalendarMap;
        if (locationCalendarMap.has(data.projLocationId)) {
          calendar = locationCalendarMap.get(data.projLocationId);
        }
      }
      if (calendar == null) {
        calendar = parentComponent.calendar;
      }
      
      let trigger = TRIGGERS.START_DATE;
      let projectScheduleFromStart = true;

      if (isAgGridGantt) {
        if (parentComponent.project != null && parentComponent.project.scheduleFrom == 'ALAP') { //Used by AgGridGantt
          trigger = TRIGGERS.CLOSE_DATE;
          projectScheduleFromStart = false;
        }
      } else { //for pagedAgGridGantt
        if (data.projScheduleFrom != null && data.projScheduleFrom == 'ALAP') { //Used by PagedAgGridGantt
          trigger = TRIGGERS.CLOSE_DATE;
          projectScheduleFromStart = false;
        }
      }
      

      let taskAutoScheduleMode = null;
      if (isAgGridGantt) {
        taskAutoScheduleMode = data.auto_scheduling;
      } else {
        taskAutoScheduleMode = data.auto_scheduling //data.actual_auto_scheduling; //Used by PagedAgGridGantt;
      }
      
      startTime.hour(oldStartDateTime.hours()).minute(oldStartDateTime.minutes());
      closeTime.hour(oldCloseDateTime.hours()).minute(oldCloseDateTime.minutes());

      if (!Object.hasOwn(data, 'tm_duration_display')) {
        if (data.lockDuration == true) {
          data.tm_duration_display = taskMove_calcDurationDisplay(data, parentComponent);
        } else {
          data.tm_duration_display = data.durationInDays;
        }
      }

      const payload = {
        trigger: trigger
        , startDateStr: projectScheduleFromStart? startTime.format('YYYY-MM-DD'): null
        , startTimeStr: projectScheduleFromStart? startTime.format('HH:mm') : null
        , closeDateStr: !projectScheduleFromStart? closeTime.format('YYYY-MM-DD'): null
        , closeTimeStr: !projectScheduleFromStart? closeTime.format('HH:mm'): null
        , durationDisplay: data.tm_duration_display //tm_duration_display is set in beforeTaskDrag event for task move mode  (helper-gantt-template.js)
        , calendar: calendar
        , projScheduleFromStart: projectScheduleFromStart
        , taskAutoScheduleMode: taskAutoScheduleMode
        , lockDuration: false
        , autoMoveForNonWorkingDay: true
        , skipOutOfProjectDateCheck: true
      }
      const result = calcDateTimeDurationv2(payload);
      startTime = result.startDateStr != null? moment.utc(`${result.startDateStr}${result.startTimeStr != null? ' '+result.startTimeStr:''}`, 'YYYY-MM-DD HH:mm') : null
      closeTime = result.closeDateStr != null? moment.utc(`${result.closeDateStr}${result.closeTimeStr != null? ' '+result.closeTimeStr:''}`, 'YYYY-MM-DD HH:mm') : null
    }
    
    const returnItem = {
      dragMode: 'move'
      , taskUuId: data.id
      , taskName: data.temp_text != null? data.temp_text : data.text
      , progressComplete: data.progress == 1
      , startDateStr: startTime.format('YYYY-MM-DD')
      , startTimeStr: startTime.format('HH:mm')
      , closeDateStr: closeTime.format('YYYY-MM-DD')
      , closeTimeStr: closeTime.format('HH:mm')
      , durationDisplay: durationDisplay
      , lockDuration: data.lockDuration != null? data.lockDuration : false
      , taskAutoScheduleMode: data.auto_scheduling != null? data.auto_scheduling : true
      , constraintType: data.constraintType
      , constraintDateStr: constraintDate != null? constraintDate.format('YYYY-MM-DD'): null
      , oldStartDateStr: oldStartDateTime != null? oldStartDateTime.format('YYYY-MM-DD') : null
      , oldStartTimeStr: oldStartDateTime != null? oldStartDateTime.format('HH:mm') : null
      , oldCloseDateStr: oldCloseDateTime != null? oldCloseDateTime.format('YYYY-MM-DD') : null
      , oldCloseTimeStr: oldCloseDateTime != null? oldCloseDateTime.format('HH:mm') : null
      , progress: (parseInt(data.progress * 100) / 100).toFixed(2)
      , startTime: startTime
      , closeTime: closeTime
      , staffs: data.staffList != null ? data.staffList : []
    }
    if (!isAgGridGantt) {
      returnItem.projId = data.projId;
      returnItem.projScheduleFromStart = data.projScheduleMode != null? data.projScheduleMode == 'ASAP' : true;
      returnItem.projStartDateStr = data.projScheduleStart != null? moment.utc(data.projScheduleStart).format('YYYY-MM-DD') : null;
      returnItem.projCloseDateStr = data.projScheduleFinish != null? moment.utc(data.projScheduleFinish).format('YYYY-MM-DD') : null;
      returnItem.projLocationId = data.projLocationId;
    }
    returnList.push(returnItem);
  }
  return returnList;
}