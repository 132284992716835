<template>
  <ol class="breadcrumb">
    <template v-for="(routeObject, index) in routeRecords">
      <template v-if="hasParam(routeObject) && isLast(index)">
        <template v-if="hasMetaLabel(routeObject)">
          <li class="breadcrumb-item" :key="index">
            <router-link :to="getRecordRoutePath(routeObject)">{{ contextName }}</router-link>
          </li>
          <li class="breadcrumb-item" :key="`${index}_1`">
            <span class="active">{{ getName(routeObject) }}</span>
          </li>
        </template>
        <template v-else>
          <li class="breadcrumb-item" :key="index">
            <span class="active">{{ contextName }}</span>
          </li>
        </template>
      </template>
      <li class="breadcrumb-item" v-else-if="getName(routeObject) !== ''" :key="index">
        <span class="active" v-if="hasParam(routeObject) && isLast(index)">{{ getName(routeObject) }}</span>

        <span class="active" v-else-if="isLast(index)">{{ getName(routeObject) }}</span>
        <router-link :to="'/'" v-else-if="'' === routeObject.path">{{ getName(routeObject) }}</router-link>
        <router-link :to="getRecordRoutePathCustom(routeObject)" v-else>{{ getName(routeObject) }}</router-link>
      </li>
    </template>
    <span v-if="viewName" class="ml-1">/ {{viewName}}</span>
  </ol>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    urlParams: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    routeRecords: function () {
      return this.list.filter((route) => route.name || route.meta.label)
    },
    contextName: function() {
      return this.$store.state.breadcrumb.contextName;
    },
    viewName: function() {
      return this.$store.state.breadcrumb.viewName;
    }
  },
  watch: {
    $route: function() {
      this.$store.dispatch("breadcrumb/clearView");
    }
  },
  methods: {
    getName (item) {
      return item.meta && typeof item.meta.label !== 'undefined' ? item.meta.label : item.name || null
    },
    isLast (index) {
      return index === this.list.length - 1
    },
    hasParam(item) {
      const _item = item;
      if(_item && _item.path) {
        return _item.path.match(this.paramRegex) != null;
      } else {
        return false;
      }
    },
    hasMetaLabel (item) {
      return item.meta && typeof item.meta.label !== 'undefined';
    },
    getRecordRoutePath(item) {
      const matched = item.path.match(this.paramRegex) || [null, null];
      const paramKey = matched[1];
      if(paramKey) {
        let index = item.path.indexOf(`:${paramKey}`);
        return `${getParentSlash(item.path.substring(0, index+1), 2)}${this.urlParams[paramKey]}`;
      }
      return item.path;
      
      //Private function
      //Get sub-string from 1st character to last (nth time of) slash based on number of times provided.
      //e.g.: Original string: "/projects/gantt/:id" 
      //       0 times --> "/projects/gantt/:id"
      //       1 times --> "/projects/gantt/"
      //       2 times --> "/projects/"
      //       3 times --> "/projects/"           
      //Note: it will return same string when the remaining string has one slash left (excluding 1st slash when it is 1st character)
      function getParentSlash(value, times) {
        if(!value || value.length < 1 || times < 1) {
          return value;
        }
        const idx = value.lastIndexOf("/", value.length-2);
        if(idx < 1) {
          return value;
        }
        return getParentSlash(value.substring(0, idx+1), --times);
      }
    },
    getRecordRoutePathCustom(item)  {
      const obj = {
        path: item.path
      }
      if(item.meta && item.meta.enableParamsAsQueries) {
        obj.query = { ...this.$route.params }
      }
      return obj;
    },
    
  },
  beforeDestroy() {
    this.paramRegex = null;
  },
  created() {
    this.paramRegex = /:([a-zA-Z0-9]+)$/;
  }
}
</script>

<style scoped>
  .breadcrumb {
    z-index: 10;
    background-color: var(--header-bg);
  }
</style>