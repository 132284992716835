import { accessPolicyService } from '@/services'
import { objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'

export const accessPolicyUtil = {
  list: accessPolicyService.list
  , remove: accessPolicyService.remove
  , clone: accessPolicyService.clone
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }
  , getColumnDefs: (self) => {
      const colDefs = [
      {
        headerName: self.$t('access_policy.field.name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: self.canEdit('ACCESS_POLICY', ['name'])
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
        }
      },
      {
        headerName: self.$t('project.field.description')
        , field: 'description'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'multilineEditor'
        , cellEditorParams: { title: self.$t('task.edit_description') }
        , hide: false
        , editable: true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      }
    ]

    const linkedEntities = [{ permissionName: 'TAG', field: 'tag' }]
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'ACCESS_POLICY', linkedEntities)
    //EDIT permission: set column to be read only.
    setEditDenyPropertiesReadOnly(colDefs, 'ACCESS_POLICY', linkedEntities)
    return colDefs
  }
  , entityUpdateApiUrl: '/api/access-policy/update'
  , entityDeleteApiUrl: '/api/access-policy/delete'
  , getValueChangedHandler: (/** self */) => ({})
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return { 
      name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'ACCESS_POLICY', action: 'VIEW' }])
  }
  , getMandatoryFields() {
    return [
      'name'
    ]
  }
  , preRowDeleteHandler: (self) => {
    return {
      isAsync: true
      , execute: async (targetColEntities) => {
        const requests = []
        if (!Array.isArray(targetColEntities) || targetColEntities.length == 0) {
          return { status: 'ERROR' }
        }
        for (const entity of targetColEntities) {
          requests.push(accessPolicyService.getUserIds(entity.uuId))
        }
        
        await Promise.allSettled(requests)
        .then(responses => {
          for (const [i, resp] of responses.entries()) {
            const entity = targetColEntities[i]
            if (resp.status == 'fulfilled') {
              const rValue = resp.value
              if (!Array.isArray(rValue) || rValue.length == 0 
                  || !Object.hasOwn(rValue[0], 'userIds')
                  || !Array.isArray(rValue[0].userIds)) {
                entity.cantDeleteDetails = { 
                  title: self.$t('access_policy.cant_delete.title')
                  , content: self.$t('access_policy.cant_delete.insufficient_permission', [entity.name])
                }
                continue
              }

              const count = rValue[0].userIds.length
              if (count > 0) {
                entity.cantDeleteDetails = { 
                  title: self.$t('access_policy.cant_delete.title')
                  , content: self.$t(`access_policy.cant_delete.already_in_use${count > 1? '_plural':''}`, [entity.name, count])
                }
                continue
              }
            } else {
              entity.cantDeleteDetails = { 
                title: self.$t('access_policy.cant_delete.title')
                , content: self.$t('access_policy.cant_delete.unexpected_error', [entity.name])
              }
              continue
            }
          }
        })

        return { status: 'OK' }
      }
    }
  }
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'name', text: self.$t('access_policy.field.name') }
      , { value: 'identifier', text: self.$t('field.identifier') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      
      return accessPolicyService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            //Normal handling
            return data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
          }
        }
        
        //Normal handling
        return data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
      });
    }
  }
}