<template>
  <HeaderSidebarContainer>
    <HeaderCompany slot="headerCompany"/>
    <HeaderDropdownAccount slot="headerDropdownAccount"/>
    <router-view></router-view>
  </HeaderSidebarContainer>
</template>

<script>
import HeaderSidebarContainer from '@/views/layout/HeaderSidebarContainer'
import HeaderDropdownAccount from '@/components/HeaderDropdownAccount'
import HeaderCompany from '@/components/HeaderCompany'
export default {
  name: 'DashView',
  components: {
    HeaderSidebarContainer,
    HeaderDropdownAccount,
    HeaderCompany
  },
}
</script>

