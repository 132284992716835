<template>
  <div class="edit-cell">
    <div v-if="staffLength > 0">
      <StaffWorkEffortModal 
        :childTaskEfforts="childTasksActualDuration" 
        :workEfforts="staff" 
        :show.sync="staffWorkEffortShow"
        :startValue="startValue"
        :highlightAllOnFocus="highlightAllOnFocus"
        @cancel="staffWorkEffortCancel" 
        @ok="staffWorkEffortOk"/>
    </div>
    <b-modal v-model="showAlert" :title="$t('task_alert.status.no_staff')"
        ok-only
        content-class="shadow"
        no-close-on-backdrop
        @ok="onAlertOk"
        >
      <span>{{ $t('task.alert.cannot_edit_work_effort') }}</span>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { onDurationKeyDown, onDurationKeyUp, maxDuratioUnit, objectClone } from '@/helpers';
import { analyzeDurationAUM, convertDurationToDisplay } from '@/helpers/task-duration-process';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;

export default Vue.extend({
  name: 'WorkEffortEditor',
  components: {
    StaffWorkEffortModal: () => import('@/components/modal/StaffWorkEffortModal')
  },
  data() {
    return {
      showAlert: false,
      value: '',
      cancelBeforeStart: false,
      staffWorkEffortShow: true,
      childTasksActualDuration: 0,
      highlightAllOnFocus: false,
      staff: [],
      oldStaff: [],
      compactMode: false,
      startValue: null,
      hasChanged: false,
      workEfforts: []
    };
  },
  computed: {
    totalWorkEffort() {
      let total = 0;
      let unit = 'D';
      for(const item of this.staff) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total, unit);
    },
    staffLength() {
      return this.compactMode? this.params.value.data.staffs.length : this.params.data.staffs.length;
    },
  },
  methods: {
    async staffWorkEffortOk(workEfforts) {
      this.hasChanged = true;
      this.workEfforts.splice(0, this.workEfforts.length, ...workEfforts);
      
      //Update this.staff array object to trigger the recalculation of the computed 'totalWorkEffort' value indirectly.
      const staff = this.staff;
      for(const workEffort of workEfforts) {
        const index = staff.findIndex(i => i.uuId === workEffort.uuId);
        if(index > -1) {
          this.$set(staff[index], 'duration', workEffort.duration);
          this.$set(staff[index], 'durationAUM', workEffort.durationAUM);
        }
      }
      this.value = this.totalWorkEffort;
      this.closePicker();
    },
    staffWorkEffortCancel() {
      this.closePicker();
    },
    getValue() {
      if (!this.hasChanged) {
        return this.params.value;
      }
      if (this.compactMode) {
        const updatedValue = objectClone(this.params.value);
        const staff = updatedValue.data.staffs;
        for(const workEffort of this.workEfforts) {
          const index = staff.findIndex(i => i.uuId === workEffort.uuId);
          if(index > -1) {
            this.$set(staff[index], 'duration', workEffort.duration);
            this.$set(staff[index], 'durationAUM', workEffort.durationAUM);
          }
        }
        updatedValue.data.oldStaffs = this.oldStaff;
        updatedValue.single = this.value;
        return updatedValue;
      } else {
        const staff = this.params.data.staffs;
        for(const workEffort of this.workEfforts) {
          const index = staff.findIndex(i => i.uuId === workEffort.uuId);
          if(index > -1) {
            this.$set(staff[index], 'duration', workEffort.duration);
            this.$set(staff[index], 'durationAUM', workEffort.durationAUM);
          }
        }
        this.$set(this.params.data, 'oldStaffs', this.oldStaff);
        return this.value;
      }
    },

    onAlertOk() {
      this.closePicker();
    },
    
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue = null;
      let highlightAllOnFocus = true;
      
      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
      } else if (params.key === KEY_F2) {
        highlightAllOnFocus = true;
      }

      this.startValue = startValue;
      this.value = this.compactMode? objectClone(params.value.single) : params.value;
      this.highlightAllOnFocus = highlightAllOnFocus;
      if (this.compactMode) {
        this.staff = this.params.value.data.staffs.map(s => { 
          return { 
            uuId: s.uuId
            , duration: s.duration
            , durationAUM: s.durationAUM
            , name: s.name
            , utilization: s.utilization 
          } 
        });
      } else {
        this.staff = this.params.data.staffs.map(s => { 
          return { 
            uuId: s.uuId
            , duration: s.duration
            , durationAUM: s.durationAUM
            , name: s.name
            , utilization: s.utilization 
          } 
        });
      }
      this.oldStaff = objectClone(this.staff);
      
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 1000000;
    },

    onKeyDown(event) {
      onDurationKeyDown(event, this.finishedEditingPressed(event));
    },

    onKeyUp(event) {
      this.value = onDurationKeyUp(event, this.value);
    },
    
    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
    },
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.showAlert = this.compactMode? this.params.value.data.staffs.length == 0 : this.params.data.staffs.length === 0;
    this.setInitialState(this.params);
  },
  mounted() {
  }
})
</script>