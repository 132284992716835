<template>
  <div>
    <div class="company">
      <img
        v-if="avatarUrl !== null && canView('STORAGE_FILE') && canView('COMPANY', ['STORAGE_FILE'])"
        :src="avatarUrl"
        class="img-avatar" />
      <span class="d-md-inline">
      {{ name }}
      </span>
  
      <div class="edit" :class="avatarUrl !== null ? 'has-image' : ''">
        <div id="changecompanybtn" class="text" @click="selector">
          <font-awesome-icon :icon="['far', 'pen']" />    
          <b-popover
            target="changecompanybtn"
            placement="top"
            triggers="hover"
            :content="$t('button.change_company')">
          </b-popover>
        </div>
      </div>
    </div>
    <CompanySelectorModal :show.sync="selectorShow" :preselected="settings.company ? settings.company.uuId : null" :title="selectorTitle" @ok="selectorSuccess"/>
  </div>
</template>

<script>
import { companyProfileService, companyService } from '@/services';
import { buildCompanyFilter } from '@/helpers/company';

export default {
  name: 'HeaderCompany',
  components: {
    CompanySelectorModal: () => import('@/components/modal/CompanySelectorModal'),
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      selectorShow: false,
      settings: {
        company: {
          uuId: null
        }
      }
    }
  },
  created() {
    if (this.$store.state.company.uuId == null ||
        !this.settings.uuId) { // we must get the uuId to update the company profile
      const userId = this.$store.state.authentication.user.uuId;
      companyProfileService.list(userId).then((response) => {  
        const profileData = response.data[response.data.jobCase];
        if (profileData.length === 0) {
          this.createCompanyProfile();
        }
        else {
          this.settings = profileData[0];
          if (typeof this.settings.company !== 'undefined') {
            this.queryCompany();
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
    else {
      this.settings.company = { uuId: this.$store.state.company.uuId };
    }
  },
  methods: {
    async queryCompany() {
      const self = this;
      const result = await companyService.query([{uuId: this.settings.company.uuId}], ['LOCATION'])
      .then((response) => {
        const data = response.data[response.data.jobCase][0];
        const payload = { 
          uuId: this.settings.company.uuId,
          name: data.name,
          type: data.type,
          avatarRef: data.avatarRef ? data.avatarRef : null,
          bannerRef: data.bannerRef ? data.bannerRef : null,
          locationList: data.locationList ? data.locationList : null,
          identifier: data.identifier,
          color: data.color
        }
        this.updateStoreCompany(payload);
        if (data.type !== 'Primary') {
          companyService.tree(false, data.uuId).then((response) => {
            const ids = buildCompanyFilter(data.uuId, response.data[response.data.jobCase]);
            self.$set(self.$store.state.company, 'filterIds', ids);
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
        else {
          this.$set(this.$store.state.company, 'filterIds', null);
        }
        return true;
      })
      .catch((e) => {
        if (e.response != null && e.response.status == 403) {
          //Do nothing when user has insufficient permission
          //set true to skip trying to get master company info
          return true;
        } else {
          console.error(e); // eslint-disable-line no-console
        }
        return false;
      });
      
      if (!result) {
        // may have been deleted, get the master
        const master = await self.getMasterCompany();
        this.updateStoreCompany(master);

        self.settings.company = { uuId: master.uuId };
        self.updateProfile();
      }
    },
    async getMasterCompany() {
      return await companyService.list({ limit: -1, start: 0 }).then((response) => {
        const data = response.data;
        const company = data.filter(d => d.type === 'Primary');
        if (company.length > 0) {
          return company[0];
        }
        return null;
      })
      .catch((e) => {
        if (e.response != null && e.response.status == 403) {
          //Do nothing when user has insufficient permission
        } else {
          console.error(e); // eslint-disable-line no-console
        }
        return null;
      });
    },
    updateProfile() {
      // update the profile with the selected company uuId
      const self = this;
      const userId = this.$store.state.authentication.user.uuId;
      companyProfileService.update([this.settings], userId)
      .then((response) => {  
        self.profileData = response.data;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    selectorSuccess({details}) {
      const self = this;
      this.settings.company = { uuId: details[0].uuId };
      const payload = { 
        uuId: this.settings.company.uuId,
        name: details[0].name,
        avatarRef: details[0].avatarRef ? details[0].avatarRef : null,
        bannerRef: details[0].bannerRef ? details[0].bannerRef : null,
        locationList: details[0].locationList ? details[0].locationList : null,
        type: details[0].type,
        identifier: details[0].identifier
      }

      this.updateStoreCompany(payload);

      this.updateProfile();
      if (details[0].type !== 'Primary') {
        companyService.tree(false, details[0].uuId).then((response) => {
          const ids = buildCompanyFilter(details[0].uuId, response.data[response.data.jobCase]);
          self.$set(self.$store.state.company, 'filterIds', ids);
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
      }
      else {
        this.$set(this.$store.state.company, 'filterIds', null);
      }
    },
    async createCompanyProfile() {
      const master = await this.getMasterCompany();

      const payload = { 
        uuId: master.uuId,
        name: master.name,
        type: master.type,
        avatarRef: master.avatarRef ? master.avatarRef : null,
        bannerRef: master.bannerRef ? master.bannerRef : null,
        locationList: master.locationList ? master.locationList : null,
        color: master.color ? master.color : null
      }
      this.$store.dispatch("company/update", payload, { root: true });
      
      this.settings.company = { uuId: master.uuId };
      const userId = this.$store.state.authentication.user.uuId;
      companyProfileService.create([this.settings], userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    selector() {
      this.selectorShow = true;
    },
    updateStoreCompany(company) {
      if (company == null) {
        return;
      }
      const payload = { 
        uuId: company.uuId,
        name: company.name,
        type: company.type,
        avatarRef: company.avatarRef ? company.avatarRef : null,
        bannerRef: company.bannerRef ? company.bannerRef : null,
        locationList: company.locationList ? company.locationList : null,
        identifier: company.identifier,
        color: company.color
      }
      this.$store.dispatch("company/update", payload, { root: true });
    }
  },
  computed: {
    name() {
      return this.$store.state.company.name != null? this.$store.state.company.name : null;
    },
    avatarUrl() {
      if (!this.canView('STORAGE_FILE')) {
        return null;
      }

      const avatarRef = this.$store.state.company.avatarRef != null ? this.$store.state.company.avatarRef : null;
      return avatarRef != null? (avatarRef !== '00000000-0000-0000-0000-000000000000' ? `/api/file/${avatarRef}`: null) : null;
    },
    bannerUrl() {
      return `${this.baseUrl}api/file/${this.bannerRef}`;
    },
    selectorTitle() {
      return this.$t('company.title');
    }
  }
}
</script>

<style>
.img-avatar {
  height: 35px;
  width: 35px;
  margin-left: 0 !important;
  object-fit: cover;
}

.company {
  position: relative;
}
.company .edit {
  position: absolute !important;
  right: -25px;
}

.company .edit.has-image {
  top: 5px;
}

.company .text {
  text-shadow: 1px 1px var(--white);
  margin-top: 1px;
}

.company:hover .edit,
.company:active .edit,
.edit:hover,
.edit:active {
  opacity: 1;
}

.company .edit {
  transition: .5s ease;
  position: relative;
  display: inline-block;
  opacity: 0;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  color: var(--banner-button);
  background-color: var(--banner-button-bg);
  width: 22px;
}

@media only screen and (max-width: 400px) {
  .company span {
    display: none;
  }
}
</style>
