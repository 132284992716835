var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container" },
    [
      _vm.alertMsg != null
        ? _c("AlertFeedback", {
            attrs: {
              msg: _vm.alertMsg,
              details: _vm.alertMsgDetails.list,
              detailTitle: _vm.alertMsgDetails.title,
              alertState: _vm.alertState,
            },
            on: { resetAlert: _vm.resetAlert },
          })
        : _vm._e(),
      _vm.allowManage
        ? _c(
            "PriorityNavigation",
            {
              ref: "project-grid-toolbar",
              staticClass: "grid-toolbar border",
              attrs: {
                dropDownStayOpened: _vm.badgeFilterModalOpened != "close",
                closeDropdown: _vm.closePriorityNavDropdown,
              },
              on: {
                "update:closeDropdown": function ($event) {
                  _vm.closePriorityNavDropdown = $event
                },
                "update:close-dropdown": function ($event) {
                  _vm.closePriorityNavDropdown = $event
                },
              },
              nativeOn: _vm._d({}, [
                _vm.priorityNavMouseOverEvent,
                function ($event) {
                  return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                    null,
                    arguments
                  )
                },
                _vm.priorityNavTouchEndEvent,
                function ($event) {
                  return _vm.onPriorityNavMouseOverOrTouchEnd.apply(
                    null,
                    arguments
                  )
                },
              ]),
            },
            [
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_ADD_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied || _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.taskEditOpen(true)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_ADD_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.add_task")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canView()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_EDIT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableEdit ||
                                  _vm.actionProcessing,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.taskEditOpen(false)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.canEdit()
                                  ? _vm.$t("button.edit_task")
                                  : _vm.$t("button.view_task")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canDelete()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_DELETE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableDelete ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.rowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_DELETE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.delete_task")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canAdd() || _vm.canView() || _vm.canDelete) &&
              _vm.canEdit(_vm.permissionName, ["TASK"])
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit(_vm.permissionName, ["TASK"])
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_OUTDENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableOutdent ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.taskOutdent },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "outdent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_OUTDENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.outdent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canEdit(_vm.permissionName, ["TASK"])
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_INDENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableIndent ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.taskIndent },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "indent"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_INDENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.indent")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd()
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_COPY_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableTaskCopy ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.taskCopy },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "copy"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_COPY_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.copy")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd()
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_PASTE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableTaskPaste ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.taskPaste },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "paste"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_PASTE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.paste")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.canAdd("TASK") && _vm.canList("TEMPLATE__PROJECT")) ||
              (_vm.canAdd("TEMPLATE__PROJECT") && _vm.canAdd("TEMPLATE__TASK"))
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canAdd("TASK") && _vm.canList("TEMPLATE__PROJECT")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_APPLY_TEMPLATE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied || _vm.actionProcessing,
                              },
                              on: { click: _vm.templateApply },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "layer-plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_APPLY_TEMPLATE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.apply_template")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canAdd("TEMPLATE__PROJECT") && _vm.canAdd("TEMPLATE__TASK")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_SAVE_TEMPLATE_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableDelete ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.templateSave },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "layer-group"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_SAVE_TEMPLATE_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.save_template")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              _vm.canAdd("TASK")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_IMPORT_DOCUMENT_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.disableWhenMoreThanOne ||
                                  _vm.actionProcessing,
                              },
                              on: { click: _vm.fileImport },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "inbox-in"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.import_document")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.isAccessDenied || _vm.actionProcessing,
                          },
                          on: { click: _vm.fileExport },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "inbox-out"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("task.button.export_document")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.canEdit("PROJECT") || _vm.canEdit("TASK")
                ? _c("li", { staticClass: "divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action-v-divider",
                        attrs: { readonly: "" },
                      },
                      [_vm._v("|")]
                    ),
                  ])
                : _vm._e(),
              _vm.canEdit("PROJECT")
                ? _c(
                    "li",
                    [
                      _vm.disableManualScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: "BTN_MANUAL_SCHEDULE_PROJECT_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.isAccessDenied ||
                                        _vm.actionProcessing,
                                    },
                                    on: {
                                      click: _vm.autoScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "thumbtack"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target:
                                    "BTN_MANUAL_SCHEDULE_PROJECT_" + _vm.id,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.manual_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.disableAutoScheduleProject
                        ? [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: "BTN_AUTO_SCHEDULE_PROJECT_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.isAccessDenied ||
                                        _vm.actionProcessing,
                                    },
                                    on: {
                                      click: _vm.manualScheduleProjectHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "calendar-check"],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "BTN_AUTO_SCHEDULE_PROJECT_" + _vm.id,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.button.auto_scheduled")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.canEdit("TASK")
                ? _c(
                    "li",
                    {
                      staticClass: "scheduler",
                      on: _vm._d({ mouseleave: _vm.onSchedulerLeave }, [
                        _vm.schedulerMouseEnterEvent,
                        _vm.onSchedulerOver,
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "scheduler",
                          staticClass: "action-bar-dropdown",
                          attrs: {
                            disabled: _vm.isAccessDenied,
                            id: "BTN_SCHEDULE_" + _vm.id,
                            "toggle-class": "text-decoration-none",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4096594029
                          ),
                        },
                        [
                          !_vm.isAccessDenied
                            ? [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.disableManualScheduleTask ||
                                        _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: {
                                      click: _vm.manualScheduleTaskHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "item-icon",
                                      attrs: { icon: ["far", "thumbtack"] },
                                    }),
                                    _c("span", { staticClass: "item-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "task.button.manual_schedule_task"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.disableAutoScheduleTask ||
                                        _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: { click: _vm.autoScheduleTaskHandler },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "item-icon",
                                      attrs: {
                                        icon: ["far", "calendar-check"],
                                      },
                                    }),
                                    _c("span", { staticClass: "item-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "task.button.auto_schedule_task"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("b-dropdown-divider"),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      disabled: _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: {
                                      click: _vm.scheduleProjectNowHandler,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "item-icon",
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                    _c("span", { staticClass: "item-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "task.button.schedule_project_now"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canEdit("TASK")
                ? _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: "BTN_AUTO_ASSIGN_STAFF_" + _vm.id } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                disabled:
                                  _vm.isAccessDenied ||
                                  _vm.autoAssignTasks().length === 0 ||
                                  _vm.inProgressShow,
                              },
                              on: { click: _vm.autoAssignStaff },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "user-helmet-safety"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "BTN_AUTO_ASSIGN_STAFF_" + _vm.id,
                            triggers: "hover",
                            placement: "top",
                            boundary: "viewport",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.auto_assign_staff")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_COLLAPSE_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.isAccessDenied ||
                              _vm.inProgressShow ||
                              _vm.expandLevel === 0,
                          },
                          on: { click: _vm.collapse },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "magnifying-glass-minus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_COLLAPSE_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("task.button.minus")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_EXPAND_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.isAccessDenied ||
                              _vm.inProgressShow ||
                              _vm.expandLevel === _vm.maxLevel,
                          },
                          on: { click: _vm.expand },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "magnifying-glass-plus"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_EXPAND_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("task.button.plus")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                    _vm.filterMouseEnterEvent,
                    _vm.onFilterOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "filter",
                      staticClass: "action-bar-dropdown text-filter",
                      attrs: {
                        disabled: _vm.isAccessDenied,
                        id: "BTN_FILTER_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      on: { hide: _vm.onFilterTextDropdownHide },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  class: _vm.filterText !== "" ? "active" : "",
                                  attrs: {
                                    icon: ["far", "file-magnifying-glass"],
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3499512239
                      ),
                    },
                    [
                      !_vm.isAccessDenied
                        ? [
                            _c(
                              "b-dropdown-form",
                              {
                                staticClass: "filter-padding",
                                on: {
                                  submit: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "search-input" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: _vm.$t("task.filter"),
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.filterTextFocus = true
                                        },
                                        blur: function ($event) {
                                          _vm.filterTextFocus = false
                                        },
                                        keypress: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.onFilterSubmit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filterText,
                                        callback: function ($$v) {
                                          _vm.filterText = $$v
                                        },
                                        expression: "filterText",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-btn",
                                          {
                                            staticClass: "search-append",
                                            attrs: {
                                              size: "sm",
                                              variant: "danger",
                                            },
                                            on: { click: _vm.onFilterClear },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "search-clear",
                                              attrs: { icon: ["far", "xmark"] },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-btn",
                                          {
                                            staticClass:
                                              "search-append search-append-bg",
                                            attrs: { size: "sm" },
                                            on: { click: _vm.onFilterSubmit },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                variant: "secondary",
                                                icon: [
                                                  "far",
                                                  "magnifying-glass",
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({}, [
                    _vm.badgeFilterMouseEnterEvent,
                    _vm.onBadgeFilterEnter,
                    _vm.badgeFilterMouseOverEvent,
                    _vm.onBadgeFilterOver,
                    _vm.badgeFilterMouseLeaveEvent,
                    _vm.onBadgeFilterLeave,
                    _vm.badgeFilterTouchEndEvent,
                    _vm.onBadgeFilterOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "badgeFilter",
                      staticClass: "action-bar-dropdown text-filter",
                      attrs: {
                        disabled: _vm.isAccessDenied,
                        id: "BTN_BADGE_FILTER_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      on: { hide: _vm.onBadgeFilterDropdownHide },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  class:
                                    _vm.badgeFilters.length > 0 ? "active" : "",
                                  attrs: { icon: ["far", "filter"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3929189510
                      ),
                    },
                    [
                      !_vm.isAccessDenied
                        ? [
                            _c(
                              "b-dropdown-form",
                              {
                                staticClass: "filter-padding",
                                on: {
                                  submit: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c("TaskViewBadgeFilter", {
                                  attrs: {
                                    filters: _vm.badgeFilters,
                                    fields: _vm.badgeFilterFields,
                                    fieldValues: _vm.badgeFilterFieldValues,
                                  },
                                  on: {
                                    modified: _vm.onBadgeFilterModified,
                                    filterModalOpened:
                                      _vm.onBadgeFilterModalOpened,
                                    filterModalClosed:
                                      _vm.onBadgeFilterModalClosed,
                                    fetchFieldOptions:
                                      _vm.onBadgeFilterFetchOptions,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("li", { staticClass: "divider" }, [
                _c(
                  "span",
                  { staticClass: "action-v-divider", attrs: { readonly: "" } },
                  [_vm._v("|")]
                ),
              ]),
              _c(
                "li",
                {
                  staticClass: "view",
                  on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                    _vm.viewMouseEnterEvent,
                    _vm.onViewOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "view",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        disabled: _vm.isAccessDenied,
                        id: "BTN_VIEW_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "desktop"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4186387599
                      ),
                    },
                    [
                      !_vm.isAccessDenied
                        ? [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.showAllColumns },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.button.all_columns"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.showNoColumns },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.button.no_columns"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("b-dropdown-divider"),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.savePreset },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [_vm._v(_vm._s(_vm.$t("task.button.save")))]
                                ),
                              ]
                            ),
                            _c("b-dropdown-divider"),
                            _vm._l(_vm.views, function (item, index) {
                              return [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    key: index,
                                    staticClass: "action-item",
                                    attrs: {
                                      disabled: _vm.actionProcessing,
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadViewSettings(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "action-item-label-with-icon",
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c(
                                      "span",
                                      [
                                        !_vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon",
                                                  attrs: {
                                                    id: "COPY_COLUMN_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.copyColumnSettings(
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "copy"],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "COPY_COLUMN_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.copy"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-third",
                                                  attrs: {
                                                    id: "BTN_INFO_" + index,
                                                  },
                                                  on: _vm._d(
                                                    {
                                                      mouseleave: function (
                                                        $event
                                                      ) {
                                                        return _vm.onInfoLeave(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    [
                                                      _vm.infoMouseEnterEvent,
                                                      function ($event) {
                                                        return _vm.onInfoOver(
                                                          index
                                                        )
                                                      },
                                                    ]
                                                  ),
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "circle-info",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("b-popover", {
                                                ref: "BTN_INFO_" + index,
                                                refInFor: true,
                                                attrs: {
                                                  target: "BTN_INFO_" + index,
                                                  show: _vm.showInfo[index],
                                                  placement: "top",
                                                  boundary: "viewport",
                                                  triggers: "manual",
                                                  content: _vm.$t("owner", [
                                                    _vm.views[index].owner,
                                                  ]),
                                                },
                                                on: {
                                                  "update:show": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.showInfo,
                                                      index,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-third",
                                                  attrs: {
                                                    id: "SHARE_COLUMN_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.shareColumnSettings(
                                                        index,
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        item.defaultView
                                                          ? "fas"
                                                          : "far",
                                                        "share-nodes",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "SHARE_COLUMN_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.share"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon position-second",
                                                  attrs: {
                                                    id:
                                                      "UPDATE_COLUMN_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.updateColumnSettings(
                                                        index,
                                                        item.name,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "floppy-disk",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "UPDATE_COLUMN_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.update"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-item-icon",
                                                  attrs: {
                                                    id:
                                                      "REMOVE_COLUMN_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeColumnSettings(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "trash-can",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "REMOVE_COLUMN_" + index,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    boundary: "viewport",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "task.button.delete"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                    _vm.colorMouseEnterEvent,
                    _vm.onColoringOver,
                  ]),
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "coloring",
                      staticClass: "action-bar-dropdown",
                      attrs: {
                        disabled: _vm.isAccessDenied,
                        id: "BTN_COLORING_" + _vm.id,
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "palette"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1244906976
                      ),
                    },
                    [
                      !_vm.isAccessDenied
                        ? [
                            _c(
                              "b-dropdown-group",
                              { attrs: { header: _vm.$t("colorby") } },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "none",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [_vm._v(_vm._s(_vm.$t("none")))]
                                    ),
                                    _vm.coloring.none
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "task",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.task"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.task
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "stage",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.stage"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.stage
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.coloring.company =
                                          !_vm.coloring.company
                                        _vm.onColorChange(
                                          "skill",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.skill"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.skill
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "staff",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.staff"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.staff
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "resource",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("task.coloring.resource")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.resource
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "rebate",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.rebate"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.rebate
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "file",
                                          "task_view_coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("task.coloring.file"))
                                        ),
                                      ]
                                    ),
                                    _vm.coloring.file
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_ENABLE_FLATLIST_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.isAccessDenied || _vm.actionProcessing,
                          },
                          on: { click: _vm.toggleFlatListHandler },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: [
                                "far",
                                _vm.flatList ? "list-tree" : "list",
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_ENABLE_FLATLIST_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.flatList
                                ? "task.button.tree"
                                : "task.button.flatlist"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "span",
                    { attrs: { id: "BTN_ENABLE_COMPACT_VIEW_" + _vm.id } },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.isAccessDenied ||
                              _vm.actionProcessing ||
                              _vm.compactLimit,
                            pressed: _vm.isCompactView,
                          },
                          on: { click: _vm.toggleCompactViewHandler },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "table-cells"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_ENABLE_COMPACT_VIEW_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                        boundary: "viewport",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.compactLimit
                                ? "task.error.compact_limit"
                                : "task.button.compact_view"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      this.id === null
        ? _c("div", [_vm._v(" No valid project id specified in the URL. ")])
        : _c(
            "div",
            { staticClass: "flex-grid-container" },
            [
              _c("ag-grid-vue", {
                ref: "project-tasks-grid",
                staticClass: "ag-theme-balham task-grid-height",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "project-tasks-grid",
                  gridOptions: _vm.gridOptions,
                  animateRows: "",
                  autoGroupColumnDef: _vm.autoGroupColumnDef,
                  columnDefs: _vm.columnDefs,
                  context: _vm.context,
                  defaultColDef: _vm.defaultColDef,
                  getMainMenuItems: _vm.getMainMenuItems,
                  noRowsOverlayComponent: "noRowsOverlay",
                  noRowsOverlayComponentParams:
                    _vm.noRowsOverlayComponentParams,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  getDataPath: function (data) {
                    return _vm.flatList ? [data.uuId] : data.path
                  },
                  getRowId: function (params) {
                    return params.data.uuId
                  },
                  rowData: _vm.rowData,
                  rowMultiSelectWithClick: false,
                  rowSelection: "multiple",
                  sideBar: false,
                  suppressContextMenu: "",
                  suppressDragLeaveHidesColumns: "",
                  suppressCellFocus: false,
                  suppressClipboardApi: "",
                  singleClickEdit: false,
                  enableRangeSelection: true,
                  enableFillHandle: true,
                  fillOperation: _vm.fillOperation,
                  fillHandleDirection: "xy",
                  processCellForClipboard: _vm.processCellForClipboard,
                  processCellFromClipboard: _vm.processCellFromClipboard,
                  navigateToNextCell: _vm.navigateToNextCell,
                  tabToNextCell: _vm.tabToNextCell,
                  suppressMultiSort: "",
                  suppressScrollOnNewData: "",
                  treeData: "",
                  suppressRowDrag: !_vm.canEdit(),
                  enableCellEditingOnBackspace: "",
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  "row-drag-enter": _vm.onRowDragEnter,
                  "row-drag-end": _vm.onRowDragEnd,
                  "row-drag-move": _vm.onRowDragMove,
                  "row-drag-leave": _vm.onRowDragLeave,
                  "cell-key-down": _vm.onCellKeyDown,
                  "paste-start": _vm.onPasteStart,
                  "paste-end": _vm.onPasteEnd,
                  "cell-focused": _vm.cellFocused,
                },
              }),
            ],
            1
          ),
      _c("TaskModal", {
        attrs: {
          show: _vm.taskEditShow,
          mode: _vm.mode,
          id: _vm.taskEdit.uuId,
          projectId: _vm.id,
          parentId: _vm.taskEdit.parentId,
          taskType: _vm.isCompactView ? "Project" : null,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.taskEditSuccess,
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
        },
      }),
      _c("TaskTemplateSaveModal", {
        attrs: {
          show: _vm.templateSaveShow,
          taskIds: _vm.templateTaskIds,
          projectId: _vm.id,
        },
        on: {
          "update:show": function ($event) {
            _vm.templateSaveShow = $event
          },
          success: _vm.templateSaveSuccess,
        },
      }),
      _c("TaskTemplateSelectorModal", {
        attrs: { show: _vm.taskTemplateSelectorShow, multiple: true },
        on: {
          "update:show": function ($event) {
            _vm.taskTemplateSelectorShow = $event
          },
          ok: _vm.taskTemplateSelectorOk,
        },
      }),
      _c("GanttImportDialog", {
        attrs: {
          projectId: _vm.id,
          show: _vm.docImportShow,
          existingData: _vm.rowData,
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptSaveShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.save"),
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptSaveShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c("SaveViewModal", {
        attrs: {
          show: _vm.promptShareShow,
          name: _vm.saveName,
          title: _vm.$t("task.confirmation.share"),
          sharing: true,
          profile: _vm.saveProfile,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptShareShow = $event
          },
          ok: _vm.confirmSaveOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.auto_assign.summary"),
            "ok-only": "",
            scrollable: "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.autoAssignSummaryOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.autoAssignSummaryShow,
            callback: function ($$v) {
              _vm.autoAssignSummaryShow = $$v
            },
            expression: "autoAssignSummaryShow",
          },
        },
        [
          _vm.autoAssignSummary.length > 0
            ? _c(
                "ul",
                { staticClass: "task-summary-list" },
                _vm._l(_vm.autoAssignSummary, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "d-block task-summary-list-item",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "task-summary-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getPathNames(
                                  _vm.taskNames[item.taskUUID].path
                                )
                              )
                            ),
                          ]),
                          item.staffAssignmentList.length > 0
                            ? _vm._l(
                                item.staffAssignmentList,
                                function (staff, sIndex) {
                                  return _c(
                                    "div",
                                    { key: sIndex, staticClass: "d-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "task.auto_assign.assigned",
                                              [staff.name]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          item.staffAssignmentList.length === 0
                            ? _c("div", { staticClass: "d-block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.auto_assign.none_assigned")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.autoAssignSummary.length === 0
            ? _c("div", { staticClass: "d-block" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.auto_assign.none_assigned")) + " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("AutoAssignStaffModal", {
        attrs: {
          projectId: _vm.id,
          companies: _vm.project ? _vm.project.companyList : null,
          tasks: _vm.autoAssignTasks(),
          show: _vm.autoAssignStaffShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.autoAssignStaffShow = $event
          },
          success: _vm.autoAssignStaffSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteViewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteViewShow,
            callback: function ($$v) {
              _vm.confirmDeleteViewShow = $$v
            },
            expression: "confirmDeleteViewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("task.confirmation.delete_view")) + " "),
          ]),
        ]
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.durationCalculationShow,
          taskName: _vm.durationCalculation.taskName,
          defaultActionForNonWorkPrompt:
            _vm.durationCalculation.defaultActionForNonWorkPrompt,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          enableManualScheduleSuggestion:
            _vm.durationCalculation.enableManualScheduleSuggestion,
          showApplyAllCheckbox: "",
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projectScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          projectStartDateStr: _vm.durationCalculation.projectStartDateStr,
          projectCloseDateStr: _vm.durationCalculation.projectCloseDateStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          resizeMode: _vm.durationCalculation.resizeMode == true,
          clearPreviousChoice: _vm.resetDurationCalculationState,
        },
        on: {
          "update:show": function ($event) {
            _vm.durationCalculationShow = $event
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          skip: function ($event) {
            return _vm.durationCalculationOk({ skip: true })
          },
          cancel: _vm.durationCalculationCancel,
          calendarChange: _vm.durationCalculationCalendarChange,
        },
      }),
      _vm.taskGroupShow
        ? _c("TaskGroupSelectorModal", {
            attrs: {
              companyId: _vm.companyId,
              userId: _vm.userId,
              show: _vm.taskGroupShow,
              data: _vm.taskGroup.data,
              propertyOptions: _vm.taskGroup.propertyOptions,
              feedback: _vm.taskGroup.feedback,
            },
            on: {
              "update:show": function ($event) {
                _vm.taskGroupShow = $event
              },
              changed: _vm.taskGroupChanged,
              deleted: _vm.taskGroupDeleted,
              reload: _vm.taskGroupReload,
            },
          })
        : _vm._e(),
      _vm.taskGroupLocalShow
        ? _c("TaskGroupLocalModal", {
            attrs: {
              userId: _vm.userId,
              companyId: _vm.companyId,
              show: _vm.taskGroupLocalShow,
              uuId: _vm.taskGroupLocal.uuId,
              headerName: _vm.taskGroupLocal.headerName,
              property: _vm.taskGroupLocal.property,
              propertyOptions: _vm.taskGroupLocal.propertyOptions,
              children: _vm.taskGroupLocal.children,
              dynamicTasks: _vm.taskGroupLocal.dynamicTasks,
            },
            on: {
              "update:show": function ($event) {
                _vm.taskGroupLocalShow = $event
              },
              ok: _vm.taskGroupLocalOk,
            },
          })
        : _vm._e(),
      _vm.newTaskGroupShow
        ? _c("TaskGroupModal", {
            attrs: {
              userId: _vm.userId,
              companyId: _vm.companyId,
              show: _vm.newTaskGroupShow,
              uuId: _vm.newTaskGroup.uuId,
              headerName: _vm.newTaskGroup.headerName,
              description: _vm.newTaskGroup.description,
              property: _vm.newTaskGroup.property,
              propertyOptions: _vm.newTaskGroup.propertyOptions,
              children: _vm.newTaskGroup.children,
              dynamicTasks: _vm.newTaskGroup.dynamicTasks,
              sharedVisibility: _vm.newTaskGroup.sharedVisibility,
              sharingMembers: _vm.newTaskGroup.sharingMembers,
              editors: _vm.taskGroup.editors,
            },
            on: {
              "update:show": function ($event) {
                _vm.newTaskGroupShow = $event
              },
              ok: _vm.newTaskGroupOk,
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "content-class": "task-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.tcConfirmDeleteShow = false
            },
            ok: _vm.tcConfirmDeleteOk,
            cancel: _vm.tcConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.tcShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.taskCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskCol, "applyAll", $$v)
                            },
                            expression: "taskCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tcConfirmDeleteShow,
            callback: function ($$v) {
              _vm.tcConfirmDeleteShow = $$v
            },
            expression: "tcConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.tcConfirmDeleteStatement))])]
      ),
      _c("TaskGroupTaskEdit", {
        attrs: {
          show: _vm.taskColEditShow,
          name: _vm.taskColEdit.name,
          color: _vm.taskColEdit.color,
          skills: _vm.taskColEdit.skills,
          staff: _vm.taskColEdit.staff,
          resources: _vm.taskColEdit.resources,
          rebates: _vm.taskColEdit.rebates,
          tags: _vm.taskColEdit.tags,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskColEditShow = $event
          },
          ok: _vm.taskColEditOk,
        },
      }),
      _c("ApplyTemplateConfigModal", {
        attrs: { show: _vm.applyTemplateConfigShow },
        on: {
          "update:show": function ($event) {
            _vm.applyTemplateConfigShow = $event
          },
          ok: _vm.applyTemplateConfigOk,
          cancel: _vm.applyTemplateConfigCancel,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("title_confirm_apply_change_to_task"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            ok: _vm.confirmApplyChangeToTaskOk,
            cancel: _vm.confirmApplyChangeToTaskCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmApplyChangeToTaskShow,
            callback: function ($$v) {
              _vm.confirmApplyChangeToTaskShow = $$v
            },
            expression: "confirmApplyChangeToTaskShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.confirmApplyToTaskStatement) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }