import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { cloneDeep } from 'lodash';
import { 
  activityLinkStageService,
  bookingLinkStageService,
  taskLinkStageService,
  calendarService,
  compositeService,
  activityService, 
  activityLinkStaffService, 
  bookingService, 
  taskService,
  taskLinkStaffService
} from '@/services';
import { 
  getDateRef,
  convertDate
} from '@/helpers';
import { 
  convertDisplayToDuration
} from '@/helpers/task-duration-process';

function getAvailabilityDay(calendars, date, startHour = 0, endHour = 0, staffAlloc = 'estimatedduration') {
  let a = 0;
  let found = false;
  
  if (calendars == null) {
    return a;
  }

  for (const calendar of calendars) {
    if (calendar != null) {
      for (const c of calendar) {
        if (date.unix() * 1000 >= c.startDate && date.unix() * 1000 <= c.endDate) {
          // exception overrides the location or base calendar
          if (c.type === 'Leave') {
            a = 0;
            found = true;
            break;
          }
          else if (c.type === 'Working') {
            if (c.v_EndHour && c.v_StartHour) {
              a += (c.v_EndHour[0] - (startHour > c.v_StartHour[0] ? startHour : c.v_StartHour[0])) / 60 /60000;
            }
            else {
              a += 8; // default 8 hour day if no hours are specified
            }
            found = true;
            break;
          }
        }
        else if (c.v_CalendarType && c.v_CalendarType[0] === date.day() + 1) {
          found = true;
          // there may be multiple entries for this day
          if (c.v_EndHour && c.v_StartHour) {
            if (startHour > 0) {
              a += (c.v_EndHour[0] - (startHour > c.v_StartHour[0] ? startHour : c.v_StartHour[0])) / 60 /60000;
            }
            else if (endHour > 0) {
              a += ((endHour < c.v_EndHour[0] ? endHour : c.v_EndHour[0]) - c.v_StartHour[0]) / 60 /60000;
            }
            else {
              a += (c.v_EndHour[0] - c.v_StartHour[0]) / 60 /60000;
            }
          }
        }
      }
    }
    
    if (found) {
      break; // we have found the calendar for this day
    }
  }
  return a;
}

export function getCost(tasks, perhourCost) {
  let cost = 0;
  let netCost = 0;
  let fixedCost = 0;
  let netFixedCost = 0;
  let actualCost = 0;
  let netActualCost = 0;
  let costPerHour = perhourCost;
  let fixedCostPerHour = 0;
  let actualCostPerHour = 0;
  
  for (const t of tasks) {
    if (t.fixedCost && t.fixedCost !== -1) {
      const duration = t.te ? (t.te / 3600000) : (t.duration / 3600000);
      fixedCostPerHour = t.fixedCost / duration;
    }
    else {
      fixedCostPerHour = 0;
    }
 
    if (t.actualCost && t.actualCost !== -1) {
      const duration = t.te ? (t.te / 3600000) : (t.duration / 3600000);
      actualCostPerHour = t.actualCost / duration;
    }
    else {
      actualCostPerHour = 0;
    }
    
    if (t.w) {
      t.cost = (t.w * costPerHour);
      cost += (t.w * costPerHour);
      t.fixedCost = (t.w * fixedCostPerHour);
      fixedCost += (t.w * fixedCostPerHour);
      t.actualCost = (t.w * actualCostPerHour);
      actualCost += (t.w * actualCostPerHour);
      if (t.rebate) {
        t.netCost = (t.w * costPerHour) * (1 - t.rebate);
        netCost += (t.w * costPerHour) * (1 - t.rebate);
        t.netFixedCost = (t.w * fixedCostPerHour) * (1 - t.rebate);
        netFixedCost += (t.w * fixedCostPerHour) * (1 - t.rebate);
        t.netActualCost = (t.w * actualCostPerHour) * (1 - t.rebate);
        netActualCost += (t.w * actualCostPerHour) * (1 - t.rebate);
      }
      else {
        t.netCost = t.w * costPerHour;
        netCost += t.w * costPerHour;
        t.netFixedCost = t.w * fixedCostPerHour;
        netFixedCost += t.w * fixedCostPerHour;
        t.netActualCost = t.w * actualCostPerHour;
        netActualCost += t.w * actualCostPerHour;
      }
    }
  }
  return { tasks: tasks, cost: cost, netCost: netCost, fixedCost: fixedCost, netFixedCost: netFixedCost, actualCost: actualCost, netActualCost: netActualCost };
}

function getWorkingHoursDay(taskList, date, a, end, calendars, staffAlloc, span, perhourCost) {
  let w = 0;
  let wActual = 0;
  let wFixed = 0;
  let t = [];
  if (taskList != null) {
    for (const task of taskList) {
      if (((task.begin <= date.unix() * 1000 && // is the date contained wholly within the task
          task.until >= date.unix() * 1000) ||
          (task.begin >= date.unix() * 1000 &&  // is the task starting on this date
              task.begin < end.unix() * 1000) ||
              (task.until >= date.unix() * 1000 && // is the task ending on this date
                  task.until < end.unix() * 1000))) {
        if (a > 0) {
          // if the task duration is less than 8 hours we should only calculate these hours
          if (task.begin >= date.unix() * 1000 &&
              task.until <= end.unix() * 1000) {
            a = (task.until - task.begin) / 60 / 60000;
          }
          else if (task.begin >= date.unix() * 1000 &&
                   task.until > end.unix() * 1000) {
            // if the task starts on this day but ends on a different day it may not take up the whole day
            a = getAvailabilityDay(calendars, date, task.begin - date.unix() * 1000, 0, staffAlloc);
          }
          else if (task.until < end.unix() * 1000 &&
              task.begin < date.unix() * 1000) {
            // if the task ends on this day but starts on a different day it may not take up the whole day
            const until = moment(task.until);
            a = getAvailabilityDay(calendars, date, 0, (until.hour() * 60000 * 60) + (until.minute() * 60000), staffAlloc);
          }
          const quantity = task.quantity ? task.quantity : 1;
          if (task.actualDuration) {
            wActual += (task.utilization * (task.actualDuration / (task.te ? task.te : task.duration)) * quantity) * a/*hours*/;
          }
          if (task.fixedDuration) {
            wFixed += (task.utilization * (task.fixedDuration / (task.te ? task.te : task.duration)) * quantity) * a/*hours*/;
          }
          w += task.utilization * a * quantity;
        }
        const clonedTask = cloneDeep(task);
        if (a > 0) {
          const quantity = task.quantity ? task.quantity : 1;
          clonedTask.w = task.utilization * a * quantity;
          if (task.actualDuration) {
            clonedTask.wActual = (task.utilization * (task.actualDuration / (task.te ? task.te : task.duration)) * quantity) * a/*hours*/;
          }
          if (task.fixedDuration) {
            clonedTask.wFixed = (task.utilization * (task.fixedDuration / (task.te ? task.te : task.duration)) * quantity) * a/*hours*/;
          }
        }
        else {
          clonedTask.w = 0;
        }
        clonedTask.a = a;
        t.push(clonedTask);
      }
    }
  }

  const resultCost = getCost(t, perhourCost);
  const basecost = a * perhourCost;
  const cost = resultCost.cost;
  const costNet = resultCost.netCost;
  const fixedCost = resultCost.fixedCost;
  const fixedCostNet = resultCost.netFixedCost;
  const actualCost = resultCost.actualCost;
  const actualCostNet = resultCost.netActualCost;
  t = resultCost.tasks;
  
  return resolveBookingsTasks({ w: w, 
                                t: t, 
                                wActual: wActual, 
                                wFixed: wFixed, 
                                a: a, 
                                basecost, 
                                cost, 
                                costNet,
                                fixedCost,
                                fixedCostNet,
                                actualCost,
                                actualCostNet }, 
                              span);
}

function addTasks(usage, tasks) {
  const list = usage.t;
  const tmap = usage.tmap;
  for (const task of tasks) {
    if (!(task.uuId in tmap)) {
      list.push(task);
      tmap[task.uuId] = task;
    }
    else {
      tmap[task.uuId].w += task.w;
      tmap[task.uuId].a += task.a;
    }
  }
  return usage;
}

export function getDefaultAvailability(span) {
  if (span === 'day') {
    return 8.0;
  }
  else if (span === 'week') {
    return 40.0;
  }
  else if (span === 'month') {
    return 168.0;
  }
  else if (span === 'year') {
    return 2080.0;
  }
}

function resolveBookingsTasks(usage, span) {
  const resolved = {};
  for (const t of usage.t) {
    if (t.type === 'task') {
      // find the booking if it exists
      const booking = usage.t.filter(b => b.type === 'booking' &&
                                   b.project === t.project &&
                                   !(b.project in resolved));
                                   
      // find the other tasks if they exist
      const tasks = usage.t.filter(b => b.type === 'task' &&
                                   b.project === t.project &&
                                   !(b.project in resolved));
      
      if (booking.length > 0) {
        let totalBooking = 0;
        let totalTask = 0;
        let totalBookingCost = 0;
        let totalBookingCostNet = 0;
        let totalBookingFixedCost = 0;
        let totalBookingFixedCostNet = 0;
        let totalTaskCost = 0;
        let totalTaskCostNet = 0;
        let totalTaskFixedCost = 0;
        let totalTaskFixedCostNet = 0;
        
        for (const b of booking) {
          totalBooking += b.w;
          if (typeof b.cost !== 'undefined') {
            totalBookingCost += b.cost;
          }
          if (typeof b.netCost !== 'undefined') {
            totalBookingCostNet += b.netCost;
          }
          if (typeof b.fixedCost !== 'undefined') {
            totalBookingFixedCost += b.fixedCost;
          }
          if (typeof b.netFixedCost !== 'undefined') {
            totalBookingFixedCostNet += b.netFixedCost;
          }
        }
        for (const b of tasks) {
          totalTask += b.w;
          if (typeof b.cost !== 'undefined') {
            totalTaskCost += b.cost;
          }
          if (typeof b.netCost !== 'undefined') {
            totalTaskCostNet += b.netCost;
          }
          if (typeof b.fixedCost !== 'undefined') {
            totalTaskFixedCost += b.fixedCost;
          }
          if (typeof b.netFixedCost !== 'undefined') {
            totalTaskFixedCostNet += b.netFixedCost;
          }
        }
        // remove the lesser working hours
        if (totalBooking > totalTask) {
          usage.w -= totalTask;
          usage.cost -= totalTaskCost;
          usage.costNet -= totalTaskCostNet;
          usage.fixedCost -= totalTaskFixedCost;
          usage.fixedCostNet -= totalTaskFixedCostNet;
        }
        else {
          usage.w -= totalBooking;
          usage.cost -= totalBookingCost;
          usage.costNet -= totalBookingCostNet;
          usage.fixedCost -= totalBookingFixedCost;
          usage.fixedCostNet -= totalBookingFixedCostNet;
        }
        
        resolved[booking[0].project] = true;
      }
    }
  }
  return usage;
}

function checkOvers(usage, quota, staffAlloc) {
  if (staffAlloc === 'fixeddurationhours' ||
      staffAlloc === 'fixeddurationdays') {
    return usage.wFixed > usage.a * quota;
  }  
  else if (staffAlloc === 'actualdurationhours' ||
      staffAlloc === 'actualdurationdays') {
    return usage.wActual > usage.a * quota;
  }  
  return usage.w > usage.a * quota;
}

export function getUsage(calendars, start, span, taskList, begin, until, quota, staffAlloc, perhourCost) {
  if (!quota) {
    quota = 1;
  }
  let usage = { w: 0, wActual: 0, wFixed: 0, a: 0, t: [], tmap: {}, h: 0, ha: 0,
      actualCost: 0,
      actualCostNet: 0,
      basecost: 0,
      cost: 0,
      costNet: 0,
      fixedCost: 0,
      fixedCostNet: 0
  };
  
  if (span === 'day') {
    let a = getAvailabilityDay(calendars, start, 0, 0, staffAlloc);
    if ((begin - 86400000) > start || // subtract 1 day so that the start date is shown as employed
        until + 86400000 < start) {
      usage.a = -1;
    }
    else {
      usage.a = a;
    }
    const wh = getWorkingHoursDay(taskList, start, a, start.clone().add(1, 'days'), calendars, staffAlloc, span, perhourCost);
    usage.w = wh.w;
    usage.wActual = wh.wActual;
    usage.wFixed = wh.wFixed;
    usage.h = a !== 0 ? usage.w / a : 0;
    usage.ha = usage.a > 0 ? 1 : 0;
    usage.t = wh.t;
    usage.actualCost += wh.actualCost;
    usage.actualCostNet += wh.actualCostNet;
    usage.basecost += wh.basecost;
    usage.cost += wh.cost;
    usage.costNet += wh.costNet;
    usage.fixedCost += wh.fixedCost;
    usage.fixedCostNet += wh.fixedCostNet;
  }
  else if (span === 'week') {
    let date = start.clone();
    let totalA = 0;
    for (let i = 0; i < 7; i++) {
      let a = getAvailabilityDay(calendars, date, 0, 0, staffAlloc);
      if ((begin - 86400000) > start || // subtract 1 day so that the start date is shown as employed
          until + 86400000 < start) {
        // do nothing
      }
      else {
        usage.a += a;
      }
      totalA += a; // record the availability for calculating headcount
      const whw = getWorkingHoursDay(taskList, date, a, date.clone().add(1, 'days'), calendars, staffAlloc, span, perhourCost);
      usage.w += whw.w;
      usage.wActual += whw.wActual;
      usage.wFixed += whw.wFixed;
      usage = addTasks(usage, whw.t);
      usage.actualCost += whw.actualCost;
      usage.actualCostNet += whw.actualCostNet;
      usage.basecost += whw.basecost;
      usage.cost += whw.cost;
      usage.costNet += whw.costNet;
      usage.fixedCost += whw.fixedCost;
      usage.fixedCostNet += whw.fixedCostNet;
      if (checkOvers(whw, quota, staffAlloc)) {
        usage.c = usage.c ? usage.c = [...usage.c, date] : usage.c = [date];
      }
      date = date.add(1, 'days');
    }
    usage.h = totalA !== 0 ? usage.w / totalA : 0;
    usage.ha = usage.a !== 0 ? 1 : 0;
  }
  else if (span === 'month') {
    let date = start.clone();
    let month = date.month();
    let totalA = 0;
    while (date.month() === month) {
      let a = getAvailabilityDay(calendars, date, 0, 0, staffAlloc);
      if ((begin - 86400000) > start || // subtract 1 day so that the start date is shown as employed
          until + 86400000 < start) {
        // do nothing
      }
      else {
        usage.a += a;
      }
      totalA += a; // record the availability for calculating headcount
      const wh = getWorkingHoursDay(taskList, date, a, date.clone().add(1, 'days'), calendars, staffAlloc, span, perhourCost);
      usage.w += wh.w;
      usage.wActual += wh.wActual;
      usage.wFixed += wh.wFixed;
      usage = addTasks(usage, wh.t);
      usage.actualCost += wh.actualCost;
      usage.actualCostNet += wh.actualCostNet;
      usage.basecost += wh.basecost;
      usage.cost += wh.cost;
      usage.costNet += wh.costNet;
      usage.fixedCost += wh.fixedCost;
      usage.fixedCostNet += wh.fixedCostNet;
      if (checkOvers(wh, quota, staffAlloc)) {
        usage.c = usage.c ? usage.c = [...usage.c, date] : usage.c = [date];
      }
      date = date.add(1, 'days');
    }
    usage.h = totalA !== 0 ? usage.w / totalA : 0;
    usage.ha = usage.a !== 0 ? 1 : 0;
  }
  else if (span === 'year') {
    let date = start.clone();
    let year = date.year();
    let totalA = 0;
    while (date.year() === year) {
      let aa = getAvailabilityDay(calendars, date, 0, 0, staffAlloc);
      if ((begin - 86400000) > start || // subtract 1 day so that the start date is shown as employed
          until + 86400000 < start) {
        // do nothing
      }
      else {
        usage.a += aa;
      }
      totalA += aa; // record the availability for calculating headcount
      const whh = getWorkingHoursDay(taskList, date, aa, date.clone().add(1, 'days'), calendars, staffAlloc, span, perhourCost);
      usage.w += whh.w;
      usage.wActual = whh.wActual;
      usage.wFixed = whh.wFixed;
      usage = addTasks(usage, whh.t);
      usage.actualCost += whh.actualCost;
      usage.actualCostNet += whh.actualCostNet;
      usage.basecost += whh.basecost;
      usage.cost += whh.cost;
      usage.costNet += whh.costNet;
      usage.fixedCost += whh.fixedCost;
      usage.fixedCostNet += whh.fixedCostNet;
      if (checkOvers(whh, quota, staffAlloc)) {
        usage.c = usage.c ? usage.c = [...usage.c, date] : usage.c = [date];
      }
      date = date.add(1, 'days');
    }
    usage.h = totalA !== 0 ? usage.w / totalA : 0;
    usage.ha = usage.a !== 0 ? 1 : 0;
  }
  return usage;
}

export function calculateStaffUsage(data, start, end, span, calendars) {
  const usage = {};
  let date = start;
  
  while (date <= end) {
    const ref = getDateRef(new Date(date.year(), date.month(),
                date.date(), date.hours(),
                date.minutes(), date.seconds()), span);
    usage[ref] = getUsage(calendars, date, span, data.taskList, data.begin, data.until);
    
    if (span === 'day') {
      date = date.add(1, 'days');
    }
    else if (span === 'week') {
      date = date.add(7, 'days');
    }
    else if (span === 'month') {
      date.set("date", 1);
      date = date.add(1, 'months');
    }
    else if (span === 'year') {
      date = date.add(1, 'years');
    }
  }
  return usage;
}


export function processSystemCalendar(calendar) {
  // convert type to v_CalendarType
  for (const entry of calendar) {
    if (entry.type === 'Sunday') {
      entry.v_CalendarType = [1];
    }
    else if (entry.type === 'Sunday') {
      entry.v_CalendarType = [1];
    }
    else if (entry.type === 'Monday') {
      entry.v_CalendarType = [2];
    }
    else if (entry.type === 'Tuesday') {
      entry.v_CalendarType = [3];
    }
    else if (entry.type === 'Wednesday') {
      entry.v_CalendarType = [4];
    }
    else if (entry.type === 'Thursday') {
      entry.v_CalendarType = [5];
    }
    else if (entry.type === 'Friday') {
      entry.v_CalendarType = [6];
    }
    else if (entry.type === 'Saturday') {
      entry.v_CalendarType = [7];
    }
    
    if (entry.endHour) {
      entry.v_EndHour = [entry.endHour];
    }
    if (entry.startHour) {
      entry.v_StartHour = [entry.startHour];
    }
  }
  return calendar;
}

export async function systemLocationCalendar() {
  let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
  .then(response => {
    return (response && response.data? response.data : []) || [];
  })
  .catch(e => {
    this.httpAjaxError(e);
    return [];
  })
  
  return processSystemCalendar(data[data.jobCase][0]);
}


export async function  createActivity(name, desc, startDate, startTime, endDate, endTime, duration, staffUuid, color, multiStaff, stage, utilization, quantity, customFields) {
  const uuId = await activityService.create([{name: name, description: desc, startTime: convertDate(startDate, startTime), closeTime: convertDate(endDate, endTime), duration: duration, color: color, ...customFields }])
  .then((response) => {
    return response.data.feedbackList[0].uuId;
  });

  if (multiStaff) {
    await activityLinkStaffService.create(uuId, multiStaff.map(s => { return { uuId: s.uuId, utilization: s.utilization, quantity: s.quantity } }));
  }
  else {
    await activityLinkStaffService.create(uuId, [{ uuId: staffUuid, utilization: utilization, quantity: quantity }]);
  }
  
  if (stage) {
    await activityLinkStageService.create(uuId, {uuId: stage});
  }
  return uuId;
}

export async function  cloneActivity(name, desc, startDate, startTime, endDate, endTime, duration, uuId, newStaff, activityStaff, utilization, quantity) {
  const data = {
    name: name, 
    description: desc, 
    startTime: convertDate(startDate, startTime), 
    closeTime: convertDate(endDate, endTime), 
    duration: duration,
    autoScheduling: false
  };
  
  if (data.closeTime > 32503680000000) {
    data.closeTime = 32503680000000;
  }
  
  const newUuid = await activityService.clone(
    data, 
    uuId
  )
  .then((response) => {
    return response.data.jobClue.uuId;
  });
  
  data.uuId = newUuid;
  await activityService.update([data])
  .then(() => {
    return;
  });
  
  if (newStaff !== activityStaff) {
    await activityLinkStaffService.remove(newUuid, [{ uuId: activityStaff }]);
    await activityLinkStaffService.create(newUuid, [{ uuId: newStaff, utilization: utilization, quantity: quantity }]);
  }
  return newUuid;
}

export async function  createTask(name, desc, startDate, startTime, endDate, endTime, duration, project, staffUuid, color, multiStaff, stage, utilization, quantity, customFields) {
  const uuId = await taskService.create([{
    name: name, 
    description: desc, 
    startTime: convertDate(startDate, startTime), 
    closeTime: convertDate(endDate, endTime), 
    duration: duration, 
    taskType: 'Task', 
    constraintType: "As_soon_as_possible",
    autoScheduling: false,
    color: color ? color : null,
    ...customFields
  }], project)
  .then((response) => {
    return response.data.feedbackList[0].uuId;
  });
  
  if (multiStaff) {
    await taskLinkStaffService.create(uuId, multiStaff.map(s => { return { uuId: s.uuId, utilization: s.utilization, quantity: s.quantity } }));
  }
  else {
    await taskLinkStaffService.create(uuId, [{ uuId: staffUuid, utilization: utilization, quantity: quantity }]); 
  }
  
  if (stage) {
    await taskLinkStageService.create(uuId, {uuId: stage});
  }
  return uuId;
}

export async function  cloneTask(name, desc, startDate, startTime, endDate, endTime, duration, project, uuId, newStaff, taskStaff, utilization, quantity) {
  const data = {
    name: name, 
    description: desc, 
    startTime: convertDate(startDate, startTime), 
    closeTime: convertDate(endDate, endTime), 
    duration: duration,
    autoScheduling: false
  };
  
  if (data.closeTime > 32503680000000) {
    data.closeTime = 32503680000000;
  }
  
  const newUuid = await taskService.clone(
    data, 
    project, 
    uuId
  )
  .then((response) => {
    return response.data.jobClue.uuId;
  });
  
  data.uuId = newUuid;
  await taskService.update([data])
  .then(() => {
    return;
  });
  
  if (newStaff !== taskStaff) {
    await taskLinkStaffService.remove(newUuid, [{ uuId: taskStaff }]);
    await taskLinkStaffService.create(newUuid, [{ uuId: newStaff, utilization: utilization, quantity: quantity }]);
  }
  return newUuid;
}

export async function  createVacation(name, desc, startDate, startTime, endDate, endTime, duration, staffUuid, multiStaff = null) {
  if (endTime === "00:00") {
    const m = moment(endDate);
    m.subtract(1, 'days');
    endDate = m.format('YYYY-MM-DD');
  }
  
  if (multiStaff) {
    const cmdList = [];
    for (const staff of multiStaff) {
      cmdList.push({
        "invoke": `/api/calendar/add?holder=${staff.uuId}`,
        "body": [{
          name: name,
          startDate: startDate,
          endDate: endDate,
          isWorking: false,
          type: "Leave"
        }]
      });
    }
    return await compositeService.exec(cmdList)
    .then((response) => {
      const result = [];
      for (const res of response.data.feedbackList) {
        result.push(res.uuId);
      }
      return result;
    });
  }
  else {
    return await calendarService.create([{
      name: name,
      startDate: startDate,
      endDate: endDate,
      isWorking: false,
      type: "Leave"
      }],
      staffUuid)
      .then((response) => {
        return response.data.feedbackList[0].uuId;
      });
  }
}

export async function createBooking(name, desc, startDate, startTime, endDate, endTime, duration, staffUuid, projectUuid, color, multiStaff, stage, utilization, quantity, customFields) {
  let idx = 0;
  if (multiStaff) {
    const cmdList = [];
    for (const staff of multiStaff) {
      idx++;
      const idName = `booking${idx}`;
      const data = {
        invoke: '/api/booking/add',
        body: [{
          name: name, 
          color: color ? color : null,
          description: desc, 
          beginDate: convertDate(startDate, startTime),
          untilDate: convertDate(endDate, endTime),
          duration: duration,
          utilization: staff.utilization,
          staff: {
            uuId: staff.uuId
          },
          project: {
            uuId: projectUuid
          },
          ...customFields
        }],
        vars: [{ name: idName, path: '$.feedbackList.uuId' }]
      }
      if (Object.hasOwn(staff, 'quantity') && staff.quantity > 1) {
        for (let i = 0, len = staff.quantity; i < len; i++) {
          const d = JSON.parse(JSON.stringify(data));
          const idVar = `${idName}_${i}`;
          d.vars = [{ name: idVar, path: '$.feedbackList.uuId' }]
          cmdList.push(d);

          if (stage) {
            cmdList.push({
              "invoke": `/api/booking/link/stage/add`,
              "body": {
                uuId: `@{${idVar}}`, 
                stage: {
                  uuId: stage
                }
              }
            });
          }
        }
      } else {
        cmdList.push(data);
        if (stage) {
          cmdList.push({
            "invoke": `/api/booking/link/stage/add`,
            "body": {
              uuId: `@{${idName}}`, 
              stage: {
                uuId: stage
              }
            }
          });
        }
      }
    }
    return await compositeService.exec(cmdList)
    .then((response) => {
      const result = [];
      for (const res of response.data.feedbackList) {
        result.push(res.uuId);
      }
      return result;
    });
  }
  else {
    const uuId = await bookingService.create([{
      name: name, 
      color: color ? color : null,
      description: desc, 
      beginDate: convertDate(startDate, startTime),
      untilDate: convertDate(endDate, endTime),
      duration: duration,
      utilization: utilization,
      staff: {
        uuId: staffUuid
      },
      project: {
        uuId: projectUuid
      },
      ...customFields
    }])
    .then((response) => {
      return response.data.feedbackList[0].uuId;
    });
    
    if (stage) {
      await bookingLinkStageService.create(uuId, {uuId: stage});
    }
    return uuId;
  }
}

export async function cloneBooking(name, startDate, startTime, endDate, endTime, duration, staffUuid, projectUuid, color, bookingId, utilization) {
  let idx = 0;
  const data = {
    name: name, 
    color: color ? color : null,
    beginDate: convertDate(startDate, startTime),
    untilDate: convertDate(endDate, endTime),
    duration: duration,
    utilization: utilization,
    staff: {
      uuId: staffUuid
    },
    project: {
      uuId: projectUuid
    }
  }

  if (data.untilDate > 32503680000000) {
    data.untilDate = 32503680000000;
  }
  
  const uuId = await bookingService.clone(
  data, bookingId)
  .then((response) => {
    return response.data.jobClue.uuId;
  });
  
  return uuId;
}

export async function staffDurationCalculationOk(self, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay) {
  if (self.creatingEvent) {
    let update = false;
    self.newEvent.startDate = startDateStr;
    self.newEvent.startTime = startTimeStr;
    self.newEvent.closeDate = closeDateStr;
    self.newEvent.closeTime = closeTimeStr;
    const { value: dValue } = convertDisplayToDuration(durationDisplay);
    self.newEvent.value = dValue;
    
    if (self.newEvent.type === 'Activity') {
      self.newEvent.uuId = await createActivity(
        self.newEvent.name, 
        self.newEvent.description, 
        self.newEvent.startDate, 
        self.newEvent.startTime, 
        self.newEvent.closeDate, 
        self.newEvent.closeTime, 
        self.newEvent.value, 
        self.newEvent.staffUuid,
        null,
        self.newEvent.multiStaff,
        self.newEvent.stage,
        self.newEvent.utilization,
        self.newEvent.quantity,
        self.newEvent.customFields
      );

      // set the name as the path
      self.newEvent.shortName = self.newEvent.name;
      
      if (self.show && !self.show.activity) {// staff usage
        self.promptShowActivity = true;
      }
      update = true;
    }
    else if (self.newEvent.type === 'Booking') {
      
      self.newEvent.uuId = await createBooking(
        self.newEvent.projectBooking[0].name, 
        self.newEvent.description, 
        self.newEvent.startDate, 
        self.newEvent.startTime, 
        self.newEvent.closeDate, 
        self.newEvent.closeTime, 
        self.newEvent.value, 
        self.newEvent.staffUuid, 
        self.newEvent.projectBooking[0].uuId,
        self.newEvent.projectBooking[0].color,
        self.newEvent.multiStaff,
        self.newEvent.stage,
        self.newEvent.utilization,
        self.newEvent.quantity,
        self.newEvent.customFields
      );

      // set the name as the path
      self.newEvent.shortName = self.newEvent.name;
      
      if (self.show && !self.show.booking) {// staff usage
        self.promptShowBooking = true;
      }
      update = true;
    }
    else if (self.newEvent.type === 'Task') {
      self.newEvent.uuId = await createTask(self.newEvent.name, 
                            self.newEvent.description,
                            self.newEvent.startDate, 
                            self.newEvent.startTime, 
                            self.newEvent.closeDate, 
                            self.newEvent.closeTime, 
                            self.newEvent.value, 
                            self.newEvent.projectTask[0].uuId, 
                            self.newEvent.staffUuid,
                            self.newEvent.projectTask[0].color,
                            self.newEvent.multiStaff,
                            self.newEvent.stage,
                            self.newEvent.utilization,
                            self.newEvent.quantity,
                            self.newEvent.customFields);
      
      // set the name as the path
      self.newEvent.shortName = self.newEvent.name;
      self.newEvent.name = `${self.newEvent.projectTask[0].name} / ${self.newEvent.name}`;
      
      // set the time stamps for comparing to bookings
      self.newEvent.begin = convertDate(self.newEvent.startDate, self.newEvent.startTime);
      self.newEvent.until = convertDate(self.newEvent.closeDate, self.newEvent.closeTime);
      
      if (self.show && !self.show.task) {// staff usage
        self.promptShowTask = true;
      }
      update = true;
    }
    
    self.callback = self.cleanupCreate;
    
    if (update) {
      self.inProgressShow = false;
      if (Array.isArray(self.newEvent.uuId)) {
        let idx = 0;
        const uuIds = self.newEvent.uuId;
        const isBooking = self.newEvent.type == 'Booking';
        for (const staff of self.newEvent.multiStaff) {
          if (isBooking && Object.hasOwn(staff, 'quantity') && staff.quantity > 1) {
            for (let i = 0, len = staff.quantity; i < len; i++) {
              self.newEvent.uuId = uuIds[idx++];
              self.newEvent.staffUuid = staff.uuId;
              self.newEvent.utilization = staff.utilization;
              self.processAdd(self.newEvent);
            }
          } else {
            self.newEvent.uuId = uuIds[idx++];
            self.newEvent.staffUuid = staff.uuId;
            self.newEvent.utilization = staff.utilization;
            self.processAdd(self.newEvent);
          }
        }
      }
      else {
        self.processAdd(self.newEvent, self.newEvent.multiStaff);
      }
    }
  }
  else {
    let update = false;
    // if a date has changed from the prompt we need to update the scheduler
    self.updateEvent[0].forceUpdate = self.updateEvent[0].startDate !== startDateStr ||
                                      self.updateEvent[0].startTime !== startTimeStr ||
                                      self.updateEvent[0].closeDate !== closeDateStr ||
                                      self.updateEvent[0].closeTime !== closeTimeStr;
    
    self.updateEvent[0].startDate = startDateStr;
    self.updateEvent[0].startTime = startTimeStr;
    self.updateEvent[0].closeDate = closeDateStr;
    self.updateEvent[0].closeTime = closeTimeStr;
    const { value: dValue } = convertDisplayToDuration(durationDisplay);
    self.updateEvent[0].duration = dValue;
    const eventId = typeof self.updateEvent[0].id === 'string' ? self.updateEvent[0].id.slice(self.updateEvent[0].id.length - 36) : self.updateEvent[0].id;
    if (self.updateEvent[0].type === 'activity') {
      await activityService.update([{
                                      name: self.updateEvent[0].name, 
                                      uuId: eventId, 
                                      duration: self.updateEvent[0].duration,
                                      startTime: convertDate(startDateStr, startTimeStr), 
                                      closeTime: convertDate(closeDateStr, closeTimeStr)
                                    }])
      .then(() => {
        update = true;
      });
      
      if (self.updateEvent[0].origStaffUuid !== self.updateEvent[0].staffUuid) {
        // remove staff
        await activityLinkStaffService.remove(eventId, [{ uuId: self.updateEvent[0].origStaffUuid }]);
        // add staff
        await activityLinkStaffService.create(eventId, [{ uuId: self.updateEvent[0].staffUuid, utilization: 1 }]);
        update = true;
      }
    }
    else if (self.updateEvent[0].type === 'booking') {
      await bookingService.update([{
                                    name: self.updateEvent[0].name, 
                                    uuId: eventId, 
                                    duration: self.updateEvent[0].duration,
                                    beginDate: convertDate(startDateStr, startTimeStr), 
                                    untilDate: convertDate(closeDateStr, closeTimeStr), 
                                    staff: {
                                      uuId: self.updateEvent[0].staffUuid
                                    }
                                  }])
      .then(() => {
        update = true;
      });
    }
    else if (self.updateEvent[0].type === 'task') {
      if (self.updateEvent[0].asch) {
        self.promptScheduleChange = true;
        return;
      }      
      await taskService.update([{
                                 uuId: eventId, 
                                 duration: self.updateEvent[0].duration,
                                 startTime: convertDate(startDateStr, startTimeStr), 
                                 closeTime: convertDate(closeDateStr, closeTimeStr)
                               }])
      .then(() => {
        update = true;
      });
      
      if (self.updateEvent[0].origStaffUuid !== self.updateEvent[0].staffUuid) {
        // remove staff
        await taskLinkStaffService.remove(eventId, [{ uuId: self.updateEvent[0].origStaffUuid }]);
        // add staff
        await taskLinkStaffService.create(eventId, [{ uuId: self.updateEvent[0].staffUuid, utilization: 1 }]);
      }
    }
    else if (self.updateEvent[0].type === 'vacation') {
      await calendarService.update([{
        uuId: self.updateEvent[0].id,
        startDate: startDateStr,
        endDate: closeDateStr
      }]);
    }
    
    self.processUpdate(self.updateEvent[0], self.updateEvent.length === 1);
    self.updateEvent.splice(0, 1);
    if (self.updateEvent.length === 0) {
      if (update) {
        //self.updateGrid(true);
      }
    }
    else {
      self.initDurationCalculation({ trigger: self.updateEvent[0].trigger });
    }
  }
}
