var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HeaderSidebarContainer",
    [
      _c("HeaderCompany", {
        attrs: { slot: "headerCompany" },
        slot: "headerCompany",
      }),
      _c("HeaderDropdownAccount", {
        attrs: { slot: "headerDropdownAccount" },
        slot: "headerDropdownAccount",
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }