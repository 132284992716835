<template>
  <StaffBadgeGroupModal :show.sync="modalShow" :companies="companies" :staffList="staffList" :taskUuId="taskId" :hideUtilization="params.hideUtilization" @ok="staffSelectorOk" @cancel="staffSelectorCancel" @staffDeleted="staffSelectorDeleted"/>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'StaffEditor',
  components: {
    StaffBadgeGroupModal: () => import('@/components/modal/StaffBadgeGroupModal')
  },
  data() {
    return {
      value: '',
      modalShow: false,
      taskId: null,
      staffList: [],
      compactMode: false
    };
  },
  computed: {
    companies() {
      if (this.params.companyList) {
        return this.params.companyList();
      }
      return [];
    }
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;

        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (let i = 0, len = orgValue.length; i < len; i++) {
          const found = curValue.find(v => v.uuId == orgValue[i].uuId);
          if (found == null || orgValue[i].utilization != found.utilization || orgValue[i].unit != found.unit) {
            hasChanged = true;
            break;
          }
        }
        
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    staffSelectorOk(modifiedStaffList) {
      //Check array size
      if (!this.params.value ||
          modifiedStaffList.length != this.params.value.length) {
        this.value = modifiedStaffList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originStaffs = this.params.value;
      const originIds = originStaffs.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifiedStaffList.length; i < len; i++) {
        if (!originIds.includes(modifiedStaffList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifiedStaffList;
        this.closePicker;
        return;
      }

      //Check utilization and unit property
      let tmpStaff;
      for (let j = 0, jLen = modifiedStaffList.length; j < jLen; j++) {
        tmpStaff = modifiedStaffList[j];
        const oStaff = originStaffs.find(e => e.uuId == tmpStaff.uuId) ;
        if (tmpStaff.utilization != oStaff.utilization) {
          hasChanged = true;
          break;
        }
        if (tmpStaff.unit != oStaff.unit) {
          hasChanged = true;
          break;
        }
      }

      if (hasChanged) {
        this.value = modifiedStaffList;
      }
      this.closePicker();
    },
    staffSelectorCancel() {
      this.closePicker();
    },
    prepareStaffList() {
      if(this.value != null) {
        for (const staff of this.value) {
          this.staffList.push({ uuId: staff.uuId, name: staff.name, utilization: staff.utilization, unit: staff.unit, genericStaff: staff.genericStaff });
        }
      }
    },
    getTaskId() {
      const p = this.params;
      if (this.compactMode) {
        this.taskId = p.value.uuId != null? p.value.uuId : null;
      } else {
        this.taskId = p && p.data && p.data.uuId? p.data.uuId : null;
      }
    },
    staffSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, utilization: i.utilization, unit: i.unit, genericStaff: i.genericStaff }});
    },
    
  },

  created() {
    this.modalShow = true;
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.value = this.compactMode? objectClone(this.params.value.single): this.params.value;
    this.prepareStaffList();
    if (this.params.api.getSelectedNodes().length < 2) { //Fix for issue #364, no recommended tab when multiple tasks are selected
      this.getTaskId();
    }
  }
})
</script>