import { userService } from '@/services'
import { objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import store from '@/store'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

export const userUtil = {
  list: (params, { self }) => {
    const redacted = [];
    if (!self.canView('TAG') || !self.canView('USER', ['TAG'])) {
      redacted.push('tag');
    }
    return userService.listv2(params, { includeAccessPolicy: true, redacted, customFields: self?.customFields });
  }
  , remove: userService.remove
  , clone: userService.clone
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      
      if (sortModel[i].colId === 'accessPolicy') {
        params.ksort.push('accessPolicyName');
      } else {
        params.ksort.push(sortModel[i].colId)
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }
    return params
  }
  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('user.field.lastName')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , cellEditorParams: {
          customProp: 'lastName'
          , isOptional: false
        }
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: self.canEdit('USER', ['lastName'])
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.lastName)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.lastName = newValue
            return true
          }
          return false
        }
      },
      {
        headerName: self.$t('user.field.firstName')
        , field: 'firstName'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , hide: false
        , editable: true
      },
      {
        headerName: self.$t('user.field.nickName')
        , field: 'nickName'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('user.field.email')
        , field: 'email'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , cellEditorParams: {
          isOptional: false
        }
        , hide: false
        , editable: true
      },
      {
        headerName: self.$t('user.field.ldapLogin')
        , field: 'ldap'
        , cellRenderer: 'genericCellRenderer'
        , minWidth: 100
        , valueGetter: params => {
          return params.data.ldap ? self.$t('user.field.ldapLogin') : '';
        }
        , hide: true
      },
      {
        headerName: self.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'tagEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('field.color')
        , field: 'color'
        , cellRenderer: 'colorCellRenderer'
        , cellEditor: 'colorEditor'
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('user.field.accessPolicy')
        , field: 'accessPolicy'
        , cellRenderer: 'genericObjectCellRenderer'
        , cellEditor: 'accessPolicyEditor'
        , minWidth: 100
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('user.field.mobile')
        , field: 'mobile'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , hide: true
        , editable: true
      },
      {
        headerName: self.$t('field.status')
        , field: 'enabled'
        , cellRenderer: 'statusCellRenderer'
        , minWidth: 100
        , hide: true

      }
    ]

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = [
      { selector: 'USER.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'USER', field: 'ldap', properties: ['ldapLogin'] } //Need this because field name is different from property name
    ]
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'USER', linkedEntities)
    //EDIT permission: set column to be read only.
    setEditDenyPropertiesReadOnly(colDefs, 'USER', linkedEntities)
    return colDefs
  }
  , getColorMenuOptions: () => ({
    none: true
    , user: false
  })
  , entityUpdateApiUrl: '/api/user/update'
  , entityDeleteApiUrl: '/api/user/delete'
  , getValueChangedHandler: (/** self **/) => {
    return {
      accessPolicy: {
        isAsync: true
        , execute: async (entityId, oldVal, newValue) => {
          const abortResponse = {
            value: oldVal
            , status: 'ABORT'
            , property: 'accessPolicy'
          }

          //Expected oldVal and newValue's value:
          //{ uuId: xxx, name: yyy }

          const oldId = oldVal != null && oldVal.uuId != null? oldVal.uuId : null
          const newId = newValue != null && newValue.uuId != null? newValue.uuId : null
          const requests = []
          
          ////[Start] Process link between user and access policy
          //Remove old link if exists and old access policy is different from new access policy.
          if (oldId != null && oldId != newId) {
            requests.push({
              method: 'POST',
              invoke: `/api/user/link/access_policy/delete`,
              body: { 
                uuId: entityId,
                accessPolicyList: [{
                  uuId: oldId
                }]
              },
              vars: [],
              note: `userRemoveAccessPolicyLink__${entityId}__${oldId}`
            })
          }
          //Add new link between user and new access policy
          if (newId != null) {
            requests.push({
              method: 'POST',
              invoke: `/api/user/link/access_policy/add`,
              body: { 
                uuId: entityId,
                accessPolicyList: [{
                  uuId: newId
                }]
              },
              vars: [],
              note: `userAddAccessPolicyLink__${entityId}__${newId}`
            })
          }
          ////[End] Process link between user and access policy

          ////[Start] Update user's permission list with Access policy's permission list
          const newPermissionList = await store.dispatch('data/accessPolicyGet', { accessPolicyIds: [{ uuId: newId }], links: ['PERMISSION'] })
          .then(value => {
            if (value == null) {
              return null
            }
            const redactedProperties = value.redacted != null? value.redacted : []
            if (redactedProperties.includes('PERMISSION')) {
              return null
            }
            if (value.jobCase == null || value[value.jobCase] == null || value[value.jobCase].length == 0) {
              return null
            }              
            const data = value[value.jobCase][0];
            if (data == null || !Array.isArray(data.permissionList)) {
              return null  
            }
            return data.permissionList
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
            return null //Signal abort operation
          });
          
          //Abort the whole operation when new permissionList is null
          if (newPermissionList == null) {
            return abortResponse
          }
          
          const userPermissionIds = await userService.getPermissionIds(entityId)
          .then(value => {
            if (!Array.isArray(value) || value.length == 0) {
              return null
            }
            const data = value[0]
            if (!Object.hasOwn(data, 'permissionIds') || !Array.isArray(data.permissionIds)) {
              return null
            }
            return data.permissionIds
          })
          .catch(e => {
            console.error(e); // eslint-disable-line no-console
            return null //Signal abort operation
          })

          if (userPermissionIds == null) {
            return abortResponse
          }

          const permToAdd = []
          const permToUpdate = []
          for(let i = 0, len = newPermissionList.length; i < len; i++) {
            const newPerm = newPermissionList[i]
            if (!Object.hasOwn(newPerm, 'permissionLink')) {
              newPerm.permissionLink = { denyRules: [] }
            }
            if(userPermissionIds.includes(newPerm.uuId)) {
              permToUpdate.push(newPerm)
            } else {
              permToAdd.push(newPerm)
            }
          }
          const idsToAdd = permToAdd.map(i => i.uuId)
          const idsToUpdate = permToUpdate.map(i => i.uuId)
          const idsToRemove = userPermissionIds.filter(i => !idsToAdd.includes(i) && !idsToUpdate.includes(i))

          if (permToAdd.length > 0) {
            requests.push({
              method: 'POST',
              invoke: `/api/user/permission/add`,
              body: [{ 
                uuId: entityId,
                permissionList: permToAdd
              }],
              vars: [],
              note: `userAddPermissionLink__${entityId}`
            })
          }

          if (permToUpdate.length > 0) {
            requests.push({
              method: 'POST',
              invoke: `/api/user/permission/update`,
              body: [{ 
                uuId: entityId,
                permissionList: permToUpdate
              }],
              vars: [],
              note: `userUpdatePermissionLink__${entityId}`
            })
          }

          if (idsToRemove.length > 0) {
            requests.push({
              method: 'POST',
              invoke: `/api/user/permission/delete`,
              body: [{ 
                uuId: entityId,
                permissionList: idsToRemove.map(i => { return { uuId: i }})
              }],
              vars: [],
              note: `userRemovePermissionLink__${entityId}`
            })
          }
          ////[End] Update user's permission list with Access policy's permission list
          
          if (requests.length > 0) {
            return {
              value: requests
              , status: 'SUCCESS'
            }
          }
          return abortResponse
        }
      }
    }
  }
  , getPropertyCompatibleFunc: (self) => {
    const _dataGroup = {
      stringGroup: ['firstName', 'lastName', 'identifier']
    }
    return (src, tgt) => {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(_dataGroup)
      for(const key of keys) {
        if (_dataGroup[key].includes(src) && _dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
  }
  , getPropertyDeleteHandler: (/** self */) => {
    return {
      tag: []
    }
  }
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    let maxIdentifierLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
      val = self.modelInfo.filter(info => info.field === 'identifier')
      if (val.length > 0) {
        maxIdentifierLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT (Example can be found in skill.js)
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      color: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.trim().length == 0) {
          value = null
        }
        return { value, status: 'SUCCESS' }
      }
      , lastName: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
      , identifier: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && value.length > maxIdentifierLength) {
          value = srcValue.substring(0, maxIdentifierLength)
        }
        return { value, status: 'SUCCESS' }
      }
      // , email: (srcValue, tgtData) => {
      //   //Prevent copy, paste
      //   return { value: tgtData.email, status: 'SUCCESS' }
      // }
      , accessPolicy: (srcValue, tgtData) => {
        if (srcValue == null || srcValue.uuId == null) {
          return { value: tgtData.accessPolicy, status: 'ABORT' }
        }
        return { value: srcValue, status: 'SUCCESS' }
      }
    }
  }
  , uuIdCellValueChanged: (self, data) => {
    return {
      colId: 'lastName'
      , newValue: data.lastName
      , oldValue: data.oldName //oldName is added in autoGroupColumnDef.valueSetter.

    }
  }
  , customClipboardUuIdCellColId: 'lastName'
  , handleAjaxError: (self, feedbackList) => {
    const foundResult = feedbackList.find(i => (i.clue == 'Not_unique_key' || i.clue == 'Invalid_value') && Array.isArray(i.args) && i.args.length > 0 && i.args[0] == 'email')
    let alertMsg = null
    if (foundResult != null) {
      if (foundResult.clue == 'Not_unique_key') {
        alertMsg = self.$t('error.not_unique_key_with_arg', [self.$t('user.field.email')])
      } else {
        alertMsg = self.$t('error.invalid_value_with_arg', [self.$t('user.field.email')])
      }
    }
    return {
      msg: alertMsg
    }
  } 
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'USER', action: 'VIEW' }], ['uuId', 'lastName'])
  }
  , getCustomName(data) {
    let name = data.firstName != null? data.firstName : ''
    return name + (name.length > 0? ' ' : '') + (data.lastName != null? data.lastName : '')
  }
  , getMandatoryFields() {
    return [
      'firstName', 'lastName', 'email', 'accessPolicy'
    ]
  }
  , syncLdap: () => userService.syncLdap()
  , getBadgeFilterFields: (self) => {
    const fields = [
      { value: 'lastName', text: self.$t('user.field.lastName') }
      , { value: 'firstName', text: self.$t('user.field.firstName') }
      , { value: 'nickName', text: self.$t('user.field.nickName') }
      , { value: 'email', text: self.$t('user.field.email') }
      , { value: 'accessPolicyName', text: self.$t('user.field.accessPolicy') }
      , { value: 'enabled', text: self.$t('field.status') }
      , { value: 'color', text: self.$t('user.field.color') }
      , { value: 'tagName', text: self.$t('field.tag') }
      , { value: 'identifier', text: self.$t('field.identifier') }
    ];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      let f = field;
      if (f == 'tagName') {
        f = 'TAG.name'
      } else if (f == 'accessPolicyName') {
        f = 'ACCESS_POLICY.name'
      }
      return userService.listUniqueValuesOfProperty(f)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            if (field == 'enabled') {
              //Additional property 'value' is added to keep the original value.
              const list = [];
              for (const d of data) {
                const text = typeof d !== 'boolean'? self.$t('string.unknown') : self.$t(`string.${d? 'active' : 'inactive' }`)
                const value = typeof d !== 'boolean'? null : d
                list.push({
                  text
                  , value
                  , checked: found.value.find(j => j.value != null && j.value == value) != null
                })
              }
              if (list.find(i => i.text == '(Empty)') == null) {
                list.unshift({ text: '(Empty)', value: null, checked: false })
              }
              return list
            }
            
            //Normal handling
            const rList = data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null 
                                          && (typeof j.text === 'string' && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) 
                                              || j.text == i) != null
            }))
            if (rList.find(i => i.text == '(Empty)') == null) {
              rList.unshift({ text: '(Empty)', checked: false })
            }
            return rList;
          }
        }
        if (field == 'enabled') {
          //Additional property 'value' is added to keep the original value.
          const list = data.map(i => ({ text: typeof i !== 'boolean'? self.$t('string.unknown') : self.$t(`string.${i? 'active' : 'inactive' }`), value: typeof i === 'boolean' ? i : null, checked: false }))
          if (list.find(i => i.text == '(Empty)') == null) {
            list.unshift({ text: '(Empty)', value: null, checked: false })
          }
          return list;
        }
        
        //Normal handling
        const rList = data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
        if (rList.find(i => i.text == '(Empty)') == null) {
          rList.unshift({ text: '(Empty)', checked: false })
        }
        return rList;
      });
    }
  }
}