var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlankCenterContainer",
    [
      _c(
        "b-col",
        { attrs: { md: "5" } },
        [
          _c("div", { staticClass: "standard-logo-wrapper mx-auto" }, [
            _c("div", {
              staticClass: "standard-logo",
              attrs: {
                id: "logo",
                alt: "Projectal Logo",
                border: "0",
                name: "logo",
              },
            }),
          ]),
          _c(
            "b-card",
            { staticClass: "mx-auto" },
            [
              _c("div", { staticClass: "text-center mb-4" }, [
                _c("h4", [_vm._v(_vm._s(_vm.$t("forgot_password.title")))]),
                _vm.hasEmail
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.$t("forgot_password.title_detail"))),
                    ])
                  : _c("p", [
                      _vm._v(_vm._s(_vm.$t("forgot_password.no_smtp"))),
                    ]),
              ]),
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.errorShow,
                    callback: function ($$v) {
                      _vm.errorShow = $$v
                    },
                    expression: "errorShow",
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "triangle-exclamation"] },
                  }),
                  _vm._v(_vm._s(_vm.$t("error.attention_required")) + " "),
                ],
                1
              ),
              _vm.formState === _vm.ForgotPasswordFormState.INIT && _vm.hasEmail
                ? _c(
                    "b-form",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "envelope"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: _vm.validate },
                                    expression: "{ required: validate }",
                                  },
                                ],
                                staticClass: "last-child form-control",
                                class: { "is-invalid": _vm.hasEmailError },
                                attrs: {
                                  type: "email",
                                  id: "email",
                                  placeholder: "Enter Email Address",
                                  "data-vv-as": _vm.$t(
                                    "forgot_password.field.email"
                                  ),
                                  "data-vv-name": "email",
                                  "data-vv-delay": "500",
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.hasEmailError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " + _vm._s(_vm.errors.first("email")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-btn",
                        {
                          staticClass: " btn-primary btn-block",
                          attrs: { disabled: _vm.isSubmitDisabled },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resetPassword.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.reset_password")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formState === _vm.ForgotPasswordFormState.SUBMIT_SUCCESS
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-success text-center",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("forgot_password.success")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.formState === _vm.ForgotPasswordFormState.SUBMIT_FAILURE
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-danger text-center",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("forgot_password.failure")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.formState ===
              _vm.ForgotPasswordFormState.SUBMIT_FAILURE_TOO_MANY
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-danger text-center",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("forgot_password.failure_too_many", [
                              _vm.retryAfter,
                            ])
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "d-block small mt-3",
                      attrs: { to: "/login" },
                    },
                    [_vm._v(_vm._s(_vm.$t("link.login_page")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "auth-footer mt-3 text-center" }, [
            _vm._v(
              _vm._s(
                _vm.$t("footer_disclaimer", [
                  new Date().getFullYear(),
                  _vm.versionNumber,
                ])
              )
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }