<template>
  <div>
    <ResourceSelectorModal :show.sync="resourceSelectorShow" @cancel="resourceSelectorCancel" @ok="resourceSelectorOk" :allowNone="true" :taskUuId="null" :selectedResource="resourceUuids" :enablePagination="false" @delete="resourceSelectorDeleted"/>
    <template v-for="(item, index) in toConfirmResources">
      <ResourceUnitModal 
        :key="`resource-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmResources.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :unit="parseInt(item.unit)" 
        :utilization="parseFloat(item.utilization)" 
        :cData="item"
        :edgeName="edgeName" 
        @ok="toConfirmResourceOk" 
        @cancel="toConfirmResourceCancel"/>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';
export default Vue.extend({
  name: 'ResourceEditor',
  components: {
    ResourceSelectorModal: () => import('@/components/modal/ResourceSelectorModal'),
    ResourceUnitModal: () => import('@/components/modal/ResourceUnitModal')
  },
  data() {
    return {
      value: '',
      resourceSelectorShow: true,
      resourceUuids: [],
      toConfirmResources: [],
      compactMode: false,
      edgeName: 'TASK-RESOURCE'
    };
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) || 
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (let i = 0, len = orgValue.length; i < len; i++) {
          const found = curValue.find(v => v.uuId == orgValue[i].uuId);
          if (found == null || found.unit != orgValue[i].unit || found.utilization != orgValue[i].utilization) {
            hasChanged = true;
            break;
          }
        }
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
      
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    resourceSelectorOk({ details }) {
      
      const newResources = details.map(i => { 
        const found = this.value.find(j => j.uuId == i.uuId)
        return { 
          uuId: i.uuId
          , name: i.name
          , unit: found != null && found.unit != null? found.unit : null
          , utilization: found != null && found.utilization != null? found.utilization : null
          , show: false
        }
      });
      this.toConfirmResources.splice(0, this.toConfirmResources.length, ...newResources);
      if (this.toConfirmResources.length === 0) {
        this.value = [];
        this.closePicker();
      }
      else {
        for(let i = this.toConfirmResources.length-1; i > -1; i--) {
          this.toConfirmResources[i].toBeShown = true;
        }
        this.toConfirmResources[this.toConfirmResources.length - 1].toBeShown = false;
        this.toConfirmResources[this.toConfirmResources.length - 1].show = true;
      }
    },
    toConfirmResourceOk({ id, unit, utilization, applyToAll, customData, customFields }) {
      const selected = this.toConfirmResources[this.toConfirmResources.findIndex(i => i.uuId === id)]
      selected.unit = unit;
      selected.utilization = utilization;
      for (const f of customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }
      const toBeShown = this.toConfirmResources.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmResourceCommit(customFields);
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.unit = unit;
          entry.utilization = utilization;
          for (const f of customFields) {
            if (Object.hasOwn(customData, f.name)) {
              entry[f.name] = customData[f.name];
            }
          }
        }
        this.toConfirmResourceCommit(customFields);
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmResourceCancel() {
      this.toConfirmResourceCommit();
    },
    toConfirmResourceCommit(customFields=[]) {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmResources.length; i < len; i ++) {
        this.toConfirmResources[i].show = false;
      }
      const newResources = this.toConfirmResources.filter(i => i.unit != null);
      this.value = newResources.map(i => { 
        const r = { uuId: i.uuId, name: i.name, unit: i.unit, utilization: i.utilization}
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            r[f.name] = i[f.name];
          }
        }
        return r;
      });
      this.closePicker();
    },
    resourceSelectorCancel() {
      if (this.toConfirmResources.length === 0) {
        this.closePicker();
      }
    },
    getUuids() {
      if(this.value != null) {
        this.resourceUuids = this.value.map(i => i.uuId);
      }
    },
    resourceSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    if (this.params.edgeName != null) {
      this.edgeName = this.params.edgeName;
    }
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.value = this.compactMode? objectClone(this.params.value.single) : this.params.value ? this.params.value : [];
    this.getUuids();
    
  }
})
</script>