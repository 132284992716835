<template>
  <div class="position-relative">
    <!-- Gantt Chart -->
    <div ref="gantt_chart" id="dhtmlx_gantt_container" :style="ganttHeight"></div>

    <!-- Task Tooltip (shown in task dragging event) -->
    <div :style="{ top: `${tooltipTop}px`, left: `${tooltipLeft}px`, visibility: taskTooltip.show? 'visible' : 'hidden' }" class="task-tooltip" style="background-color: white;" role="dialog" aria-label="Tooltip">
      <div class="header-box">
        <div class="tag tag-yellow">{{ taskTooltip.taskType == 2? $t('label.milestone') : (taskTooltip.taskType == 1? $t('label.summary_task') : taskTooltip.taskType == 3 ? $t('label.project') : $t('label.task')) }}</div>
        <div class="header">{{ taskTooltip.name != null? taskTooltip.name : $t('label.not_available') }}</div>
      </div>
      <div>
        <p v-if="taskTooltip.taskType == 2"><span>{{ $t('label.date') }}: </span><span>{{ taskTooltip.startDateStr != null? taskTooltip.startDateStr : $t('label.not_available') }}</span></p>
        <template v-else>
          <p><span>{{ $t('task.field.startTime') }}: </span><span>{{ taskTooltip.startDateStr != null? taskTooltip.startDateStr : $t('label.not_available') }}</span></p>
          <p><span>{{ $t('task.field.closeTime') }}: </span><span>{{ taskTooltip.endDateStr != null? taskTooltip.endDateStr : $t('label.not_available') }}</span></p>
          <p><span>{{ $t('task.field.durationAUM') }}: </span><span>{{ taskTooltip.durationDisplay != null? taskTooltip.durationDisplay: $t('label.not_available') }}</span></p>
          <p v-if="taskTooltip.progress"><span>{{ $t('project.display.progress') }}: </span><span>{{ taskTooltip.progress != null? taskTooltip.progress: $t('label.not_available') }}</span></p>
          <p v-if="taskTooltip.status"><span>{{ $t('project.field.status') }}: </span><span>{{ taskTooltip.status != null? taskTooltip.status: $t('label.not_available') }}</span></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Emit Event:
 * 1. 'taskNew' with parameter: { parentId }
 * 2. 'taskEdit' with parameter: { uuId: id, parentId }
 * 3. 'taskLinkEdit' with parameter: {  taskId, predecessorId } Refer #Register 'taskLinkEdit' emit event
 */

//Vendor (Script) Library 
// DHTMLx
import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_critical_path';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_auto_scheduling';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_multiselect';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_csp';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

//Vendor (Script) Library
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { debounce } from 'lodash';

//Helper Script
import { ganttEventUtil } from './script/helper-gantt-event';
import { ganttConfigUtil } from './script/helper-gantt-config';
import { ganttTemplateUtil } from './script/helper-gantt-template';

import { ganttLayoutUtil } from './script/helper-gantt-layout-with-no-grid';
import { ganttScaleUtil } from './script/helper-gantt-scale';
import { EventBus } from '@/helpers';

export default {
  name: 'DhtmlxGantt'
  , props: {
    ganttData: {
      type: Object
      , default : () => {
        return {
          data: []
          //, collections: { links: [] }
        }
      }
    }
    , router: {
      type: Function
      , required: true
      // Sample format: More details: https://docs.dhtmlx.com/gantt/api__gantt_createdataprocessor.html
      // { 
      //   task: {
      //     create: function(data) {},
      //     update: function(data, id) {},
      //     delete: function(id) {}
      //   },
      //   link: {
      //     create: function(data) {},
      //     update: function(data, id) {},
      //     delete: function(id) {}
      //   }
      // }
    }
    , startDate: {
      type: String
      , default: null
    }
    , endDate: {
      type: String
      , default: null
    }
    , timescale: {
      type: String
      , default: 'week'
    }
    , criticalPath: {
      type: Boolean
      , default: false
    }
    , autoSchedule: {
      type: Boolean
      , default: false
    }
    , freeFloat: {
      type: Boolean
      , default: false
    }
    , pendingRerender: { //This prop is used to force gantt rerender when it is true. After rerender, reset to false.
      type: Boolean
      , default: false
    }
    , markerStartDate: {
      type: String
      , default: null
    }
    , markerEndDate: {
      type: String
      , default: null
    }
    , showMarkerStartText: { //Flag to determine whether to show text for marker
      type: Boolean
      , default: false
    }
    , showMarkerEndText: { //Flag to determine whether to show text for marker
      type: Boolean
      , default: false
    }
    , showMarkerTodayText: { //Flag to determine whether to show text for marker
      type: Boolean
      , default: false
    }
    , scrollY: {
      type: Number
      , default: -1
    }
    , scrollX: {
      type: Number
      , default: -1
    }
    , collapseId: {
      type: String
      , default: null
    }
    , expandId: {
      type: String
      , default: null
    }
    , collapseIds: {
      type: Array
      , default: () => []
    }
    , expandIds: {
      type: Array
      , default: () => []
    }
    , selectedTasks: {
      type: Array
      , default: () => []
    }
    , filteredIds: {
      type: Array
      , default: null
    }
    , readOnly: {
      type: Boolean
      , default: false
    }
    , calendar: {
      type: Object
      , default: () => {}
    }
    , toDeleteTaskIds: {
      type: Array
      , default: () => []
    }
    , applyGanttMarker: {
      type: Boolean
      , default: false
    }
    // Unchangable Props. They will be used in initialization, but further changes will be ignored.
    , heightOffset: {
      type: Number
      , default: 236
    }
    , workTime: {
      type: Array
      , default: () => [
        { day: 1, hours: [9, 17] }
        , { day: 2, hours: [9, 17] }
        , { day: 3, hours: [9, 17] }
        , { day: 4, hours: [9, 17] }
        , { day: 5, hours: [9, 17] }
        , { day: 6, hours: false }
        , { day: 0, hours: false }
      ]
    }
    , optionsHr: {
      type: Array,
      default: () => []
    }
    , enableProjectColumn: {
      type: Boolean
      , default: false
    }
    , enableTaskPathColumn: {
      type: Boolean
      , default: false
    }
    , height: {
      type: Number
      , default: -1
    }
    , taskProjectDetails: {
      type: Array
      , default: null
    }
    , locationCalendarMap: {
      type: Map
      , default: null
    }
    , showPadlock: {
      type: Boolean
      , default: true
    }
    , defaultColoring: {
      type: Boolean
      , default: true
    }
    , rowHeight: {
      type: Number
      , default: -1
    }
    , useCase: {
      type: String
      , default: null
      // Possible values: [null, 'project-tiles']
    }
    // Unchangable props end
    
  }
  , data() {
    return {
      taskTooltip: {
        show: false
        , taskId: null
        , name: null
        , startDateStr: null
        , endDateStr: null
        , durationDisplay: null
        , taskType: 0
        , posX: 100
        , posY: 100
      }
      , isTaskDragging: false
      , taskTooptipWidth: 150 //default
      , taskTooltipHeight: 99 //default
      , tooltipLeft: -1
      , tooltipTop: -1
      , escapeKeyPressed: false

      , isFocused: false
      , isReadOnly: false

      , taskSelection: {}
      , timeoutHandler: null
    }
  }  
  , created() {
    //Declare non reactive variable
    this.startMarkerId = null;
    this.endMarkerId = null;
    this.todayMarkerId = null;

    // eslint-disable-next-line no-undef
    this.gantt = Gantt.getGanttInstance();
    this.$emit('ganttCreated', this.gantt);
    
    this.paramTaskId = this.$route.query.tId;
    this.paramTaskDialogOpen = this.$route.query.tdOpen;
    
    EventBus.$on('theme-change', () => {
      if (this.gantt) {
        setTimeout(() => {
          this.gantt.render();
        }, 100);
      }
    });
  }
  , mounted() {
    this.$el.addEventListener('mouseenter', this.mouseEnterHandler);
    this.$el.addEventListener('mouseleave', this.mouseLeaveHandler);

    const self = this;
    const g = this.gantt;
    this.isReadOnly = this.readOnly;
    g.config.drag_links = !this.readOnly;
    // console.log('dhtmlx rowHeight', this.rowHeight)
    // g.config.row_height = 60;
    // // if (this.rowHeight > -1) {
    // //   g.config.row_height = this.rowHeight;
    // // }
    this.setTaskClassTemplate(g, this.readOnly);
    this.setTaskTextTemplate(g, this.readOnly && this.showPadlock);
    
    ganttConfigUtil.create(g, (g) => {
      //Set every day as working days
      for(let i = 0, len = self.workTime.length; i < len; i++) {
        g.setWorkTime(self.workTime[i]);  
      }
       // g.config.row_height = 60;
      if (this.rowHeight > -1) {
        g.config.row_height = self.rowHeight;
      }
    });

    // Register markers
    this.registerGanttMarkers();

    // Register highlight effect when task id parameter is provided in URL
    ganttEventUtil.highlightTask(g, self, this.$route.query.tdOpen || false);
    
    // Apply custom templates for Gantt data manipulation or display label.
    ganttTemplateUtil.create(g, this);

    // Register Task Dialog Event Handler
    // Emit 'taskNew' event.
    g.attachEvent('onTaskCreated', function(task){
      self.$emit('taskNew', { 
        parentId: task.parent? task.parent : null
      });
      return false; // Return false to avoid default action
    });

    // Emit 'taskEdit' event.
    g.attachEvent('onBeforeLightbox', function(id) {
      const task = g.getTask(id);
      self.$emit('taskEdit', { 
        uuId: id
        , parentId: task.parent? task.parent : null
      });
      return false; // Return false to avoid default action
    });

    // Register Task Link Dialog Event Handler. Emit 'taskLinkEdit' event.
    g.attachEvent('onLinkDblClick', function(id){
      const link = g.getLink(id);
      self.$emit('taskLinkEdit', { 
        taskId: link.target
        , predecessorId: link.source
      });
      return false; // Return false to avoid default action
    });

    g.attachEvent('onGanttScroll', function (/* left  top */){ 
      const scrollState = this.getScrollState(); //The parameters left, top do not reflect the latest value. Use gantt.getScrollState() instead.
      self.$emit('ganttScroll', { left: scrollState.x, top: scrollState.y });
    });

    // NOTE: We do not currently support linking tasks across projects.
    // We will prevent cross-project links here until it is implemented
    g.attachEvent('onLinkValidation', function(link){
      let target = g.getTask(link.target);
      let source  = g.getTask(link.source);
      if (source.projId != target.projId) {
        return false;
      }
      return true;
    });

    g.attachEvent('onMarkerClicked', function(id, state) {
      if (self.startMarkerId == id) {
        self.$emit('markerChangedStart', state);
      } else if (self.endMarkerId == id) {
        self.$emit('markerChangedEnd', state);
      } else if (self.todayMarkerId == id) {
        self.$emit('markerChangedToday', state);
      }
    });

    
    g.attachEvent('onBeforeTaskDisplay', function (id) {
      if (self.filteredIds != null) {
        let _filteredIds = self.filteredIds;
        
        const isFiltered = (parent) => {
          if (_filteredIds.includes(g.getTask(parent).id)) {
            return true;
          }

          const child = g.getChildren(parent);
          for (let i = 0, len = child.length; i < len; i++) {
            if (isFiltered(child[i])) {
              return true;
            }
          }
          return false;
        }

        if (isFiltered(id)) {
          return true;
        } 
        return false;
      }
      //When filteredIds is null, it means no filter in placed. Display all.
      return true;
    });

    //Project-tiles's task bar color styling
    if (self.useCase == 'project-tiles') {
      const setColoringCssVariables = function() {
        const triggeredDate = self.lastTriggeredDate = new Date().valueOf();
        if (self.defaultColoring) {
          return;
        }
        let tasks = g.serialize();
        if (tasks.data == null) {
          return;
        }
        tasks = tasks.data;
        for (let i = 0, len = tasks.length; i < len; i++) {
          if (self.lastTriggeredDate != triggeredDate) {
            break;
          }
          const curTask = tasks[i];
          const taskNode = g.getTaskNode(curTask.id);
          if (taskNode && taskNode.style != null && taskNode.classList != null && !taskNode.classList.contains('coloring-in-place')) {
            taskNode.classList.add('coloring-in-place');
            taskNode.style.setProperty('--coloring-palette-bg-color', curTask.color != null ? curTask.color : '#fff');
            taskNode.style.setProperty('--coloring-palette-color', curTask.textColor != null ? curTask.textColor : '#000');
            taskNode.style.setProperty('--coloring-palette-border-color', curTask.progressColor != null ? curTask.progressColor : '#7F7F7FAA');
          }
        }
      }
      
      //Record the attached events. Detach these events on beforeDestroy() cycle to avoid gantt throws error.
      if (self.attachedGanttEvents == null) {
        self.attachedGanttEvents = [];
      }
      self.attachedGanttEvents.push(g.attachEvent('onDataRender', debounce(function(){
        setColoringCssVariables();
      }, 100)));

      self.attachedGanttEvents.push(g.attachEvent('onGanttScroll', debounce(function(){
        setColoringCssVariables();
      }, 0)));
    }
    

    this.updateScale(this.timescale, false); //Set second param false to avoid excessive gantt re-rendering.

    // Initialize Gantt Layout
    ganttLayoutUtil.create(g);

    g.init(this.$refs.gantt_chart);

    //Load gantt data
    g.parse(this.ganttData);

    //Register router for dataProcessor
    g.createDataProcessor(this.router({ g, self }));

    //Register auto-scheduling
    this.updateAutoSchedule(false, false);


    //Register mouseleave listener
    this.$refs.gantt_chart.addEventListener('mouseleave', this.ganttMouseLeave);

    //Register keydown listener
    document.addEventListener('keydown', this.keyDownHandler);
  }
  , beforeDestroy() {  
    if (this.timeoutHandler != null) {
      clearTimeout(this.timeoutHandler)
    }
    if (this.gantt) {
      //Detach events if exists
      if (this.attachedGanttEvents != null) {
        for (let i = 0, len = this.attachedGanttEvents.length; i < len; i++) {
          this.gantt.detachEvent(this.attachedGanttEvents[i]);
        }
        this.attachedGanttEvents = null;
      }
      this.gantt.destructor();
    }
    this.gantt = null;
    this.startMarkerId = null;
    this.endMarkerId = null;
    this.todayMarkerId = null;

    //Remove mouseleave listener
    this.$refs.gantt_chart.removeEventListener('mouseleave', this.ganttMouseLeave);
    //Remove keydown listener
    document.removeEventListener('keydown', this.keyDownHandler);

    this.$el.removeEventListener('mouseenter', this.mouseEnterHandler);
    this.$el.removeEventListener('mouseleave', this.mouseLeaveHandler);
  }
  , computed: {
    ganttHeight() {
      if(this.height > 0) {
        return `height: ${this.height}px`;
      } else {
        return `min-height: calc(100vh - ${this.heightOffset}px)`;
      }
    }
  },
  watch: {
    ganttData(/* newValue */) {
      const g = this.gantt;
      if(g) {
        g.clearAll();
        g.parse(this.ganttData);
        this.registerGanttMarkers();
      }
    }
    , timescale(newValue) {
      const g = this.gantt;
      if(g) {
        g.config.start_date = moment(this.startDate, 'YYYY-MM-DD').subtract(1, `${newValue}s`).toDate();
        g.config.end_date = moment(this.endDate, 'YYYY-MM-DD').add(1, 'days').add(1, `${newValue}s`).toDate();
      }
      this.updateScale(newValue);
    }
    , criticalPath(newValue) {
      this.updateCriticalPath(newValue);
    }
    , autoSchedule(newValue) {
      this.updateAutoSchedule(newValue);
    }
    , freeFloat(newValue) {
      this.updateSlack(newValue);
    }
    , startDate(newValue) {
      const g = this.gantt;
      if(g) {
        //Subtract 1 unit of current timescale to leave a buffer for task dragging.
        g.config.start_date = moment(newValue, 'YYYY-MM-DD').subtract(1, `${this.timescale}s`).toDate();
      }
    }
    , endDate(newValue) {
      const g = this.gantt;
      if(g) {
        //Add 1 day to make last day inclusive and 1 unit of current scale to leave a buffer for task dragging. 
        g.config.end_date = moment(newValue, 'YYYY-MM-DD').add(1, 'days').add(1, `${this.timescale}s`).toDate();
      }
    }
    , markerStartDate(newValue) {
      this.startMarkerId = this.registerGanttMarker(this.$t('gantt.marker.start'), newValue, { css: 'start_line', icon: 'far fa-backward-step', showText: this.showMarkerStartText });
    }
    , markerEndDate(newValue) {
      this.endMarkerId = this.registerGanttMarker(this.$t('gantt.marker.end'), newValue, { css: 'end_line', icon: 'far fa-forward-step', showText: this.showMarkerEndText });
    }
    , showMarkerStartText(newValue) {
      this.updateGanttMarker(this.startMarkerId, { showText: newValue });
    }
    , showMarkerEndText(newValue) {
      this.updateGanttMarker(this.endMarkerId, { showText: newValue });
    }
    , showMarkerTodayText(newValue) {
      this.updateGanttMarker(this.todayMarkerId, { showText: newValue });
    }
    , pendingRerender(newValue) {
      if(newValue) {
        const g= this.gantt;
        if(g) {
          //Enforce minimum column width (or timeline cell width) to 100
          g.config.min_column_width = 100;
          g.render();
        }
        this.$emit('update:pendingRerender', false);
      }
    }
    , scrollY(newValue) {
      if (this.gantt) {
        const g = this.gantt;
        const { y } = g.getScrollState();
        if (y !== newValue && newValue != -1) {
          g.scrollTo(null, newValue);
        }
      }
    }
    , scrollX(newValue) {
      if (this.gantt) {
        const g = this.gantt;
        const { x } = g.getScrollState();
        if (x !== newValue && newValue != -1) {
          g.scrollTo(newValue, null);
        }
      }
    }
    , collapseId(newValue) {
      if(this.gantt && newValue != null) {
        this.gantt.close(newValue);
      }
    }
    , expandId(newValue) {
      if(this.gantt && newValue != null) {
        this.gantt.open(newValue);
      }
    }
    , collapseIds(newValue) {
      if(this.gantt && newValue != null) {
        for(const value of newValue) {
          const task = this.gantt.getTask(value);
          task.$open = false;
        }
        this.gantt.render();
        if(newValue.length > 0) {
          this.$emit('progress-complete');
        }
      }
    }
    , expandIds(newValue) {
      if(this.gantt && newValue != null) {
        for(const value of newValue) {
          const task = this.gantt.getTask(value);
          task.$open = true;
        }
        this.gantt.render();
        if(newValue.length > 0) {
          this.$emit('progress-complete');
        }
      }
    }
    , selectedTasks: function(newValue) {
      if(!this.isFocused && newValue != null && this.gantt) {
        const oldSelectionIds = this.gantt.getSelectedTasks();
        
        const newSelection = newValue.filter(i => !oldSelectionIds.includes(i));
        const unselected = oldSelectionIds.filter(i => !newValue.includes(i));
        newSelection.forEach(i => {
          this.gantt.selectTask(i);
        });
        unselected.forEach(i => {
          this.gantt.unselectTask(i);
        });
      }
    }
    , readOnly(newValue) {
      this.isReadOnly = newValue;
      if (this.gantt) {
        this.gantt.config.drag_links = !newValue;
        this.setTaskClassTemplate(this.gantt, newValue);
        this.setTaskTextTemplate(this.gantt, newValue);
        this.gantt.render();
      }
    }
    , calendar(newValue) {
      if (this.gantt && newValue != null) {
        //Re-render to redraw the timeline
        this.gantt.render();
      }
    }
    , filteredIds() {
      const g = this.gantt;
      if (g != null) {
        g.render();
      }
    }
    , toDeleteTaskIds(newValue) {
      const g = this.gantt;
      if (g != null && newValue != null && newValue.length > 0) {
        for (const id of newValue) {
          g.deleteTask(id);
        }
      }
    }
    , 'taskTooltip.show': function(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const tooltipElem = document.querySelector('.task-tooltip');
          this.taskTooltipWidth =  tooltipElem.clientWidth;
          this.taskTooltipHeight = tooltipElem.clientHeight;
          const { x, y } = this.calcTooltipPosition(this.taskTooltip.posX, this.taskTooltip.posY);
          if (x != this.taskTooltip.posX) {
            this.$set(this.taskTooltip, 'posX', x);
          }
          if (y != this.taskTooltip.posY) {
            this.$set(this.taskTooltip, 'posY', y);
          }
        });
        
      }
    }
    , 'taskTooltip.posX': function(newValue) {
      const ganttContainer = document.querySelector('#dhtmlx_gantt_container');
      let ganttLeft = 0;
      if (ganttContainer != null) {
        ganttLeft = ganttContainer.getBoundingClientRect().left;
      }
      this.tooltipLeft = newValue - ganttLeft;
    }
    , 'taskTooltip.posY': function(newValue) {
      const ganttContainer = document.querySelector('#dhtmlx_gantt_container');
      let ganttTop = 0;
      if (ganttContainer != null) {
        ganttTop = ganttContainer.getBoundingClientRect().top;
      }
      this.tooltipTop =  newValue - ganttTop;
    }
    , applyGanttMarker: function(newValue) {
      if (newValue) {
        this.registerGanttMarkers();
        this.$emit('update:applyGanttMarker', false);
      }
    }
  },
  methods: {
    updateScale(value, render=true) {
      const g = this.gantt;
      ganttScaleUtil.create(g, value);
      g.config.min_column_width = 100;
      if (render) {
        g.render();
      }
    }
    , updateCriticalPath(value) {
      const g = this.gantt;
      if (value) {
        g.config.highlight_critical_path = true;
      } else {
        g.config.highlight_critical_path = false;
      }
      g.render();
    }
    , updateAutoSchedule(value, render=true) {
      const g = this.gantt;
      // auto-scheduling
      g.config.auto_scheduling = value;
      g.config.auto_scheduling_strict = value;
      g.config.auto_scheduling_compatibility = value;
      if(render) {
        g.render();
      }
      g.autoSchedule();
    }
    , updateSlack(value) {
      const g = this.gantt;
      if (value) {
        g.config.show_slack = true;
      } else {
        g.config.show_slack = false;
      }
      g.render();
    }
    , registerGanttMarker(label, date, { css='status_line', icon, showText=false } = {}) {
      const g = this.gantt;
      if(date && g) {
        const d = moment(date, 'YYYY-MM-DD');
        const payload = {
          start_date: d,
          css: css,
          text: label,
          title: `${label}: ${d.format('YYYY-MM-DD')}`,
          show_text: showText != null? showText:false
        }
        if (icon != null) {
          payload.icon = icon;
        }
        return g.addMarker(payload);
      }
    }
    , registerGanttMarkers() {
      const start = this.markerStartDate;
      if(start) {
        this.startMarkerId = this.registerGanttMarker(this.$t('gantt.marker.start'), start, { css: 'start_line', icon: 'far fa-backward-step', showText: this.showMarkerStartText });
      }

      const end = this.markerEndDate;
      if(end) {
        this.endMarkerId = this.registerGanttMarker(this.$t('gantt.marker.end'), end, { css: 'end_line', icon: 'far fa-forward-step', showText: this.showMarkerEndText });
      }

      const today = moment();
      if(today) {
        this.todayMarkerId = this.registerGanttMarker(this.$t('gantt.marker.today'), today, { icon: 'far fa-play', showText: this.showMarkerTodayText });
      }
    }
    , updateGanttMarker(id, { date, label, icon, showText } = {}) {
      if (id == null) {
        return;
      }
      const g = this.gantt;
      const marker = g.getMarker(id);
      if (marker == null) {
        return null;
      }

      if (date != null) {
        marker.start_date = moment(date, 'YYYY-MM-DD');
        marker.title = `${marker.text}: ${marker.start_date.format('YYYY-MM-DD')}`;
      }
      if (label != null) {
        marker.text = label;
        marker.title = `${marker.text}: ${marker.start_date.format('YYYY-MM-DD')}`;
      }
      if (icon != null) {
        marker.icon = icon;
      }
      if (showText != null) {
        marker.show_text = showText;
      }
      g.updateMarker(id);
    }
    , calcTooltipPosition(x, y) {
      const offsetX = 15; //Keep a distance from cursor.
      const offsetY = 15; //Keep a distance from cursor.
      const reservedBottom = 25; //The gantt container's bottom margin.
      const reservedRight = 25; //The gantt container's right margin.
      const widthBeyondRightScreen = x + offsetX + this.taskTooltipWidth + reservedRight - window.innerWidth;
      const heightBeyondBottomScreen = y + offsetY + this.taskTooltipHeight + reservedBottom - window.innerHeight;
      const rObj = { x: x + offsetX, y: y + offsetY }
      if (widthBeyondRightScreen > 0) {
        rObj.x = rObj.x - widthBeyondRightScreen - (offsetX * 2); // - 2 is avoid overlapping the right border. Reason: Look nicer.
      }
      if (heightBeyondBottomScreen > 0) {
        rObj.y = rObj.y - this.taskTooltipHeight - (offsetY * 2);
      }
      return rObj;
    }
    , ganttMouseLeave(/**e */) {
      if (this.taskTooltip.show) {
        this.hideTooltip();
      }
    }
    , hideTooltip() {
      const tooltipObj = this.taskTooltip;
      tooltipObj.show = false;
      tooltipObj.taskId = null;
      tooltipObj.name = null;
      tooltipObj.startDateStr = null;
      tooltipObj.endDateStr = null;
      tooltipObj.durationDisplay = null;
      tooltipObj.taskType = null;
      tooltipObj.posX = -1;
      tooltipObj.posY = -1;
    }
    , keyDownHandler(e) {
      if (e.key == 'Escape' && this.isTaskDragging) {
        this.escapeKeyPressed = true;
      }
    }
    , mouseEnterHandler(/**e*/) {
      this.isFocused = true;
    }
    , mouseLeaveHandler(/**e*/) {
      this.isFocused = false;
    }
    , setTaskClassTemplate(g, isReadOnly) {
      const self = this;
      g.templates.task_class = function (start, end, task) {
        let styleClass = '';
        let textSpace = '';
        if (isReadOnly) {
          styleClass = 'read-only';
          textSpace = ' ';
        } 
        if (task.type == g.config.types.janusks_project) {
          return styleClass += textSpace + 'gantt_project';
        }
        if (self.useCase == 'project-tiles' && task.type === 3/* project */) {
          if (self.defaultColoring) {
            return styleClass += self.getProgressColorClass(task.progress);
          } else {
            return styleClass += ' coloring_enabled';
          }
        }
        // if (task.type === 3/* project */ &&
        //     self.defaultColoring) {
        //   return styleClass += self.getProgressColorClass(task.progress);
        // }
        if (!task.auto_scheduling) {
          return styleClass += textSpace + 'manual';
        }
        return styleClass;
      }
    }
    , setTaskTextTemplate(g, isReadOnly) {
      g.templates.task_text = function (start, end, task) {
        if (isReadOnly) {
          return `<i class='readonly-icon far fa-lock'></i><span>${task.text}</span>`;
        } else {
          return `<span>${task.text}</span>`;
        }
      }
    }
    , getProgressColorClass(progress) {
      progress = (progress * 100).toFixed(0);
      if (progress == 0) {
        return " progress0";
      } else if (progress <= 25) {
        return " progress25";
      } else if (progress <= 50) {
        return " progress50";
      } else if (progress <= 75) {
        return " progress75";
      } else if (progress <= 99) {
        return " progress99";
      } else if (progress == 100) {
        return " progress100";
      }
      else return "";
    }
  }
}
</script>

<style lang="scss">
.gantt_marker > .gantt_marker_content {
  position: relative;
  line-height: 15px;
  padding: 0px;
}

.gantt_marker_content > span.marker_text  {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 3px;
  display: inline-block;
  background-color: rgba(0,0,0,0.4);
  line-height: 13px;
}

.gantt_marker_content > span:first-of-type {
  padding-left: 20px;
  background-color: inherit;
  padding-top: 3px;
  padding-bottom: 3px;
  float: left;
  display: block;
  height: 18px;
}

.gantt_marker_content > i, .gantt_marker_content > svg {
  position: absolute;
  top: 3px;
  left: 6px;
}

.task-tooltip-container {
  padding: 0;
  border: 0;
}

.gantt_tooltip {
  padding: 0;
  border: 0;
  border-radius: 0.25rem;
  background-color: transparent;
  box-shadow: none;
}

.task-tooltip {
  position: absolute;
  width: max-content;
  min-width: 150px;
  // overflow: hidden;
  box-shadow: var(--surface-shadow);
  border-radius: 0.25rem;
  pointer-events: none;
  transition: opacity 1s;
  background-color: var(--popover-body-bg) !important;
  color: var(--popover) !important;
  font-size: 12px;
  z-index: 9999;

  p {
    margin: 5px;
    white-space: nowrap;
  }

  .tag {
    display: flex;
    vertical-align: center;
    border-radius: 3px;
    color: var(--white);
    font-size: 0.8em;
    padding: 0 5px 0 5px;
    height: 15px;
    line-height: 15px;
    margin-right: 3px;
    margin-left: 4px;
  }

  .header {
    vertical-align: center;
    margin-right: 3px;
  }

  .header-box {
    background-color: var(--gray-500);
    color: var(--white);
    border-bottom: 1px solid var(--border-medium);
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .tag-yellow {
    background-color: var(--yellow);
    color: var(--black);
  }
}

.gantt_task_line.progress0,
.progress0 .gantt_task_progress,
.progress0 .gantt_task_content {
  background-color: var(--timeline-0-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-0);
}
.gantt_task_line.progress25,
.progress25 .gantt_task_progress,
.progress25 .gantt_task_content {
  background-color: var(--timeline-25-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-25);
}
.gantt_task_line.progress50,
.progress50 .gantt_task_progress,
.progress50 .gantt_task_content {
  background-color: var(--timeline-50-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-50);
}
.gantt_task_line.progress75,
.progress75 .gantt_task_progress,
.progress75 .gantt_task_content {
  background-color: var(--timeline-75-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-75);
}
.gantt_task_line.progress99,
.progress99 .gantt_task_progress,
.progress99 .gantt_task_content {
  background-color: var(--timeline-99-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-99);
}
.gantt_task_line.progress100,
.progress100 .gantt_task_progress,
.progress100 .gantt_task_content {
  background-color: var(--timeline-100-light) !important;
  color: var(--black) !important;
  border: 1px solid var(--timeline-100);
}
</style>