import axios from 'axios';
import https from 'https';
import { cloneDeep } from 'lodash';

const httpAjax = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  withCredentials: true,// Set true when need to include credential in header
});


httpAjax.interceptors.response.use(undefined, (error) => {
  // Handle unauthorized errors with a logout event
  if (error.response && error.response.status === 401) {
    const originUrl = cloneDeep(window.App.$router.currentRoute.path);
    if('/login' != originUrl) {
      window.App.$store.dispatch('authentication/logout');
      window.App.$router.push({ path:'/login', query: { RelayState: originUrl } });
    }
  }

  // Otherwise handle non-authentication errors normally
  return new Promise((resolve, reject) => {
    reject(error);
  });
});

export default httpAjax;