<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { convertDurationToDisplay } from '@/helpers/task-duration-process';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'DurationCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    label() {
      let value = this.params.value;
      if (typeof value !== 'undefined') {
        const hours = this.params.context.componentParent[this.params.alloc].endsWith('hours');
        if (hours) {
          value = Math.round(this.params.value) + 'h';
        } else {
          value = `${this.params.value / 8}D`;
        }
      }
      return value? value : '';
    }
  }
})
</script>
