var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlankCenterContainer",
    [
      _c(
        "b-col",
        { attrs: { md: "5" } },
        [
          _c("div", { staticClass: "standard-logo-wrapper mx-auto" }, [
            _c("div", {
              staticClass: "standard-logo",
              attrs: {
                id: "logo",
                alt: "Projectal Logo",
                border: "0",
                name: "logo",
              },
            }),
          ]),
          _c("b-card", { staticClass: "mx-auto" }, [
            _vm.projectalLogin
              ? _c(
                  "div",
                  [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              dismissible: "",
                              fade: "",
                              show: _vm.alertShow,
                              variant: _vm.alert.type,
                            },
                            on: { dismissed: _vm.dismissAlert },
                          },
                          [
                            _vm.alert.type === "danger"
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                })
                              : _vm._e(),
                            _vm.alert.type === "success"
                              ? _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "check"] },
                                })
                              : _vm._e(),
                            _vm._v("   " + _vm._s(_vm.alert.message) + " "),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "user"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.username,
                                      expression: "username",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: _vm.validate },
                                      expression: "{ required: validate }",
                                    },
                                  ],
                                  staticClass: "last-child form-control",
                                  class: {
                                    "is-invalid": _vm.showUsernameError,
                                  },
                                  attrs: {
                                    type: "email",
                                    id: "username",
                                    placeholder: "Enter Email Address",
                                    "data-vv-as": _vm.$t(
                                      "login.field.email_address"
                                    ),
                                    "data-vv-name": "username",
                                    "data-vv-delay": "500",
                                  },
                                  domProps: { value: _vm.username },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.username = $event.target.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showUsernameError },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "  " +
                                    _vm._s(_vm.errors.first("username")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "lock"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.password,
                                      expression: "password",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: _vm.validate },
                                      expression: "{ required: validate }",
                                    },
                                  ],
                                  staticClass: "last-child form-control",
                                  class: {
                                    "is-invalid": _vm.showPasswordError,
                                  },
                                  attrs: {
                                    type: "password",
                                    id: "password",
                                    placeholder: "Enter Password",
                                    "data-vv-as": _vm.$t(
                                      "login.field.password"
                                    ),
                                    "data-vv-name": "password",
                                    "data-vv-delay": "500",
                                  },
                                  domProps: { value: _vm.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.password = $event.target.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "alert-danger form-field-alert",
                                class: { "d-block": _vm.showPasswordError },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "circle-exclamation"],
                                  },
                                }),
                                _vm._v(
                                  "    " +
                                    _vm._s(_vm.errors.first("password")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-block",
                            attrs: { disabled: _vm.isSubmitDisabled },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleSubmit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.submitting
                                    ? "button.logging_in"
                                    : "button.login"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "d-block small mt-3",
                            attrs: { to: "/forgot/password" },
                          },
                          [_vm._v(_vm._s(_vm.$t("link.forgot_password")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.ssoProviders.length > 0
              ? _c(
                  "div",
                  {
                    class: _vm.projectalLogin
                      ? "sso-container"
                      : "sso-only-container",
                  },
                  [
                    _vm.projectalLogin
                      ? _c("div", { staticClass: "horizontal-divider" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("login.or_login_with"))),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "sso-group" },
                      [
                        _vm._l(_vm.ssoProviders, function (provider, key) {
                          return [
                            _c(
                              "button",
                              {
                                key: key,
                                staticClass:
                                  "btn btn-secondary btn-block position-relative",
                                on: {
                                  click: function ($event) {
                                    return _vm.ssoLogin(provider.login)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "sso-provider-icon",
                                  style: {
                                    backgroundImage:
                                      "url(" + provider.icon + ")",
                                  },
                                }),
                                _vm._v(_vm._s(provider.name)),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "auth-footer mt-3 text-center" }, [
            _vm._v(
              _vm._s(
                _vm.$t("footer_disclaimer", [
                  new Date().getFullYear(),
                  _vm.versionNumber,
                ])
              )
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }