<template>
  <div ref="aggrid-scheduler-container" class="position-relative">
    <b-alert dismissible fade :variant="alertError? 'danger':'success'" :show="showError" @dismissed="dismissAlert" class="mb-0">
      <font-awesome-icon :icon="alertError? 'exclamation-triangle' : 'check'"/>&nbsp;&nbsp;{{ alertMsg }}
      <ul :show="showErrorDetail" class="mb-0">
        <template v-for="(item, index) in alertMsgDetails">
          <li :key="index">{{ item }}</li>
        </template>
      </ul>
    </b-alert>
    
    <div v-if="!isWidget" class="staff-action-bar border-part">
      <PriorityNavigation class="grid-toolbar"
        :dropDownStayOpened="badgeFilterModalOpened != 'close'" 
        :closeDropdown.sync="closePriorityNavDropdown" 
        @[priorityNavMouseOverEvent].native="onPriorityNavMouseOverOrTouchEnd"
        @[priorityNavTouchEndEvent].native="onPriorityNavMouseOverOrTouchEnd"
      >
        <li>
          <span class="d-flex ml-2 mr-2">
            <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
            <b-form-select id="dates" v-model="dates" :options="dateOptions" @change="rangeSelected()" class="mw-150 minw-170" size="sm"></b-form-select>
          </span>
        </li>
        <li>
          <span class="d-flex mr-1 date start-date-elevation">
            <label class="mr-1 align-self-baseline" for="startDate">{{ $t('staff.from') }}</label>
            <b-form-datepicker id="staffUsageStartDate" v-model="startDate" class="date-picker d-flex" @input="dateChanged"
                today-button
                reset-button
                close-button
                hide-header
                :label-today-button="$t('date.today')"
                :label-reset-button="$t('date.reset')"
                :label-close-button="$t('date.close')"
                today-button-variant="primary"
                reset-button-variant="danger" 
                close-button-variant="secondary"
                size="sm"
              >
                <template v-slot:button-content="{ }">
                  <font-awesome-icon :icon="['far', 'calendar-days']" />
                </template>
              </b-form-datepicker>
          </span>
        </li>
        <li>
          <span class="d-flex mr-1 date">
            <label class="mr-1 align-self-baseline" for="endDate">{{ $t('staff.to') }}</label>
            <b-form-datepicker id="staffUsageEndDate" v-model="endDate" class="date-picker d-flex" @input="dateChanged"
                today-button
                reset-button
                close-button
                hide-header
                :label-today-button="$t('date.today')"
                :label-reset-button="$t('date.reset')"
                :label-close-button="$t('date.close')"
                today-button-variant="primary"
                reset-button-variant="danger" 
                close-button-variant="secondary"
                size="sm"
              >
                <template v-slot:button-content="{ }">
                  <font-awesome-icon :icon="['far', 'calendar-days']" />
                </template>
              </b-form-datepicker>
          </span>
        </li>
        <li>
          <b-btn :id="`BTN_REFRESH_${id}`" class="ml-1" @click="daySelected" :pressed.sync="highlightRefresh">
            <font-awesome-icon :icon="['far', 'arrows-rotate']"/>
            <b-popover
              :target="`BTN_REFRESH_${id}`"
              placement="top"
              boundary="viewport"
              triggers="hover"
              :content="$t('button.refresh')">
            </b-popover>
          </b-btn>
        </li>
        <!--<li>
          <b-btn :id="`BTN_UNALLOCATED_${id}`" @click="showUnallocated" class="ml-1" :class="show.unallocated ? 'active' : ''">
            <font-awesome-icon :icon="['far', 'calendar-days']"/>
            <b-popover
              :target="`BTN_UNALLOCATED_${id}`"
              placement="top"
              boundary="viewport"
              triggers="hover"
              :content="$t('button.unallocated')">
            </b-popover>
          </b-btn>
        </li>-->
        <li>
          <span class="d-flex mr-1">
            <label class="mr-1" for="timescale">{{ $t('staff.timescale') }}</label>
            <b-form-select id="timescale" v-model="span" :options="spanOptions" class="mw-150" size="sm"></b-form-select>
          </span>
        </li>
        <li @[groupingMouseEnterEvent]="onGroupingOver" @mouseleave="onGroupingLeave">
          <b-dropdown :id="`BTN_GROUPING_${id}`" ref="grouping" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'list-tree']"/>
            </template>
            <b-dropdown-group :header="$t('groupby')">
              <b-dropdown-item v-if="canView('COMPANY')" @click="onGroupChange('company', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'building']"/>{{ $t('staff.group.company') }}</span><font-awesome-icon class="active-check" v-if="grouping.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('LOCATION')" @click="onGroupChange('location', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'map-marked-alt']"/>{{ $t('staff.group.location') }}</span><font-awesome-icon class="active-check" v-if="grouping.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('STAGE')" @click="onGroupChange('stage', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'signs-post']"/>{{ $t('staff.group.stage') }}</span><font-awesome-icon class="active-check" v-if="grouping.stage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('PROJECT')" @click="onGroupChange('project', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'chart-network']"/>{{ $t('staff.group.project') }}</span><font-awesome-icon class="active-check" v-if="grouping.project" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('DEPARTMENT')" @click="onGroupChange('department', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'sitemap']"/>{{ $t('staff.group.department') }}</span><font-awesome-icon class="active-check" v-if="grouping.department" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('SKILL') && canView('STAFF', ['SKILL'])" @click="onGroupChange('skills', true)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'head-side-brain']"/>{{ $t('staff.group.skill') }}</span><font-awesome-icon class="active-check" v-if="grouping.skills" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('stafftype')">
              <b-dropdown-item @click="onGroupChange('staff', false)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'user-hard-hat']"/>{{ $t('staff.group.staff') }}</span><font-awesome-icon class="active-check" v-if="grouping.staff" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onGroupChange('showGeneric', false)" href="#">
                <span class="action-item-label"><font-awesome-icon class="mr-1 grouping-icon" :icon="['far', 'users']"/>{{ $t('staff.generic_staff') }}</span><font-awesome-icon class="active-check" v-if="showGeneric" :icon="['far', 'check']"/>
              </b-dropdown-item>
              
            </b-dropdown-group>
          </b-dropdown>
        </li>
        <li @[allocMouseEnterEvent]="onAllocOver" @mouseleave="onAllocLeave">
          <b-dropdown :id="`BTN_ALLOC_${id}`" ref="alloc" class="alloc-dropdown action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'clock']"/>
            </template>
            <b-dropdown-group :header="$t('show')">
              <b-dropdown-item @click="onShowChange('usage')" href="#">
                <span class="action-item-label">{{ $t('staff.usage') }}</span><font-awesome-icon class="active-check" v-if="show.usage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('BOOKING')" @click="onShowChange('booking')" href="#">
                <span class="action-item-label">{{ $t('booking.title') }}</span><font-awesome-icon class="active-check" v-if="show.booking" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('ACTIVITY')" @click="onShowChange('activity')" href="#">
                <span class="action-item-label">{{ $t('activity.title') }}</span><font-awesome-icon class="active-check" v-if="show.activity" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item v-if="canView('TASK')" @click="onShowChange('task')" href="#">
                <span class="action-item-label">{{ $t('task.title') }}</span><font-awesome-icon class="active-check" v-if="show.task" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onShowChange('vacation')" href="#">
                <span class="action-item-label">{{ $t('vacation.title') }}</span><font-awesome-icon class="active-check" v-if="show.vacation" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('units')">
              <li data-v-05ab69f6="" role="presentation" @[costMouseEnterEvent]="onCostOver" @mouseleave="onCostLeave" @click="onCostClick">
                <a role="menuitem" href="#" target="_self" class="dropdown-item">
                  <span class="action-item-label">{{ $t('button.cost') }}</span><font-awesome-icon class="menu-chevron" :icon="['far', 'chevron-right']"/>
                  <div ref="cost" class="sub-menu-dropdown">
                    <b-dropdown-item v-if="canView('TASK', ['fixedCost']) || canView('BOOKING', ['fixedCost']) || canView('ACTIVITY', ['fixedCost'])" @click="staffAlloc = 'fixedcost'" href="#">
                      <span class="action-item-label">{{ $t('button.fixed_cost') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'fixedcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['fixedCostNet']) || canView('BOOKING', ['fixedCost']) || canView('ACTIVITY', ['fixedCost'])" @click="staffAlloc = 'fixedcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.fixed_cost_net') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'fixedcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('STAFF', ['payAmount', 'payFrequency', 'payCurrency'])" @click="staffAlloc = 'estcost'" href="#">
                      <span class="action-item-label">{{ $t('button.estcost') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'estcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('STAFF', ['payAmount', 'payFrequency', 'payCurrency']) && canView('REBATE')" @click="staffAlloc = 'estcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.estcostnet') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'estcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualCost']) || canView('ACTIVITY', ['actualCost'])" @click="staffAlloc = 'actualcost'" href="#">
                      <span class="action-item-label">{{ $t('button.actual_cost') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'actualcost'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualCostNet']) || canView('ACTIVITY', ['actualCost'])" @click="staffAlloc = 'actualcostnet'" href="#">
                      <span class="action-item-label">{{ $t('button.actual_cost_net') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'actualcostnet'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </div>
                </a>
              </li>
              <li data-v-05ab69f6="" role="presentation" @[durationMouseEnterEvent]="onDurationOver" @mouseleave="onDurationLeave" @click="onDurationClick">
                <a role="menuitem" href="#" target="_self" class="dropdown-item">
                  <span class="action-item-label">{{ $t('button.duration') }}</span><font-awesome-icon class="menu-chevron" :icon="['far', 'chevron-right']"/>
                  <div ref="duration" class="sub-menu-dropdown">
                    <b-dropdown-item v-if="canView('TASK', ['fixedDuration']) || canView('BOOKING', ['fixedDuration']) || canView('ACTIVITY', ['fixedDuration'])" @click="staffAlloc = 'fixeddurationhours'" href="#">
                      <span class="action-item-label">{{ $t('button.fixeddurationhours') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'fixeddurationhours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['fixedDuration']) || canView('BOOKING', ['fixedDuration']) || canView('ACTIVITY', ['fixedDuration'])" @click="staffAlloc = 'fixeddurationdays'" href="#">
                      <span class="action-item-label">{{ $t('button.fixeddurationdays') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'fixeddurationdays'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['estimatedDuration']) || canView('BOOKING', ['estimatedDuration']) || canView('ACTIVITY', ['estimatedDuration'])" @click="staffAlloc = 'hours'" href="#">
                      <span class="action-item-label">{{ $t('button.estimateddurationhours') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'hours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['estimatedDuration']) || canView('BOOKING', ['estimatedDuration']) || canView('ACTIVITY', ['estimatedDuration'])" @click="staffAlloc = 'days'" href="#">
                      <span class="action-item-label">{{ $t('button.estimateddurationdays') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'days'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualDuration']) || canView('ACTIVITY', ['actualDuration'])" @click="staffAlloc = 'actualdurationhours'" href="#">
                      <span class="action-item-label">{{ $t('button.actualdurationhours') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'actualdurationhours'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canView('TASK', ['actualDuration']) || canView('ACTIVITY', ['actualDuration'])" @click="staffAlloc = 'actualdurationdays'" href="#">
                      <span class="action-item-label">{{ $t('button.actualdurationdays') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'actualdurationdays'" :icon="['far', 'check']"/>
                    </b-dropdown-item>
                  </div>
                </a>
              </li>
              <b-dropdown-item @click="staffAlloc = 'percent'" href="#">
                <span class="action-item-label">{{ $t('button.percent') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'percent'" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="staffAlloc = 'headcount'" href="#">
                <span class="action-item-label">{{ $t('button.head_count') }}</span><font-awesome-icon class="active-check" v-if="staffAlloc === 'headcount'" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group :header="$t('filter')">
              <b-dropdown-item @click="onUnders" href="#">
                <span class="action-item-label">{{ $t('button.unders') }}</span><font-awesome-icon class="active-check" v-if="showUnders" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onOptimal" href="#">
                <span class="action-item-label">{{ $t('button.optimal') }}</span><font-awesome-icon class="active-check" v-if="showOptimal" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onOvers" href="#">
                <span class="action-item-label">{{ $t('button.overs') }}</span><font-awesome-icon class="active-check" v-if="showOvers" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </li>
        <li v-if="id !== null">
          <span class="d-flex">
            <span>
              <b-popover
                target="btn_usageproject"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="staffUsageProject ? $t('staff.staff_usage_project') : $t('staff.staff_usage_all_projects')">
              </b-popover>
              <b-btn id="btn_usageproject" @click="allProjects(null)" :pressed.sync="staffUsageProject" class="tool-button" ><font-awesome-icon :icon="['far', 'chart-network']"/></b-btn>
            </span>
          </span>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li>
          <span :id="`SPAN_COLLAPSE_${id}`">
              <b-popover
                :target="`SPAN_COLLAPSE_${id}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('staff.button.minus')">
              </b-popover>
            <b-btn :disabled="expandLevel === 0" :id="`BTN_COLLAPSE_${id}`" @click="collapse"><font-awesome-icon :icon="['far', 'search-minus']"/></b-btn>
          </span>
        </li>
        <li>
          <span :id="`SPAN_EXPAND_${id}`">
              <b-popover
                :target="`SPAN_EXPAND_${id}`"
                placement="top"
                boundary="viewport"
                triggers="hover"
                :content="$t('staff.button.plus')">
              </b-popover>
            <b-btn :disabled="expandLevel === maxLevel" :id="`BTN_EXPAND_${id}`" @click="expand"><font-awesome-icon :icon="['far', 'search-plus']"/></b-btn>
          </span>
        </li>
        <li @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown :id="`BTN_FILTER_${id}`" ref="filter" 
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="filterText !== '' ? 'active' : ''" :icon="['far','file-search']"/>
            </template>
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <b-input-group class="search-input">
                <b-form-input v-model="filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"  @keypress.enter="onFilterSubmit"></b-form-input>
                <b-input-group-append>
                  <b-btn @click="onFilterClear" class="search-append" size="sm" variant="danger"><font-awesome-icon class="search-clear" icon="times"/></b-btn>
                </b-input-group-append>
                <b-input-group-append>
                  <b-btn @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon variant="secondary" :icon="['far', 'magnifying-glass']"/></b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-dropdown-form>
          </b-dropdown>
        </li>
        <li @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter" 
            @[badgeFilterMouseOverEvent]="onBadgeFilterOver" 
            @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave" 
            @[badgeFilterTouchEndEvent]="onBadgeFilterOver">
          <b-dropdown :id="`BTN_BADGE_FILTER_${id}`" ref="badgeFilter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onBadgeFilterDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
            </template>
            
            <b-dropdown-form @submit.stop.prevent class="filter-padding">
              <TaskViewBadgeFilter :filters="badgeFilters" 
                :fields="badgeFilterFields" 
                :fieldValues="badgeFilterFieldValues" 
                @modified="onBadgeFilterModified" 
                @filterModalOpened="onBadgeFilterModalOpened"
                @filterModalClosed="onBadgeFilterModalClosed"
                @fetchFieldOptions="onBadgeFilterFetchOptions"
                />
            </b-dropdown-form>
            
          </b-dropdown>
        </li>
        <li class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
          <b-dropdown :id="`BTN_VIEW_${id}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far','desktop']"/>
            </template>
            <b-dropdown-item @click="savePreset" href="#">
              <span class="action-item-label">{{ $t(id ? 'staff.button.save' : 'staff.button.save_planner') }}</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <template v-for="(item, index) in staffViews">
              <b-dropdown-item class="action-item" @click="loadViewSettings(item)" href="#" :key="index">
                <span class="action-item-label-with-icon">{{ item.name }}</span>
                <span>
                  <span class="action-item-icon" 
                      v-if="!editPermission(item)"
                      :id="`BTN_COPY_${index}`"
                      @click.stop.prevent="copyColumnSettings(item.name, item)">
                    <font-awesome-icon class="" :icon="['far','copy']"/>
                  </span>
                  <b-popover
                    v-if="!editPermission(item)"
                    :target="`BTN_COPY_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t(id ? 'staff.button.copy' : 'staff.button.copy_planner')">
                  </b-popover>
                  <span class="action-item-icon position-third" 
                      v-if="!editPermission(item)"
                      @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                      :id="`BTN_INFO_${index}`">
                    <font-awesome-icon class="" :icon="['far','circle-info']"/>
                  </span>
                  <b-popover
                    v-if="!editPermission(item)"
                    :target="`BTN_INFO_${index}`"
                    :ref="`BTN_INFO_${index}`"
                    :show.sync="showInfo[index]"
                    placement="top"
                    boundary="viewport"
                    triggers="manual"
                    :content="$t('owner', [staffViews[index].owner])">
                  </b-popover>
                  <span class="action-item-icon position-third" 
                      v-if="editPermission(item)"
                      :id="`BTN_SHARE_${index}`"
                      @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                    <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                  </span>
                  <b-popover
                    v-if="editPermission(item)"
                    :target="`BTN_SHARE_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t(id ? 'staff.button.share' : 'staff.button.share_planner')">
                  </b-popover>
                  <span class="action-item-icon position-second" 
                      v-if="editPermission(item)"
                      :id="`BTN_UPDATE_${index}`"
                      @click.stop.prevent="updateColumnSettings(index, item.name, item)">
                    <font-awesome-icon class="" :icon="['far','save']"/>
                  </span>
                  <b-popover
                    v-if="editPermission(item)"
                    :target="`BTN_UPDATE_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t(id ? 'staff.button.update' : 'staff.button.update_planner')">
                  </b-popover>
                  <span class="action-item-icon"
                      v-if="editPermission(item)"
                      :id="`BTN_DELETE_${index}`"
                      @click.stop.prevent="removeColumnSettings(index)">
                    <font-awesome-icon class="" :icon="['far','trash-alt']"/>
                  </span>
                  <b-popover
                    v-if="editPermission(item)"
                    :target="`BTN_DELETE_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t(id ? 'staff.button.delete' : 'staff.button.delete_planner')">
                  </b-popover>
                </span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </li>
        <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('company', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('stage', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('project', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.project') }}</span><font-awesome-icon class="active-check" v-if="coloring.project" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('department', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.department') }}</span><font-awesome-icon class="active-check" v-if="coloring.department" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('skill', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('staff', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('event', getProfileEntryName('coloring'))" href="#">
                <span class="action-item-label">{{ $t('staff.coloring.event') }}</span><font-awesome-icon class="active-check" v-if="coloring.event" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </li>
      </PriorityNavigation>
      <div class="menu-toggler">
        <b-dropdown class="settings-dropdown" offset="25" no-caret>
          <template slot="button-content">
            <div class="text">
              <font-awesome-icon :icon="['far', 'ellipsis-vertical']"/>
            </div>
          </template>
      
          <template>
            <b-dropdown-item @click="showSettings">{{ $t('button.settings') }}</b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>

    <SchedulerToolbar 
      :scheduler="scheduler"
      :selected="selected"
      :filter="show"
      :showDeleteConfirmation.sync="showDeleteConfirmation"
      @copyEvent="copyEvent"
      @pasteEvent="pasteEvent"
      @staffEvent="staffEvent"
      @addEvent="addEvent"
      @editEvent="editEvent"
      @deleteEvent="deleteEvent"
      @import="fileImport"
      @exportToFile="fileExport"
      @schedulerToolbarCreated="schedulerToolbarCreated"
      :showAdd="canAdd('BOOKING') || canAdd('ACTIVITY') || canAdd('TASK') || canAdd('CALENDAR')"
    />
    
    <div v-if="showLoadingOverlay" class="overlay" :style="isWidget ? `height: ${height}px` : `height: calc(100vh - ${heightOffset}px)`">
      <span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>{{ $t(id || isDataView ? 'staff.grid.loading' : 'staff.grid.planner_loading') }}</span>
    </div>
    <div v-else>
      
      <div class="splitter-container" ref="splitter-container" :style="splitterStyle">
        <div class="lhs-grid" ref="lhs-grid">
          <ag-grid-vue :style="lhsGridStyle" class="ag-theme-balham staffplanner-grid-height" id="pt-grid"
            alwaysShowHorizontalScroll
            :autoGroupColumnDef="autoGroupColumnDef"  
            :cacheBlockSize="10000"
            :columnDefs="columnDefs"  
            :context="context"
            :defaultColDef="defaultColDef"
            :getDataPath="data => data.path"
            :getRowId="params => params.data.key"   
            :gridOptions="gridOptions"
            :headerHeight="40"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :processCellForClipboard="processCellForClipboard"
            rowModelType="clientSide"
            :rowMultiSelectWithClick="false"
            rowSelection="multiple"
            :serverSideInfiniteScroll="false"
            :sideBar="false"
            :singleClickEdit="false"
            suppressCopyRowsToClipboard
            suppressDragLeaveHidesColumns
            suppressContextMenu
            suppressMultiSort
            treeData

            @grid-ready="onGridReady"
            >
          </ag-grid-vue>
        </div>
        <div class="resizer" ref="resizer" id="grid-gantt-resizer" @mousedown="mouseDownHandler">
          <div class="resizer-overlay" ref="resizer-overlay" id="resizer-overlay">
            <font-awesome-icon class="resizer-icon" :icon="['far', 'arrows-left-right']"/>
          </div>
        </div>
        <!-- Scheduler -->
        <div class="rhs-chart" style="overflow: hidden;" ref="rhs-chart">
          <Scheduler 
            :collapseId="collapseId"
            :coloring="coloring"  
            :customFields="customFields"
            :data="schedulerTasks" 
            :deleteEventId="deleteEventId"
            :disableCreate="!canAdd('BOOKING') && !canAdd('ACTIVITY') && !canAdd('TASK') && !canAdd('CALENDAR')"
            :endDate="schedulerEndDate" 
            :expandId="expandId"
            :expandLevel="expandLevel"
            :fields="fields"
            :filter="show"
            :filterByProject="grouping.project"
            :filterObject="badgeFilters"
            :exportToFile="exportToFile"
            :grouping="grouping"
            :height="isWidget ? `${height}px` : schedulerHeight > -1? `${schedulerHeight}px` : `calc(100vh - ${heightOffset2}px)`"
            :markedTimespans="markedTimespans"
            :noRowsMessage="$t('staff.grid.no_data')"
            :permission="permissionName"
            :projectId="staffUsageProject ? id : '000000000000-0000-0000-0000-00000000'"
            :projectName="id && project ? project.name : null"
            :redraw="redrawScheduler"
            :redrawEventId="redrawEventId"
            :showAdd="canAdd('BOOKING') || canAdd('ACTIVITY') || canAdd('TASK') || canAdd('CALENDAR')"
            :showNoRowsOverlay="showNoRowsOverlay"
            :showOvers="showOvers"
            :showOptimal="showOptimal"
            :showUnders="showUnders"
            :skipProcessNodes.sync="skipProcessNodes"
            :holdUpdateUntilTreeDataChanged.sync="holdUpdateUntilTreeDataChanged"
            :span="schedulerSpan" 
            :staffAlloc="staffAlloc" 
            :startDate="schedulerStartDate" 
            :style="{ height: isWidget ? `${height}px` : `calc(100vh - ${heightOffset}px)` }"
            :unallocStart="unallocStart"
            :unallocEnd="unallocEnd"
            :width="schedulerWidth"
            
            @clickItem="schedulerClickItem"
            @collapsedId="onCollapsedId"
            @copyEvent="copyEvent"
            @dateChange="dateChange"
            @deleteEvent="showDeleteConfirmation=true"
            @expandedId="onExpandedId"
            @exportEnd="exportEnd"
            @exportStart="exportStart"
            @folderToggle="folderToggle"
            @gotoday="gotoDaily"
            @markedSpansCreated="markedSpansCreated"
            @pasteEvent="pasteEvent"
            @redrawn="schedulerRedrawn"
            @redrawEventComplete="onRedrawEventComplete"
            @schedulerCreated="schedulerCreated"
            @schedulerDataRender="schedulerDataRender"
            @schedulerScroll="ganttScrollHandler"
            @selectionChanged="onSelectionChanged"
            @showtasks="onCellClicked"
            @tooltip="onTooltip"
            @updated="eventUpdated">
          </Scheduler>
        </div>
      </div>
       
    </div>
      
    <ActivityModal :readOnly="activityEdit.readonly" :show.sync="activityEditShow" @success="modalSuccessNoReload" :id.sync="activityEdit.uuId" />
    <BookingModal :readOnly="bookingEdit.readonly" :show.sync="bookingEditShow" @success="modalSuccessNoReload" :id.sync="bookingEdit.uuId" />
    <TaskModal :readOnly="taskEdit.readonly" :show.sync="taskEditShow" @success="modalSuccessNoReload" :id.sync="taskEdit.uuId" 
        :projectId="taskEdit.projectUuid" />
    <StageModal v-if="stageShow" :id="stageId" :show.sync="stageShow" @success="modalSuccess" :title="stageTitle"/>
    <StaffModal v-if="staffShow" :id="staffId" :show.sync="staffShow" @success="modalSuccess" :title="$t(isGeneric ? 'staff.title_generic_detail' : 'staff.title_detail')" :isGeneric="isGeneric"/>
    <SkillModal v-if="skillShow" :id="skillId" :show.sync="skillShow" @success="modalSuccess" :title="$t('skill.title_detail')"/>
    <LocationModal :id="locationId" :show.sync="locationShow" @success="modalSuccess" :title="$t('location.title_detail')"/>
    <DepartmentModal v-if="allowManage && departmentShow" :id="departmentId" :show.sync="departmentShow" @success="modalSuccess" :title="$t('department.title_detail')" :queryParent="true"/>
    <CompanyModal v-if="allowManage && companyShow" :id="companyId" :show.sync="companyShow" @success="modalSuccess" :title="$t('company.title_detail')"/>
    <SaveViewModal :show.sync="promptSaveShow" :name="saveName" :title="$t('task.confirmation.save')" :profile="saveProfile" @ok="confirmSaveOk"/>
    <SaveViewModal :show.sync="promptShareShow" :name="saveName" :title="$t('task.confirmation.share')" :sharing="true" :profile="saveProfile" @ok="confirmSaveOk"/> 
    <TaskSelectorModal :title="$t('staff.select_task_title')"
        :show.sync="taskSelectShow"
        :data="taskSelectData"
        :ok-title="$t('button.select')"
        :ok-disabled="taskEdit.uuId === null"
        @ok="taskSelectOk"
        />
   <ProjectModal v-if="projectShow" :id="projectEditId" :show.sync="projectShow" @success="modalSuccess" :title="$t('project.title_detail')"/>
   <StaffSelectorModal v-if="showStaffSelector" mode="MANAGE" :show.sync="showStaffSelector" @cancel="modalSuccess"/>
   <ExceptionModal :event="vacationEdit" baseStartHour="09:00" baseEndHour="17:00" :title="$t(vacationEdit.readonly ? 'location.view_exception' : 'location.edit_exception')" :readOnly="vacationEdit.readonly" :show.sync="vacationShow" 
      @success="vacationSuccess" /> 
  
   <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable" @cancel="progressCancel"/>
   
   <PlannerSettingsModal :show.sync="settingsShow" :settings="show" :title="$t('staff.planner_settings.title')" @success="plannerSettingsSuccess"/>
   
   <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :defaultActionForNonWorkPrompt="null"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projScheduleFromStart"
      :bookingAutoScheduleMode="durationCalculation.bookingAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :resizeMode="durationCalculation.resizeMode"
      @success="durationCalculationOk"
      @cancel="durationCalculationCancel"
      @skip="durationCalculationCancel"
      @calendarChange="durationCalculationCalendarChange"
   />
    
   <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteViewShow"
        @ok="confirmDeleteViewOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.delete_view') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('vacation.confirmation.show')"
        v-model="promptShowVacation"
        @ok="showVacationOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('vacation.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('activity.confirmation.show')"
        v-model="promptShowActivity"
        @ok="showActivityOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('activity.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('booking.confirmation.show')"
        v-model="promptShowBooking"
        @ok="showBookingOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('booking.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.confirmation.show')"
        v-model="promptShowTask"
        @ok="showTaskOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.show_events') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('task.field.autoScheduling')"
        v-model="promptScheduleChange"
        @ok="scheduleChangeOk"
        @cancel="scheduleChangeCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('task.confirmation.task_schedule_change') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('staff.maximum_grouping_title')"
        v-model="showGroupingAlert"
        @ok="groupingAlertOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('staff.maximum_grouping') }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
      </template>
    </b-modal>
    
   <b-modal :title="$t('staff.unallocated')"
        v-model="promptUnallocated"
        @ok="unallocatedOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <span>{{ $t('staff.unallocated_hint') }}</span>
        <b-row class="mt-2">
          <b-col cols="12" xl="6">
            <label class="mr-1 align-self-baseline" for="unallocStart">{{ $t('staff.from') }}</label>
            <b-form-datepicker v-model="unallocStart" class="d-flex"
              today-button
              reset-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
              size="sm"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-col>
          <b-col cols="12" xl="6">
            <label class="mr-1 align-self-baseline" for="unallocEnd">{{ $t('staff.to') }}</label>
            <b-form-datepicker v-model="unallocEnd" class="d-flex"
              today-button
              reset-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
              size="sm"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
   <EventTypeModal :title="$t('staff.event_type')"
        :show.sync="promptType"
        :event="newEvent"
        :profileValues="eventTypeValues"
        @ok="onPromptTypeOk"
        @cancel="onPromptTypeCancel"
        >
    </EventTypeModal>
    
    <SelectImportType :title="$t('staff.select_import')"
        @ok="onSelectImportTypeOk"
        :show.sync="selectImportShow">
    </SelectImportType>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="importProperties" :mode="importType" :show="docImportShow"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
  </div>
  
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { DEFAULT_CALENDAR, TRIGGERS
  , convertDisplayToDuration, convertDurationToDisplay, isWorkingDay, calcDateTimeDurationv2
} from '@/helpers/task-duration-process';
import { getCustomFieldInfo
  , prepareCustomFieldColumnDef
} from '@/helpers/custom-fields';

import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common'

import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import DateOnlyCellRenderer from '@/components/Aggrid/CellRenderer/DateOnly';
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray';
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import WorkingHoursCellRenderer from '@/components/Aggrid/CellRenderer/WorkingHours';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';

import currencies from '@/views/management/script/currencies';

import { 
  formatDate, 
  getNextWorkingDay, 
  getUsage,
  invertColor, 
  filterCheck,
  processCalendar,
  transformCalendar,
  staffDurationCalculationOk,
  createVacation,
  cloneTask,
  cloneActivity,
  cloneBooking,
  msToTime,
  formatTooltipDate,
  convertDate,
  costFormat,
  costFormatAdv
} from '@/helpers';
import { buildCompanyFilter, getSelectedCompany } from '@/helpers/company';
import { cloneDeep, debounce } from 'lodash';
import { staffService, 
         calendarService,
         layoutProfileService, 
         viewProfileService, 
         companyService,
         activityService,
         bookingService, 
         projectService,
         taskService,
         taskLinkStaffService,
         compositeService,
         profileService
} from '@/services';

import Scheduler from '@/components/Scheduler/Scheduler';
import SchedulerToolbar from '@/components/Scheduler/SchedulerToolbar';
import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';

import {
  skillComparator,
  departmentComparator,
  companyComparator,
  locationComparator,
  resourceComparator
} from '@/helpers/task-column-comparator';

let staffData = [];
let companies = {};
let locations = {};
let stages = {};
let projects = {};
let departments = {};
let skills = {};
let activities = {};
let tasks = {};
let bookings = {};
let staffEvents = {};
let vacations = {};
let projectBookings = {};
let staffSections = {};
let entityList = {};
let lookups = {};

const getColumnDefs = (c) => {
  return {
    colId: c.colId
    , width: c.actualWidth
    , sort: c.sort != null? c.sort : null
    , sortIndex: c.sortIndex != null? c.sortIndex : null
  }
}
 
function addEmptyGroup(self, name, staff) {
  if (!staff[`${name}List`] || staff[`${name}List`].length === 0) {
    staff[`${name}List`] = [{
      name: self.$t(`staff.no_${name}`),
      uuId: self.$t(`staff.no_${name}`)
    }];
    staff[`${name}Name`] = self.$t(`staff.no_${name}`);
    staff[`${name}UuId`] = self.$t(`staff.no_${name}`);
  }
  return staff;
}

function addEmptyGroups(self, staff) {
  addEmptyGroup(self, 'company', staff);
  addEmptyGroup(self, 'location', staff);
  addEmptyGroup(self, 'project', staff);
  addEmptyGroup(self, 'stage', staff);
  addEmptyGroup(self, 'department', staff);
  addEmptyGroup(self, 'skill', staff);
  return staff;
}

function calcDuration(start_date, end_date, calendar) {
  let trigger = TRIGGERS.START_DATE;
  let projectScheduleFromStart = true;
  
  const startTime = start_date;
  const closeTime = end_date;
  const payload = {
    trigger: trigger
    , startDateStr: startTime.format('YYYY-MM-DD')
    , startTimeStr: startTime.format('HH:mm')
    , closeDateStr: closeTime.format('YYYY-MM-DD')
    , closeTimeStr: closeTime.format('HH:mm')
    , durationDisplay: null
    , calendar: calendar
    , projScheduleFromStart: projectScheduleFromStart
    , taskAutoScheduleMode: false
    , lockDuration: false
    , autoMoveForNonWorkingDay: false
    , skipOutOfProjectDateCheck: true
  }
  const result = calcDateTimeDurationv2(payload);
  let dDisplay = convertDisplayToDuration(result.durationDisplay).value; //Note: unit of resultDisplay is always 'D'.
  
  return dDisplay;
}

function httpAjaxError(e, self) {
  const response = e.response;
  self.alertError = true;
  if (response && 403 === response.status) {
    self.alertMsg = self.$t('error.authorize_action');
  } else if (response.data.jobCase) {
    const feedback = response.data[response.data.jobCase][0];
    if(feedback.spot) {
      self.alertMsg = self.$t(`error.${feedback.clue}`, feedback.args);     
    } 
  }
  else {
    self.alertMsg = self.$t('error.internal_server');     
  }
}

function addIfNotExist(data, key, type, name, keyName, keyValue, otherKeys) {

  if (key) {
    if (!(key in data)) {
      data[key] = { 
        type: type, 
        name: name
      };
      data[key][keyName] = keyValue;
      for (const otherKey of Object.keys(otherKeys)) {
        if (otherKeys[otherKey] !== null) {
          data[key][otherKey] = otherKeys[otherKey];
        }
      }
    }
  }
  return data;
}

function processProperty(data, obj, property, properties, subproperties, parentKey, entityList, i) {
  const uuId = data[`${property}List`][i].uuId;
  const name = entityList[uuId] ? entityList[uuId].name : uuId;
  const key = `${parentKey}${uuId}`;
  if (uuId && !(key in obj)) {
    obj[key] = { 
      name: name,
      type: `${property}`,
      ...properties
    };
    obj[key][`${property}UuId`] = uuId;
  }
  
  // process the next property
  if (subproperties.length !== 0) {
    const subp = {
      ...properties
    };
    subp[`${property}UuId`] = uuId;
    subp[property] = name; // include the name for filter matching
    buildTreeProperty(data, subproperties[0].obj, subproperties[0].name, subp, subproperties.slice(1), key, entityList, subproperties[0].name === 'project' ? i : 0);
  }
}

function buildTreeProperty(data, obj, property, properties, subproperties, parentKey, entityList, index) {
  if (typeof data[`${property}List`] !== 'undefined') {
   if (property === 'project') {
      processProperty(data, obj, property, properties, subproperties, parentKey, entityList, index);
    }
    else {
      for (let i = 0; i < data[`${property}List`].length; i++) {
        processProperty(data, obj, property, properties, subproperties, parentKey, entityList, i);
      }
    }
  }
  else if (subproperties.length !== 0) {
    // skip it and go to the next property
    buildTreeProperty(data, subproperties[0].obj, subproperties[0].name, properties, subproperties.slice(1), '', entityList, 0);
  }
}

function buildTreeData(data, entityList) {
  const properties = [
    { name: 'location', obj: locations },
    { name: 'stage', obj: stages },
    { name: 'project', obj: projects },
    { name: 'department', obj: departments },
    { name: 'skill', obj: skills }
  ];
  
  // add companies
  buildTreeProperty(data, companies, 'company', {}, properties, '', entityList);  
}

function setColors(obj, staff, entityList) {
  if (staff.skillList && staff.skillList.length > 0 &&
      entityList[staff.skillList[0].uuId] &&
      entityList[staff.skillList[0].uuId].color) {
    obj.skillColor = entityList[staff.skillList[0].uuId].color;
    obj.skillTextColor = invertColor(obj.skillColor, true);
  }
  
  if (staff.companyList && staff.companyList.length > 0 &&
      entityList[staff.companyList[0].uuId] &&
      entityList[staff.companyList[0].uuId].color) {
    obj.companyColor = entityList[staff.companyList[0].uuId].color;
    obj.companyTextColor = invertColor(obj.companyColor, true);
  }
  
  if (staff.locationList && staff.locationList.length > 0 &&
      entityList[staff.locationList[0].uuId] &&
      entityList[staff.locationList[0].uuId].color) {
    obj.locationColor = entityList[staff.locationList[0].uuId].color;
    obj.locationTextColor = invertColor(obj.locationColor, true);
  }
  
  if (staff.departmentList && staff.departmentList.length > 0 &&
      entityList[staff.departmentList[0].uuId] &&
      entityList[staff.departmentList[0].uuId].color) {
    obj.departmentColor = entityList[staff.departmentList[0].uuId].color;
    obj.departmentTextColor = invertColor(obj.departmentColor, true);
  }
  
  if (staff.color) {
    obj.staffColor = staff.color;
    obj.staffTextColor = invertColor(obj.staffColor, true);
  }
  if (obj.eventColor) {
    obj.eventTextColor = invertColor(obj.eventColor, true);
  }
  if (obj.stageColor) {
    obj.stageTextColor = invertColor(obj.stageColor, true);
  }
  return obj;
}

function getPerhourCost(cost, span) {
  if (span === 'Daily') {
    return cost / 8.0;
  }
  else if (span === 'Weekly') {
    return cost / 40.0;
  }
  else if (span === 'Monthly') {
    return cost / 168.0;
  }
  else if (span === 'Annually') {
    return cost / 2080.0;
  }
  return cost;
}


async function loadData(self) {
  staffEvents = {};
  bookings = {};
  activities = {};
  tasks = {};
  companies = {};
  locations = {};
  stages = {};
  projects = {};
  departments = {};
  skills = {};
  vacations = {};
  projectBookings = {};
  staffSections = {};
  entityList = {};
  lookups = {};
  
  if (self.loading) {
    return;
  }

  self.staffDataLoaded = {};
  self.staffFieldDataLoaded = [];

  self.loading = true;
  let companyFilter = null;
  // set the dates in the scheduler
  self.schedulerStartDate = self.startDate;
  self.schedulerEndDate = self.endDate;
  if (self.$store.state.company && self.$store.state.company.type !== 'Primary') {
    if (self.$store.state.company.filterIds) {
      companyFilter = self.$store.state.company.filterIds;
    }
    else {
      let uuId = self.$store.state.company.uuId;
      if (uuId === null) {
        uuId = await getSelectedCompany(self.$store.state.authentication.user.uuId);
      }
      
      // the filter is not ready yet, create it
      companyFilter = await companyService.tree(false, uuId).then((response) => {
        return buildCompanyFilter(self.$store.state.company.uuId, response.data[response.data.jobCase]);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
    }
  }
  
  // keep track of if generic is loaded so we don't reload from server when toggling generic
  self.genericLoaded = self.showGeneric;
  self.staffLoaded = self.grouping.staff;
  
  const staffPay = await staffService.plannerList({ start: 0, limit: -1 }).then((response) => {
    return response.data.reduce(function(acc, cur /**, i */) {
      acc[cur.uuId] = cur;
      return acc;
    }, {});
  });
  

  let hasError = false;
  const staffUsageResponse = await staffService.usage({ 
      start: 0, 
      limit: -1, 
      begin: self.startDate, 
      until: self.endDate, 
      generic: self.showGeneric && self.grouping.staff ? null : self.showGeneric ? true : false
  }, false, self.staffIds && self.staffIds.length < 250 ? self.staffIds.map(s => { return { uuId: s }}) : null)
  .then(response => {
    return response;
  })
  .catch(function(error) {
    hasError = true;
    console.error(error); // eslint-disable-line no-console
    httpAjaxError(error, self);
    return null;
  });

  if (hasError) {
    self.loading = false;
    self.showLoadingOverlay = false;
    self.inProgressShow = false;
    return;
  }

  const data = staffUsageResponse.data[staffUsageResponse.data.jobCase];
  entityList = staffUsageResponse.data['entityList'];
  for (const staff of data) {
    // save the staff in a map for quick lookup on add or delete
    staffSections[staff.uuId] = staff;
  
    // setup the pay data
    if (staff.uuId in staffPay) {
      const cost = staffPay[staff.uuId].payAmount;
      const rate = staffPay[staff.uuId].payFrequency;
      const currency = staffPay[staff.uuId].payCurrency;
      
      staff.perhourCost = getPerhourCost(cost, rate);
      staff.currency = currency;
    }
    

    staff.staffType = staff.type;
    staff.type = 'staff';
    staff.company = staff.companyList && staff.companyList.length > 0 ? entityList[staff.companyList[0].uuId].name : null;
    staff.companyUuId = staff.companyList && staff.companyList.length > 0 ? staff.companyList[0].uuId : null;

    if (companyFilter === null ||
        companyFilter.includes(staff.companyUuId)) {
          
      staff.location = staff.locationList && staff.locationList.length > 0 && entityList[staff.locationList[0].uuId] ? entityList[staff.locationList[0].uuId].name : self.$t('staff.no_location');
      staff.locationUuId = staff.locationList && staff.locationList.length > 0 ? staff.locationList[0].uuId : null;
      staff.department = staff.departmentList && staff.departmentList.length > 0 && entityList[staff.departmentList[0].uuId] ? entityList[staff.departmentList[0].uuId].name : self.$t('staff.no_department');
      staff.departmentUuId = staff.departmentList && staff.departmentList.length > 0 ? staff.departmentList[0].uuId : null;
      staff.skill = staff.skillList && staff.skillList.length > 0 && entityList[staff.skillList[0].uuId] ? entityList[staff.skillList[0].uuId].name : self.$t('staff.no_skill');
      staff.skillUuId = staff.skillList && staff.skillList.length > 0 ? staff.skillList[0].uuId : null;
      
      if (staff.tagList) {
        for (const tag of staff.tagList) {
          if (entityList[tag.uuId]) {
            tag.name = entityList[tag.uuId].name;
          }
        }
      }
      
      if (staff.departmentList) {
        // save the names in the skillList
        for (const dept of staff.departmentList) {
          if (entityList[dept.uuId]) {
            dept.name = entityList[dept.uuId].name;
          }
        }
      }  
      
      if (staff.skillList) {
        // save the names in the skillList
        for (const skill of staff.skillList) {
          if (entityList[skill.uuId]) {
            skill.name = entityList[skill.uuId].name;
          }
        }
      }
      
      // save generic in a map for lookup
      if (staff.generic) {
        self.genericStaff[staff.uuId] = staff;
      }
      
      if (staff.taskList) {
        for (const refTask of staff.taskList) {
          const task = entityList[refTask.uuId];
        
          if (task) {
            // if a project stage exists, add it to the staff
            // not every project has a project status so if it 
            // does not exist add null
            let project = null;
            if (task.project) {
              project = entityList[task.project];
              if (!staff.stageList) {
                staff.stageUuId = project.stage ? project.stage : self.$t(`staff.no_stage`);
                staff.stageName = project.stage ? project.stage : self.$t(`staff.no_stage`);
                staff.stageList = [];
              }
            }
                      
            // if a project exists, add it to the staff
            if (task.project) {
              if (!staff.projectUuId) {
                staff.projectUuId = task.project;
                staff.projectName = project.name;
                staff.projectList = [];
              }
              
              // add the project to the list if it does not exist
              if (!staff.projectList.includes(task.project)) {
                staff.stageList.push({ uuId: project.stage ? project.stage : self.$t(`staff.no_stage`) });
                staff.projectList.push({ uuId: task.project, name: project.name });
              }
    
              if (self.id && (self.id === staff.projectUuId ||
                  staff.projectList.findIndex(p => p.uuId === self.id) !== -1) &&
                  !self.staff.includes(staff.uuId)) {
                self.staff.push(staff.uuId);
              }
            }
            
            if (!task.begin) {
              task.begin = 0;
            }
            
            if (!task.until ||
                task.until === 9223372036854776000) {
              task.until = 32535129600000;
            }
            
            task.uuId = refTask.uuId;
            if (task.project) {
              task.pn = entityList[task.project].name;
            }
              
            // copy the project uuId back to the task in the task list
            refTask.name = task.name;
            refTask.project = task.project;
            refTask.begin = task.begin;
            refTask.until = task.until;
            refTask.te = task.duration;
            refTask.tp = task.progress;
            refTask.path = task.path;
            refTask.fixedCost = task.fixedCost;
            refTask.actualCost = task.actualCost;
            refTask.fixedDuration = task.fixedDuration;
            refTask.actualDuration = task.actualDuration;
            
            refTask.type = 'task';
            refTask.rebate = task.rebate;
            if (task.project) {
              refTask.pn = entityList[task.project].name;
              task.projectColor = entityList[task.project].color;
              refTask.projectColor = entityList[task.project].color;
            }
            let stageColor = null;
            if (task.stage) {
              stageColor = entityList[task.stage].color;
              task.stageName = entityList[task.stage].name;
              refTask.stageName = task.stageName;
            }
            
            // if the task is not yet added
            if (!tasks[task.uuId]) {
              task.staffUuid = [staff.uuId];
              tasks[task.uuId] = task;
            }
            else {
              // add the staff
              tasks[task.uuId].staffUuid.push(staff.uuId);
            }
            
            const start = moment(task.begin).format('YYYY-MM-DD H:mm');
            const end = moment(task.until).format('YYYY-MM-DD H:mm');
            let event = { 
              type: 'task',
              id: task.uuId,
              eventColor: task.color,
              stageColor: stageColor,
              projectColor: task.projectColor,
              te: task.duration / 60000 / 60, 
              tp: task.progress,
              asch: task.asch,
              start_date: start, 
              end_date: end, 
              pu: task.project,
              name: task.name,
              stageName: task.stageName ? task.stageName : self.$t(`staff.no_stage`),
              pn: task.project ? entityList[task.project].name : null,
              text: `${task.path ? task.path.replace(/\n/g, ' / ') : ''}`, 
              //section_id: activity.staffUuid, 
              staffUuid: staff.uuId,
              readonly: !self.canEdit('TASK', ['startTime', 'closeTime', 'duration']),
              fixedCost: task.fixedCost,
              actualCost: task.actualCost,
              fixedDuration: task.fixedDuration,
              actualDuration: task.actualDuration,
              currencyCode: staff.currency
            };
            
            event = setColors(event, staff, entityList);
            
            self.addStaffEvent(event);
          }
        }
      }
      
      if (staff.activityList) {
        for (const refActivity of staff.activityList) {
          refActivity.type = 'activity';
          const activity = entityList[refActivity.uuId];
          if (activity) {
            refActivity.name = activity.name;
            refActivity.duration = activity.duration;
            refActivity.rebate = activity.rebate;
            refActivity.fixedCost = activity.fixedCost;
            refActivity.actualCost = activity.actualCost;
            refActivity.fixedDuration = activity.fixedDuration;
            refActivity.actualDuration = activity.actualDuration;
            activity.uuId = refActivity.uuId;
            activity.staffUuid = staff.uuId;
            let stageColor = null;
            if (activity.stage) {
              stageColor = entityList[activity.stage].color;
              activity.stageName = entityList[activity.stage].name;
              refActivity.stageName = activity.stageName;
            }
            
            if (!activity.begin) {
              activity.begin = 0;
            }
            
            if (!activity.until ||
                activity.until === 9223372036854776000) {
              activity.until = 32535129600000;
            }
            
            refActivity.begin = activity.begin;
            refActivity.until = activity.until;
            activities[activity.uuId] = activity;
            const start = moment(activity.begin).format('YYYY-MM-DD H:mm');
            const end = moment(activity.until).format('YYYY-MM-DD H:mm');
            let event = { 
              type: 'activity',
              id: activity.uuId,
              eventColor: activity.color,
              stageColor: stageColor,
              te: activity.duration / 60000 / 60, 
              start_date: start, 
              end_date: end, 
              text: activity.name, 
              stageName: activity.stageName ? activity.stageName : null,
              //section_id: activity.staffUuid, 
              staffUuid: staff.uuId,
              readonly: !self.canEdit('ACTIVITY', ['startTime', 'closeTime', 'duration']),
              fixedCost: activity.fixedCost,
              actualCost: activity.actualCost,
              fixedDuration: activity.fixedDuration,
              actualDuration: activity.actualDuration,
              currencyCode: staff.currency
            };
            
            event = setColors(event, staff, entityList);
            
            self.addStaffEvent(event);
          }
        }
      }
      
      staff.calendars = [ staff.calendarList, self.baseCalendar ];
      staff.nonWorking = [];
      // prepare calendar lists
      if (staff.locationList) {
        const locationUuid = staff.locationList.length > 0 ? staff.locationList[0].uuId : null;
        const locationCalendar = locationUuid !== null && entityList[locationUuid] ? entityList[locationUuid].calendarList : null;
        
        if (locationCalendar) {
          for (const locationCalendarEntry of locationCalendar) {
            vacations[locationCalendarEntry.uuId] = locationCalendarEntry;
            // adding 24 hours - 1 to the end date as calendar entries start date is the same as the end date
            // if on the same day
            if (locationCalendarEntry.type === "Leave") {
              staff.nonWorking.push({ 
                id: locationCalendarEntry.uuId, 
                name: locationCalendarEntry.name, 
                start: locationCalendarEntry.startDate, 
                end: locationCalendarEntry.endDate + (24*3600000) - 1,
                readonly: true
              });
            }
          }
          staff.calendars = [ staff.calendarList, locationCalendar, self.baseCalendar ];
        }
      }
    
      if (staff.calendarList) {
        // convert the calendar so we have integers for the day of week
        staff.calendarList = self.processCalendar([staff.calendarList]);
        for (const calendarEntry of staff.calendarList) {
          if (calendarEntry.startDate &&
              calendarEntry.endDate) {
            vacations[calendarEntry.uuId] = calendarEntry;
          
            let first_hour = 0;
            let last_hour = 23;
            if (self.show.hideNonWorking) {
              if (self.show.work_hours === null) {
                self.show.work_hours = { first_hour: 9, last_hour: 17 };
              }
              first_hour = self.show.work_hours.first_hour;
              last_hour = self.show.work_hours.last_hour;
            }
            
            const start = moment(calendarEntry.startDate).format(`YYYY-MM-DD ${first_hour}:00`);
            const end = moment(calendarEntry.endDate).format(`YYYY-MM-DD ${last_hour}:00`);
            // adding 24 hours - 1 to the end date as calendar entries start date is the same as the end date
            // if on the same day
            if (calendarEntry.type === "Leave") {
              staff.nonWorking.push({ 
                id: calendarEntry.uuId, 
                name: calendarEntry.name, 
                start: calendarEntry.startDate, 
                end: calendarEntry.endDate + (24*3600000) - 1,
                readonly: !self.canEdit('CALENDAR', ['startDate', 'endDate'])
              });
            }
            
            let event = { 
              type: 'vacation',
              id: calendarEntry.uuId,
              start_date: new Date(start), 
              end_date: new Date(end), 
              // start_date: start, 
              // end_date: end, 
              startHour: msToTime(calendarEntry.startHour),
              endHour: msToTime(calendarEntry.endHour),
              calendar_type: calendarEntry.type,
              text: calendarEntry.name, 
              staffUuid: staff.uuId,
              readonly: !self.canEdit('CALENDAR', ['startDate', 'endDate'])
            };
            
            event = setColors(event, staff, entityList);
            
            vacations[calendarEntry.uuId].event = event;
            
            self.addStaffEvent(event);
          }
        }
      }
      
      if (staff.bookingList) {  
        for (const booking of staff.bookingList) {
          booking.type = 'booking';
          booking.staffUuid = staff.uuId;
          const projectList = booking.bookedList.filter(b => b.label === 'PROJECT');
          let stageColor = null;
          if (projectList.length !== 0) {
            if (projectList[0].uuId in projectBookings) {
              projectBookings[projectList[0].uuId].push(booking);
            }
            else {
              projectBookings[projectList[0].uuId] = [booking];
            }
            
            const project = entityList[projectList[0].uuId];
            if (project) {
              booking.pn = project.name;
              booking.project = projectList[0].uuId;
              booking.projectColor = project.color;
            }
            
            if (booking.stage && entityList[booking.stage]) {
              stageColor = entityList[booking.stage].color;
              booking.stageName = entityList[booking.stage].name;
            }
            
            // if a project stage exists, add it to the staff
            // not every project has a project status so if it 
            // does not exist add null
            if (!staff.stageList && project) {
              staff.stageUuId = project.stage ? project.stage : self.$t(`staff.no_stage`);
              staff.stageName = project.stage ? project.stage : self.$t(`staff.no_stage`);
              staff.stageList = [];
            }
            
            // if a project exists, add it to the staff
            if (!staff.projectUuId && project) {
              staff.projectUuId = projectList[0].uuId;
              staff.projectName = project.name;
              staff.projectList = [];
            }
            
            // add the project to the list if it does not exist
            if (staff.projectList && !staff.projectList.includes(projectList[0].uuId) && project) {
              staff.stageList.push({ uuId: project.stage ? project.stage : self.$t(`staff.no_stage`) });
              staff.projectList.push({ uuId: projectList[0].uuId, name: project.name });
            }

            if (self.id && (self.id === staff.projectUuId ||
                staff.projectList.includes(self.id)) &&
                !self.staff.includes(staff.uuId)) {
              self.staff.push(staff.uuId);
            }
          }
          
          
          if (!booking.begin) {
            booking.begin = 0;
          }
          
          if (!booking.until ||
              booking.until === 9223372036854776000) {
            booking.until = 32535129600000;
          }
          
          bookings[booking.uuId] = booking;
          const start = moment(booking.begin).format('YYYY-MM-DD H:mm');
          const end = moment(booking.until).format('YYYY-MM-DD H:mm');
          let event = { 
            type: 'booking',
            id: booking.uuId, 
            eventColor: booking.color,
            stageColor: stageColor,
            projectColor: booking.projectColor,
            te: booking.duration / 60000 / 60, 
            start_date: start, 
            end_date: end, 
            text: booking.name, 
            stageName: booking.stageName ? booking.stageName : self.$t(`staff.no_stage`),
            //section_id: booking.staffUuid, 
            staffUuid: staff.uuId,
            project: projectList.length !== 0 ? projectList[0].uuId : null,
            pn: projectList.length !== 0 && entityList[projectList[0].uuId] ? entityList[projectList[0].uuId].name : null,
            readonly: !self.canEdit('BOOKING', ['beginDate', 'duration', 'untilDate']),
            fixedCost: booking.fixedCost,
            fixedDuration: booking.fixedDuration,
            currencyCode: staff.currency
          };
          
          event = setColors(event, staff, entityList);
          
          self.addStaffEvent(event);
        }  
      }
      
      addEmptyGroups(self, staff);
      buildTreeData(staff, entityList);
    }
  } 
    
  staffData = data.filter(staff => companyFilter === null || companyFilter.includes(staff.companyUuId));

  //Get additional data for sorted column.
  if (Array.isArray(staffData) && staffData.length > 0) {
    //Get only the chosen customFields in badgeFilters. Other chosen badgeFilters do not need data fetching.
    const allowedList = self.customFields.filter(i => i.type == 'String' || i.type == 'Enum<String>').map(i => i.name);
    const payloadFields = self.badgeFilters.filter(i => allowedList.includes(i.field)).map(i => i.field);
    let found = null;
    if (self.layoutProfile[self.getProfileEntryName('list')] != null) {
      found = self.layoutProfile[self.getProfileEntryName('list')].find(i => i.colId != self.COLUMN_AGGRID_AUTOCOLUMN && i.sort != null);
      if (found) {
        payloadFields.push(found);
      }
    }
    if (payloadFields.length > 0) {
      await self.fetchDataForSortedColumn(staffData, payloadFields.map(c => {return c.colId}));
      if (found) {
        self.staffFieldDataLoaded.push(found.colId);
      }
    }
  }
  
  self.assignData();
  
  if (self.callback) {
    self.callback();
    self.callback = null;
  }
    
  // self.loading = false;
  self.showLoadingOverlay = false;
  // self.inProgressShow = false;
}

async function ServerSideDatasource(self) {
  if (self.startDate === null) {
    const b = new Date();
    b.setMonth(b.getMonth() - 3);
    self.startDate = formatDate(b);
  }
  
  if (self.endDate === null) {
    const u = new Date();
    u.setMonth(u.getMonth() + 3);
    self.endDate = formatDate(u);
  }
  
  if (self.id !== null) {
    
    self.staff = [];
  }
  else if (self.isDataView &&
           self.staff.length === 0) {
    self.assignData();
    self.loading = false;
    self.showLoadingOverlay = false;
    self.inProgressShow = false;
    return;         
  }
  
  loadData(self);
  self.datesChanging = false;
}

export default {
  name: 'PlannerStaff',
  components: {
    'ag-grid-vue': AgGridVue,
    ActivityModal: () => import('@/components/modal/ActivityModal'),
    BookingModal: () => import('@/components/modal/BookingModal'),
    TaskModal: () => import('@/components/modal/TaskModal'),
    StaffModal: () => import('@/components/modal/StaffModal'),
    StaffSelectorModal: () => import('@/components/modal/StaffSelectorModal'),
    DepartmentModal: () => import('@/components/modal/DepartmentModal.vue'),
    CompanyModal: () => import('@/components/modal/CompanyModal.vue'),
    StageModal: () => import('@/components/modal/StageModal.vue'),
    LocationModal: () => import('@/components/modal/LocationModal.vue'),
    SkillModal: () => import('@/components/modal/SkillModal.vue'),
    EventTypeModal: () => import('@/components/modal/EventTypeModal.vue'),
    SaveViewModal: () => import('@/components/modal/SaveViewModal.vue'),
    TaskSelectorModal: () => import('@/components/modal/TaskSelectorModal.vue'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue'),
    Scheduler,
    SchedulerToolbar,
    ExceptionModal: () => import('@/components/modal/CalendarExceptionModal'),
    PriorityNavigation,
    ProjectModal: () => import('@/components/modal/ProjectModal.vue'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    PlannerSettingsModal: () => import('@/components/modal/PlannerSettingsModal'),
    SelectImportType: () => import('@/components/modal/SelectImportType')
    
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , dateOnlyCellRenderer: DateOnlyCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , stringEditor: StringEditor
    , costCellRenderer: CostCellRenderer
    , durationCellRenderer: DurationCellRenderer
    , workingHoursCellRenderer: WorkingHoursCellRenderer
    , enumCellRenderer: EnumCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */ 
  },
  props: {
    mode: {
      type: String,
      default: 'BOTH'
    },
    projectId: {
      type: String,
      default: null
    },
    staffIds: {
      type: Array,
      default: null
    },
    heightOffset: {
      type: Number,
      default: 240
    },
    heightOffset2: {
      type: Number,
      default: 240
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    widgetOwner: {
      type: String,
      default: null
    },
    dataviewId: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      id: null,
      permissionName: 'ACTIVITY',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      project: null,
      isDataView: false,
      alertError: true,
      alertMsg: null,
      alertMsgDetails: [],
      
      span: "Daily",
      spanOptions: [
        { text: "Day", value: "Daily" },
        { text: "Week", value: "Weekly" },
        { text: "Month", value: "Monthly" },
        { text: "Year", value: "Yearly" }
      ],
      dates: "this-year",
      dateOptions: [
        { text: 'Custom',  value: null},
        // - means the project's start date and end date attributes (if exist).  
        // If both don't exist, then error msg "Project start date or end date 
        // not defined." and From/To dates don't change.
        { text: "Project start-to-end", value: "project-start-to-end" }, 
        // - means the earliest task start date to the latest tasks finish date 
        // in the project (hopefully from Chris' macro?)
        { text: "Project schedule", value: "project-schedule" },  
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: "This week", value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: "This week-to-date", value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: "This month", value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: "This month-to-date", value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: "This quarter", value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: "This quarter-to-date", value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: "This year", value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: "This year-to-date", value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: "Last week", value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: "Last week-to-date", value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: "Last month", value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: "Last month-to-date", value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: "Last quarter", value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: "Last quarter-to-date", value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: "Last year", value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: "Next week", value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: "Next 4 weeks", value: "next-4-weeks" },
        // - means next 8 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: "Next 8 weeks", value: "next-8-weeks" },
        // - means next 12 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: "Next 12 weeks", value: "next-12-weeks" },
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: "Next 24 weeks", value: "next-24-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: "Next month", value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: "Next quarter", value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: "Next year", value: "next-year" } 
      ],
      startDate: null,
      endDate: null,
      schedulerStartDate: null,
      schedulerEndDate: null,
      min: null,
      max: null,
      total_records: 0,
      cachedData: {
        data: null,
        startRow: null,
        endRow: null
      },
      taskEdit: {
        uuId: null,
        projectUuid: null,
        readonly: false
      },
      activityEdit: {
        uuId: null,
        readonly: false
      },
      bookingEdit: {
        uuId: null,
        readonly: false
      },
      activityEditShow: false,
      bookingEditShow: false,
      taskEditShow: false,
      taskSelectShow: false,
      showStaffSelector: false,
      staffUsageProject: true,
      taskOptions: [],
      staff: [],
      staffAlloc: 'hours',

      layoutProfile: {},
      staffViews: [],
      showInfo: [],
      datesChanging: true, // avoid triggering setting save at bad times
      grouping: {
        company: false,
        location: false,
        department: false,
        skills: false,
        staff: true
      },
      showGeneric: true,
      
      lhsGridStyle: {},
      splitterStyle: {},
      
      staffId: null,
      staffShow: false,
      isGeneric: false,
      genericStaff: {},
      companyId: null,
      companyShow: false,
      locationId: null,
      locationShow: false,
      departmentId: null,
      departmentShow: false,
      stageId: null,
      stageShow: false,
      skillId: null,
      skillShow: false,
      projectShow: false,
      projectEditId: null,
      vacationEdit: {
        uuId: null,
        name: null,
        startDate: null,
        endDate: null,
        isWorking: false,
        startHour: null,
        endHour: null,
        calendarName: null,
        identifier: null,
      },
      vacationShow: false,
      
      previousScrollPosTop: -1,
      previousScrollPosLeft: -1,
      
      expandLevel: 0,
      maxLevel: 0,
      filterText: '',
      filterTextFocus: false,
      promptSaveShow: false,
      promptShareShow: false,
      saveName: null,
      saveProfile: null,
      saveIndex: -1,
      confirmDeleteViewShow: false,
      deleteViewIndex: -1,
      showUnders: true,
      showOptimal: true,
      showOvers: true,
      filterValue: '',
      showColumn: null,
      taskSelectData: null,
      schedulerTasks: [],
      markedTimespans: [],
      // filterFieldValues: {},
      // filterHistory: [],
      // filter: [],
      
      coloring: {
        none: true,
        event: false,
        skill: false,
        staff: false,    
        department: false,   
        location: false, 
        company: false,
        stage: false,
        project: false
      },
      promptType: false,
      deleteEventId: null,
      projectSelectorShow: false,
      folderState: {},
      
      durationCalculationShow: false,
      durationCalculation: {
        trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projScheduleFromStart: true
        , bookingAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , skipOutOfProjectDateCheck: false
        , resizeMode: false
      },
      
      loading: false,
      showLoadingOverlay: true,
      showNoRowsOverlay: false,
      
      updateEvent: [],
      newEvent: {
        description: null,
        projectBooking: [],
        projectTask: []
      },
      
      show: {
        usage: true,
        activity: true,
        booking: true,
        task: true,
        vacation: true,
        required: true,
        available: false,
        alertBookings: false,
        alertTaskBookings: false,
        alertActivities: false,
        alertTasks: false,
        alertNonWork: false,
        unallocated: false,
        fullBarColor: false,
        usageBookings: true,
        usageActivities: true,
        usageTasks: true,
        displayFilteredMetrics: false,
        work_hours: { first_hour: 9, last_hour: 17 },
        hideWeekends: false,
        hideNonWorking: true
      },
      
      eventTypeValues: {
        activityName: null,
        taskName: null,
        vacationName: null
      },
      
      promptShowVacation: false,
      promptShowActivity: false,
      promptShowBooking: false,
      promptShowTask: false,
      promptScheduleChange: false,
      
      redrawScheduler: false,
      highlightRefresh: false,
      unallocStart: null,
      unallocEnd: null,
      promptUnallocated: false,
      settingsShow: false,
      redrawEventId: null,
      showGroupingAlert: false,
      docImportShow: false,
      selectImportShow: false,
      importType: 'BOOKING',
      genericLoaded: false,
      staffLoaded: false,
      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      autoGroupColumnDef: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      
      lastOpenColumnMenuParams: null,

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      // multiple: true,
      
      //Start Scroll state
      scrollState: {
        left: -1
        , top: -1
        , triggeredByLHS: false
        , triggeredByRHS: false
      },
      //End Scroll state

      splitterEventState: {
        isMouseDown: false
        , x: 0
        , y: 0
        , leftWidth: 0
      },
      collapseId: null, 
      expandId: null,
      selected: [],
      exportToFile: false,

      schedulerWidth: null,
      scheduler: null,
      schedulerWrapper: null,
      schedulerToolbarWrapper: null,
      markedSpans: null,

      customFields: [],
      optionCurrency: [],
      payFrequencyOptions: [],
      typeOptions: [],
      fields: [],
      showDeleteConfirmation: false,
      skipProcessNodes: false,
      schedulerHeight: -1

      //BadgeFilter related
      , badgeFilters: [
        // { field: 'taskName', name: 'Task Name: Task #1, Task #2', value: [{ checked: true, text: 'Task #1'}, { checked: true, text: 'Task #2'}] }
        // , { field: 'skillName', name: 'Skill Name: Rigging, Animation', value: [{ checked: true, text: 'Rigging'}, { checked: true, text: 'Animation'}] }
      ]
      , badgeFilterFields: [
        // { value: 'complexity', text: 'Complexity' }
        // , { value: 'identifier', text: 'Identifier' }
      ]
      , badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
        // skillName: [
        //   { text: 'Rigging' },
        //   { text: 'Layout' },
        //   { text: 'Animation' }
        // ]
      }
      , badgeFilterFocus: false
      , badgeFilterModalOpened: 'close'
      , closePriorityNavDropdown: false
      , holdUpdateUntilTreeDataChanged: false
    }
  },
  watch: {
    '$store.state.company.filterIds': function() {
      // only update if the planner is loaded
      if (this.schedulerStartDate && !this.showLoadingOverlay) {
        this.updateGrid();
      }
    },
    staffIds(newValue) {
      this.staff = newValue;
      if (this.projectId === null) {
        this.staffUsageProject = false;
        this.dismissAlert();
        let optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-start-to-end');
        if (optionsIndex) {
          this.dateOptions.splice(optionsIndex, 1);
        }
        optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-schedule');
        if (optionsIndex) {
          this.dateOptions.splice(optionsIndex, 1);
        }
      }
      
      if (!this.dates &&
          !this.endDate &&
          !this.startDate) {
        this.dates = "this-month";
      }
      
      // if the profile is loaded we should update the grid
      // otherwise it will be updated when the profile is loaded
      if (this.layoutProfileLoaded) {
        this.datesChanged();
        this.updateGrid();
      }
    },
    projectId(newValue) {
      this.id = newValue;
    },
    'startDate': function(newValue) {
      if (this.datesChanging) {
        return;
      }
      if(this.layoutProfile['staffStartDate'] != newValue) {
        // Update all date values together because:
        // 1 - Avoids multiple requests when they change together
        // 2 - Need to lock in new start/end when going from range->custom date.
        //   - In those cases, the field doesn't trigger an update but we are
        //   - now interested in storing the (previously calculated) value.
        this.layoutProfile['staffStartDate'] = newValue;
        this.layoutProfile['staffEndDate'] = this.endDate;
        this.layoutProfile['staffDates'] = this.dates;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }

      }
    },
    'endDate': function(newValue) {
      if (this.datesChanging) {
        return;
      }
      if(this.layoutProfile['staffEndDate'] != newValue) {
        this.layoutProfile['staffEndDate'] = newValue;
        this.layoutProfile['staffStartDate'] = this.startDate;
        this.layoutProfile['staffDates'] = this.dates;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    'dates': function(newValue) {
      if(this.layoutProfile['staffDates'] != newValue) {
        this.layoutProfile['staffDates'] = newValue;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    'span': function(newValue) {
      if (!this.showLoadingOverlay) {
        if (this.blockMonthly) {
          this.inProgressShow = false;
          this.blockMonthly = false;
          return;
        }
        
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        
        this.checkDates();
        if(this.layoutProfile['staffTimescale'] != newValue) {
          this.layoutProfile['staffTimescale'] = newValue;
          if (!this.loadingView) {
            this.updateLayoutProfile();
          }
        }
      }
    },
    'staffUsageProject': function(newValue) {
      if(this.layoutProfile['staffUsageProject'] != newValue) {
        this.layoutProfile['staffUsageProject'] = newValue;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    'showGeneric': function(newValue) {
      if(this.layoutProfile['showGeneric'] != newValue) {
        this.layoutProfile['showGeneric'] = newValue;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    'staffAlloc': function(newValue) {
      if(this.layoutProfile['staffAlloc'] != newValue) {
        this.layoutProfile['staffAlloc'] = newValue;
        if (!this.loadingView) {
          this.updateLayoutProfile();
        }
      }
    },
    showLoadingOverlay(newValue) {
      if (!newValue && this.isResizerTouchEventRegistered != true) {
        this.isResizerTouchEventRegistered = true;
        setTimeout(() => {
          if (this.$refs['resizer-overlay'] != null) {
            if(this.isTouchDevice()) {
              this.$refs['resizer-overlay'].addEventListener('touchstart', this.touchStartHandler);
            } else {
              this.$refs['resizer-overlay'].style.display = 'none';
            }  
          }
        }, 0)
      }
    }
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    
    this.prepareData();
    
    const self = this;
    const profileKey = this.getProfileEntryName('list');
    
    this.gridOptions = {
      getRowHeight: params => {return self.getRowHeight(params)},
      onRowGroupOpened: function(event) {
        
        //The following logic is for action: user clicks 
        // on the expand/collapse icon in the autoGroupColumn column.
        if(event.node.expanded) {
          self.expandId = event.data.key;
        }
        else {
          self.collapseId = event.data.key;
        }
      },
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)))
      },
      onRowClicked: function(event) {
        if (event.data.scheduleStart &&
            event.data.scheduleFinish) {
          if (moment(self.control.startDate).unix() * 1000 > event.data.scheduleStart) {
            self.control.startDate = moment(event.data.scheduleStart).format('YYYY-MM-DD');
          }
          
          if (moment(self.control.endDate).unix() * 1000 < event.data.scheduleFinish) {
            self.control.endDate = moment(event.data.scheduleFinish).format('YYYY-MM-DD');
          }
        }
      },
      
      onBodyScroll: function(event) {
        self.scrollState.triggeredByLHS = false;
        self.scrollState.triggeredByRHS = false;
        if(self.scrollState.top != event.top) {
          self.scrollState.triggeredByLHS = true;
          if (event.top == 0) {
            //dhtmlx Scheduler does not scroll when value is 0.
            //Workaround: set to 1 to let it scroll close to 0.
            self.scrollState.top = 1;  
          } else {
            self.scrollState.top = event.top;
          }
          
          //Call scrollTo() of scheduler instance directly instead of relying vue reactivity of data variable (this.scrollState.top).
          //It improves the rendering performance (at least visually if not actual improvement).
          if (self.scheduler != null) {
            self.scheduler.getView().scrollTo({ top: self.scrollState.top, left: self.scrollState.left })
          }
        }
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
          
        // Save the new layout after applying it
        self.updateLayoutProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        const curSortColumn = columns.find(i => i.sort != null);
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateLayoutProfile();

        if (curSortColumn == null || self.staffFieldDataLoaded.includes(curSortColumn.colId)) {
          self.updateSchedulerDataAfterSortedAndFiltered(event.api);
        } else {
          self.fetchRowDataForSortedColumn(event.api, curSortColumn.colId);
        }
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateLayoutProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.layoutProfile[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateLayoutProfile();
          })
        }
      },
      onRowDataUpdated: function(event) {
        self.needSetActualRowHeights = true;

        if (self.needRedrawViewport == true) {
          self.needRedrawViewport == false;
          const firstRow = event.api.getFirstDisplayedRow();
          const lastRow = event.api.getLastDisplayedRow();
          if (lastRow !== -1) {
            self.redrawViewport(event, firstRow, lastRow);
          }
        }

        //Apply expand level to rowData
        event.api.forEachNodeAfterFilterAndSort((rowNode) => {
          if (rowNode.data && ((Object.hasOwn(self.folderState, rowNode.data.key) && self.folderState[rowNode.data.key] == true) || rowNode.data.path.length <= self.expandLevel)) {
            rowNode.expanded = true;
          } else {
            rowNode.expanded = false;
          }
        });

        event.api.onGroupExpandedOrCollapsed();
        self.updateSchedulerDataAfterSortedAndFiltered(event.api);
      },
      onViewportChanged: debounce(function(params)  {
        const firstRow = params.firstRow;
        const lastRow = params.lastRow;
        if (lastRow !== -1) {
          self.redrawViewport(params, firstRow, lastRow);
        }
      }, 250),
    };
    const colDefs = [
      {
        headerName: this.$t('staff.field.type')
        , field: 'staffType'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { options: this.typeOptions }
        , hide: false
      }
      , {
        headerName: this.$t('staff.field.position')
        , field: 'position'
        , hide: false
      }
      , {
        headerName: this.$t('staff.field.department')
        , field: 'departments'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , hide: false
        , comparator: departmentComparator
      }
      , {
        headerName: this.$t('staff.field.company')
        , field: 'companies'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , hide: false
        , comparator: companyComparator
      }
      , {
        headerName: this.$t('staff.field.location')
        , field: 'locations'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , hide: true
        , comparator: locationComparator
      }
      , {
        headerName: this.$t('staff.field.firstName')
        , field: 'firstName'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , hide: true
        , minWidth: 150
      }
      , {
        headerName: this.$t('staff.field.lastName')
        , field: 'lastName'
        , cellRenderer: 'genericCellRenderer'
        , cellEditor: 'stringEditor'
        , hide: true
        , minWidth: 150
      }
      , {
        headerName: this.$t('staff.field.startDate')
        , field: 'startDate'
        , cellRenderer: 'dateOnlyCellRenderer'
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.endDate')
        , field: 'endDate'
        , cellRenderer: 'dateOnlyCellRenderer'
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.skills')
        , field: 'skills'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: {
          labelFormatter: (params) => {
            if (params == null || params.value == null || !Array.isArray(params.value)) {
              return { value: [] }
            }
            params.value.forEach(i => {
              if (i.name != null && i.level != null) {
                i.label = `${i.name} (${i.level})`
              }
            })
            return { value: params.value }
          }
        }
        , hide: true
        , minWidth: 150
        , comparator: skillComparator
      }
      , {
        headerName: this.$t('field.tag')
        , field: 'tag'
        , cellRenderer: 'genericCellRenderer'
        , minWidth: 100
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.resources')
        , field: 'resources'
        , cellRenderer: 'genericEntityArrayCellRenderer'
        , cellRendererParams: {
          labelFormatter: (params) => {
            if (params == null || params.value == null || !Array.isArray(params.value)) {
              return { value: [] }
            }
            params.value.forEach(i => {
              if (i.name != null && i.unit != null) {
                i.label = `${i.name} (${i.unit})`
              }
            })
            return { value: params.value }
          }
        }
        , hide: true
        , minWidth: 150
        , comparator: resourceComparator
      }
      , {
        headerName: this.$t('field.identifier_full')
        , field: 'identifier'
        , cellRenderer: 'genericCellRenderer'
        , minWidth: 100
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.payAmount')
        , field: 'payAmount'
        , cellRenderer: 'costCellRenderer'
        , minWidth: 100
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.payCurrency')
        , field: 'payCurrency'
        , cellEditor: 'listEditor'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { options: this.optionCurrency }
        , hide: true
      }
      , {
        headerName: this.$t('staff.field.payFrequency')
        , field: 'payFrequency'
        , cellEditor: 'listEditor'
        , cellRenderer: 'enumCellRenderer'
        , cellRendererParams: { options: this.payFrequencyOptions }
        , minWidth: 100
        , hide: true
        
      }
      , {
        headerName: this.$t('staff.field.totalFixedDuration')
        , field: 'totalFixedDuration'
        , cellRenderer: 'workingHoursCellRenderer'
        , cellRendererParams: {
            alloc: 'staffAlloc'
          }
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
      , {
        headerName: this.$t('staff.field.totalEstimatedDuration')
        , field: 'totalEstimatedDuration'
        , cellRenderer: 'workingHoursCellRenderer'
        , cellRendererParams: {
            alloc: 'staffAlloc'
          }
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
      , {
        headerName: this.$t('staff.field.totalActualDuration')
        , field: 'totalActualDuration'
        , cellRenderer: 'workingHoursCellRenderer'
        , cellRendererParams: {
            alloc: 'staffAlloc'
          }
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
      , {
        headerName: this.$t('staff.field.totalFixedCost')
        , field: 'totalFixedCost'
        , cellRenderer: 'costCellRenderer'
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
      , {
        headerName: this.$t('staff.field.totalEstimatedCost')
        , field: 'totalEstimatedCost'
        , cellRenderer: 'costCellRenderer'
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
      , {
        headerName: this.$t('staff.field.totalActualCost')
        , field: 'totalActualCost'
        , cellRenderer: 'costCellRenderer'
        , minWidth: 100
        , hide: true
        , sortable: false
        
      }
    ];
    
    const linkedEntities = [
      { selector: 'STAFF.SKILL', field: 'skills', properties: ['name'] },
      { selector: 'STAFF.RESOURCE', field: 'resources', properties: ['name'] },
      { selector: 'STAFF.COMPANY', field: 'companies', properties: ['name'] },
      { selector: 'STAFF.DEPARTMENT', field: 'departments', properties: ['name'] },
      { selector: 'STAFF.LOCATION', field: 'locations', properties: ['name'] },
      { selector: 'STAFF.TAG', field: 'tag', properties: ['name'] },
    ]
    
    // this.columnDefs = colDefs;
    this.autoGroupColumnDef = {
      headerName: 'Name',
      field: 'uuId',
      cellRendererParams: {
        suppressCount: true, 
        innerRenderer: 'detailLinkCellRenderer'
      },
      lockVisible: true,
      pinned: 'left',
      minWidth: 150,
      sort: 'asc',
      hide: false,
      menuTabs: ['columnsMenuTab'],
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if(nodeA.group == nodeB.group) {
          if (valueA === null && valueB === null) {
            return 0;
          }
          if (valueA === null) {
            return -1;
          }
          if (valueB === null) {
            return 1;
          }
          if (nodeA.data && nodeB.data) {
            return nodeA.data.name.toLowerCase().localeCompare(nodeB.data.name.toLowerCase());
          }
          return -1;
        } else if(nodeA.group) {
          return 1;
        } else if(nodeB.group) {
          return -1;
        }
      },
    };

    const requests = [
      this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'xbrief' })
      , getCustomFieldInfo(this, 'STAFF').catch(e => this.httpAjaxError(e))
    ]

    Promise.allSettled(requests).then(responses => {
      this.skillLevels = responses && responses.length > 0 
                          && responses[0].value["STAFF-SKILL"] 
                          && responses[0].value["STAFF-SKILL"].properties 
                          && responses[0].value["STAFF-SKILL"].properties.length > 0
                          && responses[0].value["STAFF-SKILL"].properties[0].options != null
                            ? responses[0].value["STAFF-SKILL"].properties[0].options.map(v => { return { value: v, text: v }}) 
                            : [];
    })
    .finally(() => {
      const customDefs = []
      prepareCustomFieldColumnDef(customDefs, this.customFields, { self: this })

      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        for (const c of this.customFields) {
          if (c.type != 'String' && c.type != 'Enum<String>') {
            continue;
          }
          this.badgeFilterFields.push({ value: c.name, text: c.displayName });
        }
      }

      if (customDefs.length > 0) {
        for (const def of customDefs) {
          //set column to read only
          def.editable = false;

          //Reset enableReadonlyStyle to false if found. Readonly Style is not needed in staffPlanner.
          if (def.cellRendererParams?.enableReadonlyStyle === true) {
            def.cellRendererParams.enableReadonlyStyle = false;
          }
        }
        colDefs.push(...customDefs);
      }

      //VIEW permission: Remove column from display list
      filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities);

      colDefs.sort(columnDefSortFunc);
      this.initialColDefs = JSON.parse(JSON.stringify(colDefs));
      this.columnDefs = colDefs;
    });

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.context = {
      componentParent: self
    };
  },
  created() {
    this.getModelInfo('STAFF');

    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn';
    this.staffDataLoaded = {}; //keep track of staff loaded
    this.staffFieldDataLoaded = []; //keep track of staff field data loaded (due to sorting)
    this.entityId = this.isWidget ? this.dataviewId : this.$route.params.id ? this.$route.params.id : 'staff_planner';
    this.userId = this.$store.state.authentication.user.uuId;

    this.isDataView = this.isWidget || this.$router.currentRoute.path.indexOf('/dataview/') === 0;
    this.id = this.isDataView ? this.projectId : this.$route.params.id ? this.$route.params.id : null;
    if (this.id === null) {
      this.staffUsageProject = false;
      let optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-start-to-end');
      if (optionsIndex) {
        this.dateOptions.splice(optionsIndex, 1);
      }
      optionsIndex = this.dateOptions.findIndex(d => d.value === 'project-schedule');
      if (optionsIndex) {
        this.dateOptions.splice(optionsIndex, 1);
      }
    }
    this.nodeChildrenList = null;
    this.nodeId = null;
    
    if (this.staffIds !== null) {
      this.staff = this.staffIds;
      if (this.projectId === null) {
        this.staffUsageProject = false;
        this.dateOptions.splice(1, 2);
      }
    }
              
    if (this.id !== null && !this.isDataView) {
      const self = this;
      projectService.get([{ uuId: this.id}]).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          self.$store.dispatch("breadcrumb/update", data[0].name, { root: true });
          self.project = data[0];
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }     
    this.loadUserProfile(); // User profile holds Staff views  
    this.loadPublicProfile(); // Public profile holds public Staff views
    
    //Declare properties that do not need value changing observer.
    this.resizerWidth = 5;
    this.touchEvent = {
      isTouchStart: false
      , x: 0
      , leftWidth: 0
    }
    
    this.debouncedTouchMoveHandler = debounce(this.touchMoveHandler, 10);

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }

    this.badgeFilterFields = [
      { value: 'activityName', text: this.$t('filter_component.activity_name'), filterStaff: true }
      , { value: 'availability', text: this.$t('filter_component.availability') }
      , { value: 'bookingName', text: this.$t('filter_component.booking_name'), filterStaff: true }
      , { value: 'company', text: this.$t('filter_component.company_name') }
      , { value: 'department', text: this.$t('filter_component.department_name') }
      , { value: 'location', text: this.$t('filter_component.location_name') }
      , { value: 'nonWork', text: this.$t('filter_component.non_work'), filterStaff: true }
      , { value: 'projectName', text: this.$t('filter_component.project_name'), filterStaff: true }
      , { value: 'skill', text: this.$t('filter_component.skill_name') }
      , { value: 'skillLevel', text: this.$t('filter_component.skill_level') }
      , { value: 'staffName', text: this.$t('filter_component.staff_name') }
      , { value: 'staffType', text: this.$t('filter_component.staff_type') }
      , { value: 'stageName', text: this.$t('filter_component.stage_name'), filterStaff: true }
      , { value: 'tag', text: this.$t('filter_component.tag') }
      , { value: 'taskName', text: this.$t('filter_component.task_name'), filterStaff: true }
      , { value: 'usage', text: this.$t('filter_component.usage') }
    ]
  },
  mounted() {
    this.chartResizeHandler();
    window.addEventListener('resize', this.chartResizeHandler);

    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus);
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
  },
  beforeDestroy() {
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';

    if(this.splitterEventState.isMouseDown) {
      this.mouseUpHandler();
    }
    window.removeEventListener('resize', this.chartResizeHandler);
    if (this.$refs['resizer-overlay'] != null) {
      this.$refs['resizer-overlay'].removeEventListener('touchstart', this.touchStartHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
      this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
    }
    
    
    this.nodeChildrenList = null;
    this.nodeId = null;
    this.initialColDefs = null;
    this.cleanup();
    if (this.schedulerRepaintTimeoutId != null) {
      clearTimeout(this.schedulerRepaintTimeoutId);
      this.schedulerRepaintTimeoutId = null;
    }
  },
  computed: {
    importProperties() {
      if (this.importType === 'BOOKING') {
        const docImportProperties = [
          { value: 'color', text: this.$t('field.color') }, 
          { value: 'identifier', text: this.$t('field.identifier') }, 
          { value: 'name', text: this.$t('booking.field.name') }, 
          { value: 'staffs', text: this.$t('booking.field.staffs') }, 
          { value: 'startdate', text: this.$t('booking.field.beginDate') }, 
          { value: 'enddate', text: this.$t('booking.field.closeTime') }, 
          { value: 'project', text: this.$t('booking.field.project') }, 
          { value: 'stages', text: this.$t('booking.field.stage') },
          { value: 'notes', text: this.$t('booking.field.notes') },
          { value: 'desc', text: this.$t('booking.field.description') },
          { value: 'fixedCost', text: this.$t('booking.field.fixedCost') },
          { value: 'currency', text: this.$t('booking.field.currency') },
          { value: 'fixedDuration', text: this.$t('booking.field.fixedDuration') },
          { value: 'duration', text: this.$t('booking.field.estimatedDuration') }
        ];
        
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = docImportProperties.find(v => v.value === cfield.name);
            if (!found) {
              docImportProperties.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
        return docImportProperties;
      }
      else if (this.importType === 'ACTIVITY') {
        const docImportProperties = [
          { value: 'color', text: this.$t('field.color') }, 
          { value: 'identifier', text: this.$t('field.identifier') }, 
          { value: 'name', text: this.$t('activity.field.name') }, 
          { value: 'staffs', text: this.$t('activity.field.staffs') }, 
          { value: 'startdate', text: this.$t('activity.field.startTime') }, 
          { value: 'enddate', text: this.$t('activity.field.closeTime') },  
          { value: 'stages', text: this.$t('activity.field.stage') },
          { value: 'notes', text: this.$t('booking.field.notes') },
          { value: 'desc', text: this.$t('booking.field.description') },
          { value: 'fixedCost', text: this.$t('booking.field.fixedCost') },
          { value: 'currency', text: this.$t('booking.field.currency') },
          { value: 'fixedDuration', text: this.$t('booking.field.fixedDuration') },
          { value: 'duration', text: this.$t('booking.field.estimatedDuration') }
        ];
        
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = docImportProperties.find(v => v.value === cfield.name);
            if (!found) {
              docImportProperties.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
        return docImportProperties;
      }
      else if (this.importType === 'NONWORK') {
        return [
          { value: 'name', text: this.$t('activity.field.name') }, 
          { value: 'staffs', text: this.$t('entity_selector.staff_selector') }, 
          { value: 'startdate', text: this.$t('activity.field.startTime') }, 
          { value: 'enddate', text: this.$t('activity.field.closeTime') }
        ];
      }
      else if (this.importType === 'TASKS') {
        return null;
      }
      return null;
    },
    stageTitle() {
      return this.stageId && this.stageId.indexOf('STAGE_NEW') == -1? this.$t('stage.title_detail'): this.$t('stage.title_new');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    schedulerSpan() {
      if (this.span === 'Daily') {
        return 'day';
      }
      else if (this.span === 'Weekly') {
        return 'week';
      }
      else if (this.span === 'Monthly') {
        return 'month';
      }
      else if (this.span === 'Yearly') {
        return 'year';
      }
      return 'day';
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('staff.grid.no_data') }</span>`
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('staff.grid.loading') }</span>`;
    },
    disableEdit() {
      return this.selected.length != 1;
    },
    allocMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    costMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    durationMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    groupingMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    // rhsChartWidth() {
    //   return this.refs == null? null
    //     : this.showLoadingOverlay && Object.hasOwn(this.refs, 'grid-overlay')
    //     ? this.refs['grid-overlay'].clientWidth
    //     : Object.hasOwn(this.refs, 'splitter-container')
    //     ? this.refs['splitter-container'].clientWidth
    //     : null
    // }
    badgeFilterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    },
    badgeFilterMouseLeaveEvent() {
      return this.isTouchDevice()? null : 'mouseleave';
    },
    badgeFilterTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    },
    priorityNavMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    },
    priorityNavTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.gridReady = true;
      this.loadColumnSettings(this, this.layoutProfile[this.getProfileEntryName('list')]);
      
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] && this.$refs['lhs-grid']) {
        this.$refs['lhs-grid'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')].width;
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] && this.$refs['rhs-chart']) {
        this.$refs['rhs-chart'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width;
        //Expecting the width value from the profile is percetage value in string
        //Use parseFloat to get the number and remove '%' suffix.
        const widthFloat = parseFloat(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width);
        this.schedulerWidth = `${parseInt(this.$refs['rhs-chart'].parentNode.getBoundingClientRect().width * widthFloat / 100) - 2}px`;
      }
      
      if (this.schedulerDataRenderReady === true) {
        // this.schedulerDataRenderReady = false;
        params.api.setRowData(this.rowData);
        return;
      }
      if (this.rowDataReady === true) {
        params.api.setRowData(this.rowData);
        return;
      }
    },
    async loadUserProfile() {
      const self = this;
      const userProfile = await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {
        return value.length > 0 ? value[0] : {};
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      // move the views from the old array in a single profile to their own profiles
      if (Object.prototype.hasOwnProperty.call(userProfile, 'staffviews')) {
        const list = [];
        for (const profile of userProfile.staffviews) {
          profile.type = 'staff';
          profile.editingPermissions = self.userId;
          profile.sharingMembers = cloneDeep(self.userId);
          profile.sharedVisibility = 'private';
          await viewProfileService.createPreset([profile], this.userId).then((response) => {
            const data = response.data[response.data.jobCase];
            profile.uuId = data[0].uuId;
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
          list.push(profile);
        }
        delete userProfile.staffviews;
        await viewProfileService.update([userProfile], self.userId).then(() => {
          //
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        self.staffViews = list;
      }
      else {
        const views = await this.$store.dispatch('data/presetviewProfileList', self.userId).then((value) => {
          return value;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
        });
        
        this.addViews(views);
      }
    },
    async loadPublicProfile() {
      if (!localStorage.companyId) {
        const data = await companyService.list({limit: -1, start: 0}).then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line no-console
          return null;
        });

        if (data != null) {
          const company = data.filter(d => d.type === 'Primary');
          if (company.length > 0) {
            localStorage.companyId = company[0].uuId;
          }
        }
      }

      const views = await this.$store.dispatch('data/viewProfileListPublic', localStorage.companyId).then((value) => {
        return value;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
      
      this.addViews(views);
    },
    addViews(views) {
      for (const view of views) {
        // if not in the list, add it
        if (view.type === this.getViewType() &&
            this.staffViews.findIndex((i) => i.uuId === view.uuId) === -1) {
          this.showInfo.push(false);
          this.staffViews.push(view);
          if (this.useDefault && view.defaultView) {
            this.loadViewSettings(view);
          }
        }
      }
      
      this.staffViews.sort(function( a, b ) {
        if ( a.name === null ||
             (b.name !== null && a.name.toLowerCase() < b.name.toLowerCase() )){
          return -1;
        }
        if ( b.name === null ||
             a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      });
    },
    changeFilter(value) {
      this.filterValue = value;
      this.layoutProfile.staffFilterText = value;
      this.updateLayoutProfile();
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.filtering');
      setTimeout(() => {
        this.assignData();
      }, 500);
    },
    loadViewSettings(view) {
      let update = false;
      this.loadingView = true;
      this.holdUpdateUntilTreeDataChanged = true; //signal scheduler not to trigger updateData() repeatedly when settings changed. Only trigger once when treeData changed
      
      this.loadColors(view.coloring);
      this.layoutProfile[this.getProfileEntryName('coloring')] = this.coloring;
      
      this.filterText = view.filterText ? view.filterText : '';
      this.filterValue = this.filterText;
      
      if (view.filter) {
        for (const f of view.filter) {
          if (f.name.indexOf(']:') === -1) {
            const idx = f.name.indexOf(':');
            if (idx !== -1) {
              f.name = f.name.substr(0, idx) + ' [Is]' + f.name.substr(idx, f.name.length);
            }
          }
        }
      }
      this.badgeFilters = Array.isArray(view.filter) ?   cloneDeep(view.filter) : [];
      this.layoutProfile[this.getProfileEntryName('filter')] = cloneDeep(this.badgeFilters);
      this.layoutProfile.staffFilterText = this.filterText;
      
      //this.eventTypeValues = view.typeValues;
      this.layoutProfile[this.getProfileEntryName('typevalues')] = this.eventTypeValues;
      
      if(Object.prototype.hasOwnProperty.call(view, 'staffDates')) {
        update = this.dates !== view['staffDates'];
        this.$set(this, 'dates', view['staffDates']);
        this.layoutProfile.dates = view.staffDates;
        if (view.staffDates == null) {
          // Null means custom date. Use recorded range.
          try {
            this.$set(this, 'startDate', view['staffStartDate']);
            this.layoutProfile.startDate = view.staffStartDate;
            this.$set(this, 'endDate', view['staffEndDate']);
            this.layoutProfile.endDate = view.staffEndDate;
          } catch (e) {
            console.log("Invalid date range on restore. Using default"); // eslint-disable-line no-console
            this.datesChanged();
          }
        } else {
          // Range is calculated
          this.datesChanged();
        }
      }
      if(Object.prototype.hasOwnProperty.call(view, 'staffTimescale')) {
        this.$set(this, 'span', view['staffTimescale']);
        this.layoutProfile.staffTimescale = view.staffTimescale;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'staffUsageProject')) {
        this.$set(this, 'staffUsageProject', view['staffUsageProject']); 
        this.layoutProfile.staffUsageProject = view.staffUsageProject;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'staffAlloc')) {
        this.$set(this, 'staffAlloc', view['staffAlloc']); 
        this.layoutProfile.staffAlloc = view.staffAlloc;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'grouping')) {
        this.$set(this, 'grouping', cloneDeep(view['grouping']));
        this.layoutProfile.grouping = cloneDeep(view.grouping);
      }
      if(Object.prototype.hasOwnProperty.call(view, 'showGeneric')) {
        this.$set(this, 'showGeneric', view['showGeneric']); 
        this.layoutProfile.showGeneric = view.showGeneric;
      }
      
      if (this.grouping.staff &&
            !this.staffLoaded) {
        update = true;
      }
      
      if (this.showGeneric &&
            !this.genericLoaded) {
        update = true;
      }
      
      if(Object.prototype.hasOwnProperty.call(view, 'showUnders')) {
        this.$set(this, 'showUnders', view['showUnders']); 
        this.layoutProfile.showUnders = view.showUnders;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'showOptimal')) {
        this.$set(this, 'showOptimal', view['showOptimal']); 
        this.layoutProfile.showOptimal = view.showOptimal;
      }
      if(Object.prototype.hasOwnProperty.call(view, 'showOvers')) {
        this.$set(this, 'showOvers', view['showOvers']); 
        this.layoutProfile.showOvers = view.showOvers;
      }
      
      if(Object.prototype.hasOwnProperty.call(view, 'coloring')) {
        this.$set(this, 'colors', cloneDeep(view['coloring'])); 
        this.layoutProfile[this.getProfileEntryName('coloring')] = cloneDeep(view.coloring);
      }
      
      if(Object.prototype.hasOwnProperty.call(view, 'show')) {
        if (typeof view.show.usage === 'undefined') {
          view.show.usage = false;
        }
        if (typeof view.show.required === 'undefined') {
          view.show.required = true;
        }
        if (typeof view.show.available === 'undefined') {
          view.show.available = false;
        }
        if (typeof view.show.usageTasks === 'undefined') {
          view.show.usageTasks = true;
        }
        if (typeof view.show.displayFilteredMetrics === 'undefined') {
          view.show.displayFilteredMetrics = false;
        }
        if (typeof view.show.usageBookings === 'undefined') {
          view.show.usageBookings = true;
        }
        if (typeof view.show.usageActivities === 'undefined') {
          view.show.usageActivities = true;
        }
        this.$set(this, 'show', cloneDeep(view['show'])); 
        this.layoutProfile[this.getProfileEntryName('show')] = cloneDeep(view.show);
      }

      if (view.list != null) {
        this.layoutProfile[this.getProfileEntryName('list')] = cloneDeep(view.list);
        this.loadColumnSettings(this, this.layoutProfile[this.getProfileEntryName('list')]);
      } else if (this.gridOptions.api) {
        this.gridOptions.api.setColumnDefs([]);
        this.gridOptions.api.setColumnDefs(JSON.parse(JSON.stringify(this.initialColDefs)));
        this.needRedrawViewport = true;
      }

      if (view.projectGanttLHSGrid != null && this.$refs['lhs-grid']) {
        this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] = cloneDeep(view.projectGanttLHSGrid);
        this.$refs['lhs-grid'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')].width;
      }
      if (view.projectGanttRHSChart != null && this.$refs['rhs-chart']) {
        this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] = cloneDeep(view.projectGanttRHSChart);
        this.$refs['rhs-chart'].style.width = this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width;
        //Expecting the width value from the profile is percetage value in string
        //Use parseFloat to get the number and remove '%' suffix.
        const widthFloat = parseFloat(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')].width);
        this.schedulerWidth = `${parseInt(this.$refs['rhs-chart'].parentNode.getBoundingClientRect().width * widthFloat / 100) - 2}px`;
      }
            
      // save the view name in the profile
      this.layoutProfile[this.getProfileEntryName('viewName')] = view.name;
      
      // prevent a delay when the user clicks to load a view by loading the columns on the next tick
      setTimeout(() => {
        //Reset folderState as current folderState may not valid for loaded view settings
        this.folderState = {}
        // Save the new layout after applying it
        this.updateLayoutProfile({ clearViewName: false });
        if (update) {
          //When toggling group: generic or non-generic staff, onViewChanged event is not fired. Hence, redrawViewport() is not triggered.
          //Workaround: set a flag and manually trigger redrawViewport() in rowDataUpdated() event later.
          this.needRedrawViewport = true;
        }
        this.updateGrid();
          
        // expand the tree after adjusting the grouping so that the zoom level is correct
        if (typeof view.expandLevel !== 'undefined') {
          this.layoutProfile.staffExpandLevel = this.expandLevel = view.expandLevel;
        }
          
        this.loadingView = false;
        
        this.$store.dispatch("breadcrumb/updateView", view.name, { root: true });
      }, 0);
    },
    expandLevels() {
      if (typeof this.layoutProfile.staffExpandLevel !== 'undefined') {
        this.expandLevel = this.layoutProfile.staffExpandLevel;
      }
    },
    savePreset() {
      this.saveName = null;
      this.saveIndex = -1;
      this.saveProfile = { 
        type: this.getViewType(),
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        staffDates: cloneDeep(this.dates), 
        staffStartDate: cloneDeep(this.startDate), 
        staffEndDate: cloneDeep(this.endDate), 
        staffTimescale: cloneDeep(this.span),
        staffUsageProject: cloneDeep(this.staffUsageProject),
        staffAlloc: cloneDeep(this.staffAlloc),
        grouping: cloneDeep(this.grouping),
        showGeneric: cloneDeep(this.showGeneric),
        expandLevel: cloneDeep(this.expandLevel), 
        showUnders: cloneDeep(this.showUnders),
        showOptimal: cloneDeep(this.showOptimal),
        showOvers: cloneDeep(this.showOvers),
        show: cloneDeep(this.show),
        coloring: cloneDeep(this.coloring),
        filter: cloneDeep(this.badgeFilters),
        // filter: cloneDeep(this.filter),
        typeValues: cloneDeep(this.eventTypeValues),
        filterText: cloneDeep(this.filterText)
      };
      if (this.layoutProfile[this.getProfileEntryName('list')] != null) {
        this.saveProfile.list = cloneDeep(this.layoutProfile[this.getProfileEntryName('list')])
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')]);
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')]);
      }

      this.promptSaveShow = true;
    },
    async updateUsers(profile, updateUsers, service) {
      if (updateUsers) {
        const users = updateUsers.split(',');
        for (const user of users) {
          const profileData = await service.list(this.entityId, user)
           .then(response => {
             return response.data[response.data.jobCase];
          });
         
          if (profileData.length > 0) {
            profileData[0][this.getProfileEntryName('coloring')] = profile.coloring;
            profileData[0][this.getProfileEntryName('filter')] = profile.filter;
            profileData[0]['staffFilterText'] = profile.filterText;
            profileData[0][this.getProfileEntryName('typevalues')] = profile.typeValues;
            profileData[0]['staffDates'] = profile.staffDates;
            profileData[0]['staffStartDate'] = profile.staffStartDate;
            profileData[0]['staffEndDate'] = profile.staffEndDate;
            profileData[0]['span'] = profile.staffTimescale;
            profileData[0]['staffUsageProject'] = profile.staffUsageProject;
            profileData[0]['staffAlloc'] = profile.staffAlloc;
            profileData[0]['grouping'] = profile.grouping;
            profileData[0]['showGeneric'] = profile.showGeneric;
            profileData[0]['showUnders'] = profile.showUnders;
            profileData[0]['showOptimal'] = profile.showOptimal;
            profileData[0]['showOvers'] = profile.showOvers;
            profileData[0][this.getProfileEntryName('show')] = profile.show;
            profileData[0][this.getProfileEntryName('projectGanttLHSGrid')] = profile.projectGanttLHSGrid;
            profileData[0][this.getProfileEntryName('projectGanttRHSChart')] = profile.projectGanttRHSChart;
            profileData[0][this.getProfileEntryName('viewName')] = profile.name;
            profileData[0][this.getProfileEntryName('list')] = profile.list;
            
            await service.update(profileData, this.entityId, user)
          }
        }
      }
    },
    confirmSaveOk({ profile, newDefault, updateUsers, sharing }) {      
      if (newDefault) {
        // find the existing default view and turn it off
        const defaultView = this.staffViews.find(v => v.defaultView);
        if (defaultView) {
          defaultView.defaultView = false;
          viewProfileService.updatePreset([defaultView], this.userId)
          .catch((e) => {
            console.error(e); // eslint-disable-line no-console
          });
        }
      }
      
      this.updateUsers(profile, updateUsers, layoutProfileService);
      
      if (this.saveIndex !== -1) {
        this.staffViews.splice(this.saveIndex, 1, profile);
      }
      else {
        this.addViews([profile]);
      } 
      
      if (!sharing) {     
        // save the view name in the profile
        this.layoutProfile[this.getProfileEntryName('viewName')] = profile.name;
        this.$store.dispatch("breadcrumb/updateView", profile.name, { root: true });
        this.updateLayoutProfile({ clearViewName: false });
      }      
    },
    updateColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = { 
        name: profile.name,
        uuId: profile.uuId,
        type: this.getViewType(),
        defaultView: profile.defaultView,
        sharedVisibility: cloneDeep(profile.sharedVisibility),
        sharingMembers: cloneDeep(profile.sharingMembers),
        editingPermissions: cloneDeep(profile.editingPermissions),
        staffDates: cloneDeep(this.dates), 
        staffStartDate: cloneDeep(this.startDate), 
        staffEndDate: cloneDeep(this.endDate), 
        staffTimescale: cloneDeep(this.span),
        staffUsageProject: cloneDeep(this.staffUsageProject),
        staffAlloc: cloneDeep(this.staffAlloc),
        grouping: cloneDeep(this.grouping),
        showGeneric: cloneDeep(this.showGeneric),
        expandLevel: cloneDeep(this.expandLevel), 
        showUnders: cloneDeep(this.showUnders),
        showOptimal: cloneDeep(this.showOptimal),
        showOvers: cloneDeep(this.showOvers),
        show: cloneDeep(this.show),
        coloring: cloneDeep(this.coloring),
        filter: cloneDeep(this.badgeFilters),
        // filter: cloneDeep(this.filter),
        typeValues: cloneDeep(this.eventTypeValues),
        filterText: cloneDeep(this.filterText)
      };
      if (this.layoutProfile[this.getProfileEntryName('list')] != null) {
        this.saveProfile.list = cloneDeep(this.layoutProfile[this.getProfileEntryName('list')])
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')]);
      }
      if (this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')]);
      }
      this.saveIndex = index;
      this.promptSaveShow = true;
    },
    copyColumnSettings(name, profile) {
      this.saveName = `${name} ${this.$t('dataview.copy_text')}`;
      this.saveProfile = { 
        name: `${name} ${this.$t('dataview.copy_text')}`,
        uuId: null,
        type: this.getViewType(),
        sharedVisibility: 'private',
        sharingMembers: cloneDeep(this.userId),
        editingPermissions: cloneDeep(this.userId),
        staffDates: cloneDeep(profile.staffDates), 
        staffStartDate: cloneDeep(profile.staffStartDate), 
        staffEndDate: cloneDeep(profile.staffEndDate), 
        staffTimescale: cloneDeep(profile.staffTimescale),
        staffUsageProject: cloneDeep(profile.staffUsageProject),
        staffAlloc: cloneDeep(profile.staffAlloc),
        grouping: cloneDeep(profile.grouping),
        showGeneric: cloneDeep(profile.showGeneric),
        expandLevel: cloneDeep(profile.expandLevel), 
        showUnders: cloneDeep(profile.showUnders),
        showOptimal: cloneDeep(profile.showOptimal),
        showOvers: cloneDeep(profile.showOvers),
        show: cloneDeep(profile.show),
        coloring: cloneDeep(profile.coloring),
        filter: cloneDeep(profile.badgeFilters),
        // filter: cloneDeep(profile.filter),
        typeValues: cloneDeep(profile.typeValues),
        filterText: cloneDeep(profile.filterText)
      };
      if (profile.list != null) {
        this.saveProfile.list = cloneDeep(profile.list)
      }
      if (profile.projectGanttLHSGrid != null) {
        this.saveProfile.projectGanttLHSGrid = cloneDeep(profile.projectGanttLHSGrid);
      }
      if (profile.projectGanttRHSChart != null) {
        this.saveProfile.projectGanttRHSChart = cloneDeep(profile.projectGanttRHSChart);
      }
      this.saveIndex = -1;
      this.promptSaveShow = true;
    },
    shareColumnSettings(index, name, profile) {
      this.saveName = name;
      this.saveProfile = profile;
      this.saveIndex = index;
      this.promptShareShow = true;
    },
    removeColumnSettings(index) {
      this.confirmDeleteViewShow = true;
      this.deleteViewIndex = index;
    },
    confirmDeleteViewOk() {
      const toRemove = this.staffViews.splice(this.deleteViewIndex, 1);
      
      viewProfileService.remove([{ uuId: toRemove[0].uuId }],
                        this.userId).then(() => {  
                        //
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    getData(id) {
      if (id in locations) {
        return locations[id];
      }
      else if (id in skills) {
        return skills[id];
      }
      else if (id in companies) {
        return companies[id];
      }
      else if (id in departments) {
        return departments[id];
      }
      return null;
    },
    detailLinkTag(params) {
      if (params.data && params.data.type) {
        return this.$t(`staff.group.${params.data.type}`);
      }
      else if (!params.data) {
        const id = params.node.key.slice(params.node.key.length - 36);
        const data = this.getData(id);
        if (data && data.type) {
          return this.$t(`staff.group.${data.type}`);
        }
      }
      else if (params.data && params.data.generic) {
        return this.$t('staff.group.generic');
      }
      return this.$t('staff.group.staff');
    },
    detailLinkTagClass(params) {
      let data = params.data;
      
      if (!data) {
        const id = params.node.key.slice(params.node.key.length - 36);
        data = this.getData(id);
      }
      
      if (data && data.type) {
        if (data.type === 'company') {
          return 'tag-purple';
        }
        else if (data.type === 'location') {
          return 'tag-teal';
        }
        else if (data.type === 'stage') {
          return 'tag-stage';
        }
        else if (data.type === 'project') {
          return 'tag-project';
        }
        else if (data.type === 'department') {
          return 'tag-indigo';
        }
        else if (data.type === 'skill') {
          return 'tag-red';
        }
        else if (data.type === 'task') {
          return 'tag-yellow';
        }
        else if (data && data.generic) {
          return 'tag-blue';
        }
      }
      
      return 'tag-pumpkin';
    },
    detailLinkMouseOver(params, e) {
      this.renderPopup(params.data, e);
    },
    detailLinkContainerMouseLeave() {
      this.popupRemoveMenu();
    },
    renderPopup(data, event) {
      this.popupRemoveMenu();
      var target = event.target || event.srcElement;
      let avatartag = '';
      let position = '';
      let staffType = '';
      let location = '';
      let department = '';
      let skills = '';
      let company = '';
      let quota = 1;
      let begin = 0;
      let until = 0;
      // let start = 0;
      // let end = 0;
      let name = target.getAttribute('data-name');
      if (name) {
        name = name.replace(/&apos;/, '\'');
      }
      let type = target.hasAttribute('data-type') ? target.getAttribute('data-type') : null;
      // start = target.hasAttribute('data-date') ? parseInt(target.getAttribute('data-date')) : 0;
      let generic = target.hasAttribute('data-generic') ? target.getAttribute('data-generic') === 'true' : false;

      type = data.type;
      if (data.type === 'staff') {
        const avatar = data.avatar && data.avatar !== '00000000-0000-0000-0000-000000000000' ? `${process.env.BASE_URL}api/file/${data.avatar}` : null;
        if (avatar) {
          avatartag = `<div class="section-expand avatar-align"><img class="img-avatar smaller" style="object-fit:cover" src="${avatar}"/></div>`;
        }
        name = data.name;
        position = data.position;
        staffType = data.staffType;
        location = data.location;
        department = data.departmentList ? data.departmentList.map(d => { return d.name }).join(', ') : data.department;
        skills = data.skillList ? data.skillList.map(s => { return s.name }).join(', ') : '';
        company = data.company;
        begin = data.begin;
        until = data.until;
        generic = data.generic;
        quota = data.quota;
      }
      
      
      if (!name) {
        return;
      }
      const container = document.getElementById('pt-grid');
      const rect = target.getBoundingClientRect();
      let top = rect.top - 105;
      const containerRect = container.getBoundingClientRect();
      const containerHeight = container.offsetTop + container.offsetHeight;
      const htmlContentStr = `
        <div 
          class="cell-tooltip pointer-events ag-tooltip-custom ag-ltr ag-popup-child" 
          role="dialog" 
          aria-label="Tooltip" >
          <div data-v-409e30f4="" class="header-box">
            <div class="tag ${this.getTagClass({ type: type, generic: generic })}">${this.getTagLabel({ type: type, generic: generic })}</div>
            <div class="header">${name}</div>
          </div>
          ${avatartag}
          <div class="d-inline-block">
            ${this.formatStaff(position, staffType, location, department, skills, company, begin, until, quota, generic)}
          </div>
        </div>
      `;
      if (top + 105 > containerHeight) {
        top -= generic? 155 : 195;
      }
      const cellMenu = this.htmlToElement(htmlContentStr.trim().replaceAll(/>\n[\s]+/g, '>'));
      cellMenu.style.left =`${rect.left - containerRect.left}px`;
      cellMenu.style.top = `${top}px`;

      let popupElement = null;
      popupElement = document.createElement('div');
      popupElement.classList.add('ag-theme-balham')
      popupElement.classList.add('ag-popup');
      popupElement.classList.add('header-group-column-popup');
      popupElement.appendChild(cellMenu);
      document.querySelector('#pt-grid').appendChild(popupElement);

      if (popupElement.firstChild.offsetLeft + popupElement.firstChild.offsetWidth > container.offsetWidth) {
        cellMenu.style.left = `${container.offsetWidth - popupElement.firstChild.offsetWidth}px`;
      }
      this.popupElement = popupElement;
      popupElement.addEventListener('mouseleave', this.popupRemoveMenu);
    },
    popupRemoveMenu() {
        
      if (this.popupElement) {
        this.popupElement.removeEventListener('mouseleave', this.popupRemoveMenu);
        this.popupElement.remove();
        delete this.popupElement;
      }
      
    },
    getTagLabel(data) {
      if (data.type) {
        if (data.type === 'staff' && data.generic) {
          return this.$t('staff.group.generic');
        }
        return this.$t(`staff.group.${data.type}`);
      }
      return this.$t(`staff.group.staff`);
    },
    formatStaff(position, staffType, location, department, skills, company, begin, until, quota, generic) {
      let ret = '';
      if (position) {
        ret += `<p>
          <span>${this.$t('staff.field.position')}: </span>
          <span>${position}</span>
        </p>`;
      }
      if (staffType) {
        ret += `<p>
          <span>${this.$t('staff.field.type')}: </span>
          ${staffType}
        </p>`;
      }
      if (location) {
        ret += `<p>
          <span>${this.$t('staff.field.location')}: </span>
          ${location}
        </p>`;
      }
      if (department) {
        ret += `<p>
          <span>${this.$t('staff.field.department')}: </span>
          ${department}
        </p>`;
      }
      if (skills && this.canView('STAFF', ['SKILL'])) {
        ret += `<p class="staff-tooltip-skills">
          <span>${this.$t('staff.field.skills')}: </span>
          ${skills}
        </p>`;
      }
      if (company) {
        ret += `<p>
          <span>${this.$t('staff.field.company')}: </span>
          ${company}
        </p>`;
      }
      if (quota && generic) {
        ret += `<p>
          <span>${this.$t('staff.field.resourceQuota')}: </span>
          ${quota}
        </p>`;
      }
      if (begin) {
        ret += `<p>
          <span>${this.$t('staff.dates')}: </span>
          ${formatTooltipDate(new Date(begin), false)} - ${until && until !== 253402214400000 && until !== 32503680000000 ? formatTooltipDate(new Date(until), false) : ''}
        </p>`;
      }
      else if (until && until !== 253402214400000 && until !== 32503680000000) {
        ret += `<p>
          <span>${this.$t('staff.dates')}: </span>
           - ${formatTooltipDate(new Date(until), false)}
        </p>`;
      }
      return ret;
    },
    getTagClass(data) {
    
      if (data && data.type) {
        if (data.type === 'company') {
          return 'tag-company';
        }
        else if (data.type === 'location') {
          return 'tag-location';
        }
        else if (data.type === 'stage') {
          return 'tag-stage';
        }
        else if (data.type === 'project') {
          return 'tag-project';
        }
        else if (data.type === 'department') {
          return 'tag-department';
        }
        else if (data.type === 'skill') {
          return 'tag-skill';
        }
        else if (data.type === 'task') {
          return 'tag-task';
        }
        else if (data.type === 'activity') {
          return 'tag-activity';
        }
        else if (data.type === 'booking') {
          return 'tag-booking';
        }
        else if (data.type === 'staff' && data.generic) {
          return 'tag-staff-generic';
        }
        else if (data.type === 'vacation') {
          return 'tag-vacation';
        }
        else if (data.type === 'new') {
          return 'tag-new';
        }
        else if (data.type === 'resource' ||
                 data.type === 'resourceUnit') {
          return 'tag-resource';
        }
      }
      
      return 'tag-staff';
    },
    htmlToElement(html) {
      var template = document.createElement('template');
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },
    detailLinkTooltip(params) {
      if (params.data && params.data.type === 'task') {
        return true;
      }
      return false;
    },
    detailLinkLabel(params) {
      if (params.data) {
        return params.data.name;
      }
      else {
        const id = params.node.key.slice(params.node.key.length - 36);
        const data = this.getData(id);
        if (data) {
          return data.name;
        }
      }
      // If a node is not found we return N/A, this should not happen
      console.warn(`node not found ${params.node.key}`); // eslint-disable-line no-console
      return "N/A";
    },
    getRowId(params) {
      const data = params.data;
      if (typeof data !== 'undefined') {
        if (data.type === 'company') {
          return data.companyUuId;
        }
        else if (data.type === 'location') {
          if (this.grouping.company) {
            return `${data.companyUuId}${data.locationUuId}`;
          }
          return data.locationUuId;
        }
        else if (data.type === 'stage') {
          let stageId = '';
          if (this.grouping.company && data.companyUuId) {
            stageId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            stageId += data.locationUuId;
          }
          stageId += data.stageUuId;
          return stageId;
        }
        else if (data.type === 'project') {
          let projectId = '';
          if (this.grouping.company && data.companyUuId) {
            projectId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            projectId += data.locationUuId;
          }
          if (this.grouping.stage && data.stageUuId) {
            projectId += data.stageUuId;
          }
          projectId += data.projectUuId;
          return projectId;
        }
        else if (data.type === 'department') {
          let deptId = '';
          if (this.grouping.company && data.companyUuId) {
            deptId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            deptId += data.locationUuId;
          }
          if (this.grouping.stage && data.stageUuId) {
            deptId += data.stageUuId;
          }
          if (this.grouping.project && data.projectUuId) {
            deptId += data.projectUuId;
          }
          deptId += data.departmentUuId;
          return deptId;
        }
        else if (data.type === 'skill') {
          let skillId = '';
          if (this.grouping.company && data.companyUuId) {
            skillId += data.companyUuId;
          }
          if (this.grouping.location && data.locationUuId) {
            skillId += data.locationUuId;
          }
          if (this.grouping.stage && data.stageUuId) {
            skillId += data.stageUuId;
          }
          if (this.grouping.project && data.projectUuId) {
            skillId += data.projectUuId;
          }
          if (this.grouping.department && data.departmentUuId) {
            skillId += data.departmentUuId;
          }
          
          skillId += data.skillUuId;
          return skillId;
        }
        
        // for staff we need to make sure the id is unique when we clone them under department and skills
        let staffId = '';
        if (this.grouping.stage && data.stageUuId) {
          staffId += data.stageUuId;
        }
        if (this.grouping.project && data.projectUuId) {
          staffId += data.projectUuId;
        }
        if (this.grouping.department && data.departmentUuId) {
          staffId += data.departmentUuId;
        }
        if (this.grouping.skills && data.skillUuId) {
          staffId += data.skillUuId;
        }
        staffId += data.uuId;
        return staffId;
      }
    },
    allProjects() {
      this.assignData();
    },
    taskOpen(selectedId, projectUuid, readonly) {
      this.taskEdit.uuId = selectedId;
      this.taskEdit.projectUuid = projectUuid;
      this.taskEdit.readonly = readonly;
      this.taskEditShow = true;
    },
    activityOpen(selectedId/** , readonly*/) {
      this.activityEdit.uuId = selectedId;
      this.activityEdit.readonly = !this.canEdit('ACTIVITY');
      this.activityEditShow = true;
    },
    bookingOpen(selectedId/** , readonly*/) {
      this.bookingEdit.uuId = selectedId;
      this.bookingEdit.readonly = !this.canEdit('BOOKING');
      this.bookingEditShow = true;
    },
    calcActuals(staff) {
      let actualCost = 0;
      let actualDuration = 0;
      for (const st of staff) {
        if (st.duration) {
          actualDuration += st.duration * 60000;
          if (st.uuId in staffSections) {
            actualCost += (st.duration / 60) * staffSections[st.uuId].perhourCost;
          }
        }
      }
      return { actualCost, actualDuration };
    },
    async modalSuccessNoReload({ data, type, staff, origStaff, stage, stageColor, parentChanged, hasSplit }) {
      delete lookups[data.uuId];  // remove any note lookups
      
      if (hasSplit) {
        this.updateGrid();
        return;
      }
      
      const { actualCost, actualDuration } = this.calcActuals(staff);
      const evt = {
        uuId: data.uuId,
        id: data.uuId,
        name: type === 'task' ? `${data.fullPath.slice(0, data.fullPath.lastIndexOf('\n')).replace(/\n/g, ' / ')} / ${data.name}` : data.name,
        type: type,
        eventColor: data.color,
        startDate: data.duration.startDate,
        startTime: data.duration.startTime,
        closeDate: data.duration.closeDate,
        closeTime: data.duration.closeTime,
        duration: convertDisplayToDuration(data.duration.value).value,
        value: convertDisplayToDuration(data.duration.value).value,
        stageName: stage,
        stageColor: stageColor,
        tp: typeof data.progress !== 'undefined' ? data.progress / 100 : null,
        rebates: data.rebates,
        fixedCost: data.fixedCost,
        actualCost: actualCost,
        fixedDuration: convertDisplayToDuration(data.fixedDuration).value * 60000,
        actualDuration: actualDuration
      };
      
      if (parentChanged) {
        // get the task from the backend
        data.fullPath = await taskService.get([{ uuId: data.uuId }], []).then(response => {
          const listName = response.data.jobCase;
          const resdata = response.data[listName] || [];
          if(resdata.length > 0) {
            return resdata[0].fullPath;
          }
          return data.fullPath;
        });
        evt.name = `${data.fullPath.slice(0, data.fullPath.lastIndexOf('\n')).replace(/\n/g, ' / ')} / ${data.name}`;
      }
      
      for (const stf of staff) {
        evt.staffUuid = stf.uuId;
        evt.utilization = stf.utilization;
        if (origStaff.findIndex(s => s.uuId === stf.uuId) !== -1) {
          this.processUpdate(evt);
        }
        else if (origStaff.length === 1 && staff.length === 1) {
          // staff was replaced with a different staff
          evt.origStaffUuid = origStaff[0].uuId;
          this.processUpdate(evt);
        }
        else {
          this.processAdd(evt);
        }
      }
      
      for (const ostf of origStaff) {
        if (staff.findIndex(s => s.uuId === ostf.uuId) === -1) {
          // the staff was removed
          const events = staffEvents[ostf.uuId];
          const eIdx = events.findIndex(e => e.id === data.uuId);
          if (eIdx !== -1) {
            events.splice(eIdx, 1);
          }
          if (type === 'task' ||
            type === 'booking' ||
            type === 'activity') {
            const stfSec = staffSections[ostf.uuId];
            const projId = data.project.uuId;
            // we also need to remove the project from the staff projectList if no other tasks or bookings have this project
            this.updateStaffProjects(projId, type, stfSec);
          }
        }
      }
      
      this.assignData();
    },
    modalSuccess() {
      if (!this.isDataView) {
        // the staff list may have changed
        this.staff.splice(0, this.staff.length);
      }
      this.needRefreshClientRowModel = true;
      this.needRedrawViewport = true;
      this.updateGrid();
    },
    async vacationSuccess({ event }) {
      const start = typeof event.startDate === 'string' ? event.startDate : moment(Date.UTC(event.startDate.getFullYear(), event.startDate.getMonth(),
                  event.startDate.getDate(), event.startDate.getHours(),
                  event.startDate.getMinutes(), event.startDate.getSeconds())).utc().format('YYYY-MM-DD');
      const end = typeof event.endDate === 'string' ? event.endDate : moment(Date.UTC(event.endDate.getFullYear(), event.endDate.getMonth(),
                  event.endDate.getDate(), event.endDate.getHours(),
                  event.endDate.getMinutes(), event.endDate.getSeconds())).utc().format('YYYY-MM-DD');
      
      const startHour = event.startHour ? event.startHour.split(':') : null;
      const endHour = event.endHour ? event.endHour.split(':') : null;
      
      await calendarService.update([{
        uuId: event.uuId,
        name: event.name,
        isWorking: event.isWorking,
        type: event.isWorking ? 'Working' : 'Leave',
        startDate: start,
        startHour: startHour !== null ? startHour[0] * 60 * 60 * 1000 + startHour[1] * 60 * 1000 : null,
        endDate: end,
        endHour: endHour !== null ? endHour[0] * 60 * 60 * 1000 + endHour[1] * 60 * 1000 : null
      }]);
      
      this.processUpdate({
        uuId: event.uuId,
        id: event.uuId,
        name: event.name,
        type: 'vacation',
        startDate: start,
        startTime: event.isWorking ? event.startHour : this.getStartTime(),
        closeDate: end,
        closeTime: event.isWorking ? event.endHour : this.getCloseTime(),
        staffUuid: this.vacationEdit.staffUuid,
        calendar_type: event.isWorking ? 'Working' : 'Leave',
        v_StartHour: startHour !== null ? [startHour[0] * 60 * 60 * 1000 + startHour[1] * 60 * 1000] : null,
        v_EndHour: endHour !== null ? [endHour[0] * 60 * 60 * 1000 + endHour[1] * 60 * 1000] : null
      });
    },
    getStartTime() {
      if (this.show.hideNonWorking) {
        const first = this.show.work_hours.first_hour;
        if (first < 10) {
          return `0${first}:00`;
        }
        else {
          return `${first}:00`;
        }
      }
      return '00:00';
    },
    getCloseTime() {
      if (this.show.hideNonWorking) {
        const last = this.show.work_hours.last_hour;
        if (last < 10) {
          return `0${last}:00`;
        }
        else {
          return `${last}:00`;
        }
      }
      return '23:59';
    },
    rangeSelected() {
      this.holdUpdateUntilTreeDataChanged = true;
      this.datesChanged();
      this.updateGrid();
    },
    daySelected() {
      this.holdUpdateUntilTreeDataChanged = true;
      this.highlightRefresh = false;
      if (moment(this.endDate).unix() < moment(this.startDate).unix()) {
        this.endDate = moment(this.startDate).add(7, 'days').format('YYYY-MM-DD');
      }
      this.updateGrid();
    },
    checkDates() {
      if (this.startDate === null && this.endDate === null) {
        this.datesChanged();
        return true;
      }
      
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      
      const diff = end - start;

      if (end < start || this.startDate === '' || this.endDate === '' ||
          !this.startDate || !this.endDate) {
       this.alertMsg = this.$t('staff.error.invalid_date_range');
        this.alertError = true;
        return false;
      }
      else if (this.span === 'Monthly' &&
               diff / (1000 * 3600 * 24) < 32) {
        this.blockMonthly = true;
        this.span = 'Weekly';
      }

      
      return true;
    },
    updateGrid() {
      if (!this.checkDates()) {
        return;
      }
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.grid.loading_list');
      this.alertMsg = null;
      ServerSideDatasource(this);
    },
    fileImport() {
      this.selectImportShow = true;
    },
    fileExport() {
      this.fields = this.gridOptions.columnApi.getAllDisplayedColumns().filter(f => f.colId !== 'ag-Grid-AutoColumn').map(c => c.colId);
      this.exportToFile = true;
    },
    async onSelectImportTypeOk(type) {
      await getCustomFieldInfo(this, type.toUpperCase()).catch(e => this.httpAjaxError(e));
      this.$set(this, 'importType', type.toUpperCase());
      this.docImportShow = true;
    },
    addItems(items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.project.autoScheduling) {
          this.fullReloadAfterImport = true;
        }
        
        if (item.items) {
          this.addItems(item.items);
        }
        
        if (item.staffList) {
          for (const staff of item.staffList) {
            this.processAdd({
              identifier: item.identifier,
              uuId: item.uuId,
              name: `${item.project.name} / ${item.name}`,
              shortName: item.name,
              type: 'task',
              startDate: moment(item.startdate).format('YYYY-MM-DD'),
              startTime: moment(item.startdate).format('HH:mm'),
              closeDate: moment(item.enddate).format('YYYY-MM-DD'),
              closeTime: moment(item.enddate).format('HH:mm'),
              staffUuid: staff.uuId,
              utilization: staff.utilization,
              projectTask: [item.project],
              progress: item.progress ? item.progress : 0,
              stageName: item.stages && item.stages.length > 0 ? item.stages[0].name : null,
              origStaffUuid: staff.uuId,
              value: convertDisplayToDuration(item.duration).value
            });
          }
        }
      }
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      const self = this;
      if(items.length == 0) {
        return;
      }
      this.inProgressLabel = this.$t('task.progress.import_document');
      this.inProgressShow = true;
      
      // Tasks
      if (this.importType === 'TASKS') {
        let projectId = items[0].project.uuId;
        await TaskTemplateDataUtil.createTasksFromImportedDocument(items, projectId, null, false, this.inProgressState, this);
        
        if (self.importedItems) {
          this.addItems(self.importedItems);
          delete self.importedItems;
        }
        
        if (this.fullReloadAfterImport) {
          this.updateGrid(true);
        }
      }
      else if (this.importType === 'BOOKING') {
        let idx = 0;
        let uuIds = null;
        const cmdList = [];
        for (const item of items) {
          if (item.staffList) {
            for (const staff of item.staffList) {
              idx++;
              const calendar = await staffService.calendar(staff.uuId)
              .then((response) => {
                // combine the calendar lists into single lists for each day
                const data = response.data[response.data.jobCase];
                return transformCalendar(processCalendar(data));
              })
              .catch((e) => {
                self.httpAjaxError(e);
                return null;
              });
              
              if (!item.duration) {
                item.duration = calcDuration(moment(item.startdate), moment(item.enddate), calendar);
              }
              else {
                item.duration = convertDisplayToDuration(item.duration).value;
              }
              const fixedduration = convertDisplayToDuration(item.fixedduration);
              const idName = `booking${idx}`;
              const data = {
                identifier: item.identifier,
                name: item.name, 
                color: item.color ? item.color : null,
                description: item.desc ? item.desc : null, 
                duration: item.duration,
                fixedCost: item.fixedcost,
                currencyCode: item.currency,
                fixedDuration: fixedduration.value,
                beginDate: moment.utc(item.startdate).valueOf(),
                untilDate: moment.utc(item.enddate).valueOf(),
                utilization: staff.utilization,
                staff: {
                  uuId: staff.uuId
                },
                project: {
                  uuId: item.project.uuId
                }
              };
              
              if (this.customFields) {
                for (const cfield of this.customFields) {
                  if (item[cfield.name]) {
                    data[cfield.name] = item[cfield.name];
                  }
                }
              }
              
              cmdList.push({
                "invoke": `/api/booking/add`,
                "body": [data],
                "vars": [{"name": idName,"path": "$.feedbackList.uuId"}]
              });
              
              if (item.stages && item.stages.length > 0 && item.stages[0].uuId) {
                cmdList.push({
                  "invoke": `/api/booking/link/stage/add`,
                  "body": {
                    uuId: `@{${idName}}`, 
                    stage: {
                      uuId: item.stages[0].uuId
                    }
                  }
                });
              }
              
              if (item.notes) {
                item.notes.forEach(i => {
                  cmdList.push({
                    "invoke": `/api/note/add?holder=@{${idName}}`,
                    "body": [i]
                  });
                });
              }    
            }
          }
        }
                
        uuIds = await compositeService.exec(cmdList)
        .then((response) => {
          const result = [];
          for (const res of response.data.feedbackList) {
            if (res.uuId) {
              result.push(res.uuId);
            }
          }
          return result;
        });
        
        idx = 0;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          for (const staff of item.staffList) {
            this.processAdd({
              identifier: item.identifier,
              uuId: uuIds[idx++],
              name: item.name,
              shortName: item.name,
              type: 'booking',
              startDate: moment(item.startdate).format('YYYY-MM-DD'),
              startTime: moment(item.startdate).format('HH:mm'),
              closeDate: moment(item.enddate).format('YYYY-MM-DD'),
              closeTime: moment(item.enddate).format('HH:mm'),
              staffUuid: staff.uuId,
              utilization: staff.utilization,
              projectBooking: [item.project],
              stageColor: item.stages && item.stages.length > 0 ? item.stages[0].color : null,
              stageName: item.stages && item.stages.length > 0 ? item.stages[0].name : null,
              origStaffUuid: staff.uuId,
              value: item.duration
            });
          }
        }
      }
      
      else if (this.importType === 'ACTIVITY') {
        let idx = 0;
        let uuIds = null;
        const cmdList = [];
        for (const item of items) {
          for (const staff of item.staffList) {
            idx++;
            const calendar = await staffService.calendar(staff.uuId)
            .then((response) => {
              // combine the calendar lists into single lists for each day
              const data = response.data[response.data.jobCase];
              return transformCalendar(processCalendar(data));
            })
            .catch((e) => {
              self.httpAjaxError(e);
              return null;
            });
            
            if (!item.duration) {
              item.duration = calcDuration(moment(item.startdate), moment(item.enddate), calendar);
            }
            else {
              item.duration = convertDisplayToDuration(item.duration).value;
            }
            const fixedduration = convertDisplayToDuration(item.fixedduration);
            const idName = `activity${idx}`;
            const data = {
              identifier: item.identifier,
              name: item.name, 
              color: item.color ? item.color : null,
              description: item.desc ? item.desc : null, 
              duration: item.duration,
              fixedCost: item.fixedcost,
              currencyCode: item.currency,
              fixedDuration: fixedduration.value,
              startTime: moment.utc(item.startdate).valueOf(),
              closeTime: moment.utc(item.enddate).valueOf(),
            };
          
            if (this.customFields) {
              for (const cfield of this.customFields) {
                if (item[cfield.name]) {
                  data[cfield.name] = item[cfield.name];
                }
              }
            }
            
            cmdList.push({
              "invoke": `/api/activity/add`,
              "body": [data],
              "vars": [{"name": idName,"path": "$.feedbackList.uuId"}]
            });
          
            cmdList.push({
              "invoke": '/api/activity/link/staff/add',
              "body": {
                uuId: `@{${idName}}`,
                staffList: [{
                  uuId: staff.uuId, 
                  resourceLink: {
                    utilization: staff.resourceLink ? staff.resourceLink.utilization : staff.utilization
                  }
                }]
              }
            });
          
            if (item.stages && item.stages.length > 0 && item.stages[0].uuId) {
              cmdList.push({
                "invoke": `/api/activity/link/stage/add`,
                "body": {
                  uuId: `@{${idName}}`, 
                  stage: {
                    uuId: item.stages[0].uuId
                  }
                }
              });
            }
            
            if (item.notes) {
              item.notes.forEach(i => {
                cmdList.push({
                  "invoke": `/api/note/add?holder=@{${idName}}`,
                  "body": [i]
                });
              });
            }
          }
        }
                
        uuIds = await compositeService.exec(cmdList)
        .then((response) => {
          const result = [];
          for (const res of response.data.feedbackList) {
            result.push(res.uuId);
          }
          return result;
        });
        
        idx = 0;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          for (const staff of item.staffList) {
            this.processAdd({
              uuId: uuIds[idx++],
              name: item.name,
              shortName: item.name,
              type: 'activity',
              startDate: moment(item.startdate).format('YYYY-MM-DD'),
              startTime: moment(item.startdate).format('HH:mm'),
              closeDate: moment(item.enddate).format('YYYY-MM-DD'),
              closeTime: moment(item.enddate).format('HH:mm'),
              staffUuid: staff.uuId,
              utilization: staff.utilization,
              stageName: item.stages && item.stages.length > 0 ? item.stages[0].name : null,
              origStaffUuid: staff.uuId,
              value: item.duration
            });
          }
        }
      }
      else if (this.importType === 'NONWORK') {
        let idx = 0;
        let uuIds = null;
        const cmdList = [];
        for (const item of items) {
          for (const staff of item.staffList) {
            cmdList.push({
              "invoke": `/api/calendar/add?holder=${staff.uuId}`,
              "body": [{
                name: item.name,
                startDate: moment(item.startdate).format('YYYY-MM-DD'),
                endDate: moment(item.enddate).format('YYYY-MM-DD'),
                isWorking: false,
                type: "Leave"
              }]
            });
          }
        }
        uuIds = await compositeService.exec(cmdList)
        .then((response) => {
          const result = [];
          for (const res of response.data.feedbackList) {
            result.push(res.uuId);
          }
          return result;
        })
        .catch(() => {
          this.alertMsg = this.$t('vacation.import_error');
          return [];
        });
        
        idx = 0;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          for (const staff of item.staffList) {
            this.processAdd({
              uuId: idx < uuIds.length ? uuIds[idx++] : null,
              name: item.name,
              type: 'vacation',
              startDate: moment(item.startdate).format('YYYY-MM-DD'),
              startTime: moment(item.startdate).format('HH:mm'),
              closeDate: moment(item.enddate).format('YYYY-MM-DD'),
              closeTime: moment(item.enddate).format('HH:mm'),
              staffUuid: staff.uuId,
              origStaffUuid: staff.uuId
            });
          }
        }
      }
      
      if (!this.fullReloadAfterImport) {
        this.inProgressShow = false;
      }
      else {
        // reload
        this.fullReloadAfterImport = false;
        this.inProgressLabel = this.$t('staff.grid.loading_list');
      }
      
      // restore the staff custom fields
      getCustomFieldInfo(this, 'STAFF').catch(e => this.httpAjaxError(e));
    },
    docImportCancel() {
      this.docImportShow = false;
      // restore the staff custom fields
      getCustomFieldInfo(this, 'STAFF').catch(e => this.httpAjaxError(e));
    },
    exportStart() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.exporting');
    },
    exportEnd() {
      this.exportToFile = false;
      this.inProgressShow = false;
    },
    processUpdate(event, lastUpdate) {
      let staffChanged = false;
      const rows = [];

      const ev = this.schedulerTasks.filter(e => e.id === event.id || e.id.slice(e.id.length - 36) === event.id);
      if (ev.length !== 0) {
        if (!event.startDate) {
          event.startDate = "1970-01-01";
        }
        
        if (!event.closeDate) {
          event.closeDate = "3000-12-31";
        }
        const startTime = convertDate(event.startDate, event.startTime);
        if (event.startDate === null) {
          event.startDate = "1970-01-01";
        }
        
        if (event.closeDate === null) {
          event.closeDate = "3000-12-31";
        }
        const endTime = convertDate(event.closeDate, event.closeTime)
        let start = new Date(startTime);
        let end = new Date(endTime);
        // adjust for the timezone
        start = new Date(start.getTime() + (start.getTimezoneOffset() * 60000));
        end = new Date(end.getTime() + (end.getTimezoneOffset() * 60000));
          
        // get the staff data so we can find the key
        let staffSection = staffSections[event.staffUuid];
        let stfEvs = staffEvents[event.staffUuid];
        if (!stfEvs) {
          stfEvs = []; // initialize the value if there are no staff events
        }
        
        if (event.type.toLowerCase() === 'vacation') {
          if (event.calendar_type === 'Leave') {
            const nw = staffSection.nonWorking.find(n => n.id === event.id);
            if (nw) {
              nw.start = start;
              nw.end = end;
            }
          }
          else {
            const nwIndex = staffSection.nonWorking.findIndex(n => n.id === event.id);
            if (nwIndex !== -1) {
              staffSection.nonWorking.splice(nwIndex, 1);
            }
          }
                    
          if (staffSection.calendarList) {
            const cal = staffSection.calendarList.find(c => c.uuId === event.id);
            if (cal) {
              cal.name = event.name;
              cal.startDate = start.getTime();
              cal.v_StartHour = event.v_StartHour;
              cal.v_EndHour = event.v_EndHour;
              cal.endDate = end.getTime();
              cal.type = event.calendar_type;
            }
            this.redrawScheduler = true;
            const markedIndex = this.markedTimespans.findIndex(m => m.id === event.id);
            if (markedIndex !== -1) {
              if (event.calendar_type === 'Leave') {
                const oldMarked = this.markedTimespans[markedIndex];
                oldMarked.start = start;
                oldMarked.end = end;
              }
              else {
                this.markedTimespans.splice(markedIndex, 1);
              }
            }
          }                
        }
        
        // if the staff has changed
        if (event.origStaffUuid && event.origStaffUuid !== event.staffUuid) {
          const ostaffSection = staffSections[event.origStaffUuid];
          if (ostaffSection) {
            const oindex = ostaffSection[`${event.type.toLowerCase()}List`].findIndex(t => t.uuId === event.id);
            if (oindex !== -1) {
              const olist = ostaffSection[`${event.type.toLowerCase()}List`].splice(oindex, 1);
              staffSection[`${event.type.toLowerCase()}List`].push(...olist);
            }
          }
          
          // get the original staff section ids
          this.gridOptions.api.forEachNode(node => {
            if (node.data.type === 'staff') {
              if (node.data.uuId === event.origStaffUuid) {
                rows.push(node);   
              }
            }
          });
                    
          let origStfEvs = staffEvents[event.origStaffUuid];
          if (!origStfEvs) {
            // if the staff has no events yet we need to create the array
            origStfEvs = staffEvents[event.origStaffUuid] = [];
          }
          
          const origIndex = origStfEvs.findIndex(e => e.id === event.id);
          if (origIndex !== -1) {
            const origEvent = origStfEvs.splice(origIndex, 1);
            origEvent[0].section_id = origEvent[0].staffUuid = event.staffUuid;
            stfEvs.push(...origEvent);
          }
          
          // actual duration is reset when staff is changed
          event.actualDuration = 0;
        }
        
        // update the event in the map
        const stfEv = stfEvs.find(e => e.id === event.id);
        if (stfEv) {
          stfEv.text = event.name;
          stfEv.start_date = start;
          stfEv.end_date = end;
          if (event.type.toLowerCase() !== 'vacation') {
            stfEv.te = event.duration / 60;
          }
          if (event.eventColor) {
            stfEv.eventColor = event.eventColor;
          }
          if (event.stageName) {
            stfEv.stageName = event.stageName;
          }
          if (typeof event.stageColor !== 'undefined') {
            stfEv.stageColor = event.stageColor;
          }

          if (event.tp !== null && typeof event.tp !== 'undefined') {
            stfEv.tp = event.tp;
          }
          if (event.rebates) {
            let r = 0;
            for (const rb of event.rebates) {
              r += rb.rebate;
            }
            stfEv.rebate = r;
          }
          if (event.calendar_type) {
            stfEv.calendar_type = event.calendar_type;
            ev[0].calendar_type = event.calendar_type;
          }
        }
      
        if (event.type !== 'vacation') {
          const index = staffSection[`${event.type.toLowerCase()}List`].findIndex(t => t.uuId === event.id);
          if (index !== -1) {
            const task = staffSection[`${event.type.toLowerCase()}List`][index];
            task.name = event.name;
            task.begin = startTime;
            task.until = endTime;
            if (typeof event.utilization !== 'undefined') {
              task.utilization = event.utilization;
            }
            if (typeof event.quantity !== 'undefined') {
              task.quantity = event.quantity;
            }
            if (event.rebates) {
              let r = 0;
              for (const rb of event.rebates) {
                r += rb.rebate;
              }
              task.rebate = r;
            }
            task.duration = event.duration * 60000;
            task.te = event.duration * 60000;
            
            if (event.fixedCost) {
              task.fixedCost = event.fixedCost;
            }
            
            if (event.actualCost) {
              task.actualCost = event.actualCost;
            }
            
            if (event.fixedDuration) {
              task.fixedDuration = event.fixedDuration;
            }
            
            if (typeof event.actualDuration !== 'undefined') {
              task.actualDuration = event.actualDuration;
            }
          }
        }
        
        if (event.type === 'task') {
          tasks[event.id].begin = startTime;
          tasks[event.id].until = endTime;
          this.checkTaskBooking(event);
          ev[0].booking = event.booking;
          if (stfEv) {
            stfEv.booking = event.booking;
          }
        }
        else if (event.type === 'booking') {
          for (const b of stfEvs) {
            if (typeof b.start_date === 'object') {
              const utcStart = Date.UTC(b.start_date.getFullYear(), b.start_date.getMonth(),
                    b.start_date.getDate(), b.start_date.getHours(),
                    b.start_date.getMinutes(), b.start_date.getSeconds());
              const utcEnd = Date.UTC(b.end_date.getFullYear(), b.end_date.getMonth(),
                    b.end_date.getDate(), b.end_date.getHours(),
                    b.end_date.getMinutes(), b.end_date.getSeconds());
              if (b.type === 'task' && (
                  (b.booking && b.booking.uuId === event.id) ||
                  (!b.booking && utcStart >= startTime && utcEnd <= endTime))) {
                this.checkTaskBooking(b);
                const tb = this.schedulerTasks.find(e => e.id === b.id);
                if (tb) {
                  tb.booking = b.booking;
                }
                break;
              }
            }
          }
        }
        
        // update the displayed event
        ev[0].text = event.name;
        ev[0].start_date = start;
        ev[0].end_date = end;
        if (event.type.toLowerCase() !== 'vacation') {
          ev[0].te = event.duration / 60;
        }
      
        ev[0].eventColor = event.eventColor;
        ev[0].stageColor = event.stageColor;
        ev[0].dotcolor = this.getColor(event);
        ev[0].textColor = this.getTextColor(event);
      
        // get the rows to update the totals
        rows.push(this.gridOptions.api.getRowNode(ev[0].section_id));
        
        // handle updating clones
        if (ev[0].clones) {
          for (const c of ev[0].clones) {
            const cindex = this.schedulerTasks.findIndex(t => t.id === c);
            this.schedulerTasks[cindex].start_date = start;
            this.schedulerTasks[cindex].end_date = end;
            this.schedulerTasks[cindex].te = event.duration / 60;
            rows.push(this.gridOptions.api.getRowNode(this.schedulerTasks[cindex].section_id));
          }
        }
        
        // update the totals
        for (const node of rows) {
          if (node) {
            this.getTotalUsage(node.data);
          }
        }
        this.gridOptions.api.redrawRows({rowNodes: rows});
        
        if (this.badgeFilters.find(f => f.field === 'availability') ||
            event.forceUpdate ||
            (staffChanged && lastUpdate)) { // handling for when multiple events are moved to another staff
          this.inProgressShow = true;
          this.inProgressLabel = this.$t('staff.progress.filtering');
                    
          setTimeout(() => {
            this.assignData();
          }, 500);
        }
        else {
          this.redrawScheduler = true;
        }
      }
    },
    processAdd(event, multistaff) {
      if (!event.startDate) {
        event.startDate = "1970-01-01";
      }
      
      if (!event.closeDate) {
        event.closeDate = "3000-12-31";
      }
      
      if (event.type.toLowerCase() === 'vacation' &&
          !event.startTime &&
          !event.closeTime) {
        event.startTime = this.getStartTime();
        event.closeTime = this.getCloseTime();
      }
      let startTime = convertDate(event.startDate, event.startTime);
      let endTime = convertDate(event.closeDate, event.closeTime);
      const start = moment(new Date(startTime)).format('YYYY-MM-DD H:mm');
      const end = moment(new Date(endTime)).format('YYYY-MM-DD H:mm');
      let project = null;
      
      // get the staff section so we can find the key
      let staffSection = staffSections[event.staffUuid];
      
      if (staffSection) {
        const ev = { 
          type: event.type.toLowerCase(),
          id: event.uuId,
          te: event.value / 60, 
          start_date: start, 
          end_date: end, 
          text: event.name, 
          staffUuid: event.staffUuid,
          readonly: this.isReadOnlyEvent(event.type),
          eventColor: event.eventColor,
          stageColor: event.stageColor
        }
        
        if (event.type.toLowerCase() === 'vacation') {
          ev.calendar_type = event.calendar_type ? event.calendar_type : 'Leave';
          ev.startHour = event.startTime;
          ev.endHour = event.closeTime;
          vacations[event.uuId] = {
            name: event.name,
            event: ev
          }
          
          // For the calendar entries we need the date without the time component
          let calStartTime = convertDate(event.startDate, null);
          let calEndTime = convertDate(event.closeDate, null);
          if (event.calendar_type !== 'Working') {
            // const nw = 
            staffSection.nonWorking.push({
              id: event.uuId,
              start: calStartTime,
              end: calEndTime,
              name: event.name
            });
          }
          
          if (!staffSection.calendarList) {
            staffSection.calendarList = [];
            staffSection.calendars.unshift(staffSection.calendarList);
          }
          // const cal = 
          staffSection.calendarList.push({ 
            uuId: event.uuId,
            startDate: calStartTime,
            endDate: calEndTime,
            type: event.calendar_type ? event.calendar_type : 'Leave',
            name: event.name
          });   
        }
        
        if (event.stageName) {
          ev.stageName = event.stageName;
        }
        
        if (event.stageColor) {
          ev.stageColor = event.stageColor;
        }
        
        if (event.type.toLowerCase() === 'task') {
          ev.tp = 0;
          ev.path = event.name;
          project = event.projectTask[0].uuId;
          ev.projectColor = event.projectTask[0].color;   
          
          // add the task to the staff to show usage
          if (staffSection.projectName === this.$t('staff.no_project') && staffSection.projectUuId === this.$t('staff.no_project')) {
            staffSection.projectUuId = event.projectTask[0].uuId;
            staffSection.projectName = event.projectTask[0].name;
            if (!(staffSection.projectUuId in entityList)) {
              entityList[staffSection.projectUuId] = {
                name: staffSection.projectName
              }
            }
            staffSection.stageUuId = event.projectTask[0].stage ? event.projectTask[0].stage.uuId : null;
            staffSection.stage = event.projectTask[0].stage ? event.projectTask[0].stage.name : null;
            if (staffSection.projectList.find(p => p.uuId === this.$t('staff.no_project'))) {
              staffSection.projectList = [];
            }
            staffSection.projectList.push({ uuId: staffSection.projectUuId, name: staffSection.projectName });
            if (staffSection.stageList.find(p => p.uuId === this.$t('staff.no_stage'))) {
              staffSection.stageList = [];
            }
            staffSection.stageList.push({ uuId: staffSection.stageUuId, name: staffSection.stageName });
          }
          else {
            // The project may not be in the staff projectList
            const prjIdx = staffSection.projectList.findIndex(p => p.uuId === event.projectTask[0].uuId);
            if (prjIdx === -1) {
              // add the project
              if (!(event.projectTask[0].uuId in entityList)) {
                entityList[event.projectTask[0].uuId] = { 
                  name: event.projectTask[0].name
                }
              }
              staffSection.projectList.push({ uuId: event.projectTask[0].uuId, name: event.projectTask[0].name });
              staffSection.stageList.push({ uuId: event.projectTask[0].stage ? event.projectTask[0].stage.uuId : this.$t(`staff.no_stage`),
                                            name: event.projectTask[0].stage ? event.projectTask[0].stage.name : this.$t(`staff.no_stage`) });
            }
          }
          
        }
        
        if (event.projectBooking && event.projectBooking.length !== 0 &&
            ev.type === 'booking') {
          project = event.projectBooking[0].uuId;
          ev.project = event.projectBooking[0].uuId;
          ev.pn = event.projectBooking[0].name;
          ev.projectColor = event.projectBooking[0].color;   
          if (!staffSection.projectName === this.$t('staff.no_project') && staffSection.projectUuId === this.$t('staff.no_project')) {
            staffSection.projectUuId = event.projectBooking[0].uuId;
            staffSection.projectName = event.projectBooking[0].name;
            if (!(staffSection.projectUuId in entityList)) {
              entityList[staffSection.projectUuId] = {
                name: staffSection.projectName
              }
            }
            staffSection.stageUuId = event.projectBooking[0].stage ? event.projectBooking[0].stage.uuId : null;
            staffSection.stageName = event.projectBooking[0].stage ? event.projectBooking[0].stage.name : null;
            if (staffSection.projectList.find(p => p.uuId === this.$t('staff.no_project'))) {
              staffSection.projectList = [];
            }
            staffSection.projectList.push({ uuId: staffSection.projectUuId, name: staffSection.projectName });
            if (staffSection.stageList.find(p => p.uuId === this.$t('staff.no_stage'))) {
              staffSection.stageList = [];
            }
            staffSection.stageList.push({ uuId: staffSection.stageUuId, name: staffSection.stageName });
          }
          else {
            // The project may not be in the staff projectList
            const prjIdx = staffSection.projectList.findIndex(p => p.uuId === event.projectBooking[0].uuId);
            if (prjIdx === -1) {
              // add the project
              if (!(event.projectBooking[0].uuId in entityList)) {
                entityList[event.projectBooking[0].uuId] = { 
                  name: event.projectBooking[0].name
                }
              }
              staffSection.projectList.push({ uuId: event.projectBooking[0].uuId, name: event.projectBooking[0].name });
              staffSection.stageList.push({ uuId: event.projectBooking[0].stage ? event.projectBooking[0].stage.uuId : this.$t(`staff.no_stage`),
                                            name: event.projectBooking[0].stage ? event.projectBooking[0].stage.name : this.$t(`staff.no_stage`) });
            }
          }
        }
        else if (event.projectTask && event.projectTask.length !== 0 &&
            ev.type === 'task') {
          ev.pu = event.projectTask[0].uuId;
          ev.pn = event.projectTask[0].name;
          ev.projectColor = event.projectTask[0].color;   
          const task = { 
            name: event.shortName,
            project: ev.pu,
            pn: event.projectTask[0].name,
            begin: event.begin,
            until: event.until,
            staffUuid: [event.staffUuid]
          };
          if (event.stage != null && event.stageName != null && event.stageName != '') {
            task.stage = event.stage;
            task.stageName = event.stageName;
          }
          tasks[event.uuId] = task;
          this.checkTaskBooking(ev);
        }
        
        if (event.type.toLowerCase() === 'task' ||
            event.type.toLowerCase() === 'booking' ||
            event.type.toLowerCase() === 'activity') {
            
          if (typeof staffSection[`${event.type.toLowerCase()}List`] === 'undefined') {
            staffSection[`${event.type.toLowerCase()}List`] = [];
          }
          
          staffSection[`${event.type.toLowerCase()}List`].push({
            name: event.shortName,
            type: event.type.toLowerCase(),
            utilization: event.utilization ? event.utilization : 1.0,
            begin: convertDate(event.startDate, event.startTime),
            until: convertDate(event.closeDate, event.closeTime),
            uuId: event.uuId,
            path: event.type.toLowerCase() === 'task' ? `${event.projectTask[0].name} / ${event.shortName}` : event.shortName,
            tp: event.progress ? event.progress : 0,
            te: event.value * 60000,
            pn: event.projectTask && event.projectTask.length > 0 ? event.projectTask[0].name : '',
            project: project,
            fixedCost: event.fixedCost,
            actualCost: event.actualCost,
            fixedDuration: event.fixedDuration,
            actualDuration: event.actualDuration
          });

          if (event.type.toLowerCase() === 'activity') {
            //Register this new event to activities. Make it visible to badgeFilterFieldValues.
            const activity = {
              name: event.name
              , uuId: event.uuId
            }
            if (event.stage != null && event.stageName != null && event.stageName != '') {
              activity.stage = event.stage;
              activity.stageName = event.stageName;
            }
            activities[event.uuId] = activity;
          } else if (event.type.toLowerCase() === 'booking') {
            const booking = {
              name: event.name
              , uuId: event.uuId
            }
            if (Array.isArray(event.projectTask) && event.projectTask.length > 0) {
              booking.pn = event.projectTask[0].name;
              booking.project = event.projectTask[0].uuId;
              if (event.projectTask[0].color != null) {
                booking.projectColor = event.projectTask[0].color;
              }
              booking.bookedList = [{
                label: 'PROJECT'
                , uuId: event.projectTask[0].uuId
              }];
            }
            if (event.staffUuId != null) {
              if (Array.isArray(booking.bookedList)) {
                booking.bookedList.push({
                  label: 'STAFF'
                  , uuId: event.staffUuId
                });
              } else {
                booking.bookedList = [{
                  label: 'STAFF'
                  , uuId: event.staffUuId
                }];
              }
            }
            if (event.stage != null && event.stageName != null && event.stageName != '') {
              booking.stage = event.stage;
              booking.stageName = event.stageName;
            }
            bookings[event.uuId] = booking;
          }
        
        }
        
        if (ev.type === 'vacation') {
          delete ev.te;
        }
        
        this.addStaffEvent(ev);
        
        // add any missing elements to the tree (a new project may be listed)
        buildTreeData(staffSection, entityList);
        
        if (multistaff) {
          for (const staff of multistaff) {
            if (staff.uuId !== ev.staffUuid) {
              let clonedStaffSection = staffSections[staff.uuId];
              if (clonedStaffSection) {
                const clonedEvent = {...ev};
                clonedEvent.staffUuid = staff.uuId;
                this.addStaffEvent(clonedEvent);
                
                if (event.type === 'Task') {
                  clonedEvent.tp = 0;
                  // add the task to the staff to show usage
                  clonedStaffSection.taskList.push({
                    utilization: 1.0,
                    begin: convertDate(event.startDate, event.startTime),
                    until: convertDate(event.closeDate, event.closeTime),
                    uuId: event.uuId,
                    path: event.name
                  });
                }
              }
            }
          }
        }
      }
      
      // get the rows to update the totals
      const rows = [];
      this.gridOptions.api.forEachNode(node => {
        if (node.data.type === 'staff') {
          if (node.data.uuId === event.staffUuid ||
              (multistaff && multistaff.find(s => s.uuId === node.data.uuId))) {
            rows.push(node);   
          }
        }
      });
      
      // update the totals
      for (const node of rows) {
        if (node) {
          this.getTotalUsage(node.data);
        }
      }
      this.gridOptions.api.redrawRows({rowNodes: rows});
      
      // rebuild the tree and events
      this.assignData();
    },
    isReadOnlyEvent(type) {
      if (type === 'activity') {
        return !this.canEdit('ACTIVITY', ['startTime', 'closeTime', 'duration']);
      }
      else if (type === 'booking') {
        return !this.canEdit('BOOKING', ['beginDate', 'duration', 'untilDate']);
      }
      else if (type === 'task') {
        return !this.canEdit('TASK', ['startTime', 'closeTime', 'duration']);
      }
      else if (type === 'vacation') {
        return !this.canEdit('CALENDAR', ['startDate', 'endDate']);
      }
      return false;
    },
    processCalendar(calendar) {
      // convert type to v_CalendarType
      for (const entry of calendar[0]) {
        if (entry.type === 'Sunday') {
          entry.v_CalendarType = [1];
        }
        else if (entry.type === 'Sunday') {
          entry.v_CalendarType = [1];
        }
        else if (entry.type === 'Monday') {
          entry.v_CalendarType = [2];
        }
        else if (entry.type === 'Tuesday') {
          entry.v_CalendarType = [3];
        }
        else if (entry.type === 'Wednesday') {
          entry.v_CalendarType = [4];
        }
        else if (entry.type === 'Thursday') {
          entry.v_CalendarType = [5];
        }
        else if (entry.type === 'Friday') {
          entry.v_CalendarType = [6];
        }
        else if (entry.type === 'Saturday') {
          entry.v_CalendarType = [7];
        }
        
        if (entry.endHour) {
          entry.v_EndHour = [entry.endHour];
        }
        if (entry.startHour) {
          entry.v_StartHour = [entry.startHour];
        }
      }
      return calendar[0];
    },
    async systemLocationCalendar() {
      let data = await calendarService.get([{ uuId: '00000000-0000-0000-0000-000000000000'}])
      .then(response => {
        return (response && response.data? response.data : []) || [];
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      })
      this.baseCalendar = this.processCalendar(data[data.jobCase]);
    },
    prepareData() {
      const self = this;
      const updateData = async () => {
        await self.systemLocationCalendar();
        await self.loadLayoutProfile();
        if (!self.checkDates()) {
          return;
        }
        ServerSideDatasource(self);
      };
  
      if (this.id !== null && !this.isDataView) {
        projectService.span(this.id).then((response) => {
          self.min = response.min;
          self.max = response.max;
          self.datesChanged();
          updateData();
        })
        .catch(e => {
          this.httpAjaxError(e);
        });
      }
      else {
        updateData();
      }
    },
    dismissAlert() {
      this.resetAlert();
    },
    resetAlert() {
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.alertError = false;
    },
    isEditable() {
      return true;
    },
    detailLinkId(params) {
      if (params.data) {
        const type = params.data.type;
        if (type === 'company') {
          return params.data.companyUuId;
        }
        else if (type === 'location') {
          return params.data.locationUuId;
        }
        else if (type === 'department') {
          return params.data.departmentUuId;
        }
        else if (type === 'skill') {
          return params.data.skillUuId;
        }
        else if (type === 'project') {
          return params.data.projectUuId;
        }
        else if (type === 'task') {
          return params.data.tu;
        }
        else {
          return params.data.uuId;
        }
      }
      else {
        const id = params.node.key.slice(params.node.key.length - 36);
        return id;
      }
    },
    openDetail(id, params) {
      this.popupRemoveMenu();
      const data = params.data ? params.data : this.getData(id);
      if (data) {
        const type = data.type;
        if (type === 'company') {
          this.companyId = id;
          this.companyShow = true;
        }
        else if (type === 'location') {
          this.locationId = id;
          this.locationShow = true;
        }
        else if (type === 'stage') {
          this.stageId = id;
          this.stageShow = true;
        }
        else if (type === 'department') {
          this.departmentId = id;
          this.departmentShow = true;
        }
        else if (type === 'skill') {
          this.skillId = id;
          this.skillShow = true;
        }
        else if (type === 'task') {
          this.taskOpen(id, data.pu, data.readonly);
        }
        else if (type === 'activity') {
          this.activityOpen(id, data.readonly);
        }
        else if (type === 'booking') {
          this.bookingOpen(id, data.readonly);
        }
        else if (type === 'project') {
          this.projectEditId = id;
          this.projectShow = true;
        }
        else if (type === 'vacation') {
          this.vacationEdit.uuId = id;
          this.vacationEdit.name = data.name;
          const start = new Date(data.start_date.getTime() - (data.start_date.getTimezoneOffset() * 60000)).toISOString();
          const end = new Date(data.end_date.getTime() - (data.end_date.getTimezoneOffset() * 60000)).toISOString();
          this.vacationEdit.startDate = moment(start).format('YYYY-MM-DD');
          this.vacationEdit.endDate = moment(end).format('YYYY-MM-DD');
          this.vacationEdit.readonly = data.readonly;
          this.vacationEdit.isWorking = data.calendar_type === 'Working';
          this.vacationEdit.startHour = data.startHour;
          this.vacationEdit.endHour = data.endHour;
          this.vacationEdit.staffUuid = data.staffUuid;
          this.vacationShow = true;
        }
        else {
          this.staffId = data.uuId;
          this.isGeneric = data.generic;
          this.staffShow = true;
        }
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      this.alertError = true;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
    },
    datesChanged() {
      const self = this;
      this.datesChanging = true;
      if (this.dates === "project-start-to-end") {
        if (this.project === null || typeof this.project.scheduleStart === 'undefined' || typeof this.project.scheduleFinish === 'undefined') {
          this.dates = null;
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          this.startDate = formatDate(new Date(this.project.scheduleStart));
          this.endDate = formatDate(new Date(this.project.scheduleFinish));
        }
      } 
      else if (this.dates === "project-schedule") {
        if (this.min === 0 || this.max === 0 ||
            this.min === null || this.max === null ||
            this.min === 'NaN' || this.max === 'NaN') {
          this.dates = null;
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          // make sure the span is less than 3 years
          const diff = this.max - this.min;
          if (diff > 94670778000) {
            this.max = this.min + (94670778000 - 86400000);
          }
          this.startDate = formatDate(new Date(this.min));
          this.endDate = formatDate(new Date(this.max));
        }
      } 
      else if (this.dates === "this-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
        
        // Sunday
        today.setDate(today.getDate() + 6); 
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-week-to-date") {
        let today = getNextWorkingDay(new Date());
        this.endDate = formatDate(today);
        
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "this-month") {
        let today = new Date();
        this.endDate = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        const lastday = new Date(thisq.getFullYear(), thisq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "this-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-year") {
        let today = new Date();
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "this-year-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
      } 
      else if (this.dates === "last-week") {
        let today = new Date();
             
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        this.endDate = formatDate(today);
        
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-week-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-month") {
        let today = new Date();
        this.endDate = formatDate(new Date (today.getFullYear(), today.getMonth(), 0));        
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
            
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(new Date (today.getFullYear(), lastq.getMonth() + 3, 0));
      } 
      else if (this.dates === "last-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "last-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() - 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "next-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() + (8 - day)); // Monday
        this.startDate = formatDate(today);
        today.setDate(today.getDate() + 6); // Sunday
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-4-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 4 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-8-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 8 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-12-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 12 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-24-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 24 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-month") {
        let today = new Date();
        const nextm = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        this.startDate = formatDate(nextm);
        const lastday = new Date(nextm.getFullYear(), nextm.getMonth() + 1, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        var nextq;
        if (quarter == 4) {
            nextq = new Date (today.getFullYear() + 1, 1, 1);
        } else {
            nextq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(nextq);
        const lastday = new Date(nextq.getFullYear(), nextq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() + 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      }
      Vue.nextTick(() => {
        self.datesChanging = false;
      });
    },
    shouldExport(value, data) {
      if (data && data.type === 'task') {
        return true;
      }
      
      let workingHours = value.w ? value.w : 0;
      const availableHours = value && value.a ? value.a : 0;
      
      if (availableHours === -1) {
        return true;
      }
      
      const percent = Math.trunc(workingHours / availableHours * 100);
      if (this.showUnders && percent <= 75) {
        return true;
      }
      else if (this.showOptimal && percent >= 76 && percent <= 100) {
        return true;
      }
      else if (this.showOvers && percent > 100) {
        return true;
      }
      return false;
    },
    initializeLayoutProfile() {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'staffColumns')) {
        this.layoutProfile.staffColumns = [];
        this.useDefault = true; // load the default view, if the views are not loaded yet
        const defaultView = this.staffViews.find(view => view.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
      }
    },
    async createLayoutProfile() {
      this.initializeLayoutProfile();
      await layoutProfileService.create([this.layoutProfile], this.entityId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.layoutProfile.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    updateLayoutProfile({ clearViewName=true } = {}) {
      if (!Object.prototype.hasOwnProperty.call(this.layoutProfile, 'uuId')) {
        // Dataviews are triggering the watchers when opening the
        // relevant tab and trying to save. Ignore those since nothing
        // has loaded yet.
        return;
      }
            
      // clear the view name from the breadcrumb
      if (clearViewName) {
        this.layoutProfile[this.getProfileEntryName('viewName')] = null;
        this.$store.dispatch("breadcrumb/clearView");
      }
      
      layoutProfileService.update([this.layoutProfile], this.entityId, this.userId)
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      })
    },
    loadColors(profile) {
      const self = this;
      self.coloring.none = profile ? profile.none : true;
      self.coloring.staff = profile ? profile.staff : false;
      self.coloring.event = profile ? profile.event : false;
      self.coloring.department = profile ? profile.department : false;
      self.coloring.location = profile ? profile.location : false;
      self.coloring.skill = profile ? profile.skill : false;
      self.coloring.company = profile ? profile.company : false;
      self.coloring.stage = profile ? profile.stage : false;
      self.coloring.project = profile ? profile.project : false;
    },
    loadShowSettings(profile) {
      this.show.usage = profile ? profile.usage : true;
      this.show.activity = profile ? profile.activity : true;
      this.show.booking = profile ? profile.booking : true;
      this.show.task = profile ? profile.task : true;
      this.show.vacation = profile ? profile.vacation : true;
      this.show.required = profile && typeof profile.required !== 'undefined' ? profile.required : true;
      this.show.available = profile && typeof profile.available !== 'undefined' ? profile.available : false;
      this.show.alertBookings = profile && typeof profile.alertBookings !== 'undefined' ? profile.alertBookings : false;
      this.show.alertTasks = profile && typeof profile.alertTasks !== 'undefined' ? profile.alertTasks : false;
      this.show.alertActivities = profile && typeof profile.alertActivities !== 'undefined' ? profile.alertActivities : false;
      this.show.alertNonWork = profile && typeof profile.alertNonWork !== 'undefined' ? profile.alertNonWork : false;
      this.show.alertTaskBookings = profile && typeof profile.alertTaskBookings !== 'undefined' ? profile.alertTaskBookings : false;
      this.show.fullBarColor = profile && typeof profile.fullBarColor !== 'undefined' ? profile.fullBarColor : false;
      this.show.usageTasks = profile && typeof profile.usageTasks !== 'undefined' ? profile.usageTasks : true;
      this.show.displayFilteredMetrics = profile && typeof profile.displayFilteredMetrics !== 'undefined' ? profile.displayFilteredMetrics : false;
      this.show.usageBookings = profile && typeof profile.usageBookings !== 'undefined' ? profile.usageBookings : true;
      this.show.usageActivities = profile && typeof profile.usageActivities !== 'undefined' ? profile.usageActivities : true;
      this.show.unallocated = false;
      this.show.work_hours = profile && typeof profile.work_hours !== 'undefined' ? profile.work_hours : { first_hour: 9, last_hour: 17 };
      this.show.hideNonWorking = profile && typeof profile.hideNonWorking !== 'undefined' ? profile.hideNonWorking : true;
      this.show.hideWeekends = profile && typeof profile.hideWeekends !== 'undefined' ? profile.hideWeekends : false;
    },
    async loadLayoutProfile() {
      const self = this;
      const userId = this.isWidget && this.widgetOwner ? this.widgetOwner : this.userId;
      const profileData = await layoutProfileService.list(this.entityId, userId).then((response) => {
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      })
      if (profileData.length === 0) {
        await self.createLayoutProfile();
        self.useDefault = true; // load the default view, if the views are not loaded yet
        const defaultView = this.staffViews.find(view => view.defaultView);
        if (defaultView) {
          this.loadViewSettings(defaultView);
        }
        else {
          this.dates = "this-month";
          this.datesChanged();
          this.updateGrid();
        }
      } else {
        self.layoutProfile = profileData[0];
        self.initializeLayoutProfile();
        self.loadColors(self.layoutProfile[this.getProfileEntryName('coloring')]);
        
        // Handle upgrading the displayed filter so that it shows [Is] by default
        const filter = self.layoutProfile[this.getProfileEntryName('filter')] ? cloneDeep(self.layoutProfile[this.getProfileEntryName('filter')]) : [];    
        if (filter) {
          for (const f of filter) {
            if (f.name.indexOf(']:') === -1) {
              const idx = f.name.indexOf(':');
              if (idx !== -1) {
                f.name = f.name.substr(0, idx) + ' [Is]' + f.name.substr(idx, f.name.length);
              }
            }
          }
        }
        self.badgeFilters = filter;
        // self.filter = filter;
        // self.filterHistory = self.layoutProfile.staffFilterHistory ? self.layoutProfile.staffFilterHistory : [];
        
        this.eventTypeValues = self.layoutProfile[this.getProfileEntryName('typevalues')] ? self.layoutProfile[this.getProfileEntryName('typevalues')] : {
          activityName: null,
          taskName: null,
          vacationName: null
        };
        
        self.loadShowSettings(self.layoutProfile[this.getProfileEntryName('show')]);
        self.expandLevels();
        self.gridOptions.groupDefaultExpanded = self.layoutProfile.expandLevel;
        if (typeof self.layoutProfile.taskExpandLevel === 'undefined') {
          self.gridOptions.groupDefaultExpanded = -1;
        }
        
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'staffDates')) {
          self.$set(self, 'dates', self.layoutProfile['staffDates']);
          if (self.layoutProfile.staffDates == null) {
            // Null means custom date. Use recorded range.
            try {
              self.$set(self, 'startDate', self.layoutProfile['staffStartDate']);
              self.$set(self, 'endDate', self.layoutProfile['staffEndDate']);
            } catch (e) {
              console.log("Invalid date range on restore. Using default"); // eslint-disable-line no-console
              self.datesChanged();
            }
          } else {
            // Range is calculated
            self.datesChanged();
          }
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'staffTimescale')) {
          self.$set(self, 'span', self.layoutProfile['staffTimescale']);
        }
        if(this.id && Object.prototype.hasOwnProperty.call(self.layoutProfile, 'staffUsageProject')) {
          self.$set(self, 'staffUsageProject', self.layoutProfile['staffUsageProject']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'staffAlloc')) {
          self.$set(self, 'staffAlloc', self.layoutProfile['staffAlloc']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'grouping')) {
          self.$set(self, 'grouping', self.layoutProfile['grouping']);
          if (self.grouping.skills && !self.canView('STAFF', ['SKILL'])) {
            self.grouping.skills = false;
          }
          if (self.grouping.location && !self.canView('STAFF', ['LOCATION'])) {
            self.grouping.location = false;
          }
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'showGeneric')) {
          self.$set(self, 'showGeneric', self.layoutProfile['showGeneric']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'showUnders')) {
          self.$set(self, 'showUnders', self.layoutProfile['showUnders']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'showOptimal')) {
          self.$set(self, 'showOptimal', self.layoutProfile['showOptimal']); 
        }
        if(Object.prototype.hasOwnProperty.call(self.layoutProfile, 'showOvers')) {
          self.$set(self, 'showOvers', self.layoutProfile['showOvers']); 
        }
        
        self.filterText = typeof self.layoutProfile.staffFilterText !== 'undefined' ? self.layoutProfile.staffFilterText : '';
        self.filterValue = self.filterText;
        
        if (self.layoutProfile[this.getProfileEntryName('viewName')]) {
          this.$store.dispatch("breadcrumb/updateView", self.layoutProfile[this.getProfileEntryName('viewName')], { root: true });
        }
        else {
          this.$store.dispatch("breadcrumb/clearView");
        }
      }
      this.layoutProfileLoaded = true;
    },
    onAllocOver() {
      this.$refs.alloc.visible = true;
    },
    onAllocLeave() {
      this.$refs.alloc.visible = false;
    },
    onCostOver() {
      this.$refs.cost.style.visibility = 'visible';
    },
    onCostClick() {
      if (this.$refs.cost.style.visibility === 'hidden' ||
          this.$refs.cost.style.visibility === '') {
        this.$refs.cost.style.visibility = 'visible';
      }
      else {
        this.$refs.cost.style.visibility = 'hidden';
      }
    },
    onCostLeave() {
      this.$refs.cost.style.visibility = 'hidden';
    },
    onDurationOver() {
      this.$refs.duration.style.visibility = 'visible';
    },
    onDurationClick() {
      if (this.$refs.duration.style.visibility === 'hidden' ||
          this.$refs.duration.style.visibility === '') {
        this.$refs.duration.style.visibility = 'visible';
      }
      else {
        this.$refs.duration.style.visibility = 'hidden'
      }
    },
    onDurationLeave() {
      this.$refs.duration.style.visibility = 'hidden';
    },
    onGroupingOver() {
      this.$refs.grouping.visible = true;
    },
    onGroupingLeave() {
      this.$refs.grouping.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onGroupChange(event, updateLevel) {
      let reload = false;
      if ((event === 'company' ||
          event === 'location' ||
          event === 'project' ||
          event === 'stage' ||
          event === 'department' ||
          event === 'skills') &&
          !this.grouping[event]) {
        let count = 0;
        if (this.grouping.company) {
          count++;
        }
        if (this.grouping.location) {
          count++;
        }
        if (this.grouping.project) {
          count++;
        }
        if (this.grouping.stage) {
          count++;
        }
        if (this.grouping.department) {
          count++;
        }
        if (this.grouping.skills) {
          count++;
        }
        
        if (count >= 3) {
          this.showGroupingAlert = true;
          return;
        }
      }
      
      if (event !== 'showGeneric') {
        this.grouping[event] = !this.grouping[event];
        if (event === 'staff' && 
            !this.staffLoaded) {
          reload = true;
        }
      }
      else {
        this.showGeneric = !this.showGeneric;
        this.layoutProfile.showGeneric = this.showGeneric;
        if (this.showGeneric &&
            !this.genericLoaded) {
          reload = true;
        }
      }
      
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.filtering');

      setTimeout(() => {
        this.holdUpdateUntilTreeDataChanged = true;
        if (updateLevel) {
          if (event) {
            this.expandLevel++;
          }
          else {
            // else if (this.expandLevel !== 0) {
            this.expandLevel--;
          }
        }
        this.layoutProfile['grouping'] = this.grouping;
        this.updateLayoutProfile();
        
        //When group change, reset folderState
        this.folderState = {}
        
        if (reload) {
          //When toggling group: generic or non-generic staff, onViewChanged event is not fired. Hence, redrawViewport() is not triggered.
          //Workaround: set a flag and manually trigger redrawViewport() in rowDataUpdated() event later.
          this.needRedrawViewport = true;
        }
        //Reset scroll to top to mitigate the scroll out of sync (issue #2394) 
        if (this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0);
        }
        this.updateGrid();
        
      }, 500);
    }, 
    onShowChange(value, apply = true) {
      if (value !== 'usage' && value !== 'unallocated' &&
          value !== 'activity' && value !== 'booking' && value !== 'task') {
        this.holdUpdateUntilTreeDataChanged = true;
      }
      
      this.needSetActualRowHeights = true;
      this.show[value] = !this.show[value];
      this.layoutProfile[this.getProfileEntryName('show')] = this.show;
      
      if (value === 'required') {
        this.show['available'] = !this.show[value];
      }
      
      if (value === 'available') {
        this.show['required'] = !this.show[value];
      }
      
      if (apply) {
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        
        setTimeout(() => {
          this.updateLayoutProfile();
          if (value !== 'usage' && value !== 'unallocated') {
            this.assignData();
          }
        }, 500);
      }
    },
    addStaffEntries(data, dataMap, staffList) {
      const self = this;
      if (this.grouping.staff || this.showGeneric) {
        for (const staff of staffList) {
          if ((self.grouping.project || self.grouping.stage) &&
              staff.projectList && (this.showGeneric || staff.generic === false)) {
            // if the staff is in more than 1 project we will need to add them for each
            for (let i = 0; i < staff.projectList.length; i++) {
            
              const staffProj = self.cloneStaff(staff);
              if (self.grouping.project) {
                staffProj.projectUuId = staff.projectList[i].uuId;
                staffProj.projectName = staff.projectList[i].name;
              }
              if (self.grouping.stage) {
                staffProj.stageUuId = staff.stageList[i].uuId;
                staffProj.stageName = staff.stageList[i].name;
              }
              
              // not grouping stage ?
              if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                data.push(staffProj);
                dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
              }
              
              // add the staff for each department
              if (self.grouping.department && self.grouping.skills) {
                for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
                  if (staff.skillList) {
                    for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
                      const staffProj = self.cloneStaff(staff);
                      if (self.grouping.project) {
                        staffProj.projectUuId = staff.projectList[i].uuId;
                        staffProj.projectName = staff.projectList[i].name;
                      }
                      if (self.grouping.stage) {
                        staffProj.stageUuId = staff.stageList[i].uuId;
                        staffProj.stageName = staff.stageList[i].name;
                      }
                      staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
                      staffProj.department = staff.departmentList[depIdx].name;
                      staffProj.skillUuId = staff.skillList[skillIdx].uuId;
                      staffProj.skillName = staff.skillList[skillIdx].name;
                      // not grouping stage ?
                      if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                        data.push(staffProj);
                        dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                      }
                    }
                  }
                }
              }
              else if (self.grouping.department && staff.skillList) {
                for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
                  const staffProj = self.cloneStaff(staff);
                  if (self.grouping.project) {
                    staffProj.projectUuId = staff.projectList[i].uuId;
                    staffProj.projectName = staff.projectList[i].name;
                  }
                  if (self.grouping.stage) {
                    staffProj.stageUuId = staff.stageList[i].uuId;
                    staffProj.stageName = staff.stageList[i].name;
                  }
                  staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
                  staffProj.department = staff.departmentList[depIdx].name;
                  if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                    data.push(staffProj);
                    dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                  }
                }
              }
              else if (self.grouping.skills) {
                for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
                  const staffProj = self.cloneStaff(staff);
                  if (self.grouping.project) {
                    staffProj.projectUuId = staff.projectList[i].uuId;
                    staffProj.projectName = staff.projectList[i].name;
                  }
                  if (self.grouping.stage) {
                    staffProj.stageUuId = staff.stageList[i].uuId;
                    staffProj.stageName = staff.stageList[i].name;
                  }
                  staffProj.skillUuId = staff.skillList[skillIdx].uuId;
                  staffProj.skillName = staff.skillList[skillIdx].name;
                  if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                    data.push(staffProj);
                    dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                  }
                }
              }
            }
          }
          else if (self.grouping.department && self.grouping.skills && (staff.skillList && staff.skillList.length > 0)) {
            for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
              if (staff.skillList) {
                for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
                  const staffProj = self.cloneStaff(staff);
                  staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
                  staffProj.department = staff.departmentList[depIdx].name;
                  staffProj.skillUuId = staff.skillList[skillIdx].uuId;
                  staffProj.skillName = staff.skillList[skillIdx].name;
                  if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                    data.push(staffProj);
                    dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                  }
                }
              }
            }
          }
          else if (self.grouping.department) {
            for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
              const staffProj = self.cloneStaff(staff);
              staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
              staffProj.department = staff.departmentList[depIdx].name;
              if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                data.push(staffProj);
                dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
              }
            }
          }
          else if (self.grouping.skills && staff.skillList) {
            for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
              const staffProj = self.cloneStaff(staff);
              staffProj.skillUuId = staff.skillList[skillIdx].uuId;
              staffProj.skillName = staff.skillList[skillIdx].name;
              if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                data.push(staffProj);
                dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
              }
            }
          }
        }
      }
      return { data: data, dataMap: dataMap };
    },
    assignData() {
      const self = this;
      let data = [];
      let dataMap = {};
      this.maxLevel = 0;
      
      if (this.grouping.staff) {
        if (this.showGeneric) {
          data.push(...staffData.filter(s => (this.isDataView && this.staff.includes(s.uuId)) || !this.isDataView));
        }
        else {
          // filter out generic
          data.push(...staffData.filter(s => !s.generic && ((this.isDataView && this.staff.includes(s.uuId)) || !this.isDataView)));
        }
      }
      else if (this.showGeneric) {
        // just list generic staff
        data.push(...staffData.filter(s => s.generic && ((this.isDataView && this.staff.includes(s.uuId)) || !this.isDataView)));
        
      }
      
      dataMap = data.reduce(function(map, obj) {
                      map[`${obj.uuId}${obj.companyUuId}${obj.locationUuId}${self.grouping.stage ? obj.stageUuId : ''}${obj.projectUuId}${obj.departmentUuId}${obj.skillUuId}`] = true;
                      return map;
                  }, {});
         
      // save the staff list for use further down
      const staffList = this.grouping.staff || this.showGeneric ? cloneDeep(data) : cloneDeep(staffData.filter(s => (this.isDataView && this.staff.includes(s.uuId)) || !this.isDataView));
      
      const stfResult = self.addStaffEntries(data, dataMap, staffList);
      data = stfResult.data;
      dataMap = stfResult.dataMap;
      
      if (this.grouping.company) {
        var count = data.length;
        const companyList = staffList.map(s => { return s.companyUuId });
        for (const key of Object.keys(companies)) {
          if (!this.isDataView ||
              companyList.includes(companies[key].companyUuId)) {
            data.push(companies[key]);
          }
        }
        
        if (data.length !== count) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.location) {
        var countLocation = data.length;
        const locationList = staffList.map(s => { return { company: s.companyUuId, location: s.locationUuId }});
        for (const key of Object.keys(locations)) {
          delete locations[key]['children'];  // reset the tree
          if (!this.isDataView ||
              locationList.findIndex(l => l.location === locations[key].locationUuId && l.company === locations[key].companyUuId) !== -1 &&
                (this.grouping.company || data.findIndex(d => d.type === 'location' && d.locationUuId === locations[key].locationUuId) === -1)) {
            if (self.grouping.company) {
              data.push(locations[key]);
            }
            else {
              let index = data.findIndex(loc => loc.locationUuId === locations[key].locationUuId &&
                                 loc.type === 'location');
              if (index === -1) {
                data.push(locations[key]);
              }
              else {
                // clone the object and combine the values
                const cloned = self.cloneLocation(data[index]);
                data[index] = cloned;
              }
            }
          }
        }
        if (data.length !== countLocation) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.stage) {
        var stCount = data.length;
        const stageList = staffList.map(s => { return { company: s.companyUuId, location: s.locationUuId, stage: s.stageUuId }});
        for (const key of Object.keys(stages)) {
          if (!this.isDataView ||
              stageList.findIndex(l => l.stage === stages[key].stageUuId && l.location === stages[key].locationUuId && l.company === stages[key].companyUuId) !== -1 &&
                (this.grouping.location || data.findIndex(d => d.type === 'stage' && d.stageUuId === stages[key].stageUuId) === -1)) {
            if (self.grouping.location) {
              data.push(stages[key]);
            }
            else {
              let index = data.findIndex(stg => stg.stageUuId === stages[key].stageUuId &&
                                                (!self.grouping.company || stg.companyUuId === stages[key].companyUuId) &&
                                                (!self.grouping.location || stg.locationUuId === stages[key].locationUuId) &&
                                                stg.type === 'stage');
              if (index === -1) {
                data.push(stages[key]);
              }
              else {
                // clone the object and combine the values
                const cloned = self.cloneStage(data[index]);
                data[index] = cloned;
                data = addIfNotExist(data, 
                                           index, 
                                           'stage', 
                                           data[index].stageName, 
                                           'stageUuId', 
                                           data[index].stageUuId, 
                                           { 
                                             companyUuId: data[index].companyUuId, 
                                             company: data[index].company,
                                             locationUuId: data[index].locationUuId,
                                             location: data[index].location
                                           });
              }
            }
          }
        }
        if (data.length !== stCount) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.project) {
        var prjCount = data.length;
        const projectList = [];
        for (const s of staffList) {
          for (const p of s.projectList) {
            projectList.push({ company: s.companyUuId, location: s.locationUuId, project: p.uuId });
          }
        }
        
        for (const key of Object.keys(projects)) {
          if (!this.isDataView ||
              projectList.findIndex(l => l.project === projects[key].projectUuId && l.location === projects[key].locationUuId && l.company === projects[key].companyUuId) !== -1 &&
                (this.grouping.company || data.findIndex(d => d.type === 'project' && d.projectUuId === projects[key].stageUuId) === -1)) {
            // reset the children value
            projects[key].children = [];
            if (self.grouping.company) {
              data.push(projects[key]);
            }
            else {
              let index = data.findIndex(prj => prj.projectUuId === projects[key].projectUuId &&
                                                (!self.grouping.location || prj.locationUuId === projects[key].locationUuId) &&
                                                prj.type === 'project');
              if (index === -1) {
                data.push(projects[key]);
              }
              else {
                // clone the object and combine the values
                const cloned = self.cloneProject(data[index]);
                data[index] = cloned;
                data = addIfNotExist(data, 
                                           index, 
                                           'project', 
                                           data[index].projectName, 
                                           'projectUuId', 
                                           data[index].projectUuId, 
                                           { 
                                             companyUuId: data[index].companyUuId, 
                                             company: data[index].company,
                                             locationUuId: data[index].locationUuId,
                                             location: data[index].location,
                                             stageUuId: data[index].stageUuId,
                                             stageName: data[index].stageName
                                           });
              }
            }
          }
        }
        
        if (this.grouping.staff || this.showGeneric) {
          for (const staff of staffList) {
            if (staff.projectList && (this.showGeneric || staff.generic === false)) {
              // if the staff is in more than 1 project we will need to add them for each
              for (let i = 0; i < staff.projectList.length; i++) {
              
                const staffProj = self.cloneStaff(staff);
                if (self.grouping.project) {
                  staffProj.projectUuId = staff.projectList[i].uuId;
                  staffProj.projectName = staff.projectList[i].name;
                }
                if (self.grouping.stage) {
                  staffProj.stageUuId = staff.stageList[i].uuId;
                  staffProj.stageName = staff.stageList[i].name;
                }
                if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                  data.push(staffProj);
                  dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                }
                
                // add the staff for each department
                if (self.grouping.department && self.grouping.skills) {
                  for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
                    if (staff.skillList) {
                      for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
                        const staffProj = self.cloneStaff(staff);
                        staffProj.projectUuId = staff.projectList[i].uuId;
                        staffProj.projectName = staff.projectList[i].name;
                        staffProj.stageUuId = staff.stageList[i].uuId;
                        staffProj.stageName = staff.stageList[i].name;
                        staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
                        staffProj.department = staff.departmentList[depIdx].name;
                        staffProj.skillUuId = staff.skillList[skillIdx].uuId;
                        staffProj.skillName = staff.skillList[skillIdx].name;
                        // not grouping stage?        
                        if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                          data.push(staffProj);
                          dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                        }
                      }
                    }
                  }
                }
                else if (self.grouping.department) {
                  for (let depIdx = 0; depIdx < staff.departmentList.length; depIdx++) {
                    const staffProj = self.cloneStaff(staff);
                    staffProj.projectUuId = staff.projectList[i].uuId;
                    staffProj.projectName = staff.projectList[i].name;
                    staffProj.stageUuId = staff.stageList[i].uuId;
                    staffProj.stageName = staff.stageList[i].name;
                    staffProj.departmentUuId = staff.departmentList[depIdx].uuId;
                    staffProj.department = staff.departmentList[depIdx].name;
                        
                    if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                      data.push(staffProj);
                      dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                    }
                  }
                }
                else if (self.grouping.skills) {
                  for (let skillIdx = 0; skillIdx < staff.skillList.length; skillIdx++) {
                    const staffProj = self.cloneStaff(staff);
                    staffProj.projectUuId = staff.projectList[i].uuId;
                    staffProj.projectName = staff.projectList[i].name;
                    staffProj.stageUuId = staff.stageList[i].uuId;
                    staffProj.stageName = staff.stageList[i].name;
                    staffProj.skillUuId = staff.skillList[skillIdx].uuId;
                    staffProj.skillName = staff.skillList[skillIdx].name;
                        
                    if (!(`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}` in dataMap)) {
                      data.push(staffProj);
                      dataMap[`${staffProj.uuId}${staffProj.companyUuId}${staffProj.locationUuId}${self.grouping.stage ? staffProj.stageUuId : ''}${staffProj.projectUuId}${staffProj.departmentUuId}${staffProj.skillUuId}`] = true;
                    }
                  }
                }
              }
            }
          }
        }
        if (data.length !== prjCount) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.department) {
        var depCount = data.length;
        if (this.isDataView) {
          const deptList = staffList.reduce((list, s) => { 
            if (s.projectList) {
              for (let prjIdx = 0; prjIdx < s.projectList.length; prjIdx++) {
                for (const dept of s.departmentList) {
                  list.push({ company: s.companyUuId, department: dept.uuId, location: s.locationUuId, stage: s.stageList[prjIdx].uuId, project: s.projectList[prjIdx].uuId });
                }
              }
            }
            else if (this.isDataView) {
              for (const dept of s.departmentList) {
                list.push({ company: s.companyUuId, department: dept.uuId, location: s.locationUuId });
              }
            }
            return list;
          }, []);
          for (const key of Object.keys(departments)) {
            const dept = departments[key];
            if (deptList.findIndex(stf => (!self.grouping.company || dept.companyUuId === stf.company) &&
                                   (!self.grouping.location || dept.locationUuId === stf.location) &&
                                   (!self.grouping.stage || dept.stageUuId === stf.stage) &&
                                   (!self.grouping.project || dept.projectUuId === stf.project) &&
                                   (!self.grouping.department || dept.departmentUuId === stf.department)) !== -1) {
              let index = data.findIndex(d => d.type === 'department' && d.departmentUuId === dept.departmentUuId);
              if (index === -1) {
                data.push(dept);
              }
              else {
                // clone the object and combine the values
                const cloned = self.cloneDept(data[index]);
                data[index] = cloned;
                data = addIfNotExist(data, 
                                           index, 
                                           'department', 
                                           data[index].department, 
                                           'departmentUuId', 
                                           data[index].departmentUuId, 
                                           { 
                                             companyUuId: data[index].companyUuId, 
                                             company: data[index].company, 
                                             locationUuId: data[index].locationUuId, 
                                             location: data[index].location,
                                             stageUuId: data[index].stageUuId,
                                             stageName: data[index].stageName,
                                             projectUuId: data[index].projectUuId,
                                             projectName: data[index].projectName
                                           });
              }
            }
          }
        }
        else {
          for (const key of Object.keys(departments)) {
            let index = data.findIndex(d => d.type === 'department' && 
                                            (!self.grouping.company || d.companyUuId === departments[key].companyUuId) &&
                                            (!self.grouping.stage || d.stageUuId === departments[key].stageUuId) &&
                                            (!self.grouping.project || d.projectUuId === departments[key].projectUuId) &&
                                            (!self.grouping.location || d.locationUuId === departments[key].locationUuId) &&
                                            d.departmentUuId === departments[key].departmentUuId);
            if (index === -1) {
              data.push(departments[key]);
            }
            else {
              // clone the object and combine the values
              const cloned = cloneDeep(data[index]);
              data[index] = cloned;
              data = addIfNotExist(data, 
                                         index, 
                                         'department', 
                                         data[index].department, 
                                         'departmentUuId', 
                                         data[index].departmentUuId, 
                                         { 
                                           companyUuId: data[index].companyUuId, 
                                           company: data[index].company, 
                                           locationUuId: data[index].locationUuId, 
                                           location: data[index].location, 
                                             stageUuId: data[index].stageUuId,
                                             stageName: data[index].stageName,
                                             projectUuId: data[index].projectUuId,
                                             projectName: data[index].projectName
                                         });
            }
          }
        }
        
        if (!this.grouping.skills && 
            (this.grouping.staff || this.showGeneric)) {
          for (const staff of staffList) {
            if (this.showGeneric || staff.generic === false) {
              // if the staff is in more than 1 department we will need to add them for each
              for (let i = 1; i < staff.departmentList.length; i++) {
                const staffDept = self.cloneStaff(staff);
                staffDept.departmentUuId = staff.departmentList[i].uuId;
                staffDept.department = staff.departmentList[i].name;
                const index = data.findIndex(d => d.type === 'staff' && 
                                           d.skillUuId === staffDept.skillUuId && 
                                           (!self.grouping.company || d.companyUuId === staffDept.companyUuId) &&
                                           (!self.grouping.location || d.locationUuId === staffDept.locationUuId) &&
                                           (!self.grouping.stage || d.stageUuId === staffDept.stageUuId) &&
                                           (!self.grouping.project || d.projectUuId === staffDept.projectUuId) &&
                                           d.departmentUuId === staffDept.departmentUuId);
                if (index === -1) {
                  data.push(staffDept);
                }
              }
            }
          }
        }
        if (data.length !== depCount) {
          this.maxLevel++;
        }
      }
      
      if (this.grouping.skills) {
        var count2 = data.length;
        let uniqueSkills = {};
        const skillList = staffList.reduce((list, s) => { 
          if (s.departmentList && s.skillList) {
            if (s.departmentList.length <= 1) {
              for (const skill of s.skillList) {
                list.push({ skillUuId: skill.uuId, companyUuId: s.companyUuId, departmentUuId: s.departmentUuId, locationUuId: s.locationUuId });
              }
            }
            else {
              for (const dept of s.departmentList) {
                for (const skill of s.skillList) {
                  list.push({ skillUuId: skill.uuId, companyUuId: s.companyUuId, departmentUuId: dept.uuId, locationUuId: s.locationUuId });
                }
              }
            }
          }
          return list;
        }, []);
        for (const skillkey of Object.keys(skills)) {
          if (this.grouping.department) {
            if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId && 
                                         s.companyUuId === skills[skillkey].companyUuId &&
                                         s.locationUuId === skills[skillkey].locationUuId &&
                                         s.departmentUuId === skills[skillkey].departmentUuId) !== -1) {
              if (this.grouping.location) {
                // we will use location to create a unique id
                const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                           (!self.grouping.company || d.companyUuId === skills[skillkey].companyUuId) &&
                                           (!self.grouping.location || d.locationUuId === skills[skillkey].locationUuId) &&
                                           (!self.grouping.stage || d.stageUuId === skills[skillkey].stageUuId) &&
                                           (!self.grouping.project || d.projectUuId === skills[skillkey].projectUuId) &&
                                           d.departmentUuId === skills[skillkey].departmentUuId);
                if (index === -1) {
                  data.push(skills[skillkey]);
                }
                else {
                  // combine the values
                  const cloned = self.cloneSkill(data[index]);
                  data[index] = cloned;
                }
              }
              else {
                const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                           (!self.grouping.company || d.companyUuId === skills[skillkey].companyUuId) &&
                                           (!self.grouping.project || d.projectUuId === skills[skillkey].projectUuId) &&
                                           d.departmentUuId === skills[skillkey].departmentUuId);
                if (index === -1) {
                  data.push(skills[skillkey]);
                }
                else {
                  // combine the values
                  const cloned = self.cloneSkill(data[index]);
                  data[index] = cloned;
                }
              }
            }
          }
          else if (this.grouping.location) {
            if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId && 
                                         s.companyUuId === skills[skillkey].companyUuId &&
                                         s.locationUuId === skills[skillkey].locationUuId) !== -1) {
              const index = data.findIndex(d => d.type === 'skill' && d.skillUuId === skills[skillkey].skillUuId && 
                                         d.companyUuId === skills[skillkey].companyUuId &&
                                         (!self.grouping.project || d.projectUuId === skills[skillkey].projectUuId) &&
                                         d.locationUuId === skills[skillkey].locationUuId);
              if (index === -1) {
                data.push(skills[skillkey]);
              }
              else {
                // combine the values
                const cloned = self.cloneSkill(data[index]);
                data[index] = cloned;
              }
            }
          }
          else if (skillList.findIndex(s => s.skillUuId === skills[skillkey].skillUuId &&
                                       (!self.grouping.company || skills[skillkey].companyUuId === s.companyUuId)) !== -1) {
            const id = this.getRowId({ data: skills[skillkey] });
            if (!(id in uniqueSkills)) {
              uniqueSkills[id] = self.cloneSkill(skills[skillkey]);
            }
          }
        }
        for (const key of Object.keys(uniqueSkills)) {
          data.push(uniqueSkills[key]);
        }
        
        if (this.grouping.staff || this.showGeneric) {
          for (const staff of staffList) {
            if (this.showGeneric || staff.generic === false) {
              // if the staff is in more than 1 department we will need to add them for each
              if (this.grouping.department &&
                  staff.departmentList.length > 1) {
                for (let d = 1; d < staff.departmentList.length; d++) {
                  if (staff.skillList) {
                    for (let i = 0; i < staff.skillList.length; i++) {
                      const staffSkill = self.cloneStaff(staff);
                      staffSkill.departmentUuId = staff.departmentList[d].uuId;
                      staffSkill.departmentName = staff.departmentList[d].name;
                      staffSkill.skillUuId = staff.skillList[i].uuId;
                      staffSkill.skillsName = staff.skillList[i].name;
                        
                      if (!(`${staffSkill.uuId}${staffSkill.companyUuId}${staffSkill.locationUuId}${self.grouping.stage ? staffSkill.stageUuId : ''}${staffSkill.projectUuId}${staffSkill.departmentUuId}${staffSkill.skillUuId}` in dataMap)) {
                        data.push(staffSkill);
                        dataMap[`${staffSkill.uuId}${staffSkill.companyUuId}${staffSkill.locationUuId}${self.grouping.stage ? staffSkill.stageUuId : ''}${staffSkill.projectUuId}${staffSkill.departmentUuId}${staffSkill.skillUuId}`] = true;
                      }
                    }
                  }
                }
              }
              
              // Add the staff's other skills for their first department
              if (staff.skillList) {
                for (let i = 1; i < staff.skillList.length; i++) {
                  const staffSkill = self.cloneStaff(staff);
                  staffSkill.skillUuId = staff.skillList[i].uuId;
                  staffSkill.skillsName = staff.skillList[i].name;
                  if (-1 === data.findIndex(d => d.uuId === staffSkill.uuId &&
                                                       d.companyUuId === staffSkill.companyUuId &&
                                                       d.locationUuId === staffSkill.locationUuId &&
                                                       d.departmentUuId === staffSkill.departmentUuId &&
                                                       d.skillUuId === staffSkill.skillUuId)) {
                    data.push(staffSkill);
                  }
                }
              }
            }
          }
        }
        if (data.length !== count2) {
          this.maxLevel++;
        }
      }
      
      if (this.expandLevel > this.maxLevel) {
        this.expandLevel = this.maxLevel;
      }
      
      this.setSchedulerData(data);
      this.setFilterFieldValues();
    },
    cloneLocation(location) {
      return {
        company: location.company,
        companyUuId: location.companyUuId,
        locationUuId: location.locationUuId,
        name: location.name,
        type: location.type,
        totalDuration: location.totalDuration,
        w: location.w
      };
    },
    cloneStage(stage) {
      return {
        company: stage.company,
        companyUuId: stage.companyUuId,
        locationUuId: stage.locationUuId,
        location: stage.location,
        stageUuId: stage.stageUuId,
        name: stage.name,
        type: stage.type
      };
    },
    cloneProject(project) {
      return {
        company: project.company,
        companyUuId: project.companyUuId,
        locationUuId: project.locationUuId,
        location: project.location,
        stageUuId: project.stageUuId,
        stageName: project.stageName,
        projectUuId: project.projectUuId,
        name: project.name,
        type: project.type
      };
    },
    cloneDept(dept) {
      return {
        company: dept.company,
        companyUuId: dept.companyUuId,
        departmentUuId: dept.departmentUuId,
        location: dept.location,
        locationUuId: dept.locationUuId,
        stageUuId: dept.stageUuId,
        projectUuId: dept.projectUuId,
        name: dept.name,
        type: dept.type,
        totalDuration: dept.totalDuration,
        w: dept.w
      };
    },
    cloneSkill(skill) {
      return {
        company: skill.company,
        companyUuId: skill.companyUuId,
        departmentUuId: skill.departmentUuId,
        location: skill.location,
        locationUuId: skill.locationUuId,
        stageUuId: skill.stageUuId,
        projectUuId: skill.projectUuId,
        skill: skill.skill,
        skillUuId: skill.skillUuId,
        name: skill.name,
        type: skill.type,
        totalDuration: skill.totalDuration,
        w: skill.w
      };
    },
    cloneStaff(staff) {
      const customFieldList = {};
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        for (const c of this.customFields) {
          if (Object.hasOwn(staff, c.name)) {
            customFieldList[c.name] = staff[c.name];
          }
        }
      }
      return {
        avatar: staff.avatar,
        name: staff.name,
        calendars: staff.calendars,
        company: staff.company,
        companyUuId: staff.companyUuId,
        generic: staff.generic,
        location: staff.location,
        locationUuId: staff.locationUuId,
        department: staff.department,
        departmentUuId: staff.departmentUuId,
        skill: staff.skills,
        skillUuId: staff.skillUuId,
        skillList: staff.skillList,
        stageUuId: staff.stageUuId,
        projectUuId: staff.projectUuId,
        uuId: staff.uuId,
        taskList: staff.taskList,
        activityList: staff.activityList,
        bookingList: staff.bookingList,
        type: staff.type,
        nonWorking: staff.nonWorking,
        perhourCost: staff.perhourCost,
        w: staff.w,
        ...customFieldList
      };
    },
    processNodes() {
      this.maxLevel = 0;
      if (this.gridOptions == null || this.gridOptions.api == null) {
        return;
      }
      //Fixed #897, use setTimeout to make sure grid node is refreshed.
      setTimeout(() => {
        this.isTaskExpandedByActionBar = true;
        if (this.gridOptions != null && this.gridOptions.api != null) {
          this.gridOptions.api.forEachNode(node => {
            if (node.level < this.expandLevel) {
              node.expanded = true;
            } else if (node.allChildrenCount && node.allChildrenCount > 0) {
              node.expanded = false;
            }
          
            
            // update the max level
            if (node.level > this.maxLevel) {
              this.maxLevel = node.level;
            }
          });
          this.gridOptions.api.onGroupExpandedOrCollapsed();
        }
        
        if (this.maxLevel < this.expandLevel) {
          this.gridOptions.groupDefaultExpanded = this.expandLevel = this.maxLevel;
        }

        //setTimeout is used to wait the onRowGroupOpened events finish their process.
        setTimeout(() => {
          this.isTaskExpandedByActionBar = false;
        }, 300);

      }, 50)
    },
    collapse() {
      this.expandLevel--;
      this.processNodes();
      // save
      this.layoutProfile.staffExpandLevel = this.expandLevel;
      this.updateLayoutProfile();
    },
    expand() {
      this.expandLevel++;
      this.processNodes();
      // save
      this.layoutProfile.staffExpandLevel = this.expandLevel;
      this.updateLayoutProfile();

      //Issue: The vertical scroll position in RHS timeline is out of sync after performing expand, collapse and expand.
      //Set flag to set RHS timeline scroll position again in schedulerDataRender()
      this.needScroll = true;
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (arg.toElement != null && typeof arg.toElement.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover')) {
        this.$refs.view.visible = false;
      }
    },
    onFilterOver() {
      this.$refs.filter.visible = true;
    },
    onFilterLeave() {
      this.$refs.filter.visible = false;
    },
    onInfoOver(index) {
      profileService.nodeList(this.staffViews[index].uuId).then((response) => {
        this.staffViews[index].owner = response.data.resultList.filter(v => this.staffViews[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    onFilterSubmit() {
      this.filterTextFocus = false;
      this.$refs.filter.visible = false;
      this.closePriorityNavDropdown = true; //Signal priorityNavigation to close the dropdown.

      this.changeFilter(this.filterText);
    },
    onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    },
    onUnders() {
      this.showUnders = !this.showUnders;
      this.layoutProfile['showUnders'] = this.showUnders;
      this.updateLayoutProfile();
    },
    onOptimal() {
      this.showOptimal = !this.showOptimal;
      this.layoutProfile['showOptimal'] = this.showOptimal;
      this.updateLayoutProfile();
    },
    onOvers() {
      this.showOvers = !this.showOvers;
      this.layoutProfile['showOvers'] = this.showOvers;
      this.updateLayoutProfile();
    },
    gotoDaily(value) {
      this.span = 'Daily';
      this.showColumn = value;
      this.updateGrid();
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    },
    cleanup() {
      staffData = [];
      companies = {};
      locations = {};
      departments = {};
      skills = {};
      activities = {};
      bookings = {};
    },
    taskSelectOk({taskUuid, projectUuid, type}) {
      if (type === 'task') {
        this.taskEdit.uuId = taskUuid;
        this.taskEdit.projectUuid = projectUuid;
        this.taskEditShow = true;
      }
      else if (type === 'activity') {
        this.activityOpen(taskUuid);
      }
      else if (type === 'booking') {
        this.bookingOpen(taskUuid);
      }
    },
    getUuid(data) {
      if (data.type === 'task') {
        return data.tu;
      }
      else if (data.type === 'company') {
        return data.companyUuId;
      }
      else if (data.type === 'location') {
        return data.locationUuId;
      }
      else if (data.type === 'stage') {
        return data.stageUuId;
      }
      else if (data.type === 'project') {
        return data.projectUuId;
      }
      else if (data.type === 'department') {
        return data.departmentUuId;
      }
      else if (data.type === 'skill') {
        return data.skillUuId;
      }
      return data.uuId;
    },
    roundMinutes(minutes) {
      //focus on hour and minutes. 15 minutes as time interval.
      let delta = minutes % 15;
      if (delta < 8) {
        delta = 0;
      } else {
        delta = 1;
      }
      return (parseInt(minutes / 15) + delta) * 15;
    },
    async eventUpdated(evts) {
      const self = this;
      let trigger = TRIGGERS.START_TIME;
      let update = false;
      
      for (const evt of evts) {
        const orig = evt.orig;
        const changed = evt.changed;
        const name = changed.text;
        const eventId = typeof changed.id === 'string' ? changed.id.slice(changed.id.length - 36) : changed.id;
        
        // We need UTC dates for the update
        let startHour = changed.start_date.getHours();
        let startMinutes = this.roundMinutes(changed.start_date.getMinutes());
        if (startMinutes >= 60) {
          startMinutes = 0;
          startHour++;
        }
        let endHour = changed.end_date.getHours();
        let endMinutes = this.roundMinutes(changed.end_date.getMinutes());
        if (endMinutes >= 60) {
          endMinutes = 0;
          if (changed.type !== 'vacation') {
            endHour++;
          }
        }
        const utcStart = Date.UTC(changed.start_date.getFullYear(), changed.start_date.getMonth(),
                  changed.start_date.getDate(), startHour,
                  startMinutes, changed.start_date.getSeconds());
        const utcEnd = Date.UTC(changed.end_date.getFullYear(), changed.end_date.getMonth(),
                  changed.end_date.getDate(), endHour,
                  endMinutes, changed.end_date.getSeconds());
        if (typeof orig.id !== 'number') { // our ids are strings, new events are numbers
          if ((orig.end_date.getTime() !== changed.end_date.getTime() ||
            orig.start_date.getTime() !== changed.start_date.getTime()) ||
            orig.staffUuid !== changed.staffUuid) {
            const d = moment(utcStart).utc();
            const end = moment(utcEnd).utc();
            if (orig.end_date.getTime() !== changed.end_date.getTime() &&
                (evt.mode === 'resize' || !isWorkingDay(DEFAULT_CALENDAR, moment(utcEnd)))) {
              trigger = TRIGGERS.CLOSE_TIME;
            }
            this.creatingEvent = false;
            if (!this.updateEvent) {
              this.updateEvent = [];
            }
            
            if (orig.type === 'vacation') {
              const startDate = new Date(changed.start_date.getTime() - (changed.start_date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
              const endDate = new Date(changed.end_date.getTime() - (changed.end_date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
              if (orig.staffUuid !== changed.staffUuid) {
                await calendarService.remove([{ uuId: eventId }]);
                const newUuid = await calendarService.create([{
                  name: changed.text,
                  startDate: startDate,
                  endDate: endDate,
                  isWorking: changed.calendar_type !== 'Leave',
                  type: changed.calendar_type
                  }],
                  changed.staffUuid)
                .then(response => {
                  return response.data[response.data.jobCase][0].uuId;
                });
                const old = cloneDeep(vacations[eventId]);
                delete vacations[eventId];
                old.uuId = newUuid;
                old.event.id = newUuid;
                vacations[newUuid] = old;
                
              
                // remove the old event and add the new one with the new id
                this.deleteEventId = changed.id;
                const markedIndex = this.markedTimespans.findIndex(m => m.id === eventId);
                if (markedIndex !== -1) {
                  const oldMarked = this.markedTimespans[markedIndex];
                  oldMarked.id = newUuid;
                  oldMarked.start = changed.start_date.getTime() - (changed.start_date.getTimezoneOffset() * 60000);
                  oldMarked.end = changed.end_date.getTime() - (changed.start_date.getTimezoneOffset() * 60000);
                  oldMarked.key = changed.section_id;
                  this.markedTimespans.splice(markedIndex, 1, oldMarked);
                }
                
                const sidx = staffSections[orig.staffUuid].nonWorking.findIndex(n => n.uuId === eventId);
                if (sidx !== null) {
                  staffSections[orig.staffUuid].nonWorking.splice(sidx, 1);
                }
                
                const cidx = staffSections[orig.staffUuid].calendars[0].findIndex(c => c.uuId === eventId);
                if (cidx !== null) {
                  staffSections[orig.staffUuid].calendars[0].splice(cidx, 1);
                }
                
                const index = staffEvents[orig.staffUuid].findIndex(t => t.id === eventId);
                if (index !== -1) {
                  staffEvents[orig.staffUuid].splice(index, 1);
                }
              
                self.processAdd({
                  uuId: newUuid,
                  name: name,
                  type: orig.type,
                  startDate: d.format('YYYY-MM-DD'),
                  startTime: d.format('HH:mm'),
                  closeDate: end.format('YYYY-MM-DD'),
                  closeTime: end.format('HH:mm'),
                  staffUuid: evt.changed.staffUuid,
                  origStaffUuid: evt.orig.staffUuid,
                  duration: evt.orig.te * 60,
                  mode: evt.mode,
                  asch: evt.orig.asch,
                  trigger: trigger
                });
              }
              else {
                await calendarService.update([{
                  uuId: eventId,
                  startDate: startDate,
                  endDate: endDate
                }]);
                self.processUpdate({
                  id: changed.id,
                  name: name,
                  type: orig.type,
                  startDate: d.format('YYYY-MM-DD'),
                  startTime: d.format('HH:mm'),
                  closeDate: end.format('YYYY-MM-DD'),
                  closeTime: end.format('HH:mm'),
                  staffUuid: evt.changed.staffUuid,
                  origStaffUuid: evt.orig.staffUuid,
                  duration: evt.orig.te * 60,
                  mode: evt.mode,
                  asch: evt.orig.asch,
                  trigger: trigger,
                  calendar_type: evt.changed.calendar_type
                });
              }
            }
            else {
              self.updateEvent.push({
                id: eventId,
                name: name,
                type: orig.type,
                origStartDate: evt.orig.start_date,
                origCloseDate: evt.orig.end_date,
                origDuration: evt.orig.te,
                startDate: d.format('YYYY-MM-DD'),
                startTime: d.format('HH:mm'),
                closeDate: end.format('YYYY-MM-DD'),
                closeTime: end.format('HH:mm'),
                staffUuid: evt.changed.staffUuid,
                origStaffUuid: evt.orig.staffUuid,
                duration: evt.orig.te * 60,
                projectColor: evt.orig.projectColor,
                mode: evt.mode,
                asch: evt.orig.asch,
                trigger: trigger
              });
            }
          }
        }
        else {
          this.creatingEvent = true;
          const d = moment(utcStart).utc();
          const end = moment(utcEnd).utc();
          self.newEvent = {
            id: eventId,
            name: null,
            startDate: d.format('YYYY-MM-DD'),
            startTime: d.format('HH:mm'),
            closeDate: end.format('YYYY-MM-DD'),
            closeTime: end.format('HH:mm'),
            staffUuid: evt.changed.staffUuid,
            staffName: staffData.filter(s => s.uuId === evt.changed.staffUuid)[0].name,
            description: null,
            projectBooking: self.newEvent.projectBooking, // for booking
            projectTask: self.newEvent.projectTask
          };
          this.promptType = true;
        }
      }
      
      if (!this.creatingEvent &&
          this.updateEvent.length > 0) {
        this.initDurationCalculation({ trigger: trigger });
      }
      else if (update) {
        //this.updateGrid();
      }
    },
    cleanupCreate() {
      // Remove the event created by the scheduler which has a numeric id 12345678
      this.deleteEventId = this.newEvent.id;
      this.newEvent = {
        name: null,
        description: null,
        staffUuid: this.newEvent.staffUuid,
        staffName: this.newEvent.staffName,
        projectBooking: this.newEvent.projectBooking, // remember the selected project
        projectTask: this.newEvent.projectTask
      };
    },
    updateTypeValues(event) {
      if (event.type === 'Activity') {
        this.eventTypeValues.activityName = this.newEvent.name;
        this.eventTypeValues.typeTab = 1;
      }
      else if (event.type === 'Task') {
        this.eventTypeValues.taskName = this.newEvent.name;
        this.eventTypeValues.typeTab = 2;
        if (event.projectTask) {
          this.eventTypeValues.projectTask = event.projectTask;
        }
      }
      else if (event.type === 'Vacation') {
        this.eventTypeValues.vacationName = this.newEvent.name;
        this.eventTypeValues.typeTab = 3;
      }
      else if (event.type === 'Booking') {
        this.eventTypeValues.typeTab = 0;
        if (event.projectBooking) {
          this.eventTypeValues.projectBooking = event.projectBooking;
        }
      }
      
      this.layoutProfile[this.getProfileEntryName('typevalues')] = this.eventTypeValues;
      this.updateLayoutProfile();
    },
    async onPromptTypeOk(event) {
      this.newEvent = event;
      let update = false;
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('activity.progress.adding_items');
      
      // save the names in the profile
      this.updateTypeValues(event);
      
      if (this.newEvent.type === 'Activity' ||
          this.newEvent.type === 'Booking' ||
          this.newEvent.type === 'Task') {

        const staffUuid = this.creatingEvent ? this.newEvent.staffUuid : this.updateEvent[0].staffUuid;
        // get the staff calendar
        const self = this;
        let calendar = null;
        if (staffUuid) {
          calendar = await staffService.calendar(staffUuid)
          .then((response) => {
            // combine the calendar lists into single lists for each day
            const data = response.data[response.data.jobCase];
            return transformCalendar(processCalendar(data));
          })
          .catch((e) => {
            self.httpAjaxError(e);
            return null;
          });
        }
        
        if (calendar == null) {
          calendar = cloneDeep(DEFAULT_CALENDAR);
        }

        const isNotWork = !isWorkingDay(calendar, moment(this.newEvent.startDate))
        this.initDurationCalculation({ trigger: isNotWork ? TRIGGERS.START_TIME : TRIGGERS.CLOSE_TIME, calendar });
      }
      else if (this.newEvent.type === 'Vacation') {
        this.newEvent.uuId = await createVacation(this.newEvent.name, 
                              this.newEvent.description,
                              this.newEvent.startDate, 
                              this.newEvent.startTime, 
                              this.newEvent.closeDate, 
                              this.newEvent.closeTime, 
                              this.newEvent.value, 
                              this.newEvent.staffUuid,
                              this.newEvent.multiStaff);
        update = true;
        if (!this.show.vacation) {
          this.promptShowVacation = true;
        }
        this.callback = this.cleanupCreate;
      }
      
      if (update) {
        this.inProgressShow = false;
        if (Array.isArray(this.newEvent.uuId)) {
          const uuIds = this.newEvent.uuId;
          let idx = 0;
          for (const uuId of uuIds) {
            this.newEvent.uuId = uuId;
            this.newEvent.staffUuid = this.newEvent.multiStaff[idx++].uuId;
            this.processAdd(this.newEvent);
          }
        }
        else {
          this.processAdd(this.newEvent);
        }
      }
    },
    onPromptTypeCancel() {
      this.deleteEventId = this.newEvent.id;
    },
    checkTaskBooking(event) {
      delete event.booking;
      
      // check if the task has a booking
      const task = tasks[event.id];
      const taskProjectBookings = projectBookings[task.project];
      if (taskProjectBookings) {
        for (const booking of taskProjectBookings) {
          if (task.begin >= booking.begin &&
              task.until <= booking.until &&
              booking.staffUuid === task.staffUuid[0]) {
            // found
            event.booking = booking;
            break;
          }
        }
      }
    },
    checkSections(staff) {
      const events = staffEvents[staff.uuId];
      if (events) {
        // make sure the section_id contains the staff key
        for (let i = 0; i < events.length; i++) {
          let arr = events[i].section_id ? events[i].section_id.split(',') : [];
          if (!arr.includes(staff.key)) {
            arr.push(staff.key);
            events[i].section_id = arr.join(',');
            
            if (events[i].type === 'task') {
              this.checkTaskBooking(events[i]);
            }
          }
        }
      }
    },
    getColor(obj) {
      if (this.coloring.none) {
        return null;
      }
      else if (this.coloring.company &&
          obj.companyColor) {
        return obj.companyColor;
      }
      else if (this.coloring.location &&
          obj.locationColor) {
        return obj.locationColor;
      }
      else if (this.coloring.department &&
          obj.departmentColor) {
        return obj.departmentColor;
      }
      else if (this.coloring.skill &&
          obj.skillColor) {
        return obj.skillColor;
      }
      else if (this.coloring.staff &&
          obj.staffColor) {
        return obj.staffColor;
      }
      else if (this.coloring.event &&
          obj.eventColor) {
        return obj.eventColor;
      }
      else if (this.coloring.stage &&
          obj.stageColor) {
        return obj.stageColor;
      }
      else if (this.coloring.project &&
          obj.projectColor) {
        return obj.projectColor;
      }
      return null;
    },
    getTextColor(obj) {
      if (this.coloring.none) {
        return null;
      }
      else if (this.coloring.company &&
          obj.companyTextColor) {
        return obj.companyTextColor;
      }
      else if (this.coloring.location &&
          obj.locationTextColor) {
        return obj.locationTextColor;
      }
      else if (this.coloring.department &&
          obj.departmentTextColor) {
        return obj.departmentTextColor;
      }
      else if (this.coloring.skill &&
          obj.skillTextColor) {
        return obj.skillTextColor;
      }
      else if (this.coloring.staff &&
          obj.staffTextColor) {
        return obj.staffTextColor;
      }
      else if (this.coloring.event &&
          obj.eventTextColor) {
        return obj.eventTextColor;
      }
      else if (this.coloring.stage &&
          obj.stageTextColor) {
        return obj.stageTextColor;
      }
      return null;
    },
    shouldAdd(event) {
      if (!event.section_id) {
        return false;
      }
      
      return true;
    },
    setSchedulerData(rowData) {
      const self = this;
      self.markedTimespans.splice(0, self.markedTimespans.length);
      // Build the tree
      rowData.forEach(d => {
        delete d.children; // clear out the child items
        d.uuId = self.getUuid(d);
        d.key = self.getRowId({data: d});
        d.label = d.name;
        d.folder = d.type !== 'staff';
        d.path = self.getDataPath(d);
        if (d.path.length > 1) {
          d.parentId = d.path[d.path.length - 2];
        }
        else {
          d.parentId = 'ROOT';
        }
        d.open = self.folderState[d.key] || (!(d.key in self.folderState) && d.path.length <= self.expandLevel);
        if (d.type === 'staff') {
          self.checkSections(d);
          if (d.nonWorking) {
            for (const non of d.nonWorking) {
              self.markedTimespans.push( { 
                id: non.id, 
                name: non.name, 
                start: non.start, 
                end: non.end, 
                key: d.key,
                readonly: non.readonly
              });
            }
          }
        }
      });
      
      // sort by parent id to collect all the child items
      rowData.sort(function (a, b) {
        return a.parentId.localeCompare(b.parentId);
      });
      
      let children = [];
      rowData.forEach(d => {
         if (children.length === 0 || children[0].parentId === d.parentId) {
          children.push(d);
        }
        else if (children.length > 0) {
          // find the parent
          const parent = rowData.find(p => p.key === children[0].parentId);
          if (parent) {
            parent.children = children;
            parent.unfilteredChildren = [...parent.children];
            parent.children.sort(function (a, b) {
              if (a.type !== 'staff' && b.type === 'staff') {
                return -1;
              }
              else if (a.type === 'staff' && b.type !== 'staff') {
                return 1;
              }
              return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            });
          }
          children = [d];
        }
      });
      
      if (children.length > 0) {
        // find the parent
        const parent = rowData.find(p => p.key === children[0].parentId);
        if (parent) {
          parent.children = children;
          parent.unfilteredChildren = [...parent.children];
          parent.children.sort(function (a, b) {
            if (a.type !== 'staff' && b.type === 'staff') {
              return -1;
            }
            else if (a.type === 'staff' && b.type !== 'staff') {
              return 1;
            }
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
          });
        }
        children = [];
      }

      if (this.badgeFilters.length !== 0) {
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
      }
      
      const usagefilter = this.badgeFilters.find(f => f.field === 'usage');
      const availabilityfilter = this.badgeFilters.find(f => f.field === 'availability');
      for (let index = rowData.length - 1; index >= 0; index--) {
        const d = rowData[index];
        if (!filterCheck(d, this.badgeFilters.filter(f => f.field !== 'usage' && (typeof f.filterStaff === 'undefined' || f.filterStaff)), this.filterValue, staffEvents, this.staffUsageProject ? this.id : null) ||
            (usagefilter && !this.usageMatch(usagefilter, d)) ||
            (availabilityfilter &&  !this.usageMatch(availabilityfilter, d))) {
          rowData.splice(index, 1);
        }
      }
      
      for (let idx = rowData.length - 1; idx >= 0; idx--) {
        const d = rowData[idx];
        
        // if staff are shown we need to ensure that they exists at the lowest level
        if (this.grouping.staff ||
            this.showGeneric) {
          self.pruneChildren(idx, d, rowData);
        }
      }

      //Signal scheduler not to call processTreeNodes due to the change of expandLevel. 
      //processTreeNodes will be called once when treeData is changed.
      //To prevent duplicated call
      this.skipProcessNodes = true;
      
      // set the events for the scheduler
      const arr = [];
      const added = {};
      for (const key of Object.keys(staffEvents)) {
        const events = staffEvents[key];
        for (const event of events) {
          if (this.shouldAdd(event)) {
            if (event.section_id.includes(',') ||
                event.id in added) {
              // workaround for multisection event bug
              const sections = event.section_id.split(',');
              for (const section of sections) {
                const clonedEvent = { 
                  dotcolor: self.getColor(event),
                  textColor: self.getTextColor(event),
                  type: event.type,
                  id: `${section}${event.id}`,
                  te: event.te, 
                  tp: event.tp,
                  pu: event.pu,
                  pn: event.pn,
                  project: event.project,
                  asch: event.asch,
                  readonly: event.readonly,
                  start_date: event.start_date, 
                  end_date: event.end_date, 
                  text: event.text, 
                  stageName: event.stageName,
                  stageColor: event.stageColor,
                  section_id: section, 
                  staffUuid: event.staffUuid,
                  calendar_type: event.calendar_type
                };
                arr.push(clonedEvent);
                if (!(event.id in added)) {
                  added[event.id] = event;
                }
                
                if (!added[event.id].clones) {
                  added[event.id].clones = [];
                }
                event.clones = clonedEvent.clones = added[event.id].clones;  // every clone needs to reference the list
                added[event.id].clones.push(clonedEvent.id);
              }
            }
            else {
              event.dotcolor = self.getColor(event);
              event.textColor = self.getTextColor(event);
              arr.push(event);
            }
            added[event.id] = event; // keep track of added events, we need unique ids
          }
        }
      }
      
      self.schedulerTasks = arr;
      
      // this.pendingSetGridRowData = true;
      const filteredData = rowData.filter(f => f.path.length <= 1).sort(function (a, b) {
        if (a.type !== 'staff' && b.type === 'staff') {
          return -1;
        }
        else if (a.type === 'staff' && b.type !== 'staff') {
          return 1;
        }
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      rowData = [];
      const populateChildrenAsRow = (children, list) => {
        for (const c of children) {
          list.push(c);
          if (Array.isArray(c.children) && c.children.length > 0) {
            populateChildrenAsRow(c.children, list);
          }
        }
      }
      populateChildrenAsRow(filteredData, rowData);

      self.needRedrawViewport = true;
      this.rowData = rowData;
      if (this.gridReady === true) {
        this.gridOptions.api.setRowData(this.rowData);
        this.applySort();
      } else {
        this.rowDataReady = true;
      }
    },
    applySort() {
      const columns = this.gridOptions.columnApi.getAllDisplayedColumns();
      const curSortColumn = columns.find(i => i.sort != null);
      if (curSortColumn) {
        this.fetchRowDataForSortedColumn(this.gridOptions.api, curSortColumn.colId);
      }
    },
    pruneChildren(index, d, rowData) {
      if (!d.children && d.type !== 'staff') {
        rowData.splice(index, 1);
      }
      else if (d.children) {
        for (let idx = d.children.length - 1; idx >= 0; idx--) {
          this.pruneChildren(idx, d.children[idx], d.children);
        }
      }
    },
    getEndJSDate(date) {
      if (this.schedulerSpan === 'day') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      }
      else if (this.schedulerSpan === 'week') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6, 23, 59, 59);
      }
      else if (this.schedulerSpan === 'month') {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      else if (this.schedulerSpan === 'year') {
        return new Date(date.getFullYear(), 11, 31);
      }
    },
    getTotalUsage(data) {
      var state = this.scheduler.getState();
      /* eslint-enable no-undef */
      var from = new Date(state.min_date),
      to = new Date(state.max_date);
      let ret = false;
      data.totalFixedDuration = 0;
      data.totalEstimatedDuration = 0;
      data.totalActualDuration = 0;
      data.totalFixedCost = 0;
      data.totalEstimatedCost = 0;
      data.totalActualCost = 0;
      
      while(+from < +to){
        const projectId = this.grouping.project ? data.projectUuId : 
                        this.id;
        const quota = data.quota ? data.quota : 1;
        const w = this.calculateUsage(from, data, projectId);
        data.totalFixedDuration += w.wFixed;
        data.totalEstimatedDuration += w.w;
        data.totalActualDuration += w.wActual;
        data.totalFixedCost += w.fixedCost;
        data.totalEstimatedCost += w.cost;
        data.totalActualCost += w.actualCost;
        
        from = this.incrementDate(from);
      }
    },
    usageMatch(usagefilter, data) {
      var from = new Date(this.startDate),
        to = new Date(this.endDate);
      let ret = false;
      
      let runlen = 0;
      // only check staff
      if (data.type === 'staff') {
        while(+from < +to){
          const projectId = this.grouping.project ? data.projectUuId : 
                          this.id;
          const quota = data.quota ? data.quota : 1;
          const w = this.calculateUsage(from, data, projectId);
          let workingHours = w && w.w ? w.w : 0;
          const availableHours = w && w.a ? w.a * quota : 0;
          if (availableHours - workingHours > 0) {
            runlen += (availableHours - workingHours) / 8;
          }
          else {
            runlen = 0;
          }
          const percent = Math.round(workingHours / availableHours * 100)
          if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === "Unders") && percent >= 0 && percent <= 75) {
            ret = true;
            break;
          }
          else if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === "Optimal") && percent >= 76 && percent <= 100) {
            ret = true;
            break;
          }
          else if (Array.isArray(usagefilter.value) && usagefilter.value.find(v => v.text === "Overs") && percent > 100) {
            ret = true;
            break;
          }
          else if (!Array.isArray(usagefilter.value) && data.type === 'staff' && parseInt(convertDisplayToDuration(usagefilter.value).value/60/8) <= runlen) {
            const endDate = this.getEndJSDate(from);
            if ((data.begin - 86400000) > from.getTime() || // subtract 1 day so that the start date is shown as employed
                     data.until + 86400000 < endDate.getTime()) {
              // not employed        
            }
            else {
              ret = true;
            }
            break;
          }
          
          from = this.incrementDate(from);
        }
      }      
          
      if (data.children) {
        for (let c = data.children.length - 1; c >= 0; c--) {
          const child = data.children[c];
          if (this.usageMatch(usagefilter, child)) {
            ret = true;
          }
          else {
            data.children.splice(c, 1);
          }
        }
        
        if (data.children.length === 0) {
          delete data.children;
          ret = false;
        }
      }
      return ret;
    },
    incrementDate(date) {
      if (this.span === 'Daily') {
        date.setDate(date.getDate() + 1);
      }
      else if (this.span === 'Weekly') {
        date.setDate(date.getDate() + 7);
      }
      else if (this.span === 'Monthly') {
        date.setMonth(date.getMonth() + 1);
      }
      else if (this.span === 'Yearly') {
        date.setFullYear(date.getFullYear() + 1);
      }
      return date;
    },
    calculateUsage(date, section, projectId) {
      if (section.type === 'staff') {
        // We need UTC dates for the usage
        const utcDate = Date.UTC(date.getFullYear(), date.getMonth(),
                  date.getDate(), date.getHours(),
                  date.getMinutes(), date.getSeconds());
        const d = moment(utcDate).utc();
        const w = getUsage(section.calendars, 
                           d, 
                           this.schedulerSpan, 
                           this.getWorkList(section, projectId),
                           section.begin,
                           section.until,
                           section.quota,
                           this.staffAlloc,
                           section.perhourCost
                           )
        return w;
      }
      else if (section.unfilteredChildren) {
        const w = { a: 0, w: 0, h: 0, ha: 0, t: [] };
        for (const child of section.unfilteredChildren) {
          const result = this.calculateUsage(date, child, null);
          w.a += result.a;
          w.w += result.w;
          w.h += result.h;
          w.ha += result.ha;
          //w.t = this.combineTasks(w.t, result.t);
        }
        return w;
      }
      return { a: 0, w: 0, h: 0, ha: 0, t: [] };
    },
    getWorkList(section, projectId) {
      const ret = [];
      
      if (!projectId) {
        if (this.show.usageTasks && section.taskList) {
          if (this.grouping.project) {
            ret.push(...section.taskList.filter(t => section.key.includes(t.project)));
          }
          else {
            ret.push(...section.taskList);
          }
        }
        if (this.show.usageActivities && section.activityList) {
          ret.push(...section.activityList);
        }
        if (this.show.usageBookings && section.bookingList) {
          if (this.grouping.project) {
            ret.push(...section.bookingList.filter(b => section.key.includes(b.project)));
          }
          else {
            ret.push(...section.bookingList);
          }
        }
      }
      else {
        if (this.show.usageTasks && section.taskList) {
          ret.push(...section.taskList.filter(t => t.project === projectId));
        }
        if (this.show.usageActivities && section.activityList) {
          ret.push(...section.activityList);
        }
        if (this.show.usageBookings && section.bookingList) {
          ret.push(...section.bookingList.filter(t => t.project === projectId));
        }
      }
                              
      return ret;
    },
    schedulerClickItem({id, data}) {
      const eventId = typeof id === 'string' ? id.slice(id.length - 36) : id;
      this.openDetail(eventId, { data: data });
    },
    setFilterFieldValues() {
      const companyList = [];
      for (const ckey of Object.keys(companies)) {
        if (-1 === companyList.findIndex(i => i.text === companies[ckey].name)) {
          companyList.push({ text: companies[ckey].name });
        }
      }
      
      const departmentList = [];
      for (const dkey of Object.keys(departments)) {
        if (-1 === departmentList.findIndex(i => i.text === departments[dkey].name)) {
          departmentList.push({ text: departments[dkey].name });
        }
      }
      
      const locationList = [];
      for (const lkey of Object.keys(locations)) {
        if (-1 === locationList.findIndex(i => i.text === locations[lkey].name)) {
          locationList.push({ text: locations[lkey].name });
        }
      }
      
      const skillList = [];
      for (const sskey of Object.keys(skills)) {
        if (-1 === skillList.findIndex(i => i.text === skills[sskey].name)) {
          skillList.push({ text: skills[sskey].name });
        }
      }
      
      const taskList = [];
      const addedTasks = {};
      const stageList = [];
      const projectList = [];
      for (const tkey of Object.keys(tasks)) {
        if (!(tasks[tkey].name in addedTasks)) {
          taskList.push({ text: tasks[tkey].name });
          addedTasks[tasks[tkey].name] = true;
        }
        if (tasks[tkey].stageName && -1 === stageList.findIndex(i => i.text === tasks[tkey].stageName)) {
          stageList.push({ text: tasks[tkey].stageName });
        }
        if (tasks[tkey].pn && -1 === projectList.findIndex(i => i.text === tasks[tkey].pn)) {
          projectList.push({ text: tasks[tkey].pn });
        }
      }
      
      const bookingList = [];
      for (const bkey of Object.keys(bookings)) {
        if (-1 === bookingList.findIndex(i => i.text === bookings[bkey].name)) {
          bookingList.push({ text: bookings[bkey].name });
        }
        if (bookings[bkey].stageName && -1 === stageList.findIndex(i => i.text === bookings[bkey].stageName)) {
          stageList.push({ text: bookings[bkey].stageName });
        }
        if (bookings[bkey].pn && bookings[bkey].pn && -1 === projectList.findIndex(i => i.text === bookings[bkey].pn)) {
          projectList.push({ text: bookings[bkey].pn });
        }
      }
      
      const activityList = [];
      for (const akey of Object.keys(activities)) {
        if (-1 === activityList.findIndex(i => i.text === activities[akey].name)) {
          activityList.push({ text: activities[akey].name });
        }
        if (activities[akey].stageName && -1 === stageList.findIndex(i => i.text === activities[akey].stageName)) {
          stageList.push({ text: activities[akey].stageName });
        }
      }
      
      for (const stgkey of Object.keys(stages)) {
        if (stages[stgkey].name && -1 === stageList.findIndex(i => i.text === stages[stgkey].name)) {
          stageList.push({ text: stages[stgkey].name });
        }
      }
      
      for (const pkey of Object.keys(projects)) {
        if (projects[pkey].name && -1 === projectList.findIndex(i => i.text === projects[pkey].name)) {
          projectList.push({ text: projects[pkey].name });
        }
      }
      
      const vacationList = [];
      for (const vkey of Object.keys(vacations)) {
        if (-1 === vacationList.findIndex(i => i.text === vacations[vkey].name)) {
          vacationList.push({ text: vacations[vkey].name });
        }
      }
      
      const staffList = [];
      const staffTypeList = [];
      const tagList = [];
      const addedStaff = {};
      
      for (const staffkey of Object.keys(staffData)) {
        if (!(staffData[staffkey].name in addedStaff)) {
          staffList.push({ text: staffData[staffkey].name });
          addedStaff[staffData[staffkey].name] = true;
        }
        if (-1 === staffTypeList.findIndex(i => i.text === staffData[staffkey].staffType)) {
          staffTypeList.push({ text: staffData[staffkey].staffType });
        }
        
        if (staffData[staffkey].tagList) {
          for (const tag of staffData[staffkey].tagList) {
            if (-1 === tagList.findIndex(i => i.text === tag.name)) {
              tagList.push({ text: tag.name });
            }
          }
        }
      }
      
      const valuesSortFunc = function (a, b) {
        return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
      };
      companyList.sort(valuesSortFunc);
      departmentList.sort(valuesSortFunc);
      locationList.sort(valuesSortFunc);
      skillList.sort(valuesSortFunc);
      activityList.sort(valuesSortFunc);
      bookingList.sort(valuesSortFunc);
      projectList.sort(valuesSortFunc);
      taskList.sort(valuesSortFunc);
      staffList.sort(valuesSortFunc);
      staffTypeList.sort(valuesSortFunc);
      stageList.sort(valuesSortFunc);
      tagList.sort(valuesSortFunc);
      vacationList.sort(valuesSortFunc);
        
      const usageList = [];
      usageList.push({ text: this.$t('button.unders') });
      usageList.push({ text: this.$t('button.optimal') });
      usageList.push({ text: this.$t('button.overs') });
      
      this.badgeFilterFieldValues = {
        company: companyList,
        department: departmentList,
        location: locationList,
        skillLevel: this.skillLevels,
        skill: skillList,
        activityName: activityList,
        bookingName: bookingList,
        taskName: taskList,
        nonWork: vacationList,
        projectName: projectList,
        staffName: staffList,
        staffType: staffTypeList,
        stageName: stageList,
        tag: tagList,
        usage: usageList,
        availability: ''
      }
    },
    getProfileEntryName(value) {
      if (this.id) {
        return `staff_usage_${value}`;
      }
      return `staffplanner_view_${value}`;
    },
    getViewType() {
      if (this.id) {
        return 'staff';
      }
      return 'staff_planner';
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    onColorChange(val, color_key) {
      const coloring = this.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.layoutProfile[color_key] = coloring;
      this.updateLayoutProfile();
      this.assignData();
      this.$nextTick(() => {
        this.redrawScheduler = true;
      });
    },
    schedulerRedrawn() {
      this.redrawScheduler = false;
      this.gridOptions.api.resetRowHeights();
      setTimeout(() => {
        document.getElementById("scheduler_here").focus();
      }, 100);
    },
    getDataPath(data) {
      const self = this;
      if (typeof data !== 'undefined') {
        const path = [self.getRowId({ data: data })];
        
        if ((self.grouping.staff || (self.showGeneric && data.staffUuid in self.genericStaff)) && 
            !(!self.showGeneric && data.staffUuid in self.genericStaff) &&
            data.type && data.staffUuid) { 
          let staffId = '';
          if (self.grouping.stage && data.stageUuId) {
            staffId += data.stageUuId;
          }
          if (self.grouping.project && data.projectUuId) {
            staffId += data.projectUuId;
          }
          if (self.grouping.department && data.departmentUuId) {
            staffId += data.departmentUuId;
          }
          if (self.grouping.skills && data.skillUuId) {
            staffId += data.skillUuId;
          }
          staffId += data.staffUuid;
          path.unshift(staffId);
        }
        if (self.grouping.skills && data.type !== 'skill' && data.skillUuId) {
          let skillId = '';
          if (self.grouping.company && data.companyUuId) {
            skillId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            skillId += data.locationUuId;
          }
          if (self.grouping.stage && data.stageUuId) {
            skillId += data.stageUuId;
          }
          if (self.grouping.project && data.projectUuId) {
            skillId += data.projectUuId;
          }
          if (self.grouping.department && data.departmentUuId) {
            skillId += data.departmentUuId;
          }
          
          skillId += data.skillUuId;
          path.unshift(skillId);
        }
        if (self.grouping.department && data.type !== 'department' && data.departmentUuId) {
          let deptId = '';
          if (self.grouping.company && data.companyUuId) {
            deptId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            deptId += data.locationUuId;
          }
          if (self.grouping.stage && data.stageUuId) {
            deptId += data.stageUuId;
          }
          if (self.grouping.project && data.projectUuId) {
            deptId += data.projectUuId;
          }
          deptId += data.departmentUuId;
          path.unshift(deptId);
        }
        
        if (self.grouping.project && data.type !== 'project' && data.projectUuId) {
          let projectId = '';
          if (self.grouping.company && data.companyUuId) {
            projectId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            projectId += data.locationUuId;
          }
          if (self.grouping.stage && data.stageUuId) {
            projectId += data.stageUuId;
          }
          projectId += data.projectUuId;
          path.unshift(projectId);
        }
        if (self.grouping.stage && data.type !== 'stage' && data.stageUuId) {
          let stageId = '';
          if (self.grouping.company && data.companyUuId) {
            stageId += data.companyUuId;
          }
          if (self.grouping.location && data.locationUuId) {
            stageId += data.locationUuId;
          }
          stageId += data.stageUuId;
          path.unshift(stageId);
        }
        
        if (self.grouping.location && data.type !== 'location' && data.locationUuId) {
          if (self.grouping.company) {
            path.unshift(`${data.companyUuId}${data.locationUuId}`);
          }
          else {
            path.unshift(data.locationUuId);
          }
        }
        // build the path hierarchy based upon the current selections
        if (self.grouping.company && data.type !== 'company' && data.companyUuId) {
          if (Array.isArray(data.companyUuId)) {
            path.unshift(data.companyUuId);
          }
          else {
            path.unshift(data.companyUuId);
          }
        }
        return path;
      }
      return [];
    },
    progressCancel() {
      this.$set(this.inProgressState, 'cancel', true);
      this.inProgressLabel = this.$t('task.progress.stopping');
    },
    deleteEvent(toRemove) {
      this.deleteEvents(toRemove);
    },
    async deleteEvents(toRemove) {
      const self = this;
      let toRemoveActivities = toRemove.filter(r => r.type === 'activity');
      const toRemoveBookings = toRemove.filter(r => r.type === 'booking');
      let toRemoveTasks = toRemove.filter(r => r.type === 'task');
      const toRemoveVacations = toRemove.filter(r => r.type === 'vacation').map(v => {return { uuId: v.uuId }});
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('activity.progress.deleting_items');
      
      if (toRemoveActivities.length !== 0) {
        // remove duplicates
        toRemoveActivities = toRemoveActivities.filter((value, index, self) =>
        index === self.findIndex((t) => 
          t.uuId === value.uuId
        ));
        await activityService.remove(toRemoveActivities);
      }
      
      if (toRemoveBookings.length !== 0) {
        await bookingService.remove(toRemoveBookings);
      }
      
      if (toRemoveTasks.length !== 0) {
        // remove duplicates
        toRemoveTasks = toRemoveTasks.filter((value, index, self) =>
        index === self.findIndex((t) => 
          t.uuId === value.uuId
        ));
        await taskService.remove(toRemoveTasks);
      }
      
      if (toRemoveVacations.length !== 0) {
        await calendarService.remove(toRemoveVacations);
        
        // remove marked timespans
        setTimeout(() => {
          for (const r of toRemoveVacations) {
            const idx = self.markedTimespans.findIndex(m => m.id === r.uuId);
            if (idx !== -1) {
              self.markedTimespans.splice(idx, 1);
            }
          }
        }, 100);
      }
       
      for (const r of toRemove) {
      
        const index = this.schedulerTasks.findIndex(t => t.id.slice(t.id.length - 36) === r.uuId);
        if (index !== -1) {
          const type = this.schedulerTasks[index].type;
          const stfSec = staffSections[this.schedulerTasks[index].staffUuid];
        
          if (type === 'vacation') {
            const calIdx = stfSec.calendars[0].findIndex(cal => cal.uuId === r.uuId);
            if (calIdx !== -1) {
              stfSec.calendars[0].splice(calIdx, 1);
            }
            const nonIdx = stfSec.nonWorking.findIndex(non => non.id === r.uuId);
            if (nonIdx !== -1) {
              stfSec.nonWorking.splice(nonIdx, 1);
            }
          }
          
          if (type === 'task' ||
              type === 'booking' ||
              type === 'activity') {
            const task = stfSec[`${type}List`].findIndex(t => t.uuId === r.uuId);
            if (task !== -1) {
              const projId = stfSec[`${type}List`][task].project;
              stfSec[`${type}List`].splice(task, 1);
              // we also need to remove the project from the staff projectList if no other tasks or bookings have this project
              this.updateStaffProjects(projId, type, stfSec);
            }
          }
                    
          const events = staffEvents[stfSec.uuId];
          const eventsIndex = events.findIndex(t => t.id === r.uuId);
          if (eventsIndex !== -1) {
            events.splice(eventsIndex, 1);
          }
          
          if (this.schedulerTasks[index].clones) {
            // remove the clone events also
            const clones = this.schedulerTasks[index].clones;
            this.schedulerTasks.splice(index, 1);
            for (const c of clones) {
              const cindex = this.schedulerTasks.findIndex(t => t.id === c);
              if (cindex !== -1) {
                const cstfSec = staffSections[this.schedulerTasks[cindex].staffUuid];
                const task = cstfSec.taskList.findIndex(t => t.uuId === c.slice(c.length - 36));
                if (task !== -1) {
                  cstfSec.taskList.splice(task, 1);
                }
                      
                const cevents = staffEvents[cstfSec.uuId];
                const ceventsIndex = cevents.findIndex(t => t.id === c.slice(c.length - 36));
                if (ceventsIndex !== -1) {
                  cevents.splice(ceventsIndex, 1);
                }
              }
            }
          }
        }
      }
      this.selected.splice(0, this.selected.length);
      
      // rebuild the tree and events
      this.assignData();
      // this.inProgressShow = false;
    },
    updateStaffProjects(projId, type, stfSec) {
      if (type === 'task' ||
          type === 'booking') {
        const bookingIdx = stfSec.bookingList ? stfSec.bookingList.findIndex(b => b.project === projId) : -1;
        const taskIdx = stfSec.taskList ? stfSec.taskList.findIndex(t => t.project === projId) : -1;
        if (bookingIdx === -1 &&
            taskIdx === -1) {
          const projIdx = stfSec.projectList.findIndex(p => p.uuId === projId);
          stfSec.projectList.splice(projIdx, 1);
          stfSec.stageList.splice(projIdx, 1); // projects and stages have the same index
          if (stfSec.projectList.length === 0) {
            delete stfSec.projectUuId;
            delete stfSec.projectName;
            delete stfSec.stageUuId;
            delete stfSec.stageName;
          } 
          else if (projIdx === 0) {
            stfSec.projectUuId = stfSec.projectList[0].uuId;
            stfSec.projectName = stfSec.projectList[0].name;
            stfSec.stageUuId = stfSec.stageList[0].uuId;
            stfSec.stageName = stfSec.stageList[0].name;
          }  
        }   
      }
    },
    copyEvent(events) {
      this.copied = events;
      // We need the names of the tasks for making a copy because the event names are the full path
      for (const event of this.copied) {
        if (event.type === 'task') {
          const taskId = event.refId.slice(event.refId.length - 36);
          event.name = tasks[taskId].name;
        }
      }
    },
    recalcEventDates(start_date, start_time, end_date, end_time, calendar, duration) {
      let trigger = TRIGGERS.START_TIME;
      let projectScheduleFromStart = true;
      
      const payload = {
        trigger: trigger
        , startDateStr: start_date
        , startTimeStr: start_time
        , closeDateStr: end_date
        , closeTimeStr: end_time
        , durationDisplay: duration
        , calendar: calendar
        , projScheduleFromStart: projectScheduleFromStart
        , taskAutoScheduleMode: true
        , lockDuration: false
        , autoMoveForNonWorkingDay: true
        , skipOutOfProjectDateCheck: true
        , "constraintType": null
        , "constraintDateStr": null
        , "projectStartDateStr": null
        , "projectCloseDateStr": null
        , "oldDateStr": null
        , "oldTimeStr": null
        , "resizeMode": false
        , "disableDurationBuffer": false
      }
    
      const result = calcDateTimeDurationv2(payload);
      return result;
    },
    async pasteEvent() {
      let section = null;
      let startDate = null;
      
      const selected = document.querySelector('.dhx_focus_slot');
      if (selected) {
        section = selected.getAttribute('data-section');
        startDate = moment(selected.getAttribute('data-start-date')).set("hour", 9).set("minute", 0);
      }
    
      for (const evt of this.copied) {
        let utilization = 1;
        let origStaff = evt.staffUuid;
        if (section) {
          evt.staffUuid = section.slice(section.length - 36);
        }

        let evtStart = evt.start_date;
        let evtEnd = evt.end_date;
        let uuId = null;
        
        let utcStart = Date.UTC(evtStart.getFullYear(), evtStart.getMonth(),
                  evtStart.getDate(), evtStart.getHours(),
                  evtStart.getMinutes(), evtStart.getSeconds());
        let utcEnd = Date.UTC(evtEnd.getFullYear(), evtEnd.getMonth(),
                  evtEnd.getDate(), evtEnd.getHours(),
                  evtEnd.getMinutes(), evtEnd.getSeconds());
                  
        // We need UTC dates for the creation
        if (startDate) {
          // get the length of the event
          const diff = evt.end_date.getTime() - evt.start_date.getTime();
          utcStart = startDate.unix() * 1000;
          utcEnd = utcStart + diff;
        }
        const d = moment(utcStart).utc();
        const end = moment(utcEnd).utc();
        this.creatingEvent = true;
        
        // recalculate the start and end dates to make sure the event does not span non-working days
        let calendar = null;
        const staffSection = staffSections[evt.staffUuid];
        if (staffSection.calendars) {
          const cals = [];
          const names = [ 'staff', 'location', 'base_calendar'];
          
          for (let i = staffSection.calendars.length - 1; i >= 0; i--) {
            const c = staffSection.calendars[i];
            cals.push({ name: names[i], calendarList: c });
          }
          calendar = transformCalendar(processCalendar(cals));
        }
        else {
          calendar = DEFAULT_CALENDAR;
        }
        
        let rcStartDate = d.format('YYYY-MM-DD');
        let rcStartTime = d.format('HH:mm');
        let rcCloseDate = end.format('YYYY-MM-DD');
        let rcCloseTime = end.format('HH:mm');
        const recalcResult = this.recalcEventDates(rcStartDate, rcStartTime, rcCloseDate, rcCloseTime, calendar, `${evt.te / 8}D`);
        
        const newEvent = {
          name: evt.name,
          projectBooking: this.newEvent.projectBooking,
          projectTask: this.newEvent.projectTask,
          startDate: recalcResult.startDateStr,
          startTime: recalcResult.startTimeStr,
          closeDate: recalcResult.closeDateStr,
          closeTime: recalcResult.closeTimeStr
        };
        
        if (evt.type === 'activity') {
          const activityId = evt.refId.slice(evt.refId.length - 36);
          utilization = staffSections[origStaff].activityList.filter(a => a.uuId === activityId)[0].utilization;
          uuId = await cloneActivity(newEvent.name, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              activityId,
                              evt.staffUuid,
                              evt.section_id.slice(evt.section_id.length - 36),
                              utilization);
          if (!this.show.activity) {
            this.promptShowActivity = true;
          }
        }
        else if (evt.type === 'booking') {
          const bookingId = evt.refId.slice(evt.refId.length - 36);
          const bookings = staffSections[origStaff].bookingList.filter(b => b.uuId === bookingId);
          if (bookings.length > 0) {
            utilization = bookings[0].utilization;
          }
          uuId = await cloneBooking(evt.text, 
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.staffUuid,
                              evt.project,
                              evt.eventColor,
                              bookingId,
                              utilization);
          if (!this.show.booking) {
            this.promptShowBooking = true;
          }
        }
        else if (evt.type === 'task') {
          const taskId = evt.refId.slice(evt.refId.length - 36);
          const tasks = staffSections[origStaff].taskList.filter(t => t.uuId === taskId);
          if (tasks.length > 0) {
            utilization = tasks[0].utilization;
          }
          
          uuId = await cloneTask(newEvent.name, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.pu,
                              taskId,
                              evt.staffUuid,
                              evt.section_id.slice(evt.section_id.length - 36),
                              utilization);
          // when we clone the task is added at the root
          evt.text = `${evt.pn} / ${newEvent.name}`;
          
          if (!this.show.task) {
            this.promptShowTask = true;
          }
        }
        else if (evt.type === 'vacation') {
          uuId = await createVacation(evt.text, 
                              newEvent.description,
                              newEvent.startDate, 
                              newEvent.startTime, 
                              newEvent.closeDate, 
                              newEvent.closeTime, 
                              evt.te * 60, 
                              evt.staffUuid);
        }
        
        this.processAdd({
          uuId: uuId,
          name: evt.text,
          shortName: evt.name ? evt.name : evt.text,
          type: evt.type,
          startDate: newEvent.startDate,
          startTime: newEvent.startTime,
          closeDate: newEvent.closeDate,
          closeTime: newEvent.closeTime,
          staffUuid: evt.staffUuid,
          value: evt.te * 60,
          stageName: evt.stageName,
          stageColor: evt.stageColor,
          eventColor: evt.eventColor,
          projectTask: evt.type === 'task' ? [{ uuId: evt.pu, name: entityList[evt.pu].name, color: entityList[evt.pu].color }] : null,
          projectBooking: evt.type === 'booking' ? [{ uuId: evt.project, name: entityList[evt.project].name, color: entityList[evt.project].color }] : null,
          calendar_type: evt.calendar_type,
          utilization: utilization
        });
      }
    },
    staffEvent() {
      this.showStaffSelector = true;
    },
    dateChange({date, initialized}) {
      if (!initialized || 
          this.loadingView) {
        return;
      }

      //Provided date is a local date time. So get the literal date value.
      const startDate = `${date.getFullYear()}-${date.getMonth() < 9? '0'+(date.getMonth()+1): (date.getMonth()+1)}-${date.getDate() < 10? '0'+date.getDate() : date.getDate()}`;
      if (this.startDate !== startDate) {
        // get the difference
        const start = moment(this.startDate);
        const end = moment(this.endDate);
        const diff = end.unix() - start.unix();
        const d = moment(startDate).utc();
        const newEnd = moment((d.unix() + diff) * 1000);
        // increment the end date by the difference
        this.endDate = newEnd.format("YYYY-MM-DD");
        this.startDate = startDate;
        
        
        const self = this;
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(()=> {
          // get the new data
          self.updateGrid();
          self.updateTimeout = null;
        }, 100);
      }
    },
    getEndDate(d) {
      const date = d.clone();
      if (this.schedulerSpan === 'day') {
        date.set('hour', 17);
      }
      else if (this.schedulerSpan === 'week') {
        date.add(4, 'days');
      }
      else if (this.schedulerSpan === 'month') {
        date.add(1, 'months');
        date.subtract(1, 'days');
      }
      else if (this.schedulerSpan === 'year') {
        date.add(1, 'years');
        date.subtract(1, 'days');
      }
      return date;
    },
    addEvent() {
      this.creatingEvent = true;
      let section = null;
      let staffSection = null;
      let startDate = null;
      const selected = document.querySelector('.dhx_focus_slot');
      if (selected) {
        section = selected.getAttribute('data-section');
        section = section.slice(section.length - 36); // remove extra ids and get just the staff
        startDate = new Date(Date.parse(selected.getAttribute('data-start-date')));
        staffSection = staffData.filter(s => s.uuId === section);
        if (staffSection.length === 0) {
          section = null; // this is not a staff section
        }
      }
    
      const now = startDate ? moment(startDate) : moment();
      const end = this.getEndDate(now);
      this.newEvent = {
        name: null,
        projectBooking: this.newEvent.projectBooking,
        projectTask: this.newEvent.projectTask,
        staffUuid: section ? section : null,
        staffName: section ? staffSection[0].name : null
      };
      this.newEvent.startDate = now.format('YYYY-MM-DD');
      this.newEvent.startTime = '09:00';
      this.newEvent.closeDate = end.format('YYYY-MM-DD');
      this.newEvent.closeTime = '17:00';
      const { value: dValue } = convertDisplayToDuration('1D');
      this.newEvent.value = dValue;
      
      this.promptType = true;
    },
    editEvent(data) {
      this.schedulerClickItem({ id: data.uuId, data: data });
    },
    folderToggle(state) {
      this.folderState[state.section.key] = state.isOpen;
    },
    
    async durationCalculationCalendarChange({ toAddExceptions, toUpdateExceptions, skipOutOfProjectDateCheck }) {
      //NOTE: When isTemplate ( or defaultActionForNonWorkPrompt == 'move' ) is true, this event should not be emittted. Otherwise, there is logic flaw in TaskDateTimeDurationCalculation component.
      
      //1. Call calendar service api to add or update the exceptions
      //2. Update the calendar object
      //3. Call calcDateTimeDuration()
      //4. Reload latest calendar from backend for future usage.

      let hasError = false;
      const errorMsg = this.$t(`calendar.error.failed_to_update_calendar`);
      const calendar = this.creatingEvent ? this.newEvent.calendar : this.updateEvent[0].calendar;
      const baseDateTime = moment.utc().hour(0).minute(0).second(0).millisecond(0);
      const staffUuid = this.creatingEvent ? this.newEvent.staffUuid : this.updateEvent[0].staffUuid;
        
      if (toUpdateExceptions != null && toUpdateExceptions.length > 0) {
        const _toUpdateExceptions = cloneDeep(toUpdateExceptions);
        _toUpdateExceptions.forEach(i => {
          delete i.calendar;
        });
        await calendarService.update(_toUpdateExceptions, staffUuid)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
            return;
          }

          //Update the calendar object with the change
          if (calendar.Leave == null) {
            calendar.Leave = [];
          }
          for (let i = 0, len = _toUpdateExceptions.length; i < len; i++) {
            const curException = _toUpdateExceptions[i];
            //When exception type is Working, add it to calendar.Working
            if (curException.type == 'Working') {
              if (calendar.Working == null) {
                calendar.Working = [];
              }
              calendar.Working.push(cloneDeep(curException));
            }

            //Remove old leave exception if exception type is 'Working'. Otherwise, update the old leave exception.
            const idx = calendar.Leave.findIndex(j => j.uuId === curException.uuId);
            if (idx != -1) {
              if (curException.type == 'Working') {
                calendar.Leave.splice(idx, 1);
              } else {
                calendar.Leave[idx] = cloneDeep(curException);
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
        });
      }

      //Stop proceed further when failed to update calendar exception.
      if (hasError) {
        return;
      }

      if (toAddExceptions != null && toAddExceptions.length > 0) {
        //Convert startHour and endHour from '##:##' to number in millisecond.
        for (let i = 0, len = toAddExceptions.length; i < len; i++) {
          const currentException = toAddExceptions[i];
          currentException.name = this.$t('calendar.working');

          if (currentException.startHour != null &&
              typeof currentException.startHour === 'string') {
            currentException.startHour = moment.utc(currentException.startHour, 'HH:mm').diff(baseDateTime);
          }
          if (currentException.endHour != null &&
              typeof currentException.endHour === 'string') {
            currentException.endHour = moment.utc(currentException.endHour, 'HH:mm').diff(baseDateTime);
          }
        }

        await calendarService.create(toAddExceptions, staffUuid)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            this.alertError = true;
            this.alertMsg = errorMsg;
          } else { //
            //Fill the uuId to exception and add the newly created exception to calendar object
            const list = response.data[response.data.jobCase];
            for (let i = 0, len = list.length; i < len; i++) {
              const curItem = list[i];
              if (curItem && curItem.uuId != null) {
                const curException =  toAddExceptions[i];
                curException.uuId = curItem.uuId;
                curException.startHour = baseDateTime.clone().add(curException.startHour, 'millisecond').format('HH:mm')
                curException.endHour = baseDateTime.clone().add(curException.endHour, 'millisecond').format('HH:mm')
                if (calendar[curException.type] == null) {
                  calendar[curException.type] = [];
                }
                calendar[curException.type].push(cloneDeep(curException));
                this.processAdd({
                  uuId: curItem.uuId,
                  type: 'vacation',
                  name: curException.name,
                  startDate: curException.startDate,
                  startHour: curException.startHour,
                  closeDate: curException.endDate,
                  closeHour: curException.endHour,
                  staffUuid: staffUuid,
                  calendar_type: 'Working'
                });
              } else {
                hasError = true;
                this.alertError = true;
                this.alertMsg = errorMsg;
                break;
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
          this.alertError = true;
          this.alertMsg = errorMsg;
        });
      }

      if (!hasError) {
        this.initDurationCalculation({ useExistingValues: true, skipOutOfProjectDateCheck })
      }
    },
    async durationCalculationOk({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay }) {
      staffDurationCalculationOk(this, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay);
    },
    durationCalculationCancel() {
      //Reset to previous value
      if (this.newEvent) {
        this.deleteEventId = this.newEvent.id;
      }
      
      for (const evt of this.updateEvent) {
        evt.origStartDate
        const utcStart = Date.UTC(evt.origStartDate.getFullYear(), evt.origStartDate.getMonth(),
                  evt.origStartDate.getDate(), evt.origStartDate.getHours(),
                  evt.origStartDate.getMinutes(), evt.origStartDate.getSeconds());
        const utcEnd = Date.UTC(evt.origCloseDate.getFullYear(), evt.origCloseDate.getMonth(),
                  evt.origCloseDate.getDate(), evt.origCloseDate.getHours(),
                  evt.origCloseDate.getMinutes(), evt.origCloseDate.getSeconds());
        evt.startDate = moment(utcStart).utc().format('YYYY-MM-DD');
        evt.startTime = moment(utcStart).utc().format('HH:mm');
        evt.closeDate = moment(utcEnd).utc().format('YYYY-MM-DD');
        evt.closeTime = moment(utcEnd).utc().format('HH:mm');
        evt.value = evt.origDuration;
        this.processUpdate(evt);
      }
      
      // clear out the events
      this.updateEvent.splice(0, this.updateEvent.length);
      // this.inProgressShow = false;
      this.assignData(); // revert the change
    },
    async initDurationCalculation({ trigger=TRIGGERS.DURATION, useExistingValues=false, oldDateStr=null, oldTimeStr=null
                                    , oldConstraintType=null, oldConstraintDateStr=null, skipOutOfProjectDateCheck=null, calendar=null } = {}) {
      if (useExistingValues !== true) {
        this.durationCalculation.trigger = trigger;
        this.durationCalculation.startDateStr = this.creatingEvent ? this.newEvent.startDate : this.updateEvent[0].startDate;
        this.durationCalculation.startTimeStr = this.creatingEvent ? this.newEvent.startTime : this.updateEvent[0].startTime;
        this.durationCalculation.closeDateStr = this.creatingEvent ? this.newEvent.closeDate : this.updateEvent[0].closeDate;
        this.durationCalculation.closeTimeStr = this.creatingEvent ? this.newEvent.closeTime : this.updateEvent[0].closeTime;
        const durationDisplay = this.creatingEvent ? null : convertDurationToDisplay(this.updateEvent[0].duration);
        this.durationCalculation.durationDisplay = durationDisplay && this.updateEvent[0].mode === 'move' ? durationDisplay : null;
        this.durationCalculation.constraintType = null; //"As_soon_as_possible";
        this.durationCalculation.oldDateStr = this.durationCalculation.startDateStr;
        this.durationCalculation.oldTimeStr = this.durationCalculation.startTimeStr;
        this.durationCalculation.resizeMode = this.creatingEvent || this.updateEvent[0].mode != 'move';
        const staffUuid = this.creatingEvent ? this.newEvent.staffUuid : this.updateEvent[0].staffUuid;
        // get the staff calendar
        const self = this;
        let _calendar = calendar;
        if (_calendar == null && staffUuid != null) {
          calendar = await staffService.calendar(staffUuid)
          .then((response) => {
            // combine the calendar lists into single lists for each day
            const data = response.data[response.data.jobCase];
            return transformCalendar(processCalendar(data));
          })
          .catch((e) => {
            self.httpAjaxError(e);
            return null;
          });
        }
        
        if (_calendar == null) {
          _calendar = cloneDeep(DEFAULT_CALENDAR);
        }
        this.creatingEvent ? this.newEvent.calendar = _calendar : this.updateEvent[0].calendar = _calendar;
        this.durationCalculation.calendar = _calendar;
        this.durationCalculation.oldDateStr = oldDateStr;
        this.durationCalculation.oldTimeStr = oldTimeStr;
        this.durationCalculation.oldConstraintType = oldConstraintType;
        this.durationCalculation.oldConstraintDateStr = oldConstraintDateStr;
      }
      //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
      if (skipOutOfProjectDateCheck != null) {
        this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
      }
      this.durationCalculationShow = true; //start the calculation.
    },
    showVacationOk() {
      this.onShowChange('vacation')
    },
    showBookingOk() {
      this.onShowChange('booking')
    },
    showTaskOk() {
      this.onShowChange('task')
    },
    showActivityOk() {
      this.onShowChange('activity')
    },
    scheduleChangeOk() {
      this.updateTask(false);
    },
    groupingAlertOk() {
      this.showGroupingAlert = false;
    },
    scheduleChangeCancel() {
      this.updateTask(true);
    },
    async updateTask(scheduleMode) {
      const self = this;
      let update = false;
      const eventId = typeof self.updateEvent[0].id === 'string' ? self.updateEvent[0].id.slice(self.updateEvent[0].id.length - 36) : self.updateEvent[0].id;
      await taskService.update([{
                             uuId: eventId, 
                             duration: self.updateEvent[0].duration,
                             autoScheduling: scheduleMode,
                             startTime: convertDate(self.updateEvent[0].startDate, self.updateEvent[0].startTime), 
                             closeTime: convertDate(self.updateEvent[0].closeDate, self.updateEvent[0].closeTime)
                           }])
      .then(() => {
        update = true;
      });
        
      const taskStaff = self.updateEvent[0].origStaffUuid;
      const newStaff = self.updateEvent[0].staffUuid;
      if (newStaff !== taskStaff) {
        await taskLinkStaffService.remove(eventId, [{ uuId: taskStaff }]);
        await taskLinkStaffService.create(eventId, [{ uuId: newStaff, utilization: 1 }]);
      }
      
      this.updateEvent.splice(0, 1);
      if (this.updateEvent.length === 0) {
        if (update) {
          this.updateGrid(true);
        }
      }
      else {
        this.initDurationCalculation({ trigger: this.updateEvent[0].trigger });
      }
    },
    dateChanged() {
      this.highlightRefresh = true;
      this.dates = null;
    },
    onCellClicked(list) {
      if (!this.canView('TASK') ||
          !list) {
        return;
      }
      
      if (typeof list !== 'undefined') {
        var tasks = [];
        if (typeof list !== 'undefined') {
          for (var i = 0; i < list.length; i++) {
            if (!this.staffUsageProject ||
                (this.staffUsageProject &&
                list[i].project === this.id)) {
              const exists = tasks.filter(task => task.uuId === list[i].uuId);
              if (exists.length === 0) {
                tasks.push(cloneDeep(list[i]));
              }
              else {
                exists[0].w += list[i].w;
              }
            }
          }
        }
        
        if (tasks.length === 1) {
          const projectUuid = typeof list[0].project !== 'undefined' ? tasks[0].project : this.id;
          if (tasks[0].type === 'task') {
            this.taskOpen(tasks[0].uuId, projectUuid, !this.canEdit('TASK'));
          }
          else if (tasks[0].type === 'activity') {
            this.activityOpen(tasks[0].uuId);
          }
          else if (tasks[0].type === 'booking') {
            this.bookingOpen(tasks[0].uuId);
          }
        }
        else if (tasks.length !== 0) {
          // prompt
          this.taskSelectData = [];
          this.taskEdit.uuId = null;
          this.taskEdit.projectUuid = null;
          for (var ii = 0; ii < tasks.length; ii++) {
            const exists = this.taskSelectData.filter(option => option.uuId === tasks[ii].tu);
            if (exists.length === 0) {
              const projectName = this.staffUsageProject && this.project !== null ? this.project.name : tasks[ii].pn;
              const projectUuid = this.staffUsageProject && this.project !== null ? this.project.uuId : tasks[ii].project;
              const name = tasks[ii].td ? list[ii].td : `${tasks[ii].name}`
              this.taskSelectData.push({ name: name, 
                                         type: tasks[ii].type,
                                         uuId: tasks[ii].uuId, 
                                         projectName: projectName,
                                         projectUuid: projectUuid, 
                                         progress: tasks[ii].tp,
                                         estimatedDuration: tasks[ii].type === 'task' ? tasks[ii].te : tasks[ii].duration,
                                         path: tasks[ii].path ? `${tasks[ii].path.replace(/\n/g, ' / ')}` : tasks[ii].name
                                      });
            }
          }
          
          if (this.taskOptions.length === 1) {
            const projectUuid = this.staffUsageProject && this.project !== null ? this.project.uuId : this.taskOptions[0].value.pu;
            this.taskOpen(this.taskOptions[0].value.uuId, projectUuid);
          }
          else {
            this.taskOptions.sort(function( a, b ) {
              if ( a.text.toLowerCase() < b.text.toLowerCase() ){
                return -1;
              }
              if ( a.text.toLowerCase() > b.text.toLowerCase() ){
                return 1;
              }
              return 0;
            });
            this.taskSelectShow = true;
          }
        }
      }
    },
    showUnallocated() {
      if (this.show.unallocated) {
        this.show.unallocated = false;
      }
      else {
        if (this.unallocStart === null) {
          this.unallocStart = this.startDate;
        }
        if (this.unallocEnd === null) {
          this.unallocEnd = this.endDate;
        }
        this.promptUnallocated = true;
      }
    },
    unallocatedOk() {
      this.promptUnallocated = false;
      this.show.unallocated = true;
    },
    showSettings() {
      this.settingsShow = true;
    },
    plannerSettingsSuccess(settings) {
      let update = false;
      if (settings.alertBookings !== this.show.alertBookings) {
        this.onShowChange('alertBookings', false);
        update = true;
      }
      if (settings.alertActivities !== this.show.alertActivities) {
        this.onShowChange('alertActivities', false);
        update = true;
      }
      if (settings.alertTasks !== this.show.alertTasks) {
        this.onShowChange('alertTasks', false);
        update = true;
      }
      if (settings.alertNonWork !== this.show.alertNonWork) {
        this.onShowChange('alertNonWork', false);
        update = true;
      }
      if (settings.work_hours !== null &&
          (this.show.work_hours === null || 
          (this.show.work_hours && (this.show.work_hours.first_hour !== settings.work_hours.first_hour ||
          this.show.work_hours.last_hour !== settings.work_hours.last_hour)))) {
        this.$set(this.show, 'work_hours', settings.work_hours);
        this.layoutProfile[this.getProfileEntryName('show')] = this.show;
        
        update = true;
      }
      else if (settings.work_hours === null &&
               this.show.work_hours !== null) {
        this.$set(this.show, 'work_hours', settings.work_hours);
        this.layoutProfile[this.getProfileEntryName('show')] = this.show;
        update = true;
      }
      
      if (settings.hideWeekends !== this.show.hideWeekends) {
        this.onShowChange('hideWeekends', false);
        update = true;
      }
      
      if (settings.hideNonWorking !== this.show.hideNonWorking) {
        this.onShowChange('hideNonWorking', false);
        
        if (settings.work_hours.first_hour &&
            settings.work_hours.last_hour) {
          for (const key of Object.keys(vacations)) {
            const event = vacations[key].event;
            if (event) {
              if (settings.hideNonWorking) {
                event.start_date.setHours(settings.work_hours.first_hour);
                event.end_date.setHours(settings.work_hours.last_hour);
                event.end_date.setMinutes(0);
              }
              else {
                event.start_date.setHours(0);
                event.end_date.setHours(23);
                event.end_date.setMinutes(59);
              }
            }
          }
        }
        update = true;
      }
      
      if (settings.alertTaskBookings !== this.show.alertTaskBookings) {
        this.onShowChange('alertTaskBookings', false);
        update = true;
      }
      
      if (settings.fullBarColor !== this.show.fullBarColor) {
        this.onShowChange('fullBarColor', false);
        update = true;
      }
      
      if (settings.usageBookings !== this.show.usageBookings) {
        this.onShowChange('usageBookings', false);
        update = true;
      }
      
      if (settings.usageActivities !== this.show.usageActivities) {
        this.onShowChange('usageActivities', false);
        update = true;
      }
      
      if (settings.usageTasks !== this.show.usageTasks) {
        this.onShowChange('usageTasks', false);
        update = true;
      }
      
      if (settings.required !== this.show.required) {
        this.onShowChange('required', false);
        update = true;
      }
      else if (settings.available !== this.show.available) {
        this.onShowChange('available', false);
        update = true;
      }
      
      if (settings.displayFilteredMetrics !== this.show.displayFilteredMetrics) {
        this.onShowChange('displayFilteredMetrics', false);
        update = true;
      }
      
      if (update) {
        this.updateLayoutProfile(); // save the settings
        // update the data displayed
        this.inProgressShow = true;
        this.inProgressLabel = this.$t('staff.progress.filtering');
        setTimeout(() => {
          this.assignData();
        }, 500);
      }
    },
    onRedrawEventComplete() {
      this.redrawEventId = null;
    },
    addStaffEvent(event) {
      if (event.staffUuid in staffEvents) {
        staffEvents[event.staffUuid].push(event);
      }
      else {
        staffEvents[event.staffUuid] = [event];
      }
    },
    onFilterClear() {
      this.filterText = '';
      this.changeFilter(this.filterText);
    },
    actualCostAlert(event) {
      if(event.actualCost != null) {
        const fixedCost = event.fixedCost > 0? event.fixedCost : 0;
        if(event.actualCost > fixedCost && fixedCost !== 0) {
          return true;
        } else if(event.estimatedCost && event.actualCost > event.estimatedCost) {
          return true;
        }
      }
      return false;
    },
    actualCostAlertMsg(event) {
      if(event.actualCost) {
        const fixedCost = event.fixedCost ? event.fixedCost : 0;
        const isCurrencyCodeValid = event.currencyCode && event.currencyCode.trim().length > 0;
        if(event.actualCost > fixedCost && fixedCost !== 0) {
          const diff = isCurrencyCodeValid? costFormatAdv(event.actualCost - fixedCost, event.currencyCode) : `$${costFormat(event.actualCost - fixedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_fixed_cost', [diff]);
        } else if(event.estimatedCost && event.actualCost > event.estimatedCost) {
          const diff = isCurrencyCodeValid? costFormatAdv(event.actualCost - event.estimatedCost, event.currencyCode) :  `$${costFormat(event.actualCost - event.estimatedCost)}`;
          return this.$t('task.alert.actual_cost_exceeds_estimated_cost', [diff]);
        }
      }
      return '';
    },
    fixedDurationAlertMsg(event) {
      if(event.fixedDuration != null) {
        const duration = typeof event.te !== 'undefined' ? event.te : event.duration;
        if(event.actualDuration > event.fixedDuration) {
          const diff = convertDurationToDisplay((event.actualDuration - event.fixedDuration) / 60000, 'D');
          return this.$t('task.alert.actual_duration_exceeds_fixed_duration', [diff]);
        } else if(duration > event.fixedDuration) {
          const diff = convertDurationToDisplay((duration - event.fixedDuration) / 60000, 'D');
          return this.$t('task.alert.estimated_duration_exceeds_fixed_duration', [diff]);
        }
      }
      return '';
    },
    fixedDurationAlert(event) {
      if (event.fixedDuration && !['0','0Y','0M','0W','0D','0h','0m'].includes(event.fixedDuration)) {
        const duration = typeof event.te !== 'undefined' ? event.te : event.duration;
        return event.fixedDuration < duration || event.fixedDuration < event.actualDuration;
      }
      return false;
    },
    onTooltip(event) {
      const uuId = event.id.slice(event.id.length - 36);
      if (!(uuId in lookups)) {
        lookups[uuId] = true;
        if (event.type === 'booking') {
          bookingService.get([{ uuId: uuId}], ['NOTE']).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              const noteList = data[0].noteList;
              if (bookings[uuId]) {
                bookings[uuId].noteList = noteList;
              }
              const booking = this.schedulerTasks.find(e => e.id === event.id);
              if (booking) {
                booking.noteList = noteList;
                if (noteList && noteList.length > 0) {
                  const elem = document.querySelector('.dhtmlxNote');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxNote');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                      }
                    }, 500);
                  }
                }
                
                booking.estimatedCost = data[0].estimatedCost;
                booking.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof booking.estimatedCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxEstimatedCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(booking.estimatedCost, booking.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxEstimatedCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(booking.estimatedCost, booking.currency)}`;
                      }
                    }, 500);
                  }
                }
                
                booking.actualCost = data[0].actualCost;
                booking.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof booking.actualCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxActualCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(booking.actualCost, booking.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxActualCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(booking.actualCost, booking.currency)}`;
                      }
                    }, 500);
                  }
                }
              }
            }
          })
          .catch(e => {
            this.httpAjaxError(e);
          }); 
        }
        else if (event.type === 'task') {
          taskService.get([{ uuId: uuId}], ['NOTE']).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              const noteList = data[0].noteList;
              if (tasks[uuId]) {
                tasks[uuId].noteList = noteList;
              }
              const task = this.schedulerTasks.find(e => e.id === event.id);
              if (task) {
                task.noteList = noteList;
                if (noteList && noteList.length > 0) {
                  const elem = document.querySelector('.dhtmlxNote');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxNote');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                      }
                    }, 500);
                  }
                }
                
                task.estimatedCost = data[0].estimatedCost;
                task.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof task.estimatedCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxEstimatedCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(task.estimatedCost, task.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxEstimatedCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(task.estimatedCost, task.currency)}`;
                      }
                    }, 500);
                  }
                }
                
                task.actualCost = data[0].actualCost;
                task.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof task.actualCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxActualCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(task.actualCost, task.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxActualCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(task.actualCost, task.currency)}`;
                      }
                    }, 500);
                  }
                }
                
                if (this.fixedDurationAlert(data[0])) {
                  const elem = document.querySelector('.dhtmlxFixedDurationWarn');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = self.fixedDurationAlertMsg(data[0]);
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxFixedDurationWarn');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = self.fixedDurationAlertMsg(data[0]);
                      }
                    }, 500);
                  }
                }
                
                if (this.actualCostAlert(data[0])) {
                  const elem = document.querySelector('.dhtmlxActualCostWarn');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = self.actualCostAlertMsg(data[0]);
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxFixedDurationWarn');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = self.actualCostAlertMsg(data[0]);
                      }
                    }, 500);
                  }
                }
              }
            }
          })
          .catch(e => {
            this.httpAjaxError(e);
          }); 
        }
        else if (event.type === 'activity') {
          activityService.get([{ uuId: uuId}], ['NOTE']).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              const noteList = data[0].noteList;
              if (activities[uuId]) {
                activities[uuId].noteList = noteList;
              }
              const activity = this.schedulerTasks.find(e => e.id === event.id);
              if (activity) {
                activity.noteList = noteList;
                if (noteList && noteList.length > 0) {
                  const elem = document.querySelector('.dhtmlxNote');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxNote');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('note')}: ${noteList[noteList.length - 1].text}`;
                      }
                    }, 500);
                  }
                }
                
                activity.estimatedCost = data[0].estimatedCost;
                activity.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof activity.estimatedCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxEstimatedCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(activity.estimatedCost, activity.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxEstimatedCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.estimatedCost')}: ${costFormatAdv(activity.estimatedCost, activity.currency)}`;
                      }
                    }, 500);
                  }
                }
                
                activity.actualCost = data[0].actualCost;
                activity.currency = data[0].currencyCode ? data[0].currencyCode : 'USD';
                if (typeof activity.actualCost !== 'undefined') {
                  const elem = document.querySelector('.dhtmlxActualCost');
                  if (elem && elem.dataset.id === uuId) {
                    elem.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(activity.actualCost, activity.currency)}`;
                  }
                  else {
                    // wait a little for the tooltip to appear
                    setTimeout(() => {
                      const elem2 = document.querySelector('.dhtmlxActualCost');
                      if (elem2 && elem2.dataset.id === uuId) {
                        elem2.innerHTML = `${this.$t('task.field.actualCost')}: ${costFormatAdv(activity.actualCost, activity.currency)}`;
                      }
                    }, 500);
                  }
                }
              }
            }
          })
          .catch(e => {
            this.httpAjaxError(e);
          }); 
        }
      }
    },
    loadColumnSettings(data, columns) {
      if (!columns) {
        return;
      }

      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == data.COLUMN_AGGRID_AUTOCOLUMN);
      if (autoGroupSetting) {
        data.autoGroupColumnDef.width = autoGroupSetting.width;
        data.autoGroupColumnDef.sort = autoGroupSetting.sort;
        if (data.gridOptions.api) {
          data.gridOptions.api.setAutoGroupColumnDef({
            ...data.autoGroupColumnDef
          })
        }
      }

      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
          column.sort = null;
          column.sortIndex = null;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      data.columnDefs.sort(columnDefSortFunc);
      if (data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }

      //Fix the missing sort direction icon in cell header
      setTimeout(() => {
        data.gridOptions.api.refreshHeader();
      }, 100);
      return false;
    },
    touchStartHandler(e) {
      if('resizer-overlay' == e.target.id) {
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.add('pressed');
        this.$refs['resizer-overlay'].addEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].addEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].addEventListener('touchend', this.touchEndHandler);
        
        this.touchEvent.isTouchStart = true;
        this.touchEvent.x = e.touches[e.touches.length - 1].clientX;  
        this.touchEvent.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;
      }
    }, 
    touchMoveHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        const dx = e.touches[e.touches.length - 1].clientX - this.touchEvent.x;
        const resizer = this.$refs['resizer'];
        const leftSide = this.$refs['lhs-grid'];
        const rightSide = this.$refs['rhs-chart'];
        
        const newLeftWidth = (this.touchEvent.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;
        rightSide.style.width = `${100 - newLeftWidth}%`;
        this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;
        this.toggleSplitterResizeStyle(false);
      }
    }, 
    toggleUserSelect(element, disableSelect) {
      if(disableSelect) {
        element.style.userSelect = 'none';
        element.style.pointerEvents = 'none';
      } else {
        element.style.removeProperty('user-select');
        element.style.removeProperty('pointer-events');
      }
    }, 
    toggleSplitterResizeStyle(state) {
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];
      if(state) {
        this.toggleUserSelect(leftSide, true);
        this.toggleUserSelect(rightSide, true);
      } else {
        this.toggleUserSelect(leftSide, false);
        this.toggleUserSelect(rightSide, false);
      }
      
      // save the updated splitter position in the settings
      this.layoutProfile[this.getProfileEntryName('projectGanttLHSGrid')] = { width: this.$refs['lhs-grid'].style.width };
      this.layoutProfile[this.getProfileEntryName('projectGanttRHSChart')] = { width: this.$refs['rhs-chart'].style.width };
      if (this.updateLayoutProfileTimeout) {
        clearTimeout(this.updateLayoutProfileTimeout);
      }
      this.updateLayoutProfileTimeout = setTimeout(this.updateLayoutProfile, 1000);
    }, 
    touchEndHandler(e) {
      if(this.touchEvent.isTouchStart) {
        e.preventDefault();
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    }, 
    touchCancelHandler(/*e*/) {
      if(this.touchEvent.isTouchStart) {
        this.$refs['resizer-overlay'].classList.remove('pressed');
        this.touchEvent.isTouchStart = false;
        this.$refs['resizer-overlay'].removeEventListener('touchmove', this.debouncedTouchMoveHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchcancel', this.touchCancelHandler);
        this.$refs['resizer-overlay'].removeEventListener('touchend', this.touchEndHandler);
        this.toggleSplitterResizeStyle(false);
      }
    }, 
    mouseDownHandler(e) {
      e.preventDefault();
      this.splitterEventState.isMouseDown = true;
      // Get the current mouse position
      this.splitterEventState.x = e.clientX;
      this.splitterEventState.y = e.clientY;
      this.splitterEventState.leftWidth = this.$refs['lhs-grid'].getBoundingClientRect().width;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
    }, 
    mouseMoveHandler(e) {
      e.preventDefault();
      // How far the mouse has been moved
      const dx = e.clientX - this.splitterEventState.x;
      const resizer = this.$refs['resizer'];
      const leftSide = this.$refs['lhs-grid'];
      const rightSide = this.$refs['rhs-chart'];

      const newLeftWidth = (this.splitterEventState.leftWidth + dx + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
      leftSide.style.width = `${newLeftWidth}%`;
      rightSide.style.width = `${100 - newLeftWidth}%`;
      this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;

      if (this.schedulerRepaintTimeoutId != null) {
        clearTimeout(this.schedulerRepaintTimeoutId);
      }
      this.schedulerRepaintTimeoutId = setTimeout(() => {
        this.scheduler.updateView();
      }, 500)

      this.toggleSplitterResizeStyle(true);
    }, 
    mouseUpHandler(e) {
      e.preventDefault();
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
      this.splitterEventState.isMouseDown = false;
      this.toggleSplitterResizeStyle(false);
    },
    chartResizeHandler: debounce(function(/** e */) {
      const windowHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;
      const windowWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      const aGContainerElem = this.$refs['aggrid-scheduler-container'];
      const offsetTop = aGContainerElem != null? aGContainerElem.offsetTop : 0;
      const _heightOffset = -7;
      let availablePAGContainerHeight = windowHeight - offsetTop - 35 - _heightOffset;
      if (aGContainerElem != null) {
        aGContainerElem.style.height = `${availablePAGContainerHeight}px`;
      }
      //When screen width less than 601, the schedulertoolbar height will change from 2 rows to 3 rows.
      //So subtract 115 instead of 87.
      let height = availablePAGContainerHeight - (windowWidth < 601 ? 115 : 87);
      //Minimum height: 150
      if (height < 150) {
        height = 150;
      }
      this.$set(this.splitterStyle, 'height', `${height}px`);
      this.$set(this.lhsGridStyle, 'height', `${height}px`);
      this.schedulerHeight = height;
      if (this.$refs['resizer'] != null && this.$refs['lhs-grid'] != null) {
        const resizer = this.$refs['resizer'];
        const newLeftWidth = (this.$refs['lhs-grid'].getBoundingClientRect().width + this.resizerWidth) * 100 / resizer.parentNode.getBoundingClientRect().width;
        this.schedulerWidth = `${parseInt(resizer.parentNode.getBoundingClientRect().width * (100 - newLeftWidth) / 100) - 2}px`;
      }
    }, 100),
    ganttScrollHandler: debounce(function({ left, top }) {
      this.scrollState.left = left; //Used in keeping previous x position after gantt data refresh
      if( this.scrollState.top != top && !this.scrollState.triggeredByLHS) {
        this.scrollState.triggeredByLHS = false;
        this.scrollState.triggeredByRHS = true;
        this.scrollState.top = top;
        if (this.gridApi && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
          this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(top);          
        }
      } else {
        this.scrollState.triggeredByLHS = false;
        this.scrollState.triggeredByRHS = false;
      }
    }, 5),
    setActualRowHeights() {
      this.gridOptions.api.forEachNode(node => {
        let rowHeight = this.gridOptions.getRowHeight({ node });
        node.setRowHeight(rowHeight);
      });
      this.gridOptions.api.onRowHeightChanged();
    },
    onCollapsedId() {
      this.collapseId = null;
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.resetRowHeights();
      }
    },
    onExpandedId() {
      this.expandId = null;
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.resetRowHeights();
      }
    },
    onSelectionChanged(selected) {
      this.selected = selected;
    },
    getRowHeight(params) {
      if (this.scheduler != null) {
        try {
          /* eslint-disable no-undef */
          let stats = this.scheduler._timeline_get_cur_row_stats(this.scheduler.getView(), params.node.rowIndex);
          stats = this.scheduler._timeline_get_fit_events_stats(this.scheduler.getView(), params.node.rowIndex, stats);
          /* eslint-enable no-undef */

          return stats.height;
        } catch(e) {
          //Error (usually) occurred when the specific row is not rendered in RHS timeline due to collapsed state.
          //Use fallback value: null
          //A resetRowHeight() will be called later to get actual row height in onExpandedId().
        }
      }
      return null
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('staff.grid.no_data');
    }
    , schedulerCreated(instance) {
      this.scheduler = instance.schedulerInstance;
      this.schedulerWrapper = instance;
    }
    , schedulerToolbarCreated(instance) {
      this.schedulerToolbarWrapper = instance;
    }
    , markedSpansCreated(instance) {
      this.markedSpans = instance;
    }
    , schedulerDataRender: debounce(function() {
      if (this.gridOptions && this.gridOptions.api) {
        
        if (this.schedulerDataRenderBySort == true) {
          this.schedulerDataRenderBySort = false;
          this.gridOptions.api.resetRowHeights();
        }
        
        if (this.needRefreshClientRowModel == true) {
          this.needRefreshClientRowModel = false;
          //setTimeout() is needed to let refreshClientSideRowModel() takes effect.
          setTimeout(() => {
            this.gridOptions.api.refreshClientSideRowModel('sort');
          }, 100);
        }

        if (this.needSetActualRowHeights == true) {
          this.needSetActualRowHeights = false;
          this.setActualRowHeights();
        }

        if (this.needScroll == true) {
          this.needScroll = false;
          if (this.scheduler != null) {
            this.scrollState.triggeredByLHS = true;
            this.scrollState.triggeredByRHS = false;
            this.scheduler.getView().scrollTo({ top: this.scrollState.top, left: this.scrollState.left })
          }
        }
        
      } else {
        this.schedulerDataRenderReady = true;
      }
      
      //holdUpdateUntilTreeDataChanged here is used as an indicator that rendering process is complete when it is false.
      if (!this.holdUpdateUntilTreeDataChanged) {
        setTimeout(() => {
          this.loading = false;
          this.inProgressShow = false;
        }, 0)
      }
      
    }, 300)
    , updateSchedulerDataAfterSortedAndFiltered(api) {
      const rowList = {}; //Keep row with children only. Allow easy access to the parent row for adding child row.
      const rData = [];
      
      api.forEachNodeAfterFilterAndSort((rowNode) => {
        if (rowNode.data) {
          const d = JSON.parse(JSON.stringify(rowNode.data));
          //Remove non-data related properties
          delete d.$parent;
          delete d.__obj__;
          delete d.level;
          
          //Clear children items. Will be re-filled with latest child data with right order
          if (Array.isArray(d.children)) {
            d.children = []; //clear it and the child rows will be added in proper sort order.
            rowList[d.path.join()] = d;
          }
          if (d.parentId == null || d.parentId == 'ROOT') {
            rData.push(d); //Keep the top level rows only
          } else {
            const parent = rowList[d.path.slice(0, d.path.length-1).join()];
            if (parent != null) {
              parent.children.push(d);
            } else {
              //Didn't expect this flow. So add it as top level row as fallback.
              rData.push(d);
            }
          }
        }
      });
      
      //Update RHS timeline and event
      this.schedulerDataRenderBySort = true;
      this.schedulerTasks = cloneDeep(this.schedulerTasks);
      this.schedulerWrapper.setTreeData(rData);
      this.schedulerToolbarWrapper.setTreeData(rData);
      this.showNoRowsOverlay = rData.length === 0;
    }
    , redrawViewport(params, firstRow, lastRow) {
      const ids = [];
      const genericIds = [];
      const rowUuIdKeys = {} //Keep the uuId, [key] pair
      const nodes = [];
      const fields = [
        'companies', 'locations', 'position'
        , 'staffType', 'startDate', 'endDate'
        , 'departments', 'skills', 'firstName'
        , 'lastName', 'payAmount', 'payCurrency'
        , 'payFrequency', 'resources', 'tag'
        , 'identifier'
      ];
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        fields.push(...this.customFields.map(i => i.name));
      }
      for (let idx = firstRow; idx <= lastRow; idx++) {
        const row = params.api.getDisplayedRowAtIndex(idx);
        if (row && row.data && row.data.loaded != true && row.data.type == 'staff') {
          if (row.data.uuId in this.staffDataLoaded) {
            const cached = this.staffDataLoaded[row.data.uuId];
            for (const f of fields) {
              row.data.loaded = true;
              row.data[f] = cached[f];
            }
            nodes.push(row);
          } else {
            if (Array.isArray(rowUuIdKeys[row.data.uuId])) {
              rowUuIdKeys[row.data.uuId].push(row.data.key);
            } else {
              rowUuIdKeys[row.data.uuId] = [row.data.key];
            }
            if (row.data.generic == true) {
              genericIds.push(row.data.uuId);
            } else {
              ids.push(row.data.uuId);
            }
          }
        }
      }
      
      // save the fields for exporting
      this.fields = fields;
      
      const requests = [];
      if (ids.length > 0) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: JSON.parse(JSON.stringify(fields))
        }, false, this.customFields));
      }
      if (genericIds.length > 0) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: genericIds
          , fields: JSON.parse(JSON.stringify(fields))
        }, true, this.customFields));
      }

      if (requests.length > 0) {
        Promise.allSettled(requests)
        .then(responses => {
          const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
          for (const r of filteredResponseList) {
            const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
            for (const row of data) {
              const relatedRowKeys = rowUuIdKeys[row.uuId];
              if (!Array.isArray(relatedRowKeys) || relatedRowKeys.length == 0) {
                continue;
              }
              for (const key of relatedRowKeys) {
                const rowNode = params.api.getRowNode(key);
                if (rowNode) {
                  if (!(row.uuId in this.staffDataLoaded)) {
                    this.staffDataLoaded[row.uuId] = row;
                  }
                  for (const f of fields) {
                    rowNode.data.loaded = true;
                    rowNode.data[f] = row[f];
                  }
                  this.getTotalUsage(rowNode.data);
                  nodes.push(rowNode);
                }
              }
            }
          }
          params.api.redrawRows({rowNodes: nodes});
        });
      } else if (nodes.length > 0) {
        params.api.redrawRows({rowNodes: nodes});
      }
    }
    , async fetchDataForSortedColumn(staffData, fields=[]) {
      //1. real staff and generic staff can't be mixed in one query call. Workaround: make separate calls for them.
      //2. make batch call to avoid reaching the number limit of holder allowed (staff id) in one query call.

      if (!Array.isArray(fields) || fields.length == 0) {
        return;
      }

      const staffIds = staffData.filter(i => i.generic != true).map(i => i.uuId);
      const genericStaffIds = staffData.filter(i => i.generic == true).map(i => i.uuId);
      const batchStaffIds = [];
      const batchGenericStaffIds = [];
      const requests = [];

      do {
        batchStaffIds.push(staffIds.splice(0, staffIds.length > 250? 250 : staffIds.length));
      } while(staffIds.length > 250);
      if (staffIds.length > 0) {
        batchStaffIds.push(staffIds);
      }
      
      do {
        batchGenericStaffIds.push(genericStaffIds.splice(0, genericStaffIds.length > 250? 250 : genericStaffIds.length));
      } while(genericStaffIds.length > 250);
      if (genericStaffIds.length > 0) {
        batchGenericStaffIds.push(genericStaffIds);
      }
      
      for (const ids of batchStaffIds) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', ...fields]
        }, false, this.customFields));
      }

      for (const ids of batchGenericStaffIds) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', ...fields]
        }, true, this.customFields));
      }

      return Promise.allSettled(requests)
      .then(responses => {
        const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
        for (const r of filteredResponseList) {
          const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
          for (const row of data) {
            const found = staffData.find(i => i.uuId == row.uuId);
            if (found) {
              for (const f of fields) {
                found[f] = row[f];
              }
            }
          }
        }
      });
    }
    , async fetchRowDataForSortedColumn(api, field) {
      //1. real staff and generic staff can't be mixed in one query call. Workaround: make separate calls for them.
      //2. make batch call to avoid reaching the number limit of holder allowed (staff id) in one query call.

      let staffIds = new Set();
      let genericStaffIds = new Set();
      const rowUuIdKeys = {};
      api.forEachNode((rowNode) => {
        if (rowNode.data?.type == 'staff') {
          if (rowNode.data.generic == true) {
            genericStaffIds.add(rowNode.data.uuId);
          } else {
            staffIds.add(rowNode.data.uuId);
          }
          
          if (Array.isArray(rowUuIdKeys[rowNode.data.uuId])) {
            rowUuIdKeys[rowNode.data.uuId].push(rowNode.data.key);
          } else {
            rowUuIdKeys[rowNode.data.uuId] = [rowNode.data.key];
          }
        }
      });
      staffIds = Array.from(staffIds);
      genericStaffIds = Array.from(genericStaffIds);
      const batchStaffIds = [];
      const batchGenericStaffIds = [];
      const requests = [];
     
      do {
        batchStaffIds.push(staffIds.splice(0, staffIds.length > 250? 250 : staffIds.length));
      } while(staffIds.length > 250);
      if (staffIds.length > 0) {
        batchStaffIds.push(staffIds);
      }

      do {
        batchGenericStaffIds.push(genericStaffIds.splice(0, genericStaffIds.length > 250? 250 : genericStaffIds.length));
      } while(genericStaffIds.length > 250);
      if (genericStaffIds.length > 0) {
        batchGenericStaffIds.push(genericStaffIds);
      }
      
      for (const ids of batchStaffIds) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', field]
        }, false, this.customFields));
      }

      for (const ids of batchGenericStaffIds) {
        requests.push(staffService.listv2({ 
          start: 0
          , limit: -1
          , holders: ids
          , fields: ['uuId', field]
        }, true, this.customFields));
      }

      return Promise.allSettled(requests)
      .then(responses => {
        const filteredResponseList = responses.filter(i => i.status == 'fulfilled');
        const itemsToUpdate = [];
        for (const r of filteredResponseList) {
          const data = r.value?.data != null && Array.isArray(r.value.data)? r.value.data : [];
          for (const row of data) {
            const relatedRowKeys = rowUuIdKeys[row.uuId];
            if (!Array.isArray(relatedRowKeys) || relatedRowKeys.length == 0) {
              continue;
            }
            for (const key of relatedRowKeys) {
              const rowNode = api.getRowNode(key);
              if (rowNode) {
                rowNode.data[field] = row[field];
                itemsToUpdate.push(rowNode.data);
              }
            }
          }
        }
        api.applyTransaction({ update: itemsToUpdate });
        this.staffFieldDataLoaded.push(field);
        api.refreshClientSideRowModel('sort');
      });
    }
    , async getModelInfo(entity) {
      await this.$store.dispatch('data/info', {type: 'api', object: entity}).then(value => {
        const modelInfo = value[entity].properties
        const rawPayFrequencyOptions = modelInfo.find(f => f.field === 'payFrequency').options
        this.payFrequencyOptions.splice(0, this.payFrequencyOptions.length, ...rawPayFrequencyOptions.map(i => {
          return { value: i, text: this.$t(`payFrequency.${i}`) }
        }))
      })
      .catch(e => {
        this.httpAjaxError(e)
      })

      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.StaffTypeEnum != null) {
              const obj = propertyList.StaffTypeEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.typeOptions.splice(0, this.typeOptions.length, ...list)
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name';
      }

      const srcRowId = rowData.uuId;
      const srcRowData = params.api.getRowNode(srcRowId).data;
      
      if (Array.isArray(this.customFields) && this.customFields.length > 0) {
        const found = this.customFields.find(i => i.name == srcColId && i.type === 'Date');
        if (found) {
          return srcRowData[srcColId] != null && srcRowData[srcColId] != 0 && srcRowData[srcColId] != 32503680000000 && srcRowData[srcColId] != 32400000
            ? moment.utc(srcRowData[srcColId]).format('MM/DD/YYYY') 
            : ''
        }
      }

      if (srcColId == 'startDate' || srcColId == 'endDate') {
        return srcRowData[srcColId] != null && srcRowData[srcColId] != 0 && srcRowData[srcColId] != 32503680000000 && srcRowData[srcColId] != 32400000
          ? moment.utc(srcRowData[srcColId]).format('MM/DD/YYYY') 
          : ''
      } else  if (srcColId == 'payFrequency') {
        const found = this.payFrequencyOptions.find(i => i.value == srcRowData.payFrequency);
        return found? found.text : srcRowData.payFrequency;
      } else if (srcColId == 'payAmount') {
        const num = typeof srcRowData.payAmount == 'number'? srcRowData.payAmount : 0;
        return srcRowData.payCurrency != null
          ?  costFormatAdv(num, srcRowData.payCurrency)
          : costFormat(num)
      } else if (srcColId == 'payCurrency') {
        const found = this.optionCurrency.find(i => i.value == srcRowData.payCurrency);
        return found? found.text : srcRowData.payCurrency;
      } else if (srcColId == 'resources') {
        if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
          return '';
        }
        const value = [];
        for (const i of srcRowData[srcColId]) {
          if (i.name != null) {
            value.push(`${i.name}${i.unit != null? ' ('+i.level+')' : ''}`);
          }
        }
        return value.join(', ');
      } else if (srcColId == 'skills') {
        if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
          return '';
        }
        const value = [];
        for (const i of srcRowData[srcColId]) {
          if (i.name != null) {
            value.push(`${i.name}${i.level != null? ' ('+i.level+')' : ''}`);
          }
        }
        return value.join(', ');
      } else if (srcColId == 'tag') {
        if (srcRowData[srcColId] == null || !Array.isArray(srcRowData[srcColId])) {
          return '';
        }
        return srcRowData[srcColId].join(', ');
      } else if (Array.isArray(srcRowData[srcColId])) {
        let value = [];
        for (const i of srcRowData[srcColId]) {
          if (i.name != null) {
            value.push(i.name);
          }
        }
        return value.join(', ');
      }
      return srcRowData[srcColId];
    }
    , onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      this.badgeFilters = filter;
      this.layoutProfile[this.getProfileEntryName('filter')] = cloneDeep(filter);
      this.updateLayoutProfile();
      
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.filtering');
      setTimeout(() => {
        this.needRefreshClientRowModel = true;
        this.assignData();
      }, 500);
    }
    , onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    }
    , onBadgeFilterEnter() {
      this.$refs.badgeFilter.visible = true;
    }
    , onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    }
    , onBadgeFilterLeave() {
      if (!this.badgeFilterFocus) {
        this.$refs.badgeFilter.visible = false;
      }
    }
    , onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    }
    , onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
    }
    , toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    }
    , handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    }
    , onPriorityNavMouseOverOrTouchEnd(evt) {
      if ((this.$refs.badgeFilter?.$el.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      }
    }
    , async onBadgeFilterFetchOptions(payload) {
      const field = payload.field;
      await this.fetchDataForSortedColumn(staffData, [field]);
      let values = new Set();
      for (const staff of staffData) {
        values.add(staff[field]);
      }

      values = Array.from(values).sort();

      const found = this.badgeFilters.find(i => i.field == field);
      if (found != null && Array.isArray(found.value) && found.value.length > 0) {
        this.$set(this.badgeFilterFieldValues, field, values.map(i => ({ 
          text: !i ? '(Empty)' : i
          , checked: found.value.find(j => j.text != null && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) != null
        })));
      } else {
        this.$set(this.badgeFilterFieldValues, field, values.map(i => ({ text: !i ? '(Empty)' : i, checked: false })));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-toolbar {
  
  ul {
    margin: 0;
  }
}

.staff-action-bar {
  position: relative;
  z-index: 1000;
}

.staff-action-bar, .group-action-bar {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .d-flex {
    align-items: center;
  }
  li > span > label {
    margin: 12px 0;
  }
}

.tool-button {
  background: transparent;
  border: none;
  font-size: 1rem;  
}



.staff-grid-height {
  min-height: 300px;
  margin-bottom: 25px;
}

.mw-150 {
  max-width: 150px;
}

.minw-170 {
  min-width: 170px;
}

.task-list {
  overflow-x: auto;
}

::v-deep .ag-header-cell-text {
    overflow: visible;
    text-overflow: unset;
    white-space: pre;
    text-align: center;
}

.active-check {
  position: absolute;
  right: 10px;
}

.menu-chevron {
  position: absolute;
  right: 10px;
  top: 8px;
}

.group-action-bar span {
  padding: 5px;
}

.group-dropdown {
  margin: 0.5rem 0;
}

.grouping-icon {
  min-width: 18px;
}
</style>
<style>
.staff-action-bar #staffUsageStartDate, .staff-action-bar #staffUsageEndDate {
  font-size: 0.7rem !important;
}
#project-staff-grid .ag-root-wrapper {
  border-top: 0;
}

.search-input {
  padding-top: 2px;
  padding-bottom: 2px;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-type .nav-tabs .nav-link.active,
.event-type .nav-tabs .nav-link.active:focus {
  color: var(--projectal-orange);
  background: var(--bs-tab-active-bg);
  border-color: var(--bs-border);
  border-bottom-color: var(--bs-border);
}

.event-type .nav-tabs {
  border-bottom: 1px solid var(--bs-border);
}

.event-type {
  .nav-pills .nav-link.active {
    color: var(--projectal-orange);
    text-decoration: none;
    background-color: transparent;
  }
}

.event-type .nav-tabs .nav-link:hover, .event-type .nav-tabs .nav-link:focus {
    border-color: var(--bs-border) var(--bs-border) var(--bs-border);
}

.border-part {
  border-top: 1px solid var(--bs-border);
  border-left: 1px solid var(--bs-border);
  border-right: 1px solid var(--bs-border);
}

.start-date-elevation {
  z-index: 10;
  position: relative;
}

svg.active {
  color: #E77615;
}

.settings-dropdown .dropdown-item {
  padding: 4px 20px;
}

.staff-action-bar .menu-toggler .btn-secondary {
  color: var(--grid-toolbar-button);
  background-color: transparent;
  border: none;
}

.staff-action-bar .dropdown.show > .dropdown-toggle {
  background-color: var(--grid-toolbar-dropdown-bg);
  color: var(--grid-toolbar-dropdown);
}

.alloc-dropdown .dropdown-menu {
  overflow: visible;
  max-height: 550px;
}

.search-append {
  line-height: 11px !important;
  font-size: 0.8rem !important;
}

.grid-toolbar .btn.btn-secondary.search-append-bg {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--form-control-addon-bg) !important;
}

.staff-action-bar .grid-toolbar .date-picker .btn-secondary {
  color: #212121;
  background-color: #E0E0E0;
}

.staff-action-bar .date-picker .btn-danger,
.staff-action-bar .date-picker .btn-primary { 
  margin: 8px 3px;
}

.resizer {
  position: relative;
  background-color: var(--border-dark);
  cursor: ew-resize;
  height: 100%;
  width: 5px;
}

.splitter-container {
  width: 100%;
  height: 64px;
  display: flex;
  
  height: calc(100vh - 330px);
}

.lhs-grid {
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.rhs-chart {
  justify-content: center;
  align-items: center;
  width: calc(75% - 5px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid 1px var(--ag-border-color);
}

.pointer-events {
  pointer-events: all !important;
}
.sub-menu-dropdown {
  position: absolute;     
  top: -10px;       
  min-width: 10rem;
  visibility: hidden;
  left: 190px;
  padding: .5rem 0;
  margin: .125rem 0 0;
  color: var(--dropdown);
  text-align: left;
  list-style: none;
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

@media only screen and (max-width: 1366px) {
  .sub-menu-dropdown {
      position: absolute;
      top: 30px;
      min-width: 10rem;
      visibility: hidden;
      left: -40px;
      padding: .5rem 0;
      margin: .125rem 0 0;
      color: var(--dropdown);
      text-align: left;
      list-style: none;
      background-color: var(--dropdown-bg);
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: .25rem;
      z-index: 10;
  }
}
</style>
