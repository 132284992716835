
import { httpAjax, urlParams } from '@/helpers';
import { getPermissionDenyProperties } from '@/helpers/permission'

export const templateProjectService = {
  create,
  list,
  get,
  update,
  remove,
  span,
  getEarliestAndLatestDateOfTasks,
  schedule,
  clone,
  listNames,
  listUniqueValuesOfProperty
};

/**
 * Create a new project 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function create(data) {
  const url = '/api/template/project/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of project info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function list(params, customFields=[]) {
  const fields = {
    uuId: 'PROJECT_TEMPLATE.uuId', 
    name: 'PROJECT_TEMPLATE.name',
    description: 'PROJECT_TEMPLATE.description',
    identifier: 'PROJECT_TEMPLATE.identifier',
    estimatedCost: 'PROJECT_TEMPLATE.estimatedCost',
    estimatedCostNet: 'PROJECT_TEMPLATE.estimatedCostNet',
    estimatedDuration: 'PROJECT_TEMPLATE.estimatedDuration',
    color: 'PROJECT_TEMPLATE.color',
    tag: 'PROJECT_TEMPLATE.TAG.name'
  }
  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = `PROJECT_TEMPLATE.${cField.name}`;
    }
  }
  

  let data = {
    'name'  : 'Project List'
    ,'type' : 'msql'
    ,'nominate': 'PROJECT_TEMPLATE'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  if(params.filter && params.filter.length > 0) {
    const matchColumns = [
      ['PROJECT_TEMPLATE.name', 'has', params.filter]
    ]

    const templateProjectDeniedProperties = getPermissionDenyProperties('TEMPLATE__PROJECT','VIEW')
    if (!templateProjectDeniedProperties.includes('description')) {
      matchColumns.push(['PROJECT_TEMPLATE.description', 'has', params.filter])
    }
    if (!templateProjectDeniedProperties.includes('identifier')) {
      matchColumns.push(['PROJECT_TEMPLATE.identifier', 'has', params.filter])
    }

    if (Array.isArray(customFields) && customFields.length > 0) {
      for (const c of customFields) {
        if ((c.type == 'String' || c.type == 'Enum<String>') && !templateProjectDeniedProperties.includes(c.name)) {
          matchColumns.push([`PROJECT_TEMPLATE.${c.name}`, 'has', params.filter])
        }
      }
    }

    data['filter'] = ['_or_', matchColumns]
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'PROJECT_TEMPLATE.TAG.name';
      } else {
        field = `PROJECT_TEMPLATE.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if(params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId'? 'name': params.ksort[i];
        data['sort'].push([fields[sortKey], params.order[i], '', 'lowerCase']);
      }
    } else {
      data['sort'].push([fields[params.ksort], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.badgeFilters;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of project details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
 function get(data, links=null) {
  const url = urlParams('/api/template/project/get', links);
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update project details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', name: 'Paramount' ... }]
 */
function update(data) {
  const url = '/api/template/project/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of projects
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/template/project/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}

/**
 * Retrieving a start and end date for all tasks in project
 * @param String uuId
 * The id of the project 
 */
function span(uuId) {
  const fields = {
    taskUuid: ["PROJECT_TEMPLATE.TASK_TEMPLATE.uuId"],
    min: ["PROJECT_TEMPLATE.TASK_TEMPLATE.startTime", "<AYETIME>", "time", "min"],
    max: ["PROJECT_TEMPLATE.TASK_TEMPLATE.closeTime", "<OLDTIME>", "time", "max"]
  }

  let data = {
    'name': 'List of the tasks, with earliest and latest dates specified by children'
    , 'type': 'msql'
    , 'nominate': 'PROJECT_TEMPLATE.TASK_TEMPLATE'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ["PROJECT_TEMPLATE.uuId", "eq", uuId]
  ]

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    let min = 0;
    let max = 0;
    for (let i = 0; i < mappedData.length; i++) {
      if ((mappedData[i].min !== 0 && mappedData[i].min < min) || min === 0) {
        min = mappedData[i].min;
      }
      if (mappedData[i].max !== 9223372036854776000 && mappedData[i].max > max || max === 0) {
        max = mappedData[i].max;
      }
    }
    return {
      min: min,
      max: max
    }
  });
}

/**
 * Retrieving the earliest start and the latest end date of all tasks in project
 * @param String uuId
 * The id of the project 
 */
function getEarliestAndLatestDateOfTasks(uuId) {

  if (uuId == null) {
    return [];
  }

  const fields = {
    min: ["PROJECT_TEMPLATE.startTime", 'null'],
    max: ["PROJECT_TEMPLATE.closeTime", 'null']
  }

  let data = {
    'name': 'Get the earliest and latest dates of tasks of a project'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': 1
    , 'holder': uuId
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j] != 'null'? i[j] : null;
      }

      return result;
    });
    return mappedData[0];
  });
}

/**
 * schedule projects 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId},{uuId}]
 */
function schedule(data) {
  const url = '/api/template/project/schedule';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function clone(refId, data) {
  const url = '/api/template/project/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId }
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of PROJECT_TEMPLATE info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'PROJECT_TEMPLATE.uuId',
    name: 'PROJECT_TEMPLATE.name'
  }
  

  let data = {
    'name'  : 'PROJECT_TEMPLATE Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'PROJECT_TEMPLATE'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`PROJECT_TEMPLATE.${field}`]
  
  const data = {
    'name'  : `Unique PROJECT_TEMPLATE property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}
