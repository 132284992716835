<template>
  <div>
    <SkillSelectorModal :show.sync="skillSelectorShow" @cancel="skillSelectorCancel" @ok="skillSelectorOk" :allowNone="true" :taskUuId="null" :selectedSkill="skillUuids" :enablePagination="false" @delete="skillSelectorDeleted"/>
    <template v-for="(item, index) in toConfirmSkills">
      <SkillLevelModal 
        :key="`skill-${index}`" 
        :show.sync="item.show" 
        :canApplyAll="toConfirmSkills.length > 1" 
        :uuId="item.uuId" 
        :name="item.name" 
        :level="item.level"
        :cData="item"
        :edgeName="edgeName"  
        @ok="toConfirmSkillOk" 
        @cancel="toConfirmSkillCancel"/>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';
export default Vue.extend({
  name: 'SkillEditor',
  components: {
    SkillSelectorModal: () => import('@/components/modal/SkillSelectorModal'),
    SkillLevelModal: () => import('@/components/modal/SkillLevelModal')
  },
  data() {
    return {
      value: null,
      skillUuids: [],
      skillSelectorShow: true,
      skillLevel: {},
      toConfirmSkills: [],
      compactMode: false,
      edgeName: 'TASK-SKILL'
    };
  },
  computed: {
    // skillUuids() {
    //   return this.value != null? this.value.map(i => i.uuId) : [];
    // }
  },
  methods: {
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        const curValue = this.value;
        if (orgValue == null && curValue == null) {
          return this.params.value;
        } else if ((orgValue == null && curValue != null && curValue.length > 0) ||
                    (orgValue != null && (orgValue.length != curValue.length))) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        let hasChanged = false;
        for (let i = 0, len = orgValue.length; i < len; i++) {
          const found = curValue.find(v => v.uuId == orgValue[i].uuId);
          if (found == null || orgValue[i].level != found.level) {
            hasChanged = true;
            break;
          }
        }
        if (hasChanged) {
          const updatedValue = objectClone(this.params.value);
          updatedValue.single = this.value;
          return updatedValue;
        }
        return this.params.value;
      } else {
        return this.value;
      }
      
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },
    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    skillSelectorOk({ details }) {
      const newSkills = details.map(i => { return { uuId: i.uuId, name: i.name, level: i.name in this.skillLevel ? this.skillLevel[i.name] : null, show: false }});
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length, ...newSkills);
      if (this.toConfirmSkills.length === 0) {
        this.value = [];
        this.closePicker();
      }
      else {
        for(let i = this.toConfirmSkills.length-1; i > -1; i--) {
          this.toConfirmSkills[i].toBeShown = true;
        }
        this.toConfirmSkills[this.toConfirmSkills.length - 1].toBeShown = false;
        this.toConfirmSkills[this.toConfirmSkills.length - 1].show = true;
      }
    },
    toConfirmSkillOk({ id, level, applyToAll, customData, customFields }) {
      const selected = this.toConfirmSkills[this.toConfirmSkills.findIndex(i => i.uuId === id)]
      selected.level = level;
      for (const f of customFields) {
        if (Object.hasOwn(customData, f.name)) {
          selected[f.name] = customData[f.name];
        }
      }
      const toBeShown = this.toConfirmSkills.filter(i => i.toBeShown);
      if(toBeShown.length === 0) {
        this.toConfirmSkillCommit(customFields);
      }
      else if (applyToAll) {
        for (const entry of toBeShown) {
          entry.toBeShown = false;
          entry.level = level;
          for (const f of customFields) {
            if (Object.hasOwn(customData, f.name)) {
              entry[f.name] = customData[f.name];
            }
          }
        }
        this.toConfirmSkillCommit(customFields);
      }
      else {
        toBeShown[toBeShown.length - 1].toBeShown = false;
        toBeShown[toBeShown.length - 1].show = true;
      }
    },
    toConfirmSkillCancel() {
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      this.toConfirmSkills.splice(0, this.toConfirmSkills.length);
      this.closePicker();
    },
    toConfirmSkillCommit(customFields=[]) {
      //Set all show state to false. To close/hide remaining modals if any.
      for(let i = 0, len = this.toConfirmSkills.length; i < len; i ++) {
        this.toConfirmSkills[i].show = false;
      }
      const newSkills = this.toConfirmSkills.filter(i => i.level != null);
      this.value = newSkills.map(i => { 
        const s = { uuId: i.uuId, name: i.name, level: i.level }
        for (const f of customFields) {
          if (Object.hasOwn(i, f.name)) {
            s[f.name] = i[f.name];
          }
        }
        return s;
      });
      this.closePicker();
    },
    skillSelectorCancel() {
      if (this.toConfirmSkills.length === 0) {
        this.closePicker();
      }
    },
    skillSelectorDeleted({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});
    }
  },

  created() {
    if (this.params.edgeName != null) {
      this.edgeName = this.params.edgeName;
    }
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    this.value = this.compactMode? objectClone(this.params.value.single): this.params.value;
    if (this.value != null) {
      this.skillUuids.splice(0, this.skillUuids.length, ...this.value.map(i => i.uuId));
    }
  }
})
</script>