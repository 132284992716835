
import { httpAjax, urlParams } from '@/helpers';
import { getKeysWithoutRedactedFields } from './common';
import { getPermissionDenyProperties } from '@/helpers/permission'
export const projectService = {
  create,
  list,
  tilesList,
  get,
  update,
  remove,
  span,
  staff,
  listKanban,
  schedule,
  listActive,
  stageOrder,
  clone,
  clonev2,
  listHomeDashboard,
  calendar,
  listTimelineWidgetProjects,
  listTimelineWidgetMilestones,
  listv2,
  listNames,
  taskCount,
  dashboardListDepartments,
  listUniqueValuesOfProperty
};

/**
 * Create a new project 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId: 'null', name: 'Paramount' ... }]
 */
function create(data) {
  const url = '/api/project/add';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Retrieving a concise list of project info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function list(params, includeProgress = false, includeCalculation = false) {
  const fields = {
    uuId: ['PROJECT.uuId'],
    name: ['PROJECT.name'],
    scheduleStart: ['PROJECT.scheduleStart'],
    scheduleFinish: ['PROJECT.scheduleFinish'],
    customer: ['PROJECT.CUSTOMER.name'],
    company: ['PROJECT.COMPANY.name'],
    location: ['PROJECT.LOCATION.name'],
    rebateName: ['PROJECT.REBATE.name'],
    rebateRebate: ['PROJECT.REBATE.rebate'],
    priority: ['PROJECT.priority'],
    scheduleMode: ['PROJECT.scheduleMode'],
    autoScheduling: ['PROJECT.autoScheduling'],
    description: ['PROJECT.description'],
    currencyCode: ['PROJECT.currencyCode'],
    stages: ['PROJECT.STAGE_LIST.name'],
    stagesUuId: ['PROJECT.STAGE_LIST.uuId'],
    status: ['PROJECT.STAGE.name'],
    tag: ['PROJECT.TAG.name'],
    color: ['PROJECT.color'],
    companyColor: ['PROJECT.COMPANY.color'],
    locationColor: ['PROJECT.LOCATION.color'],
    statusColor: ['PROJECT.STAGE.color'],
    customerColor: ['PROJECT.CUSTOMER.color'],
    rebateColor: ['PROJECT.REBATE.color'],
    identifier: ['PROJECT.identifier'],
    avatarRef: ['PROJECT.avatarRef']
  }

  if (includeProgress) {
    fields['progress'] = ['PROJECT.progress'];
  }

  if (includeCalculation) {
    fields['progress'] = ['PROJECT.progress'];
    fields['fixedCost'] = ['PROJECT.fixedCost'];
    fields['actualCost'] = ['PROJECT.actualCost'];
    fields['estimatedCost'] = ['PROJECT.estimatedCost'];
    fields['fixedCostNet'] = ['PROJECT.fixedCostNet'];
    fields['actualCostNet'] = ['PROJECT.actualCostNet'];
    fields['estimatedCostNet'] = ['PROJECT.estimatedCostNet'];
    fields['totalActualDuration'] = ['PROJECT.actualDuration', 0, "days"];
    fields['estimatedDuration'] = ['PROJECT.estimatedDuration', 0, "days"];
    fields['fixedDuration'] = ['PROJECT.fixedDuration', 0, "days"];
  }



  let data = {
    'name': 'Project List'
    , 'type': 'msql'
    , 'start': params.start
    , 'limit': params.limit
    , 'nominate': 'PROJECT'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
        data['filter'] = [
          '_or_', [
            ['PROJECT.name', 'regex', params.filter],
            ['PROJECT.TAG.name', 'regex', params.filter],
            ['PROJECT.identifier', 'regex', params.filter],
          ]
        ]
    }
    else {
      data['filter'] = params.filter;
    }
  }
  if (params.uuIds) {
    if (!('filter' in data)) {
      data['filter'] = [];
    }
    data['filter'].push(['PROJECT.uuId', 'within', params.uuIds.join("|")]);
    delete params.uuIds;
  }

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    const numeric = ['PROJECT.scheduleStart', 'PROJECT.scheduleFinish'];
    if (Array.isArray(params.ksort) && Array.isArray(params.order)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId' ? 'name' : params.ksort[i];
        if (numeric.includes(sortKey)) {
          data['sort'].push([fields[sortKey][0], params.order[i]]);
        } else if (fields[sortKey] && numeric.includes(fields[sortKey][0])) {
          data['sort'].push([fields[sortKey][0], params.order[i]]);
        }  
        else if (fields[sortKey]){
          data['sort'].push([fields[sortKey][0], params.order[i], '', 'lowerCase']);
        }
        else {
          data['sort'].push([sortKey, params.order[i], '', 'lowerCase']);
        }
      }
    }
    else if (typeof params.ksortType !== 'undefined') {
      if (params.ksortType === 'String') {   
        data['sort'].push([params.ksort, params.order, '', 'lowerCase']);
      }
      else {
        data['sort'].push([params.ksort, params.order]);
      }
    }
    else {
      data['sort'].push([fields[params.ksort][0], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return {
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'progress') {
            result['progressPercent'] = Math.trunc(i[j] * 100) + '%';
          }

          if (keys[j] === 'customer') {
            result[keys[j]] = i[j].join(", ");
            result['customerName'] = i[j];
          }
          else {
            result[keys[j]] = i[j];
          }
        }

        // Preprocess rebates
        result.rebates = [];
        if (Array.isArray(result.rebateName) && Array.isArray(result.rebateRebate)) {
          for (let i = 0; i < result.rebateName.length; i++) {
            result.rebates.push({
              name: result.rebateName[i],
              rebate: result.rebateRebate[i],
            });
          }
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of project info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function tilesList(params, includeProgress = false, includeCalculation = false) {
  const fields = {
    uuId: ['PROJECT.uuId'],
    name: ['PROJECT.name'],
    description: ['PROJECT.description'],
    scheduleStart: ['PROJECT.scheduleStart', 'null'],
    scheduleFinish: ['PROJECT.scheduleFinish', 'null'],
    customerName: ['PROJECT.CUSTOMER.name'],
    avatarRef: ['PROJECT.avatarRef'],
    bannerRef: ['PROJECT.bannerRef'],
    color: ['PROJECT.color'],
    companyColor: ['PROJECT.COMPANY.color'],
    locationColor: ['PROJECT.LOCATION.color'],
    statusColor: ['PROJECT.STAGE.color'],
    customerColor: ['PROJECT.CUSTOMER.color'],
    rebateColor: ['PROJECT.REBATE.color'],
    tag: ['PROJECT.TAG.name'],
    status: ['PROJECT.STAGE.name']
  }

  if (includeProgress) {
    fields['progress'] = ['PROJECT.progress'];
  }

  if (includeCalculation) {
    fields['progress'] = ['PROJECT.progress'];
    fields['actualCost'] = ['PROJECT.actualCost'];
    fields['estimatedCost'] = ['PROJECT.estimatedCost'];
    fields['totalActualDuration'] = ['PROJECT.actualDuration', 0, "days"];
    fields['estimatedDuration'] = ['PROJECT.estimatedDuration', 0, "days"];
    fields['fixedDuration'] = ['PROJECT.fixedDuration', 0, "days"];
    fields['fixedCost'] = ['PROJECT.fixedCost'];
    fields['currencyCode'] = ['PROJECT.currencyCode']
  }

  let data = {
    'name': 'Project Tiles List'
    , 'type': 'msql'
    , 'start': params.start
    , 'limit': params.limit
    , 'nominate': 'PROJECT'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {
        data['filter'] = [
          '_or_', [
            ['PROJECT.name', 'regex', params.filter]
          ]
        ]
    }
    else {
      data['filter'] = params.filter;
    }
  }
  if (params.uuIds) {
    data.holder = params.uuIds;
    delete params.uuIds;
  }

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    if (Array.isArray(params.ksort) && Array.isArray(params.order)) {
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId' ? 'name' : params.ksort[i];
        data['sort'].push([fields[sortKey][0], params.order[i], '', 'lowerCase']);
      }
    }
    else if (typeof params.ksortType !== 'undefined') {
      if (params.ksortType === 'String') {   
        data['sort'].push([params.ksort, params.order, '', 'lowerCase']);
      }
      else {
        data['sort'].push([params.ksort, params.order]);
      }
    }
    else {
      data['sort'].push([fields[params.ksort][0], params.order, '', 'lowerCase']);
    }
  
    // Check that the sort field is in the select
    for (const sort of data['sort']) {
      if (data['select'].filter(v => v[0] === sort[0]).length === 0) {
        data['select'].push([sort[0]]);
        fields['sort'] = [sort[0]];
      }  
    }
  }
  else {
    // sort by name if no sorting
    data['sort'] = [['PROJECT.name', 'incr', '', 'lowerCase']];
  }

  
  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return {
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'progress') {
            result['progressPercent'] = Math.trunc(i[j] * 100) + '%';
          } 
          
          if (keys[j] === 'customer' || keys[j] === 'status') {
            result[keys[j]] = i[j].join(', ');
          }
          else if (keys[j] === 'scheduleStart' || keys[j] === 'scheduleFinish') {
            result[keys[j]] = i[j] != 'null'? i[j] : null;
          }
          else {
            result[keys[j]] = i[j];
          }
        }

        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a start and end date for all tasks in project
 * @param String uuId
 * The id of the project 
 */
function span(uuId) {
  const fields = {
    min: ["PROJECT.startTime", "<AYETIME>", "time", "min"],
    max: ["PROJECT.closeTime", "<OLDTIME>", "time", "max"]
  }

  let data = {
    'name': 'List of the tasks, with earliest and latest dates specified by children'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'PROJECT'
    , 'holder': uuId
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    const mappedData = rawData.map(i => {
      const result = {}
      for (let j = 0, len = i.length; j < len; j++) {
        result[keys[j]] = i[j];
      }

      return result;
    });
    let min = 0;
    let max = 0;
    for (let i = 0; i < mappedData.length; i++) {
      if ((mappedData[i].min !== 0 && mappedData[i].min < min) || min === 0) {
        min = mappedData[i].min;
      }
      if (mappedData[i].max !== 9223372036854776000 && mappedData[i].max > max || max === 0) {
        max = mappedData[i].max;
      }
    }
    return {
      min: min,
      max: max
    }
  });
}

/**
 * Retrieving a list of staff for all tasks in project
 * @param String uuId
 * The id of the project 
 */
function staff(uuId, ksort, order) {
  const fields = {
  staffUuid: ["PROJECT.TASK.STAFF.uuId"]
  }
  
  if (ksort) {
  fields['orderCol'] = ["PROJECT.TASK.STAFF." + ksort];
  }

  let data = {
    'name': 'List of staff in the project'
    , 'type': 'msql'
    , 'dedup': true
    , 'limit': -1
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  if (ksort) {
    data['sort'] = [];
    data['sort'].push(["PROJECT.TASK.STAFF." + ksort, order, '', 'lowerCase']);
  }

  data['filter'] = [
  ["PROJECT.uuId" , "eq", uuId]
  ]

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
        arg_total: response.data.arg_total,
        data: rawData.map(i => {
          const result = {}
          for (let j = 0, len = i.length; j < len; j++) {
            result[keys[j]] = i[j];
          }

          return result;
        })
      }
  });
}

/**
 * Retrieving a list of project details by 
 * passing in an array list of uuIds as arguments
 * @param Array data  
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function get(data, links=null, macros=null) {
  const url = urlParams('/api/project/get', links, macros);

  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

/**
 * Update project details 
 * by passing  necessary information
 * @param Array data 
 * e.g [{uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx', name: 'Paramount' ... }]
 */
function update(data) {
  const url = '/api/project/update';
  const config = {
    headers: getHeaders()
  }
  return httpAjax.put(url, data, config);
}

/**
 * Delete a list of projects
 * by passing in an array list of uuIds
 * @param Array data 
 * e.g [{ uuId: 'xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}, {...}]
 */
function remove(data) {
  const url = '/api/project/delete';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function getHeaders() {
  return Object.assign({ 'Content-Type': 'application/json' });
}


function listKanban(projectIds) {
  const fields = {
    uuId: ['PROJECT.uuId'],
    name: ['PROJECT.name'],
    stageIds: ['PROJECT.STAGE.uuId'],
    stageNames: ['PROJECT.STAGE.name'],
  }

  let data = {
    'name': 'Project Kanban List'
    , 'type': 'msql'
    , 'start'  : 0
    , 'limit'  : -1
    , 'nominate': 'PROJECT'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [['PROJECT.uuId', 'within', projectIds.join("|")]];

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        result.stageList = [];
        for (var k = 0; k < result.stageIds.length; k++) {
          if (result.stageIds[k]) {
            result.stageList.push({uuId: result.stageIds[k], name: result.stageNames[k]})
          }
        }

        delete result.stageIds;
        delete result.stageNames;

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       

        return result;
      })
    }
  });
}

/**
 * schedule projects 
 * by passing necessary information
 * @param Array data 
 * e.g [{uuId},{uuId}]
 */
function schedule(data) {
  const url = '/api/project/schedule';
  const config = {
    headers: getHeaders()
  };
  return httpAjax.post(url, data, config);
}

function listActive(params) {
  const fields = {
    uuId:             ['PROJECT.uuId'],
    name:             ['PROJECT.name'],
    startTime:        ['PROJECT.scheduleStart', '<OLD>'],
    endTime:          ['PROJECT.scheduleFinish', '<AYE>'],
    status:           ['PROJECT.STAGE.name'],
    actualProgress:   ['PROJECT.progress']
  }
  
  let sort = [];
  if( params && params.ksort && params.ksort.length > 0) {
    if(Array.isArray(params.ksort)) {
      for(let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i];
        const sortOrder = params.order[i];
        let p = [fields[sortKey][0], sortOrder];
        if (sortKey == 'name') {
          p.push("");
          p.push("lowercase");
        }
        sort.push(p);
      }
    } else {
      sort.push([fields[params.ksort][0], params.order]);
    }
  } else {
    sort.push(['PROJECT.TASK.startTime', 'incr']);
  }

  delete params.ksort;
  delete params.order;

  let data = {
    'name'    : 'Active Projects List'
    ,'optimize': true
    ,'nominate': 'PROJECT'
    ,'type'   : 'msql'
    ,'start'  : params.start
    ,'limit'  : params.limit ? params.limit : -1
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'sort'   : sort
  }
  
  data['filter'] = [
    "_and_",
    [
      "_or_",
      [
        [
            "PROJECT.scheduleStart",
            "lte",
            Date.now()
        ],
        "_not_",
        [[
            "PROJECT.scheduleStart"
        ]]
      ],
      "_or_",
      [
        [
            "PROJECT.scheduleFinish",
            "gte",
            Date.now()
        ],
        "_not_",
        [[
            "PROJECT.scheduleFinish"
        ]]
      ]
    ]
  ];

  if (params.companyrule) {
    data['filter'].push(params.companyrule);
  }

  delete params.companyrule;

  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        // Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       
        return result;
      })
    }
  });
}

/**
 * Reorder a project stage link, relative to other stage links.
 * 
 * @param {*} projectId Project uuId
 * @param {*} order List of stage uuIds, in the order we want
 */
function stageOrder(projectId, order) {
  const url = '/api/project/stage_list/order';
  const params = {
    holder: projectId
  }
  const config = {
    params: params,
    headers: getHeaders()
  };
  return httpAjax.post(url, order, config);
}

/**
 * Clone a project with given new name
 * @param String refId Target project UuId
 * @param Object data Object contains name for cloned project.
 */
function clone(refId, includeTask, data) {
  const url = '/api/project/clone';
  const config = {
    data: data,
    headers: getHeaders(),
    params: { reference: refId, includeTask: includeTask }
  };
  return httpAjax.post(url, data, config);
}

//Standardize the parameter order
//referenced in project.js and used in GenericSelectorModalForAdmin.vue
function clonev2(refId, data, { includeTask=false } ={}) {
  return clone(refId, includeTask, data)
}

function dashboardListDepartments(uuId, bookings) {
  let fields = {
    name:             ['PROJECT.TASK.STAFF.DEPARTMENT.name', ''],
    estimatedCost:         ['PROJECT.TASK.estimatedCost'],
    estimatedDuration:         ['PROJECT.TASK.estimatedDuration', 0, 'days'],
    headCount:        ["PROJECT.TASK.STAFF.uuId", 0, "<AUTO>", "count"]
  }
  
  if (bookings) {
    fields = {
      name:             ['PROJECT.BOOKING.STAFF.DEPARTMENT.name', ''],
      headCountBooking:        ["PROJECT.BOOKING.STAFF.uuId", 0, "<AUTO>", "count"]
    }
  }
  
  let data = {
    'name'    : 'Project Dashboard Department List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'left-join': 'PROJECT'
    ,'holder':   uuId
    ,'select' : Object.keys(fields).map(i => fields[i])
    ,'filter' : bookings ? [] : [['PROJECT.TASK.taskType', 'eq', 'Task']]
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listHomeDashboard(uuIds) {
  const fields = {
    uuId:             ['PROJECT.uuId'],
    name:             ['PROJECT.name'],
    progress:         ['PROJECT.progress'],
    plannedProgress:  ['PROJECT.plannedProgress'],
    // For selector columns
    scheduleStart:    ['PROJECT.scheduleStart'],
    scheduleFinish:   ['PROJECT.scheduleFinish'],
    customer:         ['PROJECT.CUSTOMER.name'],
    company:          ['PROJECT.COMPANY.name'],
    location:         ['PROJECT.LOCATION.name'],
    priority:         ['PROJECT.priority'],
    description:      ['PROJECT.description'],
    status:           ['PROJECT.STAGE.name'],
    identifier:       ['PROJECT.identifier']
  }
  
  let data = {
    'name'    : 'Home Dashboard List'
    ,'type'   : 'msql'
    ,'start'  : 0
    ,'limit'  : -1
    ,'nominate': 'PROJECT'
    ,'select' : Object.keys(fields).map(i => fields[i])
  }
  
  const url = '/api/query/match';
  const config = {
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'progress') {
            result['progressPercent'] = Math.trunc(i[j] * 100) + '%';
          }
          if (keys[j] === 'customer') {
            result[keys[j]] = i[j].join(", ");
          } else {
            result[keys[j]] = i[j];
          }
        }

        // Preprocess rebates
        result.rebates = [];
        if (result.rebateName) {
          for (let i = 0; i < result.rebateName.length; i++) {
            result.rebates.push({
              name: result.rebateName[i],
              rebate: result.rebateRebate[i],
            });
          }
        }

        //Preprocess Child details
        result.uuId = result.uuId && result.uuId.length > 0? result.uuId : null;       
        
        return result;
      })
    }
  });
}

/**
 * Retrieving a list of project calendar details
 */
 function calendar(uuId) {
  const url = `/api/project/${uuId}/calendar`;
  const config = {
    headers: getHeaders()
  };
  return httpAjax.get(url, config);
}


function listTimelineWidgetProjects(params) {
  const fields = {
    uuId: ['PROJECT.uuId'],
    name: ['PROJECT.name'],
    color: ['PROJECT.color'],
    startTime: ['PROJECT.scheduleStart'],
    closeTime: ['PROJECT.scheduleFinish'],
    fixedCost: ['PROJECT.fixedCost'],
    description: ['PROJECT.description'],
    progress: ['PROJECT.progress'],
    actualCost: ['PROJECT.actualCost'],
    estimatedCost: ['PROJECT.estimatedCost'],
    customer: ['PROJECT.CUSTOMER.name', "n/a","","fold"],
    customerColor: ['PROJECT.CUSTOMER.color', "","","fold"],
    status: ['PROJECT.STAGE.name', "n/a","","fold"],
    statusColor: ['PROJECT.STAGE.color', "","","fold"],
    tag: ['PROJECT.TAG.name'],
    tagColor: ['PROJECT.TAG.color'],
    location: ['PROJECT.LOCATION.name'],
    locationColor: ['PROJECT.LOCATION.color']
  }

  let data = {
    'name': 'Project Timeline Widget List '
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'nominate': 'PROJECT'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    '_or_', [
      ['PROJECT.scheduleStart', 'neq', 0],
      ['PROJECT.scheduleFinish', 'neq', 0]
    ]
  ];

  if (params.companyrule) {
    data['filter'].push(params.companyrule);
  }


  delete params.start;
  delete params.end;
  delete params.companyrule;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return {
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'customer' || keys[j] === 'status') {
            result[keys[j]] = i[j].join(", ");
          } else {
            result[keys[j]] = i[j];
          }
        }
        return result;
      })
    }
  });
}

function listTimelineWidgetMilestones(ids) {
  const fields = {
    projectId: ['PROJECT.uuId'],
    uuId: ['PROJECT.TASK.uuid'],
    name: ['PROJECT.TASK.name'],
    progress: ['PROJECT.TASK.progress'],
    startTime: ['PROJECT.TASK.startTime'],
  }

  let data = {
    'name': 'Project Timeline Widget List (Milesones)'
    , 'type': 'msql'
    , 'start': 0
    , 'limit': -1
    , 'holder': ids
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  data['filter'] = [
    ['PROJECT.TASK.taskType', 'eq', 'Milestone']
  ];

  const url = '/api/query/match';
  const config = { }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = Object.keys(fields);
    return {
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }
        return result;
      })
    }
  });
}

/**
 * Used by GenericSelectorModalForAdmin.vue
 * Retrieving a concise list of project info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'Paramount', ksort='name', order: 'incr' }}
 */
function listv2(params, customFields=[], { includeProgress=true, includeCalculation =true, companyId=null }={}) {
  const fields = {
    uuId: ['PROJECT.uuId']
    , name: ['PROJECT.name']
    , scheduleStart: ['PROJECT.scheduleStart']
    , scheduleFinish: ['PROJECT.scheduleFinish']
    , customerUuId: ['PROJECT.CUSTOMER.uuId']
    , customerName: ['PROJECT.CUSTOMER.name']
    , customerColor: ['PROJECT.CUSTOMER.color']
    , companyUuId: ['PROJECT.COMPANY.uuId']
    , companyName: ['PROJECT.COMPANY.name']
    , companyColor: ['PROJECT.COMPANY.color']
    , locationUuId: ['PROJECT.LOCATION.uuId']
    , locationName: ['PROJECT.LOCATION.name']
    , locationColor: ['PROJECT.LOCATION.color']
    , rebateUuId: ['PROJECT.REBATE.uuId']
    , rebateName: ['PROJECT.REBATE.name']
    , rebateRebate: ['PROJECT.REBATE.rebate']
    , rebateColor: ['PROJECT.REBATE.color']
    , priority: ['PROJECT.priority']
    , scheduleMode: ['PROJECT.scheduleMode']
    , description: ['PROJECT.description']
    , currencyCode: ['PROJECT.currencyCode']
    , stageUuIds: ['PROJECT.STAGE_LIST.uuId']
    , stageNames: ['PROJECT.STAGE_LIST.name']
    , statusUuId: ['PROJECT.STAGE.uuId']
    , statusName: ['PROJECT.STAGE.name']
    , statusColor: ['PROJECT.STAGE.color']
    , tag: ['PROJECT.TAG.name']
    , color: ['PROJECT.color']
    , identifier: ['PROJECT.identifier']
    , autoScheduling: ['PROJECT.autoScheduling']
  }

  if (includeProgress) {
    fields['progress'] = ['PROJECT.progress'];
  }

  if (includeCalculation) {
    fields['progress'] = ['PROJECT.progress'];
    fields['fixedCost'] = ['PROJECT.fixedCost'];
    fields['actualCost'] = ['PROJECT.actualCost'];
    fields['estimatedCost'] = ['PROJECT.estimatedCost'];
    fields['fixedCostNet'] = ['PROJECT.fixedCostNet'];
    fields['actualCostNet'] = ['PROJECT.actualCostNet'];
    fields['estimatedCostNet'] = ['PROJECT.estimatedCostNet'];
    fields['totalActualDuration'] = ['PROJECT.actualDuration', 0, "days"];
    fields['estimatedDuration'] = ['PROJECT.estimatedDuration', 0, "days"];
    fields['fixedDuration'] = ['PROJECT.fixedDuration', 0, "days"];
  }

  if (Array.isArray(customFields) && customFields.length > 0) {
    for(const cField of customFields) {
      fields[cField.name] = [`PROJECT.${cField.name}`];
    }
  }


  let data = {
    'name': 'Project List'
    , 'type': 'msql'
    , 'start': params.start
    , 'limit': params.limit
    , 'nominate': 'PROJECT'
    , 'select': Object.keys(fields).map(i => fields[i])
  }

  if (params.filter && params.filter.length > 0) {
    if (typeof params.filter === 'string') {

      const matchColumns = [
        ['PROJECT.name', 'has', params.filter]
      ]

      const projectDeniedProperties = getPermissionDenyProperties('PROJECT','VIEW')
      if (!projectDeniedProperties.includes('description')) {
        matchColumns.push(['PROJECT.description', 'has', params.filter])
      }
      if (!projectDeniedProperties.includes('identifier')) {
        matchColumns.push(['PROJECT.identifier', 'has', params.filter])
      }

      if (Array.isArray(customFields) && customFields.length > 0) {
        for (const c of customFields) {
          if ((c.type == 'String' || c.type == 'Enum<String>') && !projectDeniedProperties.includes(c.name)) {
            matchColumns.push([`PROJECT.${c.name}`, 'has', params.filter])
          }
        }
      }

      const tagDeniedProperties = getPermissionDenyProperties('TAG', 'VIEW')
      if (!projectDeniedProperties.includes('TAG') && 
          !projectDeniedProperties.includes('TAG.name') && 
          !tagDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.TAG.name', 'has', params.filter])
      }

      const customerDeniedProperties = getPermissionDenyProperties('CUSTOMER', 'VIEW')
      if (!projectDeniedProperties.includes('CUSTOMER') && 
          !projectDeniedProperties.includes('CUSTOMER.name') && 
          !customerDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.CUSTOMER.name', 'has', params.filter])
      }
      
      const companyDeniedProperties = getPermissionDenyProperties('COMPANY', 'VIEW')
      if (!projectDeniedProperties.includes('COMPANY') && 
          !projectDeniedProperties.includes('COMPANY.name') && 
          !companyDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.COMPANY.name', 'has', params.filter])
      }

      const locationDeniedProperties = getPermissionDenyProperties('LOCATION', 'VIEW')
      if (!projectDeniedProperties.includes('LOCATION') && 
          !projectDeniedProperties.includes('LOCATION.name') && 
          !locationDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.LOCATION.name', 'has', params.filter])
      }

      const rebateDeniedProperties = getPermissionDenyProperties('REBATE', 'VIEW')
      if (!projectDeniedProperties.includes('REBATE') && 
          !projectDeniedProperties.includes('REBATE.name') && 
          !rebateDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.REBATE.name', 'has', params.filter])
      }

      const stageDeniedProperties = getPermissionDenyProperties('STAGE', 'VIEW')
      if (!projectDeniedProperties.includes('STAGE') && 
          !projectDeniedProperties.includes('STAGE.name') && 
          !stageDeniedProperties.includes('name') ) {
        matchColumns.push(['PROJECT.STAGE.name', 'has', params.filter])
      }
      
      data['filter'] = ['_or_', matchColumns]
    }
    else {
      data['filter'] = params.filter;
    }
  }

  //BadgeFilter related
  if (Array.isArray(params.badgeFilters) && params.badgeFilters.length > 0) {
    const badgeFilterList = [];
    for (const f of params.badgeFilters) {
      if (f.field == null || !Array.isArray(f.value) || f.value.length == 0) {
        continue;
      }
      
      let field = null;
      if (f.field == 'tagName') {
        field = 'PROJECT.TAG.name';
      } else if (f.field == 'locationName') {
        field = 'PROJECT.LOCATION.name';
      } else if (f.field == 'companyName') {
        field = 'PROJECT.COMPANY.name';
      } else if (f.field == 'customerName') {
        field = 'PROJECT.CUSTOMER.name';
      } else if (f.field == 'rebateName') {
        field = 'PROJECT.REBATE.name';
      } else if (f.field == 'stageName') {
        field = 'PROJECT.STAGE.name';
      } else {
        field = `PROJECT.${f.field}`;
      } 

      const isEqual = !f.operator || f.operator === 'is';
      let valueList = [];
      const value = f.value;
      for (const v of value) {
        if (v.text != null && (typeof v.text === 'number' || (typeof v.text === 'string' && v.text&& v.text.length > 0 && v.text !== '(Empty)'))) {
          //use value when exists. Otherwise use text
          //Reason: When value property exists, it means the text is localized and can't be used in filter.
          valueList.push([field, 'eq', Object.hasOwn(v, 'value')? v.value : v.text]);
        }
        //Start processing (EMPTY) field value 
        else if (isEqual) {
          valueList.push('_not_', [[field]])
        } else {
          //Special handling: when is not empty, put it into badgeFilterList directly to act as 'field must exist'. 
          badgeFilterList.push([field])
        }
      }
      if (valueList.length > 0) {
        if (valueList.length > 1) {
          valueList = ['_or_', valueList];
        }
        if (!isEqual) {
          badgeFilterList.push('_not_', valueList);
        } else {
          badgeFilterList.push(...valueList);
        }
      }
    }
    if (badgeFilterList.length > 0) {
      if (Array.isArray(data.filter) && data.filter.length > 0) {
        data.filter = [...data.filter, ...badgeFilterList]
      } else {
        data.filter = [...badgeFilterList]
      }
    }
  }

  if (params.uuIds) {
    if (!('filter' in data)) {
      data['filter'] = [];
    }
    data['filter'].push(['PROJECT.uuId', 'within', params.uuIds.join("|")]);
    delete params.uuIds;
  }

  if (companyId != null) {
    if (!('filter' in data)) {
      data['filter'] = [];
    }
    data.filter.push(['PROJECT.COMPANY.uuId', 'eq', companyId]);
  }

  if (params.ksort && params.ksort.length > 0) {
    data['sort'] = []
    
    if (Array.isArray(params.ksort) && Array.isArray(params.order)) {
      // Can't run lowerCase on numeric fields
      const numeric = [
        'fixedCost', 'actualCost', 'estimatedCost', 'fixedCostNet', 'actualCostNet', 'fixedDuration',
        'estimatedCostNet', 'actualDuration', 'estimatedDuration','PROJECT.scheduleStart', 'PROJECT.scheduleFinish'];
      for (let i = 0, len = params.ksort.length; i < len; i++) {
        const sortKey = params.ksort[i] === 'uuId' ? 'name' : params.ksort[i];
        if (numeric.includes(sortKey)) {
          data['sort'].push([fields[sortKey][0], params.order[i]]);
        } else if (numeric.includes(fields[sortKey][0])) {
          data['sort'].push([fields[sortKey][0], params.order[i]]);
        }  
        else {
          data['sort'].push([fields[sortKey][0], params.order[i], '', 'lowerCase']);
        }
      }
    }
    else if (typeof params.ksortType !== 'undefined') {
      if (params.ksortType === 'String') {   
        data['sort'].push([params.ksort, params.order, '', 'lowerCase']);
      }
      else {
        data['sort'].push([params.ksort, params.order]);
      }
    }
    else {
      data['sort'].push([fields[params.ksort][0], params.order, '', 'lowerCase']);
    }
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;
  delete params.filter;
  delete params.badgeFilters;

  const url = '/api/query/match';
  const config = {
    params: params,
    
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    const getFirstItemOfArray = (arr, defaultValue) => {
      return Array.isArray(arr) && arr.length > 0? arr[0] : defaultValue
    }
    const getArrayItemAtIndex = (arr, idx, defaultValue) => {
      return Array.isArray(arr) && arr.length >= idx? arr[idx] : defaultValue
    }
    return {
      arg_total: response.data.arg_total,
      arg_count: response.data.arg_count,
      arg_ksort: params.ksort ? params.ksort : '',
      arg_order: params.order ? params.arg_order : null,
      arg_index: response.data.arg_index,
      data: rawData.map(i => {
        const result = {}
        for (let j = 0, len = i.length; j < len; j++) {
          if (keys[j] === 'progress') {
            result['progressPercent'] = Math.trunc(i[j] * 100) + '%';
          }
          result[keys[j]] = i[j];
        }

        //Preprocess Customers
        result.customers = []
        if (result.customerUuId) {
          for (let i = 0, len = result.customerUuId.length; i < len; i++) {
            result.customers.push({
              uuId: result.customerUuId[i]
              , name: getArrayItemAtIndex(result.customerName, i, '')
            })
          }
        }
        delete result.customerUuId
        delete result.customerName
        if (Array.isArray(result.customerColor) && result.customerColor.length > 0) {
          result.customerColor = result.customerColor[0]
        } else {
          result.customerColor = ''
        }
        
        //Preprocess Companies
        result.companies = []
        if (result.companyUuId) {
          for (let i = 0, len = result.companyUuId.length; i < len; i++) {
            result.companies.push({
              uuId: result.companyUuId[i]
              , name: getArrayItemAtIndex(result.companyName, i, '')
            })
          }
        }
        delete result.companyUuId
        delete result.companyName
        if (Array.isArray(result.companyColor) && result.companyColor.length > 0) {
          result.companyColor = result.companyColor[0]
        } else {
          result.companyColor = ''
        }

        //Preprocess Locations
        result.locations = []
        if (result.locationUuId) {
          for (let i = 0, len = result.locationUuId.length; i < len; i++) {
            result.locations.push({
              uuId: result.locationUuId[i]
              , name: getArrayItemAtIndex(result.locationName, i, '')
            })
          }
        }
        delete result.locationUuId
        delete result.locationName
        if (Array.isArray(result.locationColor) && result.locationColor.length > 0) {
          result.locationColor = result.locationColor[0]
        } else {
          result.locationColor = ''
        }

        // Preprocess rebates
        result.rebates = [];
        if (result.rebateUuId) {
          for (let i = 0, len = result.rebateUuId.length; i < len; i++) {
            result.rebates.push({
              uuId: result.rebateUuId[i]
              , name: getArrayItemAtIndex(result.rebateName, i, '')
              , rebate: getArrayItemAtIndex(result.rebateRebate, i, '')
            })
          }
        }
        delete result.rebateUuId
        delete result.rebateName
        delete result.rebateRebate
        if (Array.isArray(result.rebateColor) && result.rebateColor.length > 0) {
          result.rebateColor = result.rebateColor[0]
        } else {
          result.rebateColor = ''
        }

        //Preprocess Stages
        result.stages = []
        if (result.stageUuId) {
          for (let i = 0, len = result.stageUuId.length; i < len; i++) {
            result.stages.push({
              uuId: result.stageUuId[i]
              , name: getArrayItemAtIndex(result.stageName, i, '')
            })
          }
        }
        delete result.stageUuId
        delete result.stageName

        //Preprocess Status
        result.status = { id: null, name: null }
        if (result.statusUuId && result.statusUuId.length > 0) {
          result.status.id = result.statusUuId[0]
          result.status.name = getFirstItemOfArray(result.statusName, '')
        }
        delete result.statusUuId
        delete result.statusName
        if (Array.isArray(result.statusColor) && result.statusColor.length > 0) {
          result.statusColor = result.statusColor[0]
        } else {
          result.statusColor = ''
        }
        
        //Prepare for DetailLinkCellRenderer
        result.label = result.name;
        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of PROJECT info by 
 * passing in pagination, sorting and filtering parameters
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function listNames(params) {
  const fields = {
    uuId: 'PROJECT.uuId',
    name: 'PROJECT.name'
  }
  

  let data = {
    'name'  : 'PROJECT Name List'
    ,'type' : 'msql'
    ,'start' : params.start
    ,'limit' : params.limit
    ,'nominate': 'PROJECT'
    ,'select': Object.keys(fields).map(i => [fields[i]])
  }

  //Delete ksort and order from params object as their values have been extracted. Otherwise, they will screw the request call.
  delete params.ksort;
  delete params.order;

  const url = '/api/query/match';
  const config = {
    params: params
  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      arg_ksort: params.ksort? params.ksort: '',
      arg_order: params.order? params.arg_order: null,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

/**
 * Retrieving a concise list of SKILL info by 
 * count of tasks and sum of duration
 * @param Object params 
 * e.g. {params: { start: 0, limit: 25, filter: 'john', ksort='name', order: 'incr' }}
 */
function dashboardSkillCount(projectId) {
  const fields = {
    uuId:  ["PROJECT.TASK(one).SKILL.uuId"],
    name: ['PROJECT.TASK(one).SKILL.name'],
    count: [
        "PROJECT.TASK(one).name",
        0,
        "<AUTO>",
        "count"
    ],
    totalDuration: [
        "PROJECT.TASK(one).estimatedDuration",
        0,
        "<AUTO>",
        "sum"
    ]
  }
  

  let data = {
    'name'  : 'SKILL Dashboard Name List'
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'group': 'SKILL'
    ,'select': Object.keys(fields).map(i => fields[i])
    ,'filter': [['SKILL.TASK.PROJECT.uuId', 'eq', projectId]]
  }

  const url = '/api/query/match';
  const config = {

  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

/**
 * Counting the tasks in a project
 * @param Object params 
 */
function taskCount(projectId) {
  const fields = {
    uuId: [
      "PROJECT.uuId"
      ],
    count: [
        [
          "=countLinks(A,B)",
          [
            "PROJECT"
          ],
          [
            "TASK"
          ]
        ]
      ]
  }
  

  let data = {
    'name'  : 'PROJECT Task count'
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'nominate': 'PROJECT'
    ,'holder': projectId
    ,'select': Object.keys(fields).map(i => fields[i])
  }

  const url = '/api/query/match';
  const config = {

  }
  return httpAjax.post(url, data, config).then(response => {
    const listName = response.data.jobCase;
    const rawData = response.data[listName] || [];
    const keys = getKeysWithoutRedactedFields(fields, response);
    return { 
      arg_total: response.data.arg_total,
      data: rawData.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[keys[j]] = i[j];
        }

        return result;
      })
    }
  });
}

function listUniqueValuesOfProperty(field) {
  const fieldSelector = [`PROJECT.${field}`]
  
  const data = {
    'name'  : `Unique PROJECT property: ${field}`
    ,'type' : 'msql'
    ,'start' : 0
    ,'limit' : -1
    ,'select': [
      fieldSelector
    ]
    , 'dedup': true
  }

  return httpAjax.post('/api/query/match', data, {}).then(response => {
    const listName = response.data.jobCase;
    let rawData = response.data[listName] || [];
    rawData = rawData.map(i => i[0]);
    rawData.sort((a, b) => {
      if (typeof a === 'string') {
        return a.localeCompare(b, undefined, { sensitivity: 'base'})
      } else {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        }
        return 0
      }
    });
    return rawData;
  });
}