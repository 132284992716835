import { cloneDeep } from 'lodash';
import { convertDisplayToDuration, convertDurationToDisplay } from '@/helpers/task-duration-process';
import { toFixed } from '@/helpers/task-duration-process';
import { costFormat, getFirstColor, invertColor } from '@/helpers';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export {
  taskColumnDefs,
  taskColumnMetadata,
  prepareCompactViewData
}

function prepareCompactViewData(list, taskColumns, maxSummaryTaskDepth, self, projectData) {
  const taskColumnMetadataFunc = (prop) => taskColumnMetadata(self, prop);
  const clonedList = cloneDeep(list);
  const tColumns = cloneDeep(taskColumns);

  if (clonedList.find(i => i.uuId == 'ROOT') == null) {
    //Setup virtual ROOT row
    const rootRow = {
      uuId: 'ROOT',
      name: 'Project',
      taskType: 'Project',
      path: ['ROOT'],
      treeDepth: 0
    }
    //Fill up ROOT row's data
    if (projectData != null) {
      const durationProperties = ['actualDuration', 'estimatedDuration', 'estimatedTimeToComplete'];
      for (const p of durationProperties) {
        if (projectData[p] != null) {
          rootRow[p] = convertDurationToDisplay(projectData[p], 'D');
        }
      }

      if (projectData.actualDuration != null) {
        rootRow.totalActualDuration = convertDurationToDisplay(projectData.actualDuration, 'D');
      }
      
      const rawProperties = ['autoScheduling', 'currencyCode', 'description', 'priority', 
                              'progress', 'plannedProgress', 'startTime', 'closeTime',
                              'actualCost', 'actualCostNet', 'estimatedCost', 'estimatedCostNet', 
                              'fixedCost', 'fixedCostNet', 'plannedCost', 'plannedCostNet', 'totalFixedCost', 'totalFixedCostNet'];
      for (const p of rawProperties) {
        if (projectData[p] != null) {
          rootRow[p] = projectData[p];
        }
      }

      const diffNamePropertyMap = new Map();
      diffNamePropertyMap.set('noteList', 'notes');
      diffNamePropertyMap.set('rebateList', 'rebates');
      for (const [k, v] of diffNamePropertyMap.entries()) {
        if (projectData[k] != null) {
          rootRow[v] = projectData[k];
        }
      }
    }

    const rootChildren = clonedList.filter(i => i.pUuId === 'ROOT');
    if (rootChildren != null && rootChildren.length > 0) {
      rootRow.children = rootChildren;
      for (let i = 0, len = rootChildren.length; i < len; i++) {
        rootChildren[i].parent = rootRow;
      }
    }
    clonedList.forEach(i => {
      i.path.unshift('ROOT');
    });
    clonedList.unshift(rootRow);
  }

  //Get all the summaryTasks
  const summaryTasks = clonedList.filter(i => i.taskType === 'Project');

  //Collect all distinct task columns from backend (breadth to depth order)
  const backendTaskNames = [];
  for (const summaryTask of summaryTasks) {
    if (summaryTask.children == null || summaryTask.children.length < 1) {
      continue; //Continue to next iteration when there is no children.
    }

    const children = 
      summaryTask.children
      .filter(c => c.taskType != 'Project' && c.taskType != 'Milestone')
      .map(c => { return { name: c.name.trim() } });

    for (const bName of backendTaskNames) {
      const index = children.findIndex(i => i.name == bName.name);
      if (index > -1) {
        children.splice(index, 1);
      }
    }
    if (children.length > 0) {
      backendTaskNames.push(...children);
    }
  }

  //Generate taskcol name for the collected taskNames
  for (const bTaskName of backendTaskNames) {
    if (bTaskName.colId != null) {
      continue;
    }
    const filteredList = backendTaskNames.filter(i => i.name == bTaskName.name);
    for (const [i, v] of filteredList.entries()) {
      v.headerName = v.name;
      v.colId = `PREFIX_VARIABLE_${i > 9? i : '0' + i }_${v.name}`; //PREFIX_VARIABLE will be replaced with actual value 'taskcol_xxx' where xxx is the profile uuId later.
    }
  }

  
  //Integrate backendTaskName with taskColumns (taskGroup)
  for (const v of tColumns) {
    if (v.groupId == null) {
      continue;
    }
    const vChildren = v.children;
    const clonedBNames = cloneDeep(backendTaskNames);
    const newChildren = [];
    for (const child of vChildren) {
      const foundObj = clonedBNames.find(j => j.headerName == child.headerName);
      if (foundObj != null) {
        const index = clonedBNames.findIndex(j => j.headerName == foundObj.headerName);
        clonedBNames.splice(index, 1);
      }

      if (child.native == true || foundObj != null) {
        newChildren.push(child);
      }
    }
    //Process the remaining taskName from backend if there is any.
    if (clonedBNames.length > 0) {
      const profId = v.groupId.substring('taskgroup_'.length);
      const metadata = taskColumnMetadataFunc(v.property);
      for (const bName of clonedBNames) {
        const newColDef = cloneDeep(metadata.columnDef);
        newColDef.colId = newColDef.field = bName.colId.replace('PREFIX_VARIABLE', `taskcol_${profId}`);
        newColDef.headerName = bName.headerName;
        newColDef.cellRendererParams.totalMode = self.taskColumnTotalMode;
        newColDef.native = false;
        newChildren.push(newColDef);
      }
    }
    
    vChildren.splice(0, vChildren.length, ...newChildren);
  }

  const convertChildTaskToProperties = function(targetSummaryTask, taskCols) {
    if (targetSummaryTask == null) {
      return;
    }

    if (targetSummaryTask.children == null) {
      targetSummaryTask.children = [];
    }

    const clonedTaskCols = taskCols;
    for (const taskGroup of clonedTaskCols) {
      if (taskGroup.groupId == null) {
        continue;
      }

      const taskGroupChildren = taskGroup.children;
      const property = taskGroup.property;

      const summaryTaskChildren = targetSummaryTask.children.filter(x => x.taskType == 'Task');
      for (const taskCol of taskGroupChildren) {
        const taskColId = taskCol.colId;
        targetSummaryTask[taskColId] = { single: null, childTotal: null, property };

        //Find summary task child if there is a match, transfer the details, remove it from summary task children, else add it to a new list. This list will be added as properties as well.
        const index = summaryTaskChildren.findIndex(i => i.name === taskCol.headerName);
        if (index > -1) {
          const taskChild = summaryTaskChildren[index];
          targetSummaryTask[taskColId].uuId = taskChild.uuId;
          targetSummaryTask[taskColId].single = taskChild[property];
          targetSummaryTask[taskColId].childTotal = null;
          //Keep a copy of taskChild in data property. The values is needed during cell value change event.
          targetSummaryTask[taskColId].data = { uuId: taskChild.uuId, name: taskChild.name };//Properties(uuId and name) are mandatory.
          //Populate the related data/properties which will be used when cell value is changed. (e.g. calculating estimatedDuration requires startTime, closeTime and etc)
          const d = targetSummaryTask[taskColId].data;
          d.autoScheduling = taskChild.autoScheduling; //Needed in producing manual/auto schedule button state.
          //Always include color
          d.color = taskChild.color;
          d.stageColor = taskChild.stageColor;
          d.skillColor = taskChild.skillColor;
          d.staffColor = taskChild.staffColor;
          d.resourceColor = taskChild.resourceColor;
          d.rebateColor = taskChild.rebateColor;
          d.fileColor = taskChild.fileColor;

          const relatedProps = __getRelatedProperties(property);
          relatedProps.forEach(p => {
            d[p] = taskChild[p];
          })
          
          summaryTaskChildren.splice(index, 1);
        }
      }
    }
  } 

  //Convert all child tasks (type: task) as properties of immediate parent summary task.
  for (let i = maxSummaryTaskDepth; i > -1; i--) {
    const targetSummaryTasks = summaryTasks.filter(task => task.treeDepth == i);
    if (targetSummaryTasks == null || targetSummaryTasks.length < 1) {
      continue;
    }

    for (let j = 0, jLen = targetSummaryTasks.length; j < jLen; j++) {
      const targetSummaryTask = targetSummaryTasks[j];
      convertChildTaskToProperties(targetSummaryTask, tColumns);
    }
  }

  //Aggregate the value for parent summary task.
  for (let z = maxSummaryTaskDepth; z > -1; z--) {
    const sTasks = summaryTasks.filter(t => t.treeDepth == z) ;

    if (sTasks.length < 1) {
      continue;
    }

    //Get the taskCol properties from first item
    const taskColProps = Object.keys(sTasks[0]).filter(key => key.startsWith('taskcol_'));

    for (const taskColProp of taskColProps) {
      const parentMap = new Map();
      let metadata = null;
      let property = null;
      
      for (const sTask of sTasks) {
        const parent = sTask.parent;
        if (parent == null) {
          continue; //Continue to next iteration when parent is null. No update is required.
        }         
        const propObj = sTask[taskColProp];
        property = propObj.property;
        const single = propObj.single;
        const childTotal = propObj.childTotal;

        if (single == null && childTotal == null) {
          continue; //Continue to next iteration when both single and childTotal are null. No update is required.
        }

        if (metadata == null) {
          metadata = taskColumnMetadataFunc(property);
        }

        let total = metadata.aggregator(single, childTotal);
        //Register childTotal to parent Summary task Map
        if (parentMap.has(parent.uuId)) {
          const parentChildTotal = parentMap.get(parent.uuId);
          parentMap.set(parent.uuId, metadata.aggregator(parentChildTotal, total));
        } else {
          parentMap.set(parent.uuId, total);
        }
      }
      
      if (parentMap.size < 1) {
        continue;
      }
      //Update childTotal to parent summary task
      parentMap.forEach((v, k) => {
        const task = summaryTasks.find(t => t.uuId == k);
        if (task != null) {
          task[taskColProp].childTotal = v;
        }
      });
    }
  }

  return { list: summaryTasks, taskColumns: tColumns };
}

function taskColumnMetadata(self, property) {
  const result = {
    groupColumnDef: {
      menuTabs: ['generalMenuTab'],
      groupId: null, //A unique value is required when it is in used.
      headerName: null, //Please set a proper display name.
      property: property,
      totalMode: false,
      headerClass: 'task-group-header',
      headerGroupComponent: 'taskCompactHeaderGroup',
      headerGroupComponentParams : {
        totalMode: false,
        property: property,
        hasDifferences: false, //Used to determine whether to render the 'sync global changes' button
      },
      marryChildren: true,
      children: [],
    },
    columnDef: null,
    aggregator: null
  }

  const taskColDef = taskColumnDefs(self).get(property);
  result.groupColumnDef.headerName = taskColDef.headerName;
  result.columnDef = cloneDeep(taskColDef.columnDef);
  result.aggregator = taskColDef.aggregator;
  return result;
}

function taskColumnDefs(self) {
  const map = new Map();


  const numberComparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }
    if ((typeof valueA.single !== 'number' && typeof valueA.single !== 'boolean') || 
        (typeof valueB.single !== 'number' && typeof valueB.single !== 'boolean')) {
      return 0; //Skip sorting when the date type of provided values are not string.
    }
    return valueA.single - valueB.single;
  }
  const booleanComparator = numberComparator;

  const stringComparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }
    if (typeof valueA.single !== 'string' || typeof valueB.single !== 'string') {
      return 0; //Skip sorting when the date type of provided values are not string.
    }
    return valueA.single.localeCompare(valueB.single);
  }

  const costComparatorFunc = (totalMode) => {
    return (valueA, valueB) => {
      if (valueA == null && valueB == null) {
        return 0;
      }
      if (valueA == null) {
        return -1;
      }
      if (valueB == null) {
        return 1;
      }

      if (valueA.single != null && typeof valueA.single !== 'number' || valueB.single != null && typeof valueB.single !== 'number') {
        return 0; //Skip sorting when the date type of provided values are not string.
      }
      
      const vA = valueA.single != null? valueA.single : 0;
      const vB = valueB.single != null? valueB.single : 0;
      if (totalMode) {
        const childTotalA = valueA.childTotal != null? valueA.childTotal : 0;
        const childTotalB = valueB.childTotal != null? valueB.childTotal : 0;
        return (childTotalA+vA) - (childTotalB+vB);
      }
      return vA - vB;
    }
  }  

  const durationComparatorFunc = (totalMode) => {
    return (valueA, valueB) => {
      if (valueA == null && valueB == null) {
        return 0;
      }
      if (valueA == null) {
        return -1;
      }
      if (valueB == null) {
        return 1;
      }

      if (valueA.single != null && typeof valueA.single !== 'string' || valueB.single != null && typeof valueB.single !== 'string') {
        return 0; //Skip sorting when the date type of provided values are not string.
      }
      
      const vA = valueA.single != null? parseFloat(valueA.single.substring(0, valueA.single.length - 1)) : 0;
      const vB = valueB.single != null? parseFloat(valueB.single.substring(0, valueB.single.length - 1)) : 0;
      if (totalMode) {
        const childTotalA = valueA.childTotal != null? parseFloat(valueA.childTotal.substring(0, valueA.childTotal.length - 1)) : 0;
        const childTotalB = valueB.childTotal != null? parseFloat(valueB.childTotal.substring(0, valueB.childTotal.length - 1)) : 0;
        return (childTotalA+vA) - (childTotalB+vB);
      }
      return vA - vB;
    }
  }

  const baseCellStyle = {
    'height': '100%',
    'display': 'flex ',
    'align-items': 'center',
    'font-weight': '400'
  };

  const getCellStyle = (params) => {
    if (params == null || params.data == null || self == null || self.coloring == null || params.colDef == null || params.colDef.colId == null) {
      return baseCellStyle;
    }

    if (params.data[params.colDef.colId] == null || params.data[params.colDef.colId].data == null)  {
      return baseCellStyle;
    }

    const vData = params.data[params.colDef.colId].data;
    let color = null;
    if (vData.color && self.coloring.task) {
      color = vData.color;
    }
    else if (vData.stageColor && self.coloring.stage) {
      color = getFirstColor(vData.stageColor);
    }
    else if (vData.rebateColor && self.coloring.rebate) {
      color = getFirstColor(vData.rebateColor);
    }
    else if (vData.fileColor && self.coloring.file) {
      color = getFirstColor(vData.fileColor);
    }
    else if (vData.staffColor && self.coloring.staff) {
      color = getFirstColor(vData.staffColor);
    }
    else if (vData.skillColor && self.coloring.skill) {
      color = getFirstColor(vData.skillColor);
    }
    else if (vData.resourceColor && self.coloring.resource) {
      color = getFirstColor(vData.resourceColor);
    }

    if (color != null) {
      return {
        color: invertColor(color, true), ...baseCellStyle
      }  
    }
    return baseCellStyle;
  }

  const baseColDefTemplate = () => {
    return {
      headerClass: 'task-group-header',
      minWidth: 100,
      width: 100,
      sort: null,
			hide: false,
      cellRenderer: 'taskColCompactRenderer',
      cellRendererParams: {
        totalMode: false,
        displayFormatter: (v) => {
          return v != null? v : '';
        },
        prepareValue: ({ value=null /**, totalMode=false*/ }={}) => {
          //Logic ignores totalMode by default. Customize it for specific column def.
          //totalMode is only used by a small set of column defs: estimatedDuration, fixedCost.
          const rObj = {
            taskId: null,
            value: null,
          }
          const obj = value;
          if (obj) {
            if (obj.uuId != null) {
              rObj.taskId = obj.uuId;
            }
            rObj.value = obj.single;
          }
          return rObj;
        }
      },
      cellEditor: null, //Please override it with proper cellEditor after instantiate this template.
      cellStyle: getCellStyle,
      cellClass: 'task-compact-cell', //Used to set default background color to prevent bg-color set by row style.
      editable: (params) => {
        let taskId = null;
        if (params.data != null && params.column != null && params.data[params.column.colId] != null) {
          taskId = params.data[params.column.colId].uuId;
        }
        return !(self.editing) && ((taskId == null && self.canAdd('TASK')) || (taskId != null && self.canEdit('TASK')));
      },
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      comparator: stringComparator
    }
  }

  const durationPrepareValueFunc = ({ value=null, totalMode=false }={}) => {
    // //value is in miliseconds
    // //Expecting return value to be in display format (e.g '1D') if value is valid. Otherwise, a null value.
    // const rObj = {
    //   taskId: null,
    //   value: null,
    // }
    
    // const obj = value;
    // if (obj) {
    //   if (obj.uuId != null) {
    //     rObj.taskId = obj.uuId;
    //   }
    //   if (totalMode == true) {
    //     if (obj.childTotal == null && obj.single == null) {
    //       rObj.value = null;
    //     } else {
    //       const sum = (obj.childTotal ?? 0) + (obj.single ?? 0);
    //       rObj.value = convertDurationToDisplay(sum / 60000, 'D');
    //     }
    //   } else {
    //     rObj.value = obj.single != null ? convertDurationToDisplay(obj.single / 60000) : null;
    //   }
    // }
    // return rObj;

    const rObj = {
      taskId: null,
      value: null,
    }
    
    const obj = value;
    if (obj) {
      if (obj.uuId != null) {
        rObj.taskId = obj.uuId;
      }
      if (totalMode == true) {
        if (obj.childTotal == null && obj.single == null) {
          rObj.value = null;
        } else {
          const sum = convertDisplayToDuration(obj.childTotal).value + convertDisplayToDuration(obj.single).value;
          rObj.value = convertDurationToDisplay(sum, 'D');
        }
      } else {
        rObj.value = obj.single;
      }
    }
    
    return rObj;
  }
  const durationAggregator = function(valueA, valueB) {
    // //valueA and valueB are in milliseconds. So just sum them up like usual number calculation.
    // const _valA = valueA != null? valueA : 0;
    // const _valB = valueB != null? valueB : 0;
    // return _valA + _valB;

    const _valA = valueA != null? valueA : '0D';
    const _valB = valueB != null? valueB : '0D';
    const sum = convertDisplayToDuration(_valA).value + convertDisplayToDuration(_valB).value;
    return convertDurationToDisplay(sum, 'D');
  }
  //estimatedDuration
  let colDef = baseColDefTemplate();
  colDef.cellEditor = 'durationEditor';
  colDef.cellEditorParams = { compactMode: true }
  colDef.cellRendererParams.prepareValue = durationPrepareValueFunc;
  colDef.cellRendererParams.aggregator = durationAggregator;
  colDef.comparator = durationComparatorFunc(false);
  colDef.comparatorFunc = durationComparatorFunc;
  map.set('estimatedDuration', {
    headerName: self.$t('task.field.estimatedDuration'),
    displayName: self.$t('task.field.estimatedDuration'),
    columnDef: colDef,
    aggregator: durationAggregator
  });

  //fixedDuration
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'durationEditor';
  colDef.cellEditorParams = { compactMode: true }
  colDef.cellRendererParams.prepareValue = durationPrepareValueFunc;
  colDef.cellRendererParams.aggregator = durationAggregator;
  colDef.comparator = durationComparatorFunc(false);
  colDef.comparatorFunc = durationComparatorFunc;
  map.set('fixedDuration', {
    headerName: self.$t('task.field.fixedDuration'),
    displayName: self.$t('task.field.fixedDuration'),
    columnDef: colDef,
    aggregator: durationAggregator
  });

  //totalActualDuration
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'workEffortEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.prepareValue = durationPrepareValueFunc;
  colDef.cellRendererParams.aggregator = durationAggregator;
  colDef.comparator = durationComparatorFunc(false);
  colDef.comparatorFunc = durationComparatorFunc;
  colDef.editable = (params) => {
    return params.colDef != null && params.data[params.colDef.colId] && params.data[params.colDef.colId].uuId != null && !(self.editing) && params.data[params.colDef.colId].data.staffs != null
  }
  map.set('totalActualDuration', {
    headerName: self.$t('task.field.actualDuration'),
    columnDef: colDef,
    aggregator: durationAggregator
  });

  //estimatedTimeToComplete
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'durationEditor';
  colDef.cellEditorParams = { compactMode: true }
  colDef.cellRendererParams.prepareValue = durationPrepareValueFunc;
  colDef.comparator = durationComparatorFunc(false);
  colDef.comparatorFunc = durationComparatorFunc;
  map.set('estimatedTimeToComplete', {
    headerName: self.$t('task.field.estimatedTimeToComplete'),
    columnDef: colDef,
    aggregator: durationAggregator    
  });

  const costPrepareValueFunc = ({ value=null, totalMode=false }={}) => {
    const rObj = {
      taskId: null,
      value: null,
    }
    const obj = value;
    if (obj) {
      if (obj.uuId != null) {
        rObj.taskId = obj.uuId;
      }
      if (totalMode == true) {
        if (obj.childTotal == null && (obj.single == null || obj.single == -1)) {
          rObj.value = null;
        } else {
          const childTotal = obj.childTotal != null? obj.childTotal : 0;
          const single = obj.single != null && obj.single != -1? obj.single : 0;
          rObj.value = childTotal + single;
        }
      } else {
        rObj.value = obj.single != null && obj.single != -1? obj.single : null;
      }
    }
    return rObj;
  }
  const costDisplayFormatter = (v) => {
    if (v && Array.isArray(v)) {
      let display = [];
      for (const val of v) {
        display.push(`$${costFormat(val)}`);
      }
      return display.join(', ');
    }
    return v? `$${costFormat(v)}` : '';
  }  
  const costAggregatorFunc = (valueA, valueB) => {
    const _valA = valueA != null && valueA != -1? valueA : 0;
    const _valB = valueB != null && valueB != -1? valueB : 0;
    return _valA + _valB;
  }
  //fixedCost
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'costEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  map.set('fixedCost', {
    headerName: self.$t('task.field.fixedCost'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //fixedCostNet
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.editable = () => false;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  map.set('fixedCostNet', {
    headerName: self.$t('task.field.fixedCostNet'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //estimatedCost
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.editable = () => false;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  map.set('estimatedCost', {
    headerName: self.$t('task.field.estimatedCost'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //estimatedCostNet
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('estimatedCostNet', {
    headerName: self.$t('task.field.estimatedCostNet'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //actualCost
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('actualCost', {
    headerName: self.$t('task.field.actualCost'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //actualCostNet
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('actualCostNet', {
    headerName: self.$t('task.field.actualCostNet'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //plannedCost
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('plannedCost', {
    headerName: self.$t('task.field.plannedCost'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //plannedCostNet
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('plannedCostNet', {
    headerName: self.$t('task.field.plannedCostNet'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //totalFixedCost
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.editable = () => false;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  map.set('totalFixedCost', {
    headerName: self.$t('task.field.fixedCostTotal'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //totalFixedCostNet
  colDef = baseColDefTemplate();
  colDef.cellRendererParams.prepareValue = costPrepareValueFunc;
  colDef.cellRendererParams.displayFormatter = costDisplayFormatter;
  colDef.comparator = costComparatorFunc(false);
  colDef.comparatorFunc = costComparatorFunc;
  colDef.editable = () => false;
  map.set('totalFixedCostNet', {
    headerName: self.$t('task.field.fixedCostTotalNet'),
    columnDef: colDef,
    aggregator: costAggregatorFunc
  });

  //constraint
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'constraintEditor';
  colDef.cellEditorParams = { options: self.optionConstraint, compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null || v.type == null) {
      return '';
    }
    const type = self.$t(`constraint_type_abbreviation.${v.type}`);
    return v.time == null || v.time == 0? type : `${type} ${moment.utc(v.time).format('YYYY-MM-DD')}`;
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null || valueA.single.type == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null || valueB.single.type == null) {
      return 1;
    }

    if ( typeof valueA.single !== 'object' || typeof valueB.single !== 'object') {
      return 0;
    }

    if (valueA.single.type == null) {
      return -1;
    }

    if (valueB.single.type == null) {
      return 1;
    }

    if (valueA.single.type != valueB.single.type) {
      return valueA.single.type.localeCompare(valueB.single.type);
    }
    if (valueA.single.time == null) {
      return -1;
    }
    if (valueB.single.time == null) {
      return 1;
    }
    return valueA.single.time - valueB.single.time;
  }
  map.set('constraint', {
    headerName: self.$t('task.field.constraint'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //startTime, closeTime
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'dateTimeEditor';
  colDef.cellEditorParams = { 
    compactMode: true
    , labelDate: self.$t('task.field.startTime')
    , optional: true
  }
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null || typeof v !== 'number' || v == 0 || v == '' || v == 9223372036854776000) {
      return '';
    }
    return moment.utc(v).format('L hh:mm A');
  }
  colDef.comparator = numberComparator;
  map.set('startTime', {
    headerName: self.$t('task.field.startTime'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });
  let closeTimeColDef = cloneDeep(colDef)
  closeTimeColDef.cellEditorParams.labelDate = self.$t('task.field.closeTime')
  map.set('closeTime', {
    headerName: self.$t('task.field.closeTime'),
    columnDef: closeTimeColDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //progress
  colDef = baseColDefTemplate();
  colDef.minWidth = 130;
  colDef.width = 130;
  colDef.cellRenderer = 'taskColPercentageCompactRenderer';
  colDef.cellEditor = 'percentageEditor';
  colDef.cellEditorParams = { compactMode: true }
  colDef.cellRendererParams.displayFormatter = (v) => {
    return v !== null ? `${Math.round(v * 100)}%` : v
  }, //Used by export document feature. (Check task.template.util.js for relevant code)
  colDef.comparator = numberComparator;
  map.set('progress', {
    headerName: self.$t('task.field.progress'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //skills
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'skillEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null || !Array.isArray(v)) {
      return '';
    }
    
    const skills = v.map(i => {
      if (i.level != null) {
        return `${i.name} (${i.level})`;
      }
      return i.name;
    });
    return skills.join(', ');
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if (!Array.isArray(valueA.single) || !Array.isArray(valueB.single)) {
      return 0;
    }

    return valueA.single.map(i => `${i.name}${i.level}`).join().localeCompare(
      valueB.single.map(i => `${i.name}${i.level}`).join());
  }
  map.set('skills', {
    headerName: self.$t('task.field.skills'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //staffs
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'staffEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null || !Array.isArray(v)) {
      return '';
    }
    const staffs = v.map(i => {
      if (i.utilization != null && i.utilization < 1 && i.name) {
        return `${i.name} (${Math.round(i.utilization * 100)}%)`;
      }
      return i.name;
    });
    return staffs.join(', ');
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if (!Array.isArray(valueA.single) || !Array.isArray(valueB.single)) {
      return 0;
    }

    return valueA.single.map(i => `${i.name}${i.utilization}`).join().localeCompare(
      valueB.single.map(i => `${i.name}${i.utilization}`).join());
  }
  map.set('staffs', {
    headerName: self.$t('task.field.staffs'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //currencyCode
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'listEditor';
  colDef.cellEditorParams = { options: self.optionCurrency, compactMode: true, isEnumType: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    const found = self.optionCurrency.find(o => o.value === v);
    return found != null? found.text : '';
  }
  map.set('currencyCode', {
    headerName: self.$t('task.field.currencyCode'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //notes
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'commentEditor';
  colDef.cellEditorParams = { entityName: 'TASK', compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    return Array.isArray(v) && v.length > 0 ? v[0].text: null
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if (!Array.isArray(valueA.single) || !Array.isArray(valueB.single)) {
      return 0;
    }

    return valueA.single.map(i => i.text).join().localeCompare(
      valueB.single.map(i => i.text).join());
  }
  map.set('notes', {
    headerName: self.$t('task.field.notes'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //priority
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'listEditor';
  colDef.cellEditorParams = { options: self.optionPriority, compactMode: true, isEnumType: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    const found = self.optionPriority.find(o => o.value === v);
    return found != null? found.text : null;
  }
  map.set('priority', {
    headerName: self.$t('task.field.priority'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //stage
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'stageEditor';
  colDef.cellEditorParams = { options: self.optionStages, compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null) {
      return null;
    }
    const found = self.optionStages.find(i => i.value === v.uuId);
    if (found != null) {
      return found.text;
    }
    return null;
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if ( typeof valueA.single !== 'object' || typeof valueB.single !== 'object') {
      return 0;
    }

    if (valueA.single.name == null) {
      return -1;
    }

    if (valueB.single.name == null) {
      return 1;
    }

    return valueA.single.name.localeCompare(valueB.single.name);
  }
  map.set('stage', {
    headerName: self.$t('task.field.stage'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //complexity
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'listEditor';
  colDef.cellEditorParams = { options: self.optionComplexity, compactMode: true, isEnumType: true };
  map.set('complexity', {
    headerName: self.$t('task.field.complexity'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //plannedProgress
  colDef = baseColDefTemplate();
  colDef.minWidth = 120;
  colDef.width = 120;
  colDef.cellRenderer = 'taskColPercentageCompactRenderer';
  colDef.cellRendererParams.displayFormatter = (v) => {
    return v !== null ? `${Math.round(v * 100)}%` : v
  }, //Used by export document feature. (Check task.template.util.js for relevant code)
  colDef.comparator = numberComparator;
  colDef.editable = () => false;
  map.set('plannedProgress', {
    headerName: self.$t('task.field.plannedProgress'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //resources
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'resourceEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    const list = Array.isArray(v)? v: [];
    const resources = [];
    for(const i of list) {
      resources.push(i.unit != null && i.unit != 1? `${i.name} (${i.unit})`: i.name);
    }
    return resources.join(', ');
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if (!Array.isArray(valueA.single) || !Array.isArray(valueB.single)) {
      return 0;
    }

    return valueA.single.map(i => `${i.name}${i.unit}`).join().localeCompare(
      valueB.single.map(i => `${i.name}${i.unit}`).join());
  }
  map.set('resources', {
    headerName: self.$t('task.field.resources'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //rebates
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'rebateEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    const list = Array.isArray(v)? v: [];
    const rebates = [];
    for(const i of list) {
      rebates.push(i.rebate != null ? `${i.name} (${toFixed(i.rebate*100, 2)}%)`: i.name);
    }
    return rebates.join(', ');
  }
  colDef.comparator = (valueA, valueB) => {
    if (valueA == null && valueB == null) {
      return 0;
    }
    if (valueA == null || valueA.single == null) {
      return -1;
    }
    if (valueB == null || valueB.single == null) {
      return 1;
    }

    if (!Array.isArray(valueA.single) || !Array.isArray(valueB.single)) {
      return 0;
    }

    return valueA.single.map(i => `${i.name}${i.rebate}`).join().localeCompare(
      valueB.single.map(i => `${i.name}${i.rebate}`).join());
  }
  map.set('rebates', {
    headerName: self.$t('task.field.rebates'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //autoScheduling
  colDef = baseColDefTemplate();
  colDef.cellRenderer = 'taskColAutoSchedulingCompactRenderer'
  colDef.cellRendererParams.displayFormatter = (v) => {
    return v;
  }
  colDef.cellEditor = 'listEditor';
  colDef.cellEditorParams = { options: [
      { value: true, text: self.$t('task.autoschedule.auto') },
      { value: false, text: self.$t('task.autoschedule.manual') }
    ], compactMode: true 
  };
  
  colDef.comparator = booleanComparator;
  map.set('autoScheduling', {
    headerName: self.$t('task.field.autoScheduling'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //description
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'multilineEditor';
  colDef.cellEditorParams = { title: self.$t('task.edit_description'), compactMode: true };
  map.set('description', {
    headerName: self.$t('task.field.description'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });
  
  //identifier
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'stringEditor';
  colDef.cellEditorParams = { compactMode: true };
  map.set('identifier', {
    headerName: self.$t('field.identifier_full'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //tag
  colDef = baseColDefTemplate();
  colDef.cellEditor = 'tagEditor';
  colDef.cellEditorParams = { compactMode: true };
  colDef.cellRendererParams.displayFormatter = (v) => {
    if (v == null || !Array.isArray(v)) {
      return '';
    }
    return v.join(', ');
  }
  map.set('tag', {
    headerName: self.$t('field.tag'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });

  //color
  colDef = baseColDefTemplate();
  colDef.cellRenderer = 'taskColColorCompactRenderer';
  colDef.cellEditor = 'colorEditor';
  colDef.cellEditorParams = { compactMode: true };
  map.set('color', {
    headerName: self.$t('field.color'),
    columnDef: colDef,
    aggregator: () => {}, //Set a dummy function. Not in use.
  });
  

  return map;
}




function __getRelatedProperties(property) {
  const durationGroup = ['startTime', 'closeTime', 'duration', 'durationAUM', 'lockDuration', 'autoScheduling', 'constraint'];
  //estimatedDuration needs durationGroup and staffs
  if ('estimatedDuration' == property || durationGroup.includes(property)) {
    return [...durationGroup, 'staffs'];
  }

  const stageGroup = ['stageId', 'stageName'];
  if (stageGroup.includes(property) || property == 'stage') {
    return ['stage', 'tag'];
  }

  const skillGroup = ['skillIds', 'skillNames', 'skillLevels'];
  if (skillGroup.includes(property)) {
    return ['skills'];
  }

  const staffGroup = ['staffIds', 'staffFirstNames', 'staffLastNames', 'staffUtilizations', 'staffDuration', 'staffDurationAUM', 'staffGenericStaff'];
  //totalActualDuration needs staffs
  if ('totalActualDuration' == property || staffGroup.includes(property)) {
    return ['staffs'];
  }

  const resourceGroup = ['resourceIds', 'resourceNames', 'resourceUnits'];
  if (resourceGroup.includes(property)) {
    return ['resources'];
  }

  const noteGroup = ['noteId', 'noteText', 'noteIdentifier', 'noteModified', 'noteFirstName', 'noteLastName', 'noteAuthorRef'];
  if (noteGroup.includes(property)) {
    return ['notes'];
  }

  const rebateGroup = ['rebateUuid', 'rebateName', 'rebateRebate'];
  if (rebateGroup.includes(property)) {
    return ['rebates'];
  }

  const templateGroup = ['templateUuId', 'templateName'];
  if (templateGroup.includes(property)) {
    return ['template'];
  }

  //estimatedTimeToComplete needs durationGroup and staffs
  if ('estimatedTimeToComplete' == property) {
    return [...durationGroup, 'staffs']
  }

  return [property];
}