var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ResourceSelectorModal", {
        attrs: {
          show: _vm.resourceSelectorShow,
          allowNone: true,
          taskUuId: null,
          selectedResource: _vm.resourceUuids,
          enablePagination: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.resourceSelectorShow = $event
          },
          cancel: _vm.resourceSelectorCancel,
          ok: _vm.resourceSelectorOk,
          delete: _vm.resourceSelectorDeleted,
        },
      }),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          _c("ResourceUnitModal", {
            key: "resource-" + index,
            attrs: {
              show: item.show,
              canApplyAll: _vm.toConfirmResources.length > 1,
              uuId: item.uuId,
              name: item.name,
              unit: parseInt(item.unit),
              utilization: parseFloat(item.utilization),
              cData: item,
              edgeName: _vm.edgeName,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmResourceOk,
              cancel: _vm.toConfirmResourceCancel,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }