<template>
  <BlankCenterContainer>
    <b-col md="5">
      <div class="standard-logo-wrapper mx-auto">
          <div id="logo" class="standard-logo" alt="Projectal Logo" border="0" name="logo"> </div>
      </div>
      <b-card class="mx-auto">
        <div class="text-center mb-4">
          <h4>{{ $t('forgot_password.title') }}</h4>
          <p v-if="hasEmail">{{ $t('forgot_password.title_detail') }}</p>
          <p v-else>{{ $t('forgot_password.no_smtp') }}</p>
        </div>
        <b-alert variant="danger" dismissible v-model="errorShow">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>{{ $t('error.attention_required') }}
        </b-alert>
        <b-form v-if="formState === ForgotPasswordFormState.INIT && hasEmail">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <font-awesome-icon :icon="['far', 'envelope']"/>
                </b-input-group-text>
              </b-input-group-prepend>
              <input type="email" class="last-child form-control" id="email" placeholder="Enter Email Address"
                :class="{ 'is-invalid': hasEmailError }" 
                :data-vv-as="$t('forgot_password.field.email')"
                data-vv-name="email"
                data-vv-delay="500"
                v-model="email"
                v-validate="{ required: validate }"
                />
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': hasEmailError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']" />&nbsp;&nbsp;{{ errors.first('email') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-btn class=" btn-primary btn-block" @click.prevent="resetPassword" :disabled="isSubmitDisabled">{{ $t('button.reset_password') }}</b-btn>
        </b-form>
        <div v-if="formState === ForgotPasswordFormState.SUBMIT_SUCCESS" class="alert alert-success text-center" role="alert">
            {{ $t('forgot_password.success') }}
        </div>
        <div v-if="formState === ForgotPasswordFormState.SUBMIT_FAILURE" class="alert alert-danger text-center" role="alert">
            {{ $t('forgot_password.failure') }}
        </div>
        <div v-if="formState === ForgotPasswordFormState.SUBMIT_FAILURE_TOO_MANY" class="alert alert-danger text-center" role="alert">
            {{ $t('forgot_password.failure_too_many', [retryAfter]) }}
        </div>
        <div class="text-center">
          <router-link class="d-block small mt-3" to="/login">{{ $t('link.login_page') }}</router-link>
        </div>
      </b-card>
      <div class="auth-footer mt-3 text-center">{{ $t('footer_disclaimer', [(new Date()).getFullYear(), versionNumber]) }}</div>
    </b-col>
  </BlankCenterContainer>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { authenticationService } from '@/services'
import BlankCenterContainer from '@/views/layout/BlankCenterContainer'

export default {
  name: 'ForgotPassword',
  components: {
    BlankCenterContainer,
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      formState: null,
      email: null,
      errorShow: false,
      versionNumber: null,
      validate: false,
      retryAfter: 0
    }
  },
  created() {
    this.validate = true;
    // Get version number for footer
    const self = this;
    this.$store.dispatch('data/status').then(value => {
      self.versionNumber = value.version;
      document.title = self.$t('title_with_company', [value.companyName]);
    })
    .catch(() => {
      this.versionNumber = "N/A";
      document.title = self.$t('title');
    });
    
    this.fieldValidateUtil = fieldValidateUtil;
    this.ForgotPasswordFormState = Object.freeze({
      INIT: 1,
      SUBMIT_SUCCESS: 4,
      SUBMIT_FAILURE: 5,
      SUBMIT_FAILURE_TOO_MANY: 6
    })
    this.formState = this.ForgotPasswordFormState.INIT;
  },
  beforeUnmount() {
    this.validate = false;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.ForgotPasswordFormState = null;
  },
  computed: {
    hasEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'email');
    },
    isSubmitDisabled() {
      return !this.email || !!this.errors.first('email');
    }, 
    hasEmail() {
      return this.$store.state.data.status.smtpEnabled;
    }
  },
  methods: {
    resetPassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.errorShow = false;
          authenticationService.forgotPassword(this.email)
          .then(() => {
            this.formState = this.ForgotPasswordFormState.SUBMIT_SUCCESS;
          })
          .catch((e) => {
            if (e.response.status === 429) {
              this.formState = this.ForgotPasswordFormState.SUBMIT_FAILURE_TOO_MANY;
              this.retryAfter = e.response.headers['x-rate-limit-retry-after-seconds'];
            }
            else {
              this.formState = this.ForgotPasswordFormState.SUBMIT_FAILURE;
            }
          });
        } else {
          this.errorShow = true;
        }
      });
    }
  }
};
</script>

