var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SkillSelectorModal", {
        attrs: {
          show: _vm.skillSelectorShow,
          allowNone: true,
          taskUuId: null,
          selectedSkill: _vm.skillUuids,
          enablePagination: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.skillSelectorShow = $event
          },
          cancel: _vm.skillSelectorCancel,
          ok: _vm.skillSelectorOk,
          delete: _vm.skillSelectorDeleted,
        },
      }),
      _vm._l(_vm.toConfirmSkills, function (item, index) {
        return [
          _c("SkillLevelModal", {
            key: "skill-" + index,
            attrs: {
              show: item.show,
              canApplyAll: _vm.toConfirmSkills.length > 1,
              uuId: item.uuId,
              name: item.name,
              level: item.level,
              cData: item,
              edgeName: _vm.edgeName,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmSkillOk,
              cancel: _vm.toConfirmSkillCancel,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }