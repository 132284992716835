var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("RebateSelectorModal", {
        attrs: {
          show: _vm.rebateSelectorShow,
          selectedRebate: _vm.rebateUuids,
          allowNone: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.rebateSelectorShow = $event
          },
          cancel: _vm.rebateSelectorCancel,
          ok: _vm.rebateSelectorOk,
          delete: _vm.rebateSelectorDeleted,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }