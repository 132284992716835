var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn dataview-container" },
    [
      _vm.alertMsg != null
        ? _c("AlertFeedback", {
            staticClass: "mb-0",
            attrs: {
              msg: _vm.alertMsg,
              details: _vm.alertMsgDetails.list,
              detailTitle: _vm.alertMsgDetails.title,
              alertState: _vm.alertState,
            },
            on: { resetAlert: _vm.resetAlert },
          })
        : _vm._e(),
      !_vm.isWidget
        ? _c("div", { staticClass: "data-view-navbar" }, [
            _c(
              "nav",
              { staticClass: "data-view-nav" },
              [
                _c(
                  "PriorityNavigation",
                  { attrs: { ulClassName: "nav nav-pills" } },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "nav-pills nav-link active",
                        attrs: { name: "sheet" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.dataViewNavClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          { attrs: { href: "#", target: "_self" } },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "th-list"] },
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("dataview.sheet"))),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.isEntity("TASK") && _vm.showGantt
                      ? _c(
                          "li",
                          {
                            staticClass: "nav-pills nav-link",
                            attrs: { name: "gantt" },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.prepareGantt()
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                  return _vm.dataViewNavClick.apply(
                                    null,
                                    arguments
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: "#", target: "_self" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "stream"] },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("dataview.gantt"))),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    (_vm.isEntity("TASK") ||
                      _vm.isEntity("STAFF") ||
                      _vm.isEntity("DEPARTMENT") ||
                      _vm.isEntity("LOCATION") ||
                      _vm.isEntity("COMPANY") ||
                      _vm.isEntity("SKILL")) &&
                    _vm.showStaff &&
                    _vm.canView("STAFF")
                      ? _c(
                          "li",
                          {
                            staticClass: "nav-pills nav-link",
                            attrs: { name: "staff-usage" },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.prepareStaff()
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                  return _vm.dataViewNavClick.apply(
                                    null,
                                    arguments
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: "#", target: "_self" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "user-hard-hat"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("dataview.staff_usage"))
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    (_vm.isEntity("TASK") ||
                      _vm.isEntity("RESOURCE") ||
                      _vm.isEntity("COMPANY")) &&
                    _vm.showResource &&
                    _vm.canView("RESOURCE")
                      ? _c(
                          "li",
                          {
                            staticClass: "nav-pills nav-link",
                            attrs: { name: "resource-usage" },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.prepareResource()
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                  return _vm.dataViewNavClick.apply(
                                    null,
                                    arguments
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: "#", target: "_self" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "cart-flatbed-boxes"],
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("dataview.resource_usage"))
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isEntity("TASK") && _vm.showBoard
                      ? _c(
                          "li",
                          {
                            staticClass: "nav-pills nav-link",
                            attrs: { name: "board" },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.prepareBoard()
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                  return _vm.dataViewNavClick.apply(
                                    null,
                                    arguments
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: "#", target: "_self" } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "th-large"] },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("dataview.board"))),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._l(_vm.charts, function (chart) {
                      return _c(
                        "li",
                        {
                          key: chart.id,
                          staticClass: "nav-pills nav-link",
                          attrs: { name: "chart_" + chart.id },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.dataViewNavClick.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: "#", target: "_self" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "chart-bar"] },
                              }),
                              _vm._v(" " + _vm._s(chart.name)),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            (_vm.canView() && _vm.editPermission) ||
            _vm.canAdd() ||
            (_vm.canDelete() && _vm.editPermission) ||
            (_vm.canEdit() &&
              _vm.dataview !== null &&
              _vm.dataview.editingPermissions === "") ||
            (_vm.canEdit() && _vm.editPermission) ||
            (_vm.canEdit() && _vm.editPermission)
              ? _c(
                  "div",
                  { staticClass: "menu-toggler" },
                  [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          id: "ddown-offset",
                          variant: "link",
                          offset: "25",
                          "no-caret": "",
                        },
                      },
                      [
                        _c("template", { slot: "button-content" }, [
                          _c(
                            "div",
                            { staticClass: "text" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "ellipsis-vertical"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        [
                          _vm.canView() && _vm.editPermission
                            ? _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.editDataview(_vm.dataview)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("dataview.edit")))]
                              )
                            : _vm._e(),
                          _vm.canAdd()
                            ? _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyDataview(_vm.dataview)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("dataview.copy")))]
                              )
                            : _vm._e(),
                          _vm.canView() &&
                          _vm.canResetDefault &&
                          !_vm.editPermission
                            ? _c(
                                "b-dropdown-item",
                                { on: { click: _vm.defaultSettings } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.reset_default"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.canDelete() && _vm.editPermission
                            ? _c(
                                "b-dropdown-item",
                                { on: { click: _vm.removeDataview } },
                                [_vm._v(_vm._s(_vm.$t("dataview.delete")))]
                              )
                            : _vm._e(),
                          _vm.canEdit() && _vm.editPermission
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    download: _vm.dataview
                                      ? _vm.dataview.name + ".json"
                                      : "dataview.json",
                                    href: _vm.exportJson,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("dataview.export")))]
                              )
                            : _vm._e(),
                          _vm.canEdit() &&
                          _vm.dataview !== null &&
                          _vm.dataview.editingPermissions === ""
                            ? _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.takeOwnership(_vm.dataview)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("dataview.assign_edit_permissions")
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.canEdit() && _vm.editPermission
                            ? _c("b-dropdown-divider")
                            : _vm._e(),
                          _vm.canEdit() && _vm.editPermission
                            ? _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.createChart()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.create_chart"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                      ],
                      2
                    ),
                    _c("b-popover", {
                      attrs: {
                        target: "ddown-offset",
                        placement: "top",
                        boundary: "viewport",
                        triggers: "hover",
                        content: _vm.$t("dataview.more"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.showExistenceError
        ? _c("div", { staticClass: "existence-error" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.error.existence")) + " "),
          ])
        : _vm.id !== null && !_vm.showPermissionsError
        ? _c(
            "b-tabs",
            {
              class: !_vm.isWidget ? "tab-container" : "widget-container",
              attrs: {
                "active-nav-item-class": "active",
                "content-class": "pt-3 w-100 dataview-tab-content",
                pills: "",
              },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              !_vm.isWidget || _vm.dataviewComponent === "sheet"
                ? _c(
                    "b-tab",
                    {
                      attrs: { title: _vm.$t("dataview.sheet") },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "table-list"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("dataview.sheet")) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2585398906
                      ),
                    },
                    [
                      _c("div", { staticClass: "flex-container" }, [
                        _vm.allowManage && !_vm.isWidget
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "grid-toolbar dataview-toolbar border",
                              },
                              [
                                _vm.datesMode !== "current"
                                  ? _c(
                                      "PriorityNavigation",
                                      {
                                        staticClass:
                                          "d-flex time-toolbar border-bottom",
                                      },
                                      [
                                        _c("li", [
                                          !_vm.epoch &&
                                          _vm.startDate &&
                                          _vm.endDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center ml-2 mr-2",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "dataview.chart.data"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("b-form-select", {
                                                    staticClass:
                                                      "mw-150 minw-170",
                                                    attrs: {
                                                      id: "fields",
                                                      options: _vm.fieldOptions,
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.fieldSelected()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.timeseries_field,
                                                      callback: function ($$v) {
                                                        _vm.timeseries_field =
                                                          $$v
                                                      },
                                                      expression:
                                                        "timeseries_field",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("li", [
                                          !_vm.epoch &&
                                          _vm.startDate &&
                                          _vm.endDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-v-divider",
                                                  attrs: { readonly: "" },
                                                },
                                                [_vm._v("|")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("li", [
                                          _vm.epoch &&
                                          _vm.startDate &&
                                          _vm.endDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center ml-2 mr-2",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.spanPrefix)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "BTN_LEFT_EPOCH_" +
                                                          _vm.id,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.loading ||
                                                              _vm.disableEpochLeft,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.leftEpoch,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "chevron-circle-left",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "BTN_LEFT_EPOCH_" +
                                                            _vm.id,
                                                          placement: "top",
                                                          boundary: "viewport",
                                                          triggers: "hover",
                                                          content:
                                                            _vm.$t(
                                                              "dataview.previous"
                                                            ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("b-form-select", {
                                                    staticClass:
                                                      "mw-150 minw-170",
                                                    attrs: {
                                                      id: "epochs",
                                                      options: _vm.epochOptions,
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.epochSelected()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.epoch,
                                                      callback: function ($$v) {
                                                        _vm.epoch = $$v
                                                      },
                                                      expression: "epoch",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "BTN_RIGHT_EPOCH_" +
                                                          _vm.id,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-btn",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.loading ||
                                                              _vm.disableEpochRight,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.rightEpoch,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "chevron-circle-right",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "BTN_RIGHT_EPOCH_" +
                                                            _vm.id,
                                                          placement: "top",
                                                          boundary: "viewport",
                                                          triggers: "hover",
                                                          content:
                                                            _vm.$t(
                                                              "dataview.next"
                                                            ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("li", [
                                          _vm.epoch &&
                                          _vm.startDate &&
                                          _vm.endDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "action-v-divider",
                                                  attrs: { readonly: "" },
                                                },
                                                [_vm._v("|")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-items-center ml-2 mr-2",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { for: "dates" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("staff.dates")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("b-form-select", {
                                                staticClass: "mw-150 minw-170",
                                                attrs: {
                                                  id: "dates",
                                                  options:
                                                    _vm.filteredDateOptions,
                                                  size: "sm",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.rangeSelected()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dates,
                                                  callback: function ($$v) {
                                                    _vm.dates = $$v
                                                  },
                                                  expression: "dates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-1 date",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { for: "startDate" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("staff.from"))
                                                  ),
                                                ]
                                              ),
                                              _c("b-form-datepicker", {
                                                staticClass:
                                                  "date-picker d-flex",
                                                attrs: {
                                                  id: "startDate",
                                                  "today-button": "",
                                                  "reset-button": "",
                                                  "close-button": "",
                                                  "hide-header": "",
                                                  "label-today-button":
                                                    _vm.$t("date.today"),
                                                  "label-reset-button":
                                                    _vm.$t("date.reset"),
                                                  "label-close-button":
                                                    _vm.$t("date.close"),
                                                  "today-button-variant":
                                                    "primary",
                                                  "reset-button-variant":
                                                    "danger",
                                                  "close-button-variant":
                                                    "secondary",
                                                  size: "sm",
                                                  max: _vm.maxDate,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.daySelected()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.startDate,
                                                  callback: function ($$v) {
                                                    _vm.startDate = $$v
                                                  },
                                                  expression: "startDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-1 date",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { for: "endDate" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("staff.to"))
                                                  ),
                                                ]
                                              ),
                                              _c("b-form-datepicker", {
                                                staticClass:
                                                  "date-picker d-flex",
                                                attrs: {
                                                  id: "endDate",
                                                  "today-button": "",
                                                  "reset-button": "",
                                                  "close-button": "",
                                                  "hide-header": "",
                                                  "label-today-button":
                                                    _vm.$t("date.today"),
                                                  "label-reset-button":
                                                    _vm.$t("date.reset"),
                                                  "label-close-button":
                                                    _vm.$t("date.close"),
                                                  "today-button-variant":
                                                    "primary",
                                                  "reset-button-variant":
                                                    "danger",
                                                  "close-button-variant":
                                                    "secondary",
                                                  size: "sm",
                                                  max: _vm.maxDate,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.daySelected()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.endDate,
                                                  callback: function ($$v) {
                                                    _vm.endDate = $$v
                                                  },
                                                  expression: "endDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-1",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { for: "timescale" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("staff.timescale")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("b-form-select", {
                                                staticClass: "mw-150",
                                                attrs: {
                                                  id: "timescale",
                                                  options: _vm.spanOptions,
                                                  size: "sm",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateSpan()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.span,
                                                  callback: function ($$v) {
                                                    _vm.span = $$v
                                                  },
                                                  expression: "span",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                id: "BTN_USE_EPOCH_" + _vm.id,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  style: _vm.useEpoch
                                                    ? "color: #E77615"
                                                    : "",
                                                  attrs: {
                                                    disabled: _vm.loading,
                                                  },
                                                  on: {
                                                    click: _vm.toggleEpoch,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "history"],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("b-popover", {
                                                attrs: {
                                                  target:
                                                    "BTN_USE_EPOCH_" + _vm.id,
                                                  placement: "top",
                                                  boundary: "viewport",
                                                  triggers: "hover",
                                                  content: _vm.useEpoch
                                                    ? _vm.$t(
                                                        "dataview.timemachine_on"
                                                      )
                                                    : _vm.$t(
                                                        "dataview.timemachine_off"
                                                      ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "second-row-toolbar" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-block" },
                                      [
                                        _c("b-form-checkbox", {
                                          staticClass:
                                            "secondary-checkbox select-state",
                                          attrs: {
                                            disabled: _vm.loading,
                                            id: "BTN_SELECT_" + _vm.id,
                                            indeterminate:
                                              _vm.select_state.indeterminate,
                                          },
                                          on: { change: _vm.selectionChanged },
                                          model: {
                                            value: _vm.select_state.checked,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.select_state,
                                                "checked",
                                                $$v
                                              )
                                            },
                                            expression: "select_state.checked",
                                          },
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "BTN_SELECT_" + _vm.id,
                                              triggers: "hover",
                                              placement: "top",
                                              boundary: "viewport",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("button.select")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.canAdd(_vm.entityName) &&
                                    !(
                                      _vm.entityName == "TASK" &&
                                      !_vm.canList("PROJECT")
                                    ) &&
                                    _vm.entityName !== "NOTE"
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-popover", {
                                              attrs: {
                                                target: "BTN_ADD_" + _vm.id,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t("button.add"),
                                              },
                                            }),
                                            _c(
                                              "b-btn",
                                              {
                                                attrs: {
                                                  disabled: _vm.loading,
                                                  id: "BTN_ADD_" + _vm.id,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editOpen(true)
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--grid-toolbar-button)",
                                                  },
                                                  attrs: {
                                                    icon: ["far", "plus"],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.canView(_vm.entityName)
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-popover", {
                                              attrs: {
                                                target: "BTN_EDIT_" + _vm.id,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t("button.edit"),
                                              },
                                            }),
                                            _c(
                                              "b-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.disableEdit ||
                                                    _vm.loading,
                                                  id: "BTN_EDIT_" + _vm.id,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editOpen(false)
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "pen-to-square",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.canDelete(_vm.entityName)
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-popover", {
                                              attrs: {
                                                target: "BTN_DELETE_" + _vm.id,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content:
                                                  _vm.$t("button.delete"),
                                              },
                                            }),
                                            _c(
                                              "b-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.disableDelete ||
                                                    _vm.loading,
                                                  id: "BTN_DELETE_" + _vm.id,
                                                },
                                                on: { click: _vm.rowDelete },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "trash-can"],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "action-v-divider",
                                        attrs: { readonly: "" },
                                      },
                                      [_vm._v("|")]
                                    ),
                                    _c(
                                      "span",
                                      [
                                        _c("b-popover", {
                                          attrs: {
                                            target:
                                              "BTN_EXPORT_DOCUMENT_" + _vm.id,
                                            placement: "top",
                                            boundary: "viewport",
                                            triggers: "hover",
                                            content: _vm.$t(
                                              "task.button.export_document"
                                            ),
                                          },
                                        }),
                                        _c(
                                          "b-btn",
                                          {
                                            attrs: {
                                              disabled: _vm.loading,
                                              id:
                                                "BTN_EXPORT_DOCUMENT_" + _vm.id,
                                            },
                                            on: { click: _vm.fileExport },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "inbox-out"],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isEntity("TASK")
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-popover", {
                                              attrs: {
                                                target:
                                                  "BTN_AUTO_ASSIGN_STAFF_" +
                                                  _vm.id,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "task.button.auto_assign_staff"
                                                ),
                                              },
                                            }),
                                            _c(
                                              "b-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.autoAssignTasks()
                                                      .length === 0 ||
                                                    _vm.inProgressShow,
                                                  id:
                                                    "BTN_AUTO_ASSIGN_STAFF_" +
                                                    _vm.id,
                                                },
                                                on: {
                                                  click: _vm.autoAssignStaff,
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "user-helmet-safety",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        on: _vm._d(
                                          { mouseleave: _vm.onTimeModeLeave },
                                          [
                                            _vm.timeModeMouseEnterEvent,
                                            _vm.onTimeModeOver,
                                          ]
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            ref: "timeMode",
                                            staticClass: "action-bar-dropdown",
                                            attrs: {
                                              id: "BTN_TIME_MODE_" + _vm.id,
                                              "toggle-class":
                                                "text-decoration-none",
                                              "no-caret": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "analytics",
                                                          ],
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              3949019179
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-group",
                                              {
                                                attrs: {
                                                  header: _vm.$t(
                                                    "dataview.date_settings"
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: _vm.onCurrent,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "action-item-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "dataview.current"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.datesMode === "current"
                                                      ? _c(
                                                          "font-awesome-icon",
                                                          {
                                                            staticClass:
                                                              "active-check",
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "check",
                                                              ],
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: _vm.onActuals,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "action-item-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "dataview.actuals"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.datesMode === "actuals"
                                                      ? _c(
                                                          "font-awesome-icon",
                                                          {
                                                            staticClass:
                                                              "active-check",
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "check",
                                                              ],
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: _vm.onTimeSeries,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "action-item-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "dataview.timeseries"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.datesMode ===
                                                    "timeseries"
                                                      ? _c(
                                                          "font-awesome-icon",
                                                          {
                                                            staticClass:
                                                              "active-check",
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "check",
                                                              ],
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dataviewLive !== null && _vm.dataviewLive.query
                          ? _c(
                              "div",
                              { staticClass: "live-edit-toolbar" },
                              [
                                _vm._l(
                                  _vm.dataviewLive.query.children,
                                  function (item, itemIndex) {
                                    return [
                                      item.allowEditing
                                        ? _c(
                                            "div",
                                            {
                                              key: itemIndex,
                                              staticClass:
                                                "d-flex align-items-center m-1",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex rule-part",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.displayName
                                                        ? item.displayName
                                                        : item.field !== null &&
                                                          item.field !== ""
                                                        ? _vm.formatField(item)
                                                        : _vm.$t(
                                                            "dataview.placeholder.field"
                                                          )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex ml-2 mr-2 rule-part",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataview.operator." +
                                                          item.operator
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("FilterInput", {
                                                attrs: {
                                                  schema: _vm.schema,
                                                  macros: _vm.macros,
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataviewLive.query
                                                      .children[itemIndex],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataviewLive.query
                                                        .children,
                                                      itemIndex,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataviewLive.query.children[itemIndex]",
                                                },
                                              }),
                                              _c(
                                                "b-btn",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    disabled: _vm.checkQuery,
                                                    id:
                                                      "BTN_RECALCULATE_" +
                                                      _vm.id +
                                                      "_" +
                                                      itemIndex,
                                                  },
                                                  on: {
                                                    click: _vm.recalculate,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "arrows-rotate",
                                                      ],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target:
                                                        "BTN_RECALCULATE_" +
                                                        _vm.id +
                                                        "_" +
                                                        itemIndex,
                                                      placement: "top",
                                                      boundary: "viewport",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "dataview.recalculate"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticStyle: { flex: "1" } },
                          [
                            _vm.dataview
                              ? _c("ag-grid-vue", {
                                  staticClass: "ag-theme-balham",
                                  class: _vm.isWidget
                                    ? ""
                                    : "dataview-grid-height",
                                  style: _vm.isWidget
                                    ? "height: " +
                                      _vm.height +
                                      "px;width: 100%;"
                                    : "width: 100%;",
                                  attrs: {
                                    id: "dataview-grid",
                                    gridOptions: _vm.gridOptions,
                                    columnDefs: _vm.columnDefs,
                                    context: _vm.context,
                                    defaultColDef: _vm.defaultColDef,
                                    pagination: "",
                                    paginationPageSize: 1000,
                                    getRowId: function (params) {
                                      return params.data.index
                                    },
                                    suppressContextMenu: "true",
                                    rowSelection: "multiple",
                                    rowMultiSelectWithClick: "",
                                    overlayNoRowsTemplate:
                                      _vm.overlayNoRowsTemplate,
                                    serverSideInfiniteScroll: true,
                                    sideBar: false,
                                    suppressDragLeaveHidesColumns: "",
                                    suppressCellFocus: "",
                                    suppressMultiSort: "",
                                    tooltipShowDelay: "0",
                                    cacheBlockSize: "1000",
                                  },
                                  on: { "grid-ready": _vm.onGridReady },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isEntity("TASK") &&
              _vm.showGantt &&
              (!_vm.isWidget || _vm.dataviewComponent === "gantt")
                ? _c(
                    "b-tab",
                    {
                      attrs: { title: _vm.$t("dataview.gantt"), lazy: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "bars-staggered"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("dataview.gantt")) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4014018195
                      ),
                    },
                    [
                      _c("Gantt", {
                        style: { marginBottom: "8px" },
                        attrs: {
                          isDataView: true,
                          isWidget: _vm.isWidget,
                          dataviewId: _vm.dataviewId,
                          widgetOwner: _vm.widgetOwner,
                          height: _vm.height,
                          heightOffset: 2,
                          taskIds: _vm.taskIds,
                          loading: _vm.loading,
                        },
                        on: {
                          gridGanttError: _vm.onGanttError,
                          ganttMsg: _vm.onGanttMsg,
                          ganttResetAlert: _vm.onGanttResetAlert,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.isEntity("TASK") ||
                _vm.isEntity("STAFF") ||
                _vm.isEntity("DEPARTMENT") ||
                _vm.isEntity("LOCATION") ||
                _vm.isEntity("COMPANY") ||
                _vm.isEntity("SKILL")) &&
              _vm.showStaff &&
              (!_vm.isWidget || _vm.dataviewComponent === "staff_usage")
                ? _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("dataview.staff_usage"),
                        lazy: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "user-helmet-safety"],
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("dataview.staff_usage")) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1750593352
                      ),
                    },
                    [
                      _c("PlannerStaff", {
                        attrs: {
                          isWidget: _vm.isWidget,
                          widgetOwner: _vm.widgetOwner,
                          height: _vm.height,
                          dataviewId: _vm.dataviewId,
                          projectId: _vm.projectId,
                          staffIds: _vm.staffIds,
                          heightOffset: 235,
                          heightOffset2: 285,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.isEntity("TASK") || _vm.isEntity("RESOURCE")) &&
              _vm.showResource &&
              (!_vm.isWidget || _vm.dataviewComponent === "resource_usage")
                ? _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("dataview.staff_usage"),
                        lazy: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: ["far", "cart-flatbed-boxes"],
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("dataview.resource_usage")) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2741396943
                      ),
                    },
                    [
                      _c("PlannerResource", {
                        attrs: {
                          isWidget: _vm.isWidget,
                          widgetOwner: _vm.widgetOwner,
                          height: _vm.height,
                          dataviewId: _vm.dataviewId,
                          projectId: _vm.projectId,
                          resourceIds: _vm.resourceIds,
                          heightOffset: 235,
                          heightOffset2: 285,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEntity("TASK") &&
              _vm.showBoard &&
              (!_vm.isWidget || _vm.dataviewComponent === "board")
                ? _c(
                    "b-tab",
                    {
                      attrs: { title: _vm.$t("dataview.board"), lazy: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "table-cells-large"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("dataview.board")) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1730167880
                      ),
                    },
                    [
                      _c("KanbanBoard", {
                        attrs: {
                          isWidget: _vm.isWidget,
                          height: _vm.height,
                          taskIds: _vm.taskIds,
                          dataview: _vm.dataview,
                          heightOffset: 209,
                          idsLoading: _vm.loading,
                        },
                        on: { profileChanged: _vm.onKanbanProfileChanged },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.chartList, function (chart, index) {
                return _c(
                  "b-tab",
                  {
                    key: chart.id,
                    attrs: { title: chart.name, lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "chart-bar"] },
                              }),
                              _vm._v(" " + _vm._s(chart.name) + " "),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "overflow-auto" },
                      [
                        _c("Chart", {
                          attrs: {
                            schema: _vm.schema,
                            chart: chart,
                            dataview: _vm.dataview,
                            allowManage: _vm.allowManage,
                            editPermission: _vm.editPermission,
                            entityName: _vm.entityName,
                            isWidget: _vm.isWidget,
                            height: _vm.height,
                            width: _vm.width,
                          },
                          on: {
                            editChart: function ($event) {
                              return _vm.editChart(index)
                            },
                            removeChart: function ($event) {
                              return _vm.removeChart(index)
                            },
                            chartExport: function ($event) {
                              return _vm.chartExport(
                                _vm.dataview.name + " - " + chart.name
                              )
                            },
                            updateChart: _vm.updateChart,
                            error: _vm.onChartError,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _c("div", { staticClass: "permission-error" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.error.permission")) + " "),
          ]),
      _vm.addShow
        ? _c("DataviewModal", {
            attrs: {
              show: _vm.addShow,
              userId: _vm.userId,
              folders: _vm.folders,
              data: _vm.data,
              isPublic: _vm.isPublic,
            },
            on: {
              "update:show": function ($event) {
                _vm.addShow = $event
              },
              success: _vm.filterCreated,
            },
          })
        : _vm._e(),
      _vm.inProgressShow
        ? _c("InProgressModal", {
            attrs: {
              show: _vm.inProgressShow,
              label: _vm.inProgressLabel,
              isStopable: _vm.inProgressStoppable,
            },
            on: {
              "update:show": function ($event) {
                _vm.inProgressShow = $event
              },
            },
          })
        : _vm._e(),
      _vm.projectSelectorShow
        ? _c("ProjectSelectorModal", {
            attrs: { show: _vm.projectSelectorShow },
            on: {
              "update:show": function ($event) {
                _vm.projectSelectorShow = $event
              },
              ok: _vm.projectSelectorOk,
            },
          })
        : _vm._e(),
      _vm.projectTemplateSelectorShow
        ? _c("TaskTemplateSelectorModal", {
            attrs: { show: _vm.projectTemplateSelectorShow },
            on: {
              "update:show": function ($event) {
                _vm.projectTemplateSelectorShow = $event
              },
              ok: _vm.projectTemplateSelectorOk,
            },
          })
        : _vm._e(),
      _c("ActivityModal", {
        attrs: {
          show: _vm.activityEditShow,
          mode: _vm.activityEdit.mode,
          id: _vm.activityEdit.uuId,
        },
        on: {
          "update:show": function ($event) {
            _vm.activityEditShow = $event
          },
          success: _vm.modalSuccess,
          "update:id": function ($event) {
            return _vm.$set(_vm.activityEdit, "uuId", $event)
          },
        },
      }),
      _c("BookingModal", {
        attrs: {
          show: _vm.bookingEditShow,
          mode: _vm.bookingEdit.mode,
          id: _vm.bookingEdit.uuId,
        },
        on: {
          "update:show": function ($event) {
            _vm.bookingEditShow = $event
          },
          success: _vm.modalSuccess,
          "update:id": function ($event) {
            return _vm.$set(_vm.bookingEdit, "uuId", $event)
          },
        },
      }),
      _c("TaskModal", {
        attrs: {
          show: _vm.taskEditShow,
          mode: _vm.mode,
          id: _vm.taskEdit.uuId,
          projectId: _vm.taskProjectId,
          projectName: _vm.projectName,
          parentId: _vm.taskEdit.parentId,
        },
        on: {
          "update:show": function ($event) {
            _vm.taskEditShow = $event
          },
          success: _vm.modalSuccess,
          "update:id": function ($event) {
            return _vm.$set(_vm.taskEdit, "uuId", $event)
          },
        },
      }),
      _vm.taskTemplateEdit.uuId
        ? _c("TaskModal", {
            attrs: {
              show: _vm.taskTemplateEditShow,
              mode: _vm.mode,
              id: _vm.taskTemplateEdit.uuId,
              projectId: _vm.taskProjectId,
              projectName: _vm.projectName,
              parentId: _vm.taskTemplateEdit.parentId,
              isTemplate: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.taskTemplateEditShow = $event
              },
              success: _vm.modalSuccess,
              "update:id": function ($event) {
                return _vm.$set(_vm.taskTemplateEdit, "uuId", $event)
              },
            },
          })
        : _vm._e(),
      _vm.projectShow
        ? _c("ProjectModal", {
            attrs: {
              id: _vm.projectId,
              show: _vm.projectShow,
              title: _vm.projectTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.projectShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.projectTemplateShow
        ? _c("TaskTemplateModal", {
            attrs: {
              id: _vm.projectTemplateId,
              show: _vm.projectTemplateShow,
              title: _vm.templateTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.projectTemplateShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.customerShow
        ? _c("CustomerModal", {
            attrs: {
              id: _vm.customerId,
              show: _vm.customerShow,
              title: _vm.customerTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.customerShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.contactShow
        ? _c("ContactModal", {
            attrs: {
              id: _vm.contactId,
              holderId: _vm.contactParentId,
              show: _vm.contactShow,
              title: _vm.contactTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.contactShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.customerSelectorShow
        ? _c("CustomerSelectorModal", {
            attrs: { show: _vm.customerSelectorShow },
            on: {
              "update:show": function ($event) {
                _vm.customerSelectorShow = $event
              },
              ok: _vm.customerSelectorOk,
            },
          })
        : _vm._e(),
      _vm.departmentShow
        ? _c("DepartmentModal", {
            attrs: {
              id: _vm.departmentId,
              queryParent: true,
              parentData: _vm.selectedParent,
              show: _vm.departmentShow,
              title: _vm.departmentTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.departmentShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.locationShow
        ? _c("LocationModal", {
            attrs: {
              id: _vm.locationId,
              show: _vm.locationShow,
              title: _vm.locationTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.locationShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.resourceShow
        ? _c("ResourceModal", {
            attrs: {
              id: _vm.resourceId,
              show: _vm.resourceShow,
              title: _vm.resourceTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.resourceShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.skillShow
        ? _c("SkillModal", {
            attrs: {
              id: _vm.skillId,
              show: _vm.skillShow,
              title: _vm.skillTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.skillShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.staffShow
        ? _c("StaffModal", {
            attrs: {
              id: _vm.staffId,
              show: _vm.staffShow,
              isGeneric: _vm.isGeneric,
              title: _vm.staffTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.staffShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.userShow
        ? _c("UserModal", {
            attrs: {
              id: _vm.userModalId,
              show: _vm.userShow,
              title: _vm.userTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.userShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _vm.stageShow
        ? _c("StageModal", {
            attrs: {
              id: _vm.stageId,
              show: _vm.stageShow,
              title: _vm.stageTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.stageShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("FileDetailsModal", {
        attrs: {
          id: _vm.fileDetailsId,
          parentData: _vm.fileParentData,
          show: _vm.fileDetailsShow,
          mode: _vm.mode,
        },
        on: {
          "update:show": function ($event) {
            _vm.fileDetailsShow = $event
          },
          success: _vm.modalSuccess,
        },
      }),
      _c("ChartModal", {
        attrs: {
          show: _vm.promptChartOptions,
          data: _vm.chart,
          schema: _vm.schema,
          macros: _vm.macros,
          fields: _vm.fields,
          title: _vm.chartTitle,
        },
        on: {
          "update:show": function ($event) {
            _vm.promptChartOptions = $event
          },
          success: _vm.chartModalSuccess,
        },
      }),
      _vm.allowManage && _vm.companyShow
        ? _c("CompanyModal", {
            attrs: {
              id: _vm.companyId,
              masterCompany: _vm.masterCompany,
              parentData:
                _vm.companyId !== null &&
                _vm.companyId.indexOf("COMPANY_NEW_") === 0
                  ? _vm.masterCompany
                  : null,
              show: _vm.companyShow,
              title: _vm.companyTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.companyShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("CommentModal", {
        attrs: { id: _vm.commentId, show: _vm.modalCommentShow },
        on: {
          "update:show": function ($event) {
            _vm.modalCommentShow = $event
          },
          success: _vm.modalSuccess,
        },
      }),
      _vm.showStaffSelector
        ? _c("StaffSelectorModal", {
            attrs: {
              mode: "MANAGE",
              staffListUuIds: _vm.staffUuIds,
              show: _vm.showStaffSelector,
            },
            on: {
              "update:show": function ($event) {
                _vm.showStaffSelector = $event
              },
            },
          })
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
          cancel: _vm.progressCancel,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.auto_assign.summary"),
            "ok-title": _vm.$t("button.close"),
            "ok-only": "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.autoAssignSummaryOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.autoAssignSummaryShow,
            callback: function ($$v) {
              _vm.autoAssignSummaryShow = $$v
            },
            expression: "autoAssignSummaryShow",
          },
        },
        [
          _vm.autoAssignSummary.length > 0
            ? _c(
                "ul",
                { staticClass: "task-summary-list" },
                _vm._l(_vm.autoAssignSummary, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "d-block task-summary-list-item",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "task-summary-title" }, [
                            _vm._v(_vm._s(item.taskName)),
                          ]),
                          item.staffAssignmentList.length > 0
                            ? _vm._l(
                                item.staffAssignmentList,
                                function (staff, sIndex) {
                                  return _c(
                                    "div",
                                    { key: sIndex, staticClass: "d-block" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "task.auto_assign.assigned",
                                              [
                                                staff.firstName +
                                                  " " +
                                                  staff.lastName,
                                              ]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          item.staffAssignmentList.length === 0
                            ? _c("div", { staticClass: "d-block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("task.auto_assign.none_assigned")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.autoAssignSummary.length === 0
            ? _c("div", { staticClass: "d-block" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("task.auto_assign.none_assigned")) + " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("AutoAssignStaffModal", {
        attrs: { tasks: _vm.autoAssignTasks(), show: _vm.autoAssignStaffShow },
        on: {
          "update:show": function ($event) {
            _vm.autoAssignStaffShow = $event
          },
          success: _vm.autoAssignStaffSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.deleteMessage) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteDataviewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteDataviewOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteDataviewShow,
            callback: function ($$v) {
              _vm.confirmDeleteDataviewShow = $$v
            },
            expression: "confirmDeleteDataviewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.confirmation.delete")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteChartOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteChartOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteChartShow,
            callback: function ($$v) {
              _vm.confirmDeleteChartShow = $$v
            },
            expression: "confirmDeleteChartShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("dataview.confirmation.delete_chart")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("booking.confirmation.title_type"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmBookingTypeOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmBookingTypeOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmBookingTypeShow,
            callback: function ($$v) {
              _vm.confirmBookingTypeShow = $$v
            },
            expression: "confirmBookingTypeShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-form-radio",
                {
                  attrs: { name: "booking staff", value: "staff" },
                  model: {
                    value: _vm.bookingEdit.mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.bookingEdit, "mode", $$v)
                    },
                    expression: "bookingEdit.mode",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("staff.title_selector")))]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: { name: "booking resource", value: "resource" },
                  model: {
                    value: _vm.bookingEdit.mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.bookingEdit, "mode", $$v)
                    },
                    expression: "bookingEdit.mode",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("booking.type.resource")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("activity.confirmation.title_type"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmActivityTypeOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmActivityTypeOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmActivityTypeShow,
            callback: function ($$v) {
              _vm.confirmActivityTypeShow = $$v
            },
            expression: "confirmActivityTypeShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-form-radio",
                {
                  attrs: { name: "booking staff", value: "staff" },
                  model: {
                    value: _vm.activityEdit.mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityEdit, "mode", $$v)
                    },
                    expression: "activityEdit.mode",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("staff.title_selector")))]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: { name: "booking resource", value: "resource" },
                  model: {
                    value: _vm.activityEdit.mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.activityEdit, "mode", $$v)
                    },
                    expression: "activityEdit.mode",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("activity.type.resource")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.timeseries_hint_title"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "hint-checkbox secondary-checkbox",
                      model: {
                        value: _vm.timeseries_hint_donotshow,
                        callback: function ($$v) {
                          _vm.timeseries_hint_donotshow = $$v
                        },
                        expression: "timeseries_hint_donotshow",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("dataview.donotshow")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.closeHint()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.timeseriesHintShow,
            callback: function ($$v) {
              _vm.timeseriesHintShow = $$v
            },
            expression: "timeseriesHintShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.timeseries_hint")) + " "),
          ]),
          _c("img", {
            staticClass: "timeseries-hint-img",
            attrs: { src: "/img/help/timeseries.png" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }