import { tagService } from '@/services'
import { objectClone } from '@/helpers'
import { filterOutViewDenyProperties, setEditDenyPropertiesReadOnly, lackOfMandatoryField } from './common'
import { prepareCustomFieldColumnDef } from '@/helpers/custom-fields'

export const tagUtil = {
  list: (bParams, { self }={}) => {
    return tagService.list(bParams, true, self?.customFields)
  }
  , listNames: tagService.listNames
  , remove: tagService.remove
  , clone: tagService.clone
  , importDataFunc: (self) => {
    return async (item, errorFunc) => {
      let method = 'create';
      
      const data = {
        name: item.name,
        description: item.desc,
        identifier: item.identifier
      }

      if (self.customFields) {
        for (const cfield of self.customFields) {
          if (item[cfield.name]) {
            data[cfield.name] = item[cfield.name];
          }
        }
      }
      
      if (item.uuId) {
        data.uuId = item.uuId;
        method = 'update';
      }
      await tagService[method]([data])
      .then(response => {
        const feedbackList = response.data.feedbackList
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId
        }
      })
      .catch((e) => {
        errorFunc(e)
        return null
      })
    }
  }
  , buildParams: ({ request: {sortModel, endRow, startRow} }, { exportData=false, searchFilter=null, badgeFilters=null }={}) => {
    const params = {
      start: !exportData ? startRow : 0
      , limit: !exportData ? endRow - startRow + 1 : -1
      , ksort: []
      , order: []
      , filter: searchFilter
      , badgeFilters
    }
    
    for(let i = 0, len = sortModel.length; i < len; i++) {
      params.ksort.push(sortModel[i].colId)
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr')
    }
    if (searchFilter == null) {
      delete params.filter
    }




    return params
  }
  , getColumnDefs: (self) => {
    const colDefs = [
      {
        headerName: self.$t('tag.tag_name')
        , field: 'uuId'
        , cellRenderer: 'detailLinkCellRenderer'
        , cellEditor: 'nameEditor'
        , checkboxSelection: false
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , minWidth: 200
        , hide: false
        , sort: 'asc'
        , editable: self.canEdit('TAG', ['name'])
        , valueSetter: function(params) {
          const newValue = params.newValue.trim()
          const oldValue = objectClone(params.data.name)
          if (newValue !== '' && newValue != oldValue) {
            self.$set(params.data, 'oldName', oldValue)
            params.data.name = newValue
            return true
          }
          return false
        }
      },
      {
        headerName: self.$t('tag.field.count')
        , field: 'count'
        , cellRenderer: 'genericCellRenderer'
        , hide: false
      }
    ]

    prepareCustomFieldColumnDef(colDefs, self.customFields, { self });

    const linkedEntities = []
    //VIEW permission: Remove column from display list
    filterOutViewDenyProperties(colDefs, 'TAG', linkedEntities)
    //EDIT permission: set column to be read only.
    setEditDenyPropertiesReadOnly(colDefs, 'TAG', linkedEntities)
    return colDefs
  }
  , getImportDataProperties: (self) => [
    { value: 'name', text: self.$t('tag.field.name') }
  ]
  , entityUpdateApiUrl: '/api/tag/update'
  , entityDeleteApiUrl: '/api/tag/delete'
  , getValueChangedHandler: (/** self */) => ({})
  , getPropertyCopyHandler: (self) => {
    let maxNameLength = 200
    if (self.modelInfo != null) {
      let val = self.modelInfo.filter(info => info.field === 'name')
      if (val.length > 0) {
        maxNameLength = val[0].max
      }
    } 

    //Expected format when return value is a function:
    //{ value, status } or
    //{ value, status, colId } when status is ABORT
    //Possible status: 'SUCCESS' | 'ABORT'
    //colId is optional but is useful for specifying a different colId as reset value.
    return {
      name: (srcValue /**, tgtData*/) => {
        let value = srcValue
        if (srcValue != null && srcValue.length > maxNameLength) {
          value = srcValue.substring(0, maxNameLength)
        }
        return { value, status: 'SUCCESS' }
      }
    }
  }
  , handleAjaxError: (self, feedbackList) => {
    const foundResult = feedbackList.find(i => i.clue == 'Not_unique_key')
    let alertMsg = null
    if (foundResult != null && Array.isArray(foundResult.args) && foundResult.args.length > 0 &&  foundResult.args[0] == 'name') {
      alertMsg = self.$t('error.not_unique_key_with_arg', [self.$t('tag.tag_name')])
    }
    return {
      msg: alertMsg
    }
  } 
  , lackOfMandatoryField: () => {
    return lackOfMandatoryField([{ entity: 'TAG', action: 'VIEW' }])
  }
  , getBadgeFilterFields: (self) => {
    const fields = [{ value: 'name', text: self.$t('tag.field.name') }];
    if (Array.isArray(self.customFields) && self.customFields.length > 0) {
      for (const f of self.customFields) {
        if (f.type == 'String' || f.type == 'Enum<String>') {
          fields.push({ value: f.name, text: f.displayName });
        }
      }
    }
    fields.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }))
    return fields;
  }
  , getBadgeFilterOptionFetchFunc: (self) => {
    return (field) => {
      return tagService.listUniqueValuesOfProperty(field)
      .then(data => {
        if (data.length > 0 && self.badgeFilters != null && self.badgeFilters.length > 0) {
          const found = self.badgeFilters.find(i => i.field == field)
          if (found != null && Array.isArray(found.value) && found.value.length > 0) {
            return data.map(i => ({ 
              text: !i ? '(Empty)' : i
              , checked: found.value.find(j => j.text != null && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) != null
            }))
          }
        }
        return data.map(i => ({ text: !i ? '(Empty)' : i, checked: false }))
      });
    }
  }
}